import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { PaymentMethods, PreparationSteps } from '../../../../constants/invoice';
import { Invoice } from '../../../../types/Invoice';
import { LargeButton, LargePrimaryButton } from '../../../../components/ui/Buttons';
import InvoicePreparationStepContainer from './InvoicePreparationStepContainer';
import { GlobalState } from '../../../../types/GlobalState';
import { OnCompletedStepProps } from '../../../../types/OnCompletedStepProps';
import { getInvoice } from '../../../../actions/invoices';

interface RouteParams {
  invoiceId: string;
}

type PropsFromState = Pick<GlobalState, "invoices">;

interface Props extends OnCompletedStepProps, PropsFromState, DispatchProp, RouteComponentProps<RouteParams> { }

class PaymentMethodExists extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
    this.handleNext = this.handleNext.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  async componentDidMount(): Promise<void> {
    const { dispatch } = this.props;
    await dispatch<any>(getInvoice(this.getInvoiceId()));
  }

  handleNext(): void {
    const { onCompletedStep } = this.props;
    onCompletedStep(PreparationSteps.PAYMENT_METHOD);
  }

  cancel(): void {
    const { onCompletedStep } = this.props;
    onCompletedStep(PreparationSteps.LINE_ITEMS);
  }

  getInvoiceId(): string {
    return this.props.match.params.invoiceId;
  }

  getInvoice(): Invoice | undefined {
    return this.props.invoices.data?.[this.getInvoiceId()];
  }

  getInvoiceTypeLabel(): string {
    const invoice = this.getInvoice();
    return !invoice?.type ? "" : PaymentMethods.getByKey(invoice.type).display;
  }

  render(): React.ReactElement {
    return (
      <InvoicePreparationStepContainer
        title={`Is the customer still paying with ${this.getInvoiceTypeLabel()}?`}
        secondaryBtn={
          <LargeButton variant="outlined" id="confirm-change-payment-method-btn" onClick={this.handleNext} >
            No
          </LargeButton>
        }
        primaryBtn={
          <LargePrimaryButton id="cancel-change-payment-method-btn" onClick={this.cancel}>
            Yes
          </LargePrimaryButton>
        }
      />
    );
  }
}

const mapStateToProps = ({ invoices }: GlobalState): PropsFromState => ({ invoices });
export default withRouter(connect(mapStateToProps)(PaymentMethodExists));

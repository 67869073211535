import { Theme } from "@material-ui/core";

const styles = (theme: Theme): {} => ({
  directPaymentAlertMobileOnly: {
    [theme.breakpoints.up(961)]: {
      display: 'none'
    }
  }
});

export default styles;

import { LargePrimaryButton } from '@roadsync/roadsync-ui';
import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../actions/modals';
import ModalContent from '../../components/modals/ModalContent';
import { ModalsConstants } from '../../constants/modals';
import useStyles from './ReportRedirectModal.css';
import { P } from "@roadsync/roadsync-ui";

const ReportRedirectModal: React.FC = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const redirectUrl = 'fullbay.fleetpayments.com/reporting/';

  const handleClose = (): void => {
    dispatch(closeModal(ModalsConstants.REPORT_REDIRECT));
  };

  return (
    <ModalContent className={classes.root}>
      <div className={classes.textContainer}>
        <P>Seems like you have a reporting role.</P>
        <P>Visit <a className={classes.link} href={'https://'+redirectUrl}>{redirectUrl}</a> to use the Reporting service.</P>
      </div>
      <LargePrimaryButton onClick={handleClose}>
        Ok
      </LargePrimaryButton>
    </ModalContent>
  );
};

export default ReportRedirectModal;

import React, { useEffect } from 'react';
import { Form, Field } from "react-final-form";
import { validatePreEdit } from '../../services/app/validators';
import { Grid } from '@material-ui/core';
import { Invoice } from "../../types";
import { TextField } from 'final-form-material-ui';
import { isEqual } from "lodash";
import { DatePicker } from '@material-ui/pickers';

interface Props {
    invoice?: Invoice;
    handleSubmitPreEdit: (values: Record<string, any>) => Promise<void>;
    children?: React.ReactNode;
    loading?: boolean;
    setDirty: () => void;
    setPristine: () => void;
    setValidationErrors: (values: Record<string, any>) => void;
}

const PreEditForm: React.FC<Props> = (props: Props): React.ReactElement => {

    const { handleSubmitPreEdit, setDirty, setPristine, setValidationErrors } = props;

    useEffect(() => {
        return function cleanup() {
            setValidationErrors({});
            setPristine();
        };
    }, [setPristine, setValidationErrors]);

    return (
        <Form
            validate={values => validatePreEdit(values, false, setValidationErrors)}
            onSubmit={handleSubmitPreEdit}
            render={({ handleSubmit, values }) => {
                return <>
                    <button
                        id="pre-edit-submit-hidden"
                        type="submit"
                        onClick={handleSubmit}
                        hidden
                    >
                        Hidden EXTERNAL button
                    </button>
                    <form
                        onSubmit={e => {
                            e.preventDefault()
                            handleSubmit(values);
                        }}
                        onChange={setDirty}
                        id={preEditFormName}
                    >
                        <Grid container direction="column" wrap="nowrap" spacing={2}>

                            <Grid item>
                                <Field
                                    name="unitNumber"
                                    component={TextField}
                                    label="Unit Number"
                                    fullWidth
                                    variant="outlined"
                                    required
                                    id="unit-number"
                                />
                            </Grid>
                            <Grid item>
                                <Field
                                    name="cardExpiration"
                                    label="Expiration Date"
                                    type="text"
                                    render={(props) =>
                                        <DatePicker
                                            placeholder='Expiration Date'
                                            value={props.input.value === "" ? null : props.input.value}
                                            onChange={props.input.onChange}
                                            views={['year', 'month']}
                                            minDate={new Date()}
                                            format="MM/YYYY"
                                            inputVariant='outlined'
                                        />
                                    }
                                    error={!values.cardExpiration}
                                    id="card-expiration"
                                    helperText="Required if date is on card"
                                />
                            </Grid>
                        </Grid>
                    </form>
                </>
            }}
        />)
};

export const preEditFormName = 'preEdit';
// WARNING: isEqual is a must have to avoid infinite rerenders!
// it compares prevProps and nextProps to decide if to render
export default React.memo(PreEditForm, isEqual);

import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            fontSize: 22,
            fontWeight: "bold",
        },
        titleNumericValue: {
            fontSize: 22,
            fontWeight: "normal",
        },
        info: {
            fontSize: 16,
            fontWeight: "normal",
        },
        subTitle: {
            fontSize: 16,
            fontWeight: "bold",
        },
        inputWidth: {
            width: "60%",
        },
        chargesInputSection: {
            width: "60%",
            marginBottom: 20,
            backgroundColor: "red",
        },
        gridContainerAttachPdf: {
            [theme.breakpoints.down(410)]: {
                justifyContent: 'flex-end',
            }
        },
        gridItemLabelAttachPdfToInvoice: {
            alignContent: 'center'
        }
    })
);

export default useStyles;


import { P } from '@roadsync/roadsync-ui';
import React from 'react';

const ComdataCheckFallbackReducePaperChecksText: React.FC = () => {
  return (
    <>
      <P gutterBottom>We were unable to process your payment at this time. We will attempt an alternative validation, which may take up to five minutes to complete.</P>
      <br />
      <P gutterBottom>Please provide the following information:</P>
      <br />
    </>
  );
};

export default ComdataCheckFallbackReducePaperChecksText;

import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { CloseIcon } from "../ui/Icons";
import { StepProgress } from "./AddressBookContactForm";
import useStyles from "./AddressBookTitle.css";

interface Props {
    activeStep: number;
    closeModal: React.Dispatch<React.SetStateAction<StepProgress>>;
    setPreviousStep: React.Dispatch<React.SetStateAction<StepProgress>>;
}

const AddressBookTitle: React.FC<Props> = (props: Props) => {
    const { activeStep, closeModal, setPreviousStep } = props;
    const classes = useStyles();

    const [title, setTitle] = useState("");
    const [subTitle, setSubtitle] = useState("");

    useEffect(() => {
        if (activeStep === 0) {
            setTitle("Company Details");
            setSubtitle("Complete the details for the Payer company.");
        } else if (activeStep === 1) {
            setTitle("Contacts");
            setSubtitle("Save the company contacts information.");
        } else if (activeStep === 2) {
            setTitle("Confirm");
            setSubtitle("Double-check your Payer's details. You can revisit, add more contacts and edit anytime.");
        }
    }, [activeStep]);
    return (
        <>
            <Box textAlign="left">
                <Grid container>
                    <Grid item xs={6}>
                        <Typography variant="h3" component="div" data-testid='add-payer-title'>
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.closeIcon}>
                        <Button
                            id="close-modal"
                            data-testid="close-modal"
                            variant="text"
                            onClick={() => {
                                setPreviousStep(activeStep);
                                closeModal(4);
                            }}
                        >
                            <CloseIcon />
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6" component="div">
                            <Box fontWeight="fontWeightRegular">{subTitle}</Box>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default AddressBookTitle;


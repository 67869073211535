import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
    createStyles({
        deleteContactButton: {
            width: "100%",
            backgroundColor: "white",
            borderRadius: "5px",
            border: "black 1px solid",
        },
        addContactButton: {
            width: "100%",
            backgroundColor: "black",
            color: "white",
            borderRadius: "5px",
            border: "black 1px solid",
            "&:hover": {
                backgroundColor: "black",
            },
        },
        deleteContactSpacing: {
            marginTop: "1rem",
            marginBottom: "1rem",
        },
        divider: {
            marginTop: "1rem",
            marginBottom: "2rem",
        },
    })
);

export default useStyles;

import { createStyles, makeStyles, Theme } from "@material-ui/core";

const styles = makeStyles((theme: Theme) => createStyles({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: '-32px',
    marginLeft: '-32px'
  }
}));

export default styles;

import { CompanyCarriers } from './endpoints';
import { fetchWithHeaders, parseResponse } from './utils';
import * as http from './http';

export function updateCarrierById(carrierId: string, values): Promise<any> {
  return http.putJSON(CompanyCarriers.updateCarrierById(carrierId), values);
}

export function createCarrier(companyId: string, values): Promise<any> {
  return http.postJSON(CompanyCarriers.createCarrier(companyId), values);
}

export function getCarrierByCompanyId(companyId: string, q?: string): Promise<any> {
  return http.get(CompanyCarriers.getCarriersByCompanyId(companyId, q));
}

export function getCarrierListByCompanyId(companyId: string, offset: number, limit: number): Promise<any> {
  return http.get(CompanyCarriers.getCarriersListByCompanyId(companyId, offset, limit));
}

export function getCarrierById(carrierId: string): Promise<any> {
  return http.get(CompanyCarriers.getCarrierByById(carrierId));
}

export function deleteCarrierById(carrierId: string): Promise<any> {
  return http.del(CompanyCarriers.deleteCarrierById(carrierId));
}

export async function importCarriersList(companyId: string, file): Promise<{}> {
  const r = await fetchWithHeaders(CompanyCarriers.importCarriersList(companyId), {
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'text/csv' },
    body: file,
  });
  return parseResponse(r);
}

export function getCarriersByCompanyIdPublic(companyId: string): Promise<any> {
  return http.get(CompanyCarriers.getCarriersByCompanyIdPublic(companyId));
}

export function getUniversalCarriers(search: string, offset: number, limit: number): Promise<any> {
  return http.get(CompanyCarriers.getUniversalCarriers(search, offset, limit));
}

import { AdminPaths } from './paths/AdminPaths';
import { AuthPaths } from './paths/AuthPaths';
import { AccountingPaths } from './paths/AccountingPaths';
import { BankingPaths } from './paths/BankingPaths';
import { ActivityPaths } from './paths/ActivityPaths';
import { CheckAuthorizerPaths } from './paths/CheckAuthorizerPaths';
import { CheckPaths } from './paths/CheckPaths';
import { DashboardPaths } from './paths/DashboardPaths';
import { DebitCardPaths } from './paths/DebitCardPaths';
import { HelpPaths } from './paths/HelpPaths';
import { InvoicePaths } from './paths/InvoicePaths';
import { ReportPaths } from './paths/ReportPaths';
import { TransferPaths } from './paths/TransferPaths';
import { WorkOrderPaths } from './paths/WorkOrderPaths';

export const homeUrl = (): string => '/';

export {
    AccountingPaths,
    BankingPaths,
    AdminPaths,
    AuthPaths,
    ActivityPaths,
    CheckAuthorizerPaths,
    CheckPaths,
    DashboardPaths,
    DebitCardPaths,
    HelpPaths,
    InvoicePaths,
    ReportPaths,
    TransferPaths,
    WorkOrderPaths,
}

import React from 'react';
import { connect } from 'react-redux';
import { Role } from '../../../types/Role';
import { User } from '../../../types/User';

interface Props {
  includes?: Role[];
  requireAllRoles?: boolean;
  children: React.ReactNode;
  otherwise?: React.ReactNode;
  user?: User;
}

interface State {
  auth: {
    me?: User;
  };
}

class IfUserRole extends React.Component<Props, State> {

  render(): React.ReactNode {
    return this.meetsRequirements()
      ? <React.Fragment>{this.props.children}</React.Fragment>
      : (this.props.otherwise || null);
  }

  getUserRoles(): string[] {
    return this.props?.user?.roles || [];
  }

  getRequiredRoles(): string[] {
    return (this.props.includes || []).map((role: Role) => role.key);
  }

  meetsRequirements(): boolean {
    if (this.noRequiredRoles()) {
      return true;
    }
    return this.props.requireAllRoles ? this.hasAllRoles() : this.hasAtLeastOneRole();
  }

  noRequiredRoles(): boolean {
    return !this.props.includes || !this.props.includes.length;
  }

  hasAllRoles(): boolean {
    const userRoles = this.getUserRoles();
    return this
      .getRequiredRoles()
      .every(role => userRoles.indexOf(role) > -1);
  }

  hasAtLeastOneRole(): boolean {
    const userRoles = this.getUserRoles();
    return this
      .getRequiredRoles()
      .some(role => userRoles.indexOf(role) > -1);
  }
}

const mapStateToProps = (state: State): { user?: User } => {
  return { user: state.auth?.me };
};

export default connect(mapStateToProps)(IfUserRole);

import React from 'react';
import { reduxForm, Field, InjectedFormProps, formValueSelector } from 'redux-form';
import { futureOrCurrentMonthDate, required, validateCheckDetailsFormData } from '../../services/app/validators';
import { States } from '../../constants/states';
import { CheckType, CheckTypes as Types } from '../../constants/deposit';
import { InvoiceDeclineReasons } from '../../constants/invoiceDeclineReasons';
import DateInputWithWorkingValidation from '../controls/DateInputWithWorkingValidation'; // only used on invoice
import { connect } from 'react-redux';
import { Error } from '../ui/Error';
import { Invoice } from '../../types/Invoice';
import { P, Grid } from '@roadsync/roadsync-ui';
import { MenuItem } from '@material-ui/core';
import { RenderTextField } from '../ui/Form';
import { RenderSelectField } from '../ui/form/RenderSelectField';
import SignatureBox from '../ui/SignatureBox';
import { getAccountNumberLabel, getExpressCodeLabel } from '../../services/app/invoice';
import { GlobalState } from '../../types/GlobalState';
import { getFirstDayOfMonth } from '../../services/app/datetime';

export interface CheckDetailsFormData {
  expressCode?: string;
  driverNumber?: string;
  accountNumber?: string;
  trailerNumber?: string;
  expirationDate?: string;
  tripNumber?: string;
  unitNumber?: string;
  checkType?: string; // not a form element
  payerIdentifier?: string;
  payerState?: string;
  paymentError?: string;
}

type Props = InjectedFormProps<CheckDetailsFormData, OwnProps> & OwnProps;

interface PropsFromState {
  expressCode: string;
  expirationDate?: string;
}

interface OwnProps extends PropsFromState {
  signatureEnabled?: boolean;
  signatureUrl?: string;
  openAddSignatureModal?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  total: string;
  invoice?: Invoice;
}

class CheckDetailsForm extends React.Component<Props> {

  getCheckType(): CheckType | undefined {
    const { initialValues } = this.props;
    return initialValues?.checkType ? Types.getByKey(initialValues.checkType) : undefined;
  }

  getExpressCodeLabel(): string {
    return getExpressCodeLabel(this.getCheckType());
  }

  getAccountNumberLabel(): string {
    return getAccountNumberLabel(this.getCheckType());
  }

  getDeclineReason(): string | undefined {
    const { initialValues } = this.props;
    return initialValues?.paymentError
      ? InvoiceDeclineReasons.getByKey(initialValues.paymentError)?.display || `Error: ${initialValues.paymentError}`
      : undefined;
  }

  isComchek(): boolean {
    return this.getCheckType()?.key === Types.COMCHEK.key;
  }

  isTcheck(): boolean {
    return this.getCheckType()?.key === Types.TCHECK.key;
  }

  isEFS(): boolean {
    return this.getCheckType()?.key === Types.EFS.key;
  }

  isComchekOrEFS(): boolean {
    return this.isComchek() || this.isEFS();
  }

  isUSBank(): boolean {
    return this.getCheckType()?.key === Types.USBANK.key;
  }

  isInstamoney(): boolean {
    return this.getCheckType()?.key === Types.INSTAMONEY.key;
  }

  isUSBankOrInstamoney(): boolean {
    return this.isUSBank() || this.isInstamoney();
  }

  isNotUSBank(): boolean {
    const checkType = this.getCheckType();
    return !!checkType && checkType.key !== Types.USBANK.key;
  }

  isNotFleetOne(): boolean {
    const checkType = this.getCheckType();
    return !!checkType && checkType.key !== Types.FLEET_ONE.key;
  }

  showExpirationDate(): boolean {
    const { expressCode } = this.props;
    return !!expressCode && [10, 12].indexOf(expressCode.length) > -1;
  }

  getCardName(): string | undefined {
    return this.getCheckType()?.cardName;
  }

  minDate(): Date {
    const date = new Date();
    const firstDayOfCurrentMonth = getFirstDayOfMonth(
      date.getFullYear(),
      date.getMonth(),
    );
    return firstDayOfCurrentMonth;
  }

  // eslint-disable-next-line max-lines-per-function
  render(): React.ReactElement {
    const { error, handleSubmit, signatureEnabled, signatureUrl, openAddSignatureModal } = this.props;
    const declineReason = this.getDeclineReason();
    return (
      <form onSubmit={handleSubmit}>
        <Grid container direction="column" wrap="nowrap" spacing={2}>
          {error && <Grid item><Error error={error} /></Grid>}
          {declineReason &&
            <Grid item>
              <Error error={`Decline reason: ${declineReason}`} />
            </Grid>
          }
          {this.isComchekOrEFS() &&
            <Grid item>
              <P variant="body1" id="comcheckLabel">
                If using a {this.getCardName()}, put the card number in the field below.
              </P>
            </Grid>
          }

          {this.isUSBankOrInstamoney() &&
            <Grid item>
              <Field
                validate={required}
                name="accountNumber"
                label={this.getAccountNumberLabel()}
                type="text"
                component={RenderTextField}
              />
            </Grid>
          }
          {this.isNotFleetOne() &&
            <Grid item>
              <Field
                name="expressCode"
                label={this.getExpressCodeLabel()}
                type="text"
                component={RenderTextField}
                validate={required}
                required
              />
            </Grid>
          }
          {this.isNotUSBank() &&
            <>
              <Grid item>
                <Field
                  name="payerIdentifier"
                  label="Driver license"
                  type="text"
                  component={RenderTextField}
                  validate={required}
                  required
                />
              </Grid>
              <Grid item>
                <Field
                  name="payerState"
                  label="Driver license State"
                  type="text"
                  component={RenderSelectField}
                  validate={required}
                  required
                >
                  {States.map((value) =>
                    <MenuItem key={value.Code} value={value.Code}>{value.StateName}</MenuItem>)
                  }
                </Field>
              </Grid>
            </>
          }
          {this.isComchek() &&
            <>
              <Grid item>
                <Field name="driverNumber" label="Driver Number (optional)" type="text" component={RenderTextField} />
              </Grid>
              <Grid item>
                <Field name="tripNumber" label="Trip Number (optional)" type="text" component={RenderTextField} />
              </Grid>
              <Grid item>
                <Field name="unitNumber" label="Unit Number (optional)" type="text" component={RenderTextField} />
              </Grid>
              <Grid item>
                <Field name="trailerNumber" label="Truck Number (optional)" type="text" component={RenderTextField} />
              </Grid>
            </>
          }
          {this.isTcheck() &&
            <>
              {this.showExpirationDate() &&
                <Grid item>
                  <Field
                    name="expirationDate"
                    label="Expiration Date"
                    type="text"
                    component={DateInputWithWorkingValidation}
                    validate={[required, futureOrCurrentMonthDate]}
                    required
                    showMonthYearPicker
                    placeholder='Expiration Date'
                    minDate={this.minDate()}
                  />
                </Grid>
              }
              <Grid item>
                <Field name="tripNumber" label="Trip Number (optional)" type="text" component={RenderTextField} />
              </Grid>
              <Grid item>
                <Field name="trailerNumber" label="Truck Number (optional)" type="text" component={RenderTextField} />
              </Grid>
              <Grid item>
                <Field name="driverNumber" label="Driver Number (optional)" type="text" component={RenderTextField} />
              </Grid>
              <Grid item>
                <Field name="mileage" label="Mileage (optional)" type="text" component={RenderTextField} />
              </Grid>
              <Grid item>
                <Field name="poNumber" label="Purchase Order (PO) Number" type="text" component={RenderTextField} />
              </Grid>
            </>
          }

          {signatureEnabled &&
            <Grid item>
              <SignatureBox onClick={openAddSignatureModal} signatureUrl={signatureUrl} />
            </Grid>
          }
        </Grid>
      </form>
    );
  }
}

const form = 'checkDetails';
const selector = formValueSelector(form);
const mapStateToProps = (state: GlobalState): PropsFromState => ({
  expressCode: selector(state, 'expressCode'),
  expirationDate: selector(state, 'expirationDate')
});

export default connect(mapStateToProps)(
  reduxForm<CheckDetailsFormData, OwnProps>({ form, validate: validateCheckDetailsFormData })(CheckDetailsForm)
);

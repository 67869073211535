import React from 'react';
import { connect } from 'react-redux';
import { Role } from '../../../types/Role';
import { User } from '../../../types/User';

interface Props {
  roleSet: Role[][];
  children: React.ReactNode;
  otherwise?: React.ReactNode;
  user?: User;
}

interface State {
  auth: {
    me?: User;
  };
}

/*
Usage:

<IfUserRoleMatches
    roleSet={[
        [Roles.COMPANY_ADMIN, Roles.ACCOUNTANT],
        [Roles.SUPER_ADMIN]
    ]}>
    <p>I am either a company admin AND account, or I am a super user.</p>
    <p>I might have other roles, too, but I at least have fulfilled the above requirements.</p>
</IfUserRoleMatches>
*/
export class IfUserRoleMatches extends React.Component<Props> {

  render(): React.ReactNode {
    return this.meetsRequirements()
      ? <React.Fragment>{this.props.children}</React.Fragment>
      : (this.props.otherwise || null);
  }

  getUserRoles(): string[] {
    return this.props?.user?.roles || [];
  }

  getRequiredRoles(): string[][] {
    return this.props.roleSet.map(roleSet =>
      roleSet.map((role: Role) => role.key)
    );
  }

  meetsRequirements(): boolean {
    return this.getRequiredRoles().some(roles => this.hasAllRoles(roles));
  }

  hasAllRoles(roles: string[]): boolean {
    const userRoles = this.getUserRoles();
    return roles.every(role => userRoles.indexOf(role) > -1);
  }
}

const mapStateToProps = (state: State): { user?: User } => {
  return { user: state.auth?.me };
};

export default connect(mapStateToProps)(IfUserRoleMatches);

import { PublicActions } from '../constants/actions';
import * as InvoiceService from '../services/api/invoices';
import * as DepositService from '../services/api/deposits';
import * as appSettingsService from '../services/api/appSettings';
import * as CompanyFeeService from '../services/api/fees';
import * as CompanyFeaturesService from '../services/api/features';
import { openFile } from '../services/api/utils';
import { Deposit } from '../types/Deposit';
import { Dispatch } from 'react';

const receiveInvoicePublic = (entities) => ({ type: PublicActions.RECEIVE_INVOICE, entities });
const requestInvoicePublic = (entities, loading) => ({ type: PublicActions.REQUEST_INVOICE, entities, loading });
const receiveDepositPublic = (entities) => ({ type: PublicActions.RECEIVE_DEPOSIT, entities });
const requestDepositPublic = (entities) => ({ type: PublicActions.REQUEST_DEPOSIT, entities, });
const requestAppSettingsPublic = () => ({ type: PublicActions.REQUEST_APP_SETTINGS });
const receiveAppSettingsPublic = (entities) => ({ type: PublicActions.RECEIVE_APP_SETTINGS, entities });
const requestCompanyFeesPublic = (companyId) => ({ type: PublicActions.REQUEST_COMPANY_FEES, companyId });
const receiveCompanyFeesPublic = (entities) => ({ type: PublicActions.RECEIVE_COMPANY_FEES, entities });
const requestPublicCompanyFeatureList = (companyId) => ({ type: PublicActions.REQUEST_PUBLIC_FEATURE_LIST, companyId });
const receivePublicCompanyFeatureList = (entities) => ({ type: PublicActions.RECEIVE_PUBLIC_FEATURE_LIST, entities });

export function getAppSettingsPublic() {
  return (dispatch: Function) => {
    dispatch(requestAppSettingsPublic());
    return appSettingsService.getAppSettingsPublic()
      .then((config: any) => {
        dispatch(receiveAppSettingsPublic(config));
      });
  };
}

export function getCompanyFeesPublic(companyId: string) {
  return (dispatch: Function) => {
    dispatch(requestCompanyFeesPublic(companyId));
    return CompanyFeeService.getFeesByCompanyIdPublic(companyId)
      .then((data) => { dispatch(receiveCompanyFeesPublic(data)); });
  };
}

export function getInvoicePublic(token: string, showLoading?: boolean) {
  return (dispatch: Function) => {
    dispatch(requestInvoicePublic(token, showLoading));
    return InvoiceService.getInvoiceByToken(token)
      .then((data: any) => { dispatch(receiveInvoicePublic(data)); });
  };
}

export function getDepositPublic(token: string) {
  return (dispatch: Function) => {
    dispatch(requestDepositPublic(token));
    return DepositService.getDepositForInvoicePublic(token)
      .then((data: any) => { dispatch(receiveDepositPublic(data)); });
  };
}

export function depositCreateOrReuseCheckPublic(token: string, checkType: string) {
  return (dispatch: Function) => {
    return DepositService.depositCreateOrReuseCheckPublic(token, checkType)
      .then(result => dispatch(receiveDepositPublic(result)));
  };
}

export function processCheckPubic(deposit: Pick<Deposit, "id"> & Partial<Deposit>) {
  return (dispatch: Function) => {
    return DepositService.processCheckPublic(deposit.id, deposit)
      .then((result) => dispatch(receiveDepositPublic(result)));
  };
}

export function getInvoiceReceiptPublic(token: string): (d: Dispatch<any>) => void {
  return openFile(InvoiceService.getInvoiceReceiptByToken(token));
}

export function getPublicCompanyFeatures(companyId: string) {
  return (dispatch: Function) => {
    dispatch(requestPublicCompanyFeatureList(companyId));
    return CompanyFeaturesService.getPublicFeaturesByCompanyId(companyId)
      .then((data: any) => {
        dispatch(receivePublicCompanyFeatureList(data));
      });
  };
}

import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import useStyles from './Loader.css'

const Loader: React.FC = (): React.ReactElement => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" justifyContent="center" data-testid="loader">
      <Grid item><CircularProgress size={64} className={classes.root} /></Grid>
    </Grid>
  );
}

export default Loader;

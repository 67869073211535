import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { sendNotificationToPayer } from '../../../../actions/invoices';
import { handleReduxFormError } from '../../../../services/app/forms';
import SendInvoiceDetailsToPayerForm, { SendInvoiceDetailsToPayerFormData } from '../../../../components/invoice/SendInvoiceDetailsToPayerForm';
import { InvoicePaths } from '../../../../services/app/paths';
import { PayerMessages } from '../../../../constants/payerMessages';
import { Invoice } from '../../../../types/Invoice';
import { formatDollar } from "../../../../services/app/formats";
import { normalizePhoneOrEmail } from "../../../../services/app/normalizers";
import InvoicePreparationStepContainer from './InvoicePreparationStepContainer';
import { showSuccessAlert } from '../../../../actions/alerts';
import { GlobalState } from '../../../../types/GlobalState';
import { Deposit } from '../../../../types/Deposit';

interface RouteParams {
  invoiceId: string;
}

interface OwnProps {
  pageHeader: string;
}

type PropsFromState = Pick<GlobalState, "deposits" | "invoices">;

interface Props extends OwnProps, DispatchProp, PropsFromState, RouteComponentProps<RouteParams> { }

class SendInvoiceDetailsToPayer extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  getInvoiceId(): string {
    return this.props.match.params.invoiceId;
  }

  getInvoice(): Invoice | undefined {
    return this.props.invoices.data?.[this.getInvoiceId()];
  }

  getGrandTotal(): string {
    return formatDollar(this.getInvoice()?.grandTotal);
  }

  async onSend(): Promise<void> {
    const { history, dispatch } = this.props;
    const invoice = this.getInvoice();
    await dispatch<any>(showSuccessAlert(`Invoice #${invoice?.invoiceNumber} grand total and payment details sent to payer`));
    history.push(InvoicePaths.listUrl());
  }

  getDeposit(): Deposit | undefined {
    return this.props.deposits.data?.[this.getInvoiceId()];
  }

  async handleSubmit(values: SendInvoiceDetailsToPayerFormData): Promise<void> {
    const { dispatch } = this.props;
    const deposit = this.getDeposit();
    const phoneOrEmail = normalizePhoneOrEmail(values.phoneOrEmail);
    const phone = phoneOrEmail?.indexOf('@') >= 0 ? "" : phoneOrEmail;
    const email = phoneOrEmail?.indexOf('@') >= 0 ? phoneOrEmail : "";
    try {
      await dispatch<any>(sendNotificationToPayer(
        this.getInvoiceId(),
        phone,
        email,
        PayerMessages.INVOICE_DETAILS,
        deposit?.checkNumber,
        deposit?.checkType,
        this.getGrandTotal(),
      ));
      await this.onSend();
    } catch (e) {
      handleReduxFormError(e);
    }
  }

  getInitialValues(): Partial<SendInvoiceDetailsToPayerFormData> {
    const invoice = this.getInvoice();
    return { phoneOrEmail: invoice?.payerPhone || invoice?.payerEmail };
  }

  render(): React.ReactElement {
    const { pageHeader } = this.props;
    return (
      <InvoicePreparationStepContainer title={pageHeader}>
        <SendInvoiceDetailsToPayerForm initialValues={this.getInitialValues()} onSubmit={this.handleSubmit} />
      </InvoicePreparationStepContainer>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({ deposits, invoices }: GlobalState): PropsFromState => ({ deposits, invoices });
export default withRouter(connect(mapStateToProps)(SendInvoiceDetailsToPayer));

import { createStyles, makeStyles } from "@material-ui/core";

const useStyles =  makeStyles(() => createStyles({
  root: {
    width: '280px',
    position: 'relative',
    margin: 'auto',
    '& .modal-inner-content': {
      padding: '38px 30px',
    },
    '& p': {
      fontWeight: 'bold',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: '9px',
    right: '11px',
  },
  title: {
    fontSize: '18px',
    lineHeight: '21px',
    color: '#DA5B40',
  },
  message: {
    fontSize: '13px',
    lineHeight: '15px',
    marginTop: '8px',
  },
  imageTitle: {
    fontSize: '11px',
    lineHeight: '13px',
    margin: '28px 0 6px',
  },
}));

export default useStyles;
import { Divider, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import React from "react";
import { getLineItemCostAndTitle } from "../../services/app/invoice";
import { LineItem } from "../../types/LineItems";
import { Invoice } from "../../types";

interface Props {
  item: LineItem;
  invoice?: Invoice;
}

const ProductListLineItem: React.FC<Props> = (props: Props) => {
  const { item, invoice } = props;
  const { description, showQty, cost } = getLineItemCostAndTitle(item);
  const isTowbookInvoice = invoice?.source === 'towbook';
  const towbookDescription = `Towbook Call #${invoice?.externalInvoiceNumber}`
  return <>
    <ListItem>
      <ListItemIcon>{showQty || 1}x</ListItemIcon>
      <ListItemText>{isTowbookInvoice ? towbookDescription : description}</ListItemText>
      <ListItemSecondaryAction>{cost}</ListItemSecondaryAction>
    </ListItem>
    <Divider />
  </>
}

export default ProductListLineItem;

import React from "react";
import { FormControl, FormLabel, RadioGroup, RadioGroupProps } from "@material-ui/core";

interface Props {
  color?: "primary" | "secondary" | "default";
  children: React.ReactNode | JSX.Element;
  input: Partial<RadioGroupProps>;
  label: string;
}

export const RenderRadioGroup: React.FC<Props> = (props: Props): React.ReactElement => {
  const { input, label, children, ...rest } = props;
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <RadioGroup
        {...input}
        {...rest}
        onChange={input.onChange}>
        {children}
      </RadioGroup>
    </FormControl>
  );
}

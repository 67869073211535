import React from 'react';
import { Grid } from '@roadsync/roadsync-ui';
import { LoaderContent } from './LoaderContent';
import styles from "./PageContent.css";

interface Props {
  loading?: boolean;
  children: React.ReactNode;
  sideMenu?: React.ReactNode;
  filter?: React.ReactNode;
}

export const PageContent: React.FC<Props> = (props: Props): React.ReactElement => {
  const classes = styles();
  return (
    <Grid className={classes.root} container item direction="row" xs>
      {props.sideMenu && <Grid item className={classes.sideMenu}>{props.sideMenu}</Grid>}
      <Grid item xs>
        {props.filter && <React.Fragment>{props.filter}</React.Fragment>}
        <Grid container item xs className={classes.content}>
          {props.loading ? <LoaderContent /> : props.children}
        </Grid>
      </Grid>
    </Grid>
  )
};

import React from "react";
import { Grid } from '@material-ui/core';
import { Error } from '../Error';
import { Role } from '../../../types/Role';
import { IfUserRole } from '../Visibility';
import { BreadcrumbsContainer } from './page/BreadcrumbsContainer';
import UnauthorizedContainer from './page/UnauthorizedContainer';
import { PageContent } from './page/PageContent';
import { connect, DispatchProp } from 'react-redux';
import useStyles from "./Page.css";

interface Props extends OwnProps, DispatchProp { }

interface OwnProps {
  appBar?: React.ReactNode;
  breadcrumbs?: React.ReactNode;
  children: React.ReactNode;
  error?: string;
  loading?: boolean;
  roles?: Role[];
  sideMenu?: React.ReactNode;
  filter?: React.ReactNode;
}

export const Page: React.FC<Props> = (props: Props): React.ReactElement => {
  const classes = useStyles();
  return (
    <IfUserRole includes={props.roles} otherwise={<UnauthorizedContainer />}>
      <Grid container className={classes.root}>
        {props.appBar && <Grid item>{props.appBar}</Grid>}
        <Grid container item direction="column">
          {props.breadcrumbs && <BreadcrumbsContainer>{props.breadcrumbs}</BreadcrumbsContainer>}
          {props.error && <Grid item><Error error={props.error} /></Grid>}
          <PageContent {...props} />
        </Grid>
      </Grid>
    </IfUserRole>
  );
}

const mapStateToProps = (): {} => ({});
export default connect(mapStateToProps)(Page);

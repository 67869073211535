import _ from 'lodash';
import { LocationActions } from '../constants/actions';
import { mergeStateAndEntityResult } from '../services/app/normalizr';
import { Location } from '../types/Location';

export interface LocationsState {
  data?: { [id: string]: Location };
  ui?: { loading?: boolean };
  order?: string[];
}

// eslint-disable-next-line max-lines-per-function
export function locations(state: LocationsState = { data: {}, ui: {} }, action: Record<string, any> = {}): LocationsState {

  const nextData = mergeStateAndEntityResult(state, action.entities, 'locations');

  switch (action.type) {
    case LocationActions.REQUEST_LOCATION:
      return _.set({ ...state }, `data.${action.result}`, { loading: true });
    case LocationActions.REQUEST_LOCATION_LIST:
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: true,
        },
      };
    case LocationActions.RECEIVE_LOCATION_LIST:
      return {
        ...state,
        data: nextData,
        order: action.order,
        ui: {
          ...state.ui,
          loading: false,
        },
      };
    default:
      return {
        ...state,
        data: nextData,
      };
  }
}

import { InvoicePriceUpdatedModalActions } from '../constants/actions';
import { InvoicePriceUpdatedModalAction } from '../actions/invoicePriceUpdatedModal';

export type InvoicePriceUpdatedModalState = { isInvoicePriceUpdatedModalOpen?: boolean, invoiceId?: string };

export interface InvoicePriceUpdatedModal {
  isInvoicePriceUpdatedModalOpen?: boolean;
  invoiceId?: string;
}

export const initialState: InvoicePriceUpdatedModalState = { isInvoicePriceUpdatedModalOpen: false, invoiceId: undefined };

export const invoicePriceUpdatedModal = (state: InvoicePriceUpdatedModalState = initialState, action: InvoicePriceUpdatedModalAction): InvoicePriceUpdatedModalState => {
  switch (action.type) {
    case InvoicePriceUpdatedModalActions.OPEN: {
      return {
        ...state,
        isInvoicePriceUpdatedModalOpen: true,
        invoiceId: action?.invoiceId,
      };
    }
    case InvoicePriceUpdatedModalActions.CLOSE: {
      return {
        ...state,
        isInvoicePriceUpdatedModalOpen: false,
        invoiceId: undefined,
      };
    }
    default:
      return state;
  }
}

import React from 'react';
import { RouteComponentProps, withRouter } from "react-router";
import { connect, DispatchProp } from "react-redux";
import { Company, GlobalState, Invoice, InvoiceInput, InvoicePreparationStepProps } from "../../../../types";
import { PaymentMethods, PreparationSteps } from "../../../../constants/invoice";
import iconFleetFuelCardV2 from '../../../../assets/img/icons/v2/fleet-card.svg';
import PaymentMethodListButton from '../../../../components/app/PaymentMethodListButton';
import { Grid, List } from '@material-ui/core';
import { FeatureFlag, FeatureFlagContainer } from '../../../../components/ui/Visibility';
import { isWexEnabled } from '../../../../services/app/company';
import { setFuelCardPaymentMethod } from '../../../../actions/deposits';
import InvoicePreparationStepContainer from './InvoicePreparationStepContainer';
import { updateInvoice } from '../../../../actions/invoices';
import { FullScreenLoader } from '@roadsync/roadsync-ui';

type PropsFromState = Pick<GlobalState, "invoices" | "companies"> & {
  invoice?: Invoice;
  invoiceId: string;
}

interface RouteParams {
  invoiceId: string;
}

interface State {
  submitting: boolean;
  selectedMethod?: string;
  error?: string;
}

interface Props extends PropsFromState, InvoicePreparationStepProps, RouteComponentProps<RouteParams>, DispatchProp { }

class NewFuelCardPaymentSelectionScreen extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.back = this.back.bind(this);
    this.continue = this.continue.bind(this);
    this.isWexEnabled = this.isWexEnabled.bind(this);
    this.updatePaymentMethod = this.updatePaymentMethod.bind(this);
    this.handleSelectPaymentMethod = this.handleSelectPaymentMethod.bind(this);
    this.getPreparedInvoice = this.getPreparedInvoice.bind(this);
    this.saveInvoice = this.saveInvoice.bind(this);
    this.state = { submitting: false }
  }

  componentDidMount(): void {
    this.setState({
      selectedMethod: undefined
    });
  }

  handleSelectPaymentMethod(selectedMethod: string): void {
    this.setState({ submitting: true, selectedMethod }, async (): Promise<void> => await this.updatePaymentMethod());
  }

  async updatePaymentMethod(): Promise<void> {
    const { dispatch, onCompletedStep } = this.props;
    const invoice = this.getInvoice();
    const cardPaymentProcessor = this.state.selectedMethod === 'comdata' ? 'comdata' : 'wex'
    try {
      await dispatch<any>(setFuelCardPaymentMethod(invoice.id, cardPaymentProcessor))
        .then(async () => this.saveInvoice(cardPaymentProcessor))
      return onCompletedStep(PreparationSteps.LINE_ITEMS);

    } catch (e) {
      this.handleError(e as any);
    }
    this.setState({ submitting: false });
  }

  handleError(e?: { message?: string }): void {
    this.setState({ error: e?.message || "Something went wrong. Please try again or contact support." });
  }

  getInvoiceId(): string | undefined {
    return this.props.invoiceId
  }

  getInvoice(): Invoice {
    const { invoices } = this.props;
    const invoiceId = this.getInvoiceId();
    return (invoiceId && invoices.data?.[invoiceId]) || {} as Invoice
  }

  getCompany(): Company | undefined {
    const { companies } = this.props;
    const invoice = this.getInvoice();
    const companyId = "string" === typeof invoice?.company ? invoice.company : invoice?.company?.id;
    return companyId ? companies?.data?.[companyId] : undefined;
  }

  isWexEnabled(): boolean {
    return isWexEnabled(this.getCompany());
  }

  back(): void {
    const { onCompletedStep } = this.props;
    onCompletedStep(PreparationSteps.PAYMENT_METHOD);
  }

  continue(): void {
    const { onCompletedStep } = this.props;
    return onCompletedStep(PreparationSteps.LINE_ITEMS);
  }

  getPreparedInvoice(invoice: Invoice, paymentProcessor: string): InvoiceInput {
    let invoiceInput = {...invoice} as InvoiceInput;
    invoiceInput.cardPaymentProcessor = paymentProcessor;
    invoiceInput.departmentId = (invoice.departmentId || invoice.department) ? String(invoice.departmentId || invoice.department) : undefined;
    invoiceInput.shiftId = (invoice.shiftId || invoice.shift) ? String(invoice.shiftId || invoice.shift) : undefined;
    return invoiceInput;
  }

  async saveInvoice(paymentProcessor: string): Promise<Invoice> {
    const { dispatch } = this.props;
    const invoice = this.getInvoice()
    const invoiceToSave = this.getPreparedInvoice(invoice, paymentProcessor);
    return dispatch<any>(updateInvoice(invoiceToSave));
  }

  render(): React.ReactElement {
    const { submitting } = this.state;

    return (
      <InvoicePreparationStepContainer title='What kind of fuel card is being used ?'>
        <FullScreenLoader show={submitting} />
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <List>
              <PaymentMethodListButton
                icon={<img src={iconFleetFuelCardV2} alt="iconFleetCheckV2" />}
                onClick={() => this.handleSelectPaymentMethod(PaymentMethods.COMDATA_CARD.key)}
                id={`select-payment-method-btn--${PaymentMethods.COMDATA_CARD.key}`}
                text={PaymentMethods.COMDATA_CARD.display}
              />
              {this.isWexEnabled() &&
                <FeatureFlagContainer flag={FeatureFlag.FuelCards}>
                  <PaymentMethodListButton
                    icon={<img src={iconFleetFuelCardV2} alt="iconFleetFuelCardV2" />}
                    id={`select-payment-method-btn--${PaymentMethods.WEX_EFS.key}`}
                    onClick={() => this.handleSelectPaymentMethod(PaymentMethods.FUEL_CARD.key)}
                    text={PaymentMethods.WEX_EFS.display}
                  />
                </FeatureFlagContainer>
              }
            </List>
          </Grid>
        </Grid >
      </InvoicePreparationStepContainer>
    );
  }
}

export const mapStateToProps = (
  { invoices, companies }: GlobalState,
  { match: { params: { invoiceId } } }: RouteComponentProps<RouteParams>
): PropsFromState => {
  const invoice = invoices?.data?.[invoiceId] as Invoice;
  return {
    companies,
    invoice,
    invoices,
    invoiceId
  };
};

export default withRouter(connect(mapStateToProps)(NewFuelCardPaymentSelectionScreen));

import { CardActions } from '../constants/actions';
import _ from 'lodash';
import { CardAccountStatuses } from '../constants/bank';
import { CardAccountStatus, CardAccountStatusLoading } from '../types/CardAccountStatus';

export interface CardAccountState {
  status?: { [companyId: string]: CardAccountStatus | CardAccountStatusLoading };
  terms: { [companyId: string]: any };
  questions: { [companyId: string]: any };
}

interface CardAccountAction {
  type: string;
  companyId?: string;
  status?: CardAccountStatus;
  terms?: unknown;
  questions?: unknown;
}

// eslint-disable-next-line
export function cardAccount(
  state: CardAccountState = { status: {}, terms: {}, questions: {} },
  action: CardAccountAction = { type: "unknown" }
): CardAccountState {
  switch (action.type) {
    case CardActions.REQUEST_CARD_ACCOUNT_STATUS:
      return _.set({ ...state }, `status.${action.companyId}`, { loading: true });
    case CardActions.RECEIVE_CARD_ACCOUNT_STATUS:
      return _.set({ ...state }, `status.${action.companyId}`, action.status);
    case CardActions.REQUEST_TERMS_OF_SERVICE:
      return _.set({ ...state }, `terms.${action.companyId}`, { loading: true });
    case CardActions.RECEIVE_TERMS_OF_SERVICE:
      return _.set({ ...state }, `terms.${action.companyId}`, action.terms);
    case CardActions.REQUEST_RECOVERY_QUESTIONS:
      return _.set({ ...state }, `questions.${action.companyId}`, { loading: true });
    case CardActions.RECEIVE_RECOVERY_QUESTIONS:
      return _.set({ ...state }, `questions.${action.companyId}`, action.questions);
    case CardActions.RESETTING_BANK_ACCOUNT:
      return _.set({ ...state }, `status.${action.companyId}`, { loading: true });
    case CardActions.RESET_BANK_ACCOUNT:
      return _.set({ ...state }, `status.${action.companyId}`, {
        loading: false,
        status: CardAccountStatuses.BANKING
      });
    default:
      return state;
  }
}

import React from 'react';
import { ButtonProps } from './ButtonProps';
import { ButtonConfig } from './ButtonConfig';
import { Button } from '../Buttons';

export const PrimaryButton: React.FC<ButtonProps> = (props: ButtonProps): React.ReactElement => {
  const { children, ref, ...rest } = props;
  return (
    <Button variant={ButtonConfig.Variant} color="primary" {...rest}>
      {children}
    </Button>
  );
}

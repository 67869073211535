import React from 'react';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { fuelCardNumber, required } from '../../services/app/validators';
import { InvoiceDeclineReasons } from '../../constants/invoiceDeclineReasons';
import { connect } from 'react-redux';
import { Error } from '../ui/Error';
import { RenderTextField } from '../ui/Form';
import { Grid } from '@material-ui/core';
import { FuelCardChargeRequest } from '../../types/FuelCardChargeRequest';
import { formatNumbersOnly } from '../../services/app/formats';

export type FuelCardDetailsFormData =
  Pick<FuelCardChargeRequest, "token" | "driverNumber" | "unitNumber" | "odometer" | "tripNumber" | "poNumber" | "trailerNumber">;

type Props = InjectedFormProps<FuelCardDetailsFormData, OwnProps> & OwnProps;

interface OwnProps {
  children?: React.ReactNode;
  paymentErrorCode?: string;
}

export const fuelCardFieldLabelSubText = ' (as required by payer’s company)';

// eslint-disable-next-line max-lines-per-function,complexity,max-statements
const FuelCardDetailsForm: React.FC<Props> = (props: Props): React.ReactElement => {

  const { error, handleSubmit, children, paymentErrorCode } = props;
  const declineReason = paymentErrorCode

    ? InvoiceDeclineReasons.getByKey(paymentErrorCode)?.display || paymentErrorCode
    : undefined;

  return (
    <form onSubmit={handleSubmit}>
      <Error error={error} mb={2} />
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        {declineReason && <Grid item><Error error={`Decline reason: ${declineReason}`} /></Grid>}
        <Grid item>
          <Field
            name="token"
            id="fuel-card-number"
            label="Card Number"
            type="text"
            component={RenderTextField}
            parse={formatNumbersOnly}
            validate={[required, fuelCardNumber]}
          />
        </Grid>
        <Grid item>
          <Field name="driverNumber" id="driver-number" label={`Driver Number${fuelCardFieldLabelSubText}`} type="text" component={RenderTextField} />
        </Grid>
        <Grid item>
          <Field name="unitNumber" id="unit-number" label={`Unit Number${fuelCardFieldLabelSubText}`} type="text" component={RenderTextField} />
        </Grid>
        <Grid item>
          <Field name="odometer" id="odometer" label={`Odometer${fuelCardFieldLabelSubText}`} type="text" component={RenderTextField} />
        </Grid>
        <Grid item>
          <Field name="tripNumber" id="trip-number" label={`Trip Number${fuelCardFieldLabelSubText}`} type="text" component={RenderTextField} />
        </Grid>
        <Grid item>
          <Field name="poNumber" id="po-number" label={`PO Number${fuelCardFieldLabelSubText}`} type="text" component={RenderTextField} />
        </Grid>
        <Grid item>
          <Field name="trailerNumber" id="trailer-number" label={`Trailer Number${fuelCardFieldLabelSubText}`} type="text" component={RenderTextField} />
        </Grid>
        {children && <Grid item>{children}</Grid>}
      </Grid>
    </form>
  );
};

export const fuelCardDetailsFormName = 'fuelCardDetails';
export default connect()(reduxForm<FuelCardDetailsFormData, OwnProps>({ form: fuelCardDetailsFormName })(FuelCardDetailsForm));

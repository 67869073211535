import React from "react";
import { ButtonProps } from "./ButtonProps";
import { ButtonConfig } from "./ButtonConfig";
import { Button } from "./Button";

export const LargeButton: React.FC<ButtonProps> = (props: ButtonProps): React.ReactElement => {
  const { children, ref, ...rest } = props;
  return (
    <Button
      size="large"
      variant={ButtonConfig.Variant}
      {...rest}>{children}
    </Button>
  )
};

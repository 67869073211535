interface Type {
  key: string;
}

export class Attachment {
  static readonly SIGNATURE: Type = {
    key: 'signature',
  };
  static readonly PDF: Type = {
    key: 'pdf',
  };
}

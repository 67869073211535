import { InvoicePreparationActionType } from "../actions/invoice.preparation.actions"
import { InvoicePreparationState, InvoicePreparationAction, InvoicePreparationStep } from "../types/Invoice";

function InvoicePreparationReducer(state: InvoicePreparationState, action: InvoicePreparationAction): InvoicePreparationState {
  switch (action.type) {
    case InvoicePreparationActionType.INITIALIZE:
      const steps = action.content as Array<InvoicePreparationStep>;
      return {
        ...state,
        activeStep: steps[0],
        preparationSteps: steps
      };
    case InvoicePreparationActionType.SET_STEP:
      return {
        ...state,
        activeStep: action.content as InvoicePreparationStep,
      };
    default:
      return {
        ...state
      };
  }
}

export { InvoicePreparationReducer }

import React from 'react';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import Input from '../controls/Input';
import { emailOrPhoneNumber, required } from '../../services/app/validators';

export interface SendReceiptFormData {
  phoneOrEmail: string;
}

type Props = InjectedFormProps<SendReceiptFormData>;

const SendReceiptForm: React.FC<Props> = (props: Props) => {
  const { error, handleSubmit, anyTouched } = props;
  return (
    <form onSubmit={handleSubmit}>
      {anyTouched && error && <div className="validation-summary-errors">{error}</div>}

      <Field
        name="phoneOrEmail"
        label="Recipient Email or Phone Number"
        type="text"
        component={Input}
        validate={[required, emailOrPhoneNumber]}
      />
    </form>
  );
};

export default reduxForm<SendReceiptFormData>({ form: 'sendReceipt' })(SendReceiptForm);

import React from 'react';
import { Grid } from '@roadsync/roadsync-ui';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Loader from '../../../app/Loader';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: 'rgba(255,255,255,.3)',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    }
  }),
);

export const LoaderContent: React.FC<{}> = (): React.ReactElement => {
  const classes = useStyles();
  return (
    <Grid className={classes.root}>
      <Grid container item xs alignItems="center" spacing={2} direction="column" justifyContent="center">
        <Loader></Loader>
      </Grid>
    </Grid>
  );
}

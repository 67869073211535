import { CardReaderEndpoints } from './endpoints';
import * as http from './http';
import { CardReader, CardReaderInput } from '../../types/CardReader';

export function createCardReader(locationId: string, cardReaderInput: CardReaderInput): Promise<CardReader> {
  return http.postJSON(CardReaderEndpoints.createCardReader(locationId), cardReaderInput);
}

export function getCardReaders(
  locationId: string,
): Promise<CardReader[]> {
  return http.get(CardReaderEndpoints.getCardReaders(locationId));
}

export async function deleteCardReader(locationId: string, id: string): Promise<void> {
  return http.del(CardReaderEndpoints.deleteCardReader(locationId, id));
}

export async function cancelPayinDevice(locationId: string, id: string): Promise<void> {
  return http.post(CardReaderEndpoints.cancelPayinDevice(locationId, id));
}

export async function payinDevice(locationId: string, id: string, payinConfigId: string): Promise<void> {
  return http.postJSON(CardReaderEndpoints.payinDevice(locationId, id), { payinConfigId });
}

export async function simualteDevice(locationId: string, id: string, event: string, card: string): Promise<void> {
  return http.postJSON(CardReaderEndpoints.simulateDevice(locationId, id), { event, card });
}

import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { submit } from 'redux-form';
import { ModalsConstants } from '../../constants/modals';
import ModalContent from '../../components/modals/ModalContent';
import { closeModal } from '../../actions/modals';
import CopyLocationOrCreateForm, { CopyLocationOrCreateFormData } from '../../components/company/CopyLocationForm';
import { handleReduxFormError } from '../../services/app/forms';
import { Location } from '../../types/Location';

interface Props extends DispatchProp, OwnProps { }

interface OwnProps {
  text: string;
  getLocations: () => Location[];
  insertLocation: (locationId: string, file: any) => Promise<void>;
}

class CopyLocation extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalSubmit = this.handleModalSubmit.bind(this);
    this.handleModalRemove = this.handleModalRemove.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleModalClose(): void {
    const { dispatch } = this.props;
    dispatch(closeModal(ModalsConstants.COPY_LOCATION));
  }

  handleModalSubmit(): void {
    const { dispatch } = this.props;
    dispatch(submit('copyLocationForm'));
  }

  handleModalRemove(): void {
    const { dispatch } = this.props;
    dispatch(closeModal(ModalsConstants.COPY_LOCATION));
  }

  handleFormSubmit(values: CopyLocationOrCreateFormData): Promise<void> {
    const { insertLocation, dispatch } = this.props;
    // eslint-disable-next-line
    // @ts-ignore
    return insertLocation(values.locationId, values.file)
      .then(() => dispatch(closeModal(ModalsConstants.COPY_LOCATION)))
      .catch(handleReduxFormError);
  }

  render(): React.ReactElement {
    const { text, getLocations } = this.props;
    const locations = getLocations();
    return (
      <ModalContent
        className="copy-location"
        primaryActionText="Save"
        primaryActionClick={this.handleModalSubmit}
        secondaryActionText="Cancel"
        secondaryActionClick={this.handleModalRemove}
      >
        <p className="modal-message">{text}</p>
        <CopyLocationOrCreateForm locations={locations} onSubmit={this.handleFormSubmit} />
      </ModalContent>
    );
  }
}

export default connect()(CopyLocation);

import { Theme } from "@material-ui/core";

const styles = (theme: Theme): {} => ({
    root: {
        height: "100%",
        maxHeight: "100%",
        padding: 0,
        [theme.breakpoints.up('md')]: {
            overflow: "hidden",
        },
    },
    container: {
        [theme.breakpoints.up('md')]: {
            height: "100%",
        }
    },
    controlsColumn: {
        [theme.breakpoints.up('md')]: {
            height: "100%",
            overflow: "hidden",
            '& > [class*="MuiBox-root"]': {
                background: theme.palette.common.white,
                maxHeight: "100%",
                overflowY: "auto",
            },
        }
    },
    layoutButtonActive: {
        background: "white !important"
    },
    directPaymentAlertMobileOnly: {
        [theme.breakpoints.up(961)]: {
            display: 'none'
        }
    }
});

export default styles;

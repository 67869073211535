import React from "react";
import { connect } from "react-redux";
import { GlobalState } from "../../types/GlobalState";
import { getAppSettingsPublic } from "../../services/api/appSettings";
import { FeatureFlagProvider as Provider } from "@roadsync/roadsync-ui";
import { isEqual } from "lodash";

interface Props extends PropsFromState {
  children: React.ReactElement;
}

type PropsFromState = Pick<GlobalState, "auth">;

interface State {
  config?: SplitIO.IBrowserSettings;
}

class FeatureFlagProvider extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  async componentDidMount(): Promise<void> {
    const { auth } = this.props;
    const settings = await getAppSettingsPublic();
    const config = {
      core: {
        authorizationKey: settings.splitAuthorizationKey,
        key: auth?.me?.username ?? 'anonymous',
      },
    }
    this.setState({ config });
  }

  shouldComponentUpdate(nextProps, nextState): boolean {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState)
  }

  render(): React.ReactElement {
    const { children } = this.props;
    const { config } = this.state;
    return config ? <Provider config={config}>{children}</Provider> : children;
  }
}

/* istanbul ignore next */
const mapStateToProps = ({ auth }: GlobalState): PropsFromState => ({ auth });
export default connect(mapStateToProps)(FeatureFlagProvider);

import { ReportActions } from '../constants/actions';
import _ from 'lodash';
import { ReportSettings } from '../types/ReportSettings';

export interface ReportSettingsState {
  data: { [companyId: string]: ReportSettings };
}

interface ReportSettingsAction {
  type: string;
  companyId?: string;
  settings?: ReportSettings;
}

export function reportSettings(state: ReportSettingsState = { data: {} }, action?: ReportSettingsAction): ReportSettingsState {
  switch (action?.type) {
    case ReportActions.REQUEST_REPORT_SETTINGS:
      return _.set({ ...state }, `data.${action.companyId}`, { loading: true });
    case ReportActions.RECEIVE_REPORT_SETTINGS:
      return _.set({ ...state }, `data.${action.companyId}`, action.settings);
    default:
      return { ...state };
  }
}

import React from "react";
import { connect } from "react-redux";
import { ModalsConstants } from "../../constants/modals";
import ModalContent from "../../components/modals/ModalContent";
import { closeModal } from "../../actions/modals";
import { LargePrimaryButton } from "../../components/ui/Buttons";

interface Props {
    dispatch: Function;
    title: string;
    message: string;
    handleModalClose: Function;
}
class TowbookInvoiceErrorModal extends React.Component<Props> {

    closeModal(): void {
        const { dispatch, handleModalClose } = this.props;
        handleModalClose();
        dispatch(closeModal(ModalsConstants.TOWBOOK_INVOICE_ERROR));
    }
    // eslint-disable-next-line
    render(): React.ReactElement {
        const { title, message } = this.props;
        return (
            <ModalContent className="towbook-invoice-modal">
                <p style={{ fontSize: 24, color: "#000", fontWeight: "bold" }}>{title}</p>
                <p className="modal-message">{message}</p>
                <LargePrimaryButton
                    variant="outlined"
                    onClick={(): void => {
                        this.closeModal();
                    }}
                >
                    Try Again
                </LargePrimaryButton>
            </ModalContent>
        );
    }
}

export default connect()(TowbookInvoiceErrorModal);


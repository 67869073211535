import { Breadcrumb } from '../types/Breadcrumb';
import { BreadcrumbsActions } from '../constants/actions';

export interface BreadcrumbsState {
  data?: Breadcrumb[];
}

interface BreadcrumbsAction {
  type: string;
  breadcrumbs?: Breadcrumb[];
}

export function breadcrumbs(state: BreadcrumbsState = {}, action: BreadcrumbsAction = { type: "unknown" }): BreadcrumbsState {
  switch (action.type) {
    case BreadcrumbsActions.INITIALIZE:
      return { data: action.breadcrumbs };
    case BreadcrumbsActions.DESTROY:
      return {};
    default:
      return state;
  }
}

import React from "react";
import { FormControlLabel, Switch, SwitchProps } from "@material-ui/core";

interface Props {
  color?: "primary" | "secondary" | "default";
  input: Partial<SwitchProps>;
  label: string;
  name: string;
  disabled?: boolean;
  labelPlacement?: "end" | "start" | "top" | "bottom";
}

export const RenderSwitch: React.FC<Props> = (props: Props): React.ReactElement => {
  const { input, name, color, label, labelPlacement, ...rest } = props;
  return (
    <FormControlLabel
      labelPlacement={labelPlacement}
      control={
        <Switch
          checked={!!input.value}
          onChange={input.onChange}
          name={name}
          color={color || "primary"}
          {...rest}
        />
      }
      label={label}
    />
  );
}

import { AlertActions } from '../constants/actions';
import { AlertProps } from '../actions/alerts';

export interface AlertsState {
  data?: AlertProps[];
}

interface Action {
  type: string;
  alert?: AlertProps;
}

export function alerts(state: AlertsState, action: Action): AlertsState {
  const data = [...state?.data || []];
  const alert = action.alert;
  switch (action.type) {
    case AlertActions.ALERT_SHOW: {
      if (alert) data.push({ ...alert })
      break;
    }
    case AlertActions.ALERT_DISMISS: {
      data.shift();
      break;
    }
  }
  return { data };
}

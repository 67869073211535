import { Box, Divider, Grid, Step, StepLabel, Stepper } from "@material-ui/core";
import React, { useState } from "react";
import { Field, FieldArray, FormErrors, InjectedFormProps, getFormValues, reduxForm } from "redux-form";
import { required, usPhoneNumber, zipCode } from "../../services/app/validators";
import { RenderTextField } from "../ui/Form";
import { useSelector } from "react-redux";
import RenderContactFields from "./AddressBookContactField";
import ModalContent from "../modals/ModalContent";
import AddressBookTitle from "./AddressBookTitle";
import { createAddressBook } from "../../services/api/addressBook";
import { AddressBookConfirmationModal } from "./AddressBookConfirmationModal";
import { useDispatch } from "react-redux";
import { closeModal } from "../../actions/modals";
import { ModalsConstants } from "../../constants/modals";
import { User } from "../../types/User";
import { displayFormattedUsPhoneNumber } from "../../services/app/formats";
import useStyles from "./AddressBookContactForm.css";
import { LargePrimaryButton, LargeSecondaryButton } from "@roadsync/roadsync-ui";

export const enum StepProgress {
    CompanyDetails,
    ContactDetails,
    ConfirmDetails,
    CreatePayerProgress,
    ConfirmQuit,
}

export interface Contact {
    name?: string;
    email?: string;
    phone?: string;
    contactId?: string;
    isMain: boolean;
}

export interface AddressBookCompanyInput {
    name?: string;
    mcNumber?: string;
    dotNumber?: string;
    phone?: string;
    taxId?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    postalCode?: string;
    contacts?: Contact[];
}

interface OwnProps {
    me?: User;
    fetchData: () => Promise<void>;
}

type InjectedProps = InjectedFormProps<AddressBookCompanyInput, OwnProps>;

export const AddressBookContactForm: React.FC<InjectedProps & OwnProps> = (props: InjectedProps & OwnProps) => {
    const classes = useStyles();
    const { valid, reset, handleSubmit, me, fetchData } = props;
    const steps = ["Company", "Contacts", "Confirm"];
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(false);
    const [currentStep, setCurrentStep] = useState(StepProgress.CompanyDetails);
    const [previousStep, setPreviousStep] = useState(StepProgress.CompanyDetails);
    const formValues: AddressBookCompanyInput = useSelector((state) => getFormValues("addressBookContact")(state));
    const dispatch = useDispatch();

    const savePayerDetails = () => {
        setCurrentStep(currentStep + 1);
    };

    const goToPreviousStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const submit = async (values: AddressBookCompanyInput) => {
        setError(false);
        if (values.contacts) {
            values.contacts.forEach((contact, index) => {
                contact.isMain = index === 0;
            });
        }
        setCurrentStep(StepProgress.CreatePayerProgress);

        try {
            setIsLoading(true);
            if (me?.companyId) {
                await createAddressBook(me.companyId, values);
                await fetchData();
            }
        } catch (e) {
            setError(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box className={classes.modalMaxWidth}>
            <ModalContent>
                {currentStep !== StepProgress.CreatePayerProgress && currentStep !== StepProgress.ConfirmQuit && (
                    <AddressBookTitle activeStep={currentStep} closeModal={setCurrentStep} setPreviousStep={setPreviousStep} />
                )}

                {currentStep !== StepProgress.CreatePayerProgress && currentStep !== StepProgress.ConfirmQuit && (
                    <Stepper activeStep={currentStep}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel
                                    classes={{ label: classes.label }}
                                    StepIconProps={{
                                        classes: {
                                            root: classes.icon,
                                            completed: classes.icon,
                                            text: classes.whiteTextColor,
                                        },
                                    }}
                                >
                                    {label}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                )}

                <form onSubmit={handleSubmit(submit)}>
                    {(() => {
                        switch (currentStep) {
                            case StepProgress.CompanyDetails:
                                return (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Field
                                                name="name"
                                                label="Company Name"
                                                type="text"
                                                component={RenderTextField}
                                                validate={required}
                                                id="name"
                                                data-testid="name"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Field id="mcNumber" name="mcNumber" label="MC Number" type="text" component={RenderTextField} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Field name="dotNumber" label="DOT Number" type="text" component={RenderTextField} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Field
                                                name="phone"
                                                label="Phone Number"
                                                type="number"
                                                component={RenderTextField}
                                                validate={usPhoneNumber}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Field name="taxId" label="Tax ID" type="text" component={RenderTextField} />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Field name="address1" label="Address 1" type="text" component={RenderTextField} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Field name="address2" label="Address 2" type="text" component={RenderTextField} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Field name="city" label="City" type="text" component={RenderTextField} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Field name="state" label="State" type="text" component={RenderTextField} />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Field name="postalCode" label="Postal Code" type="text" component={RenderTextField} validate={zipCode} />
                                        </Grid>
                                    </Grid>
                                );

                            case StepProgress.ContactDetails:
                                return <FieldArray name="contacts" component={RenderContactFields} />;
                            case StepProgress.ConfirmDetails:
                                return (
                                    <>
                                        <Box className={classes.confirmationFieldsText} textAlign="left">
                                            <Box className={`${classes.confirmDetailsTitle} ${classes.companyTitle}`}>Company</Box>
                                            <Box>{formValues.name}</Box>
                                            <Box>
                                                {formValues.mcNumber && <span> MC {formValues.mcNumber}</span>}
                                                {formValues.dotNumber && <span> DOT {formValues.dotNumber}</span>}
                                            </Box>

                                            <Box>
                                                {formValues.phone && <Box>Phone Number: +1 {displayFormattedUsPhoneNumber(formValues.phone)}</Box>}
                                            </Box>
                                            <Box> {formValues.taxId && <Box>Tax ID: {formValues.taxId} </Box>}</Box>
                                            <Box>
                                                {(formValues.address1 ||
                                                    formValues.address2 ||
                                                    formValues.city ||
                                                    formValues.state ||
                                                    formValues.postalCode) && (
                                                        <Box>
                                                            Address: {formValues.address1}
                                                            {formValues.address2 && " " + formValues.address2}
                                                        </Box>
                                                    )}
                                            </Box>

                                            <Box className={classes.addressText}>
                                                {formValues.city && formValues.city + ","} {formValues.state && formValues.state + ", "}
                                                {formValues.postalCode}
                                            </Box>
                                            <Divider className={classes.divider} />
                                            <Box className={classes.confirmDetailsTitle}>Contacts</Box>
                                            {formValues.contacts?.map((contact, i) => (
                                                <Box key={i} className={`${classes.confirmationFieldsText} ${classes.contentMarginTop}`}>
                                                    <Box> {contact.name}</Box>
                                                    {contact.email && <Box> Email: {contact.email}</Box>}
                                                    {contact.phone && <Box> Phone Number: +1 {displayFormattedUsPhoneNumber(contact.phone)}</Box>}
                                                    {contact.contactId && <Box> Contact ID: {contact.contactId}</Box>}
                                                </Box>
                                            ))}
                                        </Box>
                                    </>
                                );
                            case StepProgress.CreatePayerProgress:
                                return <AddressBookConfirmationModal isLoading={isLoading} error={error} />;
                            case StepProgress.ConfirmQuit:
                                return (
                                    <>
                                        <Grid container spacing={2} justifyContent="center">
                                            <Box className={classes.quitConfirmationModalText}>Are you sure you want to quit?</Box>
                                            <Grid item xs={6}>
                                                <button
                                                    id="yes-close-modal"
                                                    data-testid="yes-close-modal"
                                                    className={"btn btn-white secondary-btn"}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        dispatch(closeModal(ModalsConstants.ADDRESS_BOOK_ADD_NEW_PAYER));
                                                    }}
                                                >
                                                    YES
                                                </button>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <button
                                                    id="no-close-modal"
                                                    data-testid="no-close-modal"
                                                    className={"btn btn-white secondary-btn"}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setCurrentStep(previousStep);
                                                    }}
                                                >
                                                    NO
                                                </button>
                                            </Grid>
                                        </Grid>
                                    </>
                                );
                            default:
                                return <></>;
                        }
                    })()}
                    <Grid container spacing={2} className={classes.navigationButtons}>
                        {currentStep === 0 && (
                            <>
                                <Grid item xs={6}>
                                    <LargeSecondaryButton
                                        variant="outlined"
                                        fullWidth={true}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            reset();
                                        }}
                                    >
                                        CLEAR INFO
                                    </LargeSecondaryButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <LargePrimaryButton
                                        id="companyDetailsSaveButton"
                                        fullWidth={true}
                                        data-testid="companyDetailsSaveButton"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            savePayerDetails();
                                        }}
                                        disabled={!valid}
                                    >
                                        SAVE & CONTINUE
                                    </LargePrimaryButton>
                                </Grid>
                            </>
                        )}

                        {currentStep === 1 && (
                            <>
                                <Grid item xs={6}>
                                    <LargeSecondaryButton variant="outlined" fullWidth={true} onClick={goToPreviousStep}>
                                        GO BACK
                                    </LargeSecondaryButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <LargePrimaryButton
                                        id="contactDetailsSaveButton"
                                        fullWidth={true}
                                        data-testid="contactDetailsSaveButton"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            savePayerDetails();
                                        }}
                                        disabled={!valid}
                                    >
                                        SAVE & CONTINUE
                                    </LargePrimaryButton>
                                </Grid>
                            </>
                        )}

                        {currentStep === 2 && !isLoading && (
                            <Grid container spacing={2} className={classes.contentMarginTop}>
                                <Grid item xs={6}>
                                    <LargeSecondaryButton variant="outlined" fullWidth={true} onClick={goToPreviousStep}>
                                        GO BACK
                                    </LargeSecondaryButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <LargePrimaryButton fullWidth={true} type="submit" data-testid='confirmBtn'>
                                        CONFIRM
                                    </LargePrimaryButton>
                                </Grid>
                            </Grid>
                        )}
                        {currentStep === 3 && !isLoading && (
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <LargeSecondaryButton
                                        variant="outlined"
                                        fullWidth={true}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            dispatch(closeModal(ModalsConstants.ADDRESS_BOOK_ADD_NEW_PAYER));
                                        }}
                                    >
                                        CLOSE
                                    </LargeSecondaryButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <LargePrimaryButton
                                        fullWidth={true}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            if (error) {
                                                setCurrentStep(2);
                                                return;
                                            }
                                            reset();
                                            setCurrentStep(0);
                                        }}
                                    >
                                        {error ? "TRY AGAIN" : "ADD NEW PAYER"}
                                    </LargePrimaryButton>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </form>
            </ModalContent>
        </Box>
    );
};

export const validate = (values: AddressBookCompanyInput): FormErrors<AddressBookCompanyInput> => {
    const errors = {};

    values.contacts?.forEach((contact) => {
        let err;
        if (contact.email === undefined && contact.phone === undefined) {
            err = { email: "Enter at least one contact method", phone: "Enter at least one contact method" };
        }

        if (!Array.isArray(errors["contacts"])) {
            errors["contacts"] = [];
        }

        errors["contacts"] = [...errors["contacts"], err];
    });

    return errors;
};

export default reduxForm<AddressBookCompanyInput, OwnProps>({
    form: "addressBookContact",
    validate,
    shouldValidate: () => true,
})(AddressBookContactForm);

import React from "react";
import { WithInvoiceProps } from "../WithInvoiceProps";
import { WithChildrenProps } from "../WithChildrenProps";
import { connect } from "react-redux";
import { Attachment } from "../../../constants/attachment";

interface Props extends WithInvoiceProps, WithChildrenProps, PropsFromState { }

interface PropsFromState {
  files?: { [k: string]: any };
}

const IfInvoiceHasAttachments: React.FC<Props> = (props: Props): React.ReactElement => {
  const { children, invoice, files } = props;
  const attachments = (invoice?.files as any || []).filter(id => files?.data?.[id]?.type !== Attachment.SIGNATURE.key);
  return <React.Fragment>
    {!!attachments?.length && children}
  </React.Fragment>
}

const mapStateToProps: (state: any) => PropsFromState = ({ files }) => ({ files });
export default connect(mapStateToProps)(IfInvoiceHasAttachments);

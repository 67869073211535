import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import ModalContent from '../../components/modals/ModalContent';
import { closeModal } from '../../actions/modals';
import { ModalsConstants } from '../../constants/modals';
import { isEmail } from '../../services/api/utils';
import { getWorkOrderTemplate } from '../../services/api/workOrder';
import { createWorkOrder, updateWorkOrder } from '../../actions/workOrders';
import { WorkOrderEditFormData } from '../../components/workOrder/WorkOrderEditForm';
import { LargeButton, LargePrimaryButton } from '../../components/ui/Buttons';
import { H3, Grid } from '@roadsync/roadsync-ui';
import { showSuccessAlert } from '../../actions/alerts';
import { Error } from '../../components/ui/Error';
import { WorkOrder } from '../../types/WorkOrder';

interface Props extends OwnProps, DispatchProp { }

interface OwnProps {
  companyId: string;
  values: WorkOrderEditFormData & { id?: string };
}

interface State {
  error?: string;
}

class WorkOrderDismissModal extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.closeModal = this.closeModal.bind(this);
    this.handleSaveWorkOrder = this.handleSaveWorkOrder.bind(this);
  }

  // eslint-disable-next-line max-lines-per-function,max-statements
  async handleSaveWorkOrder(): Promise<void> {

    const { dispatch, companyId, values } = this.props;
    const workOrder: Partial<WorkOrder> = {
      id: values.id,
      reference: values.reference,
      signedCarrierName: values.signedCarrierName,
      signedDriverName: values.signedDriverName,
      signedDriverEmail: isEmail(values.phoneOrEmail) ? values.phoneOrEmail : undefined,
      signedDriverPhone: isEmail(values.phoneOrEmail) ? undefined : values.phoneOrEmail,
      signedCustomFields: {},
      workOrderSettingsId: values.workOrderSettingsId,
      locationId: values.locationId,
    };

    if (workOrder.workOrderSettingsId) {
      const template = await getWorkOrderTemplate(workOrder.workOrderSettingsId);
      (template.customFields || [])?.map(f => f.name).forEach(fieldName => {
        workOrder.signedCustomFields = workOrder.signedCustomFields || {};
        workOrder.signedCustomFields[fieldName] = values[fieldName]
      });
    }

    try {
      await dispatch<any>(values.id ? updateWorkOrder(workOrder) : createWorkOrder(workOrder as any, companyId));
      this.closeModal();
      dispatch<any>(showSuccessAlert(`Work order saved successfully`));
    } catch (e) {
      this.setState({ error: `Error saving the work order: ${(e as any).message}` })
    }
  }

  closeModal(): void {
    const { dispatch } = this.props;
    dispatch(closeModal(ModalsConstants.HANDLE_WORK_ORDER_DISMISS));
  }

  // eslint-disable-next-line
  render(): React.ReactElement {
    const { values } = this.props;
    const { error } = this.state;
    return (
      <ModalContent className="work-order-dismiss-modal">
        <Grid container direction="column" wrap="nowrap" spacing={4}>
          <Grid item>
            <H3 gutterBottom>What do you want to do?</H3>
          </Grid>
          {error && <Grid item><Error error={error} /></Grid>}
          <Grid item>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <LargePrimaryButton onClick={this.handleSaveWorkOrder}>
                  Save Work Order
                </LargePrimaryButton>
              </Grid>
              <Grid item>
                <LargeButton variant="outlined" onClick={this.closeModal}>
                  {values.id ? 'Discard Changes' : 'Discard Work Order'}
                </LargeButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ModalContent>
    );
  }
}

export default connect()(WorkOrderDismissModal);

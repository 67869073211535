import React from 'react';
import { Box } from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import useStyles from './ReadyForCard.css';

const ReadyForCard: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container} id='ready-for-card' data-testid='ready-for-card'>
      <Box className={classes.circleWrapper}>
        <CreditCardIcon className={classes.icon} />
        <Box className={classes.circle} />
        <Box className={`${classes.circle} ${classes.circle2}`} />
        <Box className={`${classes.circle} ${classes.circle3}`} />
      </Box>
    </Box>
  );
};

export default ReadyForCard;
import React from "react";
import { Location } from 'history';
import { WithInvoiceProps } from "../WithInvoiceProps";
import { WithChildrenProps } from "../WithChildrenProps";
import { canEditInvoice } from "../../../services/app/invoice";
import IfUserRole from "./IfUserRole";
import { Roles } from "../../../constants/roles";
import IfInvoiceStatus from "./IfInvoiceStatus";
import { InvoiceStatuses } from "../../../constants/invoice";
import { isCompanyAdminSection } from "../../../services/app/url";

interface Props extends WithInvoiceProps, WithChildrenProps {
    location: Location;
}

const IfCanEditInvoice: React.FC<Props> = (props: Props): React.ReactElement => {
    const { children, invoice, location } = props;
    const companyAdminSection = isCompanyAdminSection(location);
    return (
        <React.Fragment>
            {!companyAdminSection &&
                <IfUserRole includes={[Roles.SUPER_ADMIN, Roles.COMPANY_ADMIN, Roles.ACCOUNTANT, Roles.EMPLOYEE, Roles.RSEMPLOYEE, Roles.RS_IMPLEMENTATION, Roles.CLIENT_SUPPORT]}>
                    {canEditInvoice(invoice) && children}
                </IfUserRole>
            }
            {companyAdminSection &&
                <IfUserRole includes={[Roles.SUPER_ADMIN, Roles.RSEMPLOYEE, Roles.CLIENT_SUPPORT]}>
                    {canEditInvoice(invoice) && children}
                </IfUserRole>
            }
            <IfInvoiceStatus invoice={invoice} statuses={[InvoiceStatuses.COMPLETED]}>
                {!companyAdminSection &&
                    <IfUserRole includes={[Roles.SUPER_ADMIN, Roles.RSEMPLOYEE, Roles.RS_IMPLEMENTATION]}>
                        {children}
                    </IfUserRole>
                }
            </IfInvoiceStatus>
        </React.Fragment>
    );
}

export default IfCanEditInvoice;

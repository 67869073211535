export enum Environment {
  Local = 'local',
  QA = 'qa',
  Demo = 'demo',
  Staging = 'staging',
  Production = 'production',
}

export enum CardAppEnvironment {
  AppEnvironmentProduction = "LIVE",
  AppEnvironmentTest = "TEST"
}

import { BankActions } from '../constants/actions';
import _ from 'lodash';

export interface BeneficialOwnerState {
  data: { [companyId: string]: any };
}

interface BeneficialOwnerAction {
  type: string;
  companyId?: string;
  owners?: any;
}

export function beneficialOwner(state = { data: {} }, action: BeneficialOwnerAction = { type: "unknown" }): BeneficialOwnerState {
  switch (action.type) {
    case BankActions.REQUEST_BENEFICIAL_OWNERS:
      return _.set({ ...state }, `data.${action.companyId}`, { loading: true });
    case BankActions.RECEIVE_BENEFICIAL_OWNERS:
      return _.set({ ...state }, `data.${action.companyId}`, action.owners);
    default:
      return state;
  }
}

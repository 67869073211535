import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
    createStyles({
        closeIcon: {
            textAlign: "end",
        },
    })
);

export default useStyles;

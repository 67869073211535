import * as api from '../services/api/workOrder';
import { WorkOrderTemplatesActions } from '../constants/actions';
import { openFile } from '../services/api/utils';
import { WorkOrderTemplate } from '../types/WorkOrderTemplate';
import { Dispatch } from 'react';

interface TemplateAction {
  type: string;
  templateId: string;
}

interface TemplatesAction {
  type: string;
  locationId: string;
}

const requestWorkOrderTemplates = (locationId: string): TemplatesAction => ({
  type: WorkOrderTemplatesActions.REQUEST_WORK_ORDER_TEMPLATES, locationId,
});

const requestWorkOrderTemplate = (templateId: string): TemplateAction => ({
  type: WorkOrderTemplatesActions.REQUEST_WORK_ORDER_TEMPLATE, templateId,
});

const receiveWorkOrderTemplates = (locationId: string, templates): TemplatesAction & { templates: { data: WorkOrderTemplate[] } } => ({
  type: WorkOrderTemplatesActions.RECEIVE_WORK_ORDER_TEMPLATES, locationId, templates,
});

const receiveWorkOrderTemplate = (templateId: string, template: WorkOrderTemplate): TemplateAction & { template: WorkOrderTemplate } => ({
  type: WorkOrderTemplatesActions.RECEIVE_WORK_ORDER_TEMPLATE, templateId, template,
});

export function getWorkOrderTemplates(locationId: string) {
  return (dispatch: Function) => {
    dispatch(requestWorkOrderTemplates(locationId));
    return api.getWorkOrderTemplates(locationId)
      .then(settings => dispatch(receiveWorkOrderTemplates(locationId, settings)))
      .catch(error => dispatch(receiveWorkOrderTemplates(locationId, {})));
  };
}

export function getWorkOrderTemplate(templateId: string) {
  return (dispatch: Function) => {
    dispatch(requestWorkOrderTemplate(templateId));
    return api.getWorkOrderTemplate(templateId)
      .then(template => dispatch(receiveWorkOrderTemplate(templateId, template)))
      .catch(error => dispatch(receiveWorkOrderTemplate(templateId, {} as any)))
  }
}

export function getWorkOrderLocationPdfPreview(
  locationId: string, description: string
): (d: Dispatch<any>) => void {
  return openFile(api.getWorkOrderLocationPdfPreview(locationId, description));
}

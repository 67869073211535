export const dateToDayOfTheWeek = date => {
  const dayOfWeek = new Date(date).getDay();
  return isNaN(dayOfWeek) ? '' :
    ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayOfWeek];
};

export const dateToYearMonth = (date?: string | Date): string => {

  if (!date) return '';

  let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      year = d.getFullYear();

  if (month.length < 2) month = '0' + month;

  return [year, month].join('');
}

export const getFirstDayOfMonth = (year: number, month: number): Date => {
  return new Date(year, month, 1);
}

export class CheckEndpoints {
  // eslint-disable-next-line max-params
  static getCheckList = (offset: number, limit: number, includeVoided = false, search?: string): string =>
    `/api/v1/deposits/check?offset=${offset}&limit=${limit}&includevoided=${includeVoided}${search ? '&search=' +
      search : ''}`
  static getCheckDepositsList = (offset: number, limit: number, search?: string): string =>
    `/api/v1/processing/entries?offset=${offset}&limit=${limit}${search ? '&search=' + search : ''}`
  static getCheckInfoById = (checkId: string): string => `/api/v1/processing/entries/${checkId}`;
  static insertCheckNumbers = (): string => `/api/v1/numbers/insert`;
  static getAvailableChecks = (): string => `/api/v1/numbers/stats`;
}

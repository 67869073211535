import _ from 'lodash';
import { PaymentMethods } from './invoice';
import { Deposit } from '../types/Deposit';

export interface CheckType {
  key: string;
  type?: string;
  checkName: string;
  display: string;
  method: string;
  cardName?: string;
}

export class CheckTypes {
  static readonly COMCHEK: CheckType = {
    key: 'comchek',
    display: 'Comchek',
    checkName: 'Comchek',
    cardName: 'Comdata Card',
    method: PaymentMethods.CHECK.key,
  };

  static readonly COMCHEKV2: CheckType = {
    key: 'comdataexpresscode',
    display: 'Comchek',
    checkName: 'Comchek',
    cardName: 'Comdata Card',
    method: PaymentMethods.CHECK.key,
  };

  static readonly COMDATA_EXPRESS_CODE: CheckType = {
    key: 'comdataexpresscode',
    display: 'COMDATA EXPRESS CODE',
    checkName: 'Comchek',
    cardName: 'Comdata Card',
    method: PaymentMethods.CHECK.key,
  };

  static readonly EFS: CheckType = {
    key: 'efs',
    display: 'EFS',
    checkName: 'EFS Check',
    cardName: 'EFS Card',
    method: PaymentMethods.CHECK.key,
  };

  static readonly TCHECK: CheckType = {
    key: 'tchek',
    display: 'TChek',
    checkName: 'TChek',
    method: PaymentMethods.CHECK.key,
  };

  static readonly FLEET_ONE: CheckType = {
    key: 'fleetone',
    display: 'Fleet One',
    checkName: 'Fleet One Plus Check',
    method: PaymentMethods.CHECK.key,
  };

  static readonly INSTAMONEY: CheckType = {
    key: 'instamoney',
    display: 'Instamoney',
    checkName: 'Instamoney Check',
    method: PaymentMethods.CHECK.key,
  };

  static readonly USBANK: CheckType = {
    key: 'usbank',
    display: 'US Bank',
    checkName: 'US Bank Check',
    method: PaymentMethods.CHECK.key,
  };

  private static readonly LOOKUP = {
    'comchek': CheckTypes.COMCHEK,
    'efs': CheckTypes.EFS,
    'tchek': CheckTypes.TCHECK,
    'fleetone': CheckTypes.FLEET_ONE,
    'instamoney': CheckTypes.INSTAMONEY,
    'usbank': CheckTypes.USBANK,
    'comdataexpresscode': CheckTypes.COMCHEKV2
  };

  static getTypes(): CheckType[] {
    return _.values(CheckTypes.LOOKUP);
  }

  static getByKey(key: string): CheckType {
    return CheckTypes.LOOKUP[key];
  }

  static includes(key: string): boolean {
    return CheckTypes.LOOKUP[key] !== undefined;
  }

}

export class DepositStatuses {
  static readonly CREATED: string = 'created';
  static readonly PROCESSING: string = 'processing';
  static readonly AUTHORIZED: string = 'authorized';
  static readonly DECLINED: string = 'declined';
}

export function getDepositCheckName(deposit?: Deposit): string | undefined {
  return deposit?.checkType && CheckTypes.getByKey(deposit.checkType)?.checkName;
}

import _ from 'lodash';

interface State {
  key: string;
  display: string;
}

export class CheckboxState {
  static readonly UNCHECKED: State = {
    key: 'false',
    display: 'No',
  };

  static readonly CHECKED: State = {
    key: 'true',
    display: 'Yes',
  };

  private static readonly LOOKUP = {
    'false': CheckboxState.UNCHECKED,
    'true': CheckboxState.CHECKED,
  };

  static getStates(): State[] {
    return _.values(CheckboxState.LOOKUP);
  }

  static getByKey(key: string): State {
    return CheckboxState.LOOKUP[key];
  }
}

import React from 'react';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { comdataCheckNumberValidator, required, driversLicenseNumberValidator } from '../../services/app/validators';
import { connect } from 'react-redux';
import { Error } from '../ui/Error';
import { Grid } from '@roadsync/roadsync-ui';
import { RenderTextField } from '../ui/Form';
import { RenderSelectField } from "../ui/form/RenderSelectField";
import { States } from "../../constants/states";
import { MenuItem } from "@material-ui/core";
import SignatureBox from "../ui/SignatureBox";

export interface CheckDetailsFormComdataFallbackData {
    payerIdentifier?: string;
    payerState?: string;
    expressCode?: string;
}

type Props = InjectedFormProps<CheckDetailsFormComdataFallbackData, OwnProps> & OwnProps;

interface OwnProps {
    initialValues?: Partial<CheckDetailsFormComdataFallbackData>;
    signatureEnabled?: boolean;
    signatureUrl?: string;
    openAddSignatureModal?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

class CheckDetailsFormComdataFallback extends React.Component<Props> {
    render(): React.ReactElement {
        const { error, handleSubmit, signatureEnabled, signatureUrl, openAddSignatureModal } = this.props;
        return (
            <form onSubmit={handleSubmit}>
                <Grid container direction="column" wrap="nowrap" spacing={2}>
                    {error && <Grid item><Error error={error} /></Grid>}
                    <Grid item>
                        <Field
                            id="expresscode"
                            name="expressCode"
                            label="Express Code" type="text"
                            component={RenderTextField}
                            validate={comdataCheckNumberValidator}
                        />
                    </Grid>
                    <Grid item>
                        <Field
                            name="payerIdentifier"
                            label="Driver license"
                            type="text"
                            component={RenderTextField}
                            validate={driversLicenseNumberValidator}
                            required
                        />
                    </Grid>
                    <Grid item>
                        <Field
                            name="payerState"
                            label="Driver license State"
                            type="text"
                            component={RenderSelectField}
                            validate={required}
                            required
                        >
                            {States.map((value) =>
                                <MenuItem key={value.Code} value={value.Code}>{value.StateName}</MenuItem>)
                            }
                        </Field>
                    </Grid>
                    {signatureEnabled &&
                        <Grid item>
                            <SignatureBox onClick={openAddSignatureModal} signatureUrl={signatureUrl} />
                        </Grid>
                    }
                </Grid>
            </form>
        );
    }
}

const form = 'checkDetailsComdataFallback';
export default connect()(
    reduxForm<CheckDetailsFormComdataFallbackData, OwnProps>({ form })(CheckDetailsFormComdataFallback)
);

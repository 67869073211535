import React from "react";
import { connect, DispatchProp } from "react-redux";
import { showSurveyAlert } from "../../actions/alerts";
import { getCompany } from "../../actions/companies";
import { Company } from "../../types/Company";
import { GlobalState } from "../../types/GlobalState";
import { Survey } from "./Survey";
import { getCurrentSurvey, getSurveysByUserId } from "./SurveyAPI";

type PropsFromState = Pick<GlobalState, "auth" | "companies">;

interface OwnProps {
  children?: React.ReactNode;
}

interface Props extends OwnProps, PropsFromState, DispatchProp {
}

class SurveyDisplayProvider extends React.Component<Props> {

  async componentDidMount(): Promise<void> {
    const { dispatch, auth: { me } } = this.props;
    if (me.companyId && !this.getCompany()) {
      await dispatch<any>(getCompany(me.companyId));
    }
    this.displaySurvey();
  }

  getCompany(): Company | undefined {
    const { auth: { me }, companies } = this.props;
    return me.companyId ? companies?.data?.[me.companyId] : undefined;
  }

  async displaySurvey(): Promise<void> {
    const { auth: { me }, dispatch } = this.props;
    const survey = await getCurrentSurvey();
    const company = this.getCompany();
    const showSurvey =
      survey?.enabled
      && survey?.userRoles?.some((role: string) => me.roles.indexOf(role) > -1)
      && survey?.companyTypes?.some((type: string) => type === company?.type);

    if (!showSurvey) {
      return;
    }

    const answers = await getSurveysByUserId(me.id);
    if (!answers?.answered && !answers?.ignored) {
      dispatch<any>(showSurveyAlert(survey as Survey, me.id));
    }
  }

  render(): React.ReactElement {
    const { children } = this.props;
    return <>{children}</>;
  }
}

const mapStateToProps: (state: PropsFromState) => PropsFromState = ({ auth, companies }) => ({ auth, companies });
export default connect(mapStateToProps)(SurveyDisplayProvider);

import React from "react";
import { Box } from "@material-ui/core";

interface Props {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel: React.FC<Props> = (props: Props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </div>
  );
}

export default TabPanel;

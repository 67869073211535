import { schema } from 'normalizr';

export const departmentSchema = new schema.Entity('departments');

export const shiftSchema = new schema.Entity('shifts');

export const feeSchema = new schema.Entity('fees');

export const featureSchema = new schema.Entity('features');

export const companySchema = new schema.Entity('companies');

export const labelsSchema = new schema.Entity('labels');

export const userSchema = new schema.Entity('users', {
    company: companySchema,
});

export const productSchema = new schema.Entity('products', {
    company: companySchema,
});

export const locationSchema = new schema.Entity('locations', {
    company: companySchema,
    products: [productSchema],
    departments: [departmentSchema],
    shifts: [shiftSchema],
});

export const fileSchema = new schema.Entity('files', {}, { 'idAttribute': 'invoiceFileId' });

export const invoiceSchema = new schema.Entity('invoices', {
    company: companySchema,
    location: locationSchema,
    user: userSchema,
    department: departmentSchema,
    shift: shiftSchema,
    files: [fileSchema],
});

export const payBillsSchema = new schema.Entity('payBills', {
    location: locationSchema,
    user: userSchema,
    department: departmentSchema,
    shift: shiftSchema,
    files: [fileSchema],
});

export const logSchema = new schema.Entity('logs');

export const emailsLogsSchema = new schema.Entity('emailsLogs');

export const checkSchema = new schema.Entity('checks');

export const checkDepositSchema = new schema.Entity('checks', {
    invoice: invoiceSchema,
});

export const appSettingsSchema = new schema.Object({
    accessibleCompanies: [companySchema],
    accessibleLocations: [locationSchema],
    labels: [labelsSchema],
    company: companySchema,
});

export const reportRecipientSchema = new schema.Entity('reportRecipients');

export const workOrdersSchema = new schema.Entity('workOrders');

export const companySettingsSchema = new schema.Entity('companySettings', {}, { 'idAttribute': 'companyId' });

export const carrierSchema = new schema.Entity('carriers');

export const universalCarrierSchema = new schema.Entity('universalCarriers');

export const evidenceSchema = new schema.Entity('evidences');

export const twoFactorSchema = new schema.Entity('twoFactorTokens');

export const surveysSchema = new schema.Entity('surveys');

export const accountingSettingsSchema = new schema.Entity('accountingSettings', {}, { 'idAttribute': 'companyId' });

export const accountingProductsSchema = new schema.Entity('accountingProductsList', {}, { 'idAttribute': 'companyId' });

export const companyBalanceSchema = new schema.Entity('companyBalances', {}, { 'idAttribute': 'companyId' });

export const disbursementSchema = new schema.Entity('disbursements');

export const transferSchema = new schema.Entity('transfers');

import React from 'react';
import { Card, CardContent, CircularProgress, Grid, } from '@material-ui/core';
import { H4, P } from '@roadsync/roadsync-ui';


interface CreditCardProcessingModalProps {
    show?: boolean;
    id?: string;
}

export const CreditCardProcessingModal: React.FC<CreditCardProcessingModalProps> = (props: CreditCardProcessingModalProps) => {
    return (
        props.show
            ? <div id={props.id} style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 999, display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgba(255,255,255,.5)' }}>
                <Card style={{ maxWidth: 400 }}>
                    <CardContent>
                        <Grid container direction='column' alignItems='center' spacing={2}>
                            <Grid item>
                                <CircularProgress size={128} />
                            </Grid>
                            <Grid item>
                                <H4 align='center'>Processing Payment</H4>
                                <P align='center'>To prevent duplicate charges, please wait up to 30 seconds for the process to complete.</P>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
            : <></>
    );
}
import { AuthActions } from '../constants/actions';
import * as authService from '../services/api/auth';
import { User } from '../types/User';
import { initializeSocketConnection, abandonSocketConnection } from '../services/app/sockets';
import { getAppSettings } from './appSettings';
import { homeUrl } from '../services/app/paths';
import { updateSelectedMenuItem } from './selectedMenuItem';
import { PayerEntity } from '../types/PayerEntity';
import { Log } from '../services/LoggerService';
import * as storage from '../services/app/storage';
import { now } from 'moment';

export function receiveLogin(result: User) {
  return {
    type: AuthActions.RECEIVE_LOGIN,
    result,
  };
}

function receiveAuthCheck(result: User) {
  return {
    type: AuthActions.RECEIVE_AUTH_CHECK,
    result,
  };
}

function requestAuthCheck() {
  return {
    type: AuthActions.REQUEST_AUTH_CHECK,
  };
}

function requestLogout() {
  return {
    type: AuthActions.REQUEST_LOGOUT,
  };
}

function requestSignup(result: any) {
  return {
    type: AuthActions.REQUEST_SIGNUP,
    result,
  };
}

function receiveSignup() {
  return {
    type: AuthActions.RECEIVE_SIGNUP,
  };
}

export function login(username: string, password: string, code?: string) {
  return (dispatch: Function) => {
    return authService
      .login(username, password, code)
      .then((result: User) => {
        dispatch(receiveLogin(result));
        storage.setItem('accessToken', result.accessToken);
        storage.setItem('refreshToken', result.refreshToken);
        storage.setItem('lastAccessTokenRefresh', now());
        initializeSocketConnection(dispatch);
        return result;
      });
  };
}

export function authCheck() {

  return async (dispatch: Function): Promise<void> => {
    dispatch(requestAuthCheck());
    try {
      dispatch(receiveAuthCheck(await authService.authCheck()));
      await dispatch(getAppSettings());
      initializeSocketConnection(dispatch);
    } catch (e) {
      Log.captureException(e);
      dispatch(requestLogout());
      abandonSocketConnection();
    }
  };
}

export function forgotPassword(username: string) {
  return (dispatch: Function) => authService.forgotPassword(username);
}

export function resetPassword(password: string, code: string) {
  return (dispatch: Function) => authService.resetPassword(password, code);
}

function changedPassword(result: User) {
  return {
    type: AuthActions.CHANGED_PASSWORD,
    result,
  };
}

export function changePassword(newPassword: string, oldPassword: string) {
  return (dispatch: Function) => {
    return authService.changePassword(newPassword, oldPassword)
      .then((data: User) => dispatch(changedPassword(data)));
  };
}

export function logout() {
  return (dispatch: Function) => {
    dispatch(requestLogout());
    dispatch(updateSelectedMenuItem(null));
    return authService.logout().then(() => {
      abandonSocketConnection();
    });
  };
}

export function impersonateLogin(userId: string) {
  return () => {
    return authService.impersonateLogin(userId)
      .then(() => {
        // force browser to reload apply auth cookies
        // "/" path available for all roles within app,
        // so we use it as a default route to redirect after impersonation (reset)
        window.location.href = homeUrl();
      });
  };
}

export function impersonateReset() {
  return () => {
    return authService.impersonateReset()
      .then(() => {
        window.location.href = homeUrl();
      });
  };
}

// eslint-disable-next-line max-params
export function setPassword(
  newPassword: string,
  sendNotificationEmail: string,
  generateRandomPassword: string,
  firstPasswordReset: string,
  userId: string,
) {
  return (dispatch: Function) => {
    return authService.setPassword(newPassword, sendNotificationEmail, generateRandomPassword, firstPasswordReset,
      userId);
  };
}

export function signup(info: any) {
  return (dispatch: Function) => {
    dispatch(requestSignup(info));
    return authService.signup(info.firstName, info.lastName, info.phone, info.interests)
      .then((data) => receiveSignup());
  };
}

export function publicSignup(info: PayerEntity) {
  return (dispatch: Function) => {
    dispatch(requestSignup(info));
    return authService.publicSignup(info)
      .then((data: User) => {
        receiveSignup()
        dispatch(receiveLogin(data));
        initializeSocketConnection(dispatch);
        return data;
      })
  };
}

export function loginByToken(token: string) {
  return (dispatch: Function) => {
    return authService
      .loginByToken(token)
      .then((result: User) => {
        dispatch(receiveLogin(result));
        initializeSocketConnection(dispatch);
        return result;
      });
  };
}

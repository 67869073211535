import React from 'react';
import { reduxForm, Field, InjectedFormProps, formValueSelector } from 'redux-form';
import { required } from '../../services/app/validators';
import SelectStyled from '../controls/SelectStyled';
import { connect } from 'react-redux';
import { Error } from '../ui/Error';
import { WorkOrderTemplate } from '../../types/WorkOrderTemplate';
import { Location } from '../../types/Location';
import { Department } from '../../types/Department';
import { Shift } from '../../types/Shift';
import WorkOrderTemplateSelectField from './WorkOrderTemplateSelectField';
import { LargeSecondaryButton } from '../ui/Buttons';
import { GlobalState } from '../../types/GlobalState';

const LocationsSelect = (locations: Location[], props: any): React.ReactNode => {
  return (
    <SelectStyled {...props}>
      {locations.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
    </SelectStyled>
  );
};

const DepartmentsSelect = (departments: Department[], props: any): React.ReactNode => {
  return (
    <SelectStyled {...props}>
      {departments.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
    </SelectStyled>
  );
};

const ShiftsSelect = (shifts: Shift[], props: any): React.ReactNode => {
  return (
    <SelectStyled {...props}>
      {shifts.map(item => <option key={item.id} value={item.id}>{item.name}</option>)}
    </SelectStyled>
  );
};


interface FormProps {
  showLocationPicker?: boolean;
  showDepartmentPicker?: boolean;
  disabled?: boolean;
  showShiftPicker?: boolean;
  error?: string;
  locationError?: string;
  templates?: WorkOrderTemplate[];

  onLocationChange: any;
  onSubmit: any;
  getShifts: any;
  getDepartments: any;
  locations: Location[];
}

interface PropsFromState {
  locationId?: string;
}

export interface WorkOrderPdfLocationSelectFormData {
  locationId: string;
  departmentId: string;
  shiftId: string;
  templateId: string;
}

type Props = InjectedFormProps<WorkOrderPdfLocationSelectFormData, FormProps & PropsFromState> & FormProps & PropsFromState;

// eslint-disable-next-line max-lines-per-function
const WorkOrderPdfLocationSelectForm: React.FC<Props> = (props: Props) => {

  const departments = props.getDepartments(props.locationId);
  const shifts = props.getShifts(props.locationId);

  return (
    <form onSubmit={props.handleSubmit}>
      <Error error={props.error} />
      {props.locationError && <div className="validation-summary-errors">{props.locationError}</div>}
      {props.showLocationPicker &&
        <Field
          name="locationId"
          label="Location"
          type="text"
          validate={required}
          component={LocationsSelect.bind(null, props.locations)}
          onChange={props.onLocationChange}
          disabled={props.disabled}
        />
      }

      {props.showDepartmentPicker && !!departments?.length &&
        <Field
          name="departmentId"
          label="Department"
          type="text"
          validate={required}
          component={DepartmentsSelect.bind(null, departments)}
        />
      }

      {props.showShiftPicker && !!shifts?.length &&
        <Field
          name="shiftId"
          label="Shift"
          type="text"
          validate={required}
          component={ShiftsSelect.bind(null, shifts)}
        />
      }

      {props.locationId &&
        <WorkOrderTemplateSelectField
          name="workOrderSettingsId"
          label="Work Order Template"
          validate={required}
          locationId={props.locationId} />
      }

      <div className="create-workorder-controls">
        <LargeSecondaryButton
          fullWidth
          onClick={props.handleSubmit(values => props.onSubmit({ ...values }))}
          disabled={!!props.locationError}
        >
          Print
          </LargeSecondaryButton>
      </div>
    </form>
  );
};

const form = 'workOrderPdfLocationSelect';
const selector = formValueSelector(form);
const mapStateToProps = (state: GlobalState): PropsFromState => ({ locationId: selector(state, 'locationId') });
export default connect(mapStateToProps)(
  reduxForm<WorkOrderPdfLocationSelectFormData, FormProps>({ form })(WorkOrderPdfLocationSelectForm)
);

import React from 'react';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import Input from '../controls/Input';
import { required } from '../../services/app/validators';
import { Error } from '../ui/Error';
import { LargePrimaryButton } from '../ui/Buttons';

export interface TwoFactorLoginFormData {
  username?: string;
  password?: string;
  code?: string;
}

type Props = InjectedFormProps<TwoFactorLoginFormData>;

// eslint-disable-next-line max-lines-per-function
const TwoFactorLoginForm: React.FC<Props> = (props: Props) => {
  const { error, handleSubmit, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field name="code" label="Code" type="text" component={Input} validate={required} />
      <Field name="username" type="hidden" component={Input} validate={required} />
      <Field name="password" type="hidden" component={Input} validate={required} />
      <Error error={error} />
      <div className="row">
        <LargePrimaryButton className="btn-yellow btn-row" disabled={submitting} type="submit">
          {submitting ? 'Signing in...' : 'Enter Code'}
        </LargePrimaryButton>
      </div>
    </form>
  );
};

export default reduxForm<TwoFactorLoginFormData>({ form: 'twoFactorLogin' })(TwoFactorLoginForm);

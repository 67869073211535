import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { sendInvoiceForCompletion } from '../../../../actions/invoices';
import { InvoicePaths } from '../../../../services/app/paths';
import { normalizePhoneOrEmail } from '../../../../services/app/normalizers';
import { WithInvoiceProps } from '../../../../components/ui/WithInvoiceProps';
import { InvoicePreparationStepProps } from '../../../../types/InvoicePreparationStepProps';
import { Invoice } from '../../../../types/Invoice';
import InvoiceFinalizeContainer from '../../../../components/invoice/InvoiceFinalizeContainer';
import { Error } from '../../../../components/ui/Error';
import { GlobalState } from '../../../../types/GlobalState';
import { isEqual } from 'lodash';
import DirectPaymentCheckoutForm, { DirectPaymentCheckoutFormData } from '../../../../components/invoice/DirectPaymentCheckoutForm';
import FeatureFlagContainer from '../../../../components/featureFlag/FeatureFlagContainer';
import { FeatureFlag } from '../../../../components/featureFlag/FeatureFlag';
import CustomStyledAlert from '../../../../components/invoice/CustomStyledAlert';
import { directPaymentMessage } from '../../../../components/product/ProductListTotal';
import { PaymentMethods } from '../../../../constants/invoice';
import styles from './DirectPaymentCheckout.css';
import { Box, StyledComponentProps, withStyles } from '@material-ui/core';
import { FullScreenLoader } from '@roadsync/roadsync-ui';

interface RouteParams {
  invoiceId: string;
}

type PropsFromState = Pick<GlobalState, 'invoices'>;

interface Props extends WithInvoiceProps, InvoicePreparationStepProps, PropsFromState, DispatchProp, RouteComponentProps<RouteParams>, StyledComponentProps {}

interface State {
  error?: string;
  loading: boolean;
}

class DirectPaymentCheckout extends React.Component<Props, State> {

  private mounted: boolean = false;

  constructor(props: Props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = { loading: true };
  }

  componentDidMount(): void {
    this.mounted = true;
    if (this.mounted) this.setState({ loading: false });
  }

  shouldComponentUpdate(nextProps, nextState): boolean {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState)
  }

  componentWillUnmount(): void {
    this.mounted = false;
  }

  async handleSubmit(values: DirectPaymentCheckoutFormData): Promise<void> {
    if (this.mounted) this.setState({ loading: true });
    try {
      const { history, dispatch } = this.props;
      const invoiceId = this.getInvoiceId();
      const phone = normalizePhoneOrEmail(values.payerPhone);
      await dispatch<any>(sendInvoiceForCompletion(invoiceId, undefined, phone));
      history.push(InvoicePaths.listUrl());
    } catch (e) {
      if (this.mounted) this.setState({ error: (e as any)?.message });
    }
    if (this.mounted) this.setState({ loading: false });
  }

  getInvoiceId(): string {
    return this.props.match.params.invoiceId;
  }

  getInvoice(): Invoice | undefined {
    const { invoices } = this.props;
    return invoices.data?.[this.getInvoiceId()];
  }

  getInitialValues(): Partial<DirectPaymentCheckoutFormData> {
    const invoice = this.getInvoice();
    return { payerPhone: invoice?.payerPhone };
  }

  showDirectPaymentAlert(): boolean {
    return this.getInvoice()?.type === PaymentMethods.DIRECT_PAYMENT.key;
  }

  render(): React.ReactElement {
    const { onCompletedStep, classes } = this.props;
    const { loading } = this.state;
    const { error } = this.state;
    return <>
        <FullScreenLoader show={loading} />
        <FeatureFlagContainer
          flag={FeatureFlag.AdvanceToCheckoutDirectPayments}
          disabled={undefined}
        >
          {this.showDirectPaymentAlert() &&
            <Box mb={2}>
              <CustomStyledAlert className={classes?.directPaymentAlertMobileOnly}>
                {directPaymentMessage}
              </CustomStyledAlert>
            </Box>
          }
        </FeatureFlagContainer>
        <InvoiceFinalizeContainer formName='directPaymentCheckout' onCompletedStep={onCompletedStep}>
          <Error error={error} mt={2} mb={2} />
          <DirectPaymentCheckoutForm initialValues={this.getInitialValues()} onSubmit={this.handleSubmit} />
        </InvoiceFinalizeContainer>
    </>;
  }
}

/* istanbul ignore next */
const mapStateToProps = ({ invoices }): PropsFromState => ({ invoices });
export default withRouter(connect(mapStateToProps)(withStyles(styles)(DirectPaymentCheckout)));

import React from 'react';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { required } from '../../services/app/validators';
import Input from '../controls/Input';
import { Error } from '../ui/Error';

export type ApproveCheckFormData = {
  authNumber: string;
}

type Props = InjectedFormProps<ApproveCheckFormData>;

const ApproveCheckForm: React.FC<Props> = ({ error, handleSubmit, submitting }: Props) => (
  <form onSubmit={handleSubmit}>
    <div className="dialer-approve-check-form">
      <Error error={error} />
      <button className="btn-dark submit-btn" disabled={submitting} type="submit">
        {submitting ? 'Approving...' : 'Approve'}
      </button>
      <Field
        className="dialer-auth-name"
        name="authNumber"
        placeholder="Approval Code"
        type="text"
        component={Input}
        validate={required}
      />
    </div>
  </form>
);

export default reduxForm<ApproveCheckFormData>({ form: 'approveCheckForm' })(ApproveCheckForm);

import React from "react";
import { isEqual } from "lodash";
import { Typography, Grid, Box } from "@material-ui/core";
import useStyles from "./CardReaderModalStyles.css";
import { LargeSecondaryButton } from "../ui/Buttons";
import warningIcon from "../../assets/img/icons/invoicePriceUpdatedWarningIcon.svg";
import TextButton from "../ui/buttons/TextButton";

interface Props {
    handleCancel: () => Promise<void>;
    handleTryAgain: () => void;
    deviceName: string;
}

const areEqual = (prevProps: Props, nextProps: Props) => isEqual(prevProps, nextProps);

const InvoicePriceUpdatedModal: React.FC<Props> = React.memo((props: Props) => {
    const { handleCancel, handleTryAgain, deviceName } = props;
    const classes = useStyles();

    return (
        <div>
            <Grid container direction="column" alignItems="center" spacing={4}>
                <Box height={30} />
                <Grid item container justifyContent="center" alignItems="center" spacing={4}>
                    <Grid item container justifyContent="center">
                        <img src={warningIcon} alt="icon" />
                    </Grid>
                    <Box height={30} />
                    <Typography component="div" variant="h4" className={classes.dialogTitle}>
                        The payment was canceled due to an update in the amount. Please try again.
                    </Typography>
                    <Box height={8} />
                    <Box className={classes.dialogText}>
                        Connected to <span style={{ fontWeight: "bold" }}>{deviceName}</span>
                    </Box>
                </Grid>
                <Grid item className={classes.gridItemBtn}>
                    <Grid container direction="column" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <LargeSecondaryButton
                                id="try-again-btn"
                                data-testid="try-again-btn"
                                onClick={handleTryAgain}
                                fullWidth
                                variant="outlined"
                                type="button"
                                className={classes.textTransformNone}
                            >
                                Try Again
                            </LargeSecondaryButton>
                        </Grid>
                        <Grid item>
                            <TextButton id="cancel-btn" data-testid="cancel-btn" onClick={handleCancel} fullWidth>
                                Cancel
                            </TextButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Box height={30} />
            </Grid>
        </div>
    );
}, areEqual);

export default InvoicePriceUpdatedModal;

import React from 'react';
import moment from 'moment-timezone';

interface DepositDurationProps {
  timestamp: moment.Moment;
}

interface DepositDurationState {
  duration: string;
}

const UPDATE_INTERVAL_MS = 1000;

class DepositDuration extends React.Component<DepositDurationProps, DepositDurationState> {
  timer: any;

  constructor(props) {
    super(props);

    this.state = {
      duration: '',
    };
  }

  componentDidMount() {
    this.timer = setInterval(() => this.tick(), UPDATE_INTERVAL_MS);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  tick() {
    const { timestamp } = this.props;

    this.setState({
      duration: moment.duration(moment().diff(moment.utc(timestamp))).humanize(),
    });
  }

  render() {
    const { duration } = this.state;

    return <React.Fragment>{duration}</React.Fragment>;
  }
}

export default DepositDuration;

import React from 'react';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { required, email, formPhoneNumber, integer, numeric } from '../../services/app/validators';
import { CustomFieldType } from '../../constants/customFieldTypes';
import { Error } from '../ui/Error';
import { CustomField } from '../../types/CustomField';
import { LargePrimaryButton, LargeButton } from '../ui/Buttons';
import { Grid } from '@roadsync/roadsync-ui';
import { RenderTextField } from '../ui/Form';
import { RenderSelectField } from '../ui/form/RenderSelectField';
import { MenuItem } from '@material-ui/core';
import InvoicePrepButtonRow from './fullScreenStepper/InvoicePrepButtonRow';

type FormData = {
  [k: string]: string;
}

type Props = {
  customFields?: CustomField[];
  onPrevStep?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

function getFieldLabel(field: CustomField): string {
  return field.title + (field.isRequired ? "" : " (optional)");
}

// eslint-disable-next-line max-lines-per-function
const LocationCustomFieldsForm: React.FC<Props & InjectedFormProps<FormData, Props>> = (props: Props & InjectedFormProps<FormData, Props>) => {

  const { error, handleSubmit, submitting, customFields, invalid, onPrevStep } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Error error={error} scrollIntoView={true} />
      <Grid container spacing={4} direction="column">
        {/* eslint-disable-next-line max-lines-per-function */}
        {customFields?.map((field) => {
          return (
            <Grid item key={field.name}>
              {field.type === CustomFieldType.SELECT.key &&
                <Field
                  error={error}
                  name={field.name}
                  label={getFieldLabel(field)}
                  validate={field.isRequired ? required : []}
                  options={field.options.values}
                  component={RenderSelectField}
                  required={!!field.isRequired}
                >
                  {field.options?.values?.map((v, i) =>
                    <MenuItem key={i} value={v}>{v}</MenuItem>)
                  }
                </Field>
              }
              {field.type === CustomFieldType.EMAIL.key &&
                <Field
                  error={error}
                  name={field.name}
                  type="email"
                  label={getFieldLabel(field)}
                  validate={field.isRequired ? [required, email] : email}
                  component={RenderTextField}
                  required={!!field.isRequired}
                />}
              {field.type === CustomFieldType.PHONE.key &&
                <Field
                  error={error}
                  name={field.name}
                  type="text"
                  label={getFieldLabel(field)}
                  validate={field.isRequired ? [required, formPhoneNumber] : formPhoneNumber}
                  component={RenderTextField}
                  required={!!field.isRequired}
                />}
              {field.type === CustomFieldType.INPUT.key &&
                <Field
                  error={error}
                  name={field.name}
                  type="text"
                  label={getFieldLabel(field)}
                  validate={field.isRequired ? required : []}
                  component={RenderTextField}
                  required={!!field.isRequired}
                />
              }
              {field.type === CustomFieldType.NUMERIC.key &&
                <Field
                  error={error}
                  name={field.name}
                  type="numeric"
                  label={getFieldLabel(field)}
                  validate={field.isRequired ? [required, integer] : integer}
                  component={RenderTextField}
                  required={!!field.isRequired}
                />
              }
              {field.type === CustomFieldType.DECIMAL.key &&
                <Field
                  error={error}
                  name={field.name}
                  type="numeric"
                  label={getFieldLabel(field)}
                  validate={field.isRequired ? [required, numeric] : numeric}
                  component={RenderTextField}
                  required={!!field.isRequired}
                />
              }
            </Grid>
          );
        })}
        <Grid item>
          <InvoicePrepButtonRow
            secondaryBtn={<>
              {onPrevStep &&
                <LargeButton variant="outlined" onClick={onPrevStep} id="custom-fields-back-btn">
                  Back
                </LargeButton>
              }
            </>}
          >
            <LargePrimaryButton disabled={submitting || invalid} type="submit" id="custom-fields-submit-btn">
              Save &amp; Continue
            </LargePrimaryButton>
          </InvoicePrepButtonRow>
        </Grid>
      </Grid>
    </form>
  );
};

export default reduxForm<FormData, Props>({ form: 'locationCustomFieldsForm' })(LocationCustomFieldsForm);

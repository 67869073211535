import { CompanyActions } from '../constants/actions';
import * as CompanyService from '../services/api/companies';
import { normalizeResult } from '../services/app/normalizr';
import { CompanyInput } from '../types/Company';
import { companySchema } from '../types/schemas';
import { App } from '../constants/app';
import { showSuccessAlert } from './alerts';

const receiveCompany = (entities) => ({ type: CompanyActions.RECEIVE_COMPANY, entities });
const receiveCompanyList = (entities, order) => ({ type: CompanyActions.RECEIVE_COMPANY_LIST, entities, order });
const creatingCompany = () => ({ type: CompanyActions.CREATING_COMPANY });
const createdCompany = (entities) => ({ type: CompanyActions.CREATED_COMPANY, entities });
const updatingCompany = () => ({ type: CompanyActions.UPDATING_COMPANY });
const updatedCompany = (entities) => ({ type: CompanyActions.UPDATED_COMPANY, entities });
const normalizeCompanyResult = normalizeResult(companySchema);

export function getCompany(companyId: string) {
  return (dispatch: Function) => {
    return CompanyService.getCompany(companyId)
      .then(normalizeCompanyResult)
      .then((data: any) => dispatch(receiveCompany(data.entities)));
  };
}

// eslint-disable-next-line
export function getCompanies(offset = 0, limit = App.PAGE_SIZE, filter?: string, companyType?: string) {
  return (dispatch: Function) => {
    return CompanyService.getCompanies(offset, limit, filter, companyType)
      .then(normalizeCompanyResult)
      .then((data: any) => {
        dispatch(receiveCompanyList(data.entities, data.result));
      });
  };
}

export function createCompany(company: CompanyInput) {
  return (dispatch: Function) => {
    dispatch(creatingCompany());
    return CompanyService.createCompany(company)
      .then(normalizeCompanyResult)
      .then((data: any) => {
        dispatch(createdCompany(data.entities));
      });
  };
}

export function updateCompany(company: CompanyInput) {
  return (dispatch: Function) => {
    dispatch(updatingCompany());
    return CompanyService.updateCompany(company)
      .then(normalizeCompanyResult)
      .then((data: any) => {
        dispatch(updatedCompany(data.entities));
        dispatch(showSuccessAlert('Company information updated successfully!'));
      });
  };
}

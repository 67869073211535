import { Level } from "../LoggerService";
import { LoggerInterface } from "./LoggerInterface";
import { LogLevel } from "./LogLevel";
import { v4 as uuidv4 } from "uuid";

export class MultiLogger implements LoggerInterface {

  private loggers: LoggerInterface[];

  constructor(...loggers: LoggerInterface[]) {
    this.loggers = loggers;
  }

  public level(): LogLevel {
    return Level;
  }

  public debug(msg: string, ...data: any[]): void {
    this.loggers.forEach(logger => logger.debug(msg, ...data));
  }

  public info(msg: string, ...data: any[]): void {
    this.loggers.forEach(logger => logger.info(msg, ...data));
  }

  public warn(msg: string, ...data: any[]): void {
    this.loggers.forEach(logger => logger.warn(msg, ...data));
  }

  public error(msg: string, ...data: any[]): void {
    this.loggers.forEach(logger => logger.error(msg, ...data));
  }

  public captureException(e: Error, extra?: Record<string, any>): string {
    const errors = this.loggers.map(logger => logger.captureException(e, extra));
    return errors?.[0] || uuidv4();
  }

  public captureMessage(msg: string, extra?: Record<string, any>): void {
    this.loggers.forEach(logger => logger.captureMessage(msg, extra));
  }
}

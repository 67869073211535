import React from 'react';
import { isEqual } from 'lodash';
import { Dialog, DialogContent, Typography, Grid, Box, DialogTitle, IconButton } from '@material-ui/core';
import { LargeButton } from '@roadsync/roadsync-ui';
import errorIcon from "../../assets/img/icons/cardErrorIcon.svg";
import useStyles from './ComdataInvalidDataModal.css';
import { CloseIcon } from '../ui/Icons';

interface Props {
    open?: boolean;
    onClose: () => void;
    onChangePaymentMethod: () => void;
}

const areEqual = (prevProps: Props, nextProps: Props) => isEqual(prevProps, nextProps);

const ComdataInvalidDataModal: React.FC<Props> = React.memo((props: Props) => {
    const { open, onClose, onChangePaymentMethod } = props;
    const classes = useStyles();
    const baseId = 'comdata-invalid-data-modal';

    return (
        <Dialog id={baseId} data-testid={baseId} className={classes.dialog} PaperProps={{ square: false }} fullWidth maxWidth='sm' open={open ?? false}>
            <DialogTitle>
                <IconButton id={`${baseId}-close-btn`} data-testid={`${baseId}-close-btn`} className={classes.modalCloseButton} onClick={onClose} color='inherit' size='small'>
                    <CloseIcon id={`${baseId}-close-icon`} data-testid={`${baseId}-close-icon`} />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    wrap="nowrap"
                    spacing={1}
                >
                    <Grid item>
                        <img src={errorIcon} alt="Error Icon" className={classes.icon} />
                        <Box mb={3} />
                    </Grid>
                    <Grid item>
                        <Typography component="div" variant="h1" className={classes.dialogTitle}>
                            Oops!
                        </Typography>
                        <Typography component="div" variant="h1" className={classes.dialogTitle}>
                            Some information is not right
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Box className={classes.confirmationText}>
                            It looks like there's an issue with the information you entered. Please ask the payer to contact the express code issuer to verify the details, then try again.
                        </Box>
                    </Grid>
                    <Grid item className={classes.gridItemBtn}>
                        <Grid container direction='row' wrap='wrap' spacing={2}>
                            <Grid item
                                xs={12}
                                sm={6}
                            >
                                <LargeButton
                                    id='modal-change-payment-method-btn'
                                    data-testid='modal-change-payment-method-btn'
                                    type='button'
                                    className={classes.primaryBtn}
                                    onClick={onChangePaymentMethod}
                                    fullWidth
                                    disableElevation
                                    borderVariant='rounded'
                                    variant='outlined'
                                >
                                    Change Payment Method
                                </LargeButton>
                            </Grid>
                            <Grid item
                                xs={12}
                                sm={6}
                            >
                                <LargeButton
                                    id='modal-try-again-btn'
                                    data-testid='modal-try-again-btn'
                                    type='button'
                                    className={classes.secondaryBtn}
                                    onClick={onClose}
                                    fullWidth
                                    disableElevation
                                    borderVariant='rounded'
                                    color='secondary'
                                >
                                    Try Again
                                </LargeButton>
                            </Grid>
                        </Grid>
                        <Box mb={3} />
                    </Grid>
                </Grid>
                {/* <Loader show={loading} /> */}
            </DialogContent >
        </Dialog >
    );
}, areEqual);

export default ComdataInvalidDataModal;

import _ from 'lodash';
import { EventType, EventTypes } from './eventTypes';
import { InvoiceStepCategories } from './invoiceStepCategory';
import { InvoiceBuilderPreparationStep } from '../types/Invoice';

export interface PaymentMethodType {
    key: string;
    display: string;
}

export interface InvoiceDeleteReason {
    key: string;
    display: string;
}

export class PaymentMethods {
    static readonly CASH: PaymentMethodType = {
        key: 'cash',
        display: 'Cash',
    };

    static readonly CHECK: PaymentMethodType = {
        key: 'check',
        display: 'Check',
    };

    static readonly CARD: PaymentMethodType = {
        key: 'card',
        display: 'Credit Card / Debit Card',
    };

    static readonly PAYCODE: PaymentMethodType = {
        key: 'paycode',
        display: 'Paycode',
    };

    static readonly DIRECT_BILL: PaymentMethodType = {
        key: 'directbill',
        display: 'Direct Bill',
    };

    static readonly HOST_BILL: PaymentMethodType = {
        key: 'hostbill',
        display: 'Host Bill',
    };

    static readonly UNKNOWN: PaymentMethodType = {
        key: 'unknown',
        display: `I don't know`,
    };

    static readonly REMOTE_CHECKOUT: PaymentMethodType = {
        key: 'remotecheckout',
        display: `Remote Checkout`,
    };

    static readonly ACH: PaymentMethodType = {
        key: 'ach',
        display: 'Bank Account Transfer',
    };

    static readonly FUEL_CARD: PaymentMethodType = {
        key: 'fuelcard',
        display: 'Fuel Card / Fleet Card',
    };

    static readonly COMDATA_CARD: PaymentMethodType = {
        key: 'comdata',
        display: 'Comdata Fuel Card',
    };

    static readonly WEX_EFS: PaymentMethodType = {
        key: 'wex',
        display: 'Wex / EFS Fuel Card',
    };

    static readonly DIRECT_PAYMENT: PaymentMethodType = {
        key: 'directpayment',
        display: 'Direct Payment',
    };

    static readonly ACH_PAYMENT: PaymentMethodType = {
        key: 'achpayment',
        display: 'ACH',
    };

    private static readonly LOOKUP = {
        'cash': PaymentMethods.CASH,
        'check': PaymentMethods.CHECK,
        'card': PaymentMethods.CARD,
        'paycode': PaymentMethods.PAYCODE,
        'unknown': PaymentMethods.UNKNOWN,
        'remotecheckout': PaymentMethods.REMOTE_CHECKOUT,
        'hostbill': PaymentMethods.HOST_BILL,
        'directbill': PaymentMethods.DIRECT_BILL,
        'ach': PaymentMethods.ACH,
        'fuelcard': PaymentMethods.FUEL_CARD,
        'comdata': PaymentMethods.COMDATA_CARD,
        'wex': PaymentMethods.WEX_EFS,
        'directpayment': PaymentMethods.DIRECT_PAYMENT,
        'achpayment': PaymentMethods.ACH_PAYMENT,
    };

    private static readonly FEE_LOOKUP = {
        'cash': EventTypes.CASH_CONVENIENCE,
        'check': EventTypes.CHECK_CONVENIENCE,
        'card': EventTypes.CARD_CONVENIENCE,
        'paycode': EventTypes.PAY_CODE_CONVENIENCE,
        'ach': EventTypes.ACH_CONVENIENCE,
        'fuelcard': EventTypes.FUEL_CARD_CONVENIENCE,
    };

    static getTypes(): PaymentMethodType[] {
        return _.values(PaymentMethods.LOOKUP);
    }

    static getByKey(key: string): PaymentMethodType {
        return PaymentMethods.LOOKUP[key];
    }

    static getFeeType(key: string): EventType {
        return PaymentMethods.FEE_LOOKUP[key];
    }
}

export interface InvoiceStatusType {
    key: string;
    display: string;
    description: string;
}

export const getRemainingInvoiceStatues = (statuses: InvoiceStatusType[]): InvoiceStatusType[] =>
    InvoiceStatuses.getTypes().filter(s => !statuses.includes(s));

export class InvoiceStatuses {
    static readonly NEW: InvoiceStatusType = {
        key: 'new',
        display: 'New',
        description: 'Invoice has not been sent to your customer for payment.',
    };
    static readonly PROCESSING: InvoiceStatusType = {
        key: 'processing',
        display: 'Processing',
        description: 'Payment is currently being processed. Please wait.',
    };
    static readonly COMPLETED: InvoiceStatusType = {
        key: 'completed',
        display: 'Completed',
        description: 'Payment is complete.',
    };
    static readonly FAILED: InvoiceStatusType = {
        key: 'failed',
        display: 'Failed',
        description: 'Payment has failed. Click on the “?” icon to the right to learn more.',
    };
    static readonly UNKNOWN: InvoiceStatusType = {
        key: 'unknown',
        display: 'Unknown',
        description: 'This invoice does not have a status. Please contact RoadSync support for more information.',
    };
    static readonly WAITINGAPPROVAL: InvoiceStatusType = {
        key: 'waitingapproval',
        display: 'Waiting Approval',
        description: 'Payment is currently being processed. Please wait.',
    };
    static readonly VALIDATED: InvoiceStatusType = {
        key: 'validated',
        display: 'Card Validated',
        description: 'This payment method has been validated',
    };
    static readonly VALIDATEDANDFAILED: InvoiceStatusType = {
        key: 'validatedandfailed',
        display: 'Payment failed',
        description: 'The payment has failed',
    };
    /**
     * @deprecated
     * `PENDING_REVIEW` was used for the now removed "Bill to Customer" feature and is now deprecated.
     * There shouldn't be any invoices in this status.
     */
    static readonly PENDING_REVIEW: InvoiceStatusType = {
        key: 'pendingreview',
        display: 'Pending Review',
        description: 'Invoice is being processed by Accounting.',
    };
    static readonly SENT: InvoiceStatusType = {
        key: 'sent',
        display: 'Sent',
        description: 'Invoice has been sent to your customer for payment.',
    };

    private static readonly LOOKUP = {
        'new': InvoiceStatuses.NEW,
        'processing': InvoiceStatuses.PROCESSING,
        'completed': InvoiceStatuses.COMPLETED,
        'failed': InvoiceStatuses.FAILED,
        'unknown': InvoiceStatuses.UNKNOWN,
        'waitingapproval': InvoiceStatuses.WAITINGAPPROVAL,
        'validated': InvoiceStatuses.VALIDATED,
        'sent': InvoiceStatuses.SENT,
        'validatedandfailed': InvoiceStatuses.VALIDATEDANDFAILED
    };

    static getTypes(): InvoiceStatusType[] {
        return _.values(InvoiceStatuses.LOOKUP);
    }

    static getByKey(key: string): InvoiceStatusType {
        return InvoiceStatuses.LOOKUP[key];
    }
}

export enum PreparationSteps {
    LOADING = 'LOADING',
    NO_LOCATIONS = 'NO_LOCATIONS',
    NO_PRODUCTS = 'NO_PRODUCTS',
    PAYER_INFO = 'PAYER_INFO',
    LOCATION_CUSTOM_FIELDS = 'LOCATION_CUSTOM_FIELDS',
    LINE_ITEMS = 'LINE_ITEMS',
    COMDATA_CONFIRM_REGISTER_CHECK_TO_CARD = 'COMDATA_CONFIRM_REGISTER_CHECK_TO_CARD',
    PAYMENT_METHOD = 'PAYMENT_METHOD',
    CASH_DETAILS = 'CASH_DETAILS',
    CHECK_DETAILS = 'CHECK_DETAILS',
    FUEL_CARD_DETAILS = 'FUEL_CARD_DETAILS',
    NEW_FUEL_CARD_DETAILS = 'NEW_FUEL_CARD_DETAILS',
    FUEL_CARD_PAYMENT_METHODS = 'FUEL_CARD_PAYMENT_METHODS',
    NEW_FUEL_CARD_PAYMENT_METHODS = 'NEW_FUEL_CARD_PAYMENT_METHODS',
    CHECK_TYPES = 'CHECK_TYPES',
    KEEP_CHECK_NUMBER = 'KEEP_CHECK_NUMBER',
    CHECK_PAYMENT_CODE = 'CHECK_PAYMENT_CODE',
    CARD_DETAILS = 'CARD_DETAILS',
    RAINFOREST_CARD_DETAILS = 'RAINFOREST_CARD_DETAILS',
    SEND_CHECK_INFO_TO_DRIVER = 'SEND_CHECK_INFO_TO_DRIVER',
    PAYMENT_METHOD_EXISTS = 'PAYMENT_METHOD_EXISTS',
    SELECT_FLEET_CARD_TYPE = 'SELECT_FLEET_CARD_TYPE',
    NOT_ALLOWED_TO_EDIT = 'NOT_ALLOWED_TO_EDIT',
    FLEET_CARD_CHECK_NUMBER = 'FLEET_CARD_CHECK_NUMBER',
    ATTACH_FILE = 'ATTACH_FILE',
    SEND_FOR_REMOTE_CHECKOUT = 'SEND_FOR_REMOTE_CHECKOUT',
    INVOICE_DETAILS = 'INVOICE_DETAILS',
    ACH_DETAILS = 'ACH_DETAILS',
    PAYER_BANK_ACCOUNT_CREATION = 'PAYER_BANK_ACCOUNT_CREATION',
    PAYER_FUNDING_SOURCE_CREATION = 'PAYER_FUNDING_SOURCE_CREATION',
    SEND_INVOICE_DETAILS_TO_PAYER = 'SEND_INVOICE_DETAILS_TO_PAYER',
    KEEP_CHECK_NUMBER_DOUBLE_PROMPT = 'KEEP_CHECK_NUMBER_DOUBLE_PROMPT',
    DIRECT_PAYMENT = 'DIRECT_PAYMENT',
    IMPORT_TOWBOOK = 'IMPORT_TOWBOOK',
    TOWBOOK_PAYMENT_METHOD = 'TOWBOOK_PAYMENT_METHOD',
}

export const invoiceBuilderPreparationSteps: InvoiceBuilderPreparationStep[] = [
    { label: 'Driver Info', stepName: PreparationSteps.PAYER_INFO, key: InvoiceStepCategories.INFO.key },
    { label: 'Payment Method', stepName: PreparationSteps.PAYMENT_METHOD, key: InvoiceStepCategories.PAYMENT.key },
    { label: 'Charges', stepName: PreparationSteps.LINE_ITEMS, key: InvoiceStepCategories.INVOICE.key },
    { label: 'Checkout', stepName: PreparationSteps.LINE_ITEMS, key: InvoiceStepCategories.CHECKOUT.key }
];

export const towbookInvoiceBuilderPreparationSteps: InvoiceBuilderPreparationStep[] = [
    { label: 'Invoice', stepName: PreparationSteps.IMPORT_TOWBOOK, key: InvoiceStepCategories.INFO.key },
    { label: 'Payment Method', stepName: PreparationSteps.TOWBOOK_PAYMENT_METHOD, key: InvoiceStepCategories.PAYMENT.key },
    { label: 'Charges', stepName: PreparationSteps.LINE_ITEMS, key: InvoiceStepCategories.INVOICE.key },
    { label: 'Checkout', stepName: PreparationSteps.LINE_ITEMS, key: InvoiceStepCategories.CHECKOUT.key }
];

export class InvoiceDeleteReasons {
    static readonly BY_CUSTOMER: InvoiceDeleteReason = {
        key: 'requestedbycustomer',
        display: 'Customer requested Invoice deletion without additional details',
    };
    static readonly INCORRECT_AMOUNT: InvoiceDeleteReason = {
        key: 'incorrectamount',
        display: 'Incorrect amount entered',
    };
    static readonly PREPAID_CONTRACT: InvoiceDeleteReason = {
        key: 'prepaidcontract',
        display: 'Prepaid contract',
    };
    static readonly DUPLICATE: InvoiceDeleteReason = {
        key: 'duplicate',
        display: 'Duplicate of a different invoice',
    };
    static readonly FRAUDULENT: InvoiceDeleteReason = {
        key: 'fraudulent',
        display: 'Invoice was considered fraudulent',
    };
    static readonly ALTERNATE_METHOD: InvoiceDeleteReason = {
        key: 'alternate',
        display: 'Invoice paid using alternate method.',
    };
    static readonly DRIVER_LEFT: InvoiceDeleteReason = {
        key: 'driverleft',
        display: 'Driver left prior to service',
    };
    static readonly UNABLE_TO_SERVICE: InvoiceDeleteReason = {
        key: 'unabletoservice',
        display: 'Unable to service',
    };
    private static readonly LOOKUP = {
        [InvoiceDeleteReasons.BY_CUSTOMER.key]: InvoiceDeleteReasons.BY_CUSTOMER,
        [InvoiceDeleteReasons.INCORRECT_AMOUNT.key]: InvoiceDeleteReasons.INCORRECT_AMOUNT,
        [InvoiceDeleteReasons.PREPAID_CONTRACT.key]: InvoiceDeleteReasons.PREPAID_CONTRACT,
        [InvoiceDeleteReasons.DUPLICATE.key]: InvoiceDeleteReasons.DUPLICATE,
        [InvoiceDeleteReasons.FRAUDULENT.key]: InvoiceDeleteReasons.FRAUDULENT,
        [InvoiceDeleteReasons.ALTERNATE_METHOD.key]: InvoiceDeleteReasons.ALTERNATE_METHOD,
        [InvoiceDeleteReasons.DRIVER_LEFT.key]: InvoiceDeleteReasons.DRIVER_LEFT,
        [InvoiceDeleteReasons.UNABLE_TO_SERVICE.key]: InvoiceDeleteReasons.UNABLE_TO_SERVICE,
    };
    static getTypes(isRefund?: boolean): InvoiceDeleteReason[] {
        return _.values(InvoiceDeleteReasons.LOOKUP).filter(r =>
            isRefund ? r.key !== InvoiceDeleteReasons.DRIVER_LEFT.key : true
        );
    }
    static getByKey(key: string): InvoiceDeleteReason {
        return InvoiceDeleteReasons.LOOKUP[key];
    }
}

interface DeleteType {
    key: string;
}
export class InvoiceDeleteTypes {
    static readonly VOID: DeleteType = {
        key: 'void',
    };
    static readonly REFUND: DeleteType = {
        key: 'refund',
    };
}


export enum InvoiceSource {
    REGULAR = 'regular',
    EXPRESS_PAYMENTS = 'expresspayments',
    HOSTED_PAY_PAGE = 'hostedpaypage',
    TOWBOOK = 'towbook',
}

import { P } from '@roadsync/roadsync-ui';
import React from 'react';
import { Deposit } from '../../types';

export interface Props {
  deposit?: Deposit;
}

const ComdataCheckFallbackText: React.FC<Props> = (props: Props) => {
  const { deposit } = props;
  return (
    <>
      <P variant="h4" gutterBottom>Register a Check Number</P>
      <br />
      <P gutterBottom>Please have the driver contact Comdata to register this check number to their fuel card or express code.</P>
      <br />
      <P align="center" variant="h3" gutterBottom>{deposit?.checkNumber}</P>
      <br />
      <P gutterBottom>Please provide some additional information.</P>
      <br />
    </>
  );
};

export default ComdataCheckFallbackText;

import React from 'react';
import { makeStyles, TextField as MUITextField, TextFieldProps } from "@material-ui/core";
import { FormConfig } from './FormConfig';

export interface RenderTextFieldProps {
  input: Partial<TextFieldProps>;
  custom: Partial<TextFieldProps>;
  label?: string;
  meta: { touched: boolean; error: string, active: boolean };
  helperText?: React.ReactNode;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  backgroundColor?: string;
  margin?: string;
  multiline?: boolean;
  type?: string;
  autoComplete?: string;
  readOnly?: boolean;
  isComdataFlowInvalid?: boolean;
  height42?: boolean;
  ComponentWhenActive?: JSX.Element;
}

const textFieldStyles = (backgroundColor?: string, margin?: string): Record<string, any> => {
  return makeStyles({
    input: {
      backgroundColor: `${backgroundColor ? backgroundColor : 'unset'}`,
      margin: `${margin ? margin : 'unset'}`,
    },
    field42: {
      '& input, & textarea': {
        fontSize: 14,
      },
      '& input': {
        height: 42,
        padding: '0 14px',
      },
      '& label': {
        height: 42,
        top: `-6px`,
        fontSize: 14,
      },
      '& [class*="MuiInputLabel-outlined"]': {
        '&[class*="MuiInputLabel-shrink"]': {
          transform: 'translate(14px, -1px) scale(0.86)',
        }
      },
      '& [class*="MuiOutlinedInput-multiline"]': {
        padding: '11.5px 14px',
      },
    }
  })();
}

export const RenderTextField: React.FC<RenderTextFieldProps> = (props: RenderTextFieldProps): React.ReactElement => {
  const {
    input, label, autoComplete, meta: { touched, error, active }, multiline,
    backgroundColor, margin, helperText, startAdornment, endAdornment, readOnly, isComdataFlowInvalid,
    height42, ComponentWhenActive, ...custom
  } = props;
  const classes = textFieldStyles(backgroundColor, margin);
  return (
    <>
      <MUITextField
        className={`${classes.input} ${height42 ? classes?.field42 : ""}`}
        label={label}
        variant={FormConfig.Variant}
        error={(touched && !!error) || isComdataFlowInvalid}
        InputProps={{ startAdornment, endAdornment, readOnly }}
        helperText={helperText ? helperText : touched && error ? error : undefined}
        fullWidth
        autoComplete={autoComplete}
        multiline={multiline}
        {...input}
        {...custom}
      />
      {active && ComponentWhenActive && <>{ComponentWhenActive}</>}
    </>
  );
}

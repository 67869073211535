/* eslint-disable max-lines */
import React from 'react';
import { closeModal } from '../../actions/modals';
import { ModalsConstants } from '../../constants/modals';
import { connect, DispatchProp } from 'react-redux';
import ModalContent from '../../components/modals/ModalContent';
import { approveDeposit, declineDeposit } from '../../actions/checkAuthorizer';
import { CheckTypes } from '../../constants/deposit';
import { getUserIfNotInStore } from '../../actions/users';
import ApproveCheckForm, { ApproveCheckFormData } from '../../components/checkAuthorizer/ApproveCheckForm';
import DeclineCheckForm, { RejectCheckFormData } from '../../components/checkAuthorizer/RejectCheckForm';
import { handleReduxFormError } from '../../services/app/forms';
import _ from 'lodash';
import moment from 'moment-timezone';
import DepositDuration from '../../components/checkAuthorizer/DepositDuration';
import { Device } from '../../services/app/TwilioDevice';
import { Deposit } from '../../types/Deposit';
import { GlobalState } from '../../types/GlobalState';
import { showErrorAlert } from '../../actions/alerts';
import { getTwilioToken } from '../../services/api/checkAuthorizer';
import MicOffIcon from '@material-ui/icons/MicOff';
import MicIcon from '@material-ui/icons/Mic';
import { AlertSeverity } from '../../constants/AlertSeverity';
import { Error } from '../../components/ui/Error';
import { forceValueToString } from '../../services/app/formats';

const SLOW_DIGIT_DELAY = 350;
const SLOW_DIGIT_JITTER = 100;

type PropsFromState = Pick<GlobalState, "users">;

interface OwnProps {
  deposit: Deposit;
}

interface Props extends OwnProps, DispatchProp, PropsFromState { }

interface State {
  device: Device;
  logs: string[];
  muted?: boolean;
  connected?: boolean;
}

class CheckAuthorizerCallerModal extends React.Component<Props, State> {

  // eslint-disable-next-line max-lines-per-function, max-statements
  constructor(props: Props) {
    super(props);
    this.handleApproveCheck = this.handleApproveCheck.bind(this);
    this.handleRejectCheck = this.handleRejectCheck.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.hangUp = this.hangUp.bind(this);
    this.log = this.log.bind(this);
    this.toggleMute = this.toggleMute.bind(this);
    this.mute = this.mute.bind(this);
    this.handleDeviceOffline = this.handleDeviceOffline.bind(this);
    this.handleDeviceError = this.handleDeviceError.bind(this);
    this.handleDeviceConnected = this.handleDeviceConnected.bind(this);
    this.handleDeviceDisconnected = this.handleDeviceDisconnected.bind(this);
    this.handleDeviceReady = this.handleDeviceReady.bind(this);
    this.state = {
      device: new Device(),
      logs: [],
    };
  }

  handleDeviceReady(): void {
    const { deposit } = this.props;
    const { device } = this.state;
    this.log('Twilio.Device ready!');
    this.log(`Calling for #${deposit.invoice?.invoiceNumber}`);
    device.connect({ checkType: deposit.checkType || "unknown" });
  }

  handleDeviceDisconnected(): void {
    this.setState({ connected: false });
    this.log('Call ended');
  }

  handleDeviceConnected(): void {
    this.log('Successfully established call');
    this.setState({ connected: true });
    this.mute(true);
  }

  handleDeviceOffline(): void {
    this.log('Twilio.Device offline');
  }

  handleDeviceError(e: { message: string }): void {
    this.log('Twilio.Device error: ' + e.message)
  }

  async fetchToken(): Promise<void> {
    const { dispatch } = this.props;
    const { device } = this.state;
    try {
      const data = await getTwilioToken();
      device.setup(data.token);
    } catch (e) {
      dispatch(showErrorAlert('Could not get a token from the server. Cannot complete call.'));
      this.hangUp();
    }
  }

  mute(muted: boolean): void {
    const { device } = this.state;
    const conn = device.activeConnection();
    if (conn) {
      conn.mute(muted);
      this.setState({ muted });
    }
  }

  toggleMute(): void {
    const { muted } = this.state;
    this.mute(!muted);
  }

  async setupDevice(): Promise<void> {
    const { device } = this.state;
    device.on("connect", this.handleDeviceConnected);
    device.on("disconnect", this.handleDeviceDisconnected);
    device.on("offline", this.handleDeviceOffline);
    device.on("error", this.handleDeviceError);
    device.on("ready", this.handleDeviceReady);
  }

  async componentDidMount(): Promise<void> {
    const { deposit, dispatch } = this.props;
    const userId = deposit.invoice?.userId;
    await this.setupDevice();
    await this.fetchToken();
    if (userId) {
      await dispatch<any>(getUserIfNotInStore(userId));
    }
  }

  componentWillUnmount(): void {
    this.hangUp();
  }

  log(message: string): void {
    this.setState(prevState => ({ logs: [...prevState.logs, message] }));
  }

  async handleApproveCheck(values: ApproveCheckFormData): Promise<void> {
    try {
      const { deposit, dispatch } = this.props;
      await dispatch<any>(approveDeposit(deposit.id, values.authNumber));
      this.hangUp();
      this.closeModal();
    } catch (e) {
      handleReduxFormError((e as any));
    }
  }

  async handleRejectCheck(values: RejectCheckFormData): Promise<void> {
    try {
      const { deposit, dispatch } = this.props;
      await dispatch<any>(declineDeposit(deposit.id, values.reason));
      this.hangUp();
      this.closeModal();
    } catch (e) {
      handleReduxFormError((e as any));
    }
  }

  handleModalClose(): void {
    const { device } = this.state;
    const conn = device.activeConnection();
    if (conn && !window.confirm("There's an active call going on, closing this popup would terminate it. Are you sure?")) {
      return;
    }
    this.hangUp();
    this.closeModal();
  }

  closeModal(): void {
    const { dispatch } = this.props;
    dispatch(closeModal(ModalsConstants.CHECK_AUTHORIZER_CALLER));
  }

  hangUp(): void {
    const { device } = this.state;
    device.disconnectAll();
    device.destroy();
  }

  // eslint-disable-next-line
  sendDigits(digits?: string | number): void {
    digits = forceValueToString(digits);
    if (!digits?.length) {
      return;
    }
    const { device } = this.state;
    const conn = device.activeConnection();
    const { deposit } = this.props;
    if (conn) {
      if (deposit.checkType === 'tchek') {
        this.sendDigitsSlow(digits);
      } else {
        conn.sendDigits(digits);
      }
    }
  }

  sendDigitsSlow(digits: string): void {
    const { device } = this.state;
    const conn = device.activeConnection();
    let i = 0;
    function sendDigit(): void {
      if (conn) {
        conn.sendDigits(digits.charAt(i));
      }
      i += 1;
      if (i < digits.length) {
        const delay = SLOW_DIGIT_DELAY + Math.floor(Math.random() * SLOW_DIGIT_JITTER);
        setTimeout(sendDigit, delay);
      }
    }
    sendDigit();
  }


  // eslint-disable-next-line max-lines-per-function,complexity
  render(): React.ReactElement {
    const { deposit, users } = this.props;
    const { logs, muted, connected } = this.state;
    // eslint-disable-next-line
    // @ts-ignore
    const user = deposit.invoice?.userId ? _.get(users.data, deposit.invoice.userId) : undefined;
    const formattedAmount = Number(deposit.invoice?.amount).toFixed(2);
    let accountNumberLabel = 'Account Number';
    let checkNumber = (deposit.checkNumber || "").toString();
    let amount;

    const check = deposit && deposit.checkType && CheckTypes.getByKey(deposit.checkType)
      && CheckTypes.getByKey(deposit.checkType).display;

    switch (deposit.checkType) {
      case CheckTypes.FLEET_ONE.key:
        checkNumber = deposit.checkNumber + '#';
        amount = formattedAmount.replace('.', '*') + '#';
        break;
      case CheckTypes.TCHECK.key:
      case CheckTypes.EFS.key:
        checkNumber = deposit.checkNumber + '#';
        amount = formattedAmount.replace('.', '') + '#';
        break;
      case CheckTypes.USBANK.key:
        accountNumberLabel = 'Admin Code';
        amount = formattedAmount.replace('.', '*');
        break;
      default:
        amount = formattedAmount.replace('.', '');
        break;
    }
    return (
      <ModalContent className="check-authorizer-modal">
        <button onClick={this.handleModalClose} className="link-close icon-close cursor-pointer" />

        <table className="check-authorizer-caller-table">
          <thead>
            <tr>
              <th>
                {!connected && <Error severity={AlertSeverity.Error} error="Phone is not connected" />}
                {connected && <Error severity={AlertSeverity.Success} error={`Phone call in progress${muted ? ". Your microphone is muted" : ""}`} />}
              </th>
            </tr>
            <tr>
              <th>Call Operation</th>
            </tr>
          </thead>
          <tbody>
            <div className="call-operation fl">
              <tr>
                <td>Clerk</td>
                <td>{user?.first} {user?.last}</td>
              </tr>
              <tr>
                <td>Clerk Email</td>
                <td>{user?.email}</td>
              </tr>
              {check &&
                <tr>
                  <td>Check type</td>
                  <td>{check}</td>
                </tr>
              }
              {deposit.checkType === CheckTypes.TCHECK.key &&
                <>
                  <tr>
                    <td>Expiration</td>
                    <td>
                      <span id="expiration-date-label">
                        {deposit.expirationDate ? moment(deposit.expirationDate).format('MMM Do, YYYY h:mm A') : null}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Truck Number</td>
                    <td>{deposit.trailerNumber}</td>
                  </tr>
                  <tr>
                    <td>Mileage</td>
                    <td>{deposit.mileage}</td>
                  </tr>
                  <tr>
                    <td>Purchase Order (PO) Number</td>
                    <td>{deposit.poNumber}</td>
                  </tr>
                  <tr>
                    <td>Trip Number</td>
                    <td>{deposit.tripNumber}</td>
                  </tr>
                  <tr>
                    <td>Driver Number</td>
                    <td>{deposit.driverNumber}</td>
                  </tr>
                  <tr>
                    <td>Carrier name</td>
                    <td>{deposit.invoice?.payerName}</td>
                  </tr>
                </>
              }
              <tr>
                <td>Processing Time</td>
                <td>{deposit &&
                  // eslint-disable-next-line
                  // @ts-ignore
                  <DepositDuration timestamp={deposit.timestamp} />
                }</td>
              </tr>
              <tr>
                <td>Times Submitted</td>
                <td>{deposit && deposit.timesSubmitted}</td>
              </tr>
            </div>
            <div className="fr ml-30">
              {connected &&
                <tr>
                  <td>
                    <button id="hang-up-btn" className="btn-table-red" onClick={this.hangUp}>Hangup</button>
                  </td>
                  <td />
                </tr>
              }
              <tr>
                <td>
                  <button
                    className="btn-table-grey"
                    disabled={!connected}
                    id="deposit-number-btn"
                    onClick={(): void => this.sendDigits(deposit.invoice?.invoiceNumber)}
                  >
                    Deposit #
                  </button>
                </td>
                <td><span id="deposit-number-label">{deposit.invoice?.invoiceNumber}</span></td>
              </tr>
              <tr>
                <td>
                  <button
                    id="confirm-btn"
                    className="btn-table-grey"
                    disabled={!connected}
                    onClick={(): void => this.sendDigits('1')}
                  >
                    Confirm/Press 1
                  </button>
                </td>
                <td>1</td>
              </tr>
              <tr>
                <td>
                  <button
                    id="reenter-btn"
                    className="btn-table-grey"
                    disabled={!connected}
                    onClick={(): void => this.sendDigits('2')}
                  >
                    Re-enter/Press 2
                  </button>
                </td>
                <td>2</td>
              </tr>
              <tr>
                <td>
                  <button id="check-number-btn" className="btn-table-grey" onClick={(): void => this.sendDigits(checkNumber)} disabled={!connected}>
                    Check Number
                  </button>
                </td>
                <td><span id="check-number-label">{checkNumber}</span></td>
              </tr>
              <tr>
                <td>
                  <button
                    className="btn-table-grey"
                    onClick={(): void => this.sendDigits(`${deposit.expressCode}#`)}
                    disabled={!connected}
                    id="express-code-btn"
                  >
                    Express Code
                  </button>
                </td>
                <td><span id="express-code-label">{deposit.expressCode + '#'}</span></td>
              </tr>
              {deposit.checkType === CheckTypes.COMCHEK.key && <>
                <tr>
                  <td>
                    <button
                      id="trip-number-btn"
                      className="btn-table-grey"
                      onClick={(): void => this.sendDigits(deposit.tripNumber)}
                      disabled={!connected}
                    >
                      Trip Number
                    </button>
                  </td>
                  <td><span id="trip-number-label">{deposit.tripNumber}</span></td>
                </tr>
                <tr>
                  <td>
                    <button
                      id="driver-number-btn"
                      className="btn-table-grey"
                      onClick={(): void => this.sendDigits(deposit.driverNumber)}
                      disabled={!connected}
                    >
                      Driver Number
                    </button>
                  </td>
                  <td><span id="driver-number-label">{deposit.driverNumber}</span></td>
                </tr>
                <tr>
                  <td>
                    <button
                      id="unit-number-btn"
                      className="btn-table-grey"
                      onClick={(): void => this.sendDigits(deposit.unitNumber)}
                      disabled={!connected}
                    >
                      Unit Number
                    </button>
                  </td>
                  <td><span id="unit-number-label">{deposit.unitNumber}</span></td>
                </tr>
              </>}
              {(deposit.checkType === CheckTypes.INSTAMONEY.key || deposit.checkType === CheckTypes.USBANK.key) &&
                <tr>
                  <td>
                    <button
                      id="account-number-btn"
                      className="btn-table-grey"
                      onClick={(): void => this.sendDigits(deposit.accountNumber)}
                      disabled={!connected}
                    >
                      {accountNumberLabel}
                    </button>
                  </td>
                  <td><span id="account-number-label">{deposit.accountNumber}</span></td>
                </tr>
              }
              <tr>
                <td>
                  <button
                    id="amount-btn"
                    className="btn-table-grey"
                    onClick={(): void => this.sendDigits(amount)}
                    disabled={!connected}
                  >
                    Amount
                  </button>
                </td>
                <td><span id="amount-label">{amount}</span></td>
              </tr>
              <tr>
                <td>
                  <button className="btn-table-grey" id="our-phone-btn" onClick={(): void => this.sendDigits('6782573968#')} disabled={!connected}>
                    Our Phone
                  </button>
                </td>
                <td><span id="our-phone-label">6782573968#</span></td>
              </tr>
            </div>
            {connected &&
              <tr>
                <div className="spacer">
                  <td className="d-inline-block">Keypad</td>
                  <td className="d-inline-block">
                    <table>
                      <tr>
                        <td>
                          <button id="keypad-1-btn" className="btn-table-grey" onClick={(): void => this.sendDigits('1')}>1</button>
                        </td>
                        <td>
                          <button id="keypad-2-btn" className="btn-table-grey" onClick={(): void => this.sendDigits('2')}>2</button>
                        </td>
                        <td>
                          <button id="keypad-3-btn" className="btn-table-grey" onClick={(): void => this.sendDigits('3')}>3</button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <button id="keypad-4-btn" className="btn-table-grey" onClick={(): void => this.sendDigits('4')}>4</button>
                        </td>
                        <td>
                          <button id="keypad-5-btn" className="btn-table-grey" onClick={(): void => this.sendDigits('5')}>5</button>
                        </td>
                        <td>
                          <button id="keypad-6-btn" className="btn-table-grey" onClick={(): void => this.sendDigits('6')}>6</button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <button id="keypad-7-btn" className="btn-table-grey" onClick={(): void => this.sendDigits('7')}>7</button>
                        </td>
                        <td>
                          <button id="keypad-8-btn" className="btn-table-grey" onClick={(): void => this.sendDigits('8')}>8</button>
                        </td>
                        <td>
                          <button id="keypad-9-btn" className="btn-table-grey" onClick={(): void => this.sendDigits('9')}>9</button>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <button id="keypad-star-btn" className="btn-table-grey" onClick={(): void => this.sendDigits('*')}>*</button>
                        </td>
                        <td>
                          <button id="keypad-0-btn" className="btn-table-grey" onClick={(): void => this.sendDigits('0')}>0</button>
                        </td>
                        <td>
                          <button id="keypad-pound-btn" className="btn-table-grey" onClick={(): void => this.sendDigits('#')}>#</button>
                        </td>
                      </tr>
                      <tr>
                        <td>&nbsp;</td>
                        <td>
                          <button className="btn-table-grey" id="toggle-mute-btn" onClick={this.toggleMute}>
                            {!muted && <MicIcon fontSize="small" />}
                            {muted && <MicOffIcon fontSize="small" color="error" />}
                          </button>
                        </td>
                        <td>&nbsp;</td>
                      </tr>
                    </table>
                  </td>
                </div>
              </tr>
            }
          </tbody>
        </table>
        <ApproveCheckForm onSubmit={this.handleApproveCheck} />
        <DeclineCheckForm onSubmit={this.handleRejectCheck} deposit={deposit} />
        <div className="dialer-call-log">
          {logs.map((log, index) => {
            if (log.indexOf('error') >= 0 || log.indexOf('fail') >= 0 || log.indexOf('not') >= 0) {
              return <p key={index} style={{ color: '#D33231' }}>&gt;&nbsp;{log}</p>;
            }
            return <p key={index}>&gt;&nbsp;{log}</p>;
          })}
        </div>
      </ModalContent>
    );
  }
}

const mapStateToProps = ({ users }: GlobalState): PropsFromState => ({ users });
export default connect(mapStateToProps)(CheckAuthorizerCallerModal);

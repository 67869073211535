import { AdAction, CloseAdAction, OpenAdAction } from '../actions/ads';
import { AdActions } from '../constants/actions';
import { AdTypes } from '../constants/adTypes';

export type AdsState = AdTypes[];

export const initialState: AdsState = [];

export const ads = (state: AdsState = initialState, action: AdAction): AdsState => {
  switch (action.type) {
    case AdActions.OPEN_AD: {
      const { adType } = action as OpenAdAction;
      const adIndex = state.indexOf(adType);
      if (adIndex >= 0) { return state; }
      state.push(adType);
      return [...state];
    }
    case AdActions.CLOSE_AD: {
      const { adType } = action as CloseAdAction;
      return adType ? state.filter(type => type !== adType) : state.slice(0, -1);
    }
    default:
      return state;
  }
}

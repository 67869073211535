export class PublicInvoiceEndpoints {
  static getInvoice = (token: string): string => `/api/v1/public/invoices/${token}`;
  static getInvoiceReceipt = (token: string): string => `/api/v1/public/invoices/${token}/receipt/url`;
  static updateInvoice = (token: string): string => `/api/v1/public/invoices/${token}`;
  static addSignature = (token: string): string => `/api/v1/public/invoices/${token}/signature`;
  static getFileForInvoice = (token: string, fileId: string): string =>
    (`/api/v1/public/invoices/${token}/attachment/${fileId}`)
  static markInvoiceProcessing = (token: string): string => `/api/v1/public/invoices/${token}/mark-processing`;
  static unmarkInvoiceProcessing = (token: string): string => `/api/v1/public/invoices/${token}/unmark-processing`;
}

import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import ModalContent from '../../components/modals/ModalContent';
import { closeModal } from '../../actions/modals';
import { ModalsConstants } from '../../constants/modals';
import { showErrorAlert } from '../../actions/alerts';
import { resetBankAccount } from '../../actions/bank';
import { LargePrimaryButton } from '../../components/ui/buttons/LargePrimaryButton';
import { LargeSecondaryButton } from '../../components/ui/buttons/LargeSecondaryButton';

interface Props extends DispatchProp, OwnProps { }

interface OwnProps {
  companyId: string;
}

class BankAccountChangeModal extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  async handleConfirm(): Promise<void> {
    const { dispatch, companyId } = this.props;
    try {
      await dispatch<any>(resetBankAccount(companyId));
    } catch (e) {
      dispatch(showErrorAlert((e as any)?.message));
    }
    dispatch(closeModal(ModalsConstants.CHANGE_BANK_ACCOUNT));
  }

  handleClose(): void {
    const { dispatch } = this.props;
    dispatch(closeModal(ModalsConstants.CHANGE_BANK_ACCOUNT));
  }

  render(): React.ReactElement {
    return (
      <ModalContent className="invoice-creation-confirm-modal">
        <p className="modal-message">
          You will not be able to receive funds from check invoices in
          RoadSync until you link and verify your new bank account.
          Are you sure you want to change your bank account RoadSync?
        </p>
        <LargePrimaryButton onClick={this.handleConfirm} id="change-bank-account-btn">
          Yes
        </LargePrimaryButton>
        <LargeSecondaryButton variant="outlined" onClick={this.handleClose} id="cancel-change-bank-account-btn">
          No
        </LargeSecondaryButton>
      </ModalContent>
    );
  }
}

export default connect()(BankAccountChangeModal);

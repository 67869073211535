
import React from 'react';
import Modals from '../../containers/modals/Modals';

interface Props {
  children: any;
  wrapperClass?: string;
  mainContainerClass?: string;
}

const MainWrapper = ({ children, wrapperClass, mainContainerClass }: Props) => {

  return (
    <main className={`main-container ${mainContainerClass || ''}`}>
      <Modals />
      <div className={`section-first wrap ${wrapperClass || ''}`}>
        <div className="section-inner">
          {children}
        </div>
      </div>
    </main>
  );
};

export default MainWrapper;

import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import ModalContent from '../../components/modals/ModalContent';
import { closeModal } from '../../actions/modals';
import { ModalsConstants } from '../../constants/modals';

interface Props {
  text: string;
}

class InvoiceDeclineReasonModal extends React.Component<Props & DispatchProp> {

  handleConfirm(): void {
    const { dispatch } = this.props;
    dispatch(closeModal(ModalsConstants.DECLINE_REASON));
  }

  // eslint-disable-next-line
  render(): React.ReactElement {
    const { text } = this.props;
    return (
      <ModalContent
        className="invoice-decline-reason-modal"
        primaryActionText={'Okay'}
        primaryActionClick={(): void => this.handleConfirm()}
      >
        <p className="modal-title">Rejection Reason:</p>
        <p className="modal-message">{text}</p>
      </ModalContent>
    );
  }
}

export default connect()(InvoiceDeclineReasonModal);

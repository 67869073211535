import React from 'react';
import { Button as MUIButton, makeStyles, createStyles } from '@material-ui/core';
import { ButtonProps } from './ButtonProps';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    root: { whiteSpace: "normal" },
    noWrap: { whiteSpace: "nowrap" },
  }),
);

export const ButtonRouter: React.FC<ButtonProps> = (props: ButtonProps): React.ReactElement => {
  const history = useHistory();
  const classes = useStyles();
  const { className, onClick, href, to, target, noWrap, ref, ...inherited } = props;
  const clickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    const url = href || to;
    if (url) {
      e.preventDefault();
      if (url.indexOf("//") > -1) {
        window.open(url, target);
      } else {
        history.push(url);
      }
    }
    if (onClick) onClick(e);
  }
  return (
    <MUIButton onClick={clickHandler} className={`${className || ''} ${noWrap ? classes.noWrap : classes.root}`} {...inherited}>
      {props.children}
    </MUIButton>
  );
};

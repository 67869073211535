import React from 'react';
import { ButtonRow } from '../Buttons';
import { FormButtonProps } from '../buttons/FormButtonProps';
import { Grid } from "@roadsync/roadsync-ui";
import { Error } from '../Error';

interface Props {
  error?: string;
  title?: string;
  className?: string;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  submitButton?: FormButtonProps;
  secondaryButton?: FormButtonProps;
  children?: React.ReactNode;
}

const getSubmitButton = (props: Props): React.ReactNode => (
  <React.Fragment>
    {props.submitButton &&
      <Grid item>
        <ButtonRow
          primary={props.submitButton}
          secondary={props.secondaryButton}
        />
      </Grid>
    }
  </React.Fragment>
);

export function Form(props: Props): React.ReactElement {
  return (
    <form onSubmit={props.onSubmit} className={props.className}>
      <Grid
        container
        spacing={2}
        alignItems="stretch"
        justifyContent="flex-start"
        direction="column">
        <Error error={props.error} />
        {props.children}
        {getSubmitButton(props)}
      </Grid>
    </form>
  );
}

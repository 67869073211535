import React from "react";
import wexLogo from "../../../assets/img/wexLogo.svg";
import useStyles from "./WexLogo.css";

const WexLogo: React.FC = () => {
  const classes = useStyles();

  return <img src={wexLogo} alt="WEX logo" className={classes.wexLogo} />;
};

export default WexLogo;

export enum AdminSideMenuItems {
    COMPANY_LIST = 'admin/COMPANY_LIST',
    USER_LIST = 'admin/USER_LIST',
    TRANSFERS = 'admin/TRANSFERS',
    REPORTS = 'admin/REPORTS',
    REPORTS_RECIPIENTS = 'admin/REPORTS_RECIPIENTS',
    EMAILS_LOGS = 'admin/EMAILS_LOGS',
    SURVEY_MGMT = 'admin/SURVEY_MGMT',
    QA_MANAGEMENT_CONSOLE = 'admin/QA_MANAGEMENT_CONSOLE',
    WORK_ORDER_TEMPLATES = 'admin/WORK_ORDER_TEMPLATES',
}

export enum WorkOrderSideMenuItems {
    TEMPLATES = 'company/settings/location/TEMPLATES',
    CUSTOM_FIELDS = 'company/settings/location/CUSTOM_FIELDS',
}

export enum BankSideMenuItems {
    BANKING = 'bank/BANKING',
    CARDS = 'bank/CARDS',
    CHECKS = 'bank/CHECKS',
    TERMS = 'bank/TERMS',
    ONBOARDING = 'bank/ONBOARDING',
}

export enum ChecksSideMenuItems {
    LIST = 'checks/LIST',
    DEPOSITS_LIST = 'checks/DEPOSITS_LIST',
    GENERATE = 'checks/GENERATE',
    AVAILABLE_CHECKS = 'checks/AVAILABLE_CHECKS',
}

export enum CompanySideMenuItems {
    DASHBOARD = 'company/DASHBOARD',
    TEAM = 'company/TEAM',
    SETTINGS = 'company/SETTINGS',
    WAREHOUSES = 'company/WAREHOUSES',
    LOGS = 'company/LOGS',
    DEPOSITS = 'company/DEPOSITS',
    BANK_ACCOUNT = 'company/BANK_ACCOUNT',
    COMPANY_FEE = 'company/COMPANY_FEE',
    COMPANY_FEATURE = 'company/COMPANY_FEATURE',
    WORK_ORDERS = 'company/WORK_ORDERS',
    WORK_ORDERS_CUSTOM_FIELDS = 'company/WORK_ORDERS_CUSTOM_FIELDS',
    SECURITY = 'company/SECURITY',
    REPORT_SETTINGS = 'company/REPORT_SETTINGS',
    REPORTS = 'company/REPORTS',
    COMPANY_CARRIER = 'company/COMPANY_CARRIER',
    ADMIN_CENTER = 'company/ADMIN_CENTER',
    PAYOUTS = 'company/PAYOUTS',
    QUICKBOOKS = 'company/QUICKBOOKS',
    INVOICES = 'company/INVOICES',
    ONESOURCE = 'company/ONESOURCE',
    INTEGRATIONS = 'company/INTEGRATIONS',
    ADDRESS_BOOK = 'company/ADDRESS_BOOK',
}

export enum LocationSideMenuItems {
    ADMIN_CENTER = 'company/settings/location/ADMIN_CENTER',
    SETTINGS = 'company/settings/location/SETTINGS',
    PRODUCTS = 'company/settings/location/PRODUCTS',
    TAXES = 'company/settings/location/TAXES',
    CUSTOM_FIELDS = 'company/settings/location/CUSTOM_FIELDS',
    SHIFTS = 'company/settings/location/SHIFTS',
    DEPARTMENTS = 'company/settings/location/DEPARTMENTS',
    WORK_ORDERS = 'company/settings/location/WORK_ORDER_SETTINGS',
    CARD_READERS = 'company/settings/location/CARD_READERS'
}

export enum MainTopMenuItems {
    DASHBOARD = 'admin/DASHBOARD',
    COMPANIES = 'admin/COMPANIES',
    INVOICES = 'admin/INVOICES',
    CHECKS = 'admin/CHECKS',
    CHECK_AUTHORIZER = 'admin/CHECK_AUTHORIZER',
    ACTIVITIES = 'admin/ACTIVITIES',
    REPORTS = 'admin/REPORTS',
    SETTINGS = 'admin/SETTINGS',
    WORK_ORDERS = 'admin/WORK_ORDERS',
    V1_INVOICE = 'admin/V1_INVOICE',
    PAY_BILLS = 'admin/PAY_BILLS',
    PAID_BILLS = 'admin/PAID_BILLS',
    TRANSFERS = 'admin/TRANSFERS',
    BILLING = 'admin/BILLING',
    HELP_ME = 'admin/HELP_ME',
    CREATE_INVOICE = 'admin/CREATE_INVOICE',
    CREATE_WORK_ORDER = 'admin/CREATE_WORK_ORDER',
    DEPOSITS = 'admin/DEPOSITS',
    INSTANT_PAYOUT = 'admin/INSTANT_PAYOUT',
    ADDRESS_BOOK = 'admin/ADDRESS_BOOK',
}

export enum ReportsSideMenuItems {
    LIST = 'reports/LIST',
    VOIDED_INVOICES_LIST = 'reports/VOIDED_INVOICES_LIST',
    REFUNDED_INVOICES_LIST = 'reports/REFUNDED_INVOICES_LIST',
    JOURNAL_ENTRY = 'reports/JOURNAL_ENTRY',
}

export enum BaseMenuItems {
    CREATE_WORK_ORDER = '/CREATE_WORK_ORDER',
    WORK_ORDERS = '/WORK_ORDERS',
    CREATE_INVOICE = '/CREATE_INVOICE',
    INVOICES = '/INVOICES',
    DASHBOARD = '/DASHBOARD',
    COMPANIES = '/COMPANIES',
    CHECKS = '/CHECKS',
    CHECK_AUTHORIZER = '/CHECK_AUTHORIZER',
    REPORTS = '/REPORTS',
    PAY_BILLS = '/PAY_BILLS',
    PAID_BILLS = '/PAID_BILLS',
    BILLING = '/BILLING',
}

export enum RSEMployeeMenuItems {
    DASHBOARD = 'RSEmployee/DASHBOARD',
    INVOICES = 'RSEmployee/INVOICES',
    CHECKS = 'RSEmployee/CHECKS',
    REPORTS = 'RSEmployee/REPORTS',
}

export enum SettingsSideMenuItems {
    REPORTS_RECIPIENTS = 'settings/REPORTS_RECIPIENTS',
    EMAILS_LOGS = 'settings/EMAILS_LOGS',
    SURVEY_MGMT = 'settings/SURVEY_MGMT',
}

import { isEqual } from 'lodash';
import React from 'react';

interface Props {
  bundle: Promise<{}>;
  ready: Function;
}

interface State {
  mod: Element | null;
}

class Bundle extends React.Component<Props, State> {
  state = {
    mod: null,
  };

  mounted = false;

  componentDidMount(): void {
    const { bundle, ready } = this.props;
    this.mounted = true;
    this.load(bundle, ready);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidUpdate(prevProps, prevState): void {
    const { bundle, ready } = this.props;
    if(!isEqual(bundle, prevProps.bundle)
    || !isEqual(ready, prevProps.ready)) {
      this.load(bundle, ready);
    }
  }

  load(bundle, ready) {
    bundle.then(mod => {
      const component = ready(mod);
      if (this.mounted) {
        this.setState({
          mod: component,
        });
      }
    });
  }

  render() {
    const { mod } = this.state;
    return mod ? React.createElement(mod as any, {}) : null;
  }
}

export default Bundle;

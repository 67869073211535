import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { ModalsConstants } from '../../constants/modals';
import ModalContent from '../../components/modals/ModalContent';
import { closeModal } from '../../actions/modals';
import { RouteComponentProps, withRouter } from 'react-router';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { WorkOrderTemplate } from '../../types/WorkOrderTemplate';
import { TemplateSelect } from '../../components/location/WorkOrderTemplateSelectField';
import { getWorkOrderLocationPdfPreview } from '../../actions/workOrderTemplates';

interface WorkOrderPdfForEmployeesFormData {
  workOrderTemplateId: string;
}

interface Props extends DispatchProp, RouteComponentProps, OwnProps { }

interface OwnProps {
  text: React.ReactNode;
  locationId: string;
  workOrderTemplates: WorkOrderTemplate[];
}

interface State {
  selectedTemplateId: string;
}

type InjectedProps = InjectedFormProps<WorkOrderPdfForEmployeesFormData, Props> & Props;

class WorkOrderPdfForEmployees extends React.Component<InjectedProps, State> {

  constructor(props: InjectedProps) {
    super(props);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.state = { selectedTemplateId: '' };
  }

  render(): React.ReactNode {
    return (
      <ModalContent className="workorder-create-modal">
        <button
          onClick={this.handleModalClose}
          className="link-close icon-close cursor-pointer"
        />
        <p className="modal-message">{this.props.text}</p>
        <form onSubmit={this.handleSubmit}>
          <Field
            name="workOrderTemplateId"
            type="select"
            onChange={this.onChange}
            component={TemplateSelect.bind(null, this.props.workOrderTemplates)}
          />
          <div className="create-workorder-controls">
            <button className="btn-dark" disabled={!this.state.selectedTemplateId}>Print</button>
          </div>
        </form>
      </ModalContent>
    );
  }

  handleModalClose(): void {
    const { dispatch } = this.props;
    dispatch(closeModal(ModalsConstants.WORK_ORDER_PDF_FOR_EMPLOYEES));
  }

  handleSubmit(formEvent: React.FormEvent<HTMLFormElement>): void {
    const { locationId, workOrderTemplates, dispatch } = this.props;
    formEvent.preventDefault();

    const selectedTemplate = workOrderTemplates.find((val) => {
      return val.id === this.state.selectedTemplateId
    });
    if (!selectedTemplate) {
      return;
    }

    dispatch<any>(getWorkOrderLocationPdfPreview(
      locationId,
      selectedTemplate.description
    ));

    dispatch(closeModal(ModalsConstants.WORK_ORDER_PDF_FOR_EMPLOYEES));
  }

  onChange(event: any): void {
    //on change event here return string value from Select
    this.setState({ selectedTemplateId: event })
  }

}

export default withRouter(connect()(
  reduxForm<WorkOrderPdfForEmployeesFormData, Props>({ form: 'WorkOrderPdfForEmployeesForm' })(WorkOrderPdfForEmployees)
));

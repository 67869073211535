import { TwoFactorActions } from '../constants/actions';

export interface TwoFactorState {
  data?: { secret?: string };
  loading?: boolean;
}

export function twoFactor(state: TwoFactorState = { data: {}, loading: false }, action: Record<string, any> = {}): TwoFactorState {
  switch (action.type) {
    case TwoFactorActions.REQUEST_TWO_FACTOR_AUTH_NEW:
      return {
        ...state,
        data: action.data,
        loading: true,
      };
    case TwoFactorActions.RECEIVE_TWO_FACTOR_AUTH_NEW:
      return {
        ...state,
        data: action.data,
        loading: false,
      };
    default:
      return state;
  }
}

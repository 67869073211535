export class CompanyCarriers {
  static getCarriersByCompanyIdPublic = (companyId: string): string => `/api/v1/public/companies/${companyId}/carriers`;
  static getCarriersByCompanyId = (companyId: string, q?: string): string =>
    (`/api/v1/companies/${companyId}/carriers${q ? '?q=' + q : ''}`)
  static getCarriersListByCompanyId = (companyId: string, offset: number, limit: number): string => {
    return `/api/v1/companies/${companyId}/carriers/?offset=${offset}&limit=${limit}`;
  }
  static createCarrier = (companyId: string): string => `/api/v1/companies/${companyId}/carriers`;
  static updateCarrierById = (carrierId: string): string => `/api/v1/carriers/${carrierId}`;
  static getCarrierByById = (carrierId: string): string => `/api/v1/carriers/${carrierId}`;
  static deleteCarrierById = (carrierId: string): string => `/api/v1/carriers/${carrierId}`;
  static importCarriersList = (companyId: string): string => `/api/v1/companies/${companyId}/carriers/import`;
  static getUniversalCarriers = (search: string, offset: number, limit: number): string => {
    const route = `/api/v1/universal-carriers?offset=${offset}&limit=${limit}`;
    return search ? `${route}&Search=${search}` : route;
  }
}

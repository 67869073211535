import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  listItem: {
    border: `1px solid ${theme.palette.secondary.main}`,
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[2],
    '& .MuiListItemAvatar-root': {
      alignItems: "center",
      display: "flex",
      height: theme.spacing(5),
      justifyContent: "center",
      marginRight: theme.spacing(2),
      width: theme.spacing(5),
    },
    '& img': {
      maxWidth: theme.spacing(5),
      maxHeight: theme.spacing(5),
    },
  },
}));

export default useStyles;

import React, { useEffect, useState } from 'react';
import { reduxForm, Field, InjectedFormProps, FormErrors } from 'redux-form';
import { lockOutConfirmationValidation, required } from '../../services/app/validators';
import { Error } from '../ui/Error';
import RadioButtonGroup from '../controls/RadioButtonGroup';
import { CheckboxState } from '../../constants/common';
import { RenderTextField } from '../ui/form/RenderTextField';
import { P, Grid } from '@roadsync/roadsync-ui';
import HiddenSubmit from '../app/HiddenSubmitButton';
import { LockOutLocationUsersFormData } from '../location/LockOutLocationUsersForm';
import { YesNoCheckboxField } from '../../types/Field';

export interface LockOutCompanyUsersFormData {
  isLocked: string;
  confirmation?: string;
}

type Props = InjectedFormProps<LockOutCompanyUsersFormData, OwnProps> & OwnProps;

interface OwnProps {
  initialValues: {
    id: string;
    isLocked: string;
  };
  handleSetPrimaryActionDisabled: (v: boolean) => void;
}

const YesNoCheckbox = (field: YesNoCheckboxField): React.ReactNode => (
  <RadioButtonGroup
    fieldKey="key"
    fieldDisplay="display"
    options={CheckboxState.getStates()}
    {...field}
  />
);

// eslint-disable-next-line max-lines-per-function
const LockOutCompanyUsersForm: React.FC<Props> = (props: Props) => {
  const { error, handleSubmit, submitting, valid, handleSetPrimaryActionDisabled, initialValues } = props;
  useEffect(() => {
    handleSetPrimaryActionDisabled(!valid || submitting);
  }, [handleSetPrimaryActionDisabled, valid, submitting]);

  const [isLocked, setIsLocked] = useState(initialValues.isLocked === CheckboxState.CHECKED.key);
  const updateIsLocked = (value): void => {
    setIsLocked(value === CheckboxState.CHECKED.key);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" spacing={4}>
        {error && <Grid item><Error error={error} /></Grid>}
        <Grid item>
          <Field
            name="isLocked"
            component={YesNoCheckbox}
            validate={required}
            required={true}
            defaultValue={initialValues.isLocked}
            onChange={updateIsLocked}
          />
        </Grid>

        <Grid item>
          <P variant="h6">If you want to lock out all users associated with this company,
            please type the following in the text field below: Lock out all users.</P>
        </Grid>

        <Grid item>
          <Field
            name={'confirmation'}
            label="Lock out all users"
            type="text"
            component={RenderTextField}
            required={isLocked}
            disabled={!isLocked}
          />
        </Grid>

        <HiddenSubmit text="Save" disabled={!valid || submitting} id="lock-out-company-form-submit" />

      </Grid>
    </form>
  );
};

const validate = (values: LockOutLocationUsersFormData): FormErrors<LockOutLocationUsersFormData> => {
  const errors: FormErrors<LockOutLocationUsersFormData> = {};
  const isConfirmationRequired = values.isLocked === 'true';
  if (isConfirmationRequired) {
    errors.confirmation = required(values.confirmation) || lockOutConfirmationValidation(values.confirmation);
  }
  return errors;
};

export default reduxForm<LockOutCompanyUsersFormData, OwnProps>({ form: 'lockOutCompanyUsers', validate })(LockOutCompanyUsersForm);

import { TransactionItem } from "../../components/deposits/types/TransactionItem";
import { InvoiceDeclineReasons } from "../../constants/invoiceDeclineReasons";
import moment from 'moment-timezone';

export function isExpressCodeError(error?: string): boolean {
    return !!error && (
        error === InvoiceDeclineReasons.EXPRESS_CODE.key
        || error === InvoiceDeclineReasons.COMDATA_MAXIMUM_DRAW_LIMIT.key
        || error === InvoiceDeclineReasons.COMDATA_TRY_CHECK_AUTH.key
        || error === InvoiceDeclineReasons.COMDATA_EXPRESS_INACTIVE.key
        || error === InvoiceDeclineReasons.FUNDS.key
    );
}

export function isDateGreaterThan(date1: string, date2: string): boolean {
    return moment(date1).isAfter(moment(date2));
}

export function isDateWithinRange(date: string, rangeStart: string, rangeEnd: string): boolean {
    return moment(date).isBetween(moment(rangeStart), moment(rangeEnd));
}

export function isTransaction(depositOrTransaction: TransactionItem): boolean {
    return depositOrTransaction?.id !== undefined;
}

export function getPreparedTransaction(item: TransactionItem): TransactionItem {
    return isTransaction(item) ? {
        ...item,
        completedAt: item?.settledAt ? item.settledAt : item.completedAt,
    } : {
        ...item,
    }
}

export function depositsTransactionsSortedByCreatedAtDesc(items: TransactionItem[]) {
    return items.sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix());
}

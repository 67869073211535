import moment from 'moment';
import { buildUrl } from '../utils';

export class ReportEndpoints {
  static getStatsByCompany = (
    companyId: string, locationId: string, departmentId: string, shiftId: string, fromCreatedAat: string, toCreatedAt: string, status?: string[]
    // eslint-disable-next-line max-params
  ): string => (
    `/api/v1/invoices/report/stats?${ReportEndpoints
      .getRouteParams(companyId, locationId, departmentId, shiftId, fromCreatedAat, toCreatedAt, status)}`)

  static getInvoicesByCompany = (
    offset: number, limit: number, companyId: string, locationId: string,
    departmentId: string, shiftId: string, fromCreatedAat: string, toCreatedAt: string, invoiceListFilter, status?: string[]
    // eslint-disable-next-line max-params
  ): string => (
    `/api/v1/invoices/?offset=${offset}&limit=${limit}&${ReportEndpoints
      .getRouteParams(companyId, locationId, departmentId, shiftId, fromCreatedAat, toCreatedAt, status, invoiceListFilter)}`)

  static getVoidedInvoicesByCompany = (offset: number, limit: number, companyId: string, locationId: string,
    departmentId: string, shiftId: string, fromDel: string,
    toDel: string, invoiceListFilter
    // eslint-disable-next-line max-params
  ): string => (
    `/api/v1/invoices/?offset=${offset}&limit=${limit}${ReportEndpoints
      .getRouteParamsVoided(companyId, locationId, departmentId,
        shiftId, fromDel, toDel, invoiceListFilter)}`)

  static getRefundedInvoicesByCompany = (
    offset: number, limit: number, companyId: string, locationId: string,
    departmentId: string, shiftId: string, fromAuth: string,
    toAuth: string, invoiceListFilter
    // eslint-disable-next-line max-params
  ): string => (
    `/api/v1/invoices/?offset=${offset}&limit=${limit}${ReportEndpoints
      .getRouteParamsRefunded(companyId, locationId, departmentId,
        shiftId, fromAuth, toAuth, invoiceListFilter)}`)

  static getInvoicesCsv = (): string => `/api/v1/invoices/report/csv?includevoided=true`;

  static getInvoicesByCompanyCsv = (
    companyId: string, locationId: string, departmentId: string, shiftId: string, fromCreatedAat: string, toCreatedAt: string, status?: string[]
    // eslint-disable-next-line max-params
  ): string => (
    `/api/v1/invoices/report/csv?${ReportEndpoints
      .getRouteParams(companyId, locationId, departmentId, shiftId, fromCreatedAat, toCreatedAt, status)}`)

  static getVoidedInvoicesByCompanyCsv = (
    companyId: string, locationId: string, departmentId: string, shiftId: string, fromDel: string, toDel: string
    // eslint-disable-next-line max-params
  ): string => (
    `/api/v1/invoices/report/csv?${ReportEndpoints
      .getRouteParamsVoided(companyId, locationId, departmentId, shiftId,
        fromDel, toDel)}`)

  static getRefundedInvoicesByCompanyCsv = (
    companyId: string, locationId: string, departmentId: string, shiftId: string, fromAuth: string, toAuth: string
    // eslint-disable-next-line max-params
  ): string => (
    `/api/v1/invoices/report/csv?${ReportEndpoints
      .getRouteParamsRefunded(companyId, locationId, departmentId, shiftId, fromAuth, toAuth)}`)

  static getJournalByCompanyCsv = (
    companyId: string, fromAuth: string, toAuth: string
  ): string => (
    `/api/v1/invoices/journal/csv?${ReportEndpoints
      .getRouteParamsJournal(companyId, fromAuth, toAuth)}`)

  static getReportInfoByName = (reportName: string, args: object): string => buildUrl(`/api/v1/reports/${reportName}`, args);

  static fetchReportSettings = (companyId: string): string => `/api/v1/companies/${companyId}/report-settings`;

  static getInvoiceSettlementCSV = (settlementId: string): string => `/api/v1/invoices/settlements/${settlementId}/csv`;

  static getSettlementCSV = (settlementId: string): string => `/api/v1/settlements/${settlementId}/csv`;

  static getTransactionBatchCSV = (transactionId: string): string => `/api/v1/transactions/batch/${transactionId}/csv`;

  // eslint-disable-next-line max-params,max-lines-per-function,max-statements
  private static getRouteParams(
    companyId: string,
    locationId: string,
    departmentId: string,
    shiftId: string,
    fromCreatedAat: string,
    toCreatedAt: string,
    status: string[] = ['completed'],
    invoiceListFilter?,
  ): string {
    const params: string[] = [];

    params.push(`invoicestatuses=${status.join(',')}`);

    if (companyId) {
      params.push(`companyid=${companyId}`);
    }

    if (locationId) {
      params.push(`locationid=${locationId}`);
    }

    if (departmentId) {
      params.push(`departmentid=${departmentId}`);
    }

    if (shiftId) {
      params.push(`shiftid=${shiftId}`);
    }

    if (fromCreatedAat) {
      params.push(`fromcreatedat=${moment.utc(fromCreatedAat).format()}`);
    }

    if (toCreatedAt) {
      params.push(`tocreatedat=${moment.utc(toCreatedAt).format()}`);
    }

    if (invoiceListFilter) {
      params.push(`search=${invoiceListFilter}`);
    }

    return params.join('&');
  }

  // eslint-disable-next-line max-params,max-lines-per-function,max-statements
  private static getRouteParamsVoided(
    companyId: string,
    locationId: string,
    departmentId: string,
    shiftId: string,
    fromDel: string,
    toDel: string,
    invoiceListFilter?
  ): string {
    const params = [''];

    if (companyId) {
      params.push(`companyid=${companyId}`);
    }

    if (locationId) {
      params.push(`locationid=${locationId}`);
    }

    if (departmentId) {
      params.push(`departmentid=${departmentId}`);
    }

    if (shiftId) {
      params.push(`shiftid=${shiftId}`);
    }

    if (fromDel) {
      params.push(`fromdel=${moment.utc(fromDel).format()}`);
    }

    if (toDel) {
      params.push(`todel=${moment.utc(toDel).format()}`);
    }

    if (invoiceListFilter) {
      params.push(`search=${invoiceListFilter}`);
    }

    params.push(`includevoided=true`);
    params.push(`includeregular=false`);
    params.push(`excludeauthorized=true`);

    return params.join('&');
  }

  // eslint-disable-next-line max-params,max-lines-per-function,max-statements
  private static getRouteParamsRefunded(
    companyId: string,
    locationId: string,
    departmentId: string,
    shiftId: string,
    fromAuth: string,
    toAuth: string,
    invoiceListFilter?
  ): string {
    const params = [''];

    if (companyId) {
      params.push(`companyid=${companyId}`);
    }

    if (locationId) {
      params.push(`locationid=${locationId}`);
    }

    if (departmentId) {
      params.push(`departmentid=${departmentId}`);
    }

    if (shiftId) {
      params.push(`shiftid=${shiftId}`);
    }

    if (fromAuth) {
      params.push(`fromauth=${moment.utc(fromAuth).format()}`);
    }

    if (toAuth) {
      params.push(`toauth=${moment.utc(toAuth).format()}`);
    }

    if (invoiceListFilter) {
      params.push(`search=${invoiceListFilter}`);
    }

    params.push(`includevoided=true`);
    params.push(`includeregular=false`);

    return params.join('&');
  }

  // eslint-disable-next-line max-lines-per-function,max-params
  private static getRouteParamsJournal(
    companyId: string,
    fromAuth: string,
    toAuth: string,
    invoiceListFilter?
  ): string {
    const params = [''];

    if (companyId) {
      params.push(`companyid=${companyId}`);
    }

    if (fromAuth) {
      params.push(`fromauth=${moment.utc(fromAuth).format()}`);
    }

    if (toAuth) {
      params.push(`toauth=${moment.utc(toAuth).format()}`);
    }

    if (invoiceListFilter) {
      params.push(`search=${invoiceListFilter}`);
    }

    return params.join('&');
  }
}

import React from "react";
import { WithInvoiceTypeProps } from "../WithInvoiceTypeProps";

const IfInvoiceType: React.FC<WithInvoiceTypeProps> = (props: WithInvoiceTypeProps): React.ReactElement => {
  const { invoice, children, types } = props;
  return (
    <React.Fragment>
      {types.map(t => t.key).includes(invoice.type) && children}
    </React.Fragment>
  )
}

export default IfInvoiceType;

import React from 'react';
import { Error } from '../../../../components/ui/Error';
import { Grid, StyledComponentProps, withStyles } from '@material-ui/core';
import { Form, InjectedFormProps, reduxForm } from 'redux-form';
import { RenderTextField } from '../../../../components/ui/form/RenderTextField';
import { city, firstAndLastName, preventCreditCardNumbers, required, state, streetAddress, zipCode } from '../../../../services/app/validators';
import { Invoice, GlobalState } from '../../../../types';
import { connect } from 'react-redux';
import styles from "./CardDetailsForm.css";
import SignatureBox from '../../../../components/ui/SignatureBox';
import { Field } from "../../../../components/ui/form/Field";
import { getCompanyFromState } from '../../../../services/app/split';
import { isZipBillingAddressEnabled } from '../../../../services/app/company';

export interface CardDetailsFormData {
    billingFirstName?: string;
    billingLastName?: string;
    zipCode?: string;
    license?: string;
    streetAddress?: string;
    cardState?: string;
    city?: string;
    cvv?: string
}

type PropsFromState = Pick<GlobalState, "auth" | "companies" | "publicData">;

interface State {
    isBillingInfoRequired?: boolean;
}

interface OwnProps extends PropsFromState {
    initialValues?: any
    invoice?: Invoice;
    licenseRequired?: boolean;
    openAddSignatureModal?: () => void;
    signatureEnabled?: boolean;
    signatureUrl?: string;
}

type Props = InjectedFormProps<CardDetailsFormData, OwnProps> & OwnProps & StyledComponentProps;


class CardDetailsForm extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {}
    }

    componentDidMount(): void {
        const { auth, companies, publicData } = this.props;
        const company = getCompanyFromState(companies, publicData, auth.me);
        const isBillingInfoRequired = isZipBillingAddressEnabled(company)
        this.setState({ isBillingInfoRequired });
    }

    isSignatureRequired(): boolean {
        const { signatureEnabled } = this.props;
        return !!signatureEnabled;
    }

    render(): React.ReactElement {
        const { isBillingInfoRequired } = this.state;
        const { handleSubmit, error, signatureUrl, licenseRequired, children, openAddSignatureModal, initialValues } = this.props;

        return (
            <Form onSubmit={handleSubmit}>
                <Grid container direction="column" wrap="nowrap" spacing={2}>
                    {!!error &&
                        <Grid item>
                            <Error error={error} scrollIntoView />
                        </Grid>
                    }
                    {children &&
                        <Grid item>{children}</Grid>
                    }
                    <Grid item>
                        <Grid container direction="row" wrap={initialValues ? "wrap" : "nowrap"} spacing={2}>

                            {initialValues?.ccnumber &&
                                <>
                                    <Grid item xs={12} sm={6}>
                                        <Field id="ccnumber" name="ccnumber" label="Credit Card Number" type="text" component={RenderTextField} disabled />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Field id="cvv" name="cvv" label="CVV" type="text" component={RenderTextField} validate={[required]} />
                                    </Grid>
                                </>}
                            <Grid item xs={12} sm={6}>
                                <Field id="billingFirstName" name="billingFirstName" label="Cardholder First Name" type="text" component={RenderTextField} validate={[required, preventCreditCardNumbers, firstAndLastName]} disabled={initialValues ? true : false} data-testid="first-name-field" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Field id="billingLastName" name="billingLastName" label="Cardholder Last Name" type="text" component={RenderTextField} validate={[required, preventCreditCardNumbers, firstAndLastName]} disabled={initialValues ? true : false} data-testid="last-name-field" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container wrap='wrap' spacing={2}>
                            <Grid item xs={12} sm={5}>
                                <Field name="streetAddress" label="Street Address (ex. 123 Main St)" type="text" component={RenderTextField} validate={isBillingInfoRequired ? [required, streetAddress] : streetAddress} disabled={initialValues ? true : false} data-testid="address-field" />
                            </Grid>
                            <Grid item xs={5} sm={3}>
                                <Field name="city" label="City" type="text" component={RenderTextField} validate={isBillingInfoRequired ? [required, city] : [city]} disabled={initialValues ? true : false} data-testid="city-field" />
                            </Grid>
                            <Grid item xs={3} sm={2}>
                                <Field name="cardState" label="State" type="text" component={RenderTextField} validate={isBillingInfoRequired ? [required, state] : [state]} disabled={initialValues ? true : false} data-testid="state-field" />
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <Field name="zipCode" label="Zip Code" type="text" component={RenderTextField} validate={[required, zipCode]} disabled={initialValues ? true : false} data-testid="zip-code-field" />
                            </Grid>
                        </Grid>
                    </Grid>
                    {licenseRequired &&
                        <Grid item>
                            <Field name="license" label="License" type="text" component={RenderTextField} validate={required} disabled={initialValues ? true : false} data-testid="license-field" />
                        </Grid>
                    }
                    {this.isSignatureRequired() && !initialValues &&
                        <Grid item>
                            <SignatureBox signatureUrl={signatureUrl} onClick={openAddSignatureModal} data-testid="signature-box" />
                        </Grid>
                    }
                </Grid>
            </Form>
        );
    }
}

const form = "cardDetails";
const mapPropsToState = ({ auth, companies, publicData }: GlobalState) => ({ auth, companies, publicData });
export default withStyles(styles)(connect(mapPropsToState)(reduxForm<CardDetailsFormData, OwnProps>({ form })(CardDetailsForm)));

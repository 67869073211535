import { SocketActions } from '../constants/actions';

export function connectionOpened() {
  return {
    type: SocketActions.CONNECTION_OPENED,
  };
}

export function connectionClosed() {
  return {
    type: SocketActions.CONNECTION_CLOSED,
  };
}

export function connectionFailed() {
  return {
    type: SocketActions.CONNECTION_FAILED,
  };
}

import React from 'react';
import { Field } from 'redux-form';
import { WorkOrderTemplate } from '../../types/WorkOrderTemplate';
import SelectStyled from '../controls/SelectStyled';
import { connect } from 'react-redux';
import { getWorkOrderTemplates } from '../../actions/workOrderTemplates';
import { WorkOrderTemplateStatus } from '../../types/WorkOrderTemplateStatus';
import { Error } from '../ui/Error';

export const TemplateSelect = (templates: WorkOrderTemplate[], props: any): React.ReactNode => {
  const defaultValue = Array.isArray(templates) && filterTemplates(templates)?.[0]?.id;
  filterTemplates(templates).length < 2 && defaultValue && props.setDefaultWorkOrderTemplate ?
    props.setDefaultWorkOrderTemplate(defaultValue) : void(0);
  return (
    <SelectStyled {...props}>
      {templates.map(item =>
        <option key={item.id} value={item.id}>{item.templateName}</option>)
      }
    </SelectStyled>
  );
};

interface Props {
  locationId: string;
  name?: string;
  label?: string;
  validate?: any;
  dispatch: Function;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  workOrderTemplates: {
    data: { [k: string]: { loading?: boolean } | WorkOrderTemplate[] };
  };
  setDefaultWorkOrderTemplate?: Function;
}

function filterTemplates(templates: WorkOrderTemplate[]): WorkOrderTemplate[] {
  return templates.filter(t => t.status === WorkOrderTemplateStatus.Active)
}
// eslint-disable-next-line
export const WorkOrderTemplateSelectField: React.FC<Props> = (props: Props): React.ReactElement => {
  const templates = props.workOrderTemplates?.data?.[props.locationId];
  const { onChange, ...rest } = props;
  if (!templates) {
    setTimeout(() => props.dispatch(getWorkOrderTemplates(props.locationId)));
  }
  const templatesToShow = Array.isArray(templates) ? filterTemplates(templates) : [];
  const error = !templatesToShow.length ? 'No work order templates are configured for this location' : '';
  const defaultValue = Array.isArray(templates) && templates?.[0]?.id;
  return <React.Fragment>
    <Error error={error} />
    {defaultValue &&
      <Field
        type="select"
        onChange={onChange}
        component={TemplateSelect.bind(null, templatesToShow)}
        {...rest}
      />
    }
  </React.Fragment>;
}

const mapStateToProps = ({ workOrderTemplates }) => ({ workOrderTemplates });
export default connect(mapStateToProps)(WorkOrderTemplateSelectField);

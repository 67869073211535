import { BankActions } from '../constants/actions';
import * as BankService from '../services/api/bank';
import { BusinessAccount } from '../types/BusinessAccount';
import { showErrorAlert, showPendingAlert } from '../actions/alerts';

const DELAY = 600;

function requestBankStatus(companyId: string) {
    return {
        type: BankActions.REQUEST_STATUS,
        companyId,
    };
}

function receiveBankStatus(companyId: string, status) {
    return {
        type: BankActions.RECEIVE_STATUS,
        companyId,
        status,
    };
}

export function getStatus(companyId: string) {
    return (dispatch: Function) => {
        dispatch(requestBankStatus(companyId));
        return BankService.getBankStatus(companyId)
            .then((status) => dispatch(receiveBankStatus(companyId, status)));
    };
}

function creatingCustomer(companyId: string) {
    return {
        type: BankActions.CREATING_CUSTOMER,
        companyId,
    };
}

// eslint-disable-next-line max-params
export function createCustomer(companyId: string, firstName: string, lastName: string, email: string) {
    return (dispatch: Function) => {
        dispatch(creatingCustomer(companyId));
        return BankService.createPersonalCustomer(companyId, firstName, lastName, email)
            .then((data) => {
                dispatch(receiveBankStatus(companyId, data));
                setTimeout(() => {
                    return dispatch(getStatus(companyId));
                }, DELAY);
            });
    };
}

// eslint-disable-next-line max-lines-per-function
function prepareBusinessCustomerInput(values): BusinessAccount {
    return {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        ssn: values.ssn,
        street: values.street,
        street2: values.street2,
        city: values.city,
        state: values.state,
        zipCode: values.zipCode,
        businessName: values.businessName,
        businessType: values.businessType,
        birthDay: values.dob && values.dob.getDate(),
        birthMonth: values.dob && values.dob.getMonth() + 1,
        birthYear: values.dob && values.dob.getFullYear(),
        ein: values.ein,
        controller: {
            firstName: values.controllerFirstName,
            lastName: values.controllerLastName,
            title: values.controllerTitle,
            email: values.controllerEmail,
            ssn: values.controllerSsn,
            birthYear: values.controllerBirthday && values.controllerBirthday.getFullYear(),
            birthMonth: values.controllerBirthday && values.controllerBirthday.getMonth() + 1,
            birthDay: values.controllerBirthday && values.controllerBirthday.getDate(),
            address: {
                street: values.controllerAddressStreet,
                street2: values.controllerAddressStreet2,
                city: values.controllerAddressCity,
                state: values.controllerAddressState,
                zipCode: values.controllerAddressZipCode,
            },
        },
    };
}

export function createBusinessCustomer(companyId: string, values: any) {
    return (dispatch: Function) => {
        dispatch(creatingCustomer(companyId));
        return BankService.createBusinessCustomer(companyId, prepareBusinessCustomerInput(values))
            .then((data) => {
                dispatch(receiveBankStatus(companyId, data));
            });
    };
}

function attachingBankAccount(companyId: string) {
    return {
        type: BankActions.ATTACHING_BANK_ACCOUNT,
        companyId,
    };
}

// eslint-disable-next-line max-params
export function attachBankAccount(companyId: string,
    routingNumber: string,
    accountNumber: string,
    type: string,
    accountName: string) {
    return (dispatch: Function) => {
        dispatch(attachingBankAccount(companyId));
        return BankService.attachBankAccount(companyId, routingNumber, accountNumber, type, accountName)
            .then((data) => {
                dispatch(receiveBankStatus(companyId, data));
            });
    };
}

function confirmingMicroDeposits(companyId: string) {
    return {
        type: BankActions.CONFIRM_MICRO_DEPOSITS,
        companyId,
    };
}

export function confirmMicroDeposits(companyId: string,
    amount1: number,
    amount2: number) {
    return (dispatch: Function) => {
        dispatch(confirmingMicroDeposits(companyId));
        return BankService.confirmMicroDeposits(companyId, amount1, amount2)
            .then((data) => {
                dispatch(receiveBankStatus(companyId, data));
            });
    };
}

function bankingCollecting(companyId: string) {
    return {
        type: BankActions.BANKING_COLLECTING,
        companyId,
    };
}

function bankingCollected(companyId: string, data) {
    return {
        type: BankActions.BANKING_COLLECTED,
        companyId,
        data,
    };
}

export function bankingCollect(companyId: string,
    amount: number) {
    return (dispatch: Function) => {
        dispatch(bankingCollecting(companyId));
        return BankService.bankingCollect(companyId, amount)
            .then((data) => {
                dispatch(bankingCollected(companyId, data));
            });
    };
}

function requestBankAccount(companyId: string) {
    return {
        type: BankActions.REQUEST_BANK_ACCOUNT,
        companyId,
    };
}

function receiveBankAccount(companyId: string, bankInfo) {
    return {
        type: BankActions.RECEIVE_BANK_ACCOUNT,
        companyId,
        bankInfo,
    };
}

export function requestingBankingAccount(companyId: string) {
    return (dispatch: Function) => {
        dispatch(requestBankAccount(companyId));
        return BankService.getBankAccount(companyId)
            .then((bankInfo) => {
                dispatch(receiveBankAccount(companyId, bankInfo));
            });
    };
}

function requestCompanyBeneficialOwners(companyId: string) {
    return {
        type: BankActions.REQUEST_BENEFICIAL_OWNERS,
        companyId,
    };
}

function receiveCompanyBeneficialOwners(companyId: string, owners: any) {
    return {
        type: BankActions.RECEIVE_BENEFICIAL_OWNERS,
        companyId,
        owners,
    };
}

export function getCompanyBeneficialOwners(companyId: string) {
    return (dispatch: Function) => {
        dispatch(requestCompanyBeneficialOwners(companyId));
        return BankService.getCompanyBeneficialOwners(companyId)
            .then((owners) => {
                dispatch(receiveCompanyBeneficialOwners(companyId, owners));
            });
    };
}

function updatingCompanyBeneficialOwner(ownerId: string) {
    return {
        type: BankActions.UPDATING_BENEFICIAL_OWNERS,
        ownerId,
    };
}

function updatedCompanyBeneficialOwner(ownerId: string) {
    return updatingCompanyBeneficialOwner(ownerId);
}

export function updateCompanyBeneficialOwner(ownerId: string, ownerInput: any) {
    return (dispatch: Function) => {
        dispatch(updatingCompanyBeneficialOwner(ownerId));
        return BankService.updateBeneficialOwner(ownerId, ownerInput)
            .then(() => {
                dispatch(updatedCompanyBeneficialOwner(ownerId));
            });
    };
}

function deletingCompanyBeneficialOwner(ownerId: string) {
    return {
        type: BankActions.DELETING_BENEFICIAL_OWNERS,
        ownerId,
    };
}

function deletedCompanyBeneficialOwner(companyId: string, ownerId: string) {
    return {
        type: BankActions.DELETED_BENEFICIAL_OWNERS,
        companyId,
        ownerId,
    };
}

export function deleteCompanyBeneficialOwner(companyId: string, ownerId: string) {
    return (dispatch: Function) => {
        dispatch(deletingCompanyBeneficialOwner(ownerId));
        return BankService.deleteBeneficialOwner(ownerId)
            .then((data) => deletedCompanyBeneficialOwner(companyId, ownerId));
    };
}

export function attachBankAccountWithPlaid(
    companyId: string, token: string, accountId: string, accountName: string, type: string
) {
    return (dispatch: Function) => {
        dispatch(attachingBankAccount(companyId));
        return BankService.attachBankAccountWithPlaid(companyId, token, accountId, accountName, type)
            .then((data) => {
                dispatch(receiveBankStatus(companyId, data));
                setTimeout(() => {
                    return dispatch(getStatus(companyId));
                }, DELAY);
            });
    };
}

function resettingBankAccount(companyId: string) {
    return {
        type: BankActions.RESETTING_BANK_ACCOUNT,
        companyId,
    };
}

function resetCompleteBankAccount(companyId: string) {
    return {
        type: BankActions.RESET_BANK_ACCOUNT,
        companyId,
    };
}

export function resetBankAccount(companyId: string) {
    return (dispatch: Function) => {
        dispatch(resettingBankAccount(companyId));
        return BankService.resetBankAccount(companyId)
            .then((data) =>
                dispatch(resetCompleteBankAccount(companyId))
            );
    };
}

export function getIsTodayABankHoliday() {
    return (dispatch: Function) => {
        return BankService.getIsTodayABankHoliday()
            .then((data: boolean) => {
                if (data === true) {
                    dispatch(
                        showPendingAlert(
                            'Today is a US Federal Bank Holiday. Transfers may take longer than normal.'
                        ));
                }
            });
    };
}

export class LocationEndpoints {
  static getLocation = (locationId: string): string => `/api/v1/locations/${locationId}`;
  // eslint-disable-next-line max-params
  static getLocations = (companyId: string, offset: number, limit: number, quickfilter?: string): string => (
    `/api/v1/companies/${companyId}/locations?offset=${offset}&limit=${limit}
    ${quickfilter ? '&search=' + quickfilter : ''}`
  )
  static createLocation = (companyId: string): string => `/api/v1/companies/${companyId}/locations`;
  static updateLocation = (locationId: string): string => `/api/v1/locations/${locationId}`;
  static updateCustomFields = (locationId: string): string => (
    `/api/v1/locations/${locationId}/custom-fields`
  )
  static getLocationsIncludingSubCompany = (companyId: string, offset: number, limit: number): string => (
    `/api/v1/companies/${companyId}/hierarchical-locations?offset=${offset}&limit=${limit}`
  )
}

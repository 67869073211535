import React from 'react';
import { reduxForm, Field, InjectedFormProps, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { Category } from '../../types/Category';
import { RenderSelectField } from '../ui/form/RenderSelectField';
import { MenuItem } from '@material-ui/core';

export interface FormData {
  parentId?: string;
}

interface OwnProps {
  categoryList: Category[];
}

interface PropsFromState {
  category?: string;
}

type Props = InjectedFormProps<FormData, OwnProps & PropsFromState> & OwnProps & PropsFromState;

const CategorySelectForm: React.FC<Props> = (props: Props) => {
  const { handleSubmit, categoryList } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field name="parentId" component={RenderSelectField}>
        {categoryList.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)}
      </Field>
    </form>
  );
};

const form = 'categorySelect';
const selector = formValueSelector(form);
const mapStateToProps = (state: any): PropsFromState => ({ category: selector(state, 'parentId') })
export default connect(mapStateToProps)(reduxForm<FormData, OwnProps>({ form })(CategorySelectForm));

import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { Route, RouteProps, Redirect, withRouter, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { AuthPaths } from '../../services/app/paths';
import { authCheck } from '../../actions/auth';
import Loader from '../../components/app/Loader';
import { isEmployee } from '../../services/app/auth';
import { Verification } from '../../constants/verification';
import { Role } from '../../types/Role';
import SurveyDisplayProvider from '../../components/survey/SurveyDisplayProvider';
import { GlobalState } from '../../types/GlobalState';
import { Type as CompanyType } from '../../constants/company';
import { Company } from "../../types/Company";
import { getCompany } from "../../actions/companies";

type PropsFromState = Pick<GlobalState, "auth" | "appSettings" | "companies">;

interface Props extends PropsFromState, DispatchProp, RouteComponentProps, OwnProps { }

interface OwnProps {
  exact?: boolean;
  requireDefaultsForEmployee?: boolean;
  roles: Role[];
  companyTypes?: CompanyType[];
  path: string;
  component?: React.ComponentClass<any> | React.FunctionComponent<any>;
  render?: React.ComponentClass<any> | React.FunctionComponent<any>;
}

class AuthorizedRoute extends React.Component<Props> {

  async componentDidMount(): Promise<void> {
    const { dispatch, auth: { me: { companyId } } } = this.props;
    await dispatch<any>(authCheck());
    if (companyId) {
      await dispatch<any>(getCompany(companyId))
    }
  }

  userIsNotVerified(): boolean {
    const { auth, path } = this.props;
    return !!(auth && !auth.me.isVerified && path !== Verification.VERIFICATION_PATH);
  }

  userIsAuthorizedByRole(): boolean {
    const { auth, roles } = this.props;
    return _.intersection(auth.me.roles, roles.map(r => r.key)).length > 0;
  }

  userIsNotAuthorizedByCompanyType(): boolean {
    const { auth, companies, companyTypes } = this.props;
    const company = _.get(companies?.data, auth.me.companyId) as Company;
    if (companyTypes && companyTypes.length > 0 && company?.type) {
      const companyTypeAllowed = companyTypes.find(ct => ct.key === company.type);
      if (!companyTypeAllowed) {
        return true
      }
    }
    return false;
  }

  // eslint-disable-next-line max-lines-per-function,max-statements
  render(): React.ReactElement {
    const { auth, appSettings, requireDefaultsForEmployee, path, ...rest } = this.props;
    // Make sure we wait until both auth check and app settings finished loading
    const authLoaded = Array.isArray(auth?.me?.roles);
    const appSettingsLoaded = appSettings?.settings;
    const authenticated = (authLoaded && appSettingsLoaded);

    if (authenticated) {
      _initPendo();

      if (this.userIsNotAuthorizedByCompanyType()) {
        return <Redirect to={AuthPaths.loginUrl()} />;
      }

      if (this.userIsNotVerified()) {
        return (
          <Redirect
            to={{
              pathname: AuthPaths.verifyUserUrl(auth.me.id),
              search: this.props.location.search,
            }}
          />);
      }

      if (this.userIsAuthorizedByRole()) {
        const employeeOnly = auth.me.roles.length === 1 && isEmployee(auth.me);

        if (auth.me.firstPasswordReset && path !== AuthPaths.changePasswordUrl() && auth.me.isVerified) {
          return <Redirect to={AuthPaths.changePasswordUrl()} />;
        }

        if (requireDefaultsForEmployee && employeeOnly && appSettings.defaults == null) {
          return <Redirect to={AuthPaths.selectLocationUrl(path)} />;
        }

        return (
          <SurveyDisplayProvider>
            <Route {...rest as RouteProps} />
          </SurveyDisplayProvider>
        );
      }
    }

    // Show loader only if user is NOT authenticated and app in the middle
    // of checking existing auth token or when auth loaded, but still loading app settings
    if (auth.checking || (authLoaded && !appSettingsLoaded)) {
      return <Loader />;
    }

    return <Redirect to={AuthPaths.loginUrl()} />;
  }
}

// _initPendo is a snippet directly from Pendo to install using the RoadSync API key.
// We trigger it here instead of adding it to index.html to limit sessions to logged in users only.
function _initPendo(): void {

  if ((window as any).pendo) return;

  (function (apiKey) {
    (function (p, e, n, d, o) {
      // @ts-ignore
      var v, w, x, y, z; o = p[d] = p[d] || {}; o._q = o._q || [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track']; for (w = 0, x = v.length; w < x; ++w)(function (m) {
        // @ts-ignore
        o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); };
      })(v[w]);
      y = e.createElement(n); y.async = !0; y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
      z = e.getElementsByTagName(n)[0]; z?.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');
  })('4202a8ad-1108-4ee6-648a-acbe244ee1ee');
}

const mapStateToProps = ({ auth, appSettings, companies }: GlobalState): PropsFromState => ({ auth, appSettings, companies });
export default withRouter(connect(mapStateToProps)(AuthorizedRoute));

import { AccountingActions } from '../constants/actions';
import _ from 'lodash';
import { mergeStateAndEntityResult } from '../services/app/normalizr';
import { AccountingSettings } from '../types/AccountingSettings';

export interface AccountingSettingState {
  data: { [companyId: string]: AccountingSettings };
  order: never[];
}

interface AccountingSettingAction {
  type?: string;
  entities?: AccountingSettings[];
  companyId?: string;
}

export function accountingSettings(
  state = { data: {}, ui: {}, order: [] },
  action: AccountingSettingAction = {}
): AccountingSettingState {
  const nextData = mergeStateAndEntityResult(state, action.entities, 'accountingSettings');
  switch (action.type) {
    case AccountingActions.REQUEST_ACCOUNTING_SETTINGS:
      return _.set({ ...state }, `data.${action.companyId}`, { loading: true });
    case AccountingActions.RECEIVE_ACCOUNTING_SETTINGS_ERROR:
      return _.set({ ...state }, `data.${action.companyId}`, { loading: false });
    default:
      return { ...state, data: nextData };
  }
}

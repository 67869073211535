import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => createStyles({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  textContainer: {
    marginBottom: "10px"
  },
  link: {
    color: "#c6a912"
  }
}));

export default useStyles;
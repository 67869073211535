import { CompanySettings } from './endpoints';
import * as http from './http';

export function getCompanySettings(companyId: string): Promise<any> {
  return http.get(CompanySettings.getCompanySettings(companyId));
}

export function updateCompanySettings(companyId: string, companySettings: CompanySettings): Promise<any> {
  return http.postJSON(CompanySettings.updateCompanySettings(companyId), companySettings);
}

export function getCompanyInfo(companyId: string): Promise<any> {
  return http.get(CompanySettings.getCompanyInfo(companyId));
}

import _ from 'lodash';
import { normalize, schema } from 'normalizr';

export function normalizeResult(entitySchema: schema.Entity | schema.Object) {
  return (result) => (
    new Promise((resolve, reject) => {
      const actualSchema = _.isArray(result) ? [entitySchema] : entitySchema;
      const normalizedData = normalize(result, actualSchema);
      return resolve(normalizedData);
    }
    ));
}

// eslint-disable-next-line max-params
export function mergeStateAndEntityResult(state: any, entities: any, slice: string, defaults: any = { loading: false }) {
  if (entities && entities[slice]) {
    // We are doing deep merge of state and normalized entities,
    // because different API endpoints returns same entity with different attributes
    // Because of that instead of overwrite entities in state by id,
    // we overwrite values of existing fields and adds missing
    return _.assignWith({ ...state.data }, entities[slice], (stateObject, apiObject) => ({
      ...stateObject,
      ...apiObject,
      // 'defaults' object is used to overwrite system fields values
      // like 'loading' or 'error' when merging normalized entities with state
      // If we don't do that explicitly, entities will have 'loading' set to true after merge with state
      ...defaults,
    }));
  }

  return { ...state.data };
}

import { ModalActions } from '../constants/actions';

export function openModal(name, params = {}) {
  return {
    type: ModalActions.OPEN_MODAL,
    name,
    params,
  };
}

export function closeModal(name) {
  return {
    type: ModalActions.CLOSE_MODAL,
    name,
  };
}

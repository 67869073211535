export class WorkOrderPaths {
  static workOrdersUrl = (): string => `/workorders`;
  static workOrderNewUrl = (): string => `/workorders/new`;
  static workOrderEditUrl = (workOrderId): string => `/workorders/${workOrderId}/edit`;
  static workOrderSendUrl = (workOrderId): string => `/workorders/${workOrderId}/send`;
  static workOrderPrivateAuthorizationUrl = (workOrderId: string): string => `/workorders/${workOrderId}/authorization`;
  static workOrderPublicAuthorizationUrl = (workOrderToken: string): string => `/public/workorder/${workOrderToken}`;
  static workOrderPublicApproved = (workOrderToken: string): string => `/public/workorder/${workOrderToken}/approved`;
  static workOrderCreateUrl = (workOrderId: string): string => `/workorders/${workOrderId}/invoice/new`;
}

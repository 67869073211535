import React from "react";
import { DispatchProp, connect } from "react-redux";
import { pwaPreparationSaveEvent } from "../../actions/pwa.preparation.actions";
import { Log } from "../../services/LoggerService";
import { BeforeInstallPromptEvent } from "../../types/BeforeInstallPromptEvent";

type Props = DispatchProp;

class PWAPreparer extends React.Component<Props>{

  componentDidMount(): void {
    if ('onbeforeinstallprompt' in window) {
      window.addEventListener('beforeinstallprompt', (promptEvent) => {
        const { dispatch } = this.props;
        promptEvent.preventDefault();
        Log.debug(`'beforeinstallprompt' event was fired.`);
        dispatch(pwaPreparationSaveEvent(promptEvent as BeforeInstallPromptEvent));
      });
    }
  }

  render(): React.ReactElement {
    return <></>;
  }
}


export default connect()(PWAPreparer);

import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Company } from "../../types/Company";
import { Invoice } from "../../types/Invoice";
import { Deposit } from "../../types/Deposit";
import { P, Divider, Grid } from "@roadsync/roadsync-ui";
import { CheckTypes } from "../../constants/deposit";
import { stateAbbreviations } from "../../constants/states";
import roadSyncLogo from '../../assets/img/logo_black.png';
import PrintOnlyContainer from "../ui/visibility/PrintOnlyContainer";

interface Props {
  company?: Company;
  invoice?: Invoice;
  deposit?: Deposit;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  checkNumber: theme.typography.h2,
  companyLogo: {
    maxWidth: theme.spacing(16),
    maxHeight: theme.spacing(8),
  },
  logo: {
    width: theme.spacing(12),
  },
  printableField: {
    borderBottom: `1px solid ${theme.palette.common.black}`,
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
}));

// eslint-disable-next-line
const PrintCheckContainer: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
  const { company, invoice, deposit } = props;
  const checkName = deposit?.checkType && CheckTypes.getByKey(deposit.checkType)?.checkName;
  const isComcheck = deposit?.checkType === CheckTypes.COMCHEK.key;
  const isTcheck = deposit?.checkType === CheckTypes.TCHECK.key;
  const isInstamoney = deposit?.checkType === CheckTypes.INSTAMONEY.key;
  const hasExpressCode = [CheckTypes.COMCHEK, CheckTypes.EFS, CheckTypes.TCHECK, CheckTypes.INSTAMONEY]
    .map(t => t.key).includes(deposit?.checkType || "unknown");
  return (
    <PrintOnlyContainer>
      <Grid container spacing={2} alignItems="center">
        {company?.logoUrl &&
          <Grid item xs={12}><img src={company.logoUrl} alt={company.name + ' logo'} className={classes.companyLogo} /></Grid>
        }
        {company?.state &&
          <Grid item xs={12}>{company.city}, {stateAbbreviations[company.state]} {company.zip}</Grid>
        }
        <Grid item xs={12}><Divider /></Grid>
        <Grid item xs={12}>
          <P align="center" variant="h3" gutterBottom>RS Trans #: {invoice?.invoiceNumber}</P>
          <P align="center" variant="body2" gutterBottom>No paper check will be accepted or required.</P>
          <P align="center" variant="body2" gutterBottom>
            If the driver needs a <span className="text-bold">{checkName}</span> number - it must be the one below.
            The check number has been texted to the driver.
          </P>
          {checkName && deposit?.checkNumber && <>
            <P align="center" variant="body2">
              This facility uses Roadsync for electronic payments.<br />
              If you need to register a check use the following {checkName} number for this Invoice:
            </P>
            <P align="center" className={classes.checkNumber}>{deposit.checkNumber}</P>
          </>}
        </Grid>

        <Grid item xs={12}>
          <P>If you are paying with a code, complete the required fields below:</P>
        </Grid>

        {isInstamoney && <>
          <Grid item xs={4}>Account Number</Grid>
          <Grid item xs={8} className={classes.printableField} />
        </>}
        {hasExpressCode && <>
          <Grid item xs={4}>{isInstamoney ? 'Moneycode' : 'Card number or Express Code'}</Grid>
          <Grid item xs={8} className={classes.printableField} />
        </>}
        <Grid item xs={4}>Driver License #</Grid>
        <Grid item xs={8} className={classes.printableField} />
        <Grid item xs={4}>Driver License State</Grid>
        <Grid item xs={8} className={classes.printableField} />

        {isComcheck && <>
          <Grid item xs={4}>Driver # (optional)</Grid>
          <Grid item xs={8} className={classes.printableField} />
        </>}
        {isComcheck && <>
          <Grid item xs={4}>Trip # (optional)</Grid>
          <Grid item xs={8} className={classes.printableField} />
        </>}
        {isComcheck && <>
          <Grid item xs={4}>Unit # (optional)</Grid>
          <Grid item xs={8} className={classes.printableField} />
        </>}
        {isComcheck && <>
          <Grid item xs={4}>Trailer # (optional)</Grid>
          <Grid item xs={8} className={classes.printableField} />
        </>}
        {isTcheck && <>
          <Grid item xs={4}>Expiration Date (optional)</Grid>
          <Grid item xs={8} className={classes.printableField} />
        </>}
        <Grid item xs={12}>
          <P align="center">
            <img src={roadSyncLogo} alt="RoadSync" className={classes.logo} />
          </P>
        </Grid>
      </Grid>
    </PrintOnlyContainer>
  );
}

export default PrintCheckContainer;

export class Dashboard {
  static getDashboardWeeklyTotal = (companyId: string, excludeSubCompanies?: boolean): string => (
    `/api/v1/dashboard/${companyId}/payouts?excludeSubCompanies=${excludeSubCompanies}`
  )
  static getDashboardMonthlyTotal = (companyId: string, excludeSubCompanies?: boolean): string => (
    `/api/v1/dashboard/${companyId}/stats?excludeSubCompanies=${excludeSubCompanies}`
  )
  static getDashboardTransactionsRange = (companyId: string, excludeSubCompanies?: boolean): string => (
    `/api/v1/dashboard/${companyId}/transactions?excludeSubCompanies=${excludeSubCompanies}`
  )
  // eslint-disable-next-line max-params
  static getAllPayoutActivity = (companyId: string, offset: number, limit: number, excludeSubCompanies?: boolean): string => (
    // eslint-disable-next-line max-len
    `/api/v1/companies/${companyId}/hierarchical-activities?offset=${offset}&limit=${limit}&payoutswithinvoicesonly=true&operation=completed&excludeSubCompanies=${excludeSubCompanies}`
  )
}

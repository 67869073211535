import React, { useEffect, useState } from "react";
import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import { PrimaryButton } from "./buttons/PrimaryButton";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const useStyles = makeStyles((theme: Theme) => createStyles({
  scrollToBottomContainer: {
    position: "fixed",
    bottom: 0,
    padding: theme.spacing(1),
    left: 0,
    right: 0,
    background: "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.3) 100%)",
    zIndex: theme.zIndex.appBar,
  },
  scrollToBottomBtn: {
    borderRadius: theme.spacing(3),
  }
}));

interface Props {
  containerTarget: string;
  scrollTarget?: string;
  children?: React.ReactNode;
}

// eslint-disable-next-line
const ScrollToBottomButton: React.FC<Props> = (props: Props) => {
  const { children, containerTarget, scrollTarget } = props;
  const classes = useStyles();
  const el = document.querySelector<HTMLDivElement>(containerTarget);
  const getIsVisible = (): boolean => !!(el && (el.scrollHeight - el.offsetHeight) - el.scrollTop <= 400);
  const [isVisible, setIsVisible] = useState(getIsVisible());
  const scrollToBottom = (): void => {
    if (scrollTarget) {
      document.querySelector(scrollTarget)?.scrollIntoView({ behavior: "smooth" });
      return;
    }
    if (el) {
      el.scrollTop = el.scrollHeight;
    }
  }
  const onScroll = (): void => {
    const isBottom = el && (el.scrollHeight - el.offsetHeight) - el.scrollTop <= 400;
    setIsVisible(!isBottom);
  }
  useEffect(() => {
    if (!el) {
      return;
    }
    el.addEventListener("scroll", onScroll);
    return (): void => el.removeEventListener("scroll", onScroll);
    // eslint-disable-next-line
  }, [el]);

  return <>
    {isVisible &&
      <Box className={classes.scrollToBottomContainer}>
        <PrimaryButton className={classes.scrollToBottomBtn} startIcon={<ArrowDownwardIcon />} onClick={scrollToBottom}>
          {children || "Go to bottom"}
        </PrimaryButton>
      </Box>
    }
  </>;
}

export default ScrollToBottomButton;

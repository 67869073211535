import { AddressBookCompanyOutput } from "../../types/AddressBookCompanyOutput";
import { AddressBookEndpoints } from "./endpoints";
import * as http from "./http";
import { AddressBookCompanyInput } from "../../components/addressBook/AddressBookContactForm";

export function getAaddressBookCompanies(companyId: string, offset: number, limit: number, search?: string): Promise<AddressBookCompanyOutput[]> {
    return http.get(AddressBookEndpoints.getCompaniesList(companyId, { offset, limit, search }));
}

export async function createAddressBook(companyId: string, company: AddressBookCompanyInput): Promise<void> {
    return http.postJSON(AddressBookEndpoints.createPayer(companyId), company);
}

export async function deleteContact(companyId: string, bookCompanyId: string, contactId: string): Promise<void> {
    return http.del(AddressBookEndpoints.deleteContact(companyId, bookCompanyId, contactId));
}

// TODO
// export async function setMainContact(companyId: string, contact): Promise<void> {
//     return http.putJSON(AddressBookEndpoints.setMainContact(companyId), company);
// }


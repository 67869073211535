import { mergeStateAndEntityResult } from '../services/app/normalizr';
import { AttachedFileActions } from '../constants/actions';
import { InvoiceFile } from '../types/InvoiceFile';

import _ from 'lodash';

export interface FilesState {
    data: { [id: string]: InvoiceFile };
    ui: { loading: boolean };
    order: string[];
}

// eslint-disable-next-line max-lines-per-function
export function files(state: FilesState = { data: {}, order: [], ui: { loading: true } }, action: Record<string, any> = {}): FilesState {
    const nextData = mergeStateAndEntityResult(state, action.entities, 'files');

    switch (action.type) {
        case AttachedFileActions.REQUEST_PDF_INVOICE:
            return {
                ...state,
                ui: {
                    ...state.ui,
                    loading: true,
                },
            };
        case AttachedFileActions.RECEIVE_PDF_INVOICE:
            return {
                ...state,
                data: nextData,
                order: action.order,
                ui: {
                    ...state.ui,
                    loading: false,
                },
            };
        case AttachedFileActions.REMOVED_PDF_INVOICE:
            return {
                ...state,
                data: _.omit({ ...state.data }, action.invoiceFileId),
                order: _.pull(state.order, action.invoiceFileId),
            };
        default:
            return {
                ...state,
                data: nextData,
            };
    }
}

import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import ModalContent from '../../components/modals/ModalContent';
import { closeModal } from '../../actions/modals';
import { ModalsConstants } from '../../constants/modals';
import { P } from '@roadsync/roadsync-ui';

interface Props {
  customText?: string;
  text: string;
  deleteHandler: () => void;
  warning?: React.ReactNode | JSX.Element | string;
  invertedButtonColors? : boolean;
  primaryButtonCustomText? : string;
  secondaryButtonCustomText? : string;
}
class DeleteItemModal extends React.Component<Props & DispatchProp> {

  constructor(props: Props & DispatchProp) {
    super(props);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  handleDelete(): void {
    const { dispatch, deleteHandler } = this.props;
    dispatch(closeModal(ModalsConstants.DELETE_ITEM));
    deleteHandler();
  }

  handleCancel(): void {
    const { dispatch } = this.props;
    dispatch(closeModal(ModalsConstants.DELETE_ITEM));
  }

  // eslint-disable-next-line
  render(): React.ReactElement {
    const { text, customText, warning, invertedButtonColors, 
      primaryButtonCustomText, secondaryButtonCustomText } = this.props;
    return (
      <ModalContent
        invertedButtonColors = {invertedButtonColors}
        primaryActionText={primaryButtonCustomText ? primaryButtonCustomText : "Yes, delete it"}
        primaryActionClick={(): void => { this.handleDelete() }}
        secondaryActionText={secondaryButtonCustomText ? secondaryButtonCustomText : "No, go back"}
        secondaryActionClick={(): void => { this.handleCancel() }}
      >
        {!customText && <P>Are you sure you want to delete {text} ?</P>}
        {customText && <P>{text}</P>}
        {warning && <P color="error">{warning}</P>}
      </ModalContent>
    );
  }
}

export default connect()(DeleteItemModal);

import React from "react";
import { WithInvoiceProps } from "../WithInvoiceProps";
import { WithChildrenProps } from "../WithChildrenProps";
import { canRefundInvoice } from "../../../services/app/invoice";
import { Roles } from "../../../constants/roles";
import { FeatureFlag, IfUserRole } from "../Visibility";
import FeatureFlagContainer from "../../featureFlag/FeatureFlagContainer";
import { GlobalState } from "../../../types";
import { connect } from "react-redux";
import { getCompanyFromState } from "../../../services/app/split";

interface Props extends WithInvoiceProps, WithChildrenProps, PropsFromState { }

type PropsFromState = Pick<GlobalState, "auth" | "companies" | "publicData">;

interface State {
  isRefundable?: boolean;
}


class IfCanRefundInvoice extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  async componentDidMount(): Promise<void> {
    const { auth, companies, invoice, publicData } = this.props;
    const company = getCompanyFromState(companies, publicData, auth?.me);
    const isRefundable = await canRefundInvoice(invoice, company);
    this.setState({ isRefundable });
  }

  render(): React.ReactElement {
    const { children } = this.props;
    const { isRefundable } = this.state;
    return (
      <FeatureFlagContainer
        flag={FeatureFlag.RefundKillSwitch}
        disabled={
          <IfUserRole includes={[Roles.SUPER_ADMIN, Roles.COMPANY_ADMIN, Roles.ACCOUNTANT, Roles.RSEMPLOYEE, Roles.RS_IMPLEMENTATION, Roles.CLIENT_SUPPORT]}>
            {isRefundable && children}
          </IfUserRole>
        } />
    );
  }
}

const mapStateToProps = ({ auth, companies, publicData }: GlobalState) => ({ auth, companies, publicData });
export default connect(mapStateToProps)(IfCanRefundInvoice);

import React from 'react';
import { Box, List, ListItem, ListItemText, StyledComponentProps, Theme, Typography, withStyles } from '@material-ui/core';
import { Grid } from '@roadsync/roadsync-ui';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { WithInvoiceProps } from '../ui/WithInvoiceProps';
import { GlobalState } from '../../types/GlobalState';
import { connect } from 'react-redux';

const styles = (theme: Theme): {} => ({
  icon: {
    fontSize: theme.spacing(10),
  },
  secondary: {
    fontWeight: theme.typography.fontWeightLight,
    textTransform: "uppercase",
  }
});

type PropsFromState = Pick<GlobalState, "auth" | "companies" | "publicData">;
interface Props extends StyledComponentProps, WithInvoiceProps, PropsFromState { }

// eslint-disable-next-line max-lines-per-function
const InvoiceCheckoutTopContent: React.FC<Props> = (props: Props) => {
  const { classes, invoice } = props;


  return (
    <Box mb={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <LocalShippingIcon className={classes?.icon} />
        </Grid>
        <Grid item xs>
          <List dense>
            <ListItem>
              <ListItemText
                primary={
                  <Typography variant="h4" id="invoice-payer-name" component="div">
                    {invoice.payerName}
                  </Typography>
                }
                secondary={
                  <Typography variant="h5" component="div" className={classes?.secondary}>  
                  Customer Name
                  </Typography>
                }
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={
                  <Typography id="invoice-number" variant="h4" component="div">
                    {invoice.invoiceNumber}
                  </Typography>
                }
                secondary={
                  <Typography variant="h5" component="div" className={classes?.secondary}>
                    RS Trans #
                  </Typography>
                }
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = ({ auth, companies, publicData }: GlobalState): PropsFromState => ({ auth, companies, publicData });
export default connect(mapStateToProps)(withStyles(styles)(InvoiceCheckoutTopContent));

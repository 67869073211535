export class SurveyEndpoints {

  static getSurveysByUserId = (userId: string): string => `/api/v1/survey/users/${userId}`;
  static createUserSurvey = (userId: string): string => `/api/v1/survey/users/${userId}`;
  static updateSurveyById = (surveyUserId: string): string => `/api/v1/survey/users/${surveyUserId}`;

  static createSurvey = (): string => '/api/v1/surveys/';
  static getAllSurveys = (): string => '/api/v1/surveys';
  static getSurvey = (id: string): string => `/api/v1/surveys/${id}/`;
  static updateSurvey = (id: string): string => `/api/v1/surveys/${id}/`;

  static stopSurvey = (): string => '/api/v1/survey/stop';
  static startSurvey = (): string => '/api/v1/survey/start';
  static getCurrentSurvey = (): string => `/api/v1/survey/current`;
}

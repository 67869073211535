import React from 'react';
import CancelOutlined from '@material-ui/icons/CancelOutlined';
import useStyles from './FailureOutlined.css';

interface Props {
  id?: string;
}

const FailureOutlined: React.FC<Props> = React.memo((props: Props) => {
  const { id } = props;
  const classes = useStyles();

  return <CancelOutlined
    id={id ?? 'error-icon'}
    data-testid={id ?? 'error-icon'}
    className={classes.errorIcon}
  />;
});

export default FailureOutlined;
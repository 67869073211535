import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { ModalsConstants } from '../../constants/modals';
import ModalContent from '../../components/modals/ModalContent';
import { closeModal } from '../../actions/modals';
import { RouteComponentProps, withRouter } from 'react-router';
import { change, getFormValues } from 'redux-form';
import WorkOrderPdfLocationSelectForm, { WorkOrderPdfLocationSelectFormData } from '../../components/location/WorkOrderPdfLocationSelectForm';
import { getWorkOrderLocationPdfPreview } from '../../actions/workOrderTemplates';
import { WorkOrderTemplate } from '../../types/WorkOrderTemplate';
import { isClientSupport, isEmployee } from '../../services/app/auth';
import { User } from '../../types/User';
import { Shift } from '../../types/Shift';
import { Department } from '../../types/Department';
import * as api from '../../services/api/workOrder';
import { GlobalState } from '../../types/GlobalState';
import { Location } from '../../types/Location';

interface FormData {
  locationId: string;
  departmentId: string;
  shiftId: string;
  workOrderSettingsId: string;
}

type PropsFromState = Pick<GlobalState, "auth" | "locations" | "departments" | "shifts" | "appSettings"> & {
  formValues: WorkOrderPdfLocationSelectFormData;
}

interface Props extends DispatchProp, PropsFromState, OwnProps, RouteComponentProps { }

interface OwnProps {
  text: React.ReactNode;
  disabled?: boolean;
  selectedLocationSettings?: { id: string };
}

interface State {
  error?: string;
}

class WorkOrderPdf extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onLocationChange = this.onLocationChange.bind(this);
    this.state = {};
  }

  // eslint-disable-next-line max-lines-per-function
  render(): React.ReactNode {
    const { appSettings: { defaults }, selectedLocationSettings } = this.props;
    return (
      <ModalContent className="workorder-create-modal">
        <button
          onClick={this.handleModalClose}
          className="link-close icon-close cursor-pointer"
        />
        <p className="modal-message">{this.props.text}</p>
        <WorkOrderPdfLocationSelectForm
          locations={this.getUserAccessibleLocations()}
          getDepartments={(id: string): Department[] => this.getDepartmentsByLocationId(id)}
          getShifts={(id: string): Shift[] => this.getShiftsByLocationId(id)}
          // eslint-disable-next-line no-mixed-operators
          showLocationPicker={!(defaults) || selectedLocationSettings && !selectedLocationSettings.id}
          showDepartmentPicker={!(defaults)}
          showShiftPicker={!(defaults)}
          onLocationChange={this.onLocationChange}
          onSubmit={this.handleSubmit}
          locationError={this.state.error}
          disabled={!!this.props.disabled}
          initialValues={selectedLocationSettings && !selectedLocationSettings.id && defaults ? { locationId: defaults.locationId } : {}}
        />
      </ModalContent>
    );
  }

  handleModalClose(): void {
    const { dispatch } = this.props;
    dispatch(closeModal(ModalsConstants.WORK_ORDER_PDF));
  }

  async handleSubmit(form: FormData): Promise<void> {
    const template = await api.getWorkOrderTemplate(form.workOrderSettingsId);
    if (template && template.description) {
      this.props.dispatch<any>(getWorkOrderLocationPdfPreview(form.locationId, template.description));
      return this.props.dispatch<any>(closeModal(ModalsConstants.WORK_ORDER_PDF));
    }
  }

  private getUserAccessibleLocations(): Location[] {
    const me = this.getUser();
    // eslint-disable-next-line
    // @ts-ignore
    let locations = this.props.appSettings.settings.accessibleLocations
      .map(id => this.getLocation(id))
      .filter((location) => location.companyId === me.companyId);

    if (isEmployee(me)) {
      locations = isClientSupport(me)
        // eslint-disable-next-line
        // @ts-ignore
        ? locations.filter(location => this.props.appSettings.settings.user.locationIds.indexOf(location.id) >= 0)
        // eslint-disable-next-line
        // @ts-ignore
        : locations.filter(location => this.props.appSettings.defaults.locationId.indexOf(location.id) >= 0)
    }

    return locations;
  }

  private getUser(): User {
    return this.props.auth.me;
  }

  private getLocation(id: string): Location {
    // eslint-disable-next-line
    // @ts-ignore
    return this.props.locations.data[id];
  }

  private getDepartment(id: string): Department {
    // eslint-disable-next-line
    // @ts-ignore
    return this.props.departments.data[id];
  }

  private getShift(id: string): Shift {
    // eslint-disable-next-line
    // @ts-ignore
    return this.props.shifts.data[id];
  }

  getDepartmentsByLocationId(id: string): Department[] {
    // eslint-disable-next-line
    // @ts-ignore
    return this.getLocation(id)?.departments?.map(departmentId => this.getDepartment(departmentId)) || [];
  }

  getShiftsByLocationId(locationId: string): Shift[] {
    // eslint-disable-next-line
    // @ts-ignore
    return this.getLocation(locationId)?.shifts?.map(id => this.getShift(id)) || [];
  }

  getTemplatesByLocationId(locationId: string): WorkOrderTemplate[] {
    return [];
  }

  onLocationChange(event: Event, locationId: string, prevLocationId: string): void {
    if (locationId !== prevLocationId) {
      this.props.dispatch(change('workOrderPdfLocationSelect', 'departmentId', ''));
      this.props.dispatch(change('workOrderPdfLocationSelect', 'shiftId', ''));
    }
  }
}

function mapStateToProps(state: GlobalState): PropsFromState {
  const { locations, departments, shifts, appSettings, auth } = state;
  return {
    // eslint-disable-next-line
    // @ts-ignore
    formValues: getFormValues('workOrderPdfLocationSelect')(state) || {},
    auth, locations, departments, shifts, appSettings
  };
}

export default withRouter(connect(mapStateToProps)(WorkOrderPdf));

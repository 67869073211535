import React from 'react';
import { reduxForm, InjectedFormProps } from 'redux-form';
import { Error } from '../ui/Error';
import { Grid } from '@roadsync/roadsync-ui';
import SignatureBox from '../ui/SignatureBox';

type Props = InjectedFormProps<{}, OwnProps> & OwnProps;

interface OwnProps {
  children?: React.ReactNode;
  signatureEnabled?: boolean;
  signatureUrl?: string;
  openAddSignatureModal?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

// eslint-disable-next-line max-lines-per-function
const CashDetailsFormV2: React.FC<Props> = (props: Props) => {
  const { error, handleSubmit, children, signatureEnabled, signatureUrl, openAddSignatureModal } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        {error && <Grid item><Error error={error} id="cash-details-form-error" /></Grid>}
        <Grid item>{children}</Grid>
        {signatureEnabled &&
          <Grid item>
            <SignatureBox signatureUrl={signatureUrl} onClick={openAddSignatureModal} />
          </Grid>
        }
      </Grid>
    </form>
  );
};

export default reduxForm<{}, OwnProps>({ form: 'cashDetailsForm' })(CashDetailsFormV2);

import React from 'react';

interface Props {
  isActive: string;
  className: string;
  mouseDownHandler: Function;
  itemValue: string;
  itemName: string;
}

export default class ItemRenderer extends React.Component<Props> {
  activeItem?: HTMLLIElement | null = null;

  render(): React.ReactElement {
    return (
      <li
        ref={(li) => {
          if (this.props.isActive === 'active') {
            this.activeItem = li;
          }
        }}
        className={this.props.className}
        key={this.props.itemValue}
        onMouseDown={() => this.props.mouseDownHandler()}
      >
        <span>{this.props.itemName}</span>
      </li>
    );
  }
}

import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { GlobalState } from '../../types/GlobalState';
import { FeatureFlag } from './FeatureFlag';
import { FeatureFlagContainer as Container } from '@roadsync/roadsync-ui';
import { Company } from '../../types/Company';
import { User } from '../../types/User';
import * as split from '../../services/app/split';

type PropsFromState = Pick<GlobalState, 'auth' | 'companies' | 'publicData'>;

interface OwnProps {
  flag: FeatureFlag | string;
  children?: React.ReactNode;
  disabled?: React.ReactNode;
  additionalAttributes?: Record<string, string | number | boolean | Array<string | number>>;
}

interface Props extends PropsFromState, OwnProps, DispatchProp {
}

class FeatureFlagContainer extends React.Component<Props> {

  getAttributes(): Record<string, string | number | boolean | Array<string | number>> {
    return {
      ...split.getAttributes(this.getUser(), this.getCompany()),
      ...this.props.additionalAttributes,
    };
  }

  getCompany(): Company | undefined {
    const { companies, publicData } = this.props;
    return split.getCompanyFromState(companies, publicData, this.getUser());
  }

  getUser(): User | undefined {
    return this.props.auth?.me;
  }

  render(): React.ReactElement {
    const { children, flag, disabled } = this.props;
    return <Container feature={flag} whenOff={disabled} attributes={this.getAttributes()}>{children}</Container>
  }
}

/* istanbul ignore next */
const mapStateToProps = ({ auth, companies, publicData }: GlobalState): PropsFromState => ({ auth, companies, publicData });
export default connect(mapStateToProps)(FeatureFlagContainer);

import React from 'react';
import StaticDropDown from './StaticDropDown/StaticDropDown';
import StaticListItemRenderer from './StaticDropDown/List/ItemRenderer/ItemRenderer';
import { isSafari, isMobile, isMobileSafari } from 'react-device-detect';
import { WrappedFieldProps } from 'redux-form';

interface Props extends WrappedFieldProps {
  id?: string;
  allowNullValue?: boolean;
  label?: string;
  disabled?: boolean;
  itemRenderer?: Function;
  onChange?: Function;
  required?: boolean;
  errorMessage?: string;
}

interface Option {
  name: string;
  value?: string;
}

interface State {
  options: Option[];
  selectedOption: Option;
  isOpen?: boolean;
}

export default class SelectStyled extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.onToggle = this.onToggle.bind(this);
    this.onSelection = this.onSelection.bind(this);

    this.state = {
      options: [],
      selectedOption: {
        name: '',
      }
    };
  }

  componentDidMount(): void {
    let options = [];
    let selectedOption;
    const children = this.props.children as any;
    if (children) {
      options = children.map((item) => {
        const op = {
          value: item.props?.value,
          name: item.props?.children,
        };

        if (op.value === this.props.input.value) {
          selectedOption = op;
        }

        return op;
      });
    }

    selectedOption = selectedOption || null;
    this.setState({ options, selectedOption });
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    if (nextProps.input.value !== this.props.input.value) {
      const { options } = this.state;
      let selectedOption;
      options.forEach((option) => {
        if (option.value === nextProps.input.value) {
          selectedOption = option;
        }
      });
      if (selectedOption) {
        this.setState({ selectedOption });
      }
    }
  }

  onToggle(): void {
    if (!this.props.disabled) {
      this.setState({ isOpen: !this.state.isOpen });
    }
  }

  // eslint-disable-next-line max-lines-per-function,max-statements
  macOsSafariRenderingGlitchWorkaround(): void {
    if (isSafari && !isMobile && !isMobileSafari) {
      // bug description: in macos safari there is a glitch on selection.
      // after options list disappears the bottom piece of it may remain.
      // increasing the form height by the drop down list height removes the artifact. it forces the pixels to rerender.
      // solution: changing the form height for a moment and setting it back to auto.
      const customSelect = window.document.querySelector('.custom-select');
      const dropdownOptionsPaddingTopAndBottomSum = 20;
      if (customSelect) {
        const customSelectWrapper = customSelect.parentNode as HTMLElement;
        const form = customSelectWrapper.parentNode as HTMLElement;
        const initialFormHeight = form.offsetHeight;
        const dropdownOptions = window.document.querySelectorAll('.select-dropdown li') as any;
        let sumDropdownOptionsHeight = dropdownOptionsPaddingTopAndBottomSum;
        if (dropdownOptions) {
          dropdownOptions.forEach((option) => sumDropdownOptionsHeight += option.offsetHeight);
          const newFormHeight = initialFormHeight + sumDropdownOptionsHeight;
          if (sumDropdownOptionsHeight > 20) {
            form.style.height = newFormHeight + 'px';
            setTimeout(() => form.style.height = 'auto', 1);
          }
        }
      }
    }
  }

  onSelection(option): void {
    if ((!option || !option.value) && !this.props.allowNullValue) {
      return;
    }

    if (this.props.onChange) {
      this.props.onChange(option && option.value ? option.value : null);
    }
    if (this.props.input.onChange) {
      this.props.input.onChange(option && option.value ? option.value : null);
    }

    this.setState({
      selectedOption: option,
    });

    this.macOsSafariRenderingGlitchWorkaround();
  }

  render(): React.ReactElement {
    const { id, label, disabled, meta, required, errorMessage, input, itemRenderer } = this.props;
    return (
      <StaticDropDown
        id={id}
        errorMessage={errorMessage}
        input={input}
        label={label}
        dataProvider={this.state.options}
        itemRenderer={itemRenderer ? itemRenderer : StaticListItemRenderer}
        value={this.state.selectedOption}
        onSelection={this.onSelection}
        disabled={disabled}
        meta={meta}
        required={required}
      />
    );
  }
}


import { createStyles, makeStyles, Theme } from '@material-ui/core';

const styles = makeStyles((theme: Theme) => createStyles({
  content: {
    overflow: 'auto',
    position: 'relative',
    minHeight: "100%",
    height: "100%",
  },
  root: {
    overflowY: 'auto',
  },
  sideMenu: {
    background: '#f2f2f2',
  },
}));

export default styles;

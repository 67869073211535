import { URLSearchMap, makeURLSearchParams } from "../endpoints";

export class AddressBookEndpoints {
    static getCompaniesList = (companyId: string, params: URLSearchMap): string =>
        `/api/v1/companies/${companyId}/addressbook?${makeURLSearchParams(params).toString()}`;

    static createPayer = (companyId: string): string => `/api/v1/companies/${companyId}/addressbook`;

    static deleteContact = (companyId: string, bookCompanyId: string, contactId: string): string => `/api/v1/companies/${companyId}/addressbook/${bookCompanyId}/${contactId}`;

    // TODO put /api/v1/companies/{companyId}/addressbook/{bookCompanyId}
    static setMainContact = (companyId: string, bookCompanyId: string): string => `/api/v1/companies/${companyId}/addressbook/${bookCompanyId}`;
}


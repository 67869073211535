import { CheckAuthorizerEndpoints } from './endpoints';
import * as http from './http';

export function getProcessingChecksList(offset: number, limit: number): Promise<any> {
  return http.get(CheckAuthorizerEndpoints.getProcessingChecks());
}

export function claimDeposit(depositId: string): Promise<any> {
  return http.postJSON(CheckAuthorizerEndpoints.claimDeposit(depositId));
}

export function approveDeposit(depositId: string, authNumber: string): Promise<any> {
  return http.postJSON(CheckAuthorizerEndpoints.approveDeposit(depositId), { authNumber });
}

export function declineDeposit(depositId: string, error: string): Promise<any> {
  return http.postJSON(CheckAuthorizerEndpoints.declineDeposit(depositId), { error });
}

export function restartMattDamon(): Promise<any> {
  return http.postJSON(CheckAuthorizerEndpoints.restartMattDamon());
}

export function getTwilioToken(): Promise<any> {
  return http.get(CheckAuthorizerEndpoints.getTwilioToken());
}

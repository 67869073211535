import { Box, Divider, FormControl, FormControlLabel, Grid, InputAdornment, MenuItem, Radio, Select, Switch, TextField } from "@material-ui/core";
import React, { memo } from "react";
import { Field, Form } from "react-final-form";
import { PrimaryButton, SecondaryButton, formatDollar } from "@roadsync/roadsync-ui";
import { CustomField, Location } from "../../../../types";
import useStyles from "./TowbookForm.css";
import { required, validateTowbookForm } from "../../../../services/app/validators";
import { formatMoneyOnly } from "../../../../services/app/formats";
import AttachFile from "@material-ui/icons/AttachFile";
import { CustomFieldType } from "../../../../constants/customFieldTypes";
import { Contact, TowbookCall } from "../../../../types/TowbookCall";
import { FormApi } from "final-form";

export interface TowbookFormData {
    accountName?: string;
    contactName?: string;
    phone?: string;
    email?: string;
    location?: string;
    amount?: string;
    autoAddAttachments?: boolean;
}

export enum TowbookCheckbox {
    account = "account-checkbox",
    contact = "contact-checkbox",
    email = "email-checkbox",
    phone = "phone-checkbox",
}

interface Props {
    loading?: boolean;
    towbookCallNumber?: string;
    isLocationReadOnly?: boolean;
    initialValues?: Partial<TowbookFormData>;
    towbookPaymentLocations: Location[];
    selectedLocation?: string;
    handleLocationChange: (location: string) => void;
    handleAmountChanged: (formattedAmount: string) => void;
    handleSubmit: (values: TowbookFormData) => void;
    handlePayerChange: (value: string, form?: FormApi<TowbookFormData, Partial<TowbookFormData>>) => void;
    handleRecipientChange: (value: string) => void;
    handlePdfView: () => void;
    maxValue?: string;
    towbookCallData: TowbookCall;
    payerCheckbox: string;
    recipientCheckbox: string;
    customFields: CustomField[];
    amount?: string;
    onContactChange: (contact: Contact | string | null, form?: FormApi<TowbookFormData, Partial<TowbookFormData>>) => void;
    recipientEmails: string[];
    recipientPhoneNumbers: string[];
}

const TowbookForm: React.FC<Props> = (props: Props) => {
    const {
        loading,
        towbookCallNumber,
        towbookPaymentLocations,
        selectedLocation,
        handleLocationChange,
        handleAmountChanged,
        handleSubmit,
        handlePayerChange,
        handleRecipientChange,
        handlePdfView,
        maxValue,
        towbookCallData,
        payerCheckbox,
        recipientCheckbox,
        customFields,
        amount,
        isLocationReadOnly,
        recipientEmails,
        recipientPhoneNumbers,
        initialValues,
        onContactChange,
    } = props;

    const classes = useStyles();

    const { contacts } = towbookCallData;

    const getInitialValues = () => {
        const values = {
            location: selectedLocation,
            accountName: towbookCallData.account.name,
            amount: amount,
            autoAddAttachments: true,
            ...initialValues
        };

        customFields.forEach((field) => {
            if (field.type === CustomFieldType.SELECT.key && field.options?.values?.length) {
                values[field.name] = field.options?.values[0];
            }
        });

        return values;
    };

    const useEmailFieldSelect = recipientEmails.length > 1;
    const usePhoneFieldSelect = recipientPhoneNumbers.length > 1;
    const useContactSelect = (contacts?.length || 0) > 1;

    return (
        <>
            <Form
                validate={(values) => validateTowbookForm(values, payerCheckbox, recipientCheckbox, maxValue ?? "0")}
                onSubmit={handleSubmit}
                keepDirtyOnReinitialize
                initialValues={getInitialValues()}
                render={({ handleSubmit: submit, form }) => {
                    return (
                        <form
                            noValidate
                            onSubmit={(e) => {
                                e.preventDefault();
                                submit();
                            }}
                        >
                            <Grid container direction="column" wrap="nowrap" spacing={3}>
                                <Grid container direction="column" wrap="nowrap" spacing={3}>
                                    <Grid item>
                                        <Box className={classes.title}>
                                            Towbook Call <span className={classes.titleNumericValue}>#{towbookCallNumber}</span>
                                        </Box>
                                    </Grid>

                                    <Grid item>
                                        <Box className={classes.info}>
                                            Take a moment to confirm invoice details and select a Payer for receipt delivery.
                                        </Box>
                                    </Grid>
                                    <Grid item>
                                        <Divider />
                                    </Grid>
                                    <Grid item>
                                        <Box className={classes.subTitle}>Payer</Box>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" wrap="nowrap" justifyContent="space-between" alignItems="center">
                                            <Grid item>
                                                <FormControlLabel
                                                    key={"account-checkbox"}
                                                    value={"account-checkbox"}
                                                    control={
                                                        <Radio
                                                            color="primary"
                                                            data-public
                                                            checked={payerCheckbox === TowbookCheckbox.account}
                                                            onChange={() => handlePayerChange(TowbookCheckbox.account, form)}
                                                        />
                                                    }
                                                    label={"Account"}
                                                />
                                            </Grid>
                                            <Grid item className={classes.inputWidth}>
                                                <Field
                                                    name="accountName"
                                                    render={({ input, meta }) => (
                                                        <TextField
                                                            {...input}
                                                            id="accountName"
                                                            label="Account's Name"
                                                            data-testid="account-name-field"
                                                            type="text"
                                                            variant="outlined"
                                                            fullWidth
                                                            disabled={loading}
                                                            error={meta.error && meta.touched}
                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" wrap="nowrap" justifyContent="space-between" alignItems="center">
                                            <Grid item>
                                                <FormControlLabel
                                                    key={"contact-checkbox"}
                                                    value={"contact-checkbox"}
                                                    control={
                                                        <Radio
                                                            color="primary"
                                                            data-public
                                                            checked={payerCheckbox === TowbookCheckbox.contact}
                                                            onChange={() => handlePayerChange(TowbookCheckbox.contact, form)}
                                                        />
                                                    }
                                                    label={"Contact"}
                                                />
                                            </Grid>
                                            <Grid item className={classes.inputWidth}>
                                                <Field
                                                    name="contactName"
                                                    render={({ input, meta }) => (
                                                        <TextField
                                                            {...input}
                                                            id="contactName"
                                                            select={useContactSelect}
                                                            label="Contact's Name"
                                                            data-testid="contact-name-field"
                                                            type="text"
                                                            variant="outlined"
                                                            fullWidth
                                                            disabled={loading}
                                                            onChange={(ev) => {
                                                                input.onChange(ev.target.value);
                                                                onContactChange(ev.target.value, form);
                                                            }}
                                                            error={meta.error && meta.touched}
                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                        >
                                                            {useContactSelect && contacts?.map((contact) => (
                                                                <MenuItem key={contact.name} value={contact.name} >
                                                                    {contact.name}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Divider />
                                    </Grid>

                                    <Grid item>
                                        <Box className={classes.subTitle}>Recipient</Box>
                                    </Grid>

                                    <Grid item>
                                        <Grid container direction="row" wrap="nowrap" justifyContent="space-between" alignItems="center">
                                            <Grid item>
                                                <FormControlLabel
                                                    key={"email-checkbox"}
                                                    value={"email-checkbox"}
                                                    control={
                                                        <Radio
                                                            color="primary"
                                                            data-public
                                                            checked={recipientCheckbox === TowbookCheckbox.email}
                                                            onChange={() => {
                                                                handleRecipientChange(TowbookCheckbox.email);
                                                            }}
                                                        />
                                                    }
                                                    label={"Email"}
                                                />
                                            </Grid>
                                            <Grid item className={classes.inputWidth}>
                                                <Field
                                                    name="email"
                                                    render={({ input, meta }) => (
                                                        <TextField
                                                            {...input}
                                                            select={useEmailFieldSelect}
                                                            id="emailField"
                                                            label="Email"
                                                            data-testid="email-field"
                                                            type="text"
                                                            variant="outlined"
                                                            fullWidth
                                                            disabled={loading}
                                                            error={meta.error && meta.touched}
                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                        >
                                                            {useEmailFieldSelect && recipientEmails.map((option) => (
                                                                <MenuItem key={option} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" wrap="nowrap" justifyContent="space-between" alignItems="center">
                                            <Grid item style={{ width: "40%" }}>
                                                <FormControlLabel
                                                    key={"phone-checkbox"}
                                                    value={"phone-checkbox"}
                                                    control={
                                                        <Radio
                                                            color="primary"
                                                            data-public
                                                            checked={recipientCheckbox === TowbookCheckbox.phone}
                                                            onChange={() => handleRecipientChange(TowbookCheckbox.phone)}
                                                        />
                                                    }
                                                    label={"Phone Number"}
                                                />
                                            </Grid>
                                            <Grid item className={classes.inputWidth}>
                                                <Field
                                                    name="phone"
                                                    render={({ input, meta }) => (
                                                        <TextField
                                                            {...input}
                                                            select={usePhoneFieldSelect}
                                                            id="phoneField"
                                                            label="Phone Number"
                                                            data-testid="phone-field"
                                                            variant="outlined"
                                                            fullWidth
                                                            disabled={loading}
                                                            error={meta.error && meta.touched}
                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                        >
                                                            {usePhoneFieldSelect && recipientPhoneNumbers.map((option, i) => (
                                                                <MenuItem key={i} value={option}>{option}</MenuItem>
                                                            ))}
                                                        </TextField>
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Divider />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="column" wrap="nowrap" alignItems="flex-end">
                                            {towbookPaymentLocations.length > 1 && (
                                                <>
                                                    <Grid container direction="row" wrap="nowrap" justifyContent="space-between" alignItems="center">
                                                        <Grid item>
                                                            <Box className={classes.subTitle}>Location</Box>
                                                        </Grid>
                                                        <Grid item className={classes.inputWidth}>
                                                            <Field name="location">
                                                                {({ input }) => {
                                                                    const handle = (event) => {
                                                                        const newValue = event.target.value;
                                                                        input.onChange(newValue);
                                                                        handleLocationChange(newValue);
                                                                    };

                                                                    return (
                                                                        <FormControl fullWidth>
                                                                            <Select
                                                                                key={"location-field"}
                                                                                id="location-field"
                                                                                data-testid="location-field"
                                                                                variant="outlined"
                                                                                labelId="location-field-label"
                                                                                readOnly={isLocationReadOnly}
                                                                                {...input}
                                                                                onChange={(event) => {
                                                                                    input.onChange(event.target.value);
                                                                                    handle(event);
                                                                                }}
                                                                                value={input.value}
                                                                            >
                                                                                {towbookPaymentLocations.map((item, idx) => (
                                                                                    <MenuItem
                                                                                        key={item.id}
                                                                                        value={item.id}
                                                                                        id={item.id}
                                                                                        data-testid={`${item.id}-${idx}`}
                                                                                    >
                                                                                        {item.name}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                    );
                                                                }}
                                                            </Field>
                                                        </Grid>
                                                    </Grid>
                                                </>
                                            )}

                                            <Grid item className={classes.inputWidth}>
                                                {customFields.map((field, key) => {
                                                    let type: string = "";

                                                    switch (field.type) {
                                                        case CustomFieldType.EMAIL.key:
                                                        case CustomFieldType.INPUT.key:
                                                            type = "text";
                                                            break;

                                                        case CustomFieldType.PHONE.key:
                                                        case CustomFieldType.NUMERIC.key:
                                                        case CustomFieldType.DECIMAL.key:
                                                            type = "number";
                                                            break;
                                                        default:
                                                            type = "text";
                                                            break;
                                                    }

                                                    return (
                                                        <Grid item style={{ marginTop: 20 }} key={`custom-field-${key}`} data-testid={field.name}>
                                                            <Field
                                                                name={field.name}
                                                                validate={required}
                                                                render={({ input, meta }) => {
                                                                    const handle = (event) => {
                                                                        const newValue = event.target.value;
                                                                        input.onChange(newValue);
                                                                    };

                                                                    switch (field.type) {
                                                                        case CustomFieldType.SELECT.key:
                                                                            return (
                                                                                <FormControl fullWidth>
                                                                                    <Select
                                                                                        id={field.name}
                                                                                        variant="outlined"
                                                                                        labelId={field.name}
                                                                                        {...input}
                                                                                        onChange={(event) => {
                                                                                            input.onChange(event.target.value);
                                                                                            handle(event);
                                                                                        }}
                                                                                        value={input.value}
                                                                                    >
                                                                                        {field.options.values?.map((value, idx) => (
                                                                                            <MenuItem
                                                                                                key={`optionvalues-${idx}`}
                                                                                                value={value}
                                                                                                id={`optionvalues-${idx}`}
                                                                                                data-testid={`optionvalues-${idx}`}
                                                                                            >
                                                                                                {value}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </FormControl>
                                                                            );
                                                                    }

                                                                    return (
                                                                        <TextField
                                                                            {...input}
                                                                            id={`custom-field-${field.name}`}
                                                                            label={field.title}
                                                                            data-testid={`custom-field-${field.name}`}
                                                                            type={type}
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            disabled={loading}
                                                                            error={meta.error && meta.touched}
                                                                            helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                        />
                                                                    );
                                                                }}
                                                            />
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {(!!customFields.length || towbookPaymentLocations.length > 1) && (
                                        <Grid item>
                                            <Divider />
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <Grid container direction="row" wrap="nowrap" justifyContent="space-between" alignItems="center">
                                            <Grid item>
                                                <Box className={classes.subTitle}>Charges</Box>
                                            </Grid>
                                            <Grid container direction="column" className={classes.inputWidth} spacing={2}>
                                                <Grid item>
                                                    <Field
                                                        name="amount"
                                                        render={({ input, meta }) => (
                                                            <TextField
                                                                {...input}
                                                                id="amountField"
                                                                label="Amount"
                                                                data-testid="amount-field"
                                                                type="text"
                                                                variant="outlined"
                                                                fullWidth
                                                                disabled={loading}
                                                                error={meta.error && meta.touched}
                                                                helperText={meta.touched && meta.error ? meta.error : undefined}
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                                                }}
                                                                onChange={(e) => {
                                                                    const formattedValue = formatMoneyOnly(e.target.value);
                                                                    input.onChange(formattedValue);
                                                                    handleAmountChanged(formattedValue);
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <SecondaryButton
                                                        fullWidth
                                                        startIcon={<AttachFile />}
                                                        onClick={handlePdfView}
                                                        data-testid={"view-pdf-button"}
                                                    >
                                                        View Towbook PDF Invoice
                                                    </SecondaryButton>
                                                </Grid>
                                                <Grid item>
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        wrap="wrap"
                                                        alignContent="center"
                                                        justifyContent="space-between"
                                                        className={classes.gridContainerAttachPdf}
                                                    >
                                                        <Grid item>
                                                            <Box component="span">Attach PDF to invoice</Box>
                                                        </Grid>
                                                        <Grid item>
                                                            <Field<boolean>
                                                                id="attach-pdf-to-invoice"
                                                                name="autoAddAttachments"
                                                                type="checkbox"
                                                                render={({ input }) => (
                                                                    <FormControlLabel
                                                                        labelPlacement="start"
                                                                        control={
                                                                            <Switch
                                                                                data-testid="attach-pdf-to-invoice"
                                                                                checked={input.checked}
                                                                                onChange={input.onChange}
                                                                                onBlur={input.onBlur}
                                                                                onFocus={input.onFocus}
                                                                                name={input.name}
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        label=""
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Divider />
                                    </Grid>
                                    <Grid item>
                                        <Grid container direction="row" wrap="nowrap" justifyContent="space-between" alignItems="center">
                                            <Grid item>
                                                <Box className={classes.title}>Subtotal</Box>
                                            </Grid>
                                            <Grid item>
                                                <Box className={classes.title}>{formatDollar(amount ?? "0")}</Box>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Divider />
                                    </Grid>
                                    <Grid item>
                                        <PrimaryButton
                                            fullWidth
                                            onClick={submit}
                                            type="submit"
                                            id="towbook-call-btn-submit"
                                            data-testid="towbook-call-btn-submit"
                                            disabled={loading}
                                        >
                                            IMPORT
                                        </PrimaryButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </form>
                    );
                }}
            ></Form>
        </>
    );
};

export default memo(TowbookForm);


import { attachBankAccountWithPlaid } from '../../actions/bank';

declare global {
  interface Window { Plaid: any }
}

let store;
let linkHandler;
let currentCompanyId;

let isLaunched = false;
let onExitHandler;

// eslint-disable-next-line max-lines-per-function
export const launch = (reduxStore, key, environment) => {
  if (isLaunched === true) {
    return;
  }

  if (!window.Plaid) {
    return;
  }

  if (!key || !environment) {
    return;
  }

  store = reduxStore;
  linkHandler = window.Plaid.create({
    selectAccount: true,
    env: environment,
    clientName: 'RoadSync',
    key: key,
    product: ['auth'],
    onLoad: function () {
      // The Link module finished loading.
    },

    onSuccess: function (publicToken, metadata) {
      const state = store.getState();
      const account = metadata && metadata.account;
      const accountName = account && account.name;
      const accountType = account && account.subType; // WHY????
      const accountId = account && account.id;
      const companyId = state && state.auth && state.auth.me && state.auth.me.companyId;
      store.dispatch(
        attachBankAccountWithPlaid(currentCompanyId || companyId, publicToken, accountId, accountName, accountType)
      );
    },
    onExit: function (err, metadata) {
      if (onExitHandler) {
        onExitHandler();
        onExitHandler = null;
      }
      // The user exited the Link flow.
      if (err != null) {
        // The user encountered a Plaid API error prior to exiting.
      }
      // metadata contains information about the institution
      // that the user selected and the most recent API request IDs.
      // Storing this information can be helpful for support.
    },
  });
};

// eslint-disable-next-line max-lines-per-function, max-statements
export const handleChange = () => {
  if (isLaunched === true) {
    return;
  }

  const state = store && store.getState();
  const me = state.auth && state.auth.me;

  if (!me.id) {
    // waiting for user account to be loaded
    return;
  }

  if (state.appSettings && state.appSettings.settings && state.appSettings.settings.config
    && state.appSettings.settings.impersonation !== null) {
    // checking if user is being impersonated
    launch(store, state.appSettings.settings.config.plaidPubKey, state.appSettings.settings.config.plaidEnvironment);
    isLaunched = true;
    return;
  }

  if (state.appSettings
    && state.appSettings.settings
    && state.appSettings.settings.config) {
    launch(store, state.appSettings.settings.config.plaidPubKey, state.appSettings.settings.config.plaidEnvironment);
    isLaunched = true;
  }

};

export const init = (reduxStore) => {
  store = reduxStore;
  store.subscribe(handleChange);
};

export const open = (companyId, onExit?: any) => {
  currentCompanyId = companyId;
  linkHandler.open();
  onExitHandler = onExit;
};


//The below code will be used in the future once we have migrated all users to AWS Cognito

// import { attachBankAccountWithPlaid } from '../../actions/bank';
// import * as storage from './storage';
// declare global {
//   interface Window { Plaid: any }
// }

// let store;
// let linkHandler;
// let currentCompanyId;

// let isLaunched = false;
// let onExitHandler;

// const fetchLinkToken = async (walletAPIURL: string) => {
//   const accessToken = storage.getItem('accessToken');
//   try {
//     // const response = await fetch(walletAPIURL + 'wallet/v2/plaid-link-token', {
//     const response = await fetch('localhost:3001/wallet/v2/plaid-link-token', {
//       method: 'GET', headers: {
//         'Authorization': `Bearer ${accessToken}`,
//       }
//     });
//     const responseJSON = await response.json();
//     if (responseJSON) {
//       return await responseJSON.data.linkToken;
//     }
//   } catch (e) { }
// };
// // eslint-disable-next-line max-lines-per-function
// export const launch = async (reduxStore, key: string, environment: string, walletAPIURL: string) => {
//   if (isLaunched === true) {
//     return;
//   }

//   if (!window.Plaid) {
//     return;
//   }

//   if (!key || !environment) {
//     return;
//   }
//   store = reduxStore;
//   const configs = {
//     token: await fetchLinkToken(walletAPIURL),
//     onSuccess: async function (public_token: string, metadata) {
//       const state = store.getState();
//       const account = metadata && metadata.account;
//       const accountName = account && account.name;
//       const accountType = account && account.subtype;
//       const accountId = account && account.id;
//       const companyId = state && state.auth && state.auth.me && state.auth.me.companyId;
//       store.dispatch(
//         attachBankAccountWithPlaid(currentCompanyId || companyId, public_token, accountId, accountName, accountType)
//       );
//     },
//     onExit: async function (err, metadata) {
//       if (err != null && err.error_code === 'INVALID_LINK_TOKEN') {
//         linkHandler.destroy();
//         linkHandler = window.Plaid.create({
//           ...configs,
//           token: await fetchLinkToken(walletAPIURL),
//         });
//       }
//       if (err != null) {
//         console.log(66, err);
//       }
//     },

//   };
//   linkHandler = window.Plaid.create(configs);

// };

// // eslint-disable-next-line max-lines-per-function, max-statements
// export const handleChange = async () => {
//   if (isLaunched === true) {
//     return;
//   }

//   const state = store && store.getState();
//   const me = state.auth && state.auth.me;

//   if (!me.id) {
//     // waiting for user account to be loaded
//     return;
//   }

//   if (state.appSettings && state.appSettings.settings && state.appSettings.settings.config
//     && state.appSettings.settings.impersonation !== null) {
//     // checking if user is being impersonated
//     await launch(store, state.appSettings.settings.config.plaidPubKey, state.appSettings.settings.config.plaidEnvironment, state.appSettings.settings.config.WalletAPIUrl);
//     isLaunched = true;
//     return;
//   }

//   if (state.appSettings
//     && state.appSettings.settings
//     && state.appSettings.settings.config) {
//     await launch(store, state.appSettings.settings.config.plaidPubKey, state.appSettings.settings.config.plaidEnvironment, state.appSettings.settings.config.WalletAPIUrl);
//     isLaunched = true;
//   }

// };

// export const init = (reduxStore) => {
//   store = reduxStore;
// };

// export const open = async (companyId, onExit?: any,) => {
//   currentCompanyId = companyId;
//   await handleChange();
//   linkHandler.open();
//   onExitHandler = onExit;
// };

import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  successIcon: {
    fontSize: 180,
    color: theme.palette.success.main
  },
}));

export default useStyles;

import { P } from '@roadsync/roadsync-ui';
import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../actions/modals';
import ModalContent from '../../components/modals/ModalContent';
import { CloseIcon } from '../../components/ui/Icons';
import { ModalsConstants } from '../../constants/modals';
import useStyles from './DownloadAppModal.css';
import appStore from '../../assets/img/appStore.png';
import googlePlay from '../../assets/img/googlePlay.png'

const DownloadAppModal: React.FC = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleClose = (): void => {
    dispatch(closeModal(ModalsConstants.DOWNLOAD_APP));
  };

  return (
    <ModalContent className={classes.root}>
      <CloseIcon id='download-app-modal-close' className={classes.closeIcon} onClick={handleClose} />
      <P className={classes.title}>RoadSync Driver</P>
      <P className={classes.message}>Choose your device below to download the app and get started.</P>
      <P className={classes.imageTitle}>For Android Users</P>
      <a href='https://play.google.com/store/apps/details?id=app.roadsync.invoices&referrer=utm_source=Remote%20Checkout&utm_medium=Ad&utm_campaign=driverapp&anid=admob' target='_blank' rel='noopener noreferrer'>
        <img src={googlePlay} alt='googlePlay' />
      </a>
      <P className={classes.imageTitle}>For iOS Users</P>
      <a href='https://apps.apple.com/app/apple-store/id1574222257?pt=118237197&ct=RemoteCheckoutAd&mt=8' target='_blank' rel='noopener noreferrer'>
        <img src={appStore} alt='appStore' />
      </a>
    </ModalContent>
  );
};

export default DownloadAppModal;

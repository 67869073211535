import React from 'react';

interface Props {
  dataProvider: any[];
  itemRenderer: Function;
  focusIndex: number;
  selectedIndex: number;
  styles: any;
  isVisible: string;
  className: string;
  selectHandler: Function;
}

export default class DropDownList extends React.Component<Props> {
  handleKeyDown: any;
  ul: any;
  activeItem: any;

  // eslint-disable-next-line
  render() {
    const focused = this.props.dataProvider[this.props.focusIndex];
    const selected = this.props.dataProvider[this.props.selectedIndex];
    // eslint-disable-next-line
    const children = this.props.dataProvider.map((item, i) => {
      let className = (selected === item) ? 'selected' : '';

      if (focused === item) {
        className += ' focused';
      }

      const mouseDownHandler = () => {
        this.props.selectHandler(item);
      };

      const isActive = (focused === item) ? 'active' : '';

      const RenderClass = this.props.itemRenderer as any;

      return (
        <RenderClass
          key={item.value || i}
          mouseDownHandler={mouseDownHandler}
          className={className}
          isActive={isActive}
          ref={(li) => {
            if (isActive === 'active') {
              this.activeItem = li;
            }
          }}
          itemName={item.name}
          itemValue={item.value}
        />
      );
    });

    return (
      <div
        className={`list-values dropdown-style-1 search-result-wrapper ${this.props.isVisible}`}
        onKeyDown={this.handleKeyDown}
      >
        <ul
          ref={(ul) => {
            this.ul = ul;
          }}
          className={`${this.props.className || ''}`}
          style={this.props.styles}
        >
          {children}
        </ul>
      </div>
    );
  }
}

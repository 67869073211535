import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
    createStyles({
        addressBookConfirmationModal: {
            marginTop: "3rem",
            marginBottom: "3rem",
            fontWeight: "bold",
            fontSize: "1.5rem",
        },
    })
);

export default useStyles;

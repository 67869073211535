import { CarrierActions } from '../constants/actions';
import { Carrier } from '../types/carrier';
import * as CompanyCarrierService from '../services/api/carriers';
import { showSuccessAlert, showErrorAlert } from './alerts';
import { normalizeResult } from '../services/app/normalizr';
import { carrierSchema, universalCarrierSchema } from '../types/schemas';
import { App } from '../constants/app';
import { getStandardErrorMessage } from '../services/app/errors';
import { ErrorCodes } from '../constants/errorCodes';

function requestCompanyCarrierList(companyId) {
  return {
    type: CarrierActions.REQUEST_CARRIER_LIST,
    companyId,
  };
}

function receiveCompanyCarrierList(entities, order) {
  return {
    type: CarrierActions.RECEIVE_CARRIER_LIST,
    order,
    entities,
  };
}

function requestCompanyCarrier(carrierId) {
  return {
    type: CarrierActions.REQUEST_CARRIER,
    carrierId,
  };
}

function receiveCompanyCarrier(entities) {
  return {
    type: CarrierActions.RECEIVE_CARRIER,
    entities,
  };
}

function insertingCompanyCarrier() {
  return {
    type: CarrierActions.CREATING_CARRIER,
  };
}

function insertedCompanyCarrier(entities) {
  return {
    type: CarrierActions.CREATED_CARRIER,
    entities,
  };
}

function updatingCompanyCarrier() {
  return {
    type: CarrierActions.UPDATING_CARRIER,
  };
}

function updatedCompanyCarrier(entities) {
  return {
    type: CarrierActions.UPDATED_CARRIER,
    entities,
  };
}

function deletingCompanyCarrier() {
  return {
    type: CarrierActions.DELETING_CARRIER,
  };
}

function deletedCompanyCarrier(carrierId) {
  return {
    type: CarrierActions.DELETED_CARRIER,
    carrierId,
  };
}

const normalizeCarrierResult = normalizeResult(carrierSchema);

export function getCarriersByCompanyId(companyId: string) {
  return (dispatch: Function) => {
    dispatch(requestCompanyCarrierList(companyId));
    return CompanyCarrierService.getCarrierByCompanyId(companyId)
      .then(normalizeCarrierResult)
      .then((data: any) => dispatch(receiveCompanyCarrierList(data.entities, data.result)));
  };
}

export function getCarriersListByCompanyId(companyId: string, offset = 0, limit = App.PAGE_SIZE) {
  return (dispatch: Function) => {
    dispatch(requestCompanyCarrierList(companyId));
    return CompanyCarrierService.getCarrierListByCompanyId(companyId, offset, limit)
      .then(normalizeCarrierResult)
      .then((data: any) => dispatch(receiveCompanyCarrierList(data.entities, data.result)));
  };
}

export function searchCarriersByCompanyId(companyId: string, q?: string) {
  return (dispatch: Function) => {
    return CompanyCarrierService.getCarrierByCompanyId(companyId, q);
  };
}

export function updateCarrierById(carrierId: string, carrier: Carrier) {
  return (dispatch: Function) => {
    dispatch(updatingCompanyCarrier());
    return CompanyCarrierService.updateCarrierById(carrierId, carrier)
      .then(normalizeCarrierResult)
      .then((data: any) => {
        dispatch(updatedCompanyCarrier(data.entities));
        dispatch(showSuccessAlert('Company carrier successfully updated!'));
      });
  };
}

export function getCarrierById(carrierId: string) {
  return (dispatch: Function) => {
    dispatch(requestCompanyCarrier(carrierId));
    return CompanyCarrierService.getCarrierById(carrierId)
      .then(normalizeCarrierResult)
      .then((data: any) => dispatch(receiveCompanyCarrier(data.entities)));
  };
}

export function createCarrier(companyId: string, carrier: Carrier, universalId = '') {
  if (universalId) {
    carrier.universalId = universalId;
  }
  return (dispatch: Function) => {
    dispatch(insertingCompanyCarrier());
    return CompanyCarrierService.createCarrier(companyId, carrier)
      .then(normalizeCarrierResult)
      .then((data: any) => {
        dispatch(insertedCompanyCarrier(data.entities));
        dispatch(showSuccessAlert('Company carrier successfully created!'))
      }).catch((error: any) => {
        dispatch(showErrorAlert(getStandardErrorMessage(error)));
      });
  };
}

export function deleteCarrier(carrierId: string) {
  return (dispatch: Function) => {
    dispatch(deletingCompanyCarrier());
    return CompanyCarrierService.deleteCarrierById(carrierId)
      .then(() => {
        dispatch(deletedCompanyCarrier(carrierId));
        dispatch(showSuccessAlert('Carrier successfully deleted!'));
      })
      .catch((error: any) => {
        if (String(error.status) === ErrorCodes.UNAUTHORIZED.key) {
          dispatch(showErrorAlert('You are not authorized to delete carriers'));
        }
      });
  };
}

function importingCarrierList() {
  return {
    type: CarrierActions.IMPORTING_LIST,
  };
}

function importedCarrierActions() {
  return {
    type: CarrierActions.IMPORTED_LIST,
  };
}

export function importCarriersList(companyId: string, file) {
  return (dispatch: Function) => {
    dispatch(importingCarrierList());
    return CompanyCarrierService.importCarriersList(companyId, file)
      .then(() => dispatch(importedCarrierActions()));
  };
}

const normalizeUniversalCarrierResult = normalizeResult(universalCarrierSchema);

function requestUniversalList() {
  return {
    type: CarrierActions.REQUEST_UNIVERSAL_CARRIER_LIST,
  };
}

function receiveUniversalList(entities, order) {
  return {
    type: CarrierActions.RECEIVE_UNIVERSAL_CARRIER_LIST,
    order,
    entities,
  };
}

export function cleanUniversalList() {
  return {
    type: CarrierActions.CLEAN_UNIVERSAL_CARRIER_LIST,
  };
}

export function getUniversalCarriers(search: string, offset = 0, limit = App.PAGE_SIZE) {
  return (dispatch: Function) => {
    dispatch(requestUniversalList());
    return CompanyCarrierService.getUniversalCarriers(search, offset, limit)
      .then(normalizeUniversalCarrierResult)
      .then((data: any) => dispatch(receiveUniversalList(data.entities, data.result)));
  };
}

import { UserActions } from '../constants/actions';
import { mergeStateAndEntityResult } from '../services/app/normalizr';
import _ from 'lodash';
import { User } from '../types/User';

export interface UsersState {
  data?: { [id: string]: User };
  order?: string[];
  ui?: { loading?: boolean };
}

export function users(state: UsersState = { data: {}, ui: {}, order: [] }, action: Record<string, any> = {}): UsersState {

  const nextData = mergeStateAndEntityResult(state, action.entities, 'users');

  switch (action.type) {

    case UserActions.REQUEST_USER:
      return _.set({ ...state }, `data.${action.result}`, { loading: true });
    case UserActions.REQUEST_USER_LIST:
    case UserActions.REQUEST_ADMIN_USER_LIST:

      return {
        ...state,
        ui: {
          ...state.ui,
          loading: true,
        },
      };
    case UserActions.RECEIVE_USER_LIST:
    case UserActions.RECEIVE_ADMIN_USER_LIST:
      return {
        ...state,
        data: nextData,
        order: action.order,
        ui: {
          ...state.ui,
          loading: false,
        },
      };
    case UserActions.DELETED_USER:
      return {
        ...state,
        data: _.omit({ ...state.data }, action.userId),
        order: _.pull(state.order || [], action.userId),
      };
    default:
      return {
        ...state,
        data: nextData,
      };
  }
}

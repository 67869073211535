/* eslint-disable max-lines */
import { InvoiceActions } from '../constants/actions';
import * as InvoiceService from '../services/api/invoices';
import { normalizeResult } from '../services/app/normalizr';
import { ApprovedInvoiceInput, Invoice, InvoiceInput } from '../types/Invoice';
import { invoiceSchema, payBillsSchema } from '../types/schemas';
import { App } from '../constants/app';
import { InvoiceDeclineReasons } from '../constants/invoiceDeclineReasons';
import { showErrorAlert, showPendingAlert, showSuccessAlert } from './alerts';
import { InvoiceDeleteTypes, InvoiceStatuses } from '../constants/invoice';
import { isAccountant, isCompanyAdmin, isEmployee, isRole_CS_EE, isRSEmployee, isSuperAdmin } from '../services/app/auth';
import { openFile } from '../services/api/utils';
import _ from 'lodash';
import { Dispatch } from 'react';
import { InvoiceAction } from '../reducers/invoices';
import { triggerCancelledFromHardwareEvent, triggerCancelledFromInovicePriceUpdatedEvent, triggerInvoiceUpdatedEvent } from '../services/app/invoice';
import { expireInstantPayoutBalance } from '../reducers/instantPayout';
import { openInvoicePriceUpdatedModalByInvoiceId } from './invoicePriceUpdatedModal';


function requestInvoice(invoiceId: string): InvoiceAction {
    return {
        type: InvoiceActions.REQUEST_INVOICE,
        result: invoiceId,
    };
}

function receiveInvoice(entities): InvoiceAction {
    return {
        type: InvoiceActions.RECEIVE_INVOICE,
        entities,
    };
}

function requestVoidedInvoice(invoiceId: string): InvoiceAction {
    return {
        type: InvoiceActions.REQUEST_VOIDED_INVOICE,
        result: invoiceId,
    };
}

function receiveVoidedInvoice(entities): InvoiceAction {
    return {
        type: InvoiceActions.RECEIVE_VOIDED_INVOICE,
        entities,
    };
}

function receiveInvoicePush(entities): InvoiceAction {
    return {
        type: InvoiceActions.RECEIVE_INVOICE_PUSH,
        entities,
    };
}

function requestInvoiceList(showLoader = true): InvoiceAction {
    return {
        type: InvoiceActions.REQUEST_INVOICE_LIST,
        showLoader,
    };
}

function duplicatingInvoice(showLoader = true): InvoiceAction {
    return {
        type: InvoiceActions.DUPLICATING_INVOICE,
        showLoader,
    };
}

function duplicatedInvoice(showLoader = false): InvoiceAction {
    return duplicatingInvoice(showLoader);
}

function receiveInvoiceList(entities, order): InvoiceAction {
    return {
        type: InvoiceActions.RECEIVE_INVOICE_LIST,
        entities,
        order,
    };
}

export function createdInvoice(entities): InvoiceAction {
    return {
        type: InvoiceActions.CREATED_INVOICE,
        entities,
    };
}

function updatingInvoice(): InvoiceAction {
    return {
        type: InvoiceActions.UPDATING_INVOICE,
    };
}

function updatedInvoice(entities): InvoiceAction {
    return {
        type: InvoiceActions.UPDATED_INVOICE,
        entities,
    };
}

const normalizeInvoiceResult = normalizeResult(invoiceSchema);

export function getValidatedPaymentInfo(
    invoiceId: string
) {
    return () => {
        return InvoiceService.getValidatedPaymentInfo(invoiceId)
    };
}

export function getInvoice(invoiceId: string): (dispatch: Dispatch<any>) => Promise<void | Dispatch<any>> {
    return (dispatch: Dispatch<any>): Promise<void | Dispatch<any>> => {
        dispatch(requestInvoice(invoiceId));
        return InvoiceService.getInvoice(invoiceId)
            .then(normalizeInvoiceResult)
            .then((data: any) => dispatch(receiveInvoice(data.entities)));
    };
}

export function getVoidedInvoice(invoiceId: string): (dispatch: Dispatch<any>) => Promise<void | Dispatch<any>> {
    return (dispatch: Dispatch<any>): Promise<void | Dispatch<any>> => {
        dispatch(requestVoidedInvoice(invoiceId));
        return InvoiceService.getVoidedInvoice(invoiceId)
            .then(normalizeInvoiceResult)
            .then((data: any) => dispatch(receiveVoidedInvoice(data.entities)));
    };
}

export function updateInvoice(invoice: InvoiceInput | Invoice, cb?: Function): (dispatch: Dispatch<any>) => Promise<void | Dispatch<any>> {
    return (dispatch: Dispatch<any>): Promise<void | Dispatch<any>> => {
        dispatch(updatingInvoice());
        return InvoiceService.updateInvoice(invoice)
            .then(result => {
                if (cb) { cb(result); }
                return normalizeInvoiceResult(result);
            })
            .then((data: any) => dispatch(updatedInvoice(data.entities)));
    };
}

export function updateInvoicePaidBy(
    invoice: InvoiceInput | Invoice, userId: string
): (dispatch: Dispatch<any>) => Promise<void | Dispatch<any>> {
    return (dispatch: Dispatch<any>): Promise<void | Dispatch<any>> => {
        dispatch(updatingInvoice());
        return InvoiceService.updateInvoicePaidBy(invoice, userId)
            .then((data: any) => {
                dispatch(updatedInvoice(data.entities));
            })
    };
}

// eslint-disable-next-line
export function getInvoicesByLocation(
    locationId: string,
    offset = 0,
    limit = App.PAGE_SIZE,
    quickfilter?: string,
    departmentId?: string,
    showLoader = true,
    status?: string[],
): (dispatch: Dispatch<any>) => Promise<void | Dispatch<any>> {
    return (dispatch: Dispatch<any>): Promise<void | Dispatch<any>> => {
        dispatch(requestInvoiceList(showLoader));
        return InvoiceService.getInvoicesByLocation(locationId, offset, limit, quickfilter, departmentId, status)
            .then(normalizeInvoiceResult)
            .then((data: any) => dispatch(receiveInvoiceList(data.entities, data.result)));
    };
}

// eslint-disable-next-line
export function getInvoicesByCompany(
    companyId: string,
    offset = 0,
    limit = App.PAGE_SIZE,
    search?: string,
    showLoader = true,
    status?: string[],
): (dispatch: Dispatch<any>) => Promise<void | Dispatch<any>> {
    return (dispatch: Dispatch<any>): Promise<void | Dispatch<any>> => {
        dispatch(requestInvoiceList(showLoader));
        return InvoiceService.getInvoicesByCompany(companyId, offset, limit, search, status)
            .then(normalizeInvoiceResult)
            .then((data: any) => dispatch(receiveInvoiceList(data.entities, data.result)));
    };
}

export function duplicateInvoice(invoiceId: string, useStoredPaymentDetails: boolean): (dispatch: Dispatch<any>) => Promise<any> {
    return (dispatch: Dispatch<any>): Promise<any> => {
        dispatch(duplicatingInvoice());
        return InvoiceService.duplicateInvoice(invoiceId, useStoredPaymentDetails)
            .then((value) => {
                dispatch(duplicatedInvoice());
                return value;
            });
    };
}


// eslint-disable-next-line
export function getAllInvoices(
    offset = 0,
    limit = App.PAGE_SIZE,
    quickfilter?: string,
    showLoader = true,
    includeVoided?: boolean,
    status?: string[],
): (dispatch: Dispatch<any>) => Promise<void | Dispatch<any>> {
    return (dispatch: Dispatch<any>): Promise<void | Dispatch<any>> => {
        dispatch(requestInvoiceList(showLoader));
        return InvoiceService.getAllInvoices(
            offset,
            limit,
            quickfilter,
            includeVoided,
            status,
        )
            .then(normalizeInvoiceResult)
            .then((data: any) => dispatch(receiveInvoiceList(data.entities, data.result)));
    };
}

export function unsavedProgress(invoice: Invoice): InvoiceAction {
    return {
        type: InvoiceActions.UNSAVED_PROGRESS,
        invoice,
    };
}

export function cleanUpProgress(): InvoiceAction {
    return {
        type: InvoiceActions.CLEAN_PROGRESS,
    };
}

export function preparationStarted(): InvoiceAction {
    return {
        type: InvoiceActions.PREPARATION_STARTED,
    };
}

// eslint-disable-next-line
export function receiveInvoiceUpdatePush(invoice): (dispatch: Dispatch<any>, getState: Function) => void {

    // eslint-disable-next-line
    return (dispatch: Dispatch<any>, getState: Function) => {
        normalizeInvoiceResult(invoice)
            // eslint-disable-next-line
            .then((data: any) => {

                const { auth: { me, }, appSettings, invoices } = getState();

                // eslint-disable-next-line
                const notifyInvoiceStatusUpdate = () => {
                    const oldInvoice = _.get(invoices.data, invoice.id) as Invoice;
                    const invoiceInfoText = `A payment #${invoice.invoiceNumber} from ${invoice.payerName}`;
                    if (!oldInvoice || oldInvoice.status !== invoice.status) {
                        switch (invoice.status) {
                            case InvoiceStatuses.COMPLETED.key: {
                                dispatch(expireInstantPayoutBalance());
                                InvoiceService
                                    .getInvoiceReceipt(invoice.id)// Get link for invoice receipt
                                    .then((linkObject) => {
                                        dispatch(showSuccessAlert(`${invoiceInfoText} has completed.`, { linkText: "View receipt", linkURL: linkObject.url }));
                                    });
                                break;
                            }
                            case InvoiceStatuses.PROCESSING.key: {
                                dispatch(showPendingAlert(`${invoiceInfoText} is now processing.`));
                                break;
                            }
                            case InvoiceStatuses.FAILED.key: {
                                const reason = InvoiceDeclineReasons.getByKey(invoice.paymentError);
                                let description = 'Payment was declined. Please check your card info or try a different card.';
                                if (reason) {
                                    description = reason.display;
                                }
                                dispatch(showErrorAlert(`${invoiceInfoText} needs attention: ${description}`));
                                break;
                            }
                            case InvoiceStatuses.PENDING_REVIEW.key: {
                                dispatch(showSuccessAlert(`RS Trans#${invoice.invoiceNumber} for ${invoice.payerName} will be reviewed by accounting for processing.`));
                                break;
                            }
                            default:
                                break;
                        }
                    }
                };

                const updateInvoiceStatusInStore = (): void => {
                    // RS-1006 This sends out a window event that an invoice was updated. The invoice list no longer uses Redux.
                    // Exisitng redux functionality is kept in place as we don't know what else is using it.
                    triggerInvoiceUpdatedEvent(invoice);
                    const oldInvoice = _.get(invoices.data, invoice.id) as Invoice;
                    if (oldInvoice) {
                        dispatch(receiveInvoicePush(data.entities));
                    }
                };

                if (isSuperAdmin(me) || isRSEmployee(me)) {
                    updateInvoiceStatusInStore();
                } else if (isCompanyAdmin(me)) {
                    notifyInvoiceStatusUpdate();
                    updateInvoiceStatusInStore();
                } else if (isEmployee(me)) {
                    if (invoice.location.id === appSettings?.defaults?.locationId) {
                        notifyInvoiceStatusUpdate();
                        updateInvoiceStatusInStore();
                    }
                } else if (isAccountant(me)) {
                    notifyInvoiceStatusUpdate();
                } else if (isRole_CS_EE(me)) {
                    notifyInvoiceStatusUpdate();
                }
            });
    };
}

export function receiveInvoiceAmountChangedPush(payloadData): (dispatch: Dispatch<any>, getState: Function) => void {
    const invoiceId = payloadData?.invoiceId;
    return (dispatch: Dispatch<any>) => {
        if (invoiceId) dispatch(openInvoicePriceUpdatedModalByInvoiceId(invoiceId));
    };
}

export function cancelledFromHardwareUpdatePush(payloadData): () => void {
    return () => {
        if (!payloadData) {
            return;
        }

        const parcedData = JSON.parse(payloadData);
        if (parcedData.action === 'transaction_cancelled') {
            triggerCancelledFromHardwareEvent();
        }
    };
}
export function cancelledFromInvoicePriceUpdatedPush(payloadData): () => void {
    return () => {
        if (!payloadData) {
            return;
        }

        const parcedData = JSON.parse(payloadData);
        if (parcedData.action === 'invoice_updated') {
            triggerCancelledFromInovicePriceUpdatedEvent();
        }
    };
}

export function deletingInvoice(invoiceId: string): InvoiceAction {
    return {
        type: InvoiceActions.DELETING_INVOICE,
        invoiceId,
    };
}

export function deletedInvoice(invoiceId: string): InvoiceAction {
    return {
        type: InvoiceActions.DELETED_INVOICE,
        invoiceId,
    };
}

export function deleteInvoice(
    invoice: Invoice, deletionReason: string = 'requestedbycustomer', skipAlert: boolean = false
): (dispatch: Dispatch<any>) => Promise<void | Dispatch<any>> {
    const invoiceId = invoice.id;
    let deleteType = InvoiceDeleteTypes.VOID.key;

    if (invoice.status === InvoiceStatuses.COMPLETED.key) {
        deleteType = InvoiceDeleteTypes.REFUND.key;
    }

    return (dispatch: Dispatch<any>): Promise<void | Dispatch<any>> => {
        dispatch(deletingInvoice(invoiceId));
        return InvoiceService.deleteInvoice(invoiceId, deletionReason)
            .then(() => dispatch(deletedInvoice(invoiceId)))
            .then(() => skipAlert ? null : dispatch(showSuccessAlert(`The invoice was successfully ${deleteType}ed.`)))
            .then(() => triggerInvoiceUpdatedEvent(invoice));
    };
}

export function updatingApprovedInvoice(invoiceId: string): InvoiceAction {
    return {
        type: InvoiceActions.UPDATING_APPROVED_INVOICE,
        invoiceId,
    };
}

export function updatedApprovedInvoice(invoiceId: string): InvoiceAction {
    return {
        type: InvoiceActions.UPDATED_APPROVED_INVOICE,
        invoiceId,
    };
}

export function updateApprovedInvoice(
    invoiceId: string,
    invoiceInput: ApprovedInvoiceInput
): (dispatch: Dispatch<any>) => Promise<void> {
    return (dispatch: Dispatch<any>): Promise<void> => {
        dispatch(updatingApprovedInvoice(invoiceId));
        return InvoiceService.updateApprovedInvoice(invoiceInput)
            .then(() => {
                dispatch(getInvoice(invoiceId)); // fixes another bug with updating the dropdown value
                //                                  after submitting -> redirecting to invoice list -> return back to this screen.
                dispatch(updatedApprovedInvoice(invoiceId));
                dispatch(showSuccessAlert('Invoice information updated successfully!'));
            });
    };
}

export function generatingInvoiceReceipt(invoiceId: string): InvoiceAction {
    return {
        type: InvoiceActions.GENERATING_INVOICE_RECEIPT,
        invoiceId,
    };
}

export function generatedInvoiceReceipt(invoiceId: string): InvoiceAction {
    return {
        type: InvoiceActions.GENERATED_INVOICE_RECEIPT,
        invoiceId,
    };
}

export function generateInvoiceReceipt(invoiceId: string): (v: Dispatch<any>) => Promise<void | Dispatch<any>> {
    return (dispatch: Dispatch<any>): Promise<void | Dispatch<any>> => {
        dispatch(generatingInvoiceReceipt(invoiceId));
        return InvoiceService.generateInvoiceReceipt(invoiceId)
            .then(() => dispatch(generatedInvoiceReceipt(invoiceId)));
    };
}

// eslint-disable-next-line
export function getInvoiceReceipt(
    invoiceId: string,
    isVoided = false
): (dispatch: Dispatch<any>) => void {
    return openFile(InvoiceService.getInvoiceReceipt(invoiceId, isVoided));
}

export function sendingCheckNumberToDriver(): InvoiceAction {
    return {
        type: InvoiceActions.SENDING_NUMBER_INFO_TO_DRIVER,
    };
}

export function sentCheckNumberToDriver(): InvoiceAction {
    return {
        type: InvoiceActions.SENT_NUMBER_INFO_TO_DRIVER,
    };
}

function sendingInvoiceReceipt(): InvoiceAction {
    return {
        type: InvoiceActions.SENDING_INVOICE_RECEIPT,
    };
}

function sentInvoiceReceipt(): InvoiceAction {
    return {
        type: InvoiceActions.SENT_INVOICE_RECEIPT,
    };
}

// eslint-disable-next-line
export function sendReceipt(
    invoiceId: string, email?: string, phoneNumber?: string, token?: string
): (dispatch: Dispatch<any>) => Promise<void | Dispatch<any>> {
    return (dispatch: Dispatch<any>): Promise<void | Dispatch<any>> => {
        dispatch(sendingInvoiceReceipt());
        return InvoiceService.sendInvoiceReceipt(invoiceId, email, phoneNumber, token)
            .then(() => dispatch(sentInvoiceReceipt()))
            .then(() => dispatch(showSuccessAlert('Invoice receipt was successfully sent')));
    };
}

function sendingNotificationToPayer(): InvoiceAction {
    return {
        type: InvoiceActions.SENDING_NUMBER_NOTIFICATION_TO_PAYER,
    };
}

function sentNotificationNumberToPayer(): InvoiceAction {
    return {
        type: InvoiceActions.SENT_NUMBER_NOTIFICATION_TO_PAYER,
    };
}

// eslint-disable-next-line
export function sendNotificationToPayer(
    invoiceId: string,
    phoneNumber = '',
    email = '',
    template: string,
    checkNumber?: string,
    checkType?: string,
    invoiceGrandtotal?: string
): (dispatch: Dispatch<any>) => Promise<void | Dispatch<any>> {
    return (dispatch: Dispatch<any>): Promise<void | Dispatch<any>> => {
        dispatch(sendingNotificationToPayer());
        return InvoiceService.sendNotificationToPayer(invoiceId,
            phoneNumber,
            email,
            template,
            checkNumber,
            checkType,
            invoiceGrandtotal)
            .then(() => dispatch(sentNotificationNumberToPayer()));
    };
}

function AddingSignature(): InvoiceAction {
    return {
        type: InvoiceActions.ADDING_SIGNATURE,
    };
}

function AddedSignature(): InvoiceAction {
    return {
        type: InvoiceActions.ADDED_SIGNATURE,
    };
}

export function addSignature(
    invoiceId: string, image: any

): (dispatch: Dispatch<any>) => Promise<any> {
    return (dispatch: Dispatch<any>) => {
        dispatch(AddingSignature());
        return InvoiceService.addSignature(invoiceId, image)
            .then((data: any) => {
                dispatch(AddedSignature());
                return data;
            });
    };
}

export function addPublicSignature(
    token: string, image: any
): (dispatch: Dispatch<any>) => Promise<any> {
    return (dispatch: Dispatch<any>) => {
        dispatch(AddingSignature);
        return InvoiceService.addPublicSignature(token, image)
            .then((data: any) => {
                dispatch(AddedSignature());
                return data;
            });
    };
}

function sendingInvoiceForCompletion(): InvoiceAction {
    return {
        type: InvoiceActions.SENDING_INVOICE_FOR_COMPLETION,
    };
}

function sentInvoiceForCompletion(): InvoiceAction {
    return {
        type: InvoiceActions.SENT_INVOICE_FOR_COMPLETION,
    };
}

export function sendInvoiceForCompletion(
    invoiceId: string, email?: string, phoneNumber?: string
): (dispatch: Dispatch<any>) => Promise<void | Dispatch<any>> {
    return (dispatch: Dispatch<any>): Promise<void | Dispatch<any>> => {
        dispatch(sendingInvoiceForCompletion());
        return InvoiceService.sendInvoiceForCompletion(invoiceId, email, phoneNumber)
            .then(() => dispatch(sentInvoiceForCompletion()))
            .then(() => dispatch(showSuccessAlert('Invoice has been sent for completion')));
    };
}

// actions for PayBills section
const normalizePayBillsResult = normalizeResult(payBillsSchema);

function requestPaidInvoiceList(showLoader = true): InvoiceAction {
    return {
        type: InvoiceActions.REQUEST_PAID_INVOICE_LIST,
        showLoader,
    };
}

function receivePaidInvoiceList(entities, order): InvoiceAction {
    return {
        type: InvoiceActions.RECEIVE_PAID_INVOICE_LIST,
        entities,
        order,
    };
}

// eslint-disable-next-line
export function getPaidInvoicesByCompany(
    companyId: string, offset = 0, limit = App.PAGE_SIZE, quickfilter = '', showLoader = true
): (dispatch: Dispatch<any>) => Promise<void | Dispatch<any>> {
    return (dispatch: Dispatch<any>): Promise<void | Dispatch<any>> => {
        dispatch(requestPaidInvoiceList(showLoader));
        return InvoiceService.getPaidInvoicesByCompany(companyId, offset, limit, quickfilter)
            .then(normalizePayBillsResult)
            .then((data: any) => dispatch(receivePaidInvoiceList(data.entities, data.result)));
    };
}

function requestUnpaidInvoiceList(showLoader = true): InvoiceAction {
    return {
        type: InvoiceActions.REQUEST_UNPAID_INVOICE_LIST,
        showLoader,
    };
}

function receiveUnpaidInvoiceList(entities, order: string[]): InvoiceAction {
    return {
        type: InvoiceActions.RECEIVE_UNPAID_INVOICE_LIST,
        entities,
        order,
    };
}

// eslint-disable-next-line
export function getUnpaidInvoicesByCompany(
    companyId: string, offset = 0, limit = App.PAGE_SIZE, quickfilter = '', showLoader = true
): (dispatch: Dispatch<any>) => Promise<void | Dispatch<any>> {
    return (dispatch: Dispatch<any>): Promise<void | Dispatch<any>> => {
        dispatch(requestUnpaidInvoiceList(showLoader));
        return InvoiceService.getUnpaidInvoicesByCompany(companyId, offset, limit, quickfilter)
            .then(normalizePayBillsResult)
            .then((data: any) => dispatch(receiveUnpaidInvoiceList(data.entities, data.result)));
    };
}

function requestRefundedInvoiceList(showLoader = true): InvoiceAction {
    return {
        type: InvoiceActions.REQUEST_REFUNDED_INVOICE_LIST,
        showLoader,
    };
}

function receiveRefundedInvoiceList(entities, order: string[]): InvoiceAction {
    return {
        type: InvoiceActions.RECEIVE_REFUNDED_INVOICE_LIST,
        entities,
        order,
    };
}

// eslint-disable-next-line
export function getRefundedInvoicesByCompany(
    companyId: string, offset = 0, limit = App.PAGE_SIZE, quickfilter = '', showLoader = true
): (dispatch: Dispatch<any>) => Promise<void | Dispatch<any>> {
    return (dispatch: Dispatch<any>): Promise<void | Dispatch<any>> => {
        dispatch(requestRefundedInvoiceList(showLoader));
        return InvoiceService.getRefundedInvoicesByCompany(companyId, offset, limit, quickfilter)
            .then(normalizePayBillsResult)
            .then((data: any) => dispatch(receiveRefundedInvoiceList(data.entities, data.result)));
    };
}


function MarkingInvoiceProcessing(): InvoiceAction {
    return {
        type: InvoiceActions.MARKING_INVOICE_PROCESSING,
    };
}

function MarkedInvoiceProcessing(): InvoiceAction {
    return {
        type: InvoiceActions.MARKED_INVOICE_PROCESSING,
    };
}

export function markInvoiceProcessing(
    token: string
): (dispatch: Dispatch<any>) => Promise<any> {
    return (dispatch: Dispatch<any>) => {
        dispatch(MarkingInvoiceProcessing);
        return InvoiceService.markInvoiceStatusProcessing(token)
            .then((data: any) => {
                dispatch(MarkedInvoiceProcessing());
                return data;
            });
    };
}

export function openTab(url: string, target?: string): Window | null {
    return window.open(url, target);
}

import { TransferActions } from '../constants/actions';
import { mergeStateAndEntityResult } from '../services/app/normalizr';
import { Transfer } from '../types/Transfer';

export interface TransfersState {
  data: { [k: string]: Transfer };
  order: string[];
  ui: { loading?: boolean };
}
// eslint-disable-next-line
export function transfers(state: TransfersState = { ui: {}, data: {}, order: [] }, action: Record<string, any> = {}): TransfersState {
  const nextData = mergeStateAndEntityResult(state, action.entities, 'transfers');

  switch (action.type) {
    case TransferActions.REQUEST_TRANSFER_DETAIL_LIST:
      return {
        ...state,
        ui: { loading: true },
      };
    case TransferActions.RECEIVE_TRANSFER_DETAIL_LIST:
      return {
        ...state,
        data: nextData,
        order: action.order,
        ui: { loading: false },
      };
    case TransferActions.RECEIVE_TRANSFER_DETAIL_LIST_ERROR:
      return {
        ...state,
        ui: { loading: false },
      };
    default:
      return state;
  }
}

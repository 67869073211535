import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import ModalContent from '../../components/modals/ModalContent';
import { closeModal } from '../../actions/modals';
import { ModalsConstants } from '../../constants/modals';
import { P } from '@roadsync/roadsync-ui';
import LockOutLocationUsersForm from '../../components/location/LockOutLocationUsersForm';
import _ from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { GlobalState } from '../../types/GlobalState';
import { LocationIsLocked } from '../../types/Location';
import { submit } from 'redux-form';
import { CheckboxState } from '../../constants/common';
import { LoadingContainer } from '../../components/ui/visibility/LoadingContainer';

interface OwnProps {
  customText?: string;
  text: string;
  lockOutHandler: (locationId: string, isLocked: boolean, companyId: string) => Promise<void>;
  warning?: React.ReactNode | JSX.Element | string;
}

type PropsFromState = Pick<GlobalState, "locations">;

interface RouteParams {
  locationId: string;
}

interface Props extends OwnProps, PropsFromState, DispatchProp, RouteComponentProps<RouteParams> { }

interface State {
  isPrimaryActionDisabled: boolean;
}

class LockOutLocationUsersModal extends React.Component<Props, State> {

  constructor(props: Props & DispatchProp) {
    super(props);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleLockOut = this.handleLockOut.bind(this);
    this.setPrimaryActionDisabled = this.setPrimaryActionDisabled.bind(this);
    this.getLocationIsLocked = this.getLocationIsLocked.bind(this);

    this.state = {
      isPrimaryActionDisabled: false,
    };
  }

  componentDidMount(): void {
    this.setPrimaryActionDisabled(this.getLocationIsLocked());
  }
  getLocationData(): LocationIsLocked {
    const { locations, match: { params: { locationId } } } = this.props;
    return _.get(locations?.data, locationId) as LocationIsLocked;
  }

  getLocationIsLocked(): boolean {
    return this.getLocationData()?.isLocked;
  }

  handleLockOut(): Promise<void> {
    const { dispatch } = this.props;
    return dispatch<any>(submit('lockOutLocationUsers'));
  }

  handleSubmit(values): Promise<void> {
    const { dispatch, lockOutHandler } = this.props;
    dispatch<any>(closeModal(ModalsConstants.LOCK_OUT_LOCATION_USERS));
    return lockOutHandler(values.id, String(values.isLocked) === CheckboxState.CHECKED.key, values.companyId);
  }

  handleCancel(): void {
    const { dispatch } = this.props;
    dispatch<any>(closeModal(ModalsConstants.LOCK_OUT_LOCATION_USERS));
  }

  setPrimaryActionDisabled(v: boolean): void {
    this.setState({ isPrimaryActionDisabled: v });
  }

  // eslint-disable-next-line
  render(): React.ReactElement {
    const { customText, warning } = this.props;
    const locationData = {
      id: this.getLocationData()?.id, isLocked: String(this.getLocationData()?.isLocked), companyId: this.getLocationData()?.companyId
    };
    return (
      <ModalContent
        primaryActionText="Save"
        primaryActionClick={this.handleLockOut}
        primaryActionDisabled={this.state.isPrimaryActionDisabled}
        secondaryActionText="Cancel"
        secondaryActionClick={(): void => { this.handleCancel() }}
      >
        <LoadingContainer loading={!locationData?.id} >
          <P variant="h6">Are you sure you want to lock out all user accounts in this location?</P>
          {customText && <P>{customText}</P>}

          <LockOutLocationUsersForm
            initialValues={locationData}
            onSubmit={values => this.handleSubmit(values)}
            handleSetPrimaryActionDisabled={this.setPrimaryActionDisabled}
          />

          {warning && <P color="error">{warning}</P>}
        </LoadingContainer>
      </ModalContent>
    );
  }
}

const mapStateToProps = ({ locations }): PropsFromState => { return { locations } };
export default withRouter(connect(mapStateToProps)(LockOutLocationUsersModal));

import React from 'react';
import { Grid } from '@material-ui/core';
import "./VgsCardForm.css";
import { connect } from 'react-redux';
import { VGSCollect, VGSForm, VGSFormFieldId, VGSScriptUrl } from '../../services';
import { GlobalState } from '../../types';
import { PublicDataState } from '../../reducers/public';

interface OwnProps {
    onFormCreated?: (vgsForm: VGSForm) => void;
    onFormChanged?: (state: VgsCardFormState) => void;
}

type StateProps = Pick<GlobalState, "appSettings"> & Pick<PublicDataState, "settings"> & {
};

type Props = OwnProps & StateProps;

interface State {
    form?: VGSForm;
    isScriptLoaded?: boolean;
}

class VgsCardForm extends React.Component<Props, State> {
    fadeTimeout = {};
    constructor(props: Props) {
        super(props);
        this.state = {};
        this.scriptLoaded = this.scriptLoaded.bind(this);
        this.handleFormChageState = this.handleFormChageState.bind(this);
    }

    componentDidMount() {
        const script = document.createElement("script");
        script.src = VGSScriptUrl;
        script.onload = () => this.scriptLoaded();
        document.body.appendChild(script);
    }

    scriptLoaded() {
        this.setState({ isScriptLoaded: true });
        // Initialize VGS form
        const { appSettings, settings, onFormCreated } = this.props;
        var valutId: string | undefined, environment: string | undefined;
        // Get from public setting
        if (settings.veryGoodSecurityVaultId && settings.veryGoodSecurityVaultId) {
            valutId = settings.veryGoodSecurityVaultId;
            environment = settings.veryGoodSecurityEnvironment;
        }
        else {
            if (appSettings.loading || !appSettings.settings) {
                console.error("You didnt loaded app settings first - initialize it if you can.")
                return;
            }
            if (!appSettings.settings.config?.veryGoodSecurityEnvironment || !appSettings.settings.config?.veryGoodSecurityVaultId || !appSettings.settings.config?.veryGoodSecurityVersion) {
                console.error("VGS parameters wasn't provided.")
                return;
            }
            valutId = appSettings.settings.config.veryGoodSecurityVaultId;
            environment = appSettings.settings.config.veryGoodSecurityEnvironment;
        }

        const collectJS = (window as any).VGSCollect as VGSCollect;
        const vgsForm = collectJS.create(valutId, environment!, this.handleFormChageState);
        vgsForm.field("#" + VGSFormFieldId.CardNumber, {
            name: 'card_number',
            type: 'card-number',
            placeholder: "Enter your card",
            showCardIcon: true,
            validations: ['required', '/^[0-9]{4}(\\s?)[0-9]{4}(\\s?)(([0-9]{2})|([0-9]{4}(\\s?)[0-9]{4}))(\\s?)$/'],
            css: {
                'padding': '0px 15px',
                'font-size': '15px',
                'border': 'solid 1px #b9b9b9',
                '&:focus': {
                    'border-color': '#f4cf12',
                },
                '&.invalid.touched': {
                    'border-color': 'red',
                },
                '&.valid': {
                    'border-color': 'green',
                }
            }
        });

        this.setState({ form: vgsForm });

        onFormCreated?.(vgsForm);
    }

    handleFormChageState(state: VgsCardFormState): void {
        const { onFormChanged } = this.props;
        onFormChanged?.(state);
    }

    render(): React.ReactNode {
        return (
            <Grid container direction="column" wrap="nowrap">
                <Grid item>
                    <div id={VGSFormFieldId.CardNumber}></div>
                </Grid>
            </Grid>
        );
    }
}

export interface VgsCardFormState {
    card_number: {
        bin?: string;
        cardType?: string;
        isDirty?: boolean;
        isEmpty?: boolean;
        isFocused?: boolean;
        isTouched?: boolean;
        isValid?: boolean;
        last4?: string;
        name: string;
    }
}

const mapStateToProps = ({ appSettings, publicData }: GlobalState): StateProps => ({ appSettings, settings: publicData.settings });
export default connect(mapStateToProps)(VgsCardForm);

import { Action } from 'redux';
import { MobileMenuActions } from '../constants/actions';

export interface MobileMenuState {
  isOpened?: boolean;
}

export function mobileMenu(state: MobileMenuState = {}, action: Action = { type: "unknown" }): MobileMenuState {
  switch (action.type) {
    case MobileMenuActions.TOGGLE_MOBILE_MENU:
      return {
        ...state,
        isOpened: !state.isOpened,
      };
    case MobileMenuActions.CLOSE_MOBILE_MENU:
      return {
        ...state,
        isOpened: false,
      };
    default:
      return state;
  }
}

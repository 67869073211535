import { UserActions } from '../constants/actions';
import * as UserService from '../services/api/users';
import { normalizeResult } from '../services/app/normalizr';
import { User } from '../types/User';
import { userSchema } from '../types/schemas';
import { App } from '../constants/app';
import { showSuccessAlert, showErrorAlert } from './alerts';
import _ from 'lodash';
import { ErrorCodes } from '../constants/errorCodes';

const requestUser = (userId: string) => ({ type: UserActions.REQUEST_USER, result: userId });
const receiveUser = (entities) => ({ type: UserActions.RECEIVE_USER, entities });
const creatingUser = () => ({ type: UserActions.CREATING_USER });
const createdUser = (entities) => ({ type: UserActions.CREATED_USER, entities });
const updatingUser = () => ({ type: UserActions.UPDATING_USER });
const updatedUser = (entities) => ({ type: UserActions.UPDATED_USER, entities });
const deletingUser = (userId: string) => ({ type: UserActions.DELETING_USER, userId });
const deletedUser = (userId: string) => ({ type: UserActions.DELETED_USER, userId });
const requestUserList = () => ({ type: UserActions.REQUEST_USER_LIST });
const receiveUserList = (entities, order) => ({ type: UserActions.RECEIVE_USER_LIST, entities, order });
const verifyingUser = (userId: string) => ({ type: UserActions.VERIFYING_USER, userId });
const verifiedUser = (userId: string) => ({ type: UserActions.VERIFIED_USER, userId });
const requestAdminUserList = () => ({ type: UserActions.REQUEST_ADMIN_USER_LIST });
const receiveAdminUserList = (entities, order) => ({ type: UserActions.RECEIVE_ADMIN_USER_LIST, entities, order });
const requestLockOutLocationUsers = () => ({ type: UserActions.REQUEST_LOCK_OUT_LOCATION_USERS });
const receiveLockOutLocationUsers = (entities, order) => ({ type: UserActions.RECEIVE_LOCK_OUT_LOCATION_USERS, entities, order });
const requestLockOutCompanyUsers = () => ({ type: UserActions.REQUEST_LOCK_OUT_COMPANY_USERS });
const receiveLockOutCompanyUsers = (entities, order) => ({ type: UserActions.RECEIVE_LOCK_OUT_COMPANY_USERS, entities, order });
const normalizeUserResult = normalizeResult(userSchema);

export function getUser(userId: string) {
  return (dispatch: Function) => {
    dispatch(requestUser(userId));
    return UserService.getUser(userId)
      .then(normalizeUserResult)
      .then((data: any) => {
        dispatch(receiveUser(data.entities));
        return data.entities.users[userId];
      });
  };
}

export function getUserIfNotInStore(userId: string) {
  return (dispatch: Function, getState: Function) => {
    const user = _.get(getState().users.data, userId);
    if (!user) {
      return dispatch(getUser(userId));
    }
  };
}

export function createUser(companyId: string, user: User) {
  return (dispatch: Function) => {
    dispatch(creatingUser());
    return UserService.createUser(companyId, user)
      .then(normalizeUserResult)
      .then((data: any) => {
        dispatch(createdUser(data.entities));
      });
  };
}

export function updateUser(user: Pick<User, "id"> & Partial<User>) {
  return (dispatch: Function) => {
    dispatch(updatingUser());
    return UserService.updateUser(user)
      .then(normalizeUserResult)
      .then((data: any) => {
        dispatch(updatedUser(data.entities));
      });
  };
}

// eslint-disable-next-line max-params
export function getUsers(companyId: string, offset = 0, limit = App.PAGE_SIZE, username?: string) {
  return (dispatch: Function) => {
    dispatch(requestUserList());
    return UserService.getUsers(companyId, offset, limit, username)
      .then(normalizeUserResult)
      .then((data: any) => {
        dispatch(receiveUserList(data.entities, data.result));
      });
  };
}

export function deleteUser(userId: string) {
  return (dispatch: Function) => {
    dispatch(deletingUser(userId));
    return UserService.deleteUser(userId)
      .then(() => {
        dispatch(deletedUser(userId));
        dispatch(showSuccessAlert('User successfully deleted!'));
      })
      .catch((error: any) => {
        if (String(error.status) === ErrorCodes.UNAUTHORIZED.key) {
          dispatch(showErrorAlert('You are not authorized to delete users'));
        }
      });
  };
}

export function verify(values: any, userId: string) {
  return (dispatch: Function) => {
    dispatch(verifyingUser(userId));
    return UserService.verify(values.code, userId).then(() => {
      dispatch(verifiedUser(userId));
    });
  };
}

export function regenerateVerificationCode(userId) {
  return () => {
    return UserService.regenerateVerificationCode(userId);
  };
}

// eslint-disable-next-line
export function getAdminUsers(offset = 0, limit = App.PAGE_SIZE, search: string = '', companytype: string = '', role: string = '') {
  return (dispatch: Function) => {
    dispatch(requestAdminUserList());
    return UserService.getAdminUsers(offset, limit, search, companytype, role)
      .then(normalizeUserResult)
      .then((data: any) => {
        dispatch(receiveAdminUserList(data.entities, data.result));
      });
  };
}

export function setLockOutLocationUsersState(locationId: string, isLocked: boolean) {
  return (dispatch: Function) => {
    dispatch(requestLockOutLocationUsers());
    return UserService.lockOutLocationUsers(locationId, isLocked)
      .then((data: any) => {
        dispatch(receiveLockOutLocationUsers(data.entities, data.result));
      });
  };
}

export function setLockOutCompanyUsersState(companyId: string, isLocked: boolean) {
  return (dispatch: Function) => {
    dispatch(requestLockOutCompanyUsers());
    return UserService.lockOutCompanyUsers(companyId, isLocked)
      .then((data: any) => {
        dispatch(receiveLockOutCompanyUsers(data.entities, data.result));
      });
  };
}

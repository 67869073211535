import { PwaPreparationActionType } from "../constants/actions";
import { BeforeInstallPromptEvent } from "../types/BeforeInstallPromptEvent";
import { PwaPreparationAction } from "../types/pwaPreparation/PwaPreparationAction";

function pwaPreparationSaveEvent(event: BeforeInstallPromptEvent): PwaPreparationAction {
    return {
        type: PwaPreparationActionType.SAVE_EVENT_OBJECT,
        content: event
    };
}

export { pwaPreparationSaveEvent };

import React from 'react';

interface Props {
  children?: React.ReactNode | React.ReactElement;
}

class ModalWrapper extends React.Component<Props> {
  render(): React.ReactElement {
    const { children } = this.props;
    return (
      <div className={`modal-full${children ? ' is-open' : ''}`}>
        <div className="modal-overlay" />
        <div className="modal-wrapper">
          <div className="modal-content">
            {children}
          </div>
        </div>
      </div>
    );
  }
}

export default ModalWrapper;

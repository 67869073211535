export class DepositEndpoints {
    static depositCash = (): string => '/api/v1/deposits/cash';
    static depositCheckCreateOrReuse = (): string => '/api/v1/deposits/create-or-reuse-check';
    static depositCard = (): string => '/api/v1/deposits/card';
    static validateCard = (): string => '/api/v1/deposits/card/validate';
    static depositFuelCard = (): string => '/api/v1/deposits/fuel-card';

    static setCashPaymentMethod = (): string => '/api/v1/deposits/cash/set';
    static setCardPaymentMethod = (): string => '/api/v1/deposits/card/set';
    static setRemoteCheckoutPaymentMethod = (): string => '/api/v1/deposits/remote/set';
    static setBatchPaymentMethod = (): string => `/api/v1/deposits/batch/set`;
    static setFuelCardPaymentMethod = (): string => '/api/v1/deposits/fuel-card/set';
    static makePreEditRequest = (): string => '/api/v1/deposits/fuel-card/pre-edit'

    static processCheck = (depositId: string): string => `/api/v1/deposits/check/${depositId}`;
    // dev remove when complete
    static processCheckFail = (depositId: string): string => `/api/v1/deposits/check/${depositId}111`;
    static getDepositForInvoice = (invoiceId: string): string => `/api/v1/invoices/${invoiceId}/deposits/checks/current`;
    static getDepositForVoidedInvoice = (invoiceId: string): string =>
        `/api/v1/invoices/${invoiceId}/deposits/checks/current?includeregular=false&includevoided=true`
    static approveCheck = (depositId: string): string => `/api/v1/deposits/check/${depositId}/approve`;
    static declineCheck = (depositId: string): string => `/api/v1/deposits/check/${depositId}/decline`;
    static depositLogs = (companyId: string, fromDate?: string, toDate?: string): string =>
        `/api/v1/${companyId}/deposits?fromdate=${fromDate}&todate=${toDate}`;

    /// The inversion of the [createdAtBefore] and [createdAtAfter] is intentionally. The API is incorrect, but
    /// for safety reasons, we don't want to change the API.
    static transactions = (companyId: string, createdAtAfter?: string, createdAtBefore?: string): string =>
        `/api/v1/${companyId}/deposits/transactions?types=instantpayout,settlementbatch&createdAtBefore=${createdAtAfter}&createdAtAfter=${createdAtBefore}`;
}

import { useEffect } from 'react';
import { Environment, GlobalState } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { getAppSettingsPublic } from '../../actions/public';

const getSrc = (env: Environment, type: RainforestScriptType): string => {
  switch (env) {
    case Environment.Production:
      return `https://static.rainforestpay.com/${type}.js`;
    default:
      return `https://static.rainforestpay.com/sandbox.${type}.js`;
  }
}

export enum RainforestScriptType {
  Merchant = 'merchant',
  Payment = 'payment',
}

const useRainforestScript = (type: RainforestScriptType, onLoad?: (loaded: boolean) => void): void => {
  const dispatch = useDispatch();
  const env = useSelector((state: GlobalState) => state.publicAppSettings?.settings?.serverEnvironment);

  useEffect(() => {
    dispatch(getAppSettingsPublic());
  }, [dispatch]);

  useEffect(() => {
    if (!env) return;

    const script = document.createElement('script');
    script.id = 'rainforest-js';
    script.defer = true;
    script.type = 'module';
    script.src = getSrc(env, type);
    script.addEventListener('load', () => { onLoad && onLoad(true) });
    script.addEventListener('error', () => { onLoad && onLoad(false) });
    document.body.appendChild(script);

    return () => {
      script.remove();
    };
  }, [type, env, onLoad]);
}

export default useRainforestScript;
const styles = (): {} => ({
  customStyledAlertWrapper: {
    '& .MuiAlert-root': {
      padding: '8px 16px',
      alignItems: 'center',
      borderRadius: '8px',
      color: '#212121',
      '& .MuiAlert-message': {
        fontWeight: 600
      },
      '& .MuiAlert-icon': {
        color: '#212121'
      }
    },
    '& .MuiAlert-standardWarning': {
      backgroundColor: '#FFF8E1',
    }
  }
});

export default styles;

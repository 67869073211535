import React from 'react';
import classes from './NotFound.module.css';
import img from "../../assets/img/roadsync-drivers.png";

interface Props {
  title?: React.ReactNode;
  description?: React.ReactNode;
  children?: React.ReactNode;
}

const NotFound: React.FC<Props> = (props: Props) => {
  const { title, description, children } = props;
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <img src={img} className={classes.img} alt="RoadSync" />
        <h3 className={classes.title}>{title || "Sorry!"}</h3>
        <p className={classes.description}>
          {description || "The page you are looking for cannot be found."}
          {children && <span className={classes?.children}>{children}</span>}
        </p>
      </div>
    </div>
  )
};

export default NotFound;

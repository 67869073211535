import { SurveyEndpoints } from './SurveyEndpoints';
import { Survey } from './Survey';
import * as HTTP from '../../services/api/http';
import { SurveyInput } from './SurveyInput';

export function updateSurveyById(surveyUserId: string, values: Partial<SurveyInput>): Promise<SurveyInput> {
  return HTTP.putJSON(SurveyEndpoints.updateSurveyById(surveyUserId), values);
}

export function createUserSurvey(userId: string, values: Partial<SurveyInput>): Promise<SurveyInput> {
  return HTTP.postJSON(SurveyEndpoints.createUserSurvey(userId), values);
}

export function getSurveysByUserId(userId: string): Promise<SurveyInput | null> {
  return HTTP.get(SurveyEndpoints.getSurveysByUserId(userId));
}

export function getCurrentSurvey(): Promise<Survey | undefined> {
  return HTTP.get(SurveyEndpoints.getCurrentSurvey());
}

export function stopSurvey(): Promise<"ok"> {
  return HTTP.postJSON(SurveyEndpoints.stopSurvey());
}

export function startSurvey(companyTypes: string[]): Promise<"ok"> {
  return HTTP.postJSON(SurveyEndpoints.startSurvey(), { companyTypes });
}

export function getSurveys(): Promise<Survey[]> {
  return HTTP.get(SurveyEndpoints.getAllSurveys());
}

export function saveSurvey(survey: Partial<Survey>): Promise<Survey> {
  const url = survey.id ? SurveyEndpoints.updateSurvey(survey.id) : SurveyEndpoints.createSurvey();
  return HTTP.postJSON(url, survey);
}

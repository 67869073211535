import { gt } from "lodash";
import React, { useState, useEffect } from "react";
import { decrementDecimalValueByOne, incrementDecimalValueByOne } from "../../services/app/calculation";
import { round } from "../../services/app/invoice";
import { isDecimalTwoPlaces } from "../../services/app/validators";

type InputDecimalProps = {
  id?: string;
  value?: number;
  className?: string;
  placeholder?: string;
  onChange: (value?: string | number) => void;
  shouldPreventUpdateValue: (event?: React.ChangeEvent<HTMLInputElement>) => boolean;
}

const InputDecimal: React.FC<InputDecimalProps> = (props: InputDecimalProps) => {
  const { id, value, className, placeholder, onChange, shouldPreventUpdateValue } = props;
  const [inputValue, setInputValue] = useState(Number(value) || 0);
  // eslint-disable-next-line
  useEffect(() => onChange(inputValue), [inputValue]);

  const updateValueManually = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(Math.max(0.1, Number(event.currentTarget.value)));
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    shouldPreventUpdateValue(event) ? void(0) : updateValueManually(event);
  }

  const handleClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>): void => {
    event.stopPropagation();
  }

  const increment = (event: React.MouseEvent<HTMLInputElement, MouseEvent>): void => {
    event.stopPropagation();
    const incrementedValue = incrementDecimalValueByOne(inputValue);
    setInputValue(incrementedValue);
  }

  const decrement = (event: React.MouseEvent<HTMLInputElement, MouseEvent>): void => {
    event.stopPropagation();
    const roundedValueTwoDecimalPlaces = round(Number(inputValue), 2);
    const currentValueTimes100 = roundedValueTwoDecimalPlaces * 100; // can have side effects like fractions of the number 
    const currentValueTimes100Whole = Math.round(currentValueTimes100); // deals with side effects above
    const shouldAllowDecrement = isDecimalTwoPlaces(typeof inputValue !== 'string' ? inputValue.toString() : inputValue) && gt(currentValueTimes100Whole, 109);

    if (shouldAllowDecrement) {
      setInputValue(decrementDecimalValueByOne(inputValue));
    }
  }

  return (
    <span className={className}>
      <span id={`decrement-button-${id}`} onClick={decrement} className="btn-amount btn-minus icon-minus btn_minus"></span>
      <input
        id={id}
        type="number"
        onChange={handleChange}
        onClick={handleClick}
        placeholder={placeholder}
        value={inputValue}
        min="0.01"
        step="1"
      />
      <span id={`increment-button-${id}`} onClick={increment} className="btn-amount btn-plus icon-plus btn_plus"></span>
    </span>
  )
}

export default InputDecimal;

import { Theme } from "@material-ui/core";

const styles = (theme: Theme): {} => ({
  directPaymentForm: {
    [theme.breakpoints.up(304)]: {
      paddingLeft: '97px',
    }
  },
  directPaymentNonEditableField: {
    '& .MuiFormLabel-root': {
      fontSize: '16px',
      fontWeight: 700,
      color: '#212121',
      transform: 'translate(14px, -6px) scale(1)',
      top: '-4px',
      [theme.breakpoints.between(304, 314)]: {
        position: 'static',
        marginBottom: '-11px'
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: '0'
    },
    '& [class*="PrivateNotchedOutline-legendLabelled"]': {
      display: 'none'
    },
    '& .MuiSvgIcon-root': {
      fill: '#212121',
      width: '16px',
      height: '16px'
    },
    '& .MuiInputBase-input.Mui-disabled': {
      color: '#212121!important'
    }
  }
});

export default styles;

import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { submit } from 'redux-form';
import { ModalsConstants } from '../../constants/modals';
import ModalContent from '../../components/modals/ModalContent';
import SendReceiptForm, { SendReceiptFormData } from '../../components/invoice/SendReceiptForm';
import { sendReceipt } from '../../actions/invoices';
import { closeModal } from '../../actions/modals';
import { handleReduxFormError } from '../../services/app/forms';
import { normalizePhoneOrEmail } from "../../services/app/normalizers";

interface Props extends DispatchProp, OwnProps, PropsFromState { }

interface PropsFromState {
  submitting?: boolean;
}

interface OwnProps {
  invoiceId: string;
  token: string;
  payerPhone?: string;
}

class InvoiceSendReceiptModal extends React.Component<Props> {

  constructor(props: Props) {
    super(props);

    this.handleModalSubmit = this.handleModalSubmit.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleModalSubmit(): void {
    const { dispatch } = this.props;
    dispatch(submit('sendReceipt'));
  }

  handleModalClose(): void {
    const { dispatch } = this.props;
    dispatch(closeModal(ModalsConstants.SEND_RECEIPT));
  }

  handleFormSubmit(values: SendReceiptFormData): void {
    const { dispatch, invoiceId, token } = this.props;
    values.phoneOrEmail = normalizePhoneOrEmail(values.phoneOrEmail);
    const action = values.phoneOrEmail && String(values.phoneOrEmail).indexOf('@') >= 0
      ? sendReceipt(invoiceId, values.phoneOrEmail, undefined, token)
      : sendReceipt(invoiceId, undefined, values.phoneOrEmail, token);

    dispatch<any>(action)
      .then(() => { dispatch(closeModal(ModalsConstants.SEND_RECEIPT)) })
      .catch(handleReduxFormError);
  }

  render(): React.ReactElement {
    const { submitting, payerPhone } = this.props;
    return (
      <ModalContent
        className="invoice-decline-reason-modal"
        primaryActionText={submitting ? 'Sending...' : 'Send Receipt'}
        primaryActionClick={this.handleModalSubmit}
        primaryActionDisabled={submitting}
        secondaryActionText="Close"
        secondaryActionClick={this.handleModalClose}
      >
        <SendReceiptForm initialValues={{ phoneOrEmail: payerPhone }} onSubmit={this.handleFormSubmit} />
      </ModalContent>
    );
  }
}

const mapStateToProps = ({ form }): PropsFromState => ({ submitting: form?.sendReceipt?.submitting });
export default connect(mapStateToProps)(InvoiceSendReceiptModal);

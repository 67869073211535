export class CompanyFeatures {
  static getFeaturesByCompanyId = (companyId: string, offset: number, limit: number): string => (
    `/api/v1/company/${companyId}/features?offset=${offset}&limit=${limit}`
  )
  static createFeature = (companyId: string): string => `/api/v1/company/${companyId}/features`;
  static updateFeatureById = (featureId: string): string => `/api/v1/features/${featureId}`;
  static getFeatureById = (featureId: string): string => `/api/v1/features/${featureId}`;
  static deleteFeatureById = (featureId: string): string => `/api/v1/features/${featureId}`;
  static getPublicFeaturesByCompanyId = (companyId: string): string => `/api/v1/public/company/${companyId}/features`;
  static getWexTermsConditions = (companyId: string): string => `/api/v1/company/${companyId}/wex/accept-t-and-c`;
}

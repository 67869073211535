import { AuthActions, UserActions } from '../constants/actions';
import { User } from '../types/User';

export type AuthState = {
  checking: boolean;
  me: User;
};

interface AuthAction {
  type: string;
  result?: User;
  userId?: string;
}

// eslint-disable-next-line max-lines-per-function
export function auth(
  state: AuthState = { checking: true, me: {} as any },
  action: AuthAction = { type: "unknown" }
): AuthState {
  switch (action.type) {
    case AuthActions.RECEIVE_LOGIN:
    case AuthActions.RECEIVE_AUTH_CHECK:
    case AuthActions.CHANGED_PASSWORD:
      if(state.me.accessToken) {
        (action.result as User).accessToken = state.me.accessToken;
      }
      if(state.me.refreshToken) {
        (action.result as User).refreshToken = state.me.refreshToken;
      }
      return {
        ...state,
        checking: false,
        me: action.result as User,
      };
    case AuthActions.REQUEST_AUTH_CHECK:
      return {
        ...state,
        checking: true,
      };
    case UserActions.VERIFIED_USER:
      // Verify User if response is successful
      const user = state.me;
      if (user.id === action.userId) {
        user.isVerified = true;
      }
      return {
        ...state,
        checking: false,
        me: user,
      };
    case AuthActions.REQUEST_LOGOUT:
      return { ...state, me: {} as any, checking: false };
    default:
      return state;
  }
}

import _ from 'lodash';

interface Type {
  key: string;
  message: string; // default message
}
const code = 'code';

export class ErrorCodes {
  static readonly BAD_REQUEST: Type = {
    key: '400',
    message: 'Bad request',
  };
  static readonly UNAUTHORIZED: Type = {
    key: '401',
    message: 'You are not authorized to do this action.',
  };
  static readonly FORBIDDEN: Type = {
    key: '403',
    message: `Forbidden. You don't have enough access rights.`,
  };
  static readonly NOT_FOUND: Type = {
    key: '404',
    message: 'Page you are looking for not found.',
  };
  static readonly INTERNAL_SERVER_ERROR: Type = {
    key: '500',
    message: 'Something went wrong, please try again later.',
  };
  static readonly UNAVAILABLE: Type = {
    key: '503',
    message: 'Service temporary unavailable',
  };
  static readonly GATEWAY_TIMEOUT_ERROR: Type = {
    key: '504',
    message: 'RoadSync is experiencing technical difficulties, please try again later.',
  };

  private static readonly LOOKUP = {
    'code_400': ErrorCodes.BAD_REQUEST,
    'code_401': ErrorCodes.UNAUTHORIZED,
    'code_403': ErrorCodes.FORBIDDEN,
    'code_404': ErrorCodes.NOT_FOUND,
    'code_500': ErrorCodes.INTERNAL_SERVER_ERROR,
    'code_503': ErrorCodes.UNAVAILABLE,
    'code_504': ErrorCodes.GATEWAY_TIMEOUT_ERROR,
  };

  static getTypes(): Type[] {
    return _.values(ErrorCodes.LOOKUP);
  }

  static getByKey(key: string): Type {
    return ErrorCodes.LOOKUP[`${code}_${key}`];
  }
}

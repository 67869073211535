import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { Box, DialogActions, DialogContent, DialogTitle, Fab, Grid, StyledComponentProps, withStyles } from "@material-ui/core";
import { P } from "@roadsync/roadsync-ui";
import { Button } from "./ui/Buttons";
import { isLatestVersion } from "../services/api/utils";
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import styles from "./VersionChecker.css";

type Props = StyledComponentProps & {
  duration?: number;
};

interface State {
  isOpen: boolean;
  isUpToDate: boolean;
  timer?: NodeJS.Timer;
}

class VersionChecker extends React.Component<Props, State> {

  private mounted = false;

  constructor(props: Props) {
    super(props);
    this.state = { isOpen: false, isUpToDate: true };
    this.handleReload = this.handleReload.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.checkForLatestVersion = this.checkForLatestVersion.bind(this);
  }

  async componentDidMount(): Promise<void> {
    this.mounted = true;
    if (this.mounted) this.setState({ timer: setInterval(this.checkForLatestVersion, this.getDuration()) });
    await this.checkForLatestVersion();
  }

  componentWillUnmount(): void {
    const { timer } = this.state;
    if (timer) {
      clearInterval(timer);
      if (this.mounted) this.setState({ timer: undefined });
    }
    this.mounted = false;
  }

  getDuration(): number {
    const { duration } = this.props;
    return duration || 5 * 60 * 1000;
  }

  async checkForLatestVersion(): Promise<void> {
    const isUpToDate = await isLatestVersion();
    if (this.mounted) this.setState({ isUpToDate });
  }

  handleOpen(): void {
    if (this.mounted) this.setState({ isOpen: true });
  }

  handleClose(): void {
    if (this.mounted) this.setState({ isOpen: false });
  }

  handleReload(): void {
    window.location.reload();
  }

  render(): React.ReactElement {
    const { classes } = this.props;
    const { isOpen, isUpToDate } = this.state;
    return (
      <>
        <Dialog maxWidth="xs" open={isOpen} onClose={this.handleClose}>
          <DialogTitle>
            <Grid container alignItems="center" spacing={1}>
              <Grid item><NewReleasesIcon /></Grid>
              <Grid item>We've updated the app!</Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <P>
              A new app update is available. To ensure the optimal experience,  we recommend reloading the page.
              Do you want to reload this browser window now?
            </P>
          </DialogContent>
          <DialogActions>
            <Button variant="text" onClick={this.handleClose} id="closeUpdateDialogBtn">
              No
            </Button>
            <Button color="primary" autoFocus onClick={this.handleReload} id="updateAppBtn">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        {!isUpToDate &&
          <Box className={classes?.fab}>
            <Grid container alignItems="center" justifyContent="center">
              <Grid item>
                <Fab color="primary" aria-label="New updates available" variant="extended" onClick={this.handleOpen}>
                  <NewReleasesIcon />
                  &nbsp;Updates available
                </Fab>
              </Grid>
            </Grid>
          </Box>
        }
      </>
    );
  }
}

export default withStyles(styles)(VersionChecker);

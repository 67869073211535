import React from "react";
import Alert from "../../../components/ui/Alert";
import { Button } from "../../../components/ui/Buttons";
import { P, Grid } from "@roadsync/roadsync-ui";

interface Props {
  confirm: () => void;
}

const InvoiceTotalThresholdAlert: React.FC<Props> = (props: Props): React.ReactElement => {
  const { confirm } = props;
  return (
    <Alert severity="error">
      <Grid container justifyContent="space-between">
        <Grid item>
          <P>Invoice total exceeds normal threshold. Please verify large amount for this invoice.</P>
        </Grid>
        <Grid item>
          <Button
            size="small"
            onClick={confirm}
            id="verify-invoice-amt-btn"
          >
            Confirm
          </Button>
        </Grid>
      </Grid>
    </Alert>
  );
}

export default InvoiceTotalThresholdAlert;

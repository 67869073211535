import { FormControl, InputAdornment, TextField } from '@material-ui/core';
import React from 'react';
import { useState } from "react"
import { FieldRenderProps } from 'react-final-form';
import { validateMoney } from "../../services/app/formats"

interface OwnProps {
  notMoreThan?: string;
  notLessThan?: string;
  label?: string;
  helperText?: string;

  // Inherited form Final Form
  disabled: boolean;
  input: {
    name: string;
    value: string;
    onChange: Function;
  };
  meta: {
    error?: string;
  }
}

type Props = FieldRenderProps<string, HTMLElement> & OwnProps;

// eslint-disable-next-line max-lines-per-function
const MoneyInput: React.FC<Props> = (props: Props) => {

  const { notLessThan, notMoreThan, input, helperText, meta, ...rest } = props;
  const [amount, setAmount] = useState(input.value ?? 0);

  function updateAmount(value: string): void {
    setAmount(value);
    input.onChange(value);
  }

  return (
    <FormControl fullWidth margin="none">
      <TextField
        {...rest}
        variant="outlined"
        id="moneyInput"
        onChange={(e): void => updateAmount(e.currentTarget?.value)}
        onBlur={(e): void => updateAmount(validateMoney(amount, notLessThan, notMoreThan))}
        value={amount}
        name={input.name}
        error={meta.error}
        helperText={meta.error ?? helperText}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />
    </FormControl>
  )
}

export { MoneyInput }


import { createStyles, makeStyles, Theme } from '@material-ui/core';

const styles = makeStyles((theme: Theme) => createStyles({
    root: {
        height: '100%',
    },
}));

export default styles;

import { UserEndpoints } from './endpoints/UserEndpoints';
import { AuthEndpoints } from './endpoints/AuthEndpoints';
import { CompanyEndpoints } from './endpoints/CompanyEndpoints';
import { CompanyFeatures } from './endpoints/CompanyFeatures';
import { CompanyFees } from './endpoints/CompanyFees';
import { CompanySettings } from './endpoints/CompanySettings';
import { LocationEndpoints } from './endpoints/LocationEndpoints';
import { DepositEndpoints } from './endpoints/DepositEndpoints';
import { QAEndpoints } from './endpoints/QAEndpoints';
import { InvoiceEndpoints } from './endpoints/InvoiceEndpoints';
import { ProductEndpoints } from './endpoints/ProductEndpoints';
import { PublicInvoiceEndpoints } from './endpoints/PublicInvoiceEndpoints';
import { PublicDepositEndpoints } from './endpoints/PublicDepositEndpoints';
import { SocketEndpoints } from './endpoints/SocketEndpoints';
import { ShiftEndpoints } from './endpoints/ShiftEndpoints';
import { ActivityLogsEndpoints } from './endpoints/ActivityLogsEndpoints';
import { WorkOrdersEndpoints } from './endpoints/WorkOrdersEndpoints';
import { BankEndpoints } from './endpoints/BankEndpoints';
import { CardEndpoints } from './endpoints/CardEndpoints';
import { ReportEndpoints } from './endpoints/ReportEndpoints';
import { CheckEndpoints } from './endpoints/CheckEndpoints';
import { CompanyCarriers } from './endpoints/CompanyCarriers';
import { SurveyEndpoints } from '../../components/survey/SurveyEndpoints';
import { AppSettingsEndpoints } from './endpoints/AppSettingsEndpoints';
import { Accounting } from './endpoints/Accounting';
import { TransferEndpoints } from './endpoints/TransferEndpoints';
import { Dashboard } from './endpoints/Dashboard';
import { CheckAuthorizerEndpoints } from './endpoints/CheckAuthorizerEndpoints';
import { DepartmentEndpoints } from './endpoints/DepartmentEndpoints';
import { CompanyLabelEndpoints } from './endpoints/CompanyLabelEndpoints';
import { ReportRecipientEndpoints } from './endpoints/ReportRecipientEndpoints';
import { EmailsLogsEndpoints } from './endpoints/EmailsLogsEndpoints';
import { CopyLocationEndpoints } from './endpoints/CopyLocationEndpoints';
import { FileEndpoints } from './endpoints/FileEndpoints';
import { PayoutsEndpoints } from './endpoints/PayoutsEndpoints';
import { OnesourceEndpoints } from './endpoints/OnesourceEndpoints';
import { CardReaderEndpoints } from './endpoints/CardReaderEndpoints';
import { AddressBookEndpoints } from './endpoints/AddressBookEndpoints';

export {
  CopyLocationEndpoints,
  EmailsLogsEndpoints,
  CompanyLabelEndpoints,
  CheckAuthorizerEndpoints,
  UserEndpoints,
  AuthEndpoints,
  CompanyEndpoints,
  CompanyFeatures,
  CompanyFees,
  CompanySettings,
  LocationEndpoints,
  DepositEndpoints,
  QAEndpoints,
  InvoiceEndpoints,
  ProductEndpoints,
  PublicInvoiceEndpoints,
  PublicDepositEndpoints,
  SocketEndpoints,
  ShiftEndpoints,
  ActivityLogsEndpoints,
  WorkOrdersEndpoints,
  BankEndpoints,
  CardEndpoints,
  ReportEndpoints,
  CheckEndpoints,
  CompanyCarriers,
  SurveyEndpoints,
  AppSettingsEndpoints,
  Accounting,
  TransferEndpoints,
  Dashboard,
  DepartmentEndpoints,
  ReportRecipientEndpoints,
  FileEndpoints,
  PayoutsEndpoints,
  OnesourceEndpoints,
  CardReaderEndpoints,
  AddressBookEndpoints
}

export const apiPath = (): string | undefined => process.env.REACT_APP_API_PATH;
export const absoluteApiPath = (relative = ''): string => `${apiPath()}${relative}`;

export interface URLSearchMap {
  [k: string]: string | number | null | undefined | boolean | string[] | number[];
}

export function makeURLSearchParams(params: URLSearchMap): URLSearchParams {
  const p = new URLSearchParams();
  Object.keys(params).forEach(key => {
    const val = params[key];
    if (val !== undefined && val !== null) {
      p.set(key, val.toString());
    }
  });
  return p;
}

export class Accounting {
  static getAuthorizationToQuickbooks = (companyId: string): string => {
    return `/api/v1/accounting/accountingquickbooks/company/${companyId}`;
  }
  static getCompanyAccountingSettings = (companyId: string, featureType: string): string => {
    return `/api/v1/accounting/${featureType}/company/${companyId}/settings`;
  }
  static updateCompanyAccountingSettings = (companyId: string, featureType: string): string => {
    return `/api/v1/accounting/${featureType}/company/${companyId}/settings`;
  }
  static getAccountingProductsList = (companyId: string, featureType: string): string => {
    return `/api/v1/accounting/${featureType}/company/${companyId}/items`;
  }
}

import React from "react";
import { connect, DispatchProp } from "react-redux";
import { WithChildrenProps } from "../WithChildrenProps";

interface Props extends WithChildrenProps {
  includes: string[];
}

interface PropsFromState extends DispatchProp {
  accessibleLocations?: string[];
}

const IfUserLocation: React.FC<Props & PropsFromState> = (props: Props & PropsFromState): React.ReactElement => {
  const { includes, children, accessibleLocations } = props;
  return (
    <React.Fragment>
      {!!accessibleLocations?.length && !!includes.filter(location => accessibleLocations.includes(location)).length &&
        <React.Fragment>{children}</React.Fragment>
      }
    </React.Fragment>
  )
}

const mapStateToProps: (state: any) => PropsFromState = ({ appSettings, dispatch, auth: { me } }) => ({
  accessibleLocations: appSettings?.settings?.accessibleLocations || me?.locationIds,
  dispatch
});
export default connect<PropsFromState, Props>(mapStateToProps)(IfUserLocation);

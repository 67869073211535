import { mergeStateAndEntityResult } from '../services/app/normalizr';
import { WorkOrderActions } from '../constants/actions';
import _ from 'lodash';
import { WorkOrderStatus } from '../constants/workOrder';
import { WorkOrder } from '../types/WorkOrder';

export interface WorkOrdersState {
  data: { [id: string]: WorkOrder };
  order: string[];
  ui?: {
    loading?: boolean;
  };
}

// eslint-disable-next-line max-lines-per-function
export function workOrders(
  state: WorkOrdersState = { data: {}, ui: {}, order: [] },
  action: Record<string, any> = {}
): WorkOrdersState {
  const nextData = mergeStateAndEntityResult(state, action.entities, 'workOrders');
  switch (action.type) {
    case WorkOrderActions.REQUEST_WORK_ORDER:
      return _.set({ ...state }, `data.${action.result}`, { loading: true });
    case WorkOrderActions.REQUEST_WORK_ORDER_LIST:
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: action.showLoader,
        },
      };
    case WorkOrderActions.RECEIVE_WORK_ORDER_LIST:
      return {
        ...state,
        data: nextData,
        order: action.order,
        ui: {
          ...state.ui,
          loading: false,
        },
      };
    case WorkOrderActions.CREATED_WORK_ORDER:
      return {
        ...state,
        data: nextData,
        order: [action.workOrderId, ...state.order],
      };
    case WorkOrderActions.DELETED_WORK_ORDER:
      return {
        ...state,
        data: _.omit({ ...state.data }, action.workOrderId),
        order: _.pull(state.order, action.workOrderId),
      };
    case WorkOrderActions.SENT_WORK_ORDER_RECEIPT: {
      const workOrder = state.data[action.workOrderId];
      if (workOrder && workOrder.status !== WorkOrderStatus.COMPLETED.key) {
        return _.set({ ...state }, `data.${action.workOrderId}`, {
          ...workOrder,
          status: action.workOrderStatus,
        });
      }

      return {
        ...state,
        data: nextData,
      };
    }
    default:
      return {
        ...state,
        data: nextData,
      };
  }
}

import { FeatureActions } from '../constants/actions';
import _ from 'lodash';
import { mergeStateAndEntityResult } from '../services/app/normalizr';
import { Feature } from '../types/Feature';

export interface UIState {
  loading: boolean;
}

export interface FeatureState {
  data: { [id: string]: Feature };
  ui: UIState;
  order: string[];
}

const defaultState: FeatureState = {
  data: {},
  ui: { loading: false },
  order: [],
};

// eslint-disable-next-line
export function features(state = defaultState, action: Record<string, any> = {}): FeatureState {
  const nextData = mergeStateAndEntityResult(state, action.entities, 'features');

  switch (action.type) {
    case FeatureActions.REQUEST_FEATURE:
      return _.set({ ...state }, `data.${action.companyId}`, { loading: true });
    case FeatureActions.REQUEST_FEATURE_LIST:
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: true,
        },
      };
    case FeatureActions.RECEIVE_FEATURE_LIST:
      return {
        ...state,
        data: nextData,
        order: action.order,
        ui: {
          ...state.ui,
          loading: false,
        },
      };
    case FeatureActions.DELETED_FEATURE:
      return {
        ...state,
        data: _.omit({ ...state.data }, action.featureId),
        order: _.pull(state.order, action.featureId),
      };
    case FeatureActions.DESTROY_LIST: {
      return {
        data: {},
        order: [],
        ui: {
          ...state.ui,
          loading: true,
        },
      };
    }
    default:
      return {
        ...state,
        data: nextData,
      };
  }
}

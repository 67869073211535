import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Invoice, Company, GlobalState, InvoicePreparationStepProps } from '../../../../types';
import { isSignatureEnabled, isLicenseRequiredEnabled, isConfirmationBeforeSendingEnabled, isRainforestPayEnabled } from '../../../../services/app/company';
import { AuthState } from '../../../../reducers/auth';
import { CompaniesState } from '../../../../reducers/companies';
import { PublicDataState } from '../../../../reducers/public';
import VgsCardDetails from './VgsCardDetails';
import RainforestCardDetails from './RainforestCardDetails';

type PropsFromState = Pick<GlobalState, "appSettings" | "invoices"> & {
    isConfirmationEnabled?: boolean;
    licenseRequired?: boolean;
    invoice: Invoice;
    companyId: string;
    signatureEnabled?: boolean;
    auth: AuthState;
    companies: CompaniesState;
    publicData: PublicDataState;
    company: Company;
}

interface RouteParams {
    invoiceId: string;
}

interface Props extends InvoicePreparationStepProps, PropsFromState, RouteComponentProps<RouteParams>, DispatchProp { }

class CardDetails extends React.Component<Props> {
    render(): React.ReactNode {
        const { company } = this.props;
        return isRainforestPayEnabled(company)
            ? <RainforestCardDetails {...this.props} />
            : <VgsCardDetails {...this.props} />;
    }
}

export const mapStateToProps = (
    { appSettings, invoices, companies, auth, publicData }: GlobalState,
    { match: { params: { invoiceId } } }: RouteComponentProps<RouteParams>
): PropsFromState => {
    const invoice = invoices?.data?.[invoiceId] as Invoice;
    const company = companies?.data?.[String(invoice.company)] as Company;
    const companyId = company?.id as string;
    return {
        appSettings,
        invoices,
        invoice,
        isConfirmationEnabled: isConfirmationBeforeSendingEnabled(company),
        signatureEnabled: isSignatureEnabled(company),
        licenseRequired: isLicenseRequiredEnabled(company),
        companyId,
        auth,
        companies,
        publicData,
        company,
    };
};

export default withRouter(connect(mapStateToProps)(CardDetails));



import React from 'react'
import { Box, Typography } from '@material-ui/core';
import useStyles from './ProductInputLabel.css';

interface Props {
  children?: string;
}

export const ProductInputLabel = (props: Props) => {
  const classes = useStyles();
  return <Box textAlign="center" className={classes.productInputLabel}>
    <Typography variant="body2">{props?.children}</Typography>
  </Box>;
}

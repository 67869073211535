import React from "react";
import { ButtonProps } from "./ButtonProps";
import { ButtonConfig } from "./ButtonConfig";
import { Button } from "./Button";

export const LargeSecondaryButton = (props: ButtonProps): React.ReactElement<ButtonProps> => {
  const { children, ref, ...rest } = props;
  return (
    <Button size="large" color="secondary" variant={ButtonConfig.Variant} {...rest}>
      {children}
    </Button>
  )
};

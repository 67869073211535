import React from 'react';

interface Props {
  className?: string;
  primaryActionText?: string;
  primaryActionClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  primaryActionDisabled?: boolean;
  secondaryActionText?: string;
  secondaryActionClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  secondaryActionDisabled?: boolean;
  children: React.ReactElement | React.ReactNode;
  invertedButtonColors?: boolean;
}

class ModalContent extends React.Component<Props> {

  // eslint-disable-next-line max-lines-per-function
  render(): React.ReactElement {

    const {
      primaryActionText, primaryActionClick, primaryActionDisabled,
      secondaryActionText, secondaryActionClick, secondaryActionDisabled,
      className, children, invertedButtonColors
    } = this.props;

    return (
      <div className={className}>
        <div className="modal-inner-content">
          {children}
        </div>
        <div className="modal-buttons-area">
          {primaryActionClick &&
            <button
              id="modal-primary-action-btn"
              className={invertedButtonColors ? "btn btn-white secondary-btn" : "btn btn-dark"}
              disabled={primaryActionDisabled}
              onClick={primaryActionClick}
              autoFocus={!secondaryActionClick}
            >
              {primaryActionText}
            </button>
          }
          {secondaryActionClick &&
            <button
              id="modal-secondary-action-btn"
              className={invertedButtonColors ? "btn btn-dark" : "btn btn-white secondary-btn"}
              disabled={secondaryActionDisabled}
              onClick={secondaryActionClick}
              autoFocus
            >
              {secondaryActionText}
            </button>
          }
        </div>
      </div>
    );
  }
}

export default ModalContent;

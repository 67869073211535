import React from 'react';
import { connect } from 'react-redux';
import { ModalsConstants } from '../../constants/modals';
import ModalWrapper from '../../components/modals/ModalWrapper';
import InvoiceDeclineReasonModal from './InvoiceDeclineReasonModal';
import InvoiceSendReceiptModal from './InvoiceSendReceiptModal';
import InvoiceSendRemoteCheckout from './InvoiceSendRemoteCheckoutModal';
import InvoiceLineItemCustomService from './InvoiceLineItemCustomService';
import DeleteItemModal from './DeleteItemModal';
import InvoiceAddSignature from './InvoiceAddSignature';
import SetPassword from './SetPassword';
import CopyLocation from './CopyLocation';
import CreateWorkOrder from '../workOrder/management/CreateWorkOrder';
import WorkOrderSendModal from './WorkOrderSendModal';
import WorkOrderDismissModal from './WorkOrderDismissModal';
import CheckAuthorizerCallerModal from './CheckAuthorizerCallerModal';
import TermsAndConditionsModal from './TermsAndConditionsModal';
import TwoFactorAuthenticationModal from './TwoFactorAuthenticationModal';
import DeleteInvoiceReason from './DeleteInvoiceReason';
import WorkOrderPdf from './WorkOrderPdf';
import ConfirmInvoiceTotal from './ConfirmInvoiceTotal';
import ConfirmInvoiceCreation from './ConfirmInvoiceCreation';
import FleetCardCheckModal from './FleetCardCheckModal';
import BankAccountChangeModal from './BankAccountChangeModal';
import WorkOrderPdfForEmployees from './WorkOrderPdfForEmployees';
import WorkOrderAddSignature from './WorkOrderAddSignature';
import LockOutLocationUsersModal from './LockOutLocationUsersModal';
import LockOutCompanyUsersModal from './LockOutCompanyUsersModal';
import { GlobalState } from '../../types/GlobalState';
import DownloadAppModal from './DownloadAppModal';
import ReportRedirectModal from './ReportRedirectModal';
import TowbookInvoiceError from './TowbookInvoiceErrorModal';
import AddressBookContactForm from '../../components/addressBook/AddressBookContactForm';

type PropsFromState = Pick<GlobalState, "modals">;
type Props = PropsFromState;

// eslint-disable-next-line complexity,max-lines-per-function
const Modals: React.FC<Props> = (props: Props) => {
  const { modals } = props;
  if (!modals?.length) {
    return <ModalWrapper />;
  }

  let component: React.ReactNode = null;

  const item = modals?.[modals.length - 1]; // only show last item in array
  const modalProps: any = { ...item.params };

  switch (item?.name) {
    case ModalsConstants.DECLINE_REASON:
      component = <InvoiceDeclineReasonModal {...modalProps} />;
      break;
    case ModalsConstants.SEND_RECEIPT:
      component = <InvoiceSendReceiptModal {...modalProps} />;
      break;
    case ModalsConstants.LINE_ITEMS_CUSTOM_SERVICE:
      component = <InvoiceLineItemCustomService {...modalProps} />;
      break;
    case ModalsConstants.DELETE_ITEM:
      component = <DeleteItemModal {...modalProps} />;
      break;
    case ModalsConstants.SIGNATURE:
      component = <InvoiceAddSignature {...modalProps} />;
      break;
    case ModalsConstants.WORK_ORDER_SIGNATURE:
      component = <WorkOrderAddSignature {...modalProps} />;
      break;
    case ModalsConstants.SET_PASSWORD:
      component = <SetPassword {...modalProps} />;
      break;
    case ModalsConstants.COPY_LOCATION:
      component = <CopyLocation {...modalProps} />;
      break;
    case ModalsConstants.CREATE_WORK_ORDER:
      component = <CreateWorkOrder {...modalProps} />;
      break;
    case ModalsConstants.SEND_WORK_ORDER:
      component = <WorkOrderSendModal {...modalProps} />;
      break;
    case ModalsConstants.HANDLE_WORK_ORDER_DISMISS:
      component = <WorkOrderDismissModal {...modalProps} />;
      break;
    case ModalsConstants.WORK_ORDER_PDF:
      component = <WorkOrderPdf {...modalProps} />;
      break;
    case ModalsConstants.WORK_ORDER_PDF_FOR_EMPLOYEES:
      component = <WorkOrderPdfForEmployees {...modalProps} />;
      break;
    case ModalsConstants.CHECK_AUTHORIZER_CALLER:
      component = <CheckAuthorizerCallerModal {...modalProps} />;
      break;
    case ModalsConstants.TERMS_AND_CONDITIONS:
      component = <TermsAndConditionsModal {...modalProps} />;
      break;
    case ModalsConstants.TWO_FACTOR_AUTHENTICATION:
      component = <TwoFactorAuthenticationModal {...modalProps} />;
      break;
    case ModalsConstants.DELETE_INVOICE_REASON:
      component = <DeleteInvoiceReason {...modalProps} />;
      break;
    case ModalsConstants.CONFIRM_INVOICE_TOTAL:
      component = <ConfirmInvoiceTotal {...modalProps} />;
      break;
    case ModalsConstants.CONFIRM_INVOICE_CREATION:
      component = <ConfirmInvoiceCreation {...modalProps} />;
      break;
    case ModalsConstants.FLEET_CARD_CHECK:
      component = <FleetCardCheckModal {...modalProps} />;
      break;
    case ModalsConstants.CHANGE_BANK_ACCOUNT:
      component = <BankAccountChangeModal {...modalProps} />;
      break;
    case ModalsConstants.SEND_REMOTE_CHECKOUT_INVOICE:
      component = <InvoiceSendRemoteCheckout {...modalProps} />;
      break;
    case ModalsConstants.LOCK_OUT_LOCATION_USERS:
      component = <LockOutLocationUsersModal {...modalProps} />;
      break;
    case ModalsConstants.LOCK_OUT_COMPANY_USERS:
      component = <LockOutCompanyUsersModal {...modalProps} />;
      break;
    case ModalsConstants.DOWNLOAD_APP:
      component = <DownloadAppModal />;
      break;
    case ModalsConstants.REPORT_REDIRECT:
      component = <ReportRedirectModal />;
      break;
    case ModalsConstants.TOWBOOK_INVOICE_ERROR:
      component = <TowbookInvoiceError {...modalProps} />;
      break;
    case ModalsConstants.ADDRESS_BOOK_ADD_NEW_PAYER:
      component = <AddressBookContactForm {...modalProps} />;
      break;
  }

  return (
    <ModalWrapper>
      {component}
    </ModalWrapper>
  );
};

const mapStateToProps = ({ modals }: GlobalState): PropsFromState => ({ modals });

export default connect(mapStateToProps)(Modals);

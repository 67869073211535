import React from 'react';
import { IconButton as MUIIconButton, Theme, withStyles, IconButtonProps as MUIButtonProps } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';

export enum IconButtonVariant {
  Error = "error",
}

interface OwnProps extends MUIButtonProps {
  href?: string;
  to?: string;
  variant?: IconButtonVariant | "error";
}

interface Props extends OwnProps, RouteComponentProps { }

// eslint-disable-next-line
const styles = (theme: Theme): {} => ({
  root: {
    background: theme.palette.action.hover,
    '&.MuiIconButton-colorPrimary': {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        background: theme.palette.primary.dark,
      }
    },
    '&.MuiIconButton-colorSecondary': {
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
      '&:hover': {
        background: theme.palette.secondary.dark,
      }
    }
  },
  [IconButtonVariant.Error]: {
    background: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      background: theme.palette.error.dark,
    }
  }
});

class IconButton extends React.Component<Props> {
  render(): React.ReactElement {
    const { edge, variant, className, ref, href, to, onClick, children, classes, history, staticContext, ...rest } = this.props;
    return (
      <MUIIconButton
        edge={edge}
        data-variant={variant}
        className={`${classes?.root} ${className ? className : ''} ${variant ? classes?.[variant] : ''}`}
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
          const newUrl = href || to;
          if (newUrl) {
            e.preventDefault();
            history.push(newUrl);
          }
          if (onClick) onClick(e);
        }}
        {...rest}>{children}
      </MUIIconButton>
    );
  }
}

export default withRouter(withStyles(styles)(IconButton));

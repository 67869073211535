import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";

interface Props {
  children?: React.ReactChild | React.ReactChildren | React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: "none",
    '@media print': {
      display: "initial",
    },
  }
}));

const PrintOnlyContainer: React.FC<Props> = (props: Props) => {
  const { children } = props;
  const classes = useStyles();
  return <Box className={classes.root}>{children}</Box>
}

export default PrintOnlyContainer;

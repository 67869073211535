import { CompanySettingsActions } from '../constants/actions';
import { normalizeResult } from '../services/app/normalizr';
import { companySettingsSchema } from '../types/schemas';
import * as CompanySettingsService from '../services/api/companySettings';
import { CompanySettings } from '../types/CompanySettings';

function requestCompanySettings(companyId: string) {
  return {
    type: CompanySettingsActions.REQUEST_COMPANY_SETTINGS,
    result: companyId,
  };
}

function receiveCompanySettings(companyId, entities) {
  return {
    type: CompanySettingsActions.RECEIVE_COMPANY_SETTINGS,
    companyId,
    entities,
  };
}

function updatingCompanySettings() {
  return {
    type: CompanySettingsActions.SAVING_COMPANY_SETTINGS,
  };
}

function updatedCompanySettings(entities) {
  return {
    type: CompanySettingsActions.SAVED_COMPANY_SETTINGS,
    entities,
  };
}

const normalizeCompanySettingsResult = normalizeResult(companySettingsSchema);

export function getCompanySettings(companyId: string) {
  return (dispatch: Function) => {
    dispatch(requestCompanySettings(companyId));
    return CompanySettingsService.getCompanySettings(companyId)
      .then(normalizeCompanySettingsResult)
      .then((data: any) => {
        dispatch(receiveCompanySettings(companyId, data.entities));
      })
      .catch((error) => dispatch(receiveCompanySettings(companyId, null)));
  };
}

export function updateCompanySettings(companyId: string, companySettings: CompanySettings) {
  return (dispatch: Function) => {
    dispatch(updatingCompanySettings());
    return CompanySettingsService.updateCompanySettings(companyId, companySettings)
      .then(normalizeCompanySettingsResult)
      .then((data: any) => dispatch(updatedCompanySettings(data.entities)));
  };
}

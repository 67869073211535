import { connect, DispatchProp } from 'react-redux';
import React from 'react';
import ModalContent from '../../components/modals/ModalContent';
import TwoFactorLoginForm, { TwoFactorLoginFormData } from '../../components/auth/TwoFactorLoginForm';
import { closeModal } from '../../actions/modals';
import { ModalsConstants } from '../../constants/modals';
import { AuthPaths, homeUrl } from '../../services/app/paths';
import { login } from '../../actions/auth';
import { RouteComponentProps, withRouter } from 'react-router';
import { Error } from '../../components/ui/Error';
import { GlobalState } from '../../types/GlobalState';

interface Props extends OwnProps, PropsFromState, DispatchProp, RouteComponentProps { }

interface OwnProps {
  username: string;
  password: string;
}

type PropsFromState = Pick<GlobalState, "auth" | "users">;

interface State {
  error?: string | null;
}

class TwoFactorAuthenticationModal extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.state = { error: null };
  }

  // eslint-disable-next-line max-lines-per-function
  handleFormSubmit(values: TwoFactorLoginFormData): void {
    const { dispatch, history } = this.props;
    // eslint-disable-next-line
    // @ts-ignore
    dispatch<any>(login(values.username, values.password, values.code))
      .then((user) => {
        dispatch(closeModal(ModalsConstants.TWO_FACTOR_AUTHENTICATION));
        if (user && !user.firstPasswordReset) {
          history.push(homeUrl());
        } else {
          history.push(AuthPaths.changePasswordUrl());
        }
      }).catch((error) => {
        this.setState({ error: error?.status === 400 ? 'Invalid Two Factor Authentication.' : 'Invalid Password.' });
      });
  }

  handleCancel(): void {
    const { dispatch } = this.props;
    dispatch(closeModal(ModalsConstants.TWO_FACTOR_AUTHENTICATION));
  }

  render(): React.ReactElement {
    const { username, password, ...rest } = this.props;
    const { error } = this.state;
    return (
      <ModalContent
        secondaryActionText="Cancel"
        secondaryActionClick={this.handleCancel}
      >
        <p className="modal-message">Enter in Two Factor Code:</p>
        <Error error={error} />
        <TwoFactorLoginForm
          onSubmit={this.handleFormSubmit}
          initialValues={{ username: username, password: password }}
          {...rest}
        />
      </ModalContent>
    );
  }
}

const mapStateToProps = ({ users, auth }: GlobalState): PropsFromState => ({ users, auth });
export default withRouter(connect(mapStateToProps)(TwoFactorAuthenticationModal));

import { Level, version, environment } from "../LoggerService";
import { LoggerInterface } from "./LoggerInterface";
import { LogLevel } from "./LogLevel";
import { v4 as uuidv4 } from "uuid";

export class ConsoleLogger implements LoggerInterface {

  public debug(msg: string, ...data: any[]): void {
    if (this.level() < LogLevel.Debug) {
      return;
    }
    console.debug(msg, ...data);
  }

  public warn(msg: string, ...data: any[]): void {
    if (this.level() < LogLevel.Warn) {
      return;
    }
    console.warn(msg, ...data);
  }

  public error(msg: string, ...data: any[]): void {
    if (this.level() < LogLevel.Error) {
      return;
    }
    console.error(msg, ...data);
  }

  public info(msg: string, ...data: any[]): void {
    if (this.level() < LogLevel.Info) {
      return;
    }
    console.info(msg, ...data);
  }

  public captureException(e: Error, extra: Record<string, any> = {}): string {
    this.error("Exception", { ...extra, version: version(), environment: environment() }, e);
    return uuidv4();
  }

  public captureMessage(msg: string, extra: Record<string, any> = {}): void {
    this.debug(msg, extra);
  }

  public level(): LogLevel {
    return Level;
  }
}

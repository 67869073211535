import React from "react";
import { Grid } from "@roadsync/roadsync-ui";
import { Theme, Box, createStyles, makeStyles, withWidth, WithWidthProps, isWidthDown } from "@material-ui/core";
import NoPrintContainer from "../../ui/visibility/NoPrintContainer";

interface Props extends WithWidthProps {
  children?: React.ReactNode | JSX.Element;
  secondaryBtn?: React.ReactNode | JSX.Element;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.down('xs')]: {
        position: "fixed",
        background: theme.palette.common.white,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: theme.zIndex.appBar,
      },
      '& button': {
        height: "100%",
        width: "100%",
      }
    },
    primaryBtn: {
      [theme.breakpoints.down('xs')]: {
        '& .MuiButtonBase-root': {
          width: "100%",
        }
      }
    }
  }),
);

// eslint-disable-next-line
const InvoicePrepButtonRow: React.FC<Props> = (props: Props): React.ReactElement => {
  const { children, secondaryBtn } = props;
  const classes = useStyles();
  const isMobile = isWidthDown('sm', props.width || "sm");
  if (!children || !secondaryBtn) {
    return <></>;
  }
  return (
    <NoPrintContainer>
      <Box className={classes.root}>
        <Grid container spacing={isMobile ? 0 : 2} wrap="nowrap">
          <Grid item xs md="auto" className={classes.primaryBtn}>
            {children}
          </Grid>
          {secondaryBtn &&
            <Grid item>
              {secondaryBtn}
            </Grid>
          }
        </Grid>
      </Box>
    </NoPrintContainer>
  )
}

export default withWidth()(InvoicePrepButtonRow);

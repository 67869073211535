import React from "react";
import { P } from "@roadsync/roadsync-ui";

interface Props {
  align?: "inherit" | "left" | "center" | "right" | "justify";
  color?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
}

export const NoCheckRefundsText: React.FC<Props> = (props: Props) => {
  const { align, color } = props;
  return (
    <P variant="body1" color={color ? color : 'error'} align={align} gutterBottom>Please note that checks cannot be refunded.</P>
  )
};

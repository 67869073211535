import { Theme } from "@material-ui/core";

const styles = (theme: Theme): {} => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    left: 0,
    right: 0,
    zIndex: theme.zIndex.speedDial,
    pointerEvents: "none",
    "& .MuiFab-root": {
      pointerEvents: "auto",
    }
  }
});

export default styles;

import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { ModalsConstants } from '../../constants/modals';
import ModalContent from '../../components/modals/ModalContent';
import { closeModal } from '../../actions/modals';
import ReactSignature from '../../components/controls/ReactSignature';
import { addPublicSignature, addSignature } from '../../actions/invoices';
import Spinner from '../../components/app/Spinner';
import { showErrorAlert } from '../../actions/alerts';
import { Invoice } from '../../types/Invoice';
import { ThemeProvider } from '@roadsync/roadsync-ui';

interface State {
    spinnerClass?: string;
}

interface OwnProps {
    invoiceId?: string;
    token?: string;
    signatureUploaded: (data: string) => void;
}

interface Props extends DispatchProp, OwnProps { }


class InvoiceAddSignature extends React.Component<Props, State> {

    private signatureBoard;

    constructor(props: Props) {
        super(props);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleModalSubmit = this.handleModalSubmit.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.state = {};
    }

    componentDidMount(): void {
        this.hideSpinner();
    }

    async uploadSigntureByInvoiceId(invoiceId: string, image: string): Promise<void> {
        const { dispatch, signatureUploaded } = this.props;
        const data = await dispatch<any>(addSignature(invoiceId, image));
        signatureUploaded(data.url);
    }

    async uploadSigntureByToken(token: string, image: string): Promise<void> {
        const { dispatch, signatureUploaded } = this.props;
        const data = await dispatch<any>(addPublicSignature(token, image));
        signatureUploaded(data.url);
    }

    async uploadSignatureBatch(invoices: Invoice[], image: string): Promise<void> {
        for (const invoice of invoices) {
            await this.uploadSigntureByToken(invoice.token as string, image);
        }
    }

    async handleUpload(): Promise<void> {
        const { invoiceId, token } = this.props;
        const image = this.signatureBoard.toDataURL();
        if (invoiceId) {
            await this.uploadSigntureByInvoiceId(invoiceId, image);
        }
        if (token) {
            await this.uploadSigntureByToken(token, image);
        }
    }

    async handleModalSubmit(): Promise<void> {
        const { dispatch } = this.props;
        if (this.signatureBoard.isEmpty()) {
            return;
        }
        try {
            this.showSpinner();
            await this.handleUpload();
        } catch (e) {
            dispatch(showErrorAlert((e as any).message));
        }

        this.handleModalClose();
    }

    showSpinner(): void {
        this.setState({ spinnerClass: 'visible' });
    }

    hideSpinner(): void {
        this.setState({ spinnerClass: 'hidden' });
    }

    handleModalClose(): void {
        const { dispatch } = this.props;
        this.hideSpinner();
        dispatch(closeModal(ModalsConstants.SIGNATURE));
    }

    handleClear(): void {
        this.signatureBoard.clear();
    }

    render(): React.ReactElement {
        return (
            <ThemeProvider>
                <ModalContent
                    className="add-signature-modal"
                    primaryActionText="Okay"
                    primaryActionClick={this.handleModalSubmit}
                    secondaryActionText="Cancel"
                    secondaryActionClick={this.handleModalClose}
                >
                    <p className="modal-title">Add signature</p>
                    <ReactSignature ref={(signatureBoard): void => { this.signatureBoard = signatureBoard }} />
                    <Spinner className={this.state.spinnerClass} />
                    <button className="btn-white-small clean-signature-canvas" onClick={this.handleClear}>Clear</button>
                </ModalContent>
            </ThemeProvider>
        );
    }
}

export default connect()(InvoiceAddSignature);

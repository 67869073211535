import React, { useEffect, useState } from 'react';
import { WrappedFieldProps } from 'redux-form';
import DatePicker from 'react-datepicker';
import _ from 'lodash';
import moment from 'moment';
import { TextField } from '@material-ui/core';
import { FormConfig } from '../ui/form/FormConfig';
import useStyles from "./DateInputWithWorkingValidation.css";
import "react-datepicker/dist/react-datepicker.css";

type DateInputWithWorkingValidationCustomProps = {
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  showMonthYearPicker?: boolean;
  errorMessage?: string;
  required?: boolean;
  height42?: boolean;
  minDate?: Date;
}

type Props = DateInputWithWorkingValidationCustomProps & WrappedFieldProps;

// eslint-disable-next-line
const DateInputWithWorkingValidation: React.FC<Props> = (props: Props) => {
  const { input, meta: { error }, label, placeholder, disabled, showMonthYearPicker, errorMessage, required, height42, minDate } = props;
  const [selectedDate, setSelectedDate] = useState(input.value);
  const [touched, setTouched] = useState(false); // meta.touched does not work here
  const backendError = errorMessage?.includes(_.lowerCase(input.name));
  const classes = useStyles();
  // Note: backend expects date in LL format because it stores it as string
  // please see `check_deposits.expiration_date column`
  useEffect(() => input.onChange(selectedDate && moment(selectedDate).format('LL')), [selectedDate, input]);

  return (
    <DatePicker
      key={input.name}
      id={input.name}
      onChange={setSelectedDate}
      onBlur={(): void => setTouched(true)}
      showPopperArrow
      autoComplete="off"
      selected={selectedDate ? moment(selectedDate).toDate() : undefined}
      dateFormat="MMMM, yyyy"
      showMonthYearPicker={showMonthYearPicker}
      minDate={minDate}
      className={`${height42 ? classes?.field42 : ''}`}
      customInput={
        <TextField
          disabled={disabled}
          label={label}
          InputLabelProps={{ required }}
          fullWidth
          required={required}
          placeholder={placeholder}
          error={touched ? error || backendError : undefined}
          helperText={touched ? error || backendError : undefined}
          variant={FormConfig.Variant}
        />
      }
    />
  );
};

export default DateInputWithWorkingValidation;

import { CompanyFeatures } from './endpoints';
import { Feature } from '../../types/Feature';
import { App } from '../../constants/app';
import * as http from './http';
import { Company } from '../../types/Company';

export function updateFeatureById(featureId: string, values: Feature): Promise<any> {
  return http.putJSON(CompanyFeatures.updateFeatureById(featureId), values);
}

export function createFeature(companyId: string, values: Feature): Promise<any> {
  return http.postJSON(CompanyFeatures.createFeature(companyId), values);
}

export function getFeatureByCompanyId(companyId: string, offset = 0, limit = App.PAGE_SIZE): Promise<any> {
  return http.get(CompanyFeatures.getFeaturesByCompanyId(companyId, offset, limit));
}

export function getFeatureById(featureId: string): Promise<any> {
  return http.get(CompanyFeatures.getFeatureById(featureId));
}

export function deleteFeatureById(featureId: string): Promise<any> {
  return http.del(CompanyFeatures.deleteFeatureById(featureId));
}

export function getPublicFeaturesByCompanyId(companyId: string): Promise<any> {
  return http.get(CompanyFeatures.getPublicFeaturesByCompanyId(companyId));
}

export function setWexTermsConditions(companyId: string): Promise<Company> {
  return http.postJSON(CompanyFeatures.getWexTermsConditions(companyId), {});
}

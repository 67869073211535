import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';

interface Props {
  id?: string;
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  isActive?: boolean;
  onClick: () => void;
  clickableClasses?: string[];
  text?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    toggleButton: {
      border: "none",
      margin: "unset",
      padding: "unset",
      width: "100%",
      "&: :hover": {
        cursor: "pointer",
      },
    },
    toggleButtonChildren: {
      width: "100%"
    }
  })
);

const ToggleButton: React.FC<Props> = (props: Props): React.ReactElement => {
  const { id, children, text, className, isActive, onClick, disabled } = props;
  const classes = useStyles();
  const handleClick = (e): void => {

    const targetNotOK = e.target?.classList?.contains('price-wrap')
      || e.target?.classList?.contains('btn-amount')
      || e.target?.classList?.contains('price-inp-wrap');
    if (!disabled && !targetNotOK) {
      onClick();
    }
  }
  return (
    <div
      id={id}
      onClick={handleClick}
      className={`product-toggle-btn ${classes.toggleButton} ${className || ''} ${isActive ? 'active' : ''}`}>
      <div>{children}</div>
      <div>{text}</div>
    </div>
  );
};

export default ToggleButton;

import { CarrierActions } from '../constants/actions';
import { mergeStateAndEntityResult } from '../services/app/normalizr';
import { UniversalCarrier } from '../types/carrier';

export interface UniversalCarriersState {
  data: { [k: string]: UniversalCarrier };
  ui: { loading?: boolean };
  order: string[];
}

export function universalCarriers(
  state: UniversalCarriersState = { data: {}, ui: {}, order: [] },
  action: Record<string, any> = {}
): UniversalCarriersState {
  const nextData = mergeStateAndEntityResult(state, action.entities, 'universalCarriers');
  switch (action.type) {
    case CarrierActions.REQUEST_UNIVERSAL_CARRIER_LIST:
      return { ...state, ui: { loading: true }, };
    case CarrierActions.RECEIVE_UNIVERSAL_CARRIER_LIST:
      return {
        data: nextData, order: action.order, ui: { loading: false },
      };
    case CarrierActions.CLEAN_UNIVERSAL_CARRIER_LIST:
      return { data: {}, order: [], ui: { loading: true }, };
    default:
      return { ...state, data: nextData };
  }
}

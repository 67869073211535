import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { change, submit } from 'redux-form';
import { ModalsConstants } from '../../constants/modals';
import ModalContent from '../../components/modals/ModalContent';
import { closeModal } from '../../actions/modals';
import SetPasswordForm, { SetPasswordFormData } from '../../components/auth/SetPasswordForm';
import { CheckboxState } from '../../constants/common';
import { isDisableEmailsEnabled } from '../../services/app/company';
import { Company, GlobalState } from '../../types';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

interface RouteParams {
  companyId: string;
}

interface OwnProps {
  userId: string;
  setPassword: (newPassword: string, sendNotificationEmail: string, generateRandomPassword: string, firstPasswordReset: string, userId: string) => void;
  text: string;
}

type PropsFromState = Pick<GlobalState, "companies">;

type Props = OwnProps & PropsFromState & RouteComponentProps<RouteParams> & DispatchProp;

interface State {
  isGeneratePassword: string,
};

class SetPassword extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalSubmit = this.handleModalSubmit.bind(this);
    this.handleModalRemove = this.handleModalRemove.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.updateGeneratePassword = this.updateGeneratePassword.bind(this);

    this.state = { isGeneratePassword: '' };
  }

  componentDidMount(): void {
    this.setState({ isGeneratePassword: this.isDisableEmailsEnabled() ? CheckboxState.UNCHECKED.key : CheckboxState.CHECKED.key });
  }

  getCompanyId(): string {
    const { match: { params: { companyId } } } = this.props;
    return companyId;
  }

  getCompany(): Company | undefined {
    const { companies: { data } } = this.props;
    return data?.[this.getCompanyId()];
  }

  isDisableEmailsEnabled(): boolean {
    return isDisableEmailsEnabled(this.getCompany());
  }

  updateGeneratePassword(value) {
    this.setState({ isGeneratePassword: value });

    if (value === CheckboxState.CHECKED.key && !this.isDisableEmailsEnabled()) {
      this.props.dispatch(
        change('setPassword',
          'sendNotificationEmail',
          CheckboxState.CHECKED.key
        )
      );
    }
  }

  handleModalClose() {
    const { dispatch } = this.props;
    return dispatch(closeModal(ModalsConstants.SET_PASSWORD));
  }

  handleModalSubmit() {
    const { dispatch } = this.props;
    return dispatch(submit('setPassword'));
  }

  handleModalRemove() {
    const { dispatch } = this.props;
    return dispatch(closeModal(ModalsConstants.SET_PASSWORD));
  }

  handleFormSubmit(values) {
    const { setPassword, userId } = this.props;

    values.sendNotificationEmail = values.sendNotificationEmail === true ||
      String(values.sendNotificationEmail) === CheckboxState.CHECKED.key;

    values.generateRandomPassword = values.generateRandomPassword === true ||
      String(values.generateRandomPassword) === CheckboxState.CHECKED.key;

    if (values.password && String(values.generateRandomPassword) === CheckboxState.CHECKED.key) {
      delete values.password;
    }

    values.firstPasswordReset = values.firstPasswordReset === true ||
      String(values.firstPasswordReset) === CheckboxState.CHECKED.key;

    setPassword(values.password, values.sendNotificationEmail,
      values.generateRandomPassword, values.firstPasswordReset, userId);
  }

  getInitialValues(): Partial<SetPasswordFormData> {
    return {
      sendNotificationEmail: this.isDisableEmailsEnabled() ? CheckboxState.UNCHECKED.key : CheckboxState.CHECKED.key,
      generateRandomPassword: this.isDisableEmailsEnabled() ? CheckboxState.UNCHECKED.key : CheckboxState.CHECKED.key,
      firstPasswordReset: CheckboxState.CHECKED.key
    };
  }

  render() {
    const { text } = this.props;
    const initialValues = this.getInitialValues();
    const isDisableEmailsEnabled = this.isDisableEmailsEnabled();

    return (
      <ModalContent
        className="add-signature-modal"
        primaryActionText="Save"
        primaryActionClick={this.handleModalSubmit}
        secondaryActionText="Cancel"
        secondaryActionClick={this.handleModalRemove}
      >
        <p className="modal-message">{text}</p>
        <SetPasswordForm
          onSubmit={this.handleFormSubmit}
          initialValues={initialValues}
          isGeneratePassword={this.state.isGeneratePassword}
          updateGeneratePassword={this.updateGeneratePassword}
          isDisableEmailsEnabled={isDisableEmailsEnabled}
        />
      </ModalContent>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({ companies }: GlobalState): PropsFromState => ({ companies });

export default withRouter(connect(mapStateToProps)(SetPassword));

import { PaymentMethods } from "../../constants/invoice";
import React from "react";
import { WithInvoiceProps } from "../ui/WithInvoiceProps";

const InvoiceFinalizeButtonText: React.FC<WithInvoiceProps> = (props: WithInvoiceProps) => {
  const { invoice } = props;
  switch (invoice?.type) {
    case PaymentMethods.REMOTE_CHECKOUT.key:
      return <>Send to Recipient</>;
    case PaymentMethods.CARD.key:
    case PaymentMethods.CHECK.key:
      return <>Confirm &amp; Charge</>;
    case PaymentMethods.CASH.key:
      return <>Complete Invoice</>;
    case PaymentMethods.DIRECT_PAYMENT.key:
      return <>Send to Recipient</>;
    default:
      return <>{invoice?.type ? "Checkout Customer" : "Select Payment Type"}</>
  }
}

export default InvoiceFinalizeButtonText;

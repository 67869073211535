import { FeeActions } from '../constants/actions';
import _ from 'lodash';
import { mergeStateAndEntityResult } from '../services/app/normalizr';
import { Fee } from '../types/fee';

export interface FeesState {
  data: { [feeId: string]: Fee };
  order: string[];
  ui: { loading?: boolean };
}

// eslint-disable-next-line
export function fees(state: FeesState = { data: {}, ui: {}, order: [] }, action: Record<string, any> = {}): FeesState {
  const nextData = mergeStateAndEntityResult(state, action.entities, 'fees');
  switch (action.type) {
    case FeeActions.REQUEST_FEE:
      return _.set({ ...state }, `data.${action.companyId}`, { loading: true });
    case FeeActions.REQUEST_FEE_LIST:
      return { ...state, ui: { loading: true } };
    case FeeActions.RECEIVE_FEE_LIST:
      return {
        ...state,
        data: nextData,
        order: action.order,
        ui: { loading: false },
      };
    case FeeActions.DELETED_FEE:
      return {
        ...state,
        data: _.omit({ ...state.data }, action.feeId),
        order: _.pull(state.order, action.feeId),
      };
    default:
      return { ...state, data: nextData };
  }
}

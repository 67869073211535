import React, { useEffect } from "react";
import { Field, BaseFieldArrayProps } from "redux-form";
import Grid from "@material-ui/core/Grid";
import { RenderTextField } from "../ui/Form";
import { email, required, usPhoneNumber } from "../../services/app/validators";
import { PrimaryButton } from "../ui/Buttons";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { Divider } from "@material-ui/core";
import useStyles from "./AddressBookContactField.css";

interface RenderContactFieldsProps extends BaseFieldArrayProps<any> { }

const RenderContactFields: React.FC<RenderContactFieldsProps> = ({ fields }) => {
    const classes = useStyles();
    useEffect(() => {
        if (fields.length === 0) {
            fields.push({});
        }
    }, [fields]);
    return (
        <div>
            {fields.map((contact, index) => (
                <React.Fragment key={index}>
                    <Grid container spacing={2} key={index}>
                        <Grid item xs={12}>
                            <Field
                                id={`contactName-${index}`}
                                name={`${contact}.name`}
                                label="Contact Name"
                                type="text"
                                component={RenderTextField}
                                validate={required}
                                data-testid={`contactName-${index}`}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                id={`contactNameEmail-${index}`}
                                name={`${contact}.email`}
                                label="Contact Email"
                                type="text"
                                component={RenderTextField}
                                validate={email}
                                data-testid={`contactNameEmail-${index}`}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Field
                                id={`contactNamePhone-${index}`}
                                name={`${contact}.phone`}
                                label="Contact Phone Number"
                                type="text"
                                component={RenderTextField}
                                validate={usPhoneNumber}
                                data-testid={`contactNamePhone-${index}`}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Field
                                name={`${contact}.contactId`}
                                id={`contactId-${index}`}
                                data-testid={`contactId-${index}`}
                                label="Contact ID"
                                type="text"
                                component={RenderTextField}
                            />
                        </Grid>
                        {fields.length !== 1 ? (
                            <Grid item xs={12} className={classes.deleteContactSpacing}>
                                <PrimaryButton
                                    id={`delete-contact-${index}`}
                                    data-testid={`delete-contact-${index}`}
                                    className={classes.deleteContactButton}
                                    startIcon={<DeleteIcon />}
                                    type="button"
                                    onClick={() => fields.remove(index)}
                                >
                                    Delete Contact
                                </PrimaryButton>
                            </Grid>
                        ) : null}
                    </Grid>

                    {index !== fields.length - 1 && <Divider className={classes.divider} />}
                </React.Fragment>
            ))}
            <Grid item xs={12} className={classes.deleteContactSpacing}>
                <PrimaryButton
                    className={classes.addContactButton}
                    data-testid="addContact"
                    id="addContact"
                    startIcon={<AddIcon />}
                    type="button"
                    onClick={() => fields.push({})}
                >
                    Add Contact
                </PrimaryButton>
            </Grid>
        </div>
    );
};

export default RenderContactFields;

import React, { memo } from "react";
import { Box, Container } from "@material-ui/core";
import useStyles from "./TowbookFormContainer.css";

interface Props {
    children?: React.ReactNode;
}

const TowbookFormContainer: React.FC<Props> = (props: Props) => {
    const { children } = props;
    const classes = useStyles();

    return (
        <Container className={classes.container} id="towbook-invoice-page" data-testid="towbook-invoice-page">
            <Box>{children}</Box>
        </Container>
    );
};

export default memo(TowbookFormContainer);
import { FormErrors, SubmissionError } from 'redux-form';
import { normalizePhone, normalizePhoneOrEmail } from "./normalizers";

interface FormError {
  formSubmissionError?: FormErrors;
  message: string;
}

export const handleReduxFormError = (error: FormError): SubmissionError => {
  if (error.formSubmissionError) {
    throw new SubmissionError(error.formSubmissionError);
  }
  throw new SubmissionError({ _error: error?.message || "Something went wrong, please try again later" });
};

export const formPhoneWrapper = (phoneNumberFieldNames, handleSubmit) => {
  return function (values, ...rest) {
    for (const name of phoneNumberFieldNames) {
      if (values[name]) {
        values[name] = normalizePhone(values[name]);
      }
    }
    return handleSubmit(values, ...rest);
  }
}

export const formPhoneOrEmailWrapper = (phoneNumberOrEmailFieldNames, handleSubmit) => {
  return function (values, ...rest) {
    for (const name of phoneNumberOrEmailFieldNames) {
      if (values[name]) {
        values[name] = normalizePhoneOrEmail(values[name]);
      }
    }
    return handleSubmit(values, ...rest);
  }
}

// lodash upperCase has weird behavior, use this instead
export const upperCase = value => {
  return value ? value.toUpperCase() : '';
}

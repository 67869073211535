import { App } from '../constants/app';
import * as CheckAuthorizerService from '../services/api/checkAuthorizer';
import { CheckAuthorizerActions } from '../constants/actions';
import { getUserIfNotInStore } from './users';
import * as Notifier from '../services/app/notifier';
import moment from 'moment-timezone';
import { setTimeout } from 'timers';
import { showErrorAlert, showSuccessAlert } from './alerts';
import { CheckTypes } from '../constants/deposit';
import { ModalsConstants } from '../constants/modals';
import { Deposit } from "../types/Deposit";
import { CheckAuthorizerDeposit } from "../types/CheckAuthorizerDeposit";

function requestProcessingChecks() {
  return {
    type: CheckAuthorizerActions.REQUEST_PROCESSING_CHECKS,
  };
}

function receiveProcessingChecks() {
  return {
    type: CheckAuthorizerActions.RECEIVE_PROCESSING_CHECKS,
  };
}

function requestClaimDeposit(depositId: string) {
  return {
    type: CheckAuthorizerActions.REQUEST_CLAIM_DEPOSIT,
    depositId,
  };
}

function receiveClaimDeposit(deposit: Deposit) {
  return {
    type: CheckAuthorizerActions.RECEIVE_CLAIM_DEPOSIT,
    deposit,
  };
}

function requestApproveDeposit(depositId: string) {
  return {
    type: CheckAuthorizerActions.REQUEST_APPROVE_DEPOSIT,
    depositId,
  };
}

function receiveApproveDeposit(deposit: Deposit) {
  return {
    type: CheckAuthorizerActions.RECEIVE_APPROVE_DEPOSIT,
    deposit,
  };
}

function requestDeclineDeposit(depositId: string) {
  return {
    type: CheckAuthorizerActions.REQUEST_DECLINE_DEPOSIT,
    depositId,
  };
}

function receiveDeclineDeposit(deposit: Deposit) {
  return {
    type: CheckAuthorizerActions.RECEIVE_DECLINE_DEPOSIT,
    deposit,
  };
}

function receiveDeposit(deposit: Deposit) {
  return {
    type: CheckAuthorizerActions.RECEIVE_DEPOSIT,
    deposit,
  };
}

function setBeeped(deposit: CheckAuthorizerDeposit) {
  return {
    type: CheckAuthorizerActions.SET_BEEPED,
    deposit,
  };
}

function getCheckTypeName(deposit): string {
  return deposit.checkType &&
    // eslint-disable-next-line no-mixed-operators
    CheckTypes.getByKey(deposit.checkType) &&
    // eslint-disable-next-line no-mixed-operators
    CheckTypes.getByKey(deposit.checkType).display || deposit.checkType;
}

function muteSound(state): boolean {
  return isCheckAuthorizerOpen(state);
}

export function isCheckAuthorizerOpen(state): boolean {
  return state && Array.isArray(state) && state.some(modal => modal.name === ModalsConstants.CHECK_AUTHORIZER_CALLER);
}

// eslint-disable-next-line
function addDeposit(deposit: Deposit, forceDt?: moment.Moment) {
  // eslint-disable-next-line
  return (dispatch: Function, getState: Function) => {
    // Preload claimed username for received deposit
    if (deposit.claimedById) {
      dispatch(getUserIfNotInStore(deposit.claimedById));
    }

    const { checkAuthorizer: { data }, modals } = getState();

    let info = data[deposit.id] as CheckAuthorizerDeposit;
    if (info) { // Deposit already in Redux store
      if (info.claimedById !== deposit.claimedById && deposit.claimedById == null) {
        Notifier.showNotification(
          NOTIFICATION_TITLE,
          `Please call in for ${getCheckTypeName(deposit)} deposit`,
          false,
          muteSound(modals)
        );
      }

      dispatch(receiveDeposit(deposit));
    } else { // Newly received deposit
      info = {
        ...deposit,
        claimedByName: null,
        beeped: false,
        timestamp: forceDt || moment(),
      };

      if (!forceDt) {
        // New deposit received via web socket
        setTimeout(() => {
          // Notify after 5 seconds that there is a new non-claimed invoice,
          // but ensure that it's still not claimed and exists in redux store
          const stateDeposit = getState().checkAuthorizer.data[deposit.id];
          if (stateDeposit && !stateDeposit.claimedById) {
            Notifier.showNotification(
              NOTIFICATION_TITLE,
              `There's a new ${getCheckTypeName(deposit)} deposit`,
              false,
              muteSound(modals)
            );
          }
        }, NOTIFICATION_DELAY);
      }

      dispatch(receiveDeposit(info));
    }
  };
}

const NOTIFICATION_DELAY = 5000;
const NOTIFICATION_TITLE = 'RoadSync Check Authorizer';

export function notifyDepositWaitingUnaccepted(deposit: CheckAuthorizerDeposit) {
  return (dispatch: Function, getState: Function) => {
    const { modals } = getState();

    Notifier.showNotification(
      NOTIFICATION_TITLE,
      'There is a check WAITING for more than 2 minutes unaccepted',
      true,
      muteSound(modals));
    dispatch(setBeeped(deposit));
  };
}

export function getProcessingChecksList(offset = 0, limit = App.PAGE_SIZE) {
  return (dispatch: Function) => {
    dispatch(requestProcessingChecks());

    return CheckAuthorizerService.getProcessingChecksList(offset, limit)
      .then((data: any) => data.map(deposit => dispatch(addDeposit(deposit, moment(deposit.updatedAt)))))
      .then(() => dispatch(receiveProcessingChecks()));
  };
}

export function receiveCheckDepositPush(deposit) {
  return (dispatch: Function) => dispatch(addDeposit(deposit));
}

export function claimDeposit(depositId: string) {
  return (dispatch: Function) => {
    dispatch(requestClaimDeposit(depositId));
    return CheckAuthorizerService.claimDeposit(depositId)
      .then((deposit: any) => dispatch(receiveClaimDeposit(deposit)));
  };
}

export function approveDeposit(depositId: string, authNumber: string) {
  return (dispatch: Function) => {
    dispatch(requestApproveDeposit(depositId));

    return CheckAuthorizerService.approveDeposit(depositId, authNumber)
      .then(deposit => {
        dispatch(receiveApproveDeposit(deposit));
        dispatch(showSuccessAlert(
          `Deposit ${getCheckTypeName(deposit)} ${deposit.checkNumber} successfully approved`));
      });
  };
}

export function declineDeposit(depositId: string, error: string) {
  return (dispatch: Function) => {
    dispatch(requestDeclineDeposit(depositId));

    return CheckAuthorizerService.declineDeposit(depositId, error)
      .then(deposit => {
        dispatch(receiveDeclineDeposit(deposit));
        dispatch(showErrorAlert(
          `Deposit ${getCheckTypeName(deposit)} ${deposit.checkNumber} successfully rejected`));
      });
  };
}

export function restartMattDamon() {
  return (dispatch: Function) => {
    return CheckAuthorizerService.restartMattDamon()
      .then(() => {
        dispatch(showSuccessAlert('Matt Damon restarted! Please allow a few moments for him to come back online.'));
      });
  };
}

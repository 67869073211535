
import React from 'react';
import { Grid, StyledComponentProps, withStyles } from '@material-ui/core';
import { Alert as MUIAlert } from '@material-ui/lab';
import { LightingIcon } from '../app/LightingIcon';
import styles from "./CustomStyledYellowAlert.css";

type Props = StyledComponentProps & { className?: string, icon?: React.ReactNode, children?: React.ReactNode, action?: React.ReactNode };

const CustomStyledYellowAlert: React.FC<Props> = (props: Props) => {
  const { classes, className, icon, children, action } = props;
  return <Grid item className={`${classes?.customStyledYellowAlertWrapper} ${className}`}>
    <MUIAlert
      aria-atomic="true"
      color={'warning'}
      icon={icon ? icon : <LightingIcon />}
      action={action}
    >
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs>{children}</Grid>
      </Grid>
    </MUIAlert>
  </Grid>;
};

export default withStyles(styles)(CustomStyledYellowAlert);

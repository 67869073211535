export class ReportRecipientEndpoints {
  static getReportRecipientById = (reportRecipientId: string): string => (
    `/api/v1/reports-recipients/${reportRecipientId}`
  )
  static getReportRecipientList = (offset: number, limit: number, filter?: string): string => (
    `/api/v1/reports-recipients?offset=${offset}&limit=${limit}${filter ? '&search=' + filter : ''}`
  )

  static createReportRecipient = (): string => `/api/v1/reports-recipients`;
  static updateReportRecipient = (reportRecipientId: string): string => `/api/v1/reports-recipients/${reportRecipientId}`;
  static deleteReportRecipient = (reportRecipientId: string): string => `/api/v1/reports-recipients/${reportRecipientId}`;
}

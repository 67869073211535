import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect, DispatchProp } from 'react-redux';
import { attachPdfToInvoice, removePdfFromInvoice, getFileUrl } from '../../../../actions/files';
import { PreparationSteps } from '../../../../constants/invoice';
import _ from 'lodash';
import { isAttachInvoicePdfEnabled } from '../../../../services/app/company';
import { Attachment } from '../../../../constants/attachment';
import { InvoiceFile } from '../../../../types/InvoiceFile';
import { Invoice } from '../../../../types/Invoice';
import { GlobalState } from '../../../../types/GlobalState';
import AttachFileComponent from "./AttachFileComponent";
import { showErrorAlert } from '../../../../actions/alerts';

interface OwnProps {
    readOnly?: boolean;
    onPrevStep?: () => void;
    onCompletedStep?: (nextStep: PreparationSteps) => void;
    files: InvoiceFileUpdated[];
}

interface InvoiceFileUpdated extends InvoiceFile {
    fileName: string;
    invoiceFileId: string;
}

type PropsFromState = Pick<GlobalState, "invoices" | "deposits" | "auth" | "companies"> & {
    files: InvoiceFileUpdated[];
}

interface State {
    existingFiles: InvoiceFileUpdated[];
    fileAttached?: boolean;
    isLoading?: boolean;
    isDropError?: boolean;
}

interface RouteParams {
    invoiceId: string;
}

interface Props extends PropsFromState, OwnProps, DispatchProp, RouteComponentProps<RouteParams> { }

class AttachFile extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.handleAttach = this.handleAttach.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onDropRejected = this.onDropRejected.bind(this);
        this.getAttachmentUrl = this.getAttachmentUrl.bind(this);
        this.handleRemoveExistingAttachment = this.handleRemoveExistingAttachment.bind(this);
        this.state = { existingFiles: [] };
    }

    async getAttachmentUrl(fileId: string): Promise<string> {
        try {
            const r = await getFileUrl(fileId);
            if (r?.url && r.url.length > 0) {
                return r.url;
            }
        } catch (e) {
            // Do nothing.
        }
        return '';
    }

    openAttachment(attachmentUrl: string): void {
        window.open(attachmentUrl, "_blank");
    }

    componentDidMount(): void {
        const { onPrevStep, companies, auth: { me } } = this.props;
        const company = _.get(companies.data, me.companyId.toString());
        if (!isAttachInvoicePdfEnabled(company) && onPrevStep) {
            onPrevStep();
        }
    }

    async onDrop(files?: any[]): Promise<void> {
        const { dispatch } = this.props;
        this.setState({ isLoading: true, isDropError: false, });
        for (const file of files || []) {
            try {
                await this.handleAttach(file);
            } catch (e) {
                dispatch(showErrorAlert((e as any)?.message));
            }
        }
        this.setState({ isLoading: false });
    }

    onDropRejected(): void {
        this.setState({ isDropError: true, isLoading: false });
    }

    getInvoiceId(): string {
        return this.props.match.params.invoiceId;
    }

    handleRemoveExistingAttachment(id: string): void {
        const { dispatch } = this.props;
        const { existingFiles } = this.state;
        const index = existingFiles.findIndex((item) => item.invoiceFileId === id);
        const arrayOfFiles = existingFiles;
        arrayOfFiles.splice(index, 1);
        dispatch<any>(removePdfFromInvoice(this.getInvoice().id, id))
            .then(() => this.setState({ existingFiles: arrayOfFiles }));
    }

    async handleAttach(file): Promise<void> {
        const { dispatch } = this.props;
        await dispatch<any>(attachPdfToInvoice(this.getInvoice().id, file, file.name))
            .then((data) => {
                if (data && data.entities) {
                    this.setState({ existingFiles: [...this.state.existingFiles, data.entities] });
                }
            });
    }

    getInvoice(): Invoice {
        const { invoices } = this.props;
        const invoiceId = this.getInvoiceId();
        return invoices?.data?.[invoiceId] as Invoice;
    }

    // eslint-disable-next-line
    render(): React.ReactElement {
        const { files, readOnly } = this.props;
        const normalizedExistingFiles = files
            .map((f, index) => {
                return { id: f.invoiceFileId, fileId: f.fileId, name: f.fileName || `Attachment (${index + 1})`, url: f.url };
            });

        const showDropZone = !readOnly;

        return (
            <AttachFileComponent
                onDrop={this.onDrop}
                onDropRejected={this.onDropRejected}
                readOnly={readOnly}
                showDropZone={showDropZone}
                files={normalizedExistingFiles}
                getAttachmentUrl={this.getAttachmentUrl}
                openAttachment={this.openAttachment}
                handleRemoveExistingAttachment={this.handleRemoveExistingAttachment}
            />
        );
    }
}

const mapStateToProps: (state: GlobalState, params: RouteComponentProps<RouteParams>) => PropsFromState =
    // eslint-disable-next-line max-lines-per-function
    ({ auth, companies, invoices, deposits, files }, { match: { params: { invoiceId } } }: RouteComponentProps<RouteParams>) => {
        const invoiceFiles = _.reduce(
            files.data,
            (result, value, key) => {
                if (value.invoiceId === invoiceId && value.type !== Attachment.SIGNATURE.key) {
                    result[key] = value;
                }
                return result;
            },
            {}
        );
        return { invoices, deposits, auth, companies, files: _.values(invoiceFiles) };
    };

export default withRouter(connect(mapStateToProps)(AttachFile));


import React from "react";
import { Tooltip } from '@material-ui/core';
import { H4, Container, Grid, AppBar, Toolbar, Box } from '@roadsync/roadsync-ui';
import NoPrintContainer from "./visibility/NoPrintContainer";
import styles from './AppBarContainer.css';
import { IconButton } from "./Buttons";
import { ArrowBackIcon, CloseIcon } from "./Icons";

interface Props {
  children?: React.ReactNode;
  onBack?: () => void;
  onExit?: () => void;
  title?: string;
}

// eslint-disable-next-line max-lines-per-function
const AppBarContainer: React.FC<Props> = (props: Props): React.ReactElement => {
  const { children, onBack, onExit, title } = props;
  const classes = styles();
  return (
    <Box className={classes.root}>
      <AppBar color="secondary">
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center" wrap="nowrap">
            <Grid item xs>
              {title &&
                <NoPrintContainer>
                  <H4 align="center">{title}</H4>
                </NoPrintContainer>
              }
            </Grid>
          </Grid>
          {onExit &&
            <Box className={classes.closeBtn}>
              <Tooltip title="Close">
                <span>
                  <IconButton onClick={onExit} color="secondary" id="app-bar-exit-btn">
                    <CloseIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          }
          {onBack &&
            <Box className={classes.backBtn}>
              <Tooltip title="Back">
                <span>
                  <IconButton onClick={onBack} id="app-bar-back-btn">
                    <ArrowBackIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Box>
          }
        </Toolbar>
      </AppBar>

      {children &&
        <Container maxWidth="sm">
          {children}
        </Container>
      }
    </Box>
  );
};

export default AppBarContainer;

import { Grid } from "@material-ui/core";
import React from "react";
import ToggleButton from '../../components/app/ToggleButton';
import { getMIUIGridScale, ProductGridType } from "../../types/UserInterface";
import { ProductSelectorProps } from "./ProductSelector";

// eslint-disable-next-line max-lines-per-function
const ProductTypeMultipleLineItemsSelector: React.FC<ProductSelectorProps> = (props: ProductSelectorProps) => {
  const { product, selected, lineItemsQty, lineItemsSum, onCustomize, gridType } = props;
  const itemClass = "pos-item pos-item-v2";
  const isList = gridType === ProductGridType.List;
  const isSmallGrid = gridType === ProductGridType.SmallGrid;
  return (
    <Grid item {...getMIUIGridScale(gridType)} className={`add-line-items ${itemClass} ${selected ? 'is-active' : ''}`}>
      <ToggleButton isActive={selected} onClick={(): void => onCustomize && onCustomize(product.id)}>
        <div className={`newPos-wrapper newPos-wrapper-v2 ${isList ? "newPos-wrapper__list" : ""}`}>
          <span className={`title-wrap title-wrap-v2 ${isList ? "title-wrap__list" : ""} ${isSmallGrid ? "title-wrap__small-grid" : ""}`}>
            Add Line Items <br />
            {selected &&
              <div className="title-wrap-text">
                {lineItemsQty && <div>Items: {lineItemsQty} </div>}
                {lineItemsSum && <div>Total: ${lineItemsSum}</div>}
              </div>}
            <span className="icon-close" />
          </span>
        </div>
      </ToggleButton>
    </Grid>
  );
}

export default ProductTypeMultipleLineItemsSelector;

import _ from 'lodash';

export interface EventType {
  key: string;
  display: string;
  method?: string;
  isPublic?: boolean;
  displaySign?: string;
}

export class EventTypes {
  static readonly CHECK_PROCESSING: EventType = {
    key: 'checkprocessing',
    display: 'Check processing',
    method: 'Check',
  };

  static readonly CARD_PROCESSING: EventType = {
    key: 'cardprocessing',
    display: 'Card processing',
    method: 'Credit Card',
  };

  static readonly PAY_CODE_PROCESSING: EventType = {
    key: 'paycodeprocessing',
    display: 'Paycode processing',
    method: 'Paycode',
  };

  static readonly INSTANT_PAYOUT_PROCESSING: EventType = {
    key: 'instantpayout',
    display: 'Express Deposit Processing',
    isPublic: true,
    method: 'Payout',
  };

  static readonly PAY_CODE_GENERATION: EventType = {
    key: 'paycodegeneration',
    display: 'Paycode generation',
  };

  static readonly CASH_PROCESSING: EventType = {
    key: 'cashprocessing',
    display: 'Cash processing',
    method: 'Cash',
  };

  static readonly ACH_PROCESSING: EventType = {
    key: 'achprocessing',
    display: 'Direct Debit / ACH processing',
    method: 'Direct Debit / ACH',
  };

  static readonly CHECK_CONVENIENCE: EventType = {
    key: 'convcheck',
    display: 'Check convenience',
    isPublic: true,
    method: 'Check',
  };

  static readonly CASH_CONVENIENCE: EventType = {
    key: 'convcash',
    display: 'Cash convenience',
    isPublic: true,
    method: 'Cash',
  };

  static readonly CARD_CONVENIENCE: EventType = {
    key: 'convcard',
    display: 'Card convenience',
    isPublic: true,
    method: 'Credit Card',
  };

  static readonly PAY_CODE_CONVENIENCE: EventType = {
    key: 'convpaycode',
    display: 'Paycode convenience',
    isPublic: true,
    method: 'Paycode',
  };

  static readonly ACH_CONVENIENCE: EventType = {
    key: 'convach',
    display: 'Direct Debit / ACH convenience',
    isPublic: true,
    method: 'Direct Debit / ACH',
  };

  static readonly FUEL_CARD_PROCESSING: EventType = {
    key: 'fuelcardprocessing',
    display: 'Fuel Card processing',
    isPublic: true,
    method: 'Fuel Card',
  };

  static readonly FUEL_CARD_CONVENIENCE: EventType = {
    key: 'convfuelcard',
    display: 'Fuel Card convenience',
    isPublic: true,
    method: 'Fuel Card',
  };

  private static readonly LOOKUP = {
    'checkprocessing': EventTypes.CHECK_PROCESSING,
    'cardprocessing': EventTypes.CARD_PROCESSING,
    'paycodeprocessing': EventTypes.PAY_CODE_PROCESSING,
    'paycodegeneration': EventTypes.PAY_CODE_GENERATION,
    'cashprocessing': EventTypes.CASH_PROCESSING,
    // Public
    'convcheck': EventTypes.CHECK_CONVENIENCE,
    'convcash': EventTypes.CASH_CONVENIENCE,
    'convcard': EventTypes.CARD_CONVENIENCE,
    'convpaycode': EventTypes.PAY_CODE_CONVENIENCE,
    'instantpayout': EventTypes.INSTANT_PAYOUT_PROCESSING,
    'achprocessing': EventTypes.ACH_PROCESSING,
    'convach': EventTypes.ACH_CONVENIENCE,
    'fuelcardprocessing': EventTypes.FUEL_CARD_PROCESSING,
    'convfuelcard': EventTypes.FUEL_CARD_CONVENIENCE,
  };

  static getTypes(): EventType[] {
    return _.values(EventTypes.LOOKUP);
  }

  static getPublicTypes(): EventType[] {
    return _.filter(_.values(EventTypes.LOOKUP), 'isPublic');
  }

  static getByKey(key: string): EventType {
    return EventTypes.LOOKUP[key];
  }
}

export class FeeApplyType {
  static readonly PERCENT: EventType = {
    key: 'percent',
    display: 'Percent',
    displaySign: '%',
  };

  static readonly FIXED: EventType = {
    key: 'fixed',
    display: 'Fixed',
    displaySign: '$',
  };

  private static readonly LOOKUP = {
    'percent': FeeApplyType.PERCENT,
    'fixed': FeeApplyType.FIXED,
  };

  static getTypes(): EventType[] {
    return _.values(FeeApplyType.LOOKUP);
  }

  static getByKey(key: string): EventType {
    return FeeApplyType.LOOKUP[key];
  }
}

import React from 'react';
import { connect } from 'react-redux';
import { Company } from '../../../types/Company';
import { FeatureType } from '../../../constants/feature';
import { isFeatureEnabled } from '../../../services/app/company';
import { AuthState } from '../../../reducers/auth';

interface Props {
  children: React.ReactNode;
  whenDisabled?: React.ReactNode;
  company?: Company;
  feature: FeatureType;
}

interface State {
  auth: AuthState;
  companies: {
    data: { [k: string]: Company };
  };
}

export class IfFeatureEnabled extends React.Component<Props, State> {
  render(): React.ReactNode {
    const { company, feature, children, whenDisabled } = this.props;
    return isFeatureEnabled(feature, company) ? children : (whenDisabled || null);
  }
}

export const mapStateToProps = (state: State): { company?: Company } => {
  const companyId = state.auth?.me?.companyId;
  return {
    company: companyId ? state.companies.data[companyId] : undefined
  }
}

export default connect(mapStateToProps)(IfFeatureEnabled);

import React from 'react';

export class FileUploader extends React.Component<any, any> {
  render() {
    const { label, disabled, className, inputClassName, input, meta: { touched, error }, acceptType } = this.props;
    return (
      <div className={`inp-row ${className || ''} ${touched && error ? 'field-validation-error' : ''}`}>
        {label && <label>{label}</label>}
        <div className="file-upload-json">
          <span className="upload-button"> Upload </span>
          <span className="upload-placeholder">{input.value.name || 'Click to upload file'} </span>
          <input
            disabled={disabled}
            type="file"
            name="file"
            accept={'*' || acceptType}
            className={`${inputClassName || ''}`}
            onChange={e => e.target && e.target.files ? input.onChange(e.target.files[0]) : {}}
          />
          {touched && error && <span className="field-error-message">{error}</span>}
        </div>
      </div>
    );
  }
}

export default FileUploader;

import React from 'react';
import styles from './SigninHelp.module.css';

export const SigninHelp: React.FC<{}> = (): React.ReactElement => {
  return (
    <div className={styles.root}>
      <h3 className={styles.title}>
        Need help and can't sign in?
      </h3>
      <p className={styles.text}>
        Email us at <a href='mailto:support@roadsync.com' className={styles.link}>support@roadsync.com</a>
        &nbsp;and we'll assist you shortly.
      </p>
    </div>
  );
}

export default SigninHelp;

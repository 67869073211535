import { AuthEndpoints } from './endpoints';
import { normalFetch, parseResponse } from './utils';
import { User } from "../../types/User";
import * as HTTP from "./http";
import * as storage from '../app/storage';
import { PayerEntity } from '../../types/PayerEntity';
import { CognitoTokens } from '../../types/CognitoTokens';
import { Auth } from 'aws-amplify';

export async function login(username: string, password: string, code?: string): Promise<User> {
    const r = await normalFetch(AuthEndpoints.LOGIN, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
            username: username.trim(),
            password: password.trim(),
            code,
        }),
    });
    storage.setItem('authToken', r.headers.get('Authorization') || '');
    return parseResponse(r);
}

export async function logout(): Promise<void> {
    try {
        await HTTP.postJSON(AuthEndpoints.LOGOUT);

        try { storage.clear(); } catch(_) {}

        // clear localStorage
        try { localStorage.clear(); } catch (_) {}

        // clear sessionStorage
        try { sessionStorage.clear(); } catch (_) {}

        // clear local, non httpsOnly cookies
        try {
            const cookies: string = document.cookie;

            for (let cookie of cookies.split(";")) {
                cookie = cookie.trim();
                const index: number = cookie.indexOf("=");
                const name: string = index > -1 ? cookie.substring(0, index) : cookie;
                document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
            }
        } catch (_) {}

        // unregister all service workers
        try {
            const registrations = await navigator.serviceWorker.getRegistrations();
            for(let registration of registrations) {
                registration.unregister();
            } 
        } catch (_) {}
    } catch (e) {
        // Ignore
    }

    try {
        const session = await Auth.currentSession();
        if (session.isValid()) {
            await Auth.signOut();
        }
    } catch (e) {
        // Ignore
    }
}

export function GetCognitoAccessToken(refreshToken: string, username: string): Promise<CognitoTokens> {
    return HTTP.postJSON(AuthEndpoints.GET_COGNITO_ACCESS_TOKEN, { refreshToken, username });
}
export function authCheck(): Promise<User> {
    return HTTP.get(AuthEndpoints.ME);
}

export function forgotPassword(username: string): Promise<{}> {
    return HTTP.postJSON(AuthEndpoints.FORGOT, { username });
}

export function resetPassword(password: string, code: string): Promise<{}> {
    return HTTP.postJSON(AuthEndpoints.RESET, { password, code });
}

export function changePassword(newPassword: string, oldPassword: string): Promise<User> {
    return HTTP.postJSON(AuthEndpoints.CHANGE, { newPassword, oldPassword });
}

export function impersonateLogin(userId: string): Promise<any> {
    return HTTP.postJSON(AuthEndpoints.IMPERSONATE_LOGIN, { userId });
}

export function impersonateReset(): Promise<any> {
    return HTTP.postJSON(AuthEndpoints.IMPERSONATE_RESET);
}

// eslint-disable-next-line max-params
export function setPassword(
    newPassword: string,
    sendNotificationEmail: string,
    generateRandomPassword: string,
    firstPasswordReset: string,
    userId: string
): Promise<{}> {
    return HTTP.postJSON(AuthEndpoints.SET_PASSWORD, {
        newPassword,
        sendNotificationEmail,
        generateRandomPassword,
        firstPasswordReset,
        userId,
    });
}

// eslint-disable-next-line max-params
export function signup(firstName: string, lastName: string, phone: string, interests: any): Promise<{}> {
    return HTTP.postJSON(AuthEndpoints.SIGNUP, {
        firstName,
        lastName,
        phone,
        interests,
    });
}

export const publicSignup = (r: PayerEntity): Promise<User> => HTTP.postJSON(AuthEndpoints.PUBLICSIGNUP, r);

export async function loginByToken(token: string): Promise<User> {
    const r = await normalFetch(AuthEndpoints.LOGIN_TOKEN(token), {
        method: 'POST',
        credentials: 'include',
    });
    storage.setItem('authToken', r.headers.get('Authorization') || '');
    return parseResponse(r);
}

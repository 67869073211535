import React, { useState } from 'react';
import IfGuest from '../../visibility/IfGuest';
import NotFound from '../../../notFound/NotFound';
import { default as LoginForm, FormData } from './LoginForm';
import { connect } from 'react-redux';
import { login } from '../../../../actions/auth';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

interface Props {
  dispatch: Function;
}

// eslint-disable-next-line max-lines-per-function
const UnauthorizedContainer: React.FC<Props> = (props: Props): React.ReactElement => {

  const [error, setError] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const { dispatch } = props;
  const handleSubmit = (form: FormData): void => {
    dispatch(login(form.username as string, form.password as string))
      .catch(() => {
        setShowAlert(true);
        setError('Incorrect username or password');
      });
  }

  return (
    <IfGuest
      otherwise={<NotFound />}>
      <Snackbar open={showAlert} autoHideDuration={6000} onClose={(): void => setShowAlert(false)}>
        <Alert elevation={6} variant="filled" onClose={(): void => setShowAlert(false)} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <LoginForm onSubmit={handleSubmit} />
    </IfGuest>
  )
};

export default connect()(UnauthorizedContainer);

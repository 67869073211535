import { Box, CircularProgress } from "@material-ui/core";
import React from "react";
import errorIcon from "../../assets/img/icons/cardErrorIcon.svg";
import SuccessOutlined from "../ui/SuccessOutlined";
import useStyles from "./AddressBookConfirmationModal.css";

interface Props {
    isLoading: boolean;
    error: boolean;
}

export const AddressBookConfirmationModal: React.FC<Props> = (props) => {
    const classes = useStyles();
    const { isLoading, error } = props;
    return (
        <>
            {isLoading && (
                <>
                    <CircularProgress style={{ marginTop: "3rem" }} size={150} />
                    <Box className={classes.addressBookConfirmationModal}> Saving Payer</Box>
                </>
            )}
            {!isLoading && !error && (
                <>
                    <SuccessOutlined id={`address-book-confirmation-success-icon`} />
                    <Box className={classes.addressBookConfirmationModal}>Payer Created Successfully</Box>
                </>
            )}
            {!isLoading && error && (
                <>
                    <img src={errorIcon} alt="Error Icon" style={{ marginTop: "4rem" }} />
                    <Box className={classes.addressBookConfirmationModal}>There was an error creating the Payer</Box>
                </>
            )}
        </>
    );
};

import { CheckStatusesActions } from '../constants/actions';
import { mergeStateAndEntityResult } from '../services/app/normalizr';
import _ from 'lodash';
import { CheckStatus } from '../types/CheckStatus';
import { Check } from "../types/Check";

export interface ChecksState {
  data: { [checkId: string]: Check };
  ui: { loading?: boolean };
  order?: string[];
  availableChecks?: CheckStatus[];
}

export function checks(state: ChecksState = { data: {}, ui: {} }, action: Record<string, any> = {}): ChecksState {
  const nextData = mergeStateAndEntityResult(state, action.entities, 'checks');
  switch (action.type) {
    case CheckStatusesActions.REQUEST_CHECK_DEPOSITS_LIST:
    case CheckStatusesActions.REQUEST_CHECK_LIST:
      return { ...state, ui: { loading: true } };
    case CheckStatusesActions.RECEIVE_CHECK_DEPOSITS_LIST:
    case CheckStatusesActions.RECEIVE_CHECK_LIST:
      return { ...state, data: nextData, order: action.order, ui: { loading: false } };
    case CheckStatusesActions.REQUEST_CHECK_INFO:
      return _.set({ ...state }, `data.${action.checkId}`, { loading: true });
    case CheckStatusesActions.REQUEST_AVAILABLE_CHECKS:
      return { ...state };
    case CheckStatusesActions.RECEIVE_AVAILABLE_CHECKS:
      return { ...state, availableChecks: action.entities };
    case CheckStatusesActions.DESTROY_LIST:
      return { data: {}, ui: { loading: true } };
    default:
      return { ...state, data: nextData };
  }
}

export class ProductEndpoints {
  static getProductById = (productId: string): string => (
    `/api/v1/products/${productId}`
  )
  // eslint-disable-next-line max-params
  static getProductList = (locationId: string, offset: number, limit: number, type?: string): string => {
    const typeSuffix = type ? `&producttypes=${type}` : '';
    return `/api/v1/locations/${locationId}/products?offset=${offset}&limit=${limit}${typeSuffix}`;
  }

  static getProductListWithoutTaxes = (locationId: string, offset: number, limit: number): string => (
    `/api/v1/locations/${locationId}/products?offset=${offset}&limit=${limit}&excludeproducttypes=tax`
  )
  static createProduct = (locationId: string): string => `/api/v1/locations/${locationId}/products`;
  static updateProduct = (productId: string): string => `/api/v1/products/${productId}`;
  static addImage = (productId: string, imageName: string): string => `/api/v1/products/${productId}/image?imagename=${imageName}`;
  static deleteProduct = (productId: string): string => `/api/v1/products/${productId}`;
  static retrieveFile = (fileId: string): string => `/api/v1/file/${fileId}`;
  static updateProductWeight = (locationId: string): string => `/api/v1/locations/${locationId}/products/order`;
}

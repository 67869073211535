
export class AuthPaths {
  static loginUrl = (redirect?: string): string => `/login${redirect ? '?redirect=' + redirect : ''}`;
  static forgotPasswordUrl = (redirect?: string): string => `/forgot${redirect ? '?redirect=' + redirect : ''}`;
  static resetPasswordUrl = (code: string): string => `/reset/${code}`;
  static changePasswordUrl = (): string => `/change`;
  static selectLocationUrl = (redirect: string): string => `/select-location${redirect}`;
  static selectDepartmentShiftUrl = (): string => '/select-department-shift';
  static twoFactorAuth = (): string => '/tfa';
  static enableTwoFactorAuth = (): string => '/tfa/new';
  static disableTwoFactorAuth = (): string => '/tfa/reset';
  static payerSignupUrl = (): string => `/signup/payer`;
  static payerLoginUrl = (): string => `/signin/payer`;
  static verifyUserUrl = (userId: string): string => `/verification/${userId}`;
  static rsEmployeeMobileRedirect = (): string => `/mobile/redirect`;
  static marketingSignupURL = (): string => 'signup/marketing'
}

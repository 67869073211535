import React from "react";
import ToggleButton from '../../components/app/ToggleButton';
import { ProductSelectorProps, displayProductQtyFieldBasedOnProductUnitValue } from "./ProductSelector";
import { Grid } from "@material-ui/core";
import { getMIUIGridScale, ProductGridType } from "../../types/UserInterface";
import { formatDollar } from "../../services/app/formats";
import { ProductInputLabel } from "./ProductInputLabel";
import { ProductUnit } from "../../constants/product";

class ProductTypeItemSelector extends React.Component<ProductSelectorProps> {

  constructor(props: ProductSelectorProps) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleChange(value: string | number | undefined): void {
    const { onChange, product } = this.props;
    onChange(product.id, value);
  }

  handleToggle(): void {
    const { onToggle, product } = this.props;
    onToggle(product.id);
  }

  render(): React.ReactElement {
    const { id, product, selected, qty, gridType } = this.props;
    const isSmallGrid = gridType === ProductGridType.SmallGrid;
    const isList = gridType === ProductGridType.List;
    const displayLabel = (product.unit === ProductUnit.WHOLE.key || product.unit === ProductUnit.PARTIAL.key);

    return <Grid key={`key-en-${id}`} item id={`feature-enabled-${id}`} {...getMIUIGridScale(gridType)} className={`pos-item pos-item-v2 ${selected ? 'is-active' : ''}`}>
      <ToggleButton isActive={selected} onClick={this.handleToggle}>
        <div className={`newPos-wrapper newPos-wrapper-v2 ${isList ? "newPos-wrapper__list" : ""}`}>
          <span className={`title-wrap title-wrap-v2 ${isSmallGrid ? "title-wrap__small-grid" : ""} ${isList ? "title-wrap__list" : ""}`}>
            {product.name}
          </span>
          <span className={`price-wrap price-wrap-v2 ${isList ? "price-wrap__list" : ""}`}>
            <span className={`price price-v2 ${isList ? "price__list" : ""}`}>
              {formatDollar(product.cost)} <span className="mobile-only-pos"> / Unit</span>
            </span>
          </span>
          {selected && <>
            {displayLabel &&
              <ProductInputLabel>Quantity</ProductInputLabel>
            }
            <span className="price-inp-wrap price-inp-wrap-amount amount_wrap">
              {displayProductQtyFieldBasedOnProductUnitValue(product, this.handleChange, id, qty)}
            </span>
          </>}
        </div>
      </ToggleButton>
    </Grid>
  }
}

export default ProductTypeItemSelector;

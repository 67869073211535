import { DepositActions } from '../constants/actions';
import _ from 'lodash';
import { Deposit } from '../types/Deposit';

export interface DepositsState {
  data: { [id: string]: Deposit };
  ui: {
    loading?: boolean;
    progress?: { selectedType?: string };
  };
}

interface DepositsAction {
  type: string;
  selectedType?: string;
  invoiceId?: string;
  deposit?: Deposit;
}

export function deposits(state: DepositsState = { data: {}, ui: {} }, action: DepositsAction = { type: "unknown" }): DepositsState {
  const nextState = { ...state };
  switch (action.type) {
    case DepositActions.UNSAVED_PROGRESS:
      return { ...state, ui: { ...state.ui, progress: { selectedType: action.selectedType, } } };
    case DepositActions.REQUEST_DEPOSIT:
      return _.set({ ...state }, `data.${action.invoiceId}`, { loading: true });
    case DepositActions.REQUEST_DEPOSIT_VOIDED:
      return _.set({ ...state }, `data.${action.invoiceId}`, { loading: true });
    case DepositActions.RECEIVE_DEPOSIT:
      return _.set(nextState, `data.${action.invoiceId}`, action.deposit || {});
    case DepositActions.RECEIVE_DEPOSIT_VOIDED:
      return _.set(nextState, `data.${action.invoiceId}`, action.deposit || {});
    case DepositActions.RECEIVE_BATCH_DEPOSIT:
      return _.set(nextState, `data.${action.invoiceId}`, action.deposit || {});
    default:
      return state;
  }
}

export class AdminPaths {
  static adminUrl = (companyId: string): string => `/admin/company/${companyId}/admin-center`;
  static adminUsersUrl = (): string => '/admin/users';
  static companyListUrl = (): string => '/admin/company';
  static adminQAManagementConsoleUrl = (): string => '/admin/qa-management-console';
  static companyDashboardUrl = (): string => '/admin/dashboard';
  static companyCreateUrl = (): string => `/admin/company/create`;
  static companyManageRootUrl = (companyId: string): string => (
    `/admin/company/${companyId}`
  )

  static companyTransactionsUrl = (companyId: string): string => (
    `/admin/company/${companyId}/transactions`
  )

  static companyTeamUrl = (companyId: string): string => (
    `/admin/company/${companyId}/team`
  )

  static companySettingsUrl = (companyId: string, redirect?: string): string => (
    `/admin/company/${companyId}/settings${redirect ? '?redirect=' + redirect : ''}`
  )

  static companyLocationsUrl = (companyId: string): string => (
    `/admin/company/${companyId}/locations`
  )

  static companyBankAccountUrl = (companyId: string): string => (
    `/admin/company/${companyId}/bank`
  )

  static locationAdminUrl = (companyId: string, locationId: string): string => (
    `/admin/company/${companyId}/locations/${locationId}/admin-center`
  )

  static locationCreateUrl = (companyId: string): string => (
    `/admin/company/${companyId}/locations/create`
  )

  static locationEditUrl = (companyId: string, locationId: string): string => (
    `/admin/company/${companyId}/locations/${locationId}/edit/`
  )

  static userListUrl = (companyId: string): string => (
    `/admin/company/${companyId}/team`
  )

  static userCreateUrl = (companyId: string): string => (
    `/admin/company/${companyId}/team/create`
  )

  static userEditUrl = (companyId: string, userId: string, redirect?: string): string => (
    `/admin/company/${companyId}/team/${userId}${redirect ? '?redirect=' + redirect : ''}`
  )

  static companyProductsUrl = (companyId: string, locationId: string): string => (
    `/admin/company/${companyId}/locations/${locationId}/products`
  )

  static productCreateUrl = (companyId: string, locationId: string): string => (
    `/admin/company/${companyId}/locations/${locationId}/products/create`
  )

  static productEditUrl = (companyId: string, locationId: string, productId: string): string => (
    `/admin/company/${companyId}/locations/${locationId}/products/${productId}/edit`
  )

  static productViewUrl = (companyId: string, locationId: string, productId: string): string => (
    `/admin/company/${companyId}/locations/${locationId}/payment-devices/products/${productId}`
  )

  static categoryCreateUrl = (companyId: string, locationId: string): string => (
    `/admin/company/${companyId}/locations/${locationId}/products/categories/create`
  )

  static categoryEditUrl = (companyId: string, locationId: string, categoryId: string): string => (
    `/admin/company/${companyId}/locations/${locationId}/products/categories/${categoryId}/edit`
  )

  static companyTaxesUrl = (companyId: string, locationId: string): string => (
    `/admin/company/${companyId}/locations/${locationId}/taxes`
  )
  static taxCreateUrl = (companyId: string, locationId: string): string => (
    `/admin/company/${companyId}/locations/${locationId}/taxes/create`
  )

  static taxEditUrl = (companyId: string, locationId: string, productId: string): string => (
    `/admin/company/${companyId}/locations/${locationId}/taxes/${productId}/edit`
  )

  static customFieldsUrl = (companyId: string, locationId: string): string => (
    `/admin/company/${companyId}/locations/${locationId}/custom-fields`
  )

  static locationCardReadersUrl = (companyId: string, locationId: string): string => (
    `/admin/company/${companyId}/locations/${locationId}/card-readers`
  )

  static activityLogsUrl = (companyId: string): string => (
    `/admin/company/${companyId}/logs`
  )

  static depositsUrl = (companyId: string): string => `/admin/company/${companyId}/deposits`;
  static activityListUrl = (): string => `/admin/activities`;

  static locationShiftsUrl = (companyId: string, locationId: string): string => (
    `/admin/company/${companyId}/locations/${locationId}/shifts`
  )

  static shiftCreateUrl = (companyId: string, locationId: string): string => (
    `/admin/company/${companyId}/locations/${locationId}/shifts/create`
  )

  static shiftEditUrl = (companyId: string, locationId: string, shiftId: string): string => (
    `/admin/company/${companyId}/locations/${locationId}/shifts/${shiftId}/edit`
  )

  static locationDepartmentsUrl = (companyId: string, locationId: string): string => (
    `/admin/company/${companyId}/locations/${locationId}/departments`
  )

  static departmentCreateUrl = (companyId: string, locationId: string): string => (
    `/admin/company/${companyId}/locations/${locationId}/departments/create`
  )

  static locationWorkOrderUrl = (companyId: string, locationId: string): string => (
    `/admin/company/${companyId}/locations/${locationId}/workorders`
  )

  static locationWorkOrderEditUrl = (companyId: string, locationId: string, templateId: string): string => (
    `${AdminPaths.locationWorkOrderUrl(companyId, locationId)}/${templateId}`
  )

  static locationWorkOrderCreateUrl = (companyId: string, locationId: string): string => (
    `${AdminPaths.locationWorkOrderUrl(companyId, locationId)}/create`
  )

  static departmentEditUrl = (companyId: string, locationId: string, departmentId: string): string => (
    `/admin/company/${companyId}/locations/${locationId}/departments/${departmentId}/edit`)

  static companyFeesUrl = (companyId: string): string => `/admin/company/${companyId}/fee`;
  static feeCreateUrl = (companyId: string): string => `/admin/company/${companyId}/fee/create`;
  static feeEditUrl = (companyId: string, feeId: string): string => `/admin/company/${companyId}/fee/${feeId}/edit`;

  static companyFeaturesUrl = (companyId: string): string => `/admin/company/${companyId}/feature`;
  static featureCreateUrl = (companyId: string): string => `/admin/company/${companyId}/feature/create`;
  static featureEditUrl = (companyId: string, featureId: string): string =>
    (`/admin/company/${companyId}/feature/${featureId}/edit`)

  static SimulationsUrl = (companyId: string): string => `/admin/company/${companyId}/simulations`;

  static reportRecipientsUrl = (): string => `/admin/settings/report-recipient`;
  static reportRecipientsCreateUrl = (): string => `/admin/settings/report-recipient/create`;
  static reportRecipientsEditUrl = (reportRecipientsId: string): string =>
    (`/admin/settings/report-recipient/${reportRecipientsId}`)

  static adminSettingsRootUrl = (): string => AdminPaths.reportRecipientsUrl();

  static copyLocation = (companyId: string): string => `/admin/company/${companyId}/copy-location`;

  static emailsLogs = (): string => '/admin/settings/emails-logs';

  static workOrderSettingsUrl = (companyId: string, locationId: string): string =>
    (`/admin/company/${companyId}/locations/${locationId}/workorders`);
  static companyPasswordSettingsUrl = (companyId: string): string => `/admin/company/${companyId}/security`;
  static workOrderSettingsCustomFields = (companyId: string, locationId: string): string =>
    (`/admin/company/${companyId}/locations/${locationId}/workorders/custom-fields`);
  static reportSettings = (companyId: string): string => `/admin/company/${companyId}/report-settings`;
  static reportsUrl = (companyId: string): string => `/admin/company/${companyId}/reports`;
  static reportsVoidedUrl = (companyId: string): string => `/admin/company/${companyId}/reports/voided-invoices`;
  static reportsRefundedUrl = (companyId: string): string => `/admin/company/${companyId}/reports/refunded-invoices`;
  static reportsJournalEntryUrl = (companyId: string): string => `/admin/company/${companyId}/reports/journal-entry`;

  static companyCarriersUrl = (companyId: string): string => `/admin/company/${companyId}/carrier`;
  static carrierCreateUrl = (companyId: string): string => `/admin/company/${companyId}/carrier/create`;
  static carrierEditUrl = (companyId: string, carrierId: string): string => (`/admin/company/${companyId}/carrier/${carrierId}/edit`);
  static SelectFeatureTemplateUrl = (companyId: string): string => `/admin/company/${companyId}/feature-template`;
  static surveyMgmt = (): string => `/admin/settings/survey-mgmt`;
  static payoutsUrl = (companyId: string): string => `/admin/company/${companyId}/instant-payouts`;
  static invoicesUrl = (companyId: string): string => `/admin/company/${companyId}/invoices`;
  static editInvoice = (companyId: string, invoiceId: string): string => `/admin/company/${companyId}/invoices/${invoiceId}/edit`;
  static editTowbookInvoice = (companyId: string, invoiceId: string): string => `/admin/company/${companyId}/invoices/towbook/${invoiceId}/edit`;
  static billingInvoiceDetailsUrl = (companyId: string, invoiceId: string): string => `/admin/company/${companyId}/invoices/${invoiceId}/invoice-details`;
  static workOrdersUrl = (companyId: string): string => `/admin/company/${companyId}/work-orders`;
  static onesourceUrl = (companyId: string): string => `/admin/company/${companyId}/onesource`;
  static integrationsUrl = (companyId: string): string => `/admin/company/${companyId}/integrations`;
  static addressBookUrl = (companyId: string): string => `/admin/company/${companyId}/address-book`;
}

import React from 'react';

const NoProductsScreen: React.FC = (): React.ReactElement => (
  <div className="newpos-wrap-small">
    <div className="newpos-title error">
      There are no products added to selected location and you can't create a new invoice. Please contact support.
    </div>
  </div>
);

export default NoProductsScreen;

import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        dialog: {
            "& .MuiPaper-root": {
                minWidth: "400px",
                maxWidth: "500px",
                [theme.breakpoints.down(500)]: {
                    minWidth: "320px",
                    maxWidth: "420px",
                },
            },
            "& .MuiPaper-rounded": {
                borderRadius: "12px",
            },
            "& .MuiDialogTitle-root": {
                padding: "20px 20px 8px 20px",
            },
            "& .MuiDialogContent-root": {
                padding: "8px 20px",
            },
            "& .MuiListItemIcon-root": {
                minWidth: "44px",
            },
        },
        modalCloseButton: {
            position: "absolute",
            top: "18px",
            right: "18px",
        },
        dialogTitle: {
            fontSize: 16,
            padding: "4px 0 0",
            textAlign: "center",
        },
        dialogText: {
            fontSize: "16px",
            lineHeight: "24px",
            textAlign: "center",
        },
        gridItemBtn: { width: "100%" },
        textTransformNone: {
            textTransform: "none",
        },
    })
);

export default useStyles;

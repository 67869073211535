import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  field42: {
    '& input': {
      padding: '11.5px 14px',
      fontSize: 14,
    },
    '& label': {
      height: 42,
      top: `-6px`,
      fontSize: 14,
    },
    '& [class*="MuiInputLabel-outlined"]': {
      '&[class*="MuiInputLabel-shrink"]': {
        transform: 'translate(14px, -1px) scale(0.86)',
      }
    },
  },
}));

export default useStyles;

import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { PreparationSteps, PaymentMethods } from '../../../../constants/invoice';
import { Invoice } from '../../../../types/Invoice';
import {
  isCardEnabled, isCardOnFileEnabled, isCashEnabled, isCreditCardAtPublicCheckoutOnlyEnabled, isDirectBillEnabled,
  isHostBillEnabled, isPublicCheckoutEnabled, isWexEnabled,
} from '../../../../services/app/company';
import {
  setCardPaymentMethod, setCashPaymentMethod, setRemoteCheckoutMethod, setFuelCardPaymentMethod
} from '../../../../actions/deposits';
import { GlobalState } from "../../../../types/GlobalState";
import { InvoicePreparationStepProps } from "../../../../types/InvoicePreparationStepProps";
import PaymentMethodList from "../../../../components/invoice/PaymentMethodList";
import { getCardAccountStatus } from '../../../../actions/cardAccounts';
import { Company } from '../../../../types/Company';
import { CardAccountStatus } from '../../../../types/CardAccountStatus';
import { List } from '@material-ui/core';
import InvoicePreparationStepContainer from './InvoicePreparationStepContainer';
import { Error } from '../../../../components/ui/Error';
import { getInvoice } from '../../../../actions/invoices';
import { FullScreenLoader } from "@roadsync/roadsync-ui";
import { isComdataCardServiceOn, isFleetCheckServiceOn } from '../../../../services/app/invoice';
import { updateInvoice } from '../../../../actions/invoices';
import { getPreparedInvoice } from '../../../../services/app/invoice';

interface RouteParams {
  invoiceId: string;
}

interface Props extends DispatchProp, InvoicePreparationStepProps, PropsFromState, RouteComponentProps<RouteParams> {}

type PropsFromState = Pick<GlobalState, "invoices" | "deposits" | "companies" | "cardAccount" | "auth"> & {
  comdataApiLocationId?: string;
  invoice: Invoice;
};


interface State {
  selectedMethod: string;
  isCashEnabled?: boolean;
  isCardEnabled?: boolean;
  isRemoteCheckoutEnabled?: boolean;
  isHostBillEnabled?: boolean;
  isDirectBillEnabled?: boolean;
  isWexEnabled?: boolean;
  error?: string | null;
  submitting?: boolean;
  isComdataCardOn?: boolean;
  isFleetCheckOn?: boolean;
}

class PaymentMethod extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.updatePaymentMethod = this.updatePaymentMethod.bind(this);
    this.handleSelectPaymentMethod = this.handleSelectPaymentMethod.bind(this);
    this.shouldSetStorePaymentDetailsTrueByDefault = this.shouldSetStorePaymentDetailsTrueByDefault.bind(this);
    this.state = { selectedMethod: PaymentMethods.CHECK.key, submitting: true };
  }

  async componentDidMount(): Promise<void> {
    const { dispatch, auth } = this.props;
    await dispatch<any>(getInvoice(this.getInvoiceId()));
    await dispatch<any>(getCardAccountStatus(this.getCompanyId()));
    const company = this.getCompany();
    const isComdataCardOn = await isComdataCardServiceOn(auth?.me, company);
    const isFleetCheckOn = await isFleetCheckServiceOn(auth?.me, company);
    this.setState({
      isCashEnabled: isCashEnabled(company),
      isCardEnabled: isCardEnabled(company) && !isCreditCardAtPublicCheckoutOnlyEnabled(company),
      isRemoteCheckoutEnabled: isPublicCheckoutEnabled(company),
      isHostBillEnabled: isHostBillEnabled(company),
      isDirectBillEnabled: isDirectBillEnabled(company),
      isWexEnabled: isWexEnabled(company),
      submitting: false,
      isComdataCardOn,
      isFleetCheckOn
    });
  }

  isComdataApiFlowOn(): boolean {
    const { comdataApiLocationId } = this.props;
    const { isComdataCardOn } = this.state;
    return !!isComdataCardOn && !!comdataApiLocationId && comdataApiLocationId?.length > 0;
  }

  getCompany(): Company | undefined {
    const { companies } = this.props;
    const companyId = this.getCompanyId();
    return companies?.data?.[companyId];
  }

  getCompanyId(): string {
    const invoice = this.getInvoice();
    return "string" === typeof invoice.company ? invoice.company : invoice.company?.id;
  }

  getInvoiceId(): string {
    const { match: { params: { invoiceId } } } = this.props;
    return invoiceId;
  }

  getInvoice(): Invoice {
    const { invoices } = this.props;
    return invoices?.data?.[this.getInvoiceId()] as Invoice;
  }

  handleError(e?: { message?: string }): void {
    this.setState({ error: e?.message || "Something went wrong. Please try again or contact support." });
  }

  shouldSetStorePaymentDetailsTrueByDefault(): boolean {
    const { selectedMethod } = this.state;
    const invoice = this.getInvoice();
    const company = this.getCompany();
    const isPaymentMethodSelectedRemoteCheckout = selectedMethod === PaymentMethods.REMOTE_CHECKOUT.key
    const isNewInvoice = !invoice?.type;
    const isCardOnFileEnabledForCompany = isCardOnFileEnabled(company);
    return isPaymentMethodSelectedRemoteCheckout && isNewInvoice && isCardOnFileEnabledForCompany;
  }

  // eslint-disable-next-line max-lines-per-function
  async updatePaymentMethod(): Promise<void> {
    const { dispatch, onCompletedStep } = this.props;
    const { selectedMethod } = this.state;
    const invoice = this.getInvoice();
    const invoiceToSave = getPreparedInvoice({ ...invoice, storePaymentDetails: true });

    try {
      switch (selectedMethod) {
        case PaymentMethods.CHECK.key:
          return onCompletedStep(
            invoice?.type !== PaymentMethods.CHECK.key
              ? PreparationSteps.SELECT_FLEET_CARD_TYPE
              : PreparationSteps.CHECK_TYPES
          );
        case PaymentMethods.FUEL_CARD.key:
          await dispatch<any>(setFuelCardPaymentMethod(invoice.id));
          return onCompletedStep(PreparationSteps.FUEL_CARD_PAYMENT_METHODS);
        case PaymentMethods.COMDATA_CARD.key:
          return onCompletedStep(PreparationSteps.NEW_FUEL_CARD_PAYMENT_METHODS);
        case PaymentMethods.CARD.key:
          await dispatch<any>(setCardPaymentMethod(invoice.id));
          break;
        case PaymentMethods.HOST_BILL.key:
        case PaymentMethods.DIRECT_BILL.key:
        case PaymentMethods.CASH.key:
          await dispatch<any>(setCashPaymentMethod(invoice.id, selectedMethod));
          break;
        case PaymentMethods.REMOTE_CHECKOUT.key: {
          await dispatch<any>(setRemoteCheckoutMethod(invoice.id));
          if (this.shouldSetStorePaymentDetailsTrueByDefault()) await dispatch<any>(updateInvoice(invoiceToSave));
          break;
        }
      }
      await dispatch<any>(getInvoice(invoice.id));
      onCompletedStep(PreparationSteps.LINE_ITEMS);

    } catch (e) {
      this.handleError(e as any);
    }
    this.setState({ submitting: false });
  }

  async handleSelectPaymentMethod(selectedMethod: string): Promise<void> {
    this.setState({ submitting: true });
    return new Promise((resolve) => {
      this.setState({ selectedMethod }, () => resolve(this.updatePaymentMethod()));
    });
  }

  getCardAccountStatus(): CardAccountStatus | undefined {
    const { cardAccount } = this.props;
    // @ts-ignore
    return cardAccount?.status?.[this.getCompanyId()];
  }

  render(): React.ReactElement {
    const { selectedMethod, error, submitting, isComdataCardOn, isFleetCheckOn, ...methods } = this.state;
    const { onCompletedStep, comdataApiLocationId } = this.props;
    return (
      <InvoicePreparationStepContainer title="Select Payment Method">
        <Error error={error} mb={2} />
        <FullScreenLoader show={submitting} />
        <List>
          <PaymentMethodList
            comdataApiLocationId={comdataApiLocationId}
            isComdataCardOn={isComdataCardOn}
            isFleetCheckOn={isFleetCheckOn}
            onCompletedStep={onCompletedStep}
            handleSelectPaymentMethod={this.handleSelectPaymentMethod}
            invoice={this.getInvoice()}
            company={this.getCompany()}
            cardAccountStatus={this.getCardAccountStatus()}
            {...methods}
          />
        </List>
      </InvoicePreparationStepContainer>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({ invoices, deposits, companies, cardAccount, auth, locations }: GlobalState,
  { match: { params: { invoiceId } } }: RouteComponentProps<RouteParams>
): PropsFromState => {
    const invoice = invoices?.data?.[invoiceId] as Invoice;
  return {
    invoices,
    invoice,
    deposits,
    companies,
    cardAccount,
    auth,
    comdataApiLocationId: locations?.data?.[String(invoice.location)]?.comdataApiLocationId
  };
}
export default withRouter(connect(mapStateToProps)(PaymentMethod));

export class ShiftEndpoints {
  static getShiftById = (shiftId: string): string => (
    `/api/v1/shifts/${shiftId}`
  )
  static getShiftList = (locationId: string, offset: number, limit: number): string => (
    `/api/v1/locations/${locationId}/shifts?offset=${offset}&limit=${limit}`
  )

  static createShift = (locationId: string): string => `/api/v1/locations/${locationId}/shifts`;
  static updateShift = (shiftId: string): string => `/api/v1/shifts/${shiftId}`;
  static deleteShift = (shiftId: string): string => `/api/v1/shifts/${shiftId}`;
}

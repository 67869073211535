import { ReportActions } from '../constants/actions';
import _ from 'lodash';

export interface ReportState {
  statsByCompany: any;
  statsByCompanyInvoices: any[];
}

// eslint-disable-next-line
export function report(
  state: ReportState = { statsByCompany: {}, statsByCompanyInvoices: [] },
  action: Record<string, any> = {}
): ReportState {
  switch (action.type) {
    case ReportActions.CLEAN_REPORT_SCREEN:
      return { statsByCompany: {}, statsByCompanyInvoices: [] };
    case ReportActions.GETTING_STATS_BY_COMPANY:
      return _.set({ ...state }, `statsByCompany`, { loading: true });
    case ReportActions.GOT_STATS_BY_COMPANY:
      return _.set({ ...state }, `statsByCompany`, action.stats);
    case ReportActions.RECEIVE_INVOICES_BY_FILTER:
    case ReportActions.RECEIVE_VOIDED_INVOICES_REPORT:
    case ReportActions.RECEIVE_REFUNDED_INVOICES_REPORT:
      return _.set({ ...state }, `statsByCompanyInvoices`, action.entities);
    case ReportActions.REQUEST_VOIDED_INVOICES_REPORT:
    case ReportActions.REQUEST_REFUNDED_INVOICES_REPORT:
    case ReportActions.REQUEST_INVOICES_BY_FILTER:
      return _.set({ ...state }, `statsByCompanyInvoices`, []);
    default:
      return {
        ...state,
      };
  }
}

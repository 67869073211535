export class DepartmentEndpoints {
  static getDepartmentById = (department: string): string => (
    `/api/v1/departments/${department}`
  )
  static getDepartmentList = (locationId: string, offset: number, limit: number): string => (
    `/api/v1/locations/${locationId}/departments?offset=${offset}&limit=${limit}`
  )

  static createDepartment = (locationId: string): string => `/api/v1/locations/${locationId}/departments`;
  static updateDepartment = (department: string): string => `/api/v1/departments/${department}`;
  static deleteDepartment = (department: string): string => `/api/v1/departments/${department}`;
}

export class WorkOrdersEndpoints {

  static getWorkOrderById = (workOrderId: string): string => `/api/v1/workorders/${workOrderId}`;
  static getWorkOrderByToken = (workOrderToken: string): string => `/api/v1/public/workorders/${workOrderToken}`;
  // eslint-disable-next-line max-params
  static getWorkOrderList = (offset: number, limit: number, quickfilter?: string | null): string => (
    `/api/v1/workorders?offset=${offset}&limit=${limit}${quickfilter ? '&search=' + quickfilter : ''}`
  )
  static getWorkOrderListByCompany = (
    companyId: string, offset: number, limit: number, search?: string | null, locationId?: string | null
    // eslint-disable-next-line max-params
  ): string => (
      `/api/v1/companies/${companyId}/workorders?offset=${offset}&limit=${limit}${search ? '&search=' + search
        : ''}${locationId ? '&locationid=' + locationId : ''}`
    )
  static createWorkOrder = (companyId: string): string => `/api/v1/companies/${companyId}/workorders`;
  static updateWorkOrder = (workOrderId: string): string => `/api/v1/workorders/${workOrderId}`;
  static deleteWorkOrder = (workOrderId: string): string => `/api/v1/workorders/${workOrderId}`;

  static sendWorkOrder = (workOrderId: string): string => `/api/v1/workorders/${workOrderId}/send`;

  static getWorkOrderPdf = (workOrderId: string): string => `/api/v1/workorders/${workOrderId}/auth/pdf`;
  static getWorkOrderPdfPublic = (token: string): string => `/api/v1/public/workorders/${token}/pdf`;

  static templates = (locationId: string): string => `/api/v1/locations/${locationId}/workorders/settings`;
  static createTemplate = (locationId: string): string => `/api/v1/locations/${locationId}/workorders/settings`;
  static getTemplate = (templateId: string): string => `/api/v1/workorders/settings/${templateId}`;
  static updateTemplate = (templateId: string): string => `/api/v1/workorders/settings/${templateId}`;
  static deleteTemplate = (templateId: string): string => `/api/v1/workorders/settings/${templateId}`;

  static approve = (workOrderId: string): string => `/api/v1/workorders/${workOrderId}/approve`;
  static publicApprove = (token: string): string => `/api/v1/public/workorders/${token}/approve`;
  static uploadFilePublic = (token: string): string => `/api/v1/public/workorders/file/${token}`;

  static createWorkOrderInvoice = (workOrderId: string): string => `/api/v1/workorders/${workOrderId}/invoice`;

  static createWorkOrderLocationCustomFields = (locationId: string): string =>
    (`/api/v1/locations/${locationId}/workorders/custom-fields`)

  static getWorkOrderLocationPdfPreview = (locationId: string): string => `/api/v1/locations/${locationId}/pdf/preview`;
}

import React from 'react';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { isWidthDown, Grid, withWidth } from '@material-ui/core';
import { Button } from './Button';
import { PrimaryButton } from './PrimaryButton';
import { SecondaryButton } from './SecondaryButton';
import { FormButtonProps } from './FormButtonProps';

interface Props {
  primary: FormButtonProps;
  secondary?: FormButtonProps;
  tertiary?: FormButtonProps;
  width: Breakpoint;
}

const getSecondaryButton = (props: Props, isMobile?: boolean): React.ReactNode => (
  <React.Fragment>
    {props.secondary &&
      <Grid item>
        <SecondaryButton
          fullWidth={isMobile}
          {...props.secondary}>
          {props.secondary.text}
        </SecondaryButton>
      </Grid>
    }
  </React.Fragment>
);

const getPrimaryButton = (props: Props, isMobile?: boolean): React.ReactNode => (
  <Grid item>
    <PrimaryButton
      fullWidth={isMobile}
      {...props.primary}>
      {props.primary.text}
    </PrimaryButton>
  </Grid>
);

const getTertiaryButton = (props: Props, isMobile?: boolean): React.ReactNode => (
  <React.Fragment>
    {props.tertiary &&
      <Grid item>
        <Button
          fullWidth={isMobile}
          {...props.tertiary}>{props.tertiary.text}</Button>
      </Grid>
    }
  </React.Fragment>
)

export const ButtonRow: React.FC<Props> = (props: Props): React.ReactElement => {
  const isMobile = isWidthDown('xs', props.width);
  return (
    <Grid
      container
      spacing={2}
      direction={isMobile ? 'column-reverse' : 'row'}
      justifyContent={isMobile ? 'center' : 'flex-end'}
      alignItems={isMobile ? 'stretch' : 'center'}>
      {getSecondaryButton(props, isMobile)}
      {getPrimaryButton(props, isMobile)}
      {getTertiaryButton(props, isMobile)}
    </Grid>
  );
}

export default withWidth()(ButtonRow);

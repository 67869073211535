import { Hidden } from "@material-ui/core";
import React from "react";
import InsertCommentIcon from '@material-ui/icons/InsertComment';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { InvoiceTab } from "../../types/InvoiceTab";

interface InvoiceTabLabelProps {
  tab: InvoiceTab;
}

export const InvoiceTabLabel: React.FC<InvoiceTabLabelProps> = (props: InvoiceTabLabelProps) => {
  const { tab } = props;
  switch (tab) {
    case InvoiceTab.Comments:
      return <>
        <Hidden xsDown>{tab}</Hidden>
        <Hidden smUp><InsertCommentIcon /></Hidden>
      </>;
    case InvoiceTab.Attachments:
      return <>
        <Hidden xsDown>{tab}</Hidden>
        <Hidden smUp><AttachFileIcon /></Hidden>
      </>;
    default:
      return <>{tab}</>;
  }
};

import React from "react";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { VGSCollect, VGSForm, VGSFormFieldId, VGSScriptUrl } from "../../services";
import { GlobalState } from "../../types";
import "./VgsCreditCardForm.css";

export const createVgsCreditCardForm = async (vaultId: string = '', environment: string = '', onChange: (val: any) => void): Promise<VGSForm> => {
  if (!vaultId || !environment) {
    throw new Error('Invalid configuration');
  }

  await loadVgsScript();
  const collectJS = (window as any).VGSCollect as VGSCollect;
  const vgsForm = collectJS.create(vaultId, environment!, onChange);
  const baseCss = {
    padding: "0px 15px",
    border: "solid 1px #b9b9b9",
    height: 56,
    width: "100%",
    boxSizing: "border-box",
    position: "relative",
    "&:focus": {
      "border-color": "#f4cf12",
    },
    "&.invalid.touched": {
      "border-color": "red",
    },
    "&.valid": {
      "border-color": "green",
    },
  };

  vgsForm.field("#" + VGSFormFieldId.CardNumber, {
    name: "card_number",
    type: "card-number",
    placeholder: "Card number",
    showCardIcon: true,
    validations: ["required", "validCardNumber"],
    css: baseCss,
  });

  vgsForm.field("#" + VGSFormFieldId.CardCVV, {
    name: "card_cvc",
    type: "card-security-code",
    hideValue: true,
    placeholder: "CVC",
    validations: ["required", "validCardSecurityCode"],
    css: baseCss,
  });

  vgsForm.field("#" + VGSFormFieldId.CardExpiration, {
    name: "card_exp",
    type: "card-expiration-date",
    placeholder: "Expiration date",
    validations: ["required", "validCardExpirationDate"],
    yearLength: 2,
    css: baseCss,
  });

  return vgsForm;
}

export const loadVgsScript = async (): Promise<void> => {
  return new Promise((resolve, reject) => {
    if ((window as any).VGSCollect) {
      resolve();
      return;
    }

    const script = document.createElement("script");
    script.src = VGSScriptUrl;
    script.onload = () => resolve();
    script.onerror = () => reject();
    document.body.appendChild(script);
  });
}

interface OwnProps {
  className?: string;
}

type StateProps = Pick<GlobalState, "appSettings"> & {};

type Props = OwnProps & StateProps;

interface State { }

class VgsCreditCardForm extends React.Component<Props, State> {
  fadeTimeout = {};
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render(): React.ReactNode {
    const { className } = this.props;

    return (
      <div className={`vgsForm ${className ?? ''}`}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <div id={VGSFormFieldId.CardNumber}></div>
          </Grid>
          <Grid item xs={6} sm={4}>
            <div id={VGSFormFieldId.CardExpiration}></div>
          </Grid>
          <Grid item xs={6} sm={2}>
            <div id={VGSFormFieldId.CardCVV}></div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ appSettings }: GlobalState): StateProps => ({ appSettings });
export default connect(mapStateToProps)(VgsCreditCardForm);

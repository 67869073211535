export enum ProductGridType {
  MediumGrid,
  SmallGrid,
  List
}

export type GridScale = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface MaterialGridScale {
  md: GridScale;
  xs: GridScale;
  lg: GridScale;
}

function getMIUIGridScale(gridType?: ProductGridType): MaterialGridScale {
  switch (gridType) {
    case ProductGridType.MediumGrid:
      return { md: 4, lg: 4, xs: 12 };
    case ProductGridType.SmallGrid:
      return { md: 3, lg: 3, xs: 12 };
    case ProductGridType.List:
      return { md: 12, lg: 12, xs: 12 };
    default:
      return { md: 4, lg: 4, xs: 12 };
  }
}

export { getMIUIGridScale }

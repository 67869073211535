import React, { useState, useEffect } from 'react';
import { connect, DispatchProp } from 'react-redux';
import Alert from '../../components/app/Alert';
import { AlertProps as AlertData } from '../../actions/alerts';
import { GlobalState } from '../../types/GlobalState';

interface PropsFromState {
  alerts?: AlertData[];
}

interface Props extends PropsFromState, DispatchProp { }

const Alerts: React.FC<Props> = (props: Props): React.ReactElement => {
  const { alerts } = props;
  const alert = alerts?.[0];
  const [currentAlert, setCurrentAlert] = useState<AlertData | undefined>(alert);

  useEffect(() => {
    if (alert !== currentAlert) setCurrentAlert(undefined);
    if (alert) setTimeout(() => setCurrentAlert(alert));
  }, [currentAlert, alerts, alert]);

  return <>{currentAlert && <Alert {...currentAlert} />}</>;
}

const mapStateToProps = ({ alerts }: GlobalState): PropsFromState => ({ alerts: alerts?.data });
export default connect(mapStateToProps)(Alerts);

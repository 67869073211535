import { createStyles, makeStyles, Theme } from '@material-ui/core';
import readyForCard from '../../assets/img/svg/readyForCard.svg';

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circleWrapper: {
    position: 'relative',
    width: 80,
    height: 80,
  },
  icon: {
    fontSize: 60,
    position: 'absolute',
    left: 10,
    top: 10,
    zIndex: 1,
  },
  '@keyframes expandFade': {
    '0%': {
      transform: 'scale(1)',
      opacity: 1,
    },
    '15%': {
      opacity: 1,
    },
    '80%': {
      opacity: 0.2,
    },
    '100%': {
      transform: 'scale(3.1)',
      opacity: 0,
    },
  },
  circle: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    backgroundColor: '#fbbd05',
    animation: '$expandFade 4s linear infinite',
  },
  circle2: {
    animationDelay: '1.33s',
  },
  circle3: {
    animationDelay: '2.66s',
  },
}));

export default useStyles;

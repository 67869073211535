import React from 'react';
import { reduxForm, Field, InjectedFormProps } from "redux-form";
import { Hidden, Theme, createStyles, makeStyles } from "@material-ui/core";
import { Subtitle, Grid, Container, Divider } from '@roadsync/roadsync-ui';
import { Form } from '../../form/Form';
import logo from '../../../../assets/img/logo_white.png';
import logoDark from '../../../../assets/img/logo_black.png';
import { RenderTextField } from '../../Form';
import { LargePrimaryButton } from '../../buttons/LargePrimaryButton';
import { Button } from '../../buttons/Button';
import SignupHelp from '../../../../containers/app/SignupHelp';
import SigninHelp from '../../../../containers/app/SigninHelp';

export interface FormData {
  username?: string;
  password?: string;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: '100%',
  },
  mainContainer: {
    maxWidth: '95%',
    width: 400,
  },
  desktopLogo: {
    maxHeight: 30,
  },
  mobileHeader: {
    backgroundColor: theme.palette.secondary.main,
  },
  mobileLogo: {
    maxHeight: 50,
  }
}));

const LoginFormMobileHeader: React.FC<{}> = (): React.ReactElement => {
  const classes = useStyles();
  return (
    <Hidden xsDown>
      <Grid item xs className={classes.mobileHeader} container direction="column" alignItems="center" justifyContent="center">
        <Grid item>
          <img src={logo} alt="RoadSync" className={classes.mobileLogo} />
        </Grid>
      </Grid>
    </Hidden>
  );
}

const LoginFormHelp: React.FC<{}> = (): React.ReactElement => (
  <Grid item>
    <Divider />
    <SignupHelp />
    <SigninHelp />
  </Grid>
);

const LoginFormDesktopLogo: React.FC<{}> = (): React.ReactElement => {
  const classes = useStyles();
  return (
    <Hidden smUp>
      <Grid item>
        <img src={logoDark} alt="RoadSync" className={classes.desktopLogo} />
      </Grid>
    </Hidden>
  )
}

const LoginFormFields: React.FC<{}> = (): React.ReactElement => (
  <React.Fragment>
    <Grid item>
      <Subtitle>Please sign in</Subtitle>
    </Grid>
    <Grid item>
      <Field name="username" label="Login" type="text" component={RenderTextField} required />
    </Grid>
    <Grid item>
      <Field name="password" label="Password" type="password" component={RenderTextField} required />
    </Grid>
    <Grid item>
      <LargePrimaryButton type="submit" fullWidth>Sign In</LargePrimaryButton>
    </Grid>
    <Grid item>
      <Button href="/forgot" fullWidth>Forgot Password?</Button>
    </Grid>
  </React.Fragment>
);

const LoginForm: React.FC<InjectedFormProps<FormData>> = (props: InjectedFormProps<FormData>): React.ReactElement => {
  const classes = useStyles();
  return (
    <Grid className={classes.root} container direction="row">
      <LoginFormMobileHeader />
      <Grid container item xs direction="column" alignItems="center" justifyContent="center">
        <Container className={classes.mainContainer}>
          <Form onSubmit={props.handleSubmit}>
            <LoginFormDesktopLogo />
            <LoginFormFields />
            <LoginFormHelp />
          </Form>
        </Container>
      </Grid>
    </Grid>
  );
}

export default reduxForm<FormData, {}>({
  form: 'login',
})(LoginForm);

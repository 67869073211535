import React from 'react';
import styles from "./Spinner.css"
import { StyledComponentProps } from '@material-ui/core';

interface SpinnerProps {
  className?: string;
}

interface Props extends SpinnerProps, StyledComponentProps {}

const Spinner: React.FC<Props> = (props: Props) => {
  const classes = styles();
  return (
    <div className={`${classes?.spinner} ${props?.className}`}>
      <div className="bounce1"/>
      <div className="bounce2"/>
      <div className="bounce3"/>
    </div>
  );
}

export default(Spinner);

import "../../../../types/Deposit";
// Needed for back button working correctly and keep the app working as expected
import React from 'react';
import ConfirmCheckNumber from './ConfirmCheckNumber';
import { OnCompletedStepProps } from "../../../../types/OnCompletedStepProps";

const FleetCardCheckNumber: React.FC<OnCompletedStepProps> = (props: OnCompletedStepProps): React.ReactElement => {
  const { onCompletedStep } = props;
  return (<ConfirmCheckNumber onCreate={true} onCompletedStep={onCompletedStep} />);
}

export default FleetCardCheckNumber;

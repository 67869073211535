import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { ModalsConstants } from '../../constants/modals';
import ModalContent from '../../components/modals/ModalContent';
import { closeModal } from '../../actions/modals';
import { InvoiceStatuses, PaymentMethods } from '../../constants/invoice';
import { isSuperAdmin } from '../../services/app/auth';
import { H4, Grid } from '@roadsync/roadsync-ui';
import { Invoice } from '../../types/Invoice';
import { GlobalState } from '../../types/GlobalState';
import { withStyles } from '@material-ui/core';
import styles from "./DeleteInvoiceReason.css";
import { StyledComponentProps } from '@material-ui/core';
import { Company } from '../../types/Company';
import { showErrorAlert, showSuccessAlert } from '../../actions/alerts';
import { RefundInvoiceForm, RefundInvoiceFormData } from '../../components/invoice/RefundInvoiceForm';
import { fullRefundInvoice, partialRefundInvoice } from '../../services/api/invoices';
import { formatDollar } from '../../services/app/formats';
import { getCompanyFromState} from '../../services/app/split';
import { isPartialRefundEnabled } from '../../services/app/company';
import { expireInstantPayoutBalance } from '../../reducers/instantPayout';

type PropsFromState = Pick<GlobalState, "companies" | "auth" | "publicData">;

interface Props extends PropsFromState, DispatchProp, StyledComponentProps, OwnProps { }

interface OwnProps {
    deleteHandler: (reason?: string) => Promise<void>;
    invoice: Invoice;
}

interface State {
    submitting?: boolean;
    splitPartialRefundsEnabled?: boolean;
}

class DeleteInvoiceReason extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.generateText = this.generateText.bind(this);
        this.state = {};
    }

    async componentDidMount(): Promise<void> {
        const { auth, companies, publicData } = this.props;
        const company = getCompanyFromState(companies, publicData, auth?.me);
        const splitPartialRefundsEnabled = await isPartialRefundEnabled(company);
        this.setState({ splitPartialRefundsEnabled });
    }

    isFullRefund(refundType?: string): boolean {
        return refundType === "fullRefund";
    }

    /**
     * Deletes or refunds an invoice, depending on the invoice payment method and status
     *
     * @param formValues
     * @returns true if the success message needs to be displayed, and false if the success message has already been displayed
     */
    async deleteInvoice(formValues: RefundInvoiceFormData): Promise<boolean> {
        const { invoice, deleteHandler } = this.props;
        const { splitPartialRefundsEnabled } = this.state;
        const { refundType, refundReason, refundAmount } = formValues;
        if (this.isInvoiceComplete() && splitPartialRefundsEnabled && invoice.type === PaymentMethods.CARD.key) {
            if (this.isFullRefund(refundType)) {
                await fullRefundInvoice(invoice.id, refundReason);
                return true;
            } else if (refundAmount) {
                await partialRefundInvoice(invoice.id, refundAmount);
                return true;
            }
        }
        await deleteHandler(refundReason);
        return false;
    }

    async handleSubmit(formValues: RefundInvoiceFormData): Promise<void> {
        const { dispatch, invoice } = this.props;
        const { refundType, refundAmount } = formValues;
        const isFullRefund: boolean = this.isFullRefund(refundType);

        try {
            const needToShowSuccessMsg = await this.deleteInvoice(formValues);
            if (needToShowSuccessMsg) {
                await dispatch<any>(showSuccessAlert(
                    `Invoice #${invoice.invoiceNumber} from ${invoice.payerName} was ${this.getActionTypeLabel()}ed` +
                    (isFullRefund ? "" : ` for ${formatDollar(refundAmount)}`)
                ));
            }
        } catch (e) {
            dispatch(showErrorAlert(`${(e as any)?.message ?? e}`));
        } finally {
            dispatch(expireInstantPayoutBalance());
        }

        this.handleCancel();
    }

    handleCancel(): void {
        const { dispatch } = this.props;
        dispatch<any>(closeModal(ModalsConstants.DELETE_INVOICE_REASON));
    }

    getCompany(): Company | undefined {
        const { invoice, companies } = this.props;
        const companyId = "string" === typeof invoice?.company ? invoice.company : invoice?.company?.id;
        return companies?.data?.[companyId];
    }

    isInvoiceComplete(): boolean {
        const { invoice } = this.props;
        return invoice?.status === InvoiceStatuses.COMPLETED.key;
    }

    getActionTypeLabel(): string {
        return this.isInvoiceComplete() ? "refund" : "void";
    }

    generateText(): string {
        const { invoice, auth: { me } } = this.props;
        let text = `Are you sure you want to ${this.getActionTypeLabel()} RS# ${invoice.invoiceNumber} for ${this.payerNameToUse()}`;
        if (isSuperAdmin(me)) {
            text += ` at ${this.getCompany()?.name}`;
        }
        return text;
    }

    payerNameToUse(): string {
        const { invoice } = this.props;
        return invoice?.payerName || '';
    }

    // eslint-disable-next-line
    render(): React.ReactElement {
        const { classes, invoice } = this.props;
        return (
            <ModalContent className={classes?.root}>
                <Grid container direction="column" wrap="nowrap" spacing={2}>
                    <Grid item>
                        <H4 gutterBottom>{this.generateText()}?</H4>
                    </Grid>
                    <Grid item>
                        <RefundInvoiceForm handleCancel={this.handleCancel} invoice={invoice} onSubmit={this.handleSubmit} company ={this.getCompany()} />
                    </Grid>
                </Grid>
            </ModalContent>
        );
    }
}

// istanbul ignore next
const mapStateToProps = ({ companies, auth, publicData }: GlobalState): PropsFromState => ({ companies, auth, publicData });
export default withStyles(styles)(connect(mapStateToProps)(DeleteInvoiceReason));

import { BankActions } from '../constants/actions';
import _ from 'lodash';
import { CustomerStatuses, SourceStatuses } from '../constants/bank';
import { BeneficialOwner } from '../types/BeneficialOwner';
import { BankStatus } from '../types/BankStatus';
import { BankAccountBalance } from '../types/BankAccountBalance';

export interface BankState {
  status: { [id: string]: BankStatus & { loading?: boolean } };
  accounts?: { [companyId: string]: BankAccountBalance };
  bankInfo?: { [id: string]: BankStatus & { loading?: boolean } };
  beneficialOwners?: { [id: string]: BeneficialOwner };
}

interface BankAction {
  type: string;
  companyId?: string;
  accounts?: { [companyId: string]: BankAccountBalance };
  status?: { [id: string]: BankStatus };
  bankInfo?: { [id: string]: BankStatus };
  owners?: BeneficialOwner;
}

// eslint-disable-next-line max-lines-per-function
export function bank(state: BankState = { status: {} }, action: BankAction = { type: "unknown" }): BankState {
  switch (action.type) {
    case BankActions.REQUEST_STATUS:
      return _.set({ ...state }, `status.${action.companyId}`, { loading: true });
    case BankActions.RECEIVE_STATUS:
      return _.set({ ...state }, `status.${action.companyId}`, action.status);
    case BankActions.REQUEST_BANK_BALANCE:
      return _.set({ ...state }, `accounts.${action.companyId}`, { loading: true });
    case BankActions.RECEIVE_BANK_BALANCE:
      return _.set({ ...state }, `accounts.${action.companyId}`, action.accounts);
    case BankActions.REQUEST_BANK_ACCOUNT:
      return _.set({ ...state }, `bankInfo.${action.companyId}`, { loading: true });
    case BankActions.RECEIVE_BANK_ACCOUNT:
      return _.set({ ...state }, `bankInfo.${action.companyId}`, action.bankInfo);
    case BankActions.REQUEST_BENEFICIAL_OWNERS:
      return _.set({ ...state }, `beneficialOwners.${action.companyId}`, { loading: true });
    case BankActions.RECEIVE_BENEFICIAL_OWNERS:
      return _.set({ ...state }, `beneficialOwners.${action.companyId}`, action.owners);
    case BankActions.RESETTING_BANK_ACCOUNT:
      return _.set({ ...state }, `status.${action.companyId}`, { loading: true });
    case BankActions.RESET_BANK_ACCOUNT:
      return _.set({ ...state }, `status.${action.companyId}`, {
        loading: false,
        sourceStatus: SourceStatuses.NONE, customerStatus: CustomerStatuses.VERIFIED
      });
    default:
      return state;
  }
}

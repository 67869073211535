import { Grid } from "@material-ui/core";
import React from "react";
import ToggleButton from '../../components/app/ToggleButton';
import { getMIUIGridScale, ProductGridType } from "../../types/UserInterface";
import { ProductSelectorProps } from "./ProductSelector";

// eslint-disable-next-line max-lines-per-function
const ProductTypeOtherSelector: React.FC<ProductSelectorProps> = (props: ProductSelectorProps) => {
  const { product, qty, selected, desc, onCustomize, gridType } = props;
  const isSmallGrid = gridType === ProductGridType.SmallGrid;
  const isList = gridType === ProductGridType.List;
  return (
    <Grid item {...getMIUIGridScale(gridType)} className={`pos-item pos-item-v2 ${selected ? 'is-active' : ''}`}>
      <ToggleButton isActive={selected} onClick={(): void => { if (onCustomize) { onCustomize(product.id); } }}>
        <div className={`newPos-wrapper newPos-wrapper-v2 ${isList ? "newPos-wrapper__list" : ""}`}>
          <span className={`title-wrap title-wrap-v2 ${isList ? "title-wrap__list" : ""} ${isSmallGrid ? "title-wrap__small-grid" : ""}`}>
            {desc ? desc : product.name}
          </span>
          <span className={`price-wrap price-wrap-v2 ${isList ? "price-wrap__list" : ""}`}>
            {qty && <span className={`price price-v2 ${isList ? "price__list" : ""}`}>${qty || product.cost}</span>}
            {!qty && <span className={`price price-v2 ${isList ? "price__list" : ""}`}>Enter price</span>}
          </span>
        </div>
      </ToggleButton>
    </Grid>
  );
}

export default ProductTypeOtherSelector;

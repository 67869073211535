import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { ModalsConstants } from '../../constants/modals';
import ModalContent from '../../components/modals/ModalContent';
import { closeModal } from '../../actions/modals';
import ReactSignature from '../../components/controls/ReactSignature';

interface OwnProps {
  saveSignature: (signatureBoard) => void;
}

interface Props extends DispatchProp, OwnProps { }

class WorkOrderAddSignature extends React.Component<Props> {

  private signatureBoard;

  constructor(props: Props) {
    super(props);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalSubmit = this.handleModalSubmit.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  handleModalSubmit(): void {
    if (this.signatureBoard.isEmpty()) {
      return;
    }
    const { dispatch, saveSignature } = this.props;
    saveSignature(this.signatureBoard);
    dispatch(closeModal(ModalsConstants.WORK_ORDER_SIGNATURE));
  }

  handleModalClose(): void {
    const { dispatch } = this.props;
    dispatch(closeModal(ModalsConstants.WORK_ORDER_SIGNATURE));
  }

  handleClear(): void {
    this.signatureBoard.clear();
  }

  render(): React.ReactElement {
    return (
      <ModalContent
        className="add-signature-modal"
        primaryActionText="Okay"
        primaryActionClick={this.handleModalSubmit}
        secondaryActionText="Cancel"
        secondaryActionClick={this.handleModalClose}
      >
        <p className="modal-title">Add signature</p>
        <ReactSignature ref={(board): void => { this.signatureBoard = board }} />
        <button className="btn-white-small clean-signature-canvas" onClick={this.handleClear}>Clear</button>
      </ModalContent>
    );
  }
}

export default connect()(WorkOrderAddSignature);

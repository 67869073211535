import _ from 'lodash';

export interface Type {
    key: string;
    display: string;
}

export class CompanyTypes {
    static readonly SYSTEM: Type = {
        key: 'system',
        display: 'System',
    };

    static readonly SHIPPER: Type = {
        key: 'shipper',
        display: 'Shipper',
    };

    static readonly LUMPER: Type = {
        key: 'lumper',
        display: 'Lumper',
    };

    static readonly WAREHOUSE: Type = {
        key: 'warehouse',
        display: 'Warehouse',
    };

    static readonly TOWANDREPARE: Type = {
        key: 'towandrepair',
        display: 'Tow and Repair',
    };

    static readonly OWNEROPERATOR: Type = {
        key: 'owneroperator',
        display: 'Owner-Operator',
    };

    static readonly PAYER: Type = {
        key: 'payer',
        display: 'Payer',
    };

    static readonly DISTRIBUTOR: Type = {
        key: 'distributor',
        display: 'Distributor',
    };

    static readonly UNKNOWN: Type = {
        key: 'unknown',
        display: 'Unknown',
    };

    private static readonly LOOKUP = {
        'system': CompanyTypes.SYSTEM,
        'shipper': CompanyTypes.SHIPPER,
        'lumper': CompanyTypes.LUMPER,
        'warehouse': CompanyTypes.WAREHOUSE,
        'towandrepair': CompanyTypes.TOWANDREPARE,
        'owneroperator': CompanyTypes.OWNEROPERATOR,
        'payer': CompanyTypes.PAYER,
        'distributor': CompanyTypes.DISTRIBUTOR,
        'unknown': CompanyTypes.UNKNOWN,
    };

    static getTypes(): Type[] {
        return _.values(CompanyTypes.LOOKUP);
    }

    static getByKey(key: string): Type {
        return CompanyTypes.LOOKUP[key];
    }
}

export class CompanyTier {
    static readonly EMPTYVALUE: Type = {
        key: '',
        display: 'Please Select...',
    };

    static readonly ESSENTIAL: Type = {
        key: 'essential',
        display: 'Essential',
    };

    static readonly PROFESSIONAL: Type = {
        key: 'professional',
        display: 'Professional',
    };

    static readonly ENTERPRISE: Type = {
        key: 'enterprise',
        display: 'Enterprise',
    };

    private static readonly LOOKUP = {
        'emptyvalue': CompanyTier.EMPTYVALUE,
        'essential': CompanyTier.ESSENTIAL,
        'professional': CompanyTier.PROFESSIONAL,
        'enterprise': CompanyTier.ENTERPRISE,
        
    };

    static getTypes(): Type[] {
        return _.values(CompanyTier.LOOKUP);
    }

    static getByKey(key: string): Type {
        return CompanyTier.LOOKUP[key];
    }
}


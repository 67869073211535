import { SelectedMenuItem } from '../constants/actions';

export interface SelectedMenuItemState {
  itemName?: string;
}

interface SelectedMenuAction {
  type: string;
  itemName?: string;
}

export function selectedMenuItem(
  state: SelectedMenuItemState = {},
  action: SelectedMenuAction = { type: "unknown" }
): SelectedMenuItemState {
  return action.type === SelectedMenuItem.UPDATE_SELECTED_MENU_ITEM
    ? { ...state, itemName: action.itemName }
    : state;
}

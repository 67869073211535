import { InvoicePriceUpdatedModalActions } from '../constants/actions';
export type InvoicePriceUpdatedModalAction = {
  type: InvoicePriceUpdatedModalActions;
  invoiceId?: string;
};

export type openInvoicePriceUpdatedModalByInvoiceIdAction = InvoicePriceUpdatedModalAction & {
  type: InvoicePriceUpdatedModalActions.OPEN;
};

export const openInvoicePriceUpdatedModalByInvoiceId = (invoiceId?: string): openInvoicePriceUpdatedModalByInvoiceIdAction => ({
  type: InvoicePriceUpdatedModalActions.OPEN,
  invoiceId,
});

export type closeInvoicePriceUpdatedModalAction = InvoicePriceUpdatedModalAction & {
  type: InvoicePriceUpdatedModalActions.CLOSE;
};

export const closeInvoicePriceUpdatedModal = (): closeInvoicePriceUpdatedModalAction => ({
  type: InvoicePriceUpdatedModalActions.CLOSE,
});
import { WorkOrderTemplatesActions } from '../constants/actions';
import _ from 'lodash';
import { WorkOrderTemplate } from '../types/WorkOrderTemplate';

export interface WorkOrderTemplatesState {
  data: { [locationId: string]: WorkOrderTemplate[] | { loading: true } };
}

export function workOrderTemplates(
  state: WorkOrderTemplatesState = { data: {} },
  action: Record<string, any> = {}
): WorkOrderTemplatesState {
  switch (action.type) {
    case WorkOrderTemplatesActions.REQUEST_WORK_ORDER_TEMPLATES:
      return _.set({ ...state }, `data.${action.locationId}`, { loading: true });
    case WorkOrderTemplatesActions.RECEIVE_WORK_ORDER_TEMPLATES:
      return _.set({ ...state }, `data.${action.locationId}`, action.templates);
    default:
      return { ...state };
  }
}

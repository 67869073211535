import { UserEndpoints } from './endpoints';
import { User } from '../../types/User';
import * as HTTP from './http';

export function getUser(userId: string): Promise<User> {
  return HTTP.get(UserEndpoints.getUser(userId));
}

export function createUser(companyId: string, user: Partial<User>): Promise<User> {
  return HTTP.postJSON(UserEndpoints.createUser(companyId), user);
}

export function updateUser(user: Pick<User, "id"> & Partial<User>): Promise<User> {
  return HTTP.putJSON(UserEndpoints.updateUser(user.id), user);
}

// eslint-disable-next-line max-params
export function getUsers(
  companyId: string,
  offset: number,
  limit: number,
  username?: string
): Promise<any> {
  return HTTP.get(UserEndpoints.getUsers(companyId, offset, limit, username));
}

// eslint-disable-next-line max-params
export function getAdminUsers(
  offset: number,
  limit: number,
  search: string,
  companytype: string,
  role: string
): Promise<any> {
  return HTTP.get(UserEndpoints.getAdminUsers(offset, limit, search, companytype, role));
}

export function deleteUser(userId: string): Promise<any> {
  return HTTP.del(UserEndpoints.deleteUser(userId), false);
}

export function generateTwoFactorToken(userId: string): Promise<any> {
  return HTTP.postJSON(UserEndpoints.generateTwoFactorToken(userId));
}

export function getTwoFactorQRCode(userId: string): Promise<any> {
  return HTTP.get(UserEndpoints.getTwoFactorQRCode(userId));
}

export function confirmTwoFactorToken(userId: string, code: string): Promise<any> {
  return HTTP.postJSON(UserEndpoints.confirmTwoFactorToken(userId), { code });
}

export function resetTwoFactorToken(userId: string, code: string): Promise<any> {
  return HTTP.postJSON(UserEndpoints.resetTwoFactorToken(userId), { code });
}

export function verify(code: string, userId: string): Promise<any> {
  return HTTP.postJSON(UserEndpoints.verify(userId), { code });
}

export function regenerateVerificationCode(userId: string): Promise<any> {
  return HTTP.postJSON(UserEndpoints.regenerateVerificationCode(userId));
}

export function lockOutLocationUsers(locationId: string, isLocked: boolean): Promise<any> {
  return HTTP.postJSON(UserEndpoints.lockOutLocationUsers(locationId), { isLocked });
}

export function lockOutCompanyUsers(companyId: string, isLocked: boolean): Promise<any> {
  return HTTP.postJSON(UserEndpoints.lockOutCompanyUsers(companyId), { isLocked });
}

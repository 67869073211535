import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  wexLogo: {
    width: '100%',
    height: 'auto',
    maxWidth: 200
  }
}));

export default useStyles;

import React from "react";
import { WithInvoiceProps } from "../WithInvoiceProps";
import { WithChildrenProps } from "../WithChildrenProps";
import { canDeleteInvoice } from "../../../services/app/invoice";
import { IfUserRole } from "../Visibility";
import { Roles } from "../../../constants/roles";
import { isCompanyAdminSection } from "../../../services/app/url";
import { Location } from 'history';

interface Props extends WithInvoiceProps, WithChildrenProps {
  location: Location;
}

const IfCanDeleteInvoice: React.FC<Props> = (props: Props): React.ReactElement => {
  const { children, invoice, location } = props;
  const companyAdminSection = isCompanyAdminSection(location);

  return (
    <React.Fragment>
      {!companyAdminSection &&
        <IfUserRole includes={[Roles.SUPER_ADMIN, Roles.COMPANY_ADMIN, Roles.ACCOUNTANT, Roles.EMPLOYEE, Roles.RS_IMPLEMENTATION]}>
          {canDeleteInvoice(invoice) && children}
        </IfUserRole>
      }
    </React.Fragment>
  );
}

export default IfCanDeleteInvoice;

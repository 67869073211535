import { DepositEndpoints, PublicDepositEndpoints } from './endpoints';
import { ComdataFuelCardChargeRequest, ComdataFuelCardChargeResponse, Deposit, FuelCardChargeRequest, FuelCardChargeResponse } from '../../types';
import { DepositItem } from '../../components/deposits/types/DepositItem';
import * as http from './http';
import { PreEditRequest } from '../../types/PreEditRequest';
import { PreEditResponse } from '../../types/PreEditResponse';
import { TransactionItem } from '../../components/deposits/types/TransactionItem';

export function depositCash(invoiceId: string, subType: string): Promise<{}> {
    return http.postJSON(DepositEndpoints.depositCash(), { invoiceId, subType });
}

export function depositCheckCreateOrReuse(invoiceId: string, checkType: string, payerState, payerIdentifier, deposit?: Partial<Deposit>): Promise<{}> {
    return http.postJSON(DepositEndpoints.depositCheckCreateOrReuse(), { invoiceId, checkType, payerIdentifier, payerState, ...deposit });
}

export function depositVGSCard(
    invoiceToken: string,
    license: string,
    billingFirstName: string,
    billingLastName: string,
    streetAddress: string,
    city: string,
    state: string,
    zipCode: string,
    paymentToken: string,
    cvv: string,
    ccexp: string
): Promise<{}> {
    return http.postJSON(PublicDepositEndpoints.depositCard(), {
        invoiceToken,
        license,
        billingFirstName,
        billingLastName,
        streetAddress,
        city,
        state,
        zipCode,
        paymentToken,
        cvv,
        ccexp,
        tokenizer: 'vgs',
    });
}

export function depositClonedCard(invoiceId: string): Promise<{}> {
    return http.postJSON(DepositEndpoints.depositCard(), { invoiceId, token: 'CardOnFile' });
}

export function validateCard(
    invoiceId: string,
    companyId: string,
    token: string,
    cardState = '',
    zipCode = '',
    billingFirstName = '',
    billingLastName = '',
    streetAddress = '',
    city = '',
    cvv?: string
):
    Promise<{}> {
    return http.postJSON(DepositEndpoints.validateCard(), {
        invoiceId,
        companyId,
        token,
        state: cardState,
        zipCode,
        billingFirstName,
        billingLastName,
        streetAddress,
        city,
        cvv
    });
}

export function setCashPaymentMethod(invoiceId: string, subType: string): Promise<{}> {
    return http.postJSON(DepositEndpoints.setCashPaymentMethod(), { invoiceId, subType });
}

export function setCardPaymentMethod(invoiceId: string): Promise<{}> {
    return http.postJSON(DepositEndpoints.setCardPaymentMethod(), { invoiceId });
}

export function setFuelCardPaymentMethod(invoiceId: string, cardPaymentProcessor?: string): Promise<{}> {
    return http.postJSON(DepositEndpoints.setFuelCardPaymentMethod(), { invoiceId, cardPaymentProcessor });
}

export function setRemoteCheckoutPaymentMethod(invoiceId: string): Promise<{}> {
    return http.postJSON(DepositEndpoints.setRemoteCheckoutPaymentMethod(), { invoiceId });
}

export function setBatchPaymentMethod(invoiceId: string): Promise<{}> {
    return http.postJSON(DepositEndpoints.setBatchPaymentMethod(), { invoiceId });
}

export function getDepositForInvoice(invoiceId: string): Promise<any> {
    return http.get(DepositEndpoints.getDepositForInvoice(invoiceId));
}

export function getDepositForVoidedInvoice(invoiceId: string): Promise<any> {
    return http.get(DepositEndpoints.getDepositForVoidedInvoice(invoiceId));
}

export function processCheck(depositId: string, deposit: Deposit): Promise<any> {
    return http.postJSON(DepositEndpoints.processCheck(depositId), deposit);
}

// dev remove when complete
export function processCheckFail(depositId: string, deposit: Deposit): Promise<any> {
    return http.postJSON(DepositEndpoints.processCheckFail(depositId), deposit);
}

export function chargeFuelCard(r: FuelCardChargeRequest): Promise<FuelCardChargeResponse> {
    return http.postJSON("/api/v1/deposits/fuel-card", r);
}

export function chargeComdataFuelCard(r: ComdataFuelCardChargeRequest): Promise<ComdataFuelCardChargeResponse> {
    return http.postJSON("/api/v1/deposits/fuel-card", r);
}

export function preEdit(data: PreEditRequest): Promise<PreEditResponse> {
    return http.postJSON("/api/v1/deposits/fuel-card/pre-edit", data)
}

export function approveCheck(depositId: string, authNumber: string): Promise<any> {
    return http.postJSON(DepositEndpoints.approveCheck(depositId), { authNumber });
}

export function declineCheck(depositId: string, error: string): Promise<any> {
    return http.postJSON(DepositEndpoints.declineCheck(depositId), { error });
}

// Public Workflow

export function chargeFuelCardPublic(r: FuelCardChargeRequest): Promise<FuelCardChargeResponse> {
    return http.postJSON("/api/v1/public/deposits/fuel-card", r);
}

export function getDepositForInvoicePublic(token: string): Promise<any> {
    return http.get(PublicDepositEndpoints.getDepositForInvoiceByToken(token));
}

export function depositCreateOrReuseCheckPublic(token: string, checkType: string, deposit?: Partial<Deposit>): Promise<{}> {
    return http.postJSON(PublicDepositEndpoints.depositCreateOrReuseCheck(), { ...deposit, token, checkType });
}

export function processCheckPublic(depositId: string, deposit: Partial<Deposit>): Promise<any> {
    return http.postJSON(PublicDepositEndpoints.processCheck(depositId), deposit, false);
}

// eslint-disable-next-line max-params
export function depositCardPublic(
    invoiceToken: string,
    paymentToken: string,
    cardState = '',
    zipCode = '',
    license = '',
    billingFirstName = '',
    billingLastName = '',
    streetAddress = '',
    city = ''
): Promise<any> {
    return http.postJSON(
        PublicDepositEndpoints.depositCard(),
        { invoiceToken, paymentToken, cardState, zipCode, license, billingFirstName, billingLastName, streetAddress, city },
        false
    );
}

export async function depositLogs(companyId: string, fromDate?: string, toDate?: string): Promise<DepositItem[]> {
    return http.get(DepositEndpoints.depositLogs(companyId, fromDate, toDate));
}

export async function transactions(companyId: string, createdAtAfter?: string, createdAtBefore?: string): Promise<TransactionItem[]> {
    return http.get(DepositEndpoints.transactions(companyId, createdAtAfter, createdAtBefore));
}

export function preEditPublic(params: PreEditRequest): Promise<PreEditResponse> {
    return http.postJSON('/api/v1/public/deposits/fuel-card/pre-edit', params)
}

export function chargeComdataFuelCardPublic(params: ComdataFuelCardChargeRequest): Promise<ComdataFuelCardChargeResponse> {
    return http.postJSON("/api/v1/public/deposits/fuel-card", params);
}

export function setPublicFuelCardPaymentMethod(invoiceId: string, cardPaymentProcessor?: string) {
    return http.postJSON(PublicDepositEndpoints.setPublicFuelCardPaymentMethod(), { invoiceId, cardPaymentProcessor })
};

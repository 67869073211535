import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import SendDriverCheckNumberForm, { SendDriverCheckNumberFormData } from '../../../../components/invoice/SendDriverCheckNumber';
import { sendNotificationToPayer } from '../../../../actions/invoices';
import { PreparationSteps } from '../../../../constants/invoice';
import { Deposit } from '../../../../types/Deposit';
import { handleReduxFormError } from '../../../../services/app/forms';
import { PayerMessages } from '../../../../constants/payerMessages';
import { normalizePhoneOrEmail } from "../../../../services/app/normalizers";
import { Invoice } from '../../../../types/Invoice';
import { GlobalState } from '../../../../types/GlobalState';
import InvoicePreparationStepContainer from './InvoicePreparationStepContainer';
import { InvoicePreparationStepProps } from '../../../../types/InvoicePreparationStepProps';
import { Box } from '@material-ui/core';
import { NoCheckRefundsText } from '../../../../components/ui/NoCheckRefundsText';
import { isEmail } from '../../../../services/api/utils';

interface RouteParams {
  invoiceId: string;
}

type PropsFromState = Pick<GlobalState, "deposits" | "invoices">;

interface Props extends InvoicePreparationStepProps, PropsFromState, DispatchProp, RouteComponentProps<RouteParams> { }

class SendDriverCheckNumber extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(values: SendDriverCheckNumberFormData): Promise<void> {
    try {
      const useEmail = isEmail(values.phoneNumberOrEmail);
      const sendTo = normalizePhoneOrEmail(values.phoneNumberOrEmail)
      const { onCompletedStep, dispatch } = this.props;
      const deposit = this.getDeposit();
      await dispatch<any>(sendNotificationToPayer(
        this.getInvoiceId(),
        useEmail ? "" : sendTo,
        useEmail ? sendTo : "",
        PayerMessages.CHECK_DETAILS,
        deposit?.checkNumber,
        deposit?.checkType,
      ));
      onCompletedStep(PreparationSteps.LINE_ITEMS);
    } catch (e) {
      handleReduxFormError(e);
    }
  }

  getInvoice(): Invoice | undefined {
    const { invoices } = this.props;
    const invoiceId = this.getInvoiceId();
    const invoice = invoices?.data?.[invoiceId];
    return !invoice || invoice?.loading ? undefined : invoice;
  }

  getDeposit(): Deposit | undefined {
    const { deposits } = this.props;
    return deposits?.data?.[this.getInvoiceId()];
  }

  getInvoiceId(): string {
    const { match: { params: { invoiceId } } } = this.props;
    return invoiceId;
  }

  getInitialValues(): Partial<SendDriverCheckNumberFormData> {
    const invoice = this.getInvoice();
    return {
      phoneNumberOrEmail: invoice?.payerPhone || invoice?.payerEmail,
    }
  }

  render(): React.ReactElement {
    const { pageHeader, onPrevStep } = this.props;
    return (
      <InvoicePreparationStepContainer title={pageHeader}>
        <Box mb={2}><NoCheckRefundsText /></Box>
        <SendDriverCheckNumberForm
          onSubmit={this.handleSubmit}
          onPrevStep={onPrevStep}
          initialValues={this.getInitialValues()}
        />
      </InvoicePreparationStepContainer>
    );
  }
}

export const mapStateToProps = ({ deposits, invoices }: GlobalState): PropsFromState => ({ deposits, invoices });
export default withRouter(connect(mapStateToProps)(SendDriverCheckNumber));

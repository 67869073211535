import React from "react";
import { Button } from "./Button";
import { ButtonProps } from "./ButtonProps";
import { ButtonConfig } from "./ButtonConfig";

export const LargePrimaryButton: React.FC<ButtonProps> = (props: ButtonProps) => {
  const { children, ref, ...rest } = props;
  return (
    <Button size="large" color="primary" variant={ButtonConfig.Variant} {...rest}>
      {children}
    </Button>
  );
}

import React from "react";
import {WithInvoiceProps} from "../WithInvoiceProps";
import {WithChildrenProps} from "../WithChildrenProps";
import {canDownloadReceipt} from "../../../services/app/invoice";
import {IfUserRole} from "../Visibility";
import {Roles} from "../../../constants/roles";

interface Props extends WithInvoiceProps, WithChildrenProps {
}

const IfCanSendInvoiceReceipt: React.FC<Props> = (props: Props): React.ReactElement => {
  const {children, invoice} = props;
  const validRoles = [
    Roles.COMPANY_ADMIN, Roles.ACCOUNTANT, Roles.CLIENT_SUPPORT, Roles.SUPER_ADMIN,
    Roles.RS_IMPLEMENTATION, Roles.EMPLOYEE, Roles.RSEMPLOYEE
  ];
  return <>
    {canDownloadReceipt(invoice) && <>
      {!!invoice.deletedAt && <IfUserRole includes={[Roles.SUPER_ADMIN]}>{children}</IfUserRole>}
      {!invoice.deletedAt && <IfUserRole includes={validRoles}>{children}</IfUserRole>}
    </>}
  </>;
}

export default IfCanSendInvoiceReceipt;

import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { sendInvoiceForCompletion } from '../../../../actions/invoices';
import SendRemoteCheckout, { SendRemoteCheckoutFormData } from '../../../../components/invoice/SendRemoteCheckout';
import { InvoicePaths } from '../../../../services/app/paths';
import { normalizePhoneOrEmail } from "../../../../services/app/normalizers";
import { WithInvoiceProps } from '../../../../components/ui/WithInvoiceProps';
import { InvoicePreparationStepProps } from '../../../../types/InvoicePreparationStepProps';
import { Invoice } from '../../../../types/Invoice';
import InvoiceFinalizeContainer from '../../../../components/invoice/InvoiceFinalizeContainer';
import { Error } from '../../../../components/ui/Error';
import { GlobalState } from '../../../../types/GlobalState';
import { isEqual } from 'lodash';

interface RouteParams {
  invoiceId: string;
}

type PropsFromState = Pick<GlobalState, "deposits" | "invoices">;

interface Props extends WithInvoiceProps, InvoicePreparationStepProps, PropsFromState, DispatchProp, RouteComponentProps<RouteParams> {
}

interface State {
  error?: string;
  disableSubmitButton?: boolean;
}

class SendForRemoteCheckout extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {};
  }

  shouldComponentUpdate(nextProps, nextState): boolean {
    return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState)
  }

  async handleSubmit(values: SendRemoteCheckoutFormData): Promise<void> {
    try {
      const { history, dispatch } = this.props;
      const invoiceId = this.getInvoiceId();
      const phoneOrEmail = normalizePhoneOrEmail(values.phoneOrEmail);
      const email = phoneOrEmail?.includes("@") ? phoneOrEmail : undefined;
      const phone = email ? undefined : phoneOrEmail;
      this.setState({disableSubmitButton: true});  
      await dispatch<any>(sendInvoiceForCompletion(invoiceId, email, phone));
      history.push(InvoicePaths.listUrl());
    } catch (e) {
      this.setState({ error: (e as any)?.message });
    } finally {
      this.setState({disableSubmitButton: false})
    }
  }

  getInvoiceId(): string {
    return this.props.match.params.invoiceId;
  }

  getInvoice(): Invoice | undefined {
    const { invoices } = this.props;
    return invoices.data?.[this.getInvoiceId()];
  }

  getInitialValues(): Partial<SendRemoteCheckoutFormData> {
    const invoice = this.getInvoice();
    return { phoneOrEmail: invoice?.payerPhone || invoice?.payerEmail };
  }

  render(): React.ReactElement {
    const { onCompletedStep } = this.props;
    const { error, disableSubmitButton } = this.state;
    return (
      <InvoiceFinalizeContainer formName="sendRemoteCheckout" onCompletedStep={onCompletedStep} disableSubmitButton={disableSubmitButton}>
        <Error error={error} mt={2} mb={2} />
        <SendRemoteCheckout initialValues={this.getInitialValues()} onSubmit={this.handleSubmit} />
      </InvoiceFinalizeContainer>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({ deposits, invoices }): PropsFromState => ({ deposits, invoices });
export default withRouter(connect(mapStateToProps)(SendForRemoteCheckout));

import moment from "moment";

export interface InstantPayoutState {
    totalPayoutLimit?: string;
    expiresAt?: Date;
}

enum InstantPayoutActionType {
    UPDATE = 'instantpayout/UPDATE',
    EXPIRE = 'instantpayout/EXPIRE',
}

interface InstantPayoutAction {
    type: InstantPayoutActionType;
    totalPayoutLimit?: string;
    expiresAt?: Date;
}

export function instantPayoutReducer(state: InstantPayoutState = {}, action: InstantPayoutAction): InstantPayoutState {
    switch (action.type) {
        case InstantPayoutActionType.UPDATE:
            return { totalPayoutLimit: action.totalPayoutLimit, expiresAt: action.expiresAt };
        case InstantPayoutActionType.EXPIRE:
            return { ...state, expiresAt: new Date(0) };
        default:
            return state;
    }
}
export function expireInstantPayoutBalance(): InstantPayoutAction {
    return { type: InstantPayoutActionType.EXPIRE };
}

export function updateInstantPayoutBalance(totalPayoutLimit?: string, expirationInMinutes = 2): InstantPayoutAction {
    return {
        type: InstantPayoutActionType.UPDATE,
        totalPayoutLimit,
        expiresAt: moment().add(expirationInMinutes, 'm').toDate(),
    };
}

import { createStyles, IconButton, makeStyles, Theme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Dropzone, { DropzoneState } from "react-dropzone";
import Spinner from "../../../../components/app/Spinner";
import { Button } from "../../../../components/ui/Buttons";
import {
  P, Typography, ListItemText, Box, Divider, Grid, List, ListItem,
  ListSubheader, ListItemAvatar, ListItemSecondaryAction
} from "@roadsync/roadsync-ui";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { Error } from "../../../../components/ui/Error";

const useStyles = makeStyles((theme: Theme) => createStyles({
  dropzone: {
    border: `2px dashed ${theme.palette.grey[400]}`,
    padding: theme.spacing(2),
    width: "100%",
    '&:hover': {
      cursor: "pointer",
    },
  },
  attachmentItemIcon: {
    color: theme.palette.success.main,
  },
}));

interface Props {
  readOnly?: boolean;
  showDropZone?: boolean;
  isLoading?: boolean;
  files?: any[];
  error?: string; // not used
  getAttachmentUrl: (fileId: string) => Promise<string>;
  openAttachment: (attachmentUrl: string) => void;
  onDropRejected: () => void;
  onDrop: (files: any) => Promise<void>;
  handleRemoveExistingAttachment: (fileId: string) => void;
}

const AttachFileComponent: React.FC<Props> = (props: Props) => {
  const {
    readOnly, showDropZone, onDropRejected, onDrop, isLoading, getAttachmentUrl, openAttachment,
    handleRemoveExistingAttachment, files, error
  } = props;
  const classes = useStyles();
  const [attachmentsUrl, setAttachmentsUrl] = useState<string[] | null>(null);

  useEffect(() => {
    if (attachmentsUrl === null && !!files?.length && files.length > 0) {
      let urls: string[] = [];
      files?.map(async (file, i) => {
        const url = await getAttachmentUrl(file.fileId);
        if (url && url?.length > 0) {
          urls.push(url);
        }
      });
      setAttachmentsUrl(urls);
    }
  }, [attachmentsUrl, files, getAttachmentUrl]);

  return (
    <Grid container direction="column" wrap="nowrap" spacing={2}>
      {error && <Grid item><Error error={error} /></Grid>}
      {!readOnly && showDropZone &&
        <Grid item>
          <Box display="flex" justifyContent="center">
            <Dropzone onDropRejected={onDropRejected} onDrop={onDrop}>
              {(state: DropzoneState): JSX.Element => (
                <Box className={classes?.dropzone} id="fileFieldDropzone">
                  <Grid container {...state.getRootProps()} direction="column" alignItems="center" justifyContent="center" spacing={2}>
                    <input {...state.getInputProps()} id="fileField" />
                    <Grid item>
                      <P align="center">
                        We recommend adding photos of damaged cargo, broken parts, etc. to aid chargeback prevention.
                        We accept .png, .jpg, and .pdf files.
                      </P>
                    </Grid>
                    <Grid item>
                      <Button variant="outlined">Upload</Button>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Dropzone>
          </Box>
        </Grid>
      }
      <Grid item>
        {isLoading && <Spinner />}
        <List>
          {!!files?.length && <ListSubheader>Attachments</ListSubheader>}
          {files?.map((file, i) => {
            return (
              <React.Fragment key={file.id}>
                <ListItem key={file.id} button onClick={(): void => attachmentsUrl?.[i] ? openAttachment(attachmentsUrl[i]) : void (0)}>
                  <ListItemAvatar>
                    <CheckCircleIcon className={classes?.attachmentItemIcon} />
                  </ListItemAvatar>
                  <ListItemText>
                    <Typography noWrap variant="body1">{file.name}</Typography>
                  </ListItemText>
                  {!readOnly &&
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={(): void => handleRemoveExistingAttachment(file.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  }
                </ListItem>
                {i !== files.length - 1 && <Divider />}
              </React.Fragment>
            )
          })}
        </List>
      </Grid>
    </Grid>
  );
}

export default AttachFileComponent;

import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { change } from 'redux-form';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import PayerInfoForm, { PayerInfoFormData } from '../../../../components/invoice/PayerInfoForm';
import { updateInvoice, unsavedProgress, getInvoice } from '../../../../actions/invoices';
import Loader from '../../../../components/app/Loader';
import { PaymentMethods, PreparationSteps } from '../../../../constants/invoice';
import { Invoice } from '../../../../types/Invoice';
import { Location } from '../../../../types/Location';
import { handleReduxFormError } from '../../../../services/app/forms';
import { InvoicePaths } from '../../../../services/app/paths';
import { getPreparedInvoice } from '../../../../services/app/invoice';
import { Company } from '../../../../types/Company';
import { isAnyPaymentMethodExceptCheckEnabled } from '../../../../services/app/company';
import { getWorkOrder } from '../../../../actions/workOrders';
import { WorkOrder } from '../../../../types/WorkOrder';
import {
    getLocationsArrayFromSettings,
    getLocationsByIds,
    getPreselectedLocationValuesIfOnlyOneOptionAvailable,
    PreSelectedLocationValue
} from '../../../../services/app/location';
import { isClientSupport, isCompanyAdmin, isEmployee, isAccountant } from '../../../../services/app/auth';
import * as Query from 'query-string';
import { normalizeEmail, normalizePhone, normalizePhoneOrEmail } from '../../../../services/app/normalizers';
import { Shift } from '../../../../types/Shift';
import { Department } from '../../../../types/Department';
import { AppSettings } from '../../../../types/AppSettings';
import { InvoicePreparationStepProps } from '../../../../types/InvoicePreparationStepProps';
import InvoicePreparationStepContainer from './InvoicePreparationStepContainer';
import { GlobalState } from '../../../../types/GlobalState';
import { OnCompletedStepProps } from '../../../../types/OnCompletedStepProps';
import { createWorkOrderInvoice } from '../../../../services/api/workOrder';
import { createInvoice } from '../../../../services/api/invoices';
import { isEqual } from 'lodash';
import { getAttributes, isTreatmentOn } from '../../../../services/app/split';
import { FeatureFlag } from '../../../../components/featureFlag/FeatureFlag';
import { FullScreenLoader } from '@roadsync/roadsync-ui';
import { getProducts } from '../../../../actions/products';
import { App } from '../../../../constants/app';

interface State {
    shiftsList?: Shift[];
    departmentsList?: Department[];
    loading: boolean;
    submitting: boolean;
    isSplitAdvanceToCheckoutDirectPaymentsEnabled?: boolean;
}

interface RouteParams {
    invoiceId?: string;
}

type PropsFromState = Pick<
    GlobalState,
    'auth' | 'appSettings' | 'invoices' | 'locations' | 'departments' | 'companies' | 'workOrders' | 'shifts' | 'products'
>;

interface OwnProps {
    onPrevStep: () => void;
    previewInvoice?: boolean;
}

interface Props extends OwnProps, OnCompletedStepProps, PropsFromState, RouteComponentProps<RouteParams>, DispatchProp, InvoicePreparationStepProps {
}

class PayerInfo extends React.Component<Props, State> {

    private mounted = false;

    constructor(props: Props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.goToNextStep = this.goToNextStep.bind(this);
        this.onLocationChange = this.onLocationChange.bind(this);
        this.state = { loading: true, submitting: false };
    }

    async componentDidMount(): Promise<void> {
        this.mounted = true;
        const { dispatch, auth } = this.props;
        const company = this.getCompany();
        const attributes = getAttributes(auth?.me, company);
        const isSplitAdvanceToCheckoutDirectPaymentsEnabled = await isTreatmentOn(FeatureFlag.AdvanceToCheckoutDirectPayments, attributes);

        if (this.mounted) this.setState({ isSplitAdvanceToCheckoutDirectPaymentsEnabled });

        // Get the work order first, as it may include a location for a non-yet-created invoice
        const workOrderId = this.getWorkOrderId();
        if (this.getLocationId()) {
            await dispatch<any>(getProducts(this.getLocationId() as string, 0, App.GET_ALL))
        }

        if (workOrderId && !this.getWorkOrder()) await dispatch<any>(getWorkOrder(workOrderId));
        if (this.canSelectLocation()) await this.onLocationChange(undefined, this.getLocationId());
        if (this.mounted) this.setState({ loading: false });
    }

    componentWillUnmount(): void {
        this.mounted = false;
    }

    shouldComponentUpdate(nextProps, nextState): boolean {
        return !isEqual(this.props, nextProps) || !isEqual(this.state, nextState);
    }

    // eslint-disable-next-line max-statements
    getNextStep(locationId?: string): PreparationSteps {
        const { isSplitAdvanceToCheckoutDirectPaymentsEnabled } = this.state;
        const { products } = this.props;
        const location = this.getLocationById(locationId);
        var invoice = this.getInvoice();
        const company = this.getCompany();

        if (!location?.products?.length && !Object.keys(products?.data ?? {}).length) {
            return PreparationSteps.NO_PRODUCTS;
        }
        if (location?.customFields?.length) {
            return PreparationSteps.LOCATION_CUSTOM_FIELDS;
        }

        // 1. before creation we do not know the invoice type upfront - so we need to rely on split flag only -> redirect to LineItems.
        // 2. on invoice edit if the invoice.type = 'directpayment' -> redirect to LineItems.
        // 3. on invoice edit even if split flag is off, there will be a message to change the payment method.
        if (isSplitAdvanceToCheckoutDirectPaymentsEnabled && invoice?.type === PaymentMethods.DIRECT_PAYMENT.key) {
            return PreparationSteps.LINE_ITEMS;
        }

        if (invoice?.type) {
            return PreparationSteps.PAYMENT_METHOD_EXISTS;
        }
        if (!isAnyPaymentMethodExceptCheckEnabled(company)) {
            return PreparationSteps.SELECT_FLEET_CARD_TYPE;
        }

        return PreparationSteps.PAYMENT_METHOD;
    }

    goToNextStep(nextStep: PreparationSteps): void {
        const { onCompletedStep } = this.props;
        return onCompletedStep(nextStep);
    }

    getInvoiceData(values: PayerInfoFormData): Partial<Invoice & { companyId?: string }> {
        const { auth: { me } } = this.props;
        const { isSplitAdvanceToCheckoutDirectPaymentsEnabled } = this.state;
        const currentInvoice = this.getInvoice();
        const payerPhone = isSplitAdvanceToCheckoutDirectPaymentsEnabled ? normalizePhone(values.payerPhone) : normalizePhoneOrEmail(values.payerPhone);
        const payerEmail = isSplitAdvanceToCheckoutDirectPaymentsEnabled ? normalizeEmail(values.payerEmail) : normalizePhoneOrEmail(values.payerEmail);

        return {
            ...currentInvoice,
            ...values,
            companyId: me.companyId,
            location: values.locationId || this.getLocationId(),
            departmentId: values.departmentId || this.getDepartmentId(),
            shiftId: values.shiftId || this.getShiftId(),
            payerPhone,
            payerEmail,
            id: this.getInvoiceId(),
        };
    }

    // eslint-disable-next-line max-lines-per-function,max-statements,complexity
    async handleSubmit(values: PayerInfoFormData): Promise<void> {
        this.setState({ submitting: true });
        try {
            const { history, dispatch } = this.props;
            const workOrderId = this.getWorkOrderId();
            const nextStep = this.getNextStep(values?.locationId);
            const invoiceData: any = this.getInvoiceData(values);
            const invoiceToSave = getPreparedInvoice(invoiceData, invoiceData.lineItems);

            // If custom fields need to be set, continue without saving.
            // This is done because requirements for custom fields can be changed after the fact.
            // If user saves an invoice, then adds required custom fields after the fact, they would never
            // be able to save the invoice again, unless we skip attempting to save the invoice here.
            if (nextStep === PreparationSteps.LOCATION_CUSTOM_FIELDS) {
                await dispatch<any>(unsavedProgress(invoiceData));
                return this.goToNextStep(nextStep);
            }

            // If invoice exists, and there are no custom fields, always save it directly and continue.
            if (this.getInvoiceId()) {
                await dispatch<any>(updateInvoice(invoiceToSave));
                return this.goToNextStep(nextStep);
            }

            const result = await (workOrderId
                ? createWorkOrderInvoice(workOrderId, invoiceToSave)
                : createInvoice(invoiceData.companyId, invoiceToSave)
            );

            await dispatch<any>(getInvoice(result.id));
            history.replace(InvoicePaths.editUrl(result.id));
            this.goToNextStep(this.getNextStep(values?.locationId));
        } catch (e) {
            handleReduxFormError(e as any);
        }
        this.setState({ submitting: false });
    }

    canSelectLocation(): boolean {
        const { auth: { me } } = this.props;
        const userOkay = isCompanyAdmin(me) || isAccountant(me) || (isEmployee(me) && isClientSupport(me));
        return userOkay && !this.getInvoiceId();
    }

    getDepartmentsList(location?: Location): Department[] {
        const departments: Department[] = [];
        location?.departments?.forEach((d: Department | string) => {
            const departmentId = 'string' === typeof d ? d : d?.id;
            const department = departmentId ? this.getDepartmentById(departmentId) : undefined;
            if (department) {
                departments.push(department);
            }
        });
        return departments;
    }

    getDepartmentById(id: string): Department | undefined {
        return this.props.departments.data?.[id];
    }

    getDepartmentId(): string | undefined {
        const { appSettings: { defaults } } = this.props;
        const invoice = this.getInvoice();
        return invoice?.departmentId
            || (invoice?.department as any)?.id
            || invoice?.department
            || this.getPreselectedOptions()?.departmentId
            || defaults?.departmentId;
    }

    getDepartmentsByLocationId(locationId: string): void {
        const { dispatch } = this.props;
        const departmentsList = this.getDepartmentsList(this.getLocationById(locationId));
        const departmentId = departmentsList?.[0]?.id || this.getDepartmentId();
        dispatch<any>(change('payerInfo', 'departmentId', departmentId));
        if (this.mounted) this.setState({ departmentsList });
    }

    getLocationById(locationId?: string): Location | undefined {
        const { locations } = this.props;
        return locationId ? locations?.data?.[locationId] : undefined;
    }

    getShiftsList(locationId?: string): Shift[] {
        const { shifts } = this.props;
        return this.getLocationById(locationId)?.shifts?.map((id: any) => shifts.data?.[id]) as any || [];
    }

    getShiftsByLocationId(locationId: string): void {
        const { dispatch } = this.props;
        const shiftsList = this.getShiftsList(locationId);
        const shiftId = shiftsList?.[0]?.id || this.getShiftId();
        dispatch(change('payerInfo', 'shiftId', shiftId));
        if (this.mounted) this.setState({ shiftsList });
    }

    onLocationChange(event?: React.ChangeEvent<HTMLInputElement>, newValue?: string, previousValue?: string): void {
        if (newValue && newValue !== previousValue) {
            this.getShiftsByLocationId(newValue);
            this.getDepartmentsByLocationId(newValue);
        }
    }

    getWorkOrderId(): string | undefined {
        const { location: { search } } = this.props;
        const params = Query.parse(search);
        return 'string' === typeof params?.workOrderId ? params.workOrderId : undefined;
    }

    getWorkOrder(): WorkOrder | undefined {
        const workOrderId = this.getWorkOrderId();
        const { workOrders } = this.props;
        return workOrderId ? workOrders?.data?.[workOrderId] : undefined;
    }

    isWorkOrderLoading(): boolean {
        const workOrder = this.getWorkOrder();
        return !!this.getWorkOrderId() && (!workOrder || (workOrder as any)?.loading);
    }

    getInvoiceId(): string | undefined {
        const { match: { params: { invoiceId } } } = this.props;
        return invoiceId;
    }

    getInvoice(): Partial<Invoice> {
        const { invoices } = this.props;
        const invoiceId = this.getInvoiceId();
        const invoice = invoiceId ? invoices.data?.[invoiceId] : {};
        return { ...invoice, ...invoices.ui?.progress };
    }

    getPreselectedOptions(): PreSelectedLocationValue {
        const { appSettings: { settings }, auth: { me }, departments, locations, shifts } = this.props;
        return getPreselectedLocationValuesIfOnlyOneOptionAvailable(
            settings as AppSettings, locations, shifts, departments, me.companyId, me
        );
    }

    getLocationId(): string | undefined {
        const { appSettings: { defaults } } = this.props;
        const invoice = this.getInvoice();
        return (invoice?.location as Location)?.id
            || invoice?.location as string
            || invoice?.locationId
            || (this.getWorkOrder() as any)?.location?.id
            || this.getWorkOrder()?.locationId
            || this.getPreselectedOptions()?.locationId
            || defaults?.locationId;
    }

    getPayerName(): string | undefined {
        return this.getInvoice()?.payerName || this.getWorkOrder()?.signedCarrierName;
    }

    getPayerPhone(): string | undefined {
        return this.getInvoice()?.payerPhone || this.getWorkOrder()?.signedDriverPhone;
    }

    getPayerEmail(): string | undefined {
        return this.getInvoice()?.payerEmail || this.getWorkOrder()?.signedDriverEmail;
    }

    getShiftId(): string | undefined {
        const { appSettings: { defaults } } = this.props;
        const invoice = this.getInvoice();
        return (invoice?.shift as Shift)?.id
            || invoice?.shift as string
            || invoice?.shiftId
            || this.getPreselectedOptions()?.shiftId
            || defaults?.shiftId;
    }

    getLocationData(): Location[] {
        const { appSettings: { settings }, auth: { me }, locations } = this.props;
        return isEmployee(me) && isClientSupport(me)
            ? getLocationsByIds(locations, me.companyId, me.locationIds)
            : getLocationsArrayFromSettings(settings as AppSettings, locations, me.companyId);
    }

    getCompanyId(): string {
        const invoice = this.getInvoice();
        const { auth: { me } } = this.props;
        return 'string' === typeof invoice?.company
            ? invoice.company
            : invoice.company?.id || me.companyId;
    }

    getCompany(): Company | undefined {
        const { companies } = this.props;
        return companies?.data?.[this.getCompanyId()];
    }

    getInitialValues(): Partial<PayerInfoFormData> {
        const { appSettings: { defaults } } = this.props;
        const invoice = this.getInvoice();
        return {
            ...(defaults || {}),
            locationId: this.getLocationId(),
            payerName: this.getPayerName(),
            payerPhone: this.getPayerPhone(),
            payerEmail: this.getPayerEmail(),
            departmentId: this.getDepartmentId(),
            shiftId: this.getShiftId(),
            lineItems: invoice?.lineItems,
        };
    }

    isLoading(): boolean {
        const { appSettings: { loading } } = this.props;
        return this.isWorkOrderLoading() || !!loading || this.state.loading;
    }

    getPayerInfoName(): 'payerPhone' | 'payerEmail' {
        return this.getInitialValues().payerPhone ? 'payerPhone' : 'payerEmail';
    }

    render(): React.ReactElement {
        const { onPrevStep, auth: { me } } = this.props;
        const { departmentsList, shiftsList, isSplitAdvanceToCheckoutDirectPaymentsEnabled, submitting } = this.state;
        const showLocationOptions = this.canSelectLocation();
        if (this.isLoading()) {
            return <Loader />;
        }
        return (
            <InvoicePreparationStepContainer title={"Enter Customer's Information"}>
                <FullScreenLoader show={submitting} />
                <PayerInfoForm
                    companyId={me.companyId}
                    onSubmit={this.handleSubmit}
                    payerPhoneOrEmailFieldName={this.getPayerInfoName()}
                    initialValues={this.getInitialValues()}
                    locations={this.getLocationData()}
                    departments={departmentsList}
                    shifts={shiftsList}
                    showLocationOptions={showLocationOptions}
                    onLocationChange={this.onLocationChange}
                    onPrevStep={onPrevStep}
                    isSplitAdvanceToCheckoutDirectPaymentsEnabled={isSplitAdvanceToCheckoutDirectPaymentsEnabled}
                />
            </InvoicePreparationStepContainer>
        );
    }
}

/* istanbul ignore next */
const mapStateToProps = ({ auth, appSettings, invoices, locations, departments, shifts, companies, workOrders, products }: GlobalState): PropsFromState =>
    ({ auth, appSettings, invoices, locations, departments, shifts, companies, workOrders, products });
export default withRouter(connect(mapStateToProps)(PayerInfo));

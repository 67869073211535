import { Theme } from "@material-ui/core";

const styles = (theme: Theme): {} => ({
  invoiceSummary: {
    background: theme.palette.common.white,
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none',
      padding: theme.spacing(1),
    },
    '& [class*="Mui-disabled"]:disabled': {
      '& [class*="MuiAlert-standardSuccess-"]': {
        backgroundColor: "rgb(225, 225, 225)!important",
        color: '#9b9b9b!important',
      },
      '& [class*="MuiAlert-icon-"]': {
        color: '#9b9b9b',
      }
    }
  },
  invoiceSummaryMobileDirectionsSection: {
    textAlign: 'center',
  },
  invoiceSummaryMobileDirectionsSectionArrowDown: {
    background: theme.palette.primary.main,
    borderColor: theme.palette.secondary.main,
    borderRadius: '50%',
    borderStyle: "solid",
    borderWidth: theme.spacing(.5),
    boxSizing: 'border-box',
    height: theme.spacing(10),
    marginBottom: theme.spacing(1),
    width: theme.spacing(10),
  },
  invoiceSummaryTransactionLabel: {
    fontWeight: theme.typography.fontWeightBold,
  },
  invoiceSummaryPaymentMethodBox: {
    background: theme.palette.success.light,
    fontWeight: theme.typography.fontWeightBold,
  },
  invoiceSummaryEditBtn: {
    background: "rgba(0, 0, 0, .05)",
  },
  invoiceSummaryPaymentMethodLabel: {
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'uppercase',
  },
  list: {
    background: theme.palette.common.white,
  },
  convenienceFeeRow: {
    maxHeight: theme.spacing(4),
  },
  buttonRow: {
    '& button': {
      height: "100%",
    }
  },
  grandTotalGridTitle: {
    flexGrow: 0,
    maxWidth: '100%',
    flexBasis: '100%',
    [theme.breakpoints.up(490)]: {
      maxWidth: '40%',
      flexBasis: '40%'
    },
    [theme.breakpoints.up(520)]: {
      maxWidth: '43%',
      flexBasis: '43%'
    },
    [theme.breakpoints.up(570)]: {
      maxWidth: '50%',
      flexBasis: '50%'
    },
    [theme.breakpoints.up(961)]: {
      maxWidth: '100%',
      flexBasis: '100%'
    },
    [theme.breakpoints.up(1150)]: {
      maxWidth: '40%',
      flexBasis: '40%'
    },
    [theme.breakpoints.up(1200)]: {
      maxWidth: '44%',
      flexBasis: '44%'
    },
    [theme.breakpoints.up(1360)]: {
      maxWidth: '50%',
      flexBasis: '50%'
    }
  },
  grandTotalGridPrice: {
    paddingTop: 8,
    flexGrow: 0,
    maxWidth: '100%',
    flexBasis: '100%',
    [theme.breakpoints.up(490)]: {
      maxWidth: '60%',
      flexBasis: '60%',
    },
    [theme.breakpoints.up(520)]: {
      maxWidth: '57%',
      flexBasis: '57%',
    },
    [theme.breakpoints.up(570)]: {
      maxWidth: '50%',
      flexBasis: '50%'
    },
    [theme.breakpoints.up(961)]: {
      maxWidth: '100%',
      flexBasis: '100%'
    },
    [theme.breakpoints.up(1150)]: {
      maxWidth: '60%',
      flexBasis: '60%'
    },
    [theme.breakpoints.up(1200)]: {
      maxWidth: '56%',
      flexBasis: '56%'
    },
    [theme.breakpoints.up(1360)]: {
      maxWidth: '50%',
      flexBasis: '50%'
    }
  },
  grandTotalLabel: {
    fontSize: 16,
    fontWeight: 700,
    marginBottom: '18px',
    textAlign: 'right',
  },
  grandTotalAmount: {
    fontSize: 24,
    fontWeight: 400
  },
  grandTotalWarning: {
    fontSize: 14,
    lineHeight: 1.45,
    paddingTop: 8,
    paddingBottom: 8
  },
  grandTotalTitle: {
    fontSize: 24,
    fontWeight: 700
  },
  grandTotal: {
    fontSize: 24
  },
  directPaymentAlertDesktopOnly: {
    [theme.breakpoints.down(961)]: {
      display: 'none'
    }
  }
});

export default styles;

import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import CreateCustomerForm, { CreateCustomerFormData } from '../../components/bank/forms/CreateCustomerForm';
import { createCustomer } from '../../actions/bank';
import { showSuccessAlert } from '../../actions/alerts';
import ModalContent from '../../components/modals/ModalContent';
import { closeModal } from '../../actions/modals';
import { ModalsConstants } from '../../constants/modals';
import { logout } from '../../actions/auth';
import { Error } from '../../components/ui/Error';
import { GlobalState } from '../../types/GlobalState';

interface State {
  error?: string;
}

type PropsFromState = Pick<GlobalState, "auth" | "users">;

interface OwnProps {
  companyId: string;
}

interface Props extends PropsFromState, DispatchProp, OwnProps { }

class TermsAndConditionsModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.state = {};
  }

  handleLogout(): void {
    const { dispatch } = this.props;
    dispatch<any>(logout());
    dispatch(closeModal(ModalsConstants.TERMS_AND_CONDITIONS));
  }

  handleSubmit(values: CreateCustomerFormData): void {
    const { companyId, dispatch } = this.props;
    dispatch<any>(createCustomer(companyId, values.firstName, values.lastName, values.email))
      .then(() => {
        dispatch<any>(showSuccessAlert('Your request successfully accepted'));
        dispatch(closeModal(ModalsConstants.TERMS_AND_CONDITIONS));
      })
      .catch((error) =>
        this.setState({ error: error.message ? error.message : 'Something went wrong. Please try again later.' })
      );
  }
  // eslint-disable-next-line max-lines-per-function
  render(): React.ReactElement {
    const { error } = this.state;
    const link = 'https://s3.amazonaws.com/legal.roadsync.com/policy/roadsync_checkout_terms_of_service.pdf';
    return (
      <ModalContent className="terms-and-conditions-modal">
        <p className="modal-title mb-50">Terms &amp; Conditions</p>
        <Error error={error} />
        <p className="content-info-message-small">
          In order to provide secure payments, RoadSync has partnered with Dwolla for bank account
          verification and payment servicing.
          Please find their Terms of Service and Privacy Policy
          <a className="link" href="//dwolla.com/legal/tos/" target="_blank" rel="noopener noreferrer"> here </a>
          and
          <a className="link" href="//dwolla.com/legal/privacy/" target="_blank" rel="noopener noreferrer"> here </a>.
        </p>
        <p className="content-info-message-small mb-10-sm-m mb-69-sm-t">
          Additionally, use of our Services requires acceptance of our Services Agreement which can be found
          <a className="link" href={link} target="_blank" rel="noopener noreferrer"> here </a>.
          By clicking "I Accept" below, you represent that you have the legal authority to accept these Terms.
        </p>
        <CreateCustomerForm onSubmit={this.handleSubmit} />
        <button className="btn-border-yellow mr-10" onClick={this.handleLogout} type="button">
          Logout
        </button>
        <div className="clear" />
      </ModalContent>
    );
  }
}

const mapStateToProps = ({ auth, users }: GlobalState): PropsFromState => ({ auth, users });
export default connect(mapStateToProps)(TermsAndConditionsModal);

import { Button } from '@roadsync/roadsync-ui';
import React from 'react';

const SignupHelp: React.FC = (): React.ReactElement => (
  <div className="text-center mt-20">
    <div className="newpos-title-small">
      Are you a commercial driver?
    </div>
    <div className="fs-16">
      <Button
        variant="text" href="https://roadsync.com/roadsync-driver-app"
        style={{ textDecoration: "underline" }}
        target="_blank"
      >
        Download our driver app to pay RoadSync invoices and access receipts.
      </Button>
    </div>
  </div>
);

export default SignupHelp;

import React from 'react';
import { ButtonProps } from './ButtonProps';
import { ButtonRouter } from './ButtonRouter';

export class Button extends React.Component<ButtonProps> {
  render(): React.ReactElement {
    const { children, ref, ...rest } = this.props;
    return <ButtonRouter {...rest}>{children}</ButtonRouter>;
  }
}

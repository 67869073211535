import _ from 'lodash';

interface Type {
  key: string;
  display: string;
}

export class CustomFieldType {

  static readonly INPUT: Type = {
    key: 'input',
    display: 'Text field',
  };

  static readonly PHONE: Type = {
    key: 'phone',
    display: 'Phone number',
  };

  static readonly EMAIL: Type = {
    key: 'email',
    display: 'Email',
  };

  static readonly SELECT: Type = {
    key: 'select',
    display: 'Dropdown',
  };

  static readonly NUMERIC: Type = {
    key: 'numeric',
    display: 'Number',
  };

  static readonly DECIMAL: Type = {
    key: 'decimal',
    display: 'Decimal',
  };

  private static readonly LOOKUP = {
    'input': CustomFieldType.INPUT,
    'phone': CustomFieldType.PHONE,
    'email': CustomFieldType.EMAIL,
    'select': CustomFieldType.SELECT,
    'numeric': CustomFieldType.NUMERIC,
    'decimal': CustomFieldType.DECIMAL,
  };

  static getTypes(): Type[] {
    return _.values(CustomFieldType.LOOKUP);
  }

  static getByKey(key: string): Type {
    return CustomFieldType.LOOKUP[key];
  }
}

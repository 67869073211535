import React from "react";
import _ from 'lodash';
import { Company } from '../types/Company';
import DoneIcon from '@material-ui/icons/Done';
import SendIcon from '@material-ui/icons/Send';
import NewIcon from '@material-ui/icons/NewReleases';
import HelpIcon from '@material-ui/icons/Help';

export interface WorkOrderStatusType {
  key: string;
  display: string;
  description: string;
  icon: React.ReactNode;
}

export const getRemainingWorkOrderStatuses = (statuses: WorkOrderStatusType[]): WorkOrderStatusType[] =>
  WorkOrderStatus.getTypes().filter(s => !statuses.includes(s));

export class WorkOrderStatus {
  static readonly UNKNOWN: WorkOrderStatusType = {
    key: 'unknown',
    display: 'Unknown',
    description: ' ',
    icon: <HelpIcon />,
  };

  static readonly NEW: WorkOrderStatusType = {
    key: 'new',
    display: 'New',
    description: 'Work Order has not been sent to your customer',
    icon: <NewIcon />,
  };

  static readonly SENT: WorkOrderStatusType = {
    key: 'sent',
    display: 'Sent',
    description: 'Work Order has been sent to your customer, but not signed yet',
    icon: <SendIcon />,
  };

  static readonly COMPLETED: WorkOrderStatusType = {
    key: 'completed',
    display: 'Completed',
    description: 'Work Order has been signed by your customer',
    icon: <DoneIcon />,
  };

  private static readonly LOOKUP = {
    'unknown': WorkOrderStatus.UNKNOWN,
    'new': WorkOrderStatus.NEW,
    'sent': WorkOrderStatus.SENT,
    'completed': WorkOrderStatus.COMPLETED,
  };

  static getTypes(): WorkOrderStatusType[] {
    return _.values(WorkOrderStatus.LOOKUP);
  }

  static getByKey(key: string): WorkOrderStatusType {
    return WorkOrderStatus.LOOKUP[key];
  }
}

export const workOrderText = '*PLEASE SIGN THIS WORK AUTHORIZATION AND RETURN AS SOON AS POSSIBLE.*\n' +
  '*A TECHNICIAN WILL BE DISPATCHED IMMEDIATELY UPON RECEIPT.*\n\n' +

  '*WORK ORDER AUTHORIZATION:*\n' +

  'Authorization to perform Maintenance and Repair Services on trucks, trailers, bus, RV and auto.\n\n' +

  '[company name] is a repair company whose purpose is to respond to emergency breakdowns of vehicles.\n' +

  '[company name] will attempt to get our customers back on the road as quickly as possible. ' +
  'Please understand that not all vehicles can be repaired via roadside service and may need to ' +
  'be taken to a shop for further diagnosis. Also note that there will be no guarantees or ' +
  'warranties given on any roadside service. There will be absolutely no refunds on services completed.\n\n' +

  'I authorize [company name] to perform the repair work described by a [company name] employee in a written or ' +
  'verbalized estimate utilizing necessary labor, parts and materials. I agree that [company name] ' +
  'is not responsible for loss or damage to the vehicle, or articles left in the vehicle, ' +
  'in case of theft, fire, or any other cause beyond their control. I agree that [company name] ' +
  'is not responsible for delays caused by unavailability of parts, or delay in parts shipments ' +
  'by the supplier or transporter. I grant [company name] employees permission to operate my vehicle ' +
  'for the purpose of testing and/or inspection. I understand that, if any closer analysis finds ' +
  'additional labor, parts or materials are necessary to complete the repair, ' +
  'I will be contacted for authorization.\n\n' +

  'I acknowledge that this work authorization approved supplemental estimates. ' +
  'I agree that if I should halt repairs for any reason, I will be responsible for the cost of ' +
  'any and all repairs completed to that point, as well as the cost of the parts which are not ' +
  'returnable, or restocking fees charged to [company name], if I choose not to purchase said parts ' +
  'outright. I am entitled to retain any parts I pay for that are not returnable to their vendors. \n\n' +

  '*SERVICE RATES*\n' +
  '\tTruck rate:\n' +
  '\t\tCallout fee: $95.00\n' +
  '\t\tHourly Rate (2 hour minimum): $98.50\n' +
  '\tBus / RV rate:\n' +
  '\t\tCallout fee: $95.00\n' +
  '\t\tHourly Rate (2 hour minimum): $150\n' +
  '\t\tShop Expense: $10\n' +
  '\n\n*ADDITIONAL FEES*\n' +
  '\t3.5% CONVENIENCE FEE ON ALL CREDIT CARD TRANSACTIONS\n' +
  '\t3% CHECK CONVENIENCE FEE\n\n' +

  '*CREDIT CARD AUTHORIZATION*\n' +
  'If paying by credit card, your signature on this form gives [company name] permission to debit ' +
  'your credit card account, on or after the indicated date, for the agreed upon services according ' +
  'to the terms outlined on this Work Order. This payment authorization is for the goods/services ' +
  'described herein on the indicated date. By signing this form, you certify that you are an ' +
  'authorized user of this credit card and that you will not dispute the payment with your credit ' +
  'card company, as long as the transaction corresponds to the terms indicated on this agreement. ' +
  'Please note that the credit card must bear the name of your company, or if a personal credit ' +
  'card, must match the name on your ID; proof of ID will be requested onsite.\n\n' +

  'Please note that the charge may show up on your credit card statement as ROADSYNC, our payments partner.\n\n' +

  'Thank you for giving [company name] the opportunity to serve you. We appreciate your business!\n\n' +

  'Your satisfaction is important to us. If, for any reason, you are not satisfied ' +
  'with the services you receive, please contact [company name] immediately at [company phone].\n';

export function getDefaultWorkOrderText(company?: Company): string {
  let text = workOrderText;
  if (company?.name) {
    text = text.replace(/\[company name\]/gi, company?.name);
  }
  if (company?.phone) {
    text = text.replace(/\[company phone\]/gi, company?.phone);
  }
  return text;
}

import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { H3, Container, Grid } from '@roadsync/roadsync-ui';
import InvoicePrepButtonRow from "../../../../components/invoice/fullScreenStepper/InvoicePrepButtonRow";
import NoPrintContainer from "../../../../components/ui/visibility/NoPrintContainer";

interface Props {
  title?: string;
  children?: React.ReactNode;
  primaryBtn?: React.ReactNode;
  secondaryBtn?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

const InvoicePreparationStepContainer: React.FC<Props> = (props: Props): React.ReactElement => {
  const { title, children, primaryBtn, secondaryBtn } = props;
  const classes = useStyles();
  return (
    <Container maxWidth="sm" className={classes.root}>
      <Grid container direction="column" spacing={2}>
        {title &&
          <Grid item>
            <NoPrintContainer>
              <H3 gutterBottom>{title}</H3>
            </NoPrintContainer>
          </Grid>
        }
        {children && <Grid item>{children}</Grid>}
        {(primaryBtn || secondaryBtn) &&
          <Grid item>
            <InvoicePrepButtonRow secondaryBtn={secondaryBtn}>{primaryBtn}</InvoicePrepButtonRow>
          </Grid>
        }
      </Grid>
    </Container>
  );
}

export default InvoicePreparationStepContainer;

import { ErrorCodes } from '../../constants/errorCodes';

export function getStandardErrorMessage(error): string {
  // eslint-disable-next-line no-mixed-operators
  return ErrorCodes.getByKey(error.status) && ErrorCodes.getByKey(error.status).message || error.message;
}

export function getLoginErrorMessage(error): string {
  switch (error.status) {
    case 400:
      return 'Two factor Authentication Required';
    case 401:
      return 'Incorrect Username or Password';
    case 423:
      return 'This account has been locked. Please contact customer support.';
    case 504:
      return 'RoadSync is experiencing technical difficulties, please try again later.';
    default:
      if (error.message) {
        return error.message;
      }
      return 'Incorrect Username or Password';
  }
}

export function getAppSettingsErrorMessage(error): string {
  switch (error.status) {
    case 400:
      return 'Incorrect token provided or token expired';
    case 404:
      return 'User for provided token not found.';
    default:
      if (error.message) {
        return error.message;
      }
      return 'Incorrect token provided or token expired';
  }
}

import { CheckboxState } from "../../constants/common";

export const normalizePhone = (value?: string | null): string => {
  return value?.toString().replace(/^(\()|(\))|(-)|(\s+)/g, "") || "";
};

export const normalizePhoneOrEmail = (value?: string | null): string => {
  if (value && String(value).indexOf("@") >= 0) {
    return value;
  }
  return normalizePhone(value);
};

export const normalizeEmail = (value?: string): string => {
  const last = value?.charAt(value.length - 1);
  if (value && String(value).indexOf("@") >= 0 && String(value).indexOf(".") >= 0 && last !== ".") {
    return value?.toLowerCase();
  }
  return "";
};

export const normalizeCheckbox = (value?: string | boolean): boolean => {
  return value === true || String(value) === CheckboxState.CHECKED.key;
};

export const normalizeUsername = (value?: string): string => {
  return value?.replace(/\s/g, "") || "";
};

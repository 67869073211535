import React from "react";
import PasswordRequirement from "./PasswordRequirement";

export interface Props {
  value: string;
}

const PasswordRequirementGroup: React.FC<Props> = (props: Props): React.ReactElement => {
  return (
    <ul style={{ marginTop: "10px", display: "flex", flexDirection: "column", alignItems: "start" }}>
      <PasswordRequirement value={props.value} regex={/^(?=.{8,})/} message="Must be at least 8 characters long" />
      <PasswordRequirement value={props.value} regex={/^(?=.*[A-Z])/} message="At least 1 uppercase letter" />
      <PasswordRequirement value={props.value} regex={/^(?=.*[0-9])/} message="At least 1 number" />
      <PasswordRequirement value={props.value} regex={/^(?=.*[!@#$%^&*])/} message="At least 1 special character (!#%^&*)" />
      <PasswordRequirement value={props.value} regex={/^(?=.*[a-z])/} message="At least 1 lowercase letter" />
    </ul>
  );
};

export default PasswordRequirementGroup;

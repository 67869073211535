import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles((theme: Theme) => createStyles({
  hiddenBtn: {
    height: 0,
    width: 0,
    padding: 0,
    border: 0,
    visibility: 'hidden',
    opacity: 0,
    position: 'absolute',
    left: '-9999px'
  },
}));

export default styles;

import moment from 'moment';

// eslint-disable-next-line max-statements
export const formatPhone = (value = '', previous?: string): string => {
  if (!value || !value.replace) {
    return value;
  }

  let prefix = '';
  if (value[0] === '+') {
    value = value.substr(1);
    prefix = '+';
  }

  let phone = formatNumbersOnly(value);
  if (previous && previous.length === 5 && value.length === 4) {
    phone = phone.substring(0, phone.length - 1);
  }
  return prefix + phone;
}

export function forceValueToString(value: any): string {
  return (value || "").toString().trim();
}

export const displayFormattedPhoneNumber = (value: string): string => {
  return forceValueToString(value).replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
}

// 1234567890 -> (123) 456-7890
export const displayFormattedUsPhoneNumber = (value: string): string => {
  const cleaned = ('' + value).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return value;
}

export const formatNumbersOnly = (value = ''): string => {
  return forceValueToString(value).replace(/[^0-9]+/g, '');
}

export const formatLettersOnly = (value = ''): string => {
  return forceValueToString(value).replace(/[^A-Za-z]+/g, '');
}

/**
 * @param value Any value
 * @returns The value, formatted as dollars and cents.
 */
export const formatMoneyOnly = (value = ''): string => {
  const newValue = forceValueToString(value).replace(/[^0-9.]+/g, '');
  const sections = newValue.split('.');

  if (sections[0] !== '0' && sections[0] !== '00') {
    sections[0] = sections[0].replace(/^0+/, '');
  } else {
    sections[0] = '0';
  }
  if (sections[1]) {
    return sections[0] + '.' + sections[1].slice(0, 2);
  }
  if (newValue.indexOf('.') !== -1) {
    return sections[0] + '.';
  }
  return sections[0];
}

export const toFixed = (value?: string | number): string => {
  const val = parseFloat(forceValueToString(value).replace(/[^0-9.]+/g, ''));
  return (Number.isNaN(val) ? 0 : val).toFixed(2).replace(/\.00$/, "");
}

export const formatPercentage = (value?: string | number, fractionDigits: number = 5, allowTrailingDecimal = true): string => {
  const val = forceValueToString(value).replace(/[^0-9.]+/g, '');
  if (!val.length) {
    return "";
  }

  const hasDecimal = val.includes('.');
  const decimal = (val.match(/\.[0-9]+$/)?.[0] ?? "").replace(".", "").substring(0, fractionDigits);
  let num = parseInt(val);
  if (Number.isNaN(num)) {
    num = 0;
  }

  let result = num.toString() + (hasDecimal ? "." : "") + decimal;
  if (result.endsWith('.') && !allowTrailingDecimal) {
    result = result.replace('.', '');
  }
  return result;
}

export const formatZipCode = (value: string): string => {
  const newValue = formatNumbersOnly(value);
  return newValue.substring(0, 5);
}

export const formatDollar = (amount?: string | number): string => {
  const n = parseFloat(amount?.toString() || "0");
  const val = (isNaN(n) ? 0 : n).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `$${val}`;
}

export const maxLength = (max: number) => (value?: string): string | undefined =>
  value && value.length <= max ? undefined : `Must be ${max} characters or less`;

export const formatNullDate = (date?: string): string | null => {
  if (!date) {
    return null;
  }
  const nullYear = '0001';
  const isNullYear = date.indexOf(nullYear);

  if (isNullYear === -1) {
    return moment(date).format('lll z');
  }
  return null;
}

export const validateMoney = (amt: string | number = '0', min?: number | string, max?: number | string): string => {
  let val = Number(amt);
  val = max ? Math.min(Number(max), val) : val;
  val = min ? Math.max(Number(min), val) : val;

  return val.toFixed(2);
}

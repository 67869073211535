import { Theme } from "@material-ui/core";

const styles = (theme: Theme): {} => ({
  gridGrandTotalWarning: {
    fontSize: 14,
    lineHeight: 1.45,
    paddingTop: 8,
    paddingBottom: 8
  },
  gridGrandTotalTitle: {
    fontSize: 24,
    fontWeight: 700
  },
  gridGrandTotalAmount: {
    fontSize: 24,
    fontWeight: 400
  },
});

export default styles;

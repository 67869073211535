import { ProductEndpoints } from './endpoints';
import { Product } from '../../types/Product';
import * as http from './http';

export interface FileSaveResponse {
    fileId: string;
    fileName?: string;
    productId: string;
    url: string;
}

export interface GetFileResponse {
    id: string;
    url: string;
}

// eslint-disable-next-line max-params
export function getProducts(locationId: string, offset: number, limit: number, type?: string): Promise<{}> {
    return http.get(ProductEndpoints.getProductList(locationId, offset, limit, type));
}

export function getProductWithoutTaxes(locationId: string, offset: number, limit: number): Promise<{}> {
    return http.get(ProductEndpoints.getProductListWithoutTaxes(locationId, offset, limit));
}

export function getProductById(productId: string): Promise<{}> {
    return http.get(ProductEndpoints.getProductById(productId));
}

export function createProduct(locationId: string, product: Partial<Product>): Promise<Product> {
    return http.postJSON(ProductEndpoints.createProduct(locationId), product);
}

export function updateProduct(product: Product): Promise<Product> {
    return http.putJSON(ProductEndpoints.updateProduct(product.id), product);
}

export function deleteProduct(productId: string): Promise<void> {
    return http.del(ProductEndpoints.deleteProduct(productId));
}

export function addImageToProduct(productId: string, imageBlob: any, imageName: string, mimeFileType: string): Promise<FileSaveResponse> {
    var headers = new Headers();
    headers.append('Content-Type', mimeFileType);
    return http.post<FileSaveResponse>(ProductEndpoints.addImage(productId, imageName), imageBlob, true, headers);
}

export function updateProductWeight(locationId: string, productIds: string[]): Promise<any> {
    return http.putJSON(ProductEndpoints.updateProductWeight(locationId), { productIds });
}

export interface GetFileResponse {
    id: string;
    url: string;
}

export function getFileById(fileId: string): Promise<GetFileResponse> {
    return http.get<GetFileResponse>(ProductEndpoints.retrieveFile(fileId));
}

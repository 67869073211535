import React from 'react';
import InvoiceCommentsForm, { InvoiceCommentsFormData } from '../../../../components/invoice/InvoiceCommentsForm';
import { Invoice } from '../../../../types';

interface Props {
  onChange?: (values: Partial<InvoiceCommentsFormData>) => void;
  initialValues: Invoice;
}

const LineItemsCommentsSection: React.FC<Props> = (props: Props) => {
  const { initialValues, onChange } = props;
  return (
    <InvoiceCommentsForm initialValues={{ comments: initialValues.comments }} onChange={onChange} />
  );
};

export default LineItemsCommentsSection;

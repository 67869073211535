import { FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Radio, RadioGroup } from '@material-ui/core';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { InvoiceDeleteReasons, InvoiceStatuses, PaymentMethods } from '../../constants/invoice';
import { formatDollar } from '../../services/app/formats';
import { Invoice } from '../../types/Invoice';
import { isPartialRefundEnabled } from '../../services/app/company';
import { Button, SecondaryButton } from '../ui/Buttons';
import { RenderSelectField } from '../ui/form/RenderSelectField';
import { MoneyInput } from '../ui/MoneyInput';
import { P } from '@roadsync/roadsync-ui';
import { moneyValueInRange } from '../../services/app/validators';
import { Company } from '../../types';

interface Props {
  invoice: Invoice;
  company: Company | undefined;
  onSubmit: (values: RefundInvoiceFormData) => void;
  handleCancel?: () => void;
}

enum RefundType {
  FullRefund = "fullRefund",
  PartialRefund = "partialRefund",
}

export interface RefundInvoiceFormData {
  refundReason: string;
  refundType: string;
  refundAmount?: string;
}

export class RefundInvoiceForm extends React.Component<Props>{

  isRefundAmountDisabled(formValues?: RefundInvoiceFormData): boolean {
    return formValues?.refundType === RefundType.FullRefund;
  }

  showRefundReason(formValues?: RefundInvoiceFormData): boolean {
    return formValues?.refundType !== RefundType.PartialRefund;
  }

  getCurrentInvoiceAmount(): string {
    const { invoice } = this.props;
    let currentAmount = Number(invoice.amount);
    if (invoice.refunds) {
      invoice.refunds.forEach(element => {
        currentAmount = currentAmount - Number(element.amount);
      });
    }
    return (Math.round(currentAmount * 100) / 100).toFixed(2).toString();
  }

  isComplete(): boolean {
    const { invoice } = this.props;
    return invoice?.status === InvoiceStatuses.COMPLETED.key;
  }

  canPartiallyRefundInvoice(): boolean {
    const { invoice, company} = this.props;
    const partialRefundsEnabled = isPartialRefundEnabled(company);
    return invoice.type === PaymentMethods.CARD.key && this.isComplete() && partialRefundsEnabled;
  }

  getInitialValues(): Partial<RefundInvoiceFormData> {
    return {
      refundType: RefundType.FullRefund,
      refundReason: InvoiceDeleteReasons.BY_CUSTOMER.key,
    };
  }

  // eslint-disable-next-line
  render(): React.ReactElement {
    const { handleCancel, onSubmit } = this.props;
    const invoiceAmount = this.getCurrentInvoiceAmount();
    return (
      <Form
        onSubmit={onSubmit}
        initialValues={this.getInitialValues()}
        // eslint-disable-next-line
        render={({ handleSubmit, values, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2} wrap="nowrap">
                {this.canPartiallyRefundInvoice() &&
                  <>
                    <Grid item>
                      <Field name="refundType">
                        {({ input }: any) => (
                          <FormControl fullWidth margin="none">
                            <FormLabel>Refund Type</FormLabel>
                            <RadioGroup aria-label="refundType" {...input}>
                              <FormControlLabel value={RefundType.FullRefund} control={<Radio />} label="Full Refund" />
                              <FormControlLabel value={RefundType.PartialRefund} control={<Radio />} label="Partial Refund" />
                            </RadioGroup>
                          </FormControl>
                        )}
                      </Field>
                    </Grid>
                    {!this.isRefundAmountDisabled(values) &&
                      <>
                        <Grid item>
                          <P variant="body2" gutterBottom>How much would you like to refund?</P>
                        </Grid>
                        <Grid item>
                          <Field
                            name="refundAmount"
                            label="Custom Amount"
                            defaultValue={invoiceAmount}
                            component={MoneyInput}
                            validate={(value) => moneyValueInRange(0.01, parseFloat(invoiceAmount), value)}
                            helperText={`Invoice amount remaining: ${formatDollar(invoiceAmount)}`}
                          />
                        </Grid>
                      </>
                    }
                  </>
                }
              {this.showRefundReason(values) &&
                <Grid item>
                  <Field name="refundReason" label="Refund Reason">
                    {(props: any) => (
                      <RenderSelectField {...props}>
                        {InvoiceDeleteReasons.getTypes(true).map((o) => (
                          <MenuItem key={o.key} value={o.key}>{o.display}</MenuItem>
                        ))}
                      </RenderSelectField>
                    )}
                  </Field>
                </Grid>
              }
              <Grid item>
                <Grid container spacing={2} alignItems="center" justifyContent="flex-end">
                  {handleCancel &&
                    <Grid item>
                      <Button variant="text" id="refund-cancel-btn" onClick={handleCancel} disabled={submitting} >
                        Cancel
                      </Button>
                    </Grid>
                  }
                  <Grid item>
                    <SecondaryButton type="submit" id="refund-submit-btn" disabled={submitting} autoFocus>
                      {this.isComplete() ? "Refund" : "Void"} Invoice
                    </SecondaryButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      />
    );
  }
}

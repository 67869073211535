import React, { ChangeEventHandler, useState } from 'react';
import { reduxForm, Field, InjectedFormProps, FormErrors } from 'redux-form';
import Input from '../controls/Input';
import { isValidPassword, required } from '../../services/app/validators';
import { CheckboxState } from '../../constants/common';
import RadioButtonGroup from '../controls/RadioButtonGroup';
import { Error } from '../ui/Error';
import PasswordRequirementGroup from './PasswordRequirementGroup';

export type SetPasswordFormData = {
  password: string;
  confirmPassword: string;
  sendNotificationEmail: string;
  generateRandomPassword: string;
  firstPasswordReset: string;
};

type FormProps = {
  isGeneratePassword?: string;
  updateGeneratePassword: ChangeEventHandler<HTMLInputElement>;
  isDisableEmailsEnabled?: boolean;
};

type InjectedProps = InjectedFormProps<SetPasswordFormData, FormProps>;

const YesNoCheckbox = (field) => <RadioButtonGroup fieldKey="key" fieldDisplay="display" options={CheckboxState.getStates()} {...field} />;

type Props = InjectedProps & FormProps;
// eslint-disable-next-line max-lines-per-function
const SetPasswordForm: React.FC<Props> = (props: Props) => {
  const { error, handleSubmit, isGeneratePassword, updateGeneratePassword, isDisableEmailsEnabled } = props;
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const disableGeneratePassword: boolean = isDisableEmailsEnabled || isGeneratePassword === CheckboxState.CHECKED.key;
  return (
    <form onSubmit={handleSubmit}>
      {isGeneratePassword === CheckboxState.UNCHECKED.key && (
        <Field
          name="password"
          label="New password"
          type="password"
          component={Input}
          validate={required}
          ComponentWhenActive={PasswordRequirementGroup({ value: password })}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(e.target.value);
          }}
        />
      )}

      {isGeneratePassword === CheckboxState.UNCHECKED.key && (
        <Field
          name="confirmPassword"
          label="Confirm password"
          type="password"
          component={Input}
          validate={required}
          ComponentWhenActive={PasswordRequirementGroup({ value: confirmPassword })}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setConfirmPassword(e.target.value);
          }}
        />
      )}

      <Field
        label="Send email notification with new password"
        name="sendNotificationEmail"
        component={YesNoCheckbox}
        validate={required}
        disabled={disableGeneratePassword}
      />

      <Field
        label="Generate random password"
        name="generateRandomPassword"
        component={YesNoCheckbox}
        validate={required}
        onChange={updateGeneratePassword}
        disabled={isDisableEmailsEnabled}
      />

      <Field label="Reset password after login" name="firstPasswordReset" component={YesNoCheckbox} validate={required} />

      <Error error={error} />
    </form>
  );
};

export const validate = (values: SetPasswordFormData): FormErrors<SetPasswordFormData> => {
  const errors: FormErrors<SetPasswordFormData> = {};

  if (values.confirmPassword !== values.password) {
    errors.confirmPassword = "Password mismatched";
  }

  if (!values.password || !isValidPassword(values.password)) {
    errors.password = "Password not valid";
  }

  if (!values.confirmPassword || !isValidPassword(values.confirmPassword)) {
    errors.confirmPassword = "Password not valid";
  }


  return errors;
};

export default reduxForm<SetPasswordFormData, FormProps>({
  form: "setPassword",
  validate,
})(SetPasswordForm);

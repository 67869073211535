import { Grid } from "@material-ui/core";
import React from "react";
import ToggleButton from '../../components/app/ToggleButton';
import { getMIUIGridScale, ProductGridType } from "../../types/UserInterface";
import { ProductSelectorProps } from "./ProductSelector";

// eslint-disable-next-line
const ProductTypeTaxSelector: React.FC<ProductSelectorProps> = (props: ProductSelectorProps) => {
  const { product, onToggle, selected, gridType } = props;
  const isSmallGrid = gridType === ProductGridType.SmallGrid;
  const isList = gridType === ProductGridType.List;
  return (
    <Grid item {...getMIUIGridScale(gridType)} className={`pos-item pos-item-v2 ${selected ? 'is-active' : ''}`}>
      <ToggleButton isActive={selected} onClick={(): void => onToggle(product.id)}>
        <div className={`newPos-wrapper newPos-wrapper-v2 ${isList ? "newPos-wrapper__list" : ""}`}>
          <span className={`title-wrap title-wrap-v2 ${isSmallGrid ? "title-wrap__small-grid" : ""} ${isList ? "title-wrap__list" : ""}`}>
            {product.name}
          </span>
          <span className={`price-wrap price-wrap-v2 ${isList ? "price-wrap__list" : ""}`}>
            {product.cost && product.cost.toString() !== '0' &&
              <span className={`price price-v2 ${isList ? "price__list" : ""}`}>{product.cost}%</span>
            }
          </span>
        </div>
      </ToggleButton>
    </Grid>
  );
};

export default ProductTypeTaxSelector;

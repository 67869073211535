import React from 'react';
import useStyles from './TextButton.css';
import { LargeButton } from '../Buttons';

interface Props {
  id: string;
  fullWidth?: boolean;
  onClick: () => Promise<void>;
  children?: React.ReactNode;
}

const TextButton: React.FC<Props> = React.memo((props: Props) => {
  const { id, fullWidth, onClick, children } = props;
  const classes = useStyles();

  return <LargeButton
    id={id}
    data-testid={id}
    variant='text'
    fullWidth={fullWidth}
    className={classes.textBtn}
    onClick={onClick}
  >
    {children ?? ''}
  </LargeButton>;
});

export default TextButton;
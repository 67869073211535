import React from 'react';
import { LargePrimaryButton } from "../../../../components/ui/buttons/LargePrimaryButton";
import { RouteComponentProps, withRouter } from "react-router";
import { connect, DispatchProp } from "react-redux";
import { Company, GlobalState, Invoice, InvoicePreparationStepProps } from "../../../../types";
import { PreparationSteps } from "../../../../constants/invoice";
import InvoicePreparationStepContainer from "./InvoicePreparationStepContainer";
import { LargeButton } from "../../../../components/ui/buttons/LargeButton";
import { Grid, Typography } from "@roadsync/roadsync-ui";
import WexLogo from "../../../../components/invoice/wex/WexLogo";
import { CompanyTypes } from "../../../../constants/company";


type PropsFromState = Pick<GlobalState, "invoices" | "companies"> & {
  invoice?: Invoice;
  invoiceCompanyType?: string;
}

interface RouteParams {
  invoiceId: string;
}

type Props = InvoicePreparationStepProps & RouteComponentProps<RouteParams> & DispatchProp & PropsFromState;

class FuelCardPaymentSelectionScreen extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
    this.back = this.back.bind(this);
    this.continue = this.continue.bind(this);
    this.getText = this.getText.bind(this);
  }

  back(): void {
    const { onCompletedStep } = this.props;
    onCompletedStep(PreparationSteps.PAYMENT_METHOD);
  }

  continue(): void {
    const { onCompletedStep } = this.props;
    onCompletedStep(PreparationSteps.LINE_ITEMS);
  }

  getText(): string | undefined {
    const { invoiceCompanyType } = this.props;
    switch (invoiceCompanyType) {
      case CompanyTypes.TOWANDREPARE.key:
        return 'The driver must have accessorial payments enabled on their card. If not, please ask the driver to contact the fleet manager.';
      case CompanyTypes.WAREHOUSE.key:
        return 'The driver must have warehouse payments enabled on their card. If not, please ask the driver to contact the fleet manager.';
      case CompanyTypes.LUMPER.key:
        return 'The driver must have lumper payments enabled on their card. If not, please ask the driver to contact the fleet manager.';
      default:
        return undefined;
    }
  }

  render(): React.ReactElement {
    return (
      <InvoicePreparationStepContainer
        primaryBtn={
          <LargePrimaryButton id="confirm-check-number-btn" onClick={this.continue}>
            Continue
          </LargePrimaryButton>
        }
        secondaryBtn={
          <LargeButton variant="outlined" id="check-number-back-btn" onClick={this.back}>Back</LargeButton>
        }
        title="If the driver has a WEX or EFS Fleet Card/Fuel Card, please select continue. If not, please go back and select another payment method."
      >
        <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start">
          <Grid item>
            <WexLogo />
          </Grid>
          {this.getText() && <Grid item>
            <Typography variant="subtitle2">{this.getText()}</Typography>
          </Grid>
          }
        </Grid>
      </InvoicePreparationStepContainer>
    );
  }
}

export const mapStateToProps = (
  { invoices, companies }: GlobalState,
  { match: { params: { invoiceId } } }: RouteComponentProps<RouteParams>
): PropsFromState => {
  const invoice = invoices?.data?.[invoiceId] as Invoice;
  let company;
  if (typeof invoice.company === "string") {
    company = companies?.data?.[invoice.company] as Company;
  }
  return {
    invoices,
    invoice,
    companies,
    invoiceCompanyType: company?.type
  };
};

export default withRouter(connect(mapStateToProps)(FuelCardPaymentSelectionScreen));

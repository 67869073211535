import { createStyles, makeStyles, Theme } from '@material-ui/core';

const styles = makeStyles((theme: Theme) => createStyles({
  signature: {
    background: theme.palette.common.white,
    height: theme.spacing(6),
    padding: 0,
    '&:hover': {
      cursor: 'pointer',
    },
    '& img': {
      maxHeight: '100%',
    },
  },
  signatureBtn: {
    whiteSpace: "nowrap",
  },
  iconWrapper: {
    [theme.breakpoints.down(768)]: {
      textAlign: 'center',
    }
  },
  icon: {
    display: 'inline-block',
    background: 'transparent',
    padding: 0,
    '& > [class*="MuiAlert-icon"]': {
      fontSize: 45,
      padding: 0,
      margin: 0,
    },
    '& > [class*="MuiAlert-message"]': {
      display: 'none',
    },
  },
}));

export default styles;

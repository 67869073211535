import _ from 'lodash';
import { InvoiceActions } from '../constants/actions';
import { mergeStateAndEntityResult } from '../services/app/normalizr';
import { Invoice } from '../types/Invoice';

export interface InvoiceState {
  entities?: { [k: string]: Invoice };
  data?: { [id: string]: Invoice & { loading?: boolean } };
  order?: string[];
  ui?: {
    loading?: boolean;
    progress?: Invoice;
  };
}

export interface InvoiceAction {
  type: string;
  entities?: { [k: string]: Invoice };
  result?: string;
  showLoader?: boolean;
  order?: string[];
  invoiceId?: string;
  invoice?: Invoice;
}

// eslint-disable-next-line max-lines-per-function,complexity
export function invoices(
  state: InvoiceState = { ui: {}, data: {}, order: [] },
  action: InvoiceAction = { type: "unknown" }
): InvoiceState {

  const nextData = mergeStateAndEntityResult(state, action.entities, 'invoices');

  switch (action.type) {
    case InvoiceActions.REQUEST_INVOICE_LIST:
    case InvoiceActions.DUPLICATING_INVOICE:
    case InvoiceActions.REQUEST_ALL_OUTSTANDING_INVOICE_LIST:
    case InvoiceActions.REQUEST_UNSENT_OUTSTANDING_INVOICE_LIST:
    case InvoiceActions.REQUEST_SENT_OUTSTANDING_INVOICE_LIST:
    case InvoiceActions.REQUEST_BILLING_PAID_INVOICE_LIST:
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: action.showLoader,
        },
      };
    case InvoiceActions.RECEIVE_INVOICE_LIST:
    case InvoiceActions.RECEIVE_ALL_OUTSTANDING_INVOICE_LIST:
    case InvoiceActions.RECEIVE_UNSENT_OUTSTANDING_INVOICE_LIST:
    case InvoiceActions.RECEIVE_SENT_OUTSTANDING_INVOICE_LIST:
    case InvoiceActions.RECEIVE_BILLING_PAID_INVOICE_LIST:
      return {
        ...state,
        data: nextData,
        order: action.order,
        ui: {
          ...state.ui,
          loading: false,
        },
      };
    case InvoiceActions.UNSAVED_PROGRESS:
      return {
        ...state,
        ui: {
          ...state.ui,
          progress: action.invoice,
        },
      };
    case InvoiceActions.CLEAN_PROGRESS:
    case InvoiceActions.PREPARATION_STARTED:
      return {
        ...state,
        ui: {
          ...state.ui,
          progress: undefined,
        },
      };
    case InvoiceActions.DELETED_INVOICE:
      return {
        ...state,
        data: _.omit({ ...state.data }, action.invoiceId as string),
        order: _.pull(state.order || [], action.invoiceId as string),
      };
    case InvoiceActions.DESTROY_LIST: {
      return {
        data: {},
        entities: {},
        ui: {
          ...state.ui,
          loading: true,
        },
      };
    }
    case InvoiceActions.REQUEST_INVOICE:
    case InvoiceActions.REQUEST_VOIDED_INVOICE:
    default:
      return {
        ...state,
        data: nextData,
      };
  }
}

import { GenericLogger } from './GenericLogger';
import { LogLevel } from './LogLevel';

// Extend the Window interface to include DD_LOGS
declare global {
  interface Window {
    DD_LOGS: any;
  }
}

export class DatadogLogger extends GenericLogger {
  public captureException(e: Error, extra?: Record<string, any>): string {
    window.DD_LOGS && window.DD_LOGS.logger.error(e.message, extra);
    return '';
  }

  public captureMessage(msg: string, extra?: Record<string, any>): void {
    window.DD_LOGS && window.DD_LOGS.logger.log(msg, extra);
  }

  public debug(msg: string, ...data: any[]): void {
    if (this.level() < LogLevel.Debug) {
      return;
    }
    window.DD_LOGS && window.DD_LOGS.logger.debug(msg, ...data);
  }

  public warn(msg: string, ...data: any[]): void {
    if (this.level() < LogLevel.Warn) {
      return;
    }
    window.DD_LOGS && window.DD_LOGS.logger.warn(msg, ...data);
  }

  public error(msg: string, ...data: any[]): void {
    if (this.level() < LogLevel.Error) {
      return;
    }
    window.DD_LOGS && window.DD_LOGS.logger.error(msg, ...data);
  }

  public info(msg: string, ...data: any[]): void {
    if (this.level() < LogLevel.Info) {
      return;
    }
    window.DD_LOGS && window.DD_LOGS.logger.info(msg, ...data);
  }
}

import { useEffect } from "react";
import useRainforestScript, { RainforestScriptType } from "./useRainforestScript";

export interface RainforestApproval {
  data: {
    payin_id: string;
  }
}

export interface RainforestDecline {
  data: {
    payin_id: string;
    refusal_code: string;
    refusal_desc: string;
  };
}

export interface RainforestError {
  code: string,
  message: string;
}

export interface RainforestAttempted { };

export interface RainforestValid { };

export interface RainforestInvalid { };

export interface RainforestConfigUpdateIgnored{ };

export interface RainforestCardBrandUpdated{ };

export interface Props {
  sessionKey: string;
  payinConfigId: string;
  hideButton?: boolean;
  onLoad?: (loaded: boolean) => void;
  onPaymentApproved?: (e: CustomEvent<RainforestApproval[]>) => void;
  onPaymentDeclined?: (e: CustomEvent<RainforestDecline[]>) => void;
  onPaymentError?: (e: CustomEvent<RainforestError[]>) => void;
  onPaymentAttempted?: (e: CustomEvent<RainforestAttempted[]>) => void;
  onPaymentValid?: (e: CustomEvent<RainforestValid[]>) => void;
  onPaymentInvalid?: (e: CustomEvent<RainforestInvalid[]>) => void;
  onConfigUpdateIgnored?: (e: CustomEvent<RainforestConfigUpdateIgnored[]>) => void;
  onCardBrandUpdated?: (e: CustomEvent<RainforestCardBrandUpdated[]>) => void;
}

const RainforestPayment: React.FC<Props> = ({
  sessionKey,
  payinConfigId,
  hideButton,
  onLoad,
  onPaymentApproved,
  onPaymentDeclined,
  onPaymentError,
  onPaymentAttempted,
  onPaymentValid,
  onPaymentInvalid,
  onConfigUpdateIgnored,
  onCardBrandUpdated,
}) => {
  useRainforestScript(RainforestScriptType.Payment, onLoad);

  useEffect(() => {
    const form = document.querySelector('rainforest-payment');

    form?.addEventListener('approved', onPaymentApproved as EventListener);
    form?.addEventListener('declined', onPaymentDeclined as EventListener);
    form?.addEventListener('error', onPaymentError as EventListener);
    form?.addEventListener('attempted', onPaymentAttempted as EventListener);
    form?.addEventListener('valid', onPaymentValid as EventListener);
    form?.addEventListener('invalid', onPaymentInvalid as EventListener);
    form?.addEventListener('config-update-ignored', onConfigUpdateIgnored as EventListener);
    form?.addEventListener('card-brand-updated', onCardBrandUpdated as EventListener);

    return () => {
      form?.removeEventListener('approved', onPaymentApproved as EventListener);
      form?.removeEventListener('declined', onPaymentDeclined as EventListener);
      form?.removeEventListener('error', onPaymentError as EventListener);
      form?.removeEventListener('attempted', onPaymentAttempted as EventListener);
      form?.removeEventListener('valid', onPaymentValid as EventListener);
      form?.removeEventListener('invalid', onPaymentInvalid as EventListener);
    };

  }, [
    // form props
    sessionKey,
    payinConfigId,
    hideButton,
    // event listener callbacks
    onPaymentApproved,
    onPaymentDeclined,
    onPaymentError,
    onPaymentAttempted,
    onPaymentValid,
    onPaymentInvalid,
    onConfigUpdateIgnored,
    onCardBrandUpdated,
  ]);

  // @ts-ignore
  return <rainforest-payment
    data-testid='rainforest-payment'
    session-key={sessionKey}
    payin-config-id={payinConfigId}
    allowed-methods='CARD'
    style-button-color='#212121'
    style-button-border-radius='90rem'
    style-font-family="'ProximaNova',sans-serif"
    {...(hideButton ? { 'hide-button': true } : {})}
  />;
}

export default RainforestPayment;

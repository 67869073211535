import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import ModalContent from '../../components/modals/ModalContent';
import { closeModal } from '../../actions/modals';
import { ModalsConstants } from '../../constants/modals';

interface FleetCardCheckProps extends DispatchProp {
  selectCheck: () => void;
}

class FleetCardCheckModal extends React.Component<FleetCardCheckProps> {
  constructor(props) {
    super(props);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleConfirmation = this.handleConfirmation.bind(this);
  }

  handleModalClose(): void {
    const { dispatch } = this.props;
    dispatch(closeModal(ModalsConstants.FLEET_CARD_CHECK));
  }

  handleConfirmation(): void {
    const { selectCheck } = this.props;
    selectCheck();
  }

  render(): React.ReactElement {
    return (
      <ModalContent
        primaryActionText="Continue"
        primaryActionClick={this.handleConfirmation}
        secondaryActionText="Cancel"
        secondaryActionClick={this.handleModalClose}
      >
        <div className="wrap-small mt-20">
          <div className="newpos-title-small">
            Fleet Cards are processed like Fleet Checks.
            <br />
            <br />
            <span className="text-bold">
              Continue to Fleet Check Selection?
            </span>
          </div>
        </div>
      </ModalContent>
    );
  }
}

export default connect()(FleetCardCheckModal);

import { FileEndpoints } from './endpoints';
import * as http from './http';
import { File } from '../../types/File';

export function uploadFile(file): Promise<any> {
  return http.post(FileEndpoints.uploadFile(), file);
}

export function getFile(fileId): Promise<File> {
  return http.get(FileEndpoints.getFileById(fileId));
}

import { PwaPreparationActionType } from '../constants/actions';
import { BeforeInstallPromptEvent } from '../types/BeforeInstallPromptEvent';

export interface PwaPreparerState {
  installPromptEvent?: BeforeInstallPromptEvent;
}

export function PwaPreparerReducer(state: PwaPreparerState = {}, action: Record<string, any> = {}): PwaPreparerState {
  return action.type === PwaPreparationActionType.SAVE_EVENT_OBJECT
    ? { installPromptEvent: action.content }
    : { ...state }
}

export class CompanyEndpoints {
  static readonly CREATE = '/api/v1/companies';

  // eslint-disable-next-line max-params
  static getCompanies = (offset: number, limit: number, quickfilter?: string, companyType?: string): string => (
    `/api/v1/companies?offset=${offset}&limit=${limit}${CompanyEndpoints
      .getCompanyParams(quickfilter, companyType)}`)

  static getCompany = (companyId: string): string => `/api/v1/companies/${companyId}`;
  static getCompanyPublic = (companyId: string): string => `/api/v1/public/companies/${companyId}`;
  static updateCompany = (companyId: string): string => `/api/v1/companies/${companyId}`;

  private static getCompanyParams(quickfilter?, companyType?: string): string {
    const params = [''];
    if (quickfilter) {
      params.push(`search=${quickfilter}`);
    }
    if (companyType) {
      params.push(`companytype=${companyType}`);
    }
    return params.join('&');
  }
}

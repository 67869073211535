
import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { PreparationSteps } from '../../../../constants/invoice';
import { LargeButton, LargePrimaryButton } from '../../../../components/ui/Buttons';
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import { invoiceStepBack } from '../../../../thunks';
import { GlobalState } from '../../../../types/GlobalState';
import InvoicePreparationStepContainer from './InvoicePreparationStepContainer';
import { Invoice } from '../../../../types/Invoice';
import { Deposit } from '../../../../types/Deposit';
import { Company } from '../../../../types/Company';
import PrintCheckContainer from '../../../../components/invoice/PrintCheckContainer';
import PrintIcon from '@material-ui/icons/Print';
import SendIcon from '@material-ui/icons/Send';
import { CheckTypes } from '../../../../constants/deposit';
import NoPrintContainer from '../../../../components/ui/visibility/NoPrintContainer';
import { OnCompletedStepProps } from '../../../../types/OnCompletedStepProps';
import { NoCheckRefundsText } from '../../../../components/ui/NoCheckRefundsText';

type PropsFromState = Pick<GlobalState, "invoices" | "deposits" | "companies">;

interface RouteParams {
  invoiceId: string;
}

interface OwnProps extends OnCompletedStepProps {
  onCreate?: boolean;
}

interface Props extends OwnProps, PropsFromState, DispatchProp, RouteComponentProps<RouteParams> { }

class ConfirmCheckNumber extends React.Component<Props> {

  constructor(props: Props) {
    super(props);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.sendToDriver = this.sendToDriver.bind(this);
    this.printPage = this.printPage.bind(this);
  }

  handleNext(): void {
    const { onCompletedStep } = this.props;
    onCompletedStep(PreparationSteps.LINE_ITEMS);
  }

  sendToDriver(): void {
    const { onCompletedStep } = this.props;
    onCompletedStep(PreparationSteps.SEND_CHECK_INFO_TO_DRIVER);
  }

  printPage(): void {
    window.print();
  }

  handleBack(): void {
    const { dispatch } = this.props;
    dispatch<any>(invoiceStepBack(this.getInvoiceId()));
  }

  getHeaderText(): string {
    const { onCreate } = this.props;
    return onCreate ? "Check number for this invoice" : "Make sure the payer uses the following Fleet Check number to pay for the invoice:"
  }

  getInvoiceId(): string {
    const { match: { params: { invoiceId } } } = this.props;
    return invoiceId;
  }

  getInvoice(): Invoice {
    return this.props.invoices?.data?.[this.getInvoiceId()] as Invoice;
  }

  getDeposit(): Deposit | undefined {
    return this.props.deposits?.data?.[this.getInvoiceId()];
  }

  getCompany(): Company | undefined {
    const { companies } = this.props;
    const invoice = this.getInvoice();
    return "string" === typeof invoice?.company
      ? companies?.data?.[invoice.company]
      : invoice?.company;
  }

  getCheckNameLabel(): string | undefined {
    const deposit = this.getDeposit();
    return deposit?.checkType ? CheckTypes.getByKey(deposit.checkType)?.display : undefined;
  }

  // eslint-disable-next-line max-lines-per-function
  render(): React.ReactElement {
    const deposit = this.getDeposit();
    return (
      <InvoicePreparationStepContainer
        primaryBtn={
          <LargePrimaryButton id="confirm-check-number-btn" onClick={this.handleNext}>
            Continue
          </LargePrimaryButton>
        }
        secondaryBtn={
          <LargeButton variant="outlined" id="check-number-back-btn" onClick={this.handleBack}>Back</LargeButton>
        }
        title={this.getHeaderText()}
      >
        <PrintCheckContainer invoice={this.getInvoice()} deposit={deposit} company={this.getCompany()} />
        <NoPrintContainer>
          <Box mb={2}><NoCheckRefundsText /></Box>
          <Box mb={4}>
            <List style={{ border: "1px solid #ccc" }}>
              <ListItem>
                <ListItemText
                  primary={<Typography variant="h1" component="div">{deposit?.checkNumber}</Typography>}
                  secondary={<Typography variant="h5" component="div">{this.getCheckNameLabel()} Check #</Typography>}
                />
              </ListItem>
              <Divider />
              <ListItem button onClick={this.printPage}>
                <ListItemIcon><PrintIcon /></ListItemIcon>
                <ListItemText>Print page</ListItemText>
              </ListItem>
              <Divider />
              <ListItem button onClick={this.sendToDriver}>
                <ListItemIcon><SendIcon /></ListItemIcon>
                <ListItemText>Send to driver</ListItemText>
              </ListItem>
            </List>
          </Box>
        </NoPrintContainer>
      </InvoicePreparationStepContainer>
    );
  }
}

const mapStateToProps = ({ invoices, deposits, companies }: GlobalState): PropsFromState => ({ invoices, deposits, companies });
export default withRouter(connect(mapStateToProps)(ConfirmCheckNumber));

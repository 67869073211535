import { CompanyFees } from './endpoints';
import * as http from './http';

export function updateFeeById(feeId: string, values): Promise<any> {
  return http.putJSON(CompanyFees.updateFeeById(feeId), values);
}

export function createFee(companyId: string, values): Promise<any> {
  return http.postJSON(CompanyFees.createFee(companyId), values);
}

export function getFeeByCompanyId(companyId: string): Promise<any> {
  return http.get(CompanyFees.getFeesByCompanyId(companyId));
}

export function getFeeById(feeId: string): Promise<any> {
  return http.get(CompanyFees.getFeeByById(feeId));
}

export function deleteFeeById(feeId: string): Promise<any> {
  return http.del(CompanyFees.deleteFeeById(feeId));
}

// Public

export function getFeesByCompanyIdPublic(companyId: string): Promise<any> {
  return http.get(CompanyFees.getFeesByCompanyIdPublic(companyId));
}

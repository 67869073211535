import { ModalActions } from '../constants/actions';

interface Modal {
  name: string;
  params?: ModalParams;
}

const arraySingle = (array: Modal[] | undefined, key: string, val: string): Modal | null => {
  if (Array.isArray(array)) {
    const search = array.filter(item => item[key] === val);
    if (search && search.length === 1) {
      return search[0];
    }
  }
  return null;
};

interface ModalsAction {
  type: string;
  name: string;
  params: ModalParams;
}

interface ModalParams {
  [k: string]: any;
}

export type ModalsState = Modal[];

// eslint-disable-next-line
export function modals(state: ModalsState = [], action: ModalsAction = { type: "", name: "", params: {} }): ModalsState {
  let nextState;
  let existing;
  switch (action.type) {
    case ModalActions.OPEN_MODAL:
      nextState = Object.assign([], state);
      existing = arraySingle(nextState, 'name', action.name);
      if (existing) {
        existing.params = action.params;
      } else {
        nextState.push({
          name: action.name,
          params: action.params,
        });
      }
      return nextState;
    case ModalActions.CLOSE_MODAL:
      nextState = Object.assign([], state);
      existing = arraySingle(nextState, 'name', action.name);
      if (existing) {
        nextState.splice(nextState.indexOf(existing), 1);
      }
      return nextState;
    default:
      return state;
  }
}

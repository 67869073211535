import { Store, AnyAction } from 'redux';
import { AuthState } from './reducers/auth';

// Extend the Window interface to include DD_RUM
declare global {
  interface Window {
    DD_RUM: any;
  }
}

export class Datadog {
  static init(store: Store<any, AnyAction>): void {
    try {
      // grab the user from the auth global state
      // used to add context in 'beforeSend'
      store.subscribe(() => {
        const { auth: { me } }: { auth: AuthState } = store.getState();
        if (!me?.username) {
          window.DD_RUM && window.DD_RUM.clearUser();
          return;
        }

        window.DD_RUM && window.DD_RUM.setUser({
          id: me.id,
          email: me.email,
          name: `${me.first} ${me.last}`,
          username: me.username,
          companyId: me.companyId,
          isVerified: me.isVerified,
        });
      });
    } catch (error) {
      console.error('Error setting Datadog user:', error);
    }
  }
}

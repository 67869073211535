import { Theme } from "@material-ui/core";

const styles = (theme: Theme): {} => ({
  root: {
    height: "100%",
    maxHeight: "100%",
    [theme.breakpoints.up('md')]: {
      overflow: "hidden",
    },
  },
  container: {
    [theme.breakpoints.up('md')]: {
      paddingBottom: theme.spacing(2),
      height: "100%",
    }
  },
  contentColumn: {
    height: "100%",
    overflowY: "auto",
  },
  controlsColumn: {
    [theme.breakpoints.up('md')]: {
      height: "100%",
      overflow: "hidden",
      '& > [class*="MuiBox-root"]': {
        background: theme.palette.common.white,
        maxHeight: "100%",
        overflowY: "auto",
      },
    }
  },
});

export default styles;

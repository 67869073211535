import { MemoryStorage } from "./memoryStorage";

const memoryStorage = new MemoryStorage();
let length = 0;

function getItem<T>(k: string): T | null {
  const val = getStorage().getItem(k);
  try {
    return val ? JSON.parse(val) : null;
  } catch (e) {
    return val as any;
  }
}

function setItem(k: string, v: any): void {
  getStorage().setItem(k, "string" === typeof v ? v : JSON.stringify(v));
  updateLength();
}

function removeItem(k: string): void {
  getStorage().removeItem(k);
  updateLength();
}

function key(index: number): string | null {
  return getStorage().key(index);
}

function clear(): void {
  getStorage().clear();
}

function canUseLocalStorage(): boolean {
  try {
    return 'localStorage' in window && !!window.localStorage;
  } catch (e) {
    return false;
  }
}

function canUseSessionStorage(): boolean {
  try {
    return 'sessionStorage' in window && !!window.sessionStorage;
  } catch (e) {
    return false;
  }
}

function getStorage(): Storage {
  if (canUseLocalStorage()) {
    return localStorage
  }
  return canUseSessionStorage() ? sessionStorage : memoryStorage;
}

function updateLength(): void {
  length = getStorage().length;
}

export {
  getItem,
  setItem,
  removeItem,
  clear,
  key,
  length,
  getStorage,
}

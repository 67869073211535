import { SocketActions } from '../constants/actions';

export interface SocketsState {
  isOpen: boolean;
}

interface SocketsAction {
  type: string;
}

export function sockets(state: SocketsState = { isOpen: false }, action: SocketsAction = { type: "unknown" }): SocketsState {
  switch (action.type) {
    case SocketActions.CONNECTION_CLOSED:
    case SocketActions.CONNECTION_FAILED:
      return { isOpen: false };
    case SocketActions.CONNECTION_OPENED:
      return { isOpen: true };
    default:
      return state;
  }
}

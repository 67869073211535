import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import { RenderTextField } from '../ui/Form';

export interface InvoiceCommentsFormData {
  comments?: string | null;
}

type Props = InjectedFormProps<InvoiceCommentsFormData>;

const InvoiceCommentsForm: React.FC<Props> = (props: Props) => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="comments"
        id="invoice-comments-field"
        label="Comments"
        component={RenderTextField}
        multiline
        placeholder="Begin typing comments here..."
        helperText="Comments will appear on the final receipt. We recommend using this space to add any notes or a description of services provided."
      />
    </form>
  );
};

export default reduxForm<InvoiceCommentsFormData>({ form: 'invoiceComments' })(InvoiceCommentsForm);

import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { GlobalState } from "../types/GlobalState";
import { connect, DispatchProp } from "react-redux";
import * as authService from '../services/api/auth';
import * as storage from '../services/app/storage';
import { now } from "moment";
import { getAttributes, getCompanyFromState, isTreatmentOn } from '../services/app/split';
import { FeatureFlag } from "./featureFlag/FeatureFlag";

interface Props extends PropsFromState, DispatchProp, RouteComponentProps {
    children: React.ReactElement;
}

class RefreshAccessToken extends React.Component<Props> {
    timer: any;

    constructor(props: Props) {
        super(props);
        this.state = {};
        this.attemptToRefreshAccessToken = this.attemptToRefreshAccessToken.bind(this);
    }

    startRefreshAccessTokenTimer = () => {
        this.timer = setInterval(async () => {
            const currentTime = now();
            const fiveMinutesFromLastRefresh = currentTime - Number(storage.getItem('lastAccessTokenRefresh')) > 300000;
            if (fiveMinutesFromLastRefresh) {
                await this.attemptToRefreshAccessToken();
            }
        }, 300000);
    };

    async componentDidMount(): Promise<void> {
        const { auth, companies, publicData } = this.props;
        const company = getCompanyFromState(companies, publicData);
        const attributes = getAttributes(auth?.me, company);
        const refreshAccessTokenFlag = await isTreatmentOn(FeatureFlag.RefreshAccessToken, attributes);
        if (refreshAccessTokenFlag) {
            this.startRefreshAccessTokenTimer();
        }
    }

    componentWillUnmount(): void {
        clearInterval(this.timer);
    }

    async attemptToRefreshAccessToken() {
        const { auth: { me }, history, location } = this.props;
        const refreshToken = storage.getItem('refreshToken') as string;

        try {
            if (refreshToken) {
                const resp = await authService.GetCognitoAccessToken(refreshToken, me.username);
                if (resp.AccessToken) {
                    storage.setItem('accessToken', resp.AccessToken);
                    storage.setItem('lastAccessTokenRefresh', now());
                }
            }
        } catch (e) {
            history.push({ pathname: `/login/redirect?previousRoute=${location.pathname}` })
        }
    }

    render(): React.ReactElement {
        const { children } = this.props;
        return children;
    }
}


type PropsFromState = Pick<GlobalState, "auth" | "companies" | "publicData">;
const mapStateToProps = ({ auth, companies, publicData }: GlobalState): PropsFromState => ({ auth, companies, publicData });
export default withRouter(connect(mapStateToProps)(RefreshAccessToken));

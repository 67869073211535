import { AccountingActions } from '../constants/actions';
import _ from 'lodash';
import { mergeStateAndEntityResult } from '../services/app/normalizr';
import { AccountingProductItem } from '../types/AccountingProduct';

export interface AccountingProductListState {
  data: { [companyId: string]: { items: AccountingProductItem[]; loading?: boolean } };
}

interface AccountingProductListAction {
  type?: string;
  companyId?: string;
  entities?: { accountingProductsList: { data: any } };
}

export function accountingProductsList(
  state: AccountingProductListState = { data: {} },
  action: AccountingProductListAction = {}
): AccountingProductListState {
  const nextData = mergeStateAndEntityResult(state, action.entities, 'accountingProductsList');
  switch (action.type) {
    case AccountingActions.REQUEST_ACCOUNTING_PRODUCTS_LIST:
      return _.set({ ...state }, `data.${action.companyId}`, { loading: true });
    case AccountingActions.RECEIVE_ACCOUNTING_PRODUCTS_LIST:
      return { data: nextData };
    case AccountingActions.RECEIVE_ACCOUNTING_PRODUCTS_LIST_ERROR:
      return _.set({ ...state }, `data.${action.companyId}`, { loading: false });
    default:
      return state;
  }
}

import { BankAccountInput } from '../../types/BankAccount';
import { CardAccountStatus } from '../../types/CardAccountStatus';
import { CardEndpoints } from './endpoints';
import * as http from './http';

export function acceptTermsAndConditions(companyId: string, version: any): Promise<{}> {
  return http.postJSON(CardEndpoints.acceptTermsOfService(companyId), { version });
}

export function getCardAccountStatus(companyID: string): Promise<CardAccountStatus> {
  return http.get(CardEndpoints.getCardAccountStatus(companyID));
}

export function createCardBankAccount(companyId: string, bankAccount: BankAccountInput): Promise<{}> {
  return http.postJSON(CardEndpoints.createBankAccount(companyId), prepareBankAccountInput(bankAccount));
}

export function createCardCompany(companyId: string, companyInfo: any): Promise<{}> {
  return http.postJSON(CardEndpoints.createCompany(companyId), companyInfo);
}

export function createCardMerchant(companyId: string, merchantInfo: any): Promise<{}> {
  return http.postJSON(CardEndpoints.createMerchant(companyId), merchantInfo);
}

export function getTermsOfService(companyId: string): Promise<{}> {
  return http.get(CardEndpoints.getTerms(companyId));
}

export function confirmCardMicrodeposit(companyId: string, amount: any): Promise<{}> {
  return http.postJSON(CardEndpoints.validateMicrodeposit(companyId), { amount });
}

export function getSecurityQuestions(companyId: string): Promise<{}> {
  return http.get(CardEndpoints.getRecoveryQuestions(companyId));
}

export function confirmBeneficialOwners(companyId: string): Promise<{}> {
  return http.post(CardEndpoints.confirmBeneficialOwners(companyId));
}

export function resetBankAccount(companyId: string): Promise<{}> {
  return http.post(CardEndpoints.resetBankAccount(companyId));
}

function prepareBankAccountInput(bankAccount: BankAccountInput): Partial<BankAccountInput> & { birthday: number } {
  return {
    firstName: bankAccount.firstName,
    lastName: bankAccount.lastName,
    routingNumber: bankAccount.routingNumber,
    accountNumber: bankAccount.accountNumber,
    ssn: bankAccount.ssn,
    birthday: bankAccount.birthDay,
    birthMonth: bankAccount.birthMonth,
    birthYear: bankAccount.birthYear,
  };
}

export class MemoryStorage {

  public length = 0;
  private map: { [k: string]: string } = {};

  public clear(): void {
    Object.keys(this.map).forEach(k => this.removeItem(k));
  }

  public getItem(key: string): string | null {
    return this.map[key] || null
  }

  public setItem(k: string, v: string): void {
    this.map[k] = v;
    this.updateLength();
  }

  public removeItem(k: string): void {
    delete this.map[k];
    this.updateLength();
  }

  public key(index: number): string | null {
    return Object.keys(this.map)[index];
  }

  private updateLength(): void {
    this.length = Object.keys(this.map).length;
  }
}

import React, { useState } from 'react';
import { Snackbar, Button, IconButton, Theme, createStyles, makeStyles } from '@material-ui/core';
import { P } from '@roadsync/roadsync-ui';
import AlertMUI from '@material-ui/lab/Alert';
import { AlertProps, dismissAlert } from '../../actions/alerts';
import { connect, DispatchProp } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';

interface Props extends AlertProps, DispatchProp { }

const useStyles = makeStyles((theme: Theme) => createStyles({
  button: {
    textTransform: "none",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(1),
  },
}));

// eslint-disable-next-line max-lines-per-function
const Alert: React.FC<Props> = (props: Props): React.ReactElement => {

  const classes = useStyles();
  const { severity, text, onClose, dispatch, linkText, linkURL, linkOnClick, autoHideDuration, onCloseText } = props;
  const [opened, setOpened] = useState(true);
  const handleClose = (clicked?: boolean): void => {
    if (autoHideDuration !== null || clicked) {
      setOpened(false);
      dispatch(dismissAlert());
    }
    if (clicked && onClose) onClose();
  };

  const action = <>

    {linkText && linkURL &&
      <Button
        size="small"
        color="inherit"
        variant="outlined"
        id="snackbar-action-btn"
        className={classes.button}
        onClick={(): void => {
          window.open(linkURL, "_blank");
          if (linkOnClick) linkOnClick();
          handleClose();
        }}
      >{linkText}</Button>

    }

    {onCloseText &&
      <Button
        size="small"
        color="inherit"
        variant="text"
        id="snackbar-close-btn"
        style={{ marginLeft: 8, whiteSpace: "nowrap" }}
        onClick={(): void => handleClose(true)}
      >{onCloseText}</Button>
    }

    {!onCloseText &&
      <IconButton onClick={(): void => handleClose(true)} color="inherit" size="small" id="snackbar-close-btn">
        <CloseIcon />
      </IconButton>
    }
  </>

  return (
    <Snackbar
      action={!severity && action}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={"undefined" === typeof autoHideDuration ? 5000 : autoHideDuration}
      message={text}
      onClose={(): void => handleClose()}
      open={opened}
    >
      {severity &&
        <AlertMUI
          onClose={(): void => handleClose()}
          elevation={3}
          variant="filled"
          severity={severity}
          action={action}
        >
          <P>
            {text}
          </P>
        </AlertMUI>
      }
    </Snackbar>
  );
}

export default connect()(Alert);

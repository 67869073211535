import { CompanyLabels } from '../constants/actions';
import { mergeStateAndEntityResult } from '../services/app/normalizr';
import _ from 'lodash';

export interface LabelsState {
  data?: {
    [k: string]: {
      companyId: string;
      data?: { [labelName: string]: { label?: string } };
    };
  };
}

interface LabelsAction {
  type: string;
  companyId?: string;
  entities?: { [labelId: string]: { label?: string } };
}

export function labels(state: LabelsState = { data: {} }, action: LabelsAction = { type: "unknown" }): LabelsState {
  const nextData = mergeStateAndEntityResult(state, action.entities, 'labels');
  switch (action.type) {
    case CompanyLabels.REQUEST_COMPANY_LABELS:
      return _.set({ ...state }, `data.${action.companyId}`, { loading: true });
    case CompanyLabels.RECEIVE_COMPANY_LABELS:
      return _.set({ ...state }, `data.${action.companyId}`, { id: action.companyId, data: action.entities });
    default:
      return { ...state, data: nextData };
  }
}

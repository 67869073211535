import { createStyles, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() =>
    createStyles({
        icon: {
            color: "black !important",
        },
        whiteTextColor: {
            color: "white",
            fill: "white",
        },
        label: {
            color: "black",
        },
        modalMaxWidth: {
            maxWidth: "600px",
        },
        modalButton: {
            border: "2px solid black",
            backgroundColor: "white",
            borderRadius: "5px",
            fontWeight: "bold",
            width: "100%",
        },
        quitConfirmationModalText: {
            marginTop: "3rem",
            marginBottom: "3rem",
            fontWeight: "bold",
            fontSize: "1.5rem",
        },
        saveAndContinueButton: {
            marginTop: "10px",
            borderRadius: "5px",
            width: "100%",
        },
        confirmationButtton: {
            marginTop: "10px",
            width: "100%",
        },
        divider: {
            marginTop: "1.5rem",
            marginBottom: "1.5rem !important",
        },
        companyTitle: {
            marginBottom: "1rem",
        },
        confirmDetailsTitle: {
            fontWeight: 700,
            marginBottom: ".5rem",
        },
        confirmationFieldsText: {
            "& > *": {
                marginBottom: ".3rem",
                wordWrap: "break-word",
            },
        },
        addressText: {
            marginBottom: ".5rem",
        },
        contentMarginTop: {
            marginTop: "1rem",
        },
        navigationButtons: {
            marginTop: "2rem",
        },
    })
);

export default useStyles;

import { CardActions } from '../constants/actions';
import * as CardService from '../services/api/card';
import { showErrorAlert } from './alerts';

function requestCardAccountStatus(companyId: string) {
  return {
    type: CardActions.REQUEST_CARD_ACCOUNT_STATUS,
    companyId,
  };
}

function receiveCardAccountStatus(companyId: string, status) {
  return {
    type: CardActions.RECEIVE_CARD_ACCOUNT_STATUS,
    companyId,
    status,
  };
}

export function getCardAccountStatus(companyId: string) {
  return (dispatch: Function) => {
    dispatch(requestCardAccountStatus(companyId));
    return CardService.getCardAccountStatus(companyId)
      .then((data) => {
        dispatch(receiveCardAccountStatus(companyId, data));
      }).catch((error) => {
        dispatch(receiveCardAccountStatus(companyId, {}));
      });
  };
}

function acceptingCardTermsOfService(companyId: string) {
  return {
    type: CardActions.ACCEPTING_CARD_TERMS_OF_SERVICE,
    companyId,
  };
}

function acceptedCardTermsOfService(companyId, cardAccountInfo) {
  return {
    type: CardActions.ACCEPTED_CARD_TERMS_OF_SERVICE,
    companyId,
    cardAccountInfo,
  };
}

export function acceptTermsOfService(companyId: string, versionId: string) {
  return (dispatch: Function) => {
    dispatch(acceptingCardTermsOfService(companyId));
    return CardService.acceptTermsAndConditions(companyId, versionId)
      .then((info) => {
        dispatch(acceptedCardTermsOfService(companyId, info));
        dispatch(getCardAccountStatus(companyId));
      }).catch((err) => {
        dispatch(showErrorAlert(err.message));
      });
  };
}

function attachingBankAccountToCardAccount(companyId) {
  return {
    type: CardActions.ATTACHING_CARD_BANK_ACCOUNT,
    companyId,
  };
}

function attachedBankAccountToCardAccount(companyId, info) {
  return {
    type: CardActions.ATTACHED_CARD_BANK_ACCOUNT,
    companyId,
    info,
  };
}

export function attachBankAccountToCardAccount(companyId: string, bankInfo: any) {
  return (dispatch: Function) => {
    dispatch(attachingBankAccountToCardAccount(companyId));
    return CardService.createCardBankAccount(companyId, bankInfo)
      .then((info) => {
        dispatch(attachedBankAccountToCardAccount(companyId, info));
        dispatch(getCardAccountStatus(companyId));
      }).catch((err) => {
        dispatch(showErrorAlert(err.message));
      });
  };
}

function creatingCardMerchant(companyId) {
  return {
    type: CardActions.CREATING_CARD_MERCHANT,
    companyId,
  };
}

function createdCardMerchant(companyId) {
  return {
    type: CardActions.CREATED_CARD_MERCHANT,
    companyId,
  };
}

export function createCardMerchant(companyId: string, merchantInfo: any) {
  return (dispatch: Function) => {
    dispatch(creatingCardMerchant(companyId));
    return CardService.createCardMerchant(companyId, merchantInfo)
      .then((info) => {
        dispatch(createdCardMerchant(companyId));
        dispatch(getCardAccountStatus(companyId));
      });
  };
}

function requestTermsOfService(companyId: string) {
  return {
    type: CardActions.REQUEST_TERMS_OF_SERVICE,
    companyId,
  };
}

function receiveTermsOfService(companyId: string, terms) {
  return {
    type: CardActions.RECEIVE_TERMS_OF_SERVICE,
    companyId,
    terms,
  };
}

export function getTermsOfService(companyId: string) {
  return (dispatch: Function) => {
    dispatch(requestTermsOfService(companyId));
    return CardService.getTermsOfService(companyId)
      .then((terms) => {
        dispatch(receiveTermsOfService(companyId, terms));
      });
  };
}

function creatingCardCompany(companyId: string) {
  return {
    type: CardActions.CREATING_CARD_COMPANY_ACCOUNT,
    companyId,
  };
}

function createdCardCompany(companyId: string) {
  return {
    type: CardActions.CREATED_CARD_COMPANY_ACCOUNT,
    companyId,
  };
}

export function createCardCompany(companyId: string, companyInfo: any) {
  return (dispatch: Function) => {
    dispatch(creatingCardCompany(companyId));
    return CardService.createCardCompany(companyId, companyInfo)
      .then((info) => {
        dispatch(createdCardCompany(companyId));
        dispatch(getCardAccountStatus(companyId));
      }).catch((err) => {
        dispatch(showErrorAlert(err.message));
      });
  };
}

function confirmingMicrodeposit(companyId: string) {
  return {
    type: CardActions.CONFIRMING_MICRODEPOSIT,
    companyId,
  };
}

function confirmedMicrodeposit(companyId: string) {
  return {
    type: CardActions.CONFIRMED_MICRODEPOSIT,
    companyId,
  };
}

export function confirmCardMicrodeposit(companyId: string, amount: any) {
  return (dispatch: Function) => {
    dispatch(confirmingMicrodeposit(companyId));
    return CardService.confirmCardMicrodeposit(companyId, amount)
      .then((info) => {
        dispatch(confirmedMicrodeposit(companyId));
        dispatch(getCardAccountStatus(companyId));
      }).catch((err) => {
        dispatch(showErrorAlert(err.message));
      });
  };
}

function requestRecoveryQuestions(companyId: string) {
  return {
    type: CardActions.REQUEST_RECOVERY_QUESTIONS,
    companyId,
  };
}

function receiveRecoveryQuestions(companyId: string, questions: any) {
  return {
    type: CardActions.RECEIVE_RECOVERY_QUESTIONS,
    companyId,
    questions,
  };
}

export function getRecoveryQuestions(companyId) {
  return (dispatch: Function) => {
    dispatch(requestRecoveryQuestions(companyId));
    return CardService.getSecurityQuestions(companyId)
      .then((data) => {
        dispatch(receiveRecoveryQuestions(companyId, data));
      });
  };
}

function confirmingBeneficialOwners(companyId: string) {
  return {
    type: CardActions.CONFIRMING_BENEFICIAL_OWNERS,
    companyId,
  };
}

function confirmedBeneficialOwners(companyId: string) {
  return {
    type: CardActions.CONFIRMED_BENEFICIAL_OWNERS,
    companyId,
  };
}

export function confirmBeneficialOwners(companyId) {
  return (dispatch: Function) => {
    dispatch(confirmingBeneficialOwners(companyId));
    return CardService.confirmBeneficialOwners(companyId)
      .then((data) => {
        dispatch(confirmedBeneficialOwners(companyId));
      });
  };
}

function resettingBankAccount(companyId: string) {
  return {
    type: CardActions.RESETTING_BANK_ACCOUNT,
    companyId,
  };
}

function resetCompleteBankAccount(companyId: string) {
  return {
    type: CardActions.RESET_BANK_ACCOUNT,
    companyId,
  };
}

export function resetBankAccount(companyId) {
  return (dispatch: Function) => {
    dispatch(resettingBankAccount(companyId));
    return CardService.resetBankAccount(companyId)
      .then((data) => {
        dispatch(resetCompleteBankAccount(companyId));
      });
  };
}

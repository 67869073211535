import _ from 'lodash';
import { ProductActions } from '../constants/actions';
import { mergeStateAndEntityResult } from '../services/app/normalizr';
import { Product } from '../types/Product';

export interface ProductsState {
  data: { [k: string]: Product };
  order: string[];
  ui: { loading?: boolean };
}

// eslint-disable-next-line
export function products(state: ProductsState = { data: {}, ui: {}, order: [] }, action: Record<string, any> = {}): ProductsState {

  const nextData = mergeStateAndEntityResult(state, action.entities, 'products');

  switch (action.type) {
    case ProductActions.REQUEST_PRODUCT:
      return _.set({ ...state }, `data.${action.result}`, { loading: true });
    case ProductActions.REQUEST_PRODUCT_LIST:
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: true,
        },
      };
    case ProductActions.RECEIVE_PRODUCT_LIST:
      return {
        ...state,
        data: nextData,
        order: action.order,
        ui: {
          ...state.ui,
          loading: false,
        },
      };
    case ProductActions.DELETED_PRODUCT:
      return {
        ...state,
        data: _.omit({ ...state.data }, action.productId),
        order: _.pull(state.order as any, action.productId),
      };
    case ProductActions.UPDATE_PRODUCT_WEIGHT:
      return {
        ...state,
        order: action.order,
      };
    case ProductActions.DESTROY_LIST:
      return {
        data: {},
        order: [],
        ui: {
          ...state.ui,
          loading: true,
        },
      };
    default:
      return {
        ...state,
        data: nextData,
      };
  }
}

import React from 'react';
import { CustomFieldType } from '../../../constants/customFieldTypes';
import { email, formPhoneNumber, integer, numeric, required } from '../../../services/app/validators';
import { Field } from 'redux-form';
import { WorkOrderTemplate } from '../../../types/WorkOrderTemplate';
import { RenderTextField } from '../../ui/form/RenderTextField';
import { Grid, MenuItem } from '@material-ui/core';
import { RenderSelectField } from '../../ui/form/RenderSelectField';

interface Props {
  template?: Pick<WorkOrderTemplate, "customFields">;
  isPublicView?: boolean;
}

export const CustomFieldRenderHelper: React.FC<Props> = (props: Props) => {
  const { isPublicView, template } = props;
  const customFields = template?.customFields || [];
  return (
    <Grid container direction="column" wrap="nowrap" spacing={2}>
      {customFields.map((field) => {
        const isReadOnly = isPublicView && !field.isEditable;
        switch (field.type) {
          case CustomFieldType.SELECT.key:
            return (
              <Grid item key={field.name}>
                <Field
                  name={field.name}
                  label={field.title}
                  validate={field.isRequired ? required : []}
                  required={!!field.isRequired}
                  component={RenderSelectField}
                  readOnly={isReadOnly}
                >
                  {field.options?.values?.map((option: string) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </Field>
              </Grid>
            );
          case CustomFieldType.EMAIL.key:
            return (
              <Grid item key={field.name}>
                <Field
                  name={field.name}
                  type="email"
                  label={field.title}
                  validate={field.isRequired ? [required, email] : email}
                  required={!!field.isRequired}
                  component={RenderTextField}
                  readOnly={isReadOnly}
                />
              </Grid>
            );
          case CustomFieldType.PHONE.key:
            return (
              <Grid item key={field.name}>
                <Field
                  name={field.name}
                  type="text"
                  label={field.title}
                  validate={field.isRequired ? [required, formPhoneNumber] : formPhoneNumber}
                  required={!!field.isRequired}
                  component={RenderTextField}
                  readOnly={isReadOnly}
                />
              </Grid>
            );
          case CustomFieldType.INPUT.key:
            return (
              <Grid item key={field.name}>
                <Field
                  name={field.name}
                  type="text"
                  label={field.title}
                  validate={field.isRequired ? required : []}
                  required={!!field.isRequired}
                  component={RenderTextField}
                  readOnly={isReadOnly}
                />
              </Grid>
            );
          case CustomFieldType.NUMERIC.key:
            return (
              <Grid item key={field.name}>
                <Field
                  name={field.name}
                  type="numeric"
                  label={field.title}
                  validate={field.isRequired ? [required, integer] : integer}
                  required={!!field.isRequired}
                  component={RenderTextField}
                  readOnly={isReadOnly}
                />
              </Grid>
            );
            case CustomFieldType.DECIMAL.key:
            return (
              <Grid item key={field.name}>
                <Field
                  name={field.name}
                  type="decimal"
                  label={field.title}
                  validate={field.isRequired ? [required, numeric] : numeric}
                  required={!!field.isRequired}
                  component={RenderTextField}
                  readOnly={isReadOnly}
                />
              </Grid>
            );
          default:
            return (
              <React.Fragment key={field.name}></React.Fragment>
            );
        }
      })}
    </Grid>
  );
};

export default CustomFieldRenderHelper;

import React from 'react';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { required, emailOrPhoneNumber } from '../../services/app/validators';
import { LargeButton, LargePrimaryButton } from '../ui/Buttons';
import { RenderTextField } from '../ui/Form';
import { Grid } from '@roadsync/roadsync-ui';
import InvoicePrepButtonRow from './fullScreenStepper/InvoicePrepButtonRow';

export interface SendDriverCheckNumberFormData {
  phoneNumberOrEmail?: string;
}

interface OwnProps {
  onPrevStep?: () => void;
}

type Props = InjectedFormProps<SendDriverCheckNumberFormData, OwnProps> & OwnProps;

// eslint-disable-next-line max-lines-per-function
const SendDriverCheckNumber: React.FC<Props> = (props: Props): React.ReactElement => {
  const { handleSubmit, submitting, onPrevStep } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        <Grid item>
          <Field
            name="phoneNumberOrEmail"
            label="Driver phone number or email"
            type="text"
            component={RenderTextField}
            validate={[required, emailOrPhoneNumber]}
          />
        </Grid>
        <Grid item>
          <InvoicePrepButtonRow secondaryBtn={
            <LargeButton variant="outlined" onClick={onPrevStep} id="send-check-number-back-btn">Back</LargeButton>
          }>
            <LargePrimaryButton disabled={submitting} type="submit" id="send-check-number-submit-btn">
              Continue
            </LargePrimaryButton>
          </InvoicePrepButtonRow>
        </Grid>
      </Grid>
    </form>
  );
};

export default reduxForm<SendDriverCheckNumberFormData, OwnProps>({ form: 'sendDriverCheckNumber' })(SendDriverCheckNumber);

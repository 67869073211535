import React from "react";
import { isCardEnabled, isCreditCardAtPublicCheckoutOnlyEnabled } from "../../../services/app/company";
import { CardAccountStatus } from "../../../types/CardAccountStatus";
import { Company } from "../../../types/Company";

interface Props {
  company?: Partial<Company>;
  status?: Partial<CardAccountStatus>;
  children?: React.ReactNode;
}

const IfCanUseCreditCard: React.FC<Props> = (props: Props) => {
  const { company, children, status } = props;
  return (
    <>{isCardEnabled(company) && !isCreditCardAtPublicCheckoutOnlyEnabled(company) && !!status?.externalAccount && children}</>
  );
}

export default IfCanUseCreditCard;

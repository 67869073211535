import React from "react";

export interface Props {
  value: string;
  regex: RegExp;
  message: string;
}

const PasswordRequirement: React.FC<Props> = (props: Props) => {
  const isValid = props.regex.test(props.value);

  return (
    <li style={{ color: isValid ? "#1CA531" : "", marginBottom: "10px" }}>
      {isValid ? "✓" : "✗"} {props.message}
    </li>
  );
};

export default PasswordRequirement;

import { ConsoleLogger } from "./ConsoleLogger";
import { LogLevel } from "./LogLevel";
import { version, environment } from "../LoggerService";

export class GenericLogger extends ConsoleLogger {

  public warn(msg: string, ...data: any[]): void {
    if (this.level() < LogLevel.Warn) {
      return;
    }
    this.captureMessage(msg, this.getData("warn", ...data));
  }

  public error(msg: string, ...data: any[]): void {
    if (this.level() < LogLevel.Error) {
      return;
    }
    this.captureMessage(msg, this.getData("error", ...data));
  }

  private getData(level: string, ...data: any[]): {} {
    return {
      tags: { version: version(), level },
      extra: {
        data: JSON.stringify(data),
        domain: document.domain,
        environment: environment(),
      },
    }
  }
}

import React from 'react';
import { connect } from 'react-redux';
import { ModalsConstants } from '../../constants/modals';
import ModalContent from '../../components/modals/ModalContent';
import { closeModal } from '../../actions/modals';
import { LargePrimaryButton, LargeSecondaryButton } from '../../components/ui/Buttons';

interface Props {
  dispatch: Function;
  text: string;
  handleInvoiceCreation: Function;
}
class ConfirmInvoiceCreation extends React.Component<Props> {

  handleInvoiceCreation(): void {
    const { handleInvoiceCreation } = this.props;
    this.closeModal();
    handleInvoiceCreation();
  }

  closeModal(): void {
    const { dispatch } = this.props;
    dispatch(closeModal(ModalsConstants.CONFIRM_INVOICE_CREATION));
  }
  // eslint-disable-next-line
  render(): React.ReactElement {
    const { text } = this.props;
    return (
      <ModalContent className="invoice-creation-confirm-modal">
        <p className="modal-message">{text}</p>
        <LargePrimaryButton onClick={(): void => { this.closeModal() }}>
          No
              </LargePrimaryButton>
        <LargeSecondaryButton
          variant="outlined"
          onClick={(): void => { this.handleInvoiceCreation() }}
        >
          Yes
        </LargeSecondaryButton>
      </ModalContent>
    );
  }
}

export default connect()(ConfirmInvoiceCreation);

import { makeStyles, createStyles, Theme} from '@material-ui/core';

const styles = makeStyles((theme: Theme) => createStyles({
  spinner: {
    textAlign: 'center',
    margin: '5px 0',
    '& div': {
      width: 18,
      height: 18,
      backgroundColor: theme.palette.primary.main,
      borderRadius: '100%',
      display: 'inline-block',
      webkitAnimation: '$sk-bouncedelay 1.4s infinite ease-in-out both',
      animation: '$sk-bouncedelay 1.4s infinite ease-in-out both',
    },
    '& .bounce1': {
      webkitAnimationDelay: '-0.32s',
      animationDelay: '-0.32s',
    },
    '& .bounce2': {
      webkitAnimationDelay: '-0.16s',
      animationDelay: '-0.16s',
    },
    '.add-signature-modal &, .copy-location &': {
      width: 300,
      height: 150,
      bottom: 20,
      position: 'absolute',
      verticalAlign: 'middle',
      lineHeight: '150px',
      left: 'calc(50% - 150px)',
      top: 38,
    }
  },
  '@-webkit-keyframes sk-bouncedelay': {
    '0%, 80%, 100%': {
      webkitTransform: 'scale(0)',
    },
    '40%': {
      webkitTransform: 'scale(1.0)',
    }
  },
  '@keyframes sk-bouncedelay': {
    '0%, 80%, 100%': {
      webkitTransform: 'scale(0)',
      transform: 'scale(0)',
    },
    '40%': {
      webkitTransform: 'scale(1.0)',
      transform: 'scale(1.0)',
    }
  }
}));

export default styles;

import React from "react";
import { PaymentMethods } from "../../constants/invoice";
import { WithInvoiceProps } from "../ui/WithInvoiceProps";
import { CheckTypes } from "../../constants/deposit";
import useStyles from "./InvoiceTypeLabel.css";

const InvoiceTypeLabel: React.FC<WithInvoiceProps> = (props: WithInvoiceProps): React.ReactElement => {
  const { invoice } = props;
  const initialType = PaymentMethods.getByKey(invoice.initialType);
  const type = PaymentMethods.getByKey(invoice.type);
  const classes = useStyles();
  return (
    <>
      {initialType && initialType !== type && <span className={classes.span}>{initialType?.display}</span>}
      <span className={classes.span}>
        {type?.display}{invoice.subtype && <> / {CheckTypes.getByKey(invoice.subtype)?.display}</>}
      </span>
    </>
  );
}

export default React.memo(InvoiceTypeLabel);

import { CarrierActions } from '../constants/actions';
import _ from 'lodash';
import { mergeStateAndEntityResult } from '../services/app/normalizr';
import { Carrier } from '../types/carrier';

export interface CarriersState {
  data: { [id: string]: Carrier };
  entities?: { [k: string]: Carrier };
  ui?: { loading?: boolean };
  order?: string[];
}

interface CarriersAction {
  type: string;
  carrierId?: string;
  entities?: { carriers: { [k: string]: Carrier } };
  order?: string[];
}

export function carriers(state: CarriersState = { data: {}, ui: {}, order: [] }, action: CarriersAction = { type: "unknown" }): CarriersState {
  const nextData = mergeStateAndEntityResult(state, action.entities, 'carriers');
  switch (action.type) {
    case CarrierActions.REQUEST_CARRIER:
      return _.set({ ...state }, `data.${action.carrierId}`, { loading: true });
    case CarrierActions.REQUEST_CARRIER_LIST:
      return { ...state, ui: { ...state.ui, loading: true } };
    case CarrierActions.RECEIVE_CARRIER_LIST:
      return {
        ...state,
        data: nextData,
        order: action.order,
        ui: { loading: false },
      };
    case CarrierActions.DELETED_CARRIER:
      return {
        ...state,
        data: _.omit({ ...state.data }, action.carrierId as string),
        order: _.pull(state.order || [], action.carrierId as string),
      };
    default:
      return { ...state, data: nextData };
  }
}

import React from 'react';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { emailOrPhoneNumber, required } from '../../services/app/validators';
import { Error } from '../ui/Error';
import { RenderTextField } from '../ui/Form';
import { P, Grid } from '@roadsync/roadsync-ui';

export interface SendRemoteCheckoutFormData {
  phoneOrEmail: string;
}

type Props = InjectedFormProps<SendRemoteCheckoutFormData>;

const SendRemoteCheckout: React.FC<Props> = (props: Props) => {
  const { error, handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        {error && <Error error={error} />}
        <Grid item>
          <P variant="h4" gutterBottom>Enter the recipient’s email or phone number. </P>
        </Grid>
        <Grid item>
          <Field
            name="phoneOrEmail"
            label="Payer Email or Phone Number"
            type="text"
            component={RenderTextField}
            validate={[required, emailOrPhoneNumber]}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default reduxForm<SendRemoteCheckoutFormData>({ form: 'sendRemoteCheckout' })(SendRemoteCheckout);

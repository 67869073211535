import _ from 'lodash';

export interface Type {
  key: string;
  display: string;
  description: string;
}

export class ProductType {
  static readonly SERVICE: Type = {
    key: 'service',
    display: 'Service',
    description: 'This type of product is meant for salable services provided by your company. ' +
      'When adding to an invoice, this type of product has a fixed price and a fixed quantity.',
  };

  static readonly ITEM: Type = {
    key: 'item',
    display: 'Item',
    description: 'This type of product is meant for products held in inventory by your company. ' +
      'When adding to an invoice, this type of product has a fixed price and an editable quantity.',
  };

  static readonly CUSTOM: Type = {
    key: 'custom',
    display: 'Custom Amount',
    description: 'This type of product has a default price that is editable when adding the product to an invoice.',
  };

  static readonly OTHER: Type = {
    key: 'other',
    display: 'Other',
    description: 'This type of product is similar to a Custom Amount product but allows editing ' +
      'both the price and the name of the product when adding it to an invoice.',
  };

  static readonly ADD_MULTIPLE_LINE_ITEMS: Type = {
    key: 'addmultiplelineitems',
    display: 'Add multiple line items',
    description: 'This type of product is special and is used any time the "Add Line Items" ' +
      'button on the POS screen is used to add multiple custom line items to an invoice.',
  };

  static readonly TAX: Type = {
    key: 'tax',
    display: 'Tax',
    description: 'This type of product facilitates adding taxes.',
  };

  static readonly CATEGORY: Type = {
    key: 'category',
    display: 'Category',
    description: 'This type of product facilitates creating a grouping of products. ' +
      'You can create a category and then assign other products as children of it. ' +
      'You can have up to 2 categories nested together.',
  };

  private static readonly LOOKUP = {
    'service': ProductType.SERVICE,
    'item': ProductType.ITEM,
    'custom': ProductType.CUSTOM,
    'other': ProductType.OTHER,
    'addmultiplelineitems': ProductType.ADD_MULTIPLE_LINE_ITEMS,
    'category': ProductType.CATEGORY,
    'tax': ProductType.TAX,
  };

  static getTypes(): Type[] {
    return _.values(ProductType.LOOKUP);
  }

  static getByKey(key: string): Type {
    return ProductType.LOOKUP[key];
  }
}

export class ProductSelectType {
  static readonly NONE: Type = {
    key: 'none',
    display: 'None',
    description: 'Product with this select type will be ' +
      'available to select when creating an invoice. You will' +
      ' also be able to edit the price when creating an invoice.',
  };
  static readonly OBLIGATORY: Type = {
    key: 'obligatory',
    display: 'Obligatory',
    description: 'Products with this select type will ' +
      'automatically be added onto all invoices, and you will NOT' +
      ' be able to remove it or edit it when creating an invoice.',
  };
  static readonly PRESELECTED: Type = {
    key: 'preselected',
    display: 'Preselected',
    description: 'Products with this select type will automatically be added onto all invoices.' +
      ' You CAN remove it or adjust the price when creating an invoice',
  };

  private static readonly LOOKUP = {
    'none': ProductSelectType.NONE,
    'obligatory': ProductSelectType.OBLIGATORY,
    'preselected': ProductSelectType.PRESELECTED,
  };

  static getTypes(): Type[] {
    return _.values(ProductSelectType.LOOKUP);
  }

  static getByKey(key: string): Type {
    return ProductSelectType.LOOKUP[key];
  }
}

export class TaxSelectType {
  static readonly NONE: Type = {
    key: 'none',
    display: 'None',
    description: 'Tax with this select type will be ' +
      'available to select when creating an invoice. You will' +
      ' also be able to edit the price when creating an invoice.',
  };

  static readonly OBLIGATORY: Type = {
    key: 'obligatory',
    display: 'Obligatory',
    description: 'Taxes with this select type will ' +
      'automatically be added onto all invoices, and you will NOT' +
      ' be able to remove it or edit it when creating an invoice.',
  };

  static readonly PRESELECTED: Type = {
    key: 'preselected',
    display: 'Preselected',
    description: 'Taxes with this select type will automatically be added onto all invoices.' +
      ' You CAN remove it or adjust the price when creating an invoice',
  };

  private static readonly LOOKUP = {
    'none': TaxSelectType.NONE,
    'obligatory': TaxSelectType.OBLIGATORY,
    'preselected': TaxSelectType.PRESELECTED,
  };

  static getTypes(): Type[] {
    return _.values(TaxSelectType.LOOKUP);
  }

  static getByKey(key: string): Type {
    return TaxSelectType.LOOKUP[key];
  }
}

export interface ProductUnitType {
  key: string;
  display: string;
}

export class ProductUnit {
  static readonly SINGLE: ProductUnitType = {
    key: 'single',
    display: 'Single',
  };

  static readonly WHOLE: ProductUnitType = {
    key: 'whole',
    display: 'Whole',
  };

  static readonly PARTIAL: ProductUnitType = {
    key: 'partial',
    display: 'Partial',
  };

  private static readonly LOOKUP = {
    'service': ProductUnit.SINGLE,
    'item': ProductUnit.WHOLE,
    'custom': ProductUnit.PARTIAL,
  };

  static getTypes(): ProductUnitType[] {
    return _.values(ProductUnit.LOOKUP);
  }

  static getByKey(key: string): ProductUnitType {
    return ProductUnit.LOOKUP[key];
  }
}

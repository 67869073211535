const alertSoundFile = require('../../assets/audio/softAlert.mp3')?.default;
const harshAlertSoundFile = require('../../assets/audio/alertV2.mp3')?.default;
const supportMessageAlertSoundFile = require('../../assets/audio/supportMessageAlert.mp3')?.default;

export let alertSound: HTMLAudioElement | undefined = undefined;
export let harshSound: HTMLAudioElement | undefined = undefined;
export let supportMsgSound: HTMLAudioElement | undefined = undefined;

function setUpSounds(): void {
  if ("Audio" in window) {
    alertSound = new Audio(alertSoundFile);
    harshSound = new Audio(harshAlertSoundFile);
    supportMsgSound = new Audio(supportMessageAlertSoundFile);
  }
}

export async function ensureNotificationsEnabled(): Promise<boolean> {
  if ("Notification" in window === false) {
    return false;
  }
  setUpSounds();
  return await Notification.requestPermission() === 'granted';
}

export async function showNotification(title: string, body: string, harsh?: boolean, muteSound?: boolean): Promise<void> {
  await createNotification(title, body);
  if (muteSound) {
    return;
  }
  if (harshSound && harsh) {
    return harshSound.play();
  }
  if (alertSound) {
    return alertSound.play();
  }
}

export async function showSupportMessageNotification(title: string, body: string, muteSound?: boolean): Promise<void> {
  await createNotification(title, body);
  if (!muteSound && supportMsgSound) {
    return supportMsgSound.play();
  }
}

async function createNotification(title: string, body: string): Promise<Notification> {
  await ensureNotificationsEnabled();
  return new Notification(title, { body });
}

import React from 'react';
import { connect } from 'react-redux';
import { User } from '../../../types/User';

interface Props {
  children?: React.ReactNode;
  otherwise?: React.ReactNode;
  user?: User;
}

interface State {
  auth: {
    me?: User;
  };
}

const IfGuest: React.FC<Props> = (props: Props): React.ReactElement => {
  return (
    <>
      {props.user?.id && props.otherwise}
      {!props.user?.id && props.children}
    </>
  );
}

const mapStateToProps = (state: State): { user?: User } => ({ user: state.auth?.me });
export default connect(mapStateToProps)(IfGuest);

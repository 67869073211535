import { createStyles, makeStyles, Theme } from "@material-ui/core";

const styles = makeStyles(() => createStyles({
  field42: {
    '& [class*="MuiFormLabel-root"]': {
      transform: 'translate(14px, 14px) scale(0.85)',
      '&[class*="MuiInputLabel-shrink"]': {
        transform: 'translate(14px, -6px) scale(0.75)',
      }
    },
    '& [class*="MuiSelect-root"]': {
      padding: '11.5px 14px',
      fontSize: 14,
    }
  },
}));

export default styles;

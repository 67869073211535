import React from 'react';
import { Product } from '../../types/Product';
import { ProductType, ProductUnit } from '../../constants/product';
import Loader from '../app/Loader';
import ProductTypeCustomSelector from "./ProductTypeCustomSelector";
import ProductTypeTaxSelector from './ProductTypeTaxSelector';
import ProductTypeMultipleLineItemsSelector from './ProductTypeMultipleLineItemsSelector';
import ProductTypeOtherSelector from "./ProductTypeOtherSelector";
import ProductTypeItemSelector from "./ProductTypeItemSelector";
import ProductTypeServiceSelector from "./ProductTypeServiceSelector";
import { ProductGridType } from '../../types/UserInterface';
import { isDecimalTwoPlaces } from '../../services/app/validators';
import { round } from '../../services/app/invoice';
import InputDecimal from '../controls/InputDecimal';
import InputNumber from '../controls/InputNumber';

export const shouldUpdateProductQtyDecimalValue = (value: string) => {
  return isDecimalTwoPlaces(value) && round(Number(value), 2) * 100 > 9;
}

export function shouldPreventUpdateProductQtyDecimalValue(event?: React.ChangeEvent<HTMLInputElement>): boolean {
  const value = event?.currentTarget.value || '';
  return !shouldUpdateProductQtyDecimalValue(value);
}

export function displayProductQtyFieldBasedOnProductUnitValue(product: Product, handleChange: (value: string | number | undefined) => void, id: string, qty?: number) {
  if (product && product.unit === ProductUnit.WHOLE.key) { // Whole Units -> default 1 & numeric
    return <InputNumber id={`input-number-${id}`} value={qty || undefined} onChange={handleChange} />
  }   
  if (product.unit === ProductUnit.PARTIAL.key) { // Partial Units -> default 1 & min 0.1 & decimal
    return <InputDecimal id={`input-decimal-${id}`} value={qty || undefined} onChange={handleChange} shouldPreventUpdateValue={shouldPreventUpdateProductQtyDecimalValue} />
  }
  return <></>; // ProductUnit.SINGLE.key
}

export interface ProductSelectorProps {
  id: string;
  product: Product;
  selected: boolean;
  desc?: string;
  qty?: number;
  lineItemsSum?: number;
  lineItemsQty?: number;
  multipleLineItems: number | '';
  onToggle: (productId: string) => void;
  onChange: (productId: string, value?: string | number) => void;
  onCustomize?: (productId: string) => void;
  gridType?: ProductGridType;
}

const ProductSelector: React.FC<ProductSelectorProps> = (props: ProductSelectorProps) => {
  const { product } = props;
  switch (product.type) {
    case ProductType.SERVICE.key:
      return <ProductTypeServiceSelector {...props} />;
    case ProductType.ITEM.key:
      return <ProductTypeItemSelector {...props} />;
    case ProductType.CUSTOM.key:
      return <ProductTypeCustomSelector {...props} />;
    case ProductType.OTHER.key:
      return <ProductTypeOtherSelector {...props} />;
    case ProductType.ADD_MULTIPLE_LINE_ITEMS.key:
      return <ProductTypeMultipleLineItemsSelector {...props} />;
    case ProductType.TAX.key:
      return <ProductTypeTaxSelector {...props} />;
    default:
      return <Loader />;
  }
};

export default ProductSelector;

import { createStyles, makeStyles, Theme } from '@material-ui/core';

const styles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: "100%",
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    overflowY: "auto",
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(4),
    }
  },
  backBtn: {
    position: "absolute",
    left: theme.spacing(1),
    top: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      left: theme.spacing(.5),
      top: theme.spacing(.5),
    }
  },
  closeBtn: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      right: theme.spacing(.5),
      top: theme.spacing(.5),
    }
  }
}));

export default styles;

import React from "react";
import { isEqual } from "lodash";
import { Typography, Grid, Box, Dialog, DialogContent } from "@material-ui/core";
import { LargePrimaryButton, LargeSecondaryButton } from "../ui/Buttons";
import warningIcon from "../../assets/img/icons/invoicePriceUpdatedWarningIcon.svg";
import useStyles from "./InvoicePriceUpdatedModal.css";

interface Props {
    handleCancel: () => void;
    handleTryAgain: () => void;
    open?: boolean;
    initialAmount: string;
    updatedAmount: string;
}

const areEqual = (prevProps: Props, nextProps: Props) => isEqual(prevProps, nextProps);

const InvoicePriceUpdatedModal: React.FC<Props> = React.memo((props: Props) => {
    const { handleCancel, handleTryAgain, open, initialAmount, updatedAmount } = props;
    const classes = useStyles();

    const baseId = 'invoice-price-update-modal';

    return (
        <Dialog id={baseId} data-testid={baseId} className={classes.dialog} PaperProps={{ square: false }} fullWidth maxWidth='sm' open={open ?? false}>
            <DialogContent>
                <Grid container direction="column" alignItems="center" spacing={4}>
                    <Grid item container justifyContent="center" alignItems="center" spacing={0} direction="column">
                        <Grid item>
                            <Box height={60} />
                        </Grid>
                        <Grid item container justifyContent="center">
                            <img src={warningIcon} alt="icon" />
                        </Grid>
                        <Grid item>
                            <Box height={32} />
                            <Typography component="div" variant="h4" className={classes.dialogTitle}>There is a mismatch in the amount. Please try again with a new amount.</Typography>
                            <Box height={20} />
                        </Grid>
                        <Grid item>
                            <Box className={classes.dialogText}>Initial Amount: {initialAmount} USD</Box>
                        </Grid>
                        <Grid item>
                            <Box className={classes.dialogText}>Updated Amount: <Box component='span' className={classes.dialogTextGreen}>{updatedAmount} USD</Box></Box>
                            <Box height={8} />
                        </Grid>
                    </Grid>
                    <Grid item className={classes.gridItemBtn}>
                        <Grid container direction="column" wrap="nowrap" spacing={2}>
                            <Grid item>
                                <LargePrimaryButton
                                    id="retry-btn"
                                    data-testid="retry-btn"
                                    onClick={handleTryAgain}
                                    fullWidth
                                    type="button"
                                    className={classes.textTransformNone}
                                >
                                    Retry Payment
                                </LargePrimaryButton>
                            </Grid>
                            <Grid item>
                                <LargeSecondaryButton
                                    id="cancel-btn"
                                    data-testid="cancel-btn"
                                    onClick={handleCancel}
                                    fullWidth
                                    variant="outlined"
                                >
                                    Cancel
                                </LargeSecondaryButton>
                            </Grid>
                            <Grid item />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent >
        </Dialog >
    );
}, areEqual);

export default InvoicePriceUpdatedModal;

import { CheckAuthorizerActions } from '../constants/actions';
import { DepositStatuses } from '../constants/deposit';
import _ from 'lodash';
import { CheckAuthorizerDeposit } from '../types/CheckAuthorizerDeposit';

export interface CheckAuthorizerState {
  data: { [depositId: string]: CheckAuthorizerDeposit };
  ui: { loading?: boolean };
}

// eslint-disable-next-line max-lines-per-function
export function checkAuthorizer(
  state: CheckAuthorizerState = { data: {}, ui: {} },
  action: Record<string, any> = {}
): CheckAuthorizerState {
  switch (action.type) {
    case CheckAuthorizerActions.REQUEST_PROCESSING_CHECKS:
      return { ...state, ui: { loading: true } };
    case CheckAuthorizerActions.RECEIVE_PROCESSING_CHECKS:
      return { ...state, ui: { loading: false } };
    case CheckAuthorizerActions.RECEIVE_CLAIM_DEPOSIT:
    case CheckAuthorizerActions.RECEIVE_APPROVE_DEPOSIT:
    case CheckAuthorizerActions.RECEIVE_DECLINE_DEPOSIT:
    case CheckAuthorizerActions.RECEIVE_DEPOSIT: {
      if (action.deposit.status === DepositStatuses.PROCESSING) {
        return {
          ...state,
          data: { ...state.data, [action.deposit.id]: { ...state.data[action.deposit.id], ...action.deposit, } },
        };
      }
      return { ...state, data: _.omit({ ...state.data }, action.deposit.id) };
    }
    case CheckAuthorizerActions.SET_BEEPED: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.deposit.id]: {
            ...state.data[action.deposit.id],
            beeped: true,
          },
        },
      };
    }
    default:
      return { ...state };
  }
}

import SaveIcon from '@material-ui/icons/Save';
import EmailIcon from '@material-ui/icons/Email';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PersonIcon from '@material-ui/icons/Person';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import DoneAllIcon from '@material-ui/icons/DoneAll';

export {
  SaveIcon,
  EmailIcon,
  ReceiptIcon,
  AddIcon,
  CloseIcon,
  ArrowBackIcon,
  VisibilityIcon,
  VisibilityOffIcon,
  NavigateNextIcon,
  PersonIcon,
  PhoneAndroidIcon,
  DoneAllIcon,
}

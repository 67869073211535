import { AppConfig } from '../../types/AppConfig';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as HTTP from './http';
import { AppSettingsEndpoints } from './endpoints/AppSettingsEndpoints';

export function getAppSettings(): Promise<AppConfig> {
  return HTTP.get(AppSettingsEndpoints.getSettings());
}

export function getAppSettingsPublic(): Promise<AppConfig> {
  return HTTP.get(AppSettingsEndpoints.getSettingsPublic(), false);
}

export function getPublicAppSettings(): Observable<AppConfig> {
  return from(getAppSettingsPublic()).pipe(
    map(r => Object.assign({} as any, r))
  );
}

import React from "react";
import { FeatureFlag, FeatureFlagContainer } from '../ui/Visibility';
import { sendInvoiceReceiptViaSMSToPayer } from '../../services/api/invoices';
import { Invoice } from "../../types";

interface State {
  isSent?: boolean;
}

interface Props {
  token?: string;
  invoice?: Invoice;
}

class SendInvoiceReceiptViaSMSToPayer extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  sendInvoice(): React.ReactNode {
    const { isSent } = this.state;
    const { token, invoice } = this.props;
    const payerPhone = invoice?.payerPhone;
    if (!isSent && token && payerPhone) {
      this.setState({ isSent: true }, () => {
        sendInvoiceReceiptViaSMSToPayer(token, payerPhone);
      });
    }
    return null;
  }

  render(): React.ReactElement {
    return (
      <FeatureFlagContainer flag={FeatureFlag.DriversReceiptExperiment}>
        {this.sendInvoice()}
      </FeatureFlagContainer>
    );
  }
}

export default SendInvoiceReceiptViaSMSToPayer;

import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { submit } from 'redux-form';
import { ModalsConstants } from '../../constants/modals';
import ModalContent from '../../components/modals/ModalContent';
import { closeModal } from '../../actions/modals';
import LineItemCustomServiceForm, { LineItemCustomServiceFormData } from '../invoice/preparation/steps/LineItemCustomServiceForm';

interface Props extends DispatchProp {
  addService: (description: string, amount: number) => void;
  removeService: () => void;
  description: string;
  amount: number;
  selected?: boolean;
  isObligatory?: boolean;
}

class InvoiceLineItemCustomService extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalSubmit = this.handleModalSubmit.bind(this);
    this.handleModalRemove = this.handleModalRemove.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleModalClose(): void {
    const { dispatch } = this.props;
    dispatch(closeModal(ModalsConstants.LINE_ITEMS_CUSTOM_SERVICE));
  }

  handleModalSubmit(): void {
    const { dispatch } = this.props;
    dispatch(submit('lineItemCustomService'));
  }

  handleModalRemove(): void {
    const { removeService, dispatch } = this.props;
    removeService();
    dispatch(closeModal(ModalsConstants.LINE_ITEMS_CUSTOM_SERVICE));
  }

  handleFormSubmit(values: LineItemCustomServiceFormData): void {
    const { addService, dispatch } = this.props;
    addService(values.description, values.amount);
    dispatch(closeModal(ModalsConstants.LINE_ITEMS_CUSTOM_SERVICE));
  }

  render(): React.ReactElement {
    const { selected, isObligatory, description, amount } = this.props;
    return (
      <ModalContent
        className="line-item-custom-service-modal"
        primaryActionText={selected ? 'Update' : 'Add'}
        primaryActionClick={this.handleModalSubmit}
        secondaryActionText="Remove"
        secondaryActionClick={selected && !isObligatory ? this.handleModalRemove : undefined}
      >
        <button onClick={this.handleModalClose} className="link-close icon-close cursor-pointer" />
        <LineItemCustomServiceForm initialValues={{ description, amount }} onSubmit={this.handleFormSubmit} />
      </ModalContent>
    );
  }
}

export default connect()(InvoiceLineItemCustomService);

import React from 'react';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { required } from '../../../../services/app/validators';
import { formatMoneyOnly, maxLength } from '../../../../services/app/formats';
import { Error } from '../../../../components/ui/Error';
import { RenderTextField } from '../../../../components/ui/Form';
import { Grid } from '@material-ui/core';

export interface LineItemCustomServiceFormData {
  description: string;
  amount: number;
  isTaxable?: boolean;
}

type Props = InjectedFormProps<LineItemCustomServiceFormData>;

const handleFirstFocus = (event: React.ChangeEvent<HTMLInputElement>): void => {
  event.target.select();
  event.target.onfocus = null;
};

const maxLengthValidator = maxLength(116);

// eslint-disable-next-line max-lines-per-function
const LineItemCustomServiceForm: React.FC<Props> = (props: Props) => {
  const { error, handleSubmit, anyTouched } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        {anyTouched && <Grid item><Error error={error} /></Grid>}
        <Grid item>
          <Field
            name="description"
            label="Description"
            type="text"
            component={RenderTextField}
            validate={[required, maxLengthValidator]}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name="amount"
            label="Amount"
            type="number"
            onFocus={handleFirstFocus}
            inputProps={{ 'data-testid': 'price' }}
            component={RenderTextField}
            normalize={formatMoneyOnly}
            validate={required}
            required
            className="product-other-amount"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default reduxForm<LineItemCustomServiceFormData>({ form: 'lineItemCustomService' })(LineItemCustomServiceForm);

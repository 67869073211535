import React from 'react';
import { Field } from 'redux-form';
import { WorkOrderTemplate } from '../../types/WorkOrderTemplate';
import { connect } from 'react-redux';
import { getWorkOrderTemplates } from '../../actions/workOrderTemplates';
import { WorkOrderTemplateStatus } from '../../types/WorkOrderTemplateStatus';
import { Error } from '../ui/Error';
import { MenuItem } from '@material-ui/core';
import { RenderSelectField } from '../ui/form/RenderSelectField';
import { required } from '../../services/app/validators';

interface Props {
  locationId: string;
  name?: string;
  label?: string;
  validate?: any;
  dispatch: Function;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  workOrderTemplates: {
    data: { [k: string]: { loading?: boolean } | WorkOrderTemplate[] };
  };
  setDefaultWorkOrderTemplate?: Function;
}

function filterTemplates(templates: WorkOrderTemplate[]): WorkOrderTemplate[] {
  return templates.filter(t => t.status === WorkOrderTemplateStatus.Active)
}

export const WorkOrderTemplateSelectFieldMUI: React.FC<Props> = (props: Props): React.ReactElement => {
  const templates = props.workOrderTemplates?.data?.[props.locationId];
  const { onChange, validate, ...rest } = props;
  if (!templates) {
    setTimeout(() => props.dispatch(getWorkOrderTemplates(props.locationId)));
  }
  const templatesToShow = Array.isArray(templates) ? filterTemplates(templates) : [];
  const error = !templatesToShow.length ? 'No work order templates are configured for this location' : '';
  const defaultValue = Array.isArray(templates) && templates?.[0]?.id;

  return <>
    <Error error={error} />
    {defaultValue &&
      <Field
        type="select"
        component={RenderSelectField}
        validate={required}
        required={true}
        onChange={onChange}
        {...rest}
      >
        {templatesToShow.map((tz) => <MenuItem key={tz.id} value={tz.id}>{tz.templateName}</MenuItem>)}
      </Field>
    }
  </>;
}

const mapStateToProps = ({ workOrderTemplates }) => ({ workOrderTemplates });
export default connect(mapStateToProps)(WorkOrderTemplateSelectFieldMUI);

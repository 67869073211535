import React, { useEffect } from 'react';
import { Form, Field } from "react-final-form";
import { driversLicenseNumber, fuelCardComdataValidateForm, state, required, numeric } from "../../services/app/validators";
import { Grid } from '@material-ui/core';
import { ComdataFuelCardChargeRequest, Invoice, PreEditResponseParsed } from "../../types";
import { TextField } from "final-form-material-ui";
import { isEqual } from "lodash";
import SignatureBox from '../ui/SignatureBox';
import { requiredByIndicatorValue } from '../../services/app/comdata/preEditParser';
import { DatePicker } from '@material-ui/pickers';
import { upperCase } from '../../services/app/forms';
import { composeValidators } from '@roadsync/roadsync-ui';
import { formatNumbersOnly } from '../../services/app/formats';

interface Props {
  invoice?: Invoice;
  handleSubmitCharge: (values: Record<string, any>) => Promise<void>;
  children?: React.ReactNode;
  preEditResponseParsed?: PreEditResponseParsed | null;
  loading?: boolean;
  setDirty: () => void;
  setPristine: () => void;
  setValidationErrors: (values: Record<string, any>) => void;
  initialValues?: Partial<ComdataFuelCardChargeRequest>;
  signatureEnabled?: boolean;
  signatureUrl?: string;
  openAddSignatureModal?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isCardNubmerInputRequired: boolean;
}

const ComdataFuelCardForm: React.FC<Props> = (props: Props): React.ReactElement => {

  const { handleSubmitCharge, initialValues, preEditResponseParsed, setDirty, setPristine, setValidationErrors, signatureUrl, signatureEnabled, openAddSignatureModal, isCardNubmerInputRequired } = props;

  useEffect(() => {
    if (!!initialValues) {
      setDirty();
    }
    return function cleanup() {
      setValidationErrors({});
      setPristine();
    };
  }, [setDirty, setPristine, setValidationErrors, initialValues]);

  return (
    <Form
      initialValues={initialValues}
      validate={values => fuelCardComdataValidateForm(values, setValidationErrors, preEditResponseParsed, isCardNubmerInputRequired)}
      onSubmit={handleSubmitCharge}
      render={({ handleSubmit, values }) => {
        return <>
          <button
            id="comdata-fuel-card-submit-hidden"
            type="submit"
            onClick={handleSubmit}
            hidden
          >
            Hidden EXTERNAL button
          </button>
          <form
            onSubmit={e => {
              e.preventDefault()
              handleSubmit(values);
            }}
            onChange={setDirty}
            id={comdataFuelCardFormName}
          >
            <Grid container direction="column" wrap="nowrap" spacing={2}>
              {isCardNubmerInputRequired &&
                <Grid item>
                  <Field
                    name="token"
                    component={TextField}
                    label="Card Number"
                    fullWidth
                    variant="outlined"
                    validate={composeValidators(required, numeric)}
                    required
                    parse={formatNumbersOnly}
                    id="fuel-card-number"
                  />
                </Grid>
              }
              <Grid item>
                <Field
                  name="unitNumber"
                  component={TextField}
                  label="Unit Number"
                  fullWidth
                  variant="outlined"
                  required
                  id="unit-number"
                />
              </Grid>
              <Grid item>
                <Field
                  name="cardExpiration"
                  label="Expiration Date"
                  type="text"
                  render={(props)=>
                    <DatePicker
                      placeholder='Expiration Date'
                      value={props.input.value === "" ? null : props.input.value}
                      onChange={props.input.onChange}
                      views={['year', 'month']}
                      minDate={new Date()}
                      format="MM/YYYY"
                      inputVariant='outlined'
                    />
                  }
                  error={!values.cardExpiration}
                  id="card-expiration"
                  helperText="Required if date is on card"
                />
              </Grid>
              {requiredByIndicatorValue(preEditResponseParsed?.driverNumberIndicator) &&
                <Grid item>
                  <Field
                    name="driverNumber"
                    component={TextField}
                    label="Driver Number"
                    fullWidth
                    variant="outlined"
                    required
                    id="driver-number"
                  />
                </Grid>
              }
              {requiredByIndicatorValue(preEditResponseParsed?.hubIndicator) &&
                <Grid item>
                  <Field
                    name="hubReading"
                    component={TextField}
                    label="Hub Reading"
                    fullWidth
                    variant="outlined"
                    required
                    id="hub-reading"
                  />
                </Grid>
              }
              {requiredByIndicatorValue(preEditResponseParsed?.trailerHubIndicator) &&
                <Grid item>
                  <Field
                    name="trailerHubReading"
                    component={TextField}
                    label="Trailer Hub Reading"
                    fullWidth
                    variant="outlined"
                    required
                    id="trailer-hub-reading"
                  />
                </Grid>
              }
              {requiredByIndicatorValue(preEditResponseParsed?.trailerNumberIndicator) &&
                <Grid item>
                  <Field
                    name="trailerNumber"
                    component={TextField}
                    label="Trailer Number"
                    fullWidth
                    variant="outlined"
                    required
                    id="trailer-number"
                  />
                </Grid>
              }
              {requiredByIndicatorValue(preEditResponseParsed?.trailerHoursIndicator) &&
                <Grid item>
                  <Field
                    name="trailerHours"
                    component={TextField}
                    label="Trailer Hours"
                    fullWidth
                    variant="outlined"
                    required
                    id="hub-hours"
                  />
                </Grid>
              }
              {requiredByIndicatorValue(preEditResponseParsed?.tripNumberIndicator) &&
                <Grid item>
                  <Field
                    name="tripNumber"
                    component={TextField}
                    label="Trip Number"
                    fullWidth
                    variant="outlined"
                    required
                    id="trip-number"
                  />
                </Grid>
              }
              {requiredByIndicatorValue(preEditResponseParsed?.driverLicenseNumberIndicator) &&
                <Grid item>
                  <Field
                    name="driversLicenseNumber"
                    component={TextField}
                    label="Driver License Number"
                    fullWidth
                    variant="outlined"
                    required
                    validate={driversLicenseNumber}
                    id="driver-license-number"
                  />
                </Grid>
              }
              {requiredByIndicatorValue(preEditResponseParsed?.driverLicenseNumberIndicator)
                && preEditResponseParsed?.driverLicenseStateFormat
                && preEditResponseParsed?.driverLicenseStateFormat.length > 0 &&
                <Grid item>
                  <Field
                    name="driversLicenseState"
                    component={TextField}
                    label="Driver License State"
                    fullWidth
                    variant="outlined"
                    required
                    validate={state}
                    id="driver-license-state"
                    parse={upperCase}
                  />
                </Grid>
              }
              {requiredByIndicatorValue(preEditResponseParsed?.poNumberIndicator) &&
                <Grid item>
                  <Field
                    name="poNumber"
                    component={TextField}
                    label="Po Number"
                    fullWidth
                    variant="outlined"
                    required
                    id="po-number"
                  />
                </Grid>
              }
              {signatureEnabled &&
                <Grid item>
                  <SignatureBox signatureUrl={signatureUrl} onClick={openAddSignatureModal} />
                </Grid>
              }
            </Grid>
          </form>
        </>
      }}
    />)
};

export const comdataFuelCardFormName = 'comdataFuelCardForm';
// WARNING: isEqual is a must have to avoid infinite rerenders!
// it compares prevProps and nextProps to decide if to render
export default React.memo(ComdataFuelCardForm, isEqual);

import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  dialog: {
    '& .MuiPaper-root': {
      minWidth: '400px',
      maxWidth: '500px',
      [theme.breakpoints.down(500)]: {
        minWidth: '320px',
        maxWidth: '420px',
      },
    },
    '& .MuiPaper-rounded': {
      borderRadius: '12px'
    },
    '& .MuiDialogTitle-root': {
      padding: '20px 20px 8px 20px'
    },
    '& .MuiDialogContent-root': {
      padding: '8px 20px',
      overflowY: 'hidden',
      overflowX: 'hidden',
    },
    '& .MuiListItemIcon-root': {
      minWidth: '44px',
    }
  },
  modalCloseButton: {
    position: 'absolute',
    top: '18px',
    right: '18px'
  },
  dialogTitle: {
    fontSize: 24,
    padding: '4px 0 0',
    textAlign: 'center'
  },
  icon: {
    marginTop: "3rem",
    width: '120px',
  },
  confirmationText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    lineHeight: '1.2',
    textAlign: 'center',
    marginBottom: '24px',
    padding: '0px 24px'
  },
  gridItemBtn: { width: '100%' },
  primaryBtn: {
    padding: '14px 16px 13px',
    borderWidth: '2px'
  },
  secondaryBtn: {
    padding: '14px 16px 13px',
  },
}));

export default useStyles;

import { PublicActions } from '../constants/actions';
import { AppConfig } from '../types/AppConfig';

export interface PublicAppSettingsState {
  loading: boolean;
  settings?: AppConfig;
}

interface PublicAppSettingsAction {
  type: string;
  entities?: AppConfig;
}

export function publicAppSettings(
  state: PublicAppSettingsState = { loading: true },
  action: PublicAppSettingsAction = { type: "unknown" }
): {} {
  if (action.type === PublicActions.REQUEST_APP_SETTINGS) {
    return {
      ...state,
      loading: true,
    };
  }
  if (action.type === PublicActions.RECEIVE_APP_SETTINGS) {
    return { 
      loading: false, 
      settings: action.entities 
    };
  }
  return state;
}

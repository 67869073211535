import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { CheckTypes as Types } from '../../../../constants/deposit';
import { depositCheckCreateOrReuse, unsavedProgress } from '../../../../actions/deposits';
import { Invoice, GlobalState, OnCompletedStepProps } from '../../../../types';
import { PreparationSteps } from '../../../../constants/invoice';
import InvoicePreparationStepContainer from './InvoicePreparationStepContainer';
import { LargeButton, LargePrimaryButton } from '../../../../components/ui/Buttons';
import { mapState } from '../../../../services/api/utils';
import { FeatureFlag } from "../../../../components/featureFlag/FeatureFlag";
import { getAttributes, getCompanyFromState, isTreatmentOn } from "../../../../services/app/split";
import { CheckTypes } from '../../../../constants/deposit';
import { Grid } from '@material-ui/core';
import { Error } from '../../../../components/ui/Error';
import { FullScreenLoader } from "@roadsync/roadsync-ui";

interface State {
  selectedType: string;
  error?: string;
  loading: boolean;
  isComdataV2On?: boolean;
}

type PropsFromState = Pick<GlobalState, "invoices" | "companies" | "auth" | "publicData">;

interface RouteParams {
  invoiceId: string;
}

interface OwnProps extends OnCompletedStepProps { }

interface Props extends OwnProps, PropsFromState, DispatchProp, RouteComponentProps<RouteParams> { }

class ComdataConfirmRegisterCheckToCard extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.submit = this.submit.bind(this);

    this.state = { selectedType: Types.COMCHEKV2.key, loading: true };
  }

  async componentDidMount(): Promise<void> {
    const { auth, companies, publicData } = this.props;
    const company = getCompanyFromState(companies, publicData, auth?.me);
    const attributes = getAttributes(auth?.me, company);
    const isComdataV2On = await isTreatmentOn(FeatureFlag.ComdataCheckApi, attributes);
    this.setState({ loading: false, isComdataV2On });
  }

  shouldComponentUpdate(nextProps, nextState): boolean {
    const { auth, companies, publicData, invoices } = this.props;
    const { selectedType, loading, error, isComdataV2On } = this.state;

    return auth !== nextProps?.auth
      || companies !== nextProps?.companies
      || publicData !== nextProps?.publicData
      || invoices !== nextProps?.invoices
      || selectedType !== nextState?.selectedType
      || loading !== nextState?.loading
      || error !== nextState?.pristine
      || isComdataV2On !== nextState?.isComdataV2On
  }

  getHeaderText(): string {
    return "Do you need to register this check to a card?";
  }

  onError(e?: { message?: string }): void {
    this.setState({ error: e?.message || 'Unable to process your request.' });
  }

  getNextStep(): PreparationSteps {
    return PreparationSteps.LINE_ITEMS;
  }

  getInvoiceId(): string {
    const { match: { params: { invoiceId } } } = this.props;
    return invoiceId;
  }

  getInvoice(): Invoice | undefined {
    const { invoices } = this.props;
    return invoices.data?.[this.getInvoiceId()];
  }

  async submit(yes?: boolean): Promise<void> {
    const { onCompletedStep, dispatch } = this.props;
    const { selectedType } = this.state;
    const invoice = this.getInvoice();
    this.setState({ loading: true });
    dispatch(unsavedProgress(selectedType));
    try {
      await dispatch<any>(depositCheckCreateOrReuse(this.getInvoiceId(), yes ? CheckTypes.COMCHEK.key : CheckTypes.COMCHEKV2.key, invoice?.payerState, invoice?.payerIdentifier));
      onCompletedStep(this.getNextStep());

    } catch (e) {
      this.onError(e as any);
      this.setState({ loading: false });
    }
  }

  render(): React.ReactElement {
    const { error, loading, isComdataV2On } = this.state;
    if (!isComdataV2On && !loading) { return <>You are in the wrong place</>; }
    return (
      <InvoicePreparationStepContainer
        primaryBtn={
          <LargePrimaryButton id="confirm--btn" onClick={() => this.submit(true)}>
            Yes
          </LargePrimaryButton>
        }
        secondaryBtn={
          <LargeButton variant="outlined" id="-back-btn" onClick={() => this.submit()}>No</LargeButton>
        }
        title={this.getHeaderText()}
      >
        <Grid container direction="column" spacing={2}>
          {error && <Grid item><Error error={error} /></Grid>}
          <FullScreenLoader show={loading} />
        </Grid>
      </InvoicePreparationStepContainer>
    );
  }
}

const mapStateToProps = (state: GlobalState): PropsFromState => mapState(state, "invoices", "companies", "auth", "publicData");
export default withRouter(connect(mapStateToProps)(ComdataConfirmRegisterCheckToCard));

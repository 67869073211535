//types

import { InvoicePreparationStep, InvoicePreparationAction } from "../types/Invoice";

enum InvoicePreparationActionType {
    NEXT_STEP = 'INVOICE/NEXT_STEP',
    PREVIOUS_STEP = 'INVOICE/PREVIOUS_STEP',
    SET_STEP = 'INVOICE/SET_STEP',
    INITIALIZE = 'INVOICE/INITIALIZE'
}

function invoiceSetStepAction(content: InvoicePreparationStep): InvoicePreparationAction {
    return {
        type: InvoicePreparationActionType.SET_STEP,
        content: content
    };
}

function invoiceInitializeAction(content: Array<InvoicePreparationStep>): InvoicePreparationAction {
    return {
        type: InvoicePreparationActionType.INITIALIZE,
        content: content
    };
}

export { InvoicePreparationActionType, invoiceSetStepAction, invoiceInitializeAction }

import React from 'react';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { email, required } from '../../../services/app/validators';
import Input from '../../controls/Input';
import { Error } from '../../ui/Error';
import { LargePrimaryButton } from '../../ui/Buttons';

export interface CreateCustomerFormData {
  firstName: string;
  lastName: string;
  email: string;
}

type Props = InjectedFormProps<CreateCustomerFormData>;

const CreateCustomerForm: React.FC<Props> = (props: Props) => {
  const { error, handleSubmit, submitting } = props;
  const buttonText = submitting ? 'Submitting...' : 'I Accept';
  return (
    <form onSubmit={handleSubmit}>
      <Error error={error} />
      <Field name="firstName" label="First Name" type="text" validate={required} component={Input} />
      <Field name="lastName" label="Last Name" type="text" validate={required} component={Input} />
      <Field name="email" label="Email" type="text" validate={[required, email]} component={Input} />
      <LargePrimaryButton className="btn-yellow" disabled={submitting} type="submit">
        {buttonText}
      </LargePrimaryButton>
    </form>
  );
};

export default reduxForm<CreateCustomerFormData>({ form: 'createCustomerForm' })(CreateCustomerForm);

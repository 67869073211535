import React, { ReactNode } from 'react';
import { reduxForm, Field, InjectedFormProps, formValueSelector } from 'redux-form';
import { comdataExpressCodeValidator } from '../../services/app/validators';
import { InvoiceDeclineReasons } from '../../constants/invoiceDeclineReasons';
import { connect } from 'react-redux';
import { Error } from '../ui/Error';
import { Invoice, GlobalState } from '../../types';
import { Grid, Typography } from '@roadsync/roadsync-ui';
import { RenderTextField } from '../ui/Form';
import { isExpressCodeError } from "../../services/app/deposits";
import SignatureBox from '../ui/SignatureBox';
import { forceValueToString } from '../../services/app/formats';

export interface CheckDetailsFormComdataData {
    expressCode?: string;
    payerFirstName?: string;
    payerLastName?: string;
    driverNumber?: string;
    tripNumber?: string;
    unitNumber?: string;
    paymentError?: string;
}

type Props = InjectedFormProps<CheckDetailsFormComdataData, OwnProps> & OwnProps;

interface PropsFromState {
    expressCode: string;
}

interface OwnProps extends PropsFromState {
    invoice?: Invoice;
    initialValues?: Partial<CheckDetailsFormComdataData>;
    resetFieldInvalidState: (e: React.ChangeEvent<HTMLInputElement>, errorKey?: string) => void;
    comdataValidationError?: string;
    resetDisableChargeBtnComdataV2: () => void;
    signatureEnabled?: boolean;
    signatureUrl?: string;
    openAddSignatureModal: () => void;
    fieldRequiredValidationRules: {
        ComdataDriverNumberRequired: boolean;
        ComdataUnitNumberRequired: boolean;
        ComdataTripNumberRequired: boolean;
    };
    resetRequiredValidationRules: () => void;
    setPayerFirstName: (value?: string) => void;
    setPayerLastName: (value?: string) => void;
    setExpressCodeHasBeenChanged: (value: boolean) => void;
    expressCodeHasBeenChanged: boolean;
}

class CheckDetailsFormComdata extends React.Component<Props> {

    isFieldInvalid(errorKey: string): boolean | undefined {
        const { comdataValidationError, expressCodeHasBeenChanged } = this.props;
        return !expressCodeHasBeenChanged && !!comdataValidationError && comdataValidationError === errorKey
            && (InvoiceDeclineReasons.getByKey(comdataValidationError)?.key) ? true : undefined;
    }

    helperText(errorKey: string): string | undefined {
        if (!this.isFieldInvalid(errorKey)) return undefined;
        return InvoiceDeclineReasons.getByKey(errorKey)?.helpText ?? InvoiceDeclineReasons.getByKey(errorKey)?.display;
    }

    prepareOptionallyRequiredLabel(text: string): ReactNode {
        return <>{text} <Typography component="span" color='error' style={{ lineHeight: '16px' }}>*</Typography></>
    }

    getDeclineReason(): string | undefined {
        const { initialValues } = this.props;
        return initialValues?.paymentError
            ? InvoiceDeclineReasons.getByKey(initialValues.paymentError)?.display
            || initialValues.paymentError
            : undefined;
    }

    getDriverNumberValidator(): ((value?: string | undefined) => string | undefined)[] {
        return this.props?.fieldRequiredValidationRules?.ComdataDriverNumberRequired
            ? [driverNumberRequiredValidator]
            : [driverNumberValidator];
    }

    getUnitNumberValidator(): ((value?: string | undefined) => string | undefined)[] {
        return this.props?.fieldRequiredValidationRules?.ComdataUnitNumberRequired
            ? [unitNumberRequiredValidator]
            : [unitNumberValidator];
    }

    getTripNumberValidator(): ((value?: string | undefined) => string | undefined)[] {
        return this.props?.fieldRequiredValidationRules?.ComdataTripNumberRequired
            ? [tripNumberRequiredValidator]
            : [tripNumberValidator];
    }

    render(): React.ReactElement {
        const {
            error, initialValues, handleSubmit, resetFieldInvalidState,
            resetDisableChargeBtnComdataV2, signatureEnabled, signatureUrl,
            openAddSignatureModal, resetRequiredValidationRules, setPayerFirstName,
            setPayerLastName, setExpressCodeHasBeenChanged
        } = this.props;
        const declineReason = this.getDeclineReason();
        const driverNumberValidator = this.getDriverNumberValidator();
        const unitNumberValidator = this.getUnitNumberValidator();
        const tripNumberValidator = this.getTripNumberValidator();

        return (
            <form onSubmit={handleSubmit}>
                <Grid container direction="column" wrap="nowrap" spacing={2}>
                    {error && <Grid item><Error error={error} /></Grid>}
                    {declineReason &&
                        <Grid item>
                            <Error error={`Decline reason: ${declineReason}`} />
                        </Grid>
                    }
                    <Grid item>
                        <Field
                            id="expresscode"
                            key="expresscode"
                            name="expressCode"
                            label="Comdata Express Code"
                            type="text"
                            component={RenderTextField}
                            validate={comdataExpressCodeValidator}
                            required
                            isComdataFlowInvalid={this.isFieldInvalid(InvoiceDeclineReasons.EXPRESS_CODE.key)}
                            helperText={this.helperText(InvoiceDeclineReasons.EXPRESS_CODE.key)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (isExpressCodeError(initialValues?.paymentError)) {
                                    resetDisableChargeBtnComdataV2()
                                }
                                resetFieldInvalidState(e, InvoiceDeclineReasons.EXPRESS_CODE.key);
                                resetRequiredValidationRules();
                                setExpressCodeHasBeenChanged(true);
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Field
                            id="drivernumber"
                            key="drivernumber"
                            name="driverNumber"
                            label="Driver Number"
                            type="text"
                            validate={driverNumberValidator}
                            component={RenderTextField}
                            isComdataFlowInvalid={this.isFieldInvalid(InvoiceDeclineReasons.COMDATA_DRIVER_NUMBER_REQUIRED.key)}
                            helperText={this.helperText(InvoiceDeclineReasons.COMDATA_DRIVER_NUMBER_REQUIRED.key)}
                            required={this.isFieldInvalid(InvoiceDeclineReasons.COMDATA_DRIVER_NUMBER_REQUIRED.key) || this.props?.fieldRequiredValidationRules?.ComdataDriverNumberRequired}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => resetFieldInvalidState(e, InvoiceDeclineReasons.COMDATA_DRIVER_NUMBER_REQUIRED.key)}
                        />
                    </Grid>
                    <Grid item>
                        <Field
                            id="unitnumber"
                            key="unitnumber"
                            name="unitNumber"
                            label="Unit Number"
                            type="text"
                            validate={unitNumberValidator}
                            component={RenderTextField}
                            isComdataFlowInvalid={this.isFieldInvalid(InvoiceDeclineReasons.COMDATA_UNIT_NUMBER_REQUIRED.key)}
                            helperText={this.helperText(InvoiceDeclineReasons.COMDATA_UNIT_NUMBER_REQUIRED.key)}
                            required={this.isFieldInvalid(InvoiceDeclineReasons.COMDATA_UNIT_NUMBER_REQUIRED.key) || this.props?.fieldRequiredValidationRules?.ComdataUnitNumberRequired}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => resetFieldInvalidState(e, InvoiceDeclineReasons.COMDATA_UNIT_NUMBER_REQUIRED.key)}
                        />
                    </Grid>
                    <Grid item>
                        <Field
                            id="tripnumber"
                            key="tripnumber"
                            name="tripNumber"
                            label="Trip Number"
                            type="text"
                            validate={tripNumberValidator}
                            component={RenderTextField}
                            isComdataFlowInvalid={this.isFieldInvalid(InvoiceDeclineReasons.COMDATA_TRIP_NUMBER_REQUIRED.key)}
                            helperText={this.helperText(InvoiceDeclineReasons.COMDATA_TRIP_NUMBER_REQUIRED.key)}
                            required={this.isFieldInvalid(InvoiceDeclineReasons.COMDATA_TRIP_NUMBER_REQUIRED.key) || this.props?.fieldRequiredValidationRules?.ComdataTripNumberRequired}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => resetFieldInvalidState(e, InvoiceDeclineReasons.COMDATA_TRIP_NUMBER_REQUIRED.key)}
                        />
                    </Grid>
                    <Grid item>
                        <Field
                            id="payer-first-name"
                            name="payerFirstName"
                            validate={payerFirstNameRequiredValidator}
                            label={this.prepareOptionallyRequiredLabel("Payer First Name")}
                            type="text"
                            component={RenderTextField}
                            isComdataFlowInvalid={
                                this.isFieldInvalid(InvoiceDeclineReasons.COMDATA_NAME_REQUIRED.key)
                                || this.isFieldInvalid(InvoiceDeclineReasons.COMDATA_INVALID_NAME.key)}
                            helperText={
                                this.helperText(InvoiceDeclineReasons.COMDATA_NAME_REQUIRED.key)
                                || this.helperText(InvoiceDeclineReasons.COMDATA_INVALID_NAME.key)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                resetFieldInvalidState(e, InvoiceDeclineReasons.COMDATA_NAME_REQUIRED.key);
                                resetFieldInvalidState(e, InvoiceDeclineReasons.COMDATA_INVALID_NAME.key);
                                setPayerFirstName(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Field
                            id="payer-last-name"
                            name="payerLastName"
                            validate={payerLastNameRequiredValidator}
                            label={this.prepareOptionallyRequiredLabel("Payer Last Name")}
                            type="text"
                            component={RenderTextField}
                            isComdataFlowInvalid={
                                this.isFieldInvalid(InvoiceDeclineReasons.COMDATA_NAME_REQUIRED.key)
                                || this.isFieldInvalid(InvoiceDeclineReasons.COMDATA_INVALID_NAME.key)}
                            helperText={
                                this.helperText(InvoiceDeclineReasons.COMDATA_NAME_REQUIRED.key)
                                || this.helperText(InvoiceDeclineReasons.COMDATA_INVALID_NAME.key)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                resetFieldInvalidState(e, InvoiceDeclineReasons.COMDATA_NAME_REQUIRED.key);
                                resetFieldInvalidState(e, InvoiceDeclineReasons.COMDATA_INVALID_NAME.key);
                                setPayerLastName(e.target.value);
                            }}
                        />
                    </Grid>
                    {signatureEnabled &&
                        <Grid item>
                            <SignatureBox onClick={openAddSignatureModal} signatureUrl={signatureUrl} />
                        </Grid>
                    }
                </Grid>
            </form>
        );
    }
}

const form = 'checkDetailsComdata';
const selector = formValueSelector(form);
const mapStateToProps = (state: GlobalState): PropsFromState => ({
    expressCode: selector(state, 'expressCode'),
});

function driverNumberValidator(value?: string | null): string | undefined {
    return forceValueToString(value).length > 16
        ? InvoiceDeclineReasons.COMDATA_DRIVER_NUMBER_REQUIRED.helpText
        : undefined;
}

function driverNumberRequiredValidator(value?: string | null): string | undefined {
    return forceValueToString(value)
        ? undefined
        : 'Driver Number is required and should be up to 16 characters long.';
}

function unitNumberValidator(value?: string | null): string | undefined {
    return forceValueToString(value).length > 6
        ? InvoiceDeclineReasons.COMDATA_UNIT_NUMBER_REQUIRED.helpText
        : undefined;
}
function unitNumberRequiredValidator(value?: string | null): string | undefined {
    return forceValueToString(value)
        ? undefined
        : 'Unit Number is required and should be up to 6 characters long.';
}

function tripNumberValidator(value?: string | null): string | undefined {
    return forceValueToString(value).length > 10
        ? InvoiceDeclineReasons.COMDATA_TRIP_NUMBER_REQUIRED.helpText
        : undefined;
}

function tripNumberRequiredValidator(value?: string | null): string | undefined {
    return forceValueToString(value)
        ? undefined
        : 'Trip Number is required and should be up to 10 characters long.';
}

function payerFirstNameRequiredValidator(value?: string): string | undefined {
    return value ? undefined : 'Payer First Name is required';
}

function payerLastNameRequiredValidator(value?: string): string | undefined {
    return value ? undefined : 'Payer Last Name is required';
}

export default connect(mapStateToProps)(
    reduxForm<CheckDetailsFormComdataData, OwnProps>({ form, keepDirtyOnReinitialize: true })(CheckDetailsFormComdata)
);

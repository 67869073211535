import { BankEndpoints } from './endpoints';
import { BusinessAccount } from '../../types/BusinessAccount';
import * as http from './http';

export function getBankStatus(companyId: string): Promise<{}> {
  return http.get(BankEndpoints.getBankStatus(companyId));
}

// eslint-disable-next-line max-params
export function createPersonalCustomer(companyId: string, firstName: string, lastName: string, email: string): Promise<{}> {
  return http.postJSON(BankEndpoints.createPersonalCustomer(companyId), { firstName, lastName, email });
}

export function createBusinessCustomer(companyId: string, businessAccount: BusinessAccount): Promise<{}> {
  return http.postJSON(BankEndpoints.createBusinessCustomer(companyId), businessAccount);
}

// eslint-disable-next-line max-params
export function attachBankAccount(
  companyId: string,
  routingNumber: string,
  accountNumber: string,
  type: string,
  accountName: string
): Promise<{}> {
  return http.postJSON(BankEndpoints.attachBankAccount(companyId), {
    routingNumber,
    accountNumber,
    type,
    accountName,
  });
}

export function confirmMicroDeposits(companyId: string, amount1: number, amount2: number): Promise<{}> {
  return http.postJSON(BankEndpoints.confirmMicroDeposits(companyId), { amount1, amount2 });
}

export function bankingCollect(companyId: string, amount: number): Promise<{}> {
  return http.postJSON(BankEndpoints.bankingCollect(companyId), { amount });
}

export function getBankAccount(companyId: string): Promise<{}> {
  return http.get(BankEndpoints.getBankAccount(companyId));
}

export function getCompanyBeneficialOwners(companyId: string): Promise<{}> {
  return http.get(BankEndpoints.getCompanyBeneficialOwners(companyId));
}

export function updateBeneficialOwner(ownerId: string, ownerInput: any): Promise<{}> {
  return http.postJSON(BankEndpoints.updateBeneficialOwnerById(ownerId), ownerInput);
}

export function deleteBeneficialOwner(ownerId: string): Promise<{}> {
  return http.del(BankEndpoints.deleteBeneficialOwnerById(ownerId));
}

// eslint-disable-next-line max-params
export function attachBankAccountWithPlaid(
  companyId: string, token: string, accountId: string, accountName: string, type: string
): Promise<{}> {
  return http.postJSON(BankEndpoints.attachBankAccountWithPlaid(companyId), {
    token,
    accountId,
    accountName,
    type,
  });
}

export function resetBankAccount(companyId: string): Promise<{}> {
  return http.postJSON(BankEndpoints.resetBankAccount(companyId));
}

export function getIsTodayABankHoliday(): Promise<any> {
  return http.get(BankEndpoints.getIsTodayABankHoliday());
}

import { EmailsLogsActions } from '../constants/actions';
import { mergeStateAndEntityResult } from '../services/app/normalizr';
import { EmailsLogs } from '../types/EmailsLogs';

export interface EmailsLogsState {
  ui: { loading?: boolean };
  data: { [id: string]: EmailsLogs };
  order?: string[];
}

export function emailsLogs(state: EmailsLogsState = { ui: {}, data: {} }, action: Record<string, any> = {}): EmailsLogsState {
  const nextData = mergeStateAndEntityResult(state, action.entities, 'emailsLogs');
  switch (action.type) {
    case EmailsLogsActions.REQUEST_EMAILS_LOGS_LIST:
      return { ...state, ui: { loading: true } };
    case EmailsLogsActions.RECEIVE_EMAILS_LOGS_LIST:
      return { data: nextData, order: action.order, ui: { loading: false } };
    default:
      return { ...state, data: nextData };
  }
}

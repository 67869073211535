import React from 'react';
import { LargePrimaryButton, LargeSecondaryButton } from '../../../../../components/ui/Buttons';
import { DialogActions, DialogTitle } from "@material-ui/core";
import { P, Grid } from '@roadsync/roadsync-ui';
import { Dialog } from "../../../../../components/ui/Dialog";

interface Props {
  text: string;
  isOpen: boolean;
  handleClose: () => void;
  handlePayForInvoice: (deleteStoredPaymentDetails: boolean) => void;
}

const ConfirmCardOnFileModal: React.FC<Props> = (props: Props): JSX.Element => {
  const { text, isOpen, handleClose, handlePayForInvoice } = props;
  const handleYesNo = (deleteStoredPaymentDetails: boolean): void => {
    handleClose();
    handlePayForInvoice(deleteStoredPaymentDetails);
  }

  return (
    <Dialog maxWidth="xs" open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <P>{text}</P>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogActions>
        <LargePrimaryButton
          id="pay-for-card-on-file-invoice-yes"
          onClick={(): void => { handleYesNo(true) }}
        >
          Yes
        </LargePrimaryButton>
        <LargeSecondaryButton
          id="pay-for-card-on-file-invoice-no"
          variant="outlined"
          onClick={(): void => { handleYesNo(false) }}
        >
          No
        </LargeSecondaryButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmCardOnFileModal;
export enum stateAbbreviations {
  AL = 'Alabama',
  AK = 'Alaska',
  AZ = 'Arizona',
  AR = 'Arkansas',
  CA = 'California',
  CO = 'Colorado',
  CT = 'Connecticut',
  DE = 'Delaware',
  DC = 'District Of Columbia',
  FL = 'Florida',
  GA = 'Georgia',
  HI = 'Hawaii',
  ID = 'Idaho',
  IL = 'Illinois',
  IN = 'Indiana',
  IA = 'Iowa',
  KS = 'Kansas',
  KY = 'Kentucky',
  LA = 'Louisiana',
  ME = 'Maine',
  MD = 'Maryland',
  MA = 'Massachusetts',
  MI = 'Michigan',
  MN = 'Minnesota',
  MS = 'Mississippi',
  MO = 'Missouri',
  MT = 'Montana',
  NE = 'Nebraska',
  NV = 'Nevada',
  NH = 'New Hampshire',
  NJ = 'New Jersey',
  NM = 'New Mexico',
  NY = 'New York',
  NC = 'North Carolina',
  ND = 'North Dakota',
  OH = 'Ohio',
  OK = 'Oklahoma',
  OR = 'Oregon',
  PA = 'Pennsylvania',
  RI = 'Rhode Island',
  SC = 'South Carolina',
  SD = 'South Dakota',
  TN = 'Tennessee',
  TX = 'Texas',
  UT = 'Utah',
  VT = 'Vermont',
  VA = 'Virginia',
  WA = 'Washington',
  WV = 'West Virginia',
  WI = 'Wisconsin',
  WY = 'Wyoming',
  MEX = 'Mexico',
  CAN = 'Canada',
}

interface State{
  Code:string;
  StateName:string;
}

const StatesOnly: Array<State> = [
  { Code: 'AL', StateName: 'Alabama' },
  { Code: 'AK', StateName: 'Alaska' },
  { Code: 'AZ', StateName: 'Arizona' },
  { Code: 'AR', StateName: 'Arkansas' },
  { Code: 'CA', StateName: 'California' },
  { Code: 'CO', StateName: 'Colorado' },
  { Code: 'CT', StateName: 'Connecticut' },
  { Code: 'DE', StateName: 'Delaware' },
  { Code: 'DC', StateName: 'District Of Columbia' },
  { Code: 'FL', StateName: 'Florida' },
  { Code: 'GA', StateName: 'Georgia' },
  { Code: 'HI', StateName: 'Hawaii' },
  { Code: 'ID', StateName: 'Idaho' },
  { Code: 'IL', StateName: 'Illinois' },
  { Code: 'IN', StateName: 'Indiana' },
  { Code: 'IA', StateName: 'Iowa' },
  { Code: 'KS', StateName: 'Kansas' },
  { Code: 'KY', StateName: 'Kentucky' },
  { Code: 'LA', StateName: 'Louisiana' },
  { Code: 'ME', StateName: 'Maine' },
  { Code: 'MD', StateName: 'Maryland' },
  { Code: 'MA', StateName: 'Massachusetts' },
  { Code: 'MI', StateName: 'Michigan' },
  { Code: 'MN', StateName: 'Minnesota' },
  { Code: 'MS', StateName: 'Mississippi' },
  { Code: 'MO', StateName: 'Missouri' },
  { Code: 'MT', StateName: 'Montana' },
  { Code: 'NE', StateName: 'Nebraska' },
  { Code: 'NV', StateName: 'Nevada' },
  { Code: 'NH', StateName: 'New Hampshire' },
  { Code: 'NJ', StateName: 'New Jersey' },
  { Code: 'NM', StateName: 'New Mexico' },
  { Code: 'NY', StateName: 'New York' },
  { Code: 'NC', StateName: 'North Carolina' },
  { Code: 'ND', StateName: 'North Dakota' },
  { Code: 'OH', StateName: 'Ohio' },
  { Code: 'OK', StateName: 'Oklahoma' },
  { Code: 'OR', StateName: 'Oregon' },
  { Code: 'PA', StateName: 'Pennsylvania' },
  { Code: 'RI', StateName: 'Rhode Island' },
  { Code: 'SC', StateName: 'South Carolina' },
  { Code: 'SD', StateName: 'South Dakota' },
  { Code: 'TN', StateName: 'Tennessee' },
  { Code: 'TX', StateName: 'Texas' },
  { Code: 'UT', StateName: 'Utah' },
  { Code: 'VT', StateName: 'Vermont' },
  { Code: 'VA', StateName: 'Virginia' },
  { Code: 'WA', StateName: 'Washington' },
  { Code: 'WV', StateName: 'West Virginia' },
  { Code: 'WI', StateName: 'Wisconsin' },
  { Code: 'WY', StateName: 'Wyoming' },
];

const States: Array<State> = [
  ...StatesOnly,
  { Code: 'MEX', StateName: 'Mexico' },
  { Code: 'CAN', StateName: 'Canada' }
];

function isState(stateLetters: string): boolean {
  if (States.find((x => x.Code === stateLetters)))
    return true;
  else
    return false;
}

export { StatesOnly, States, isState };
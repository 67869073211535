import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { ModalsConstants } from '../../constants/modals';
import ModalContent from '../../components/modals/ModalContent';
import { closeModal } from '../../actions/modals';
import { RouteComponentProps, withRouter } from 'react-router';
import { formatDollar } from '../../services/app/formats';

interface Props extends DispatchProp, RouteComponentProps, OwnProps { }

interface OwnProps {
  handleInvoiceSubmit: () => Promise<void>;
  checkNumber: string;
  price: string;
  carrier: string;
  invoiceType?: { display: string };
  type?: { display: string };
}

class ConfirmInvoiceTotal extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleInvoiceSubmit = this.handleInvoiceSubmit.bind(this);
  }

  handleInvoiceSubmit(): Promise<void> {
    const { dispatch, handleInvoiceSubmit } = this.props;
    return handleInvoiceSubmit().then(() => {
      dispatch(closeModal(ModalsConstants.CONFIRM_INVOICE_TOTAL));
    });
  }

  handleCancel(): void {
    const { dispatch } = this.props;
    return dispatch<any>(closeModal(ModalsConstants.CONFIRM_INVOICE_TOTAL));
  }

  render(): React.ReactElement {
    const { carrier, price, type, invoiceType, checkNumber } = this.props;
    return (
      <ModalContent
        className="line-item-custom-service-modal"
        primaryActionText={'Yes'}
        primaryActionClick={this.handleInvoiceSubmit}
        secondaryActionText="No"
        secondaryActionClick={this.handleCancel}
      >
        <button
          onClick={this.handleCancel}
          className="link-close icon-close cursor-pointer"
        />
        <span key="displayName" className="newpos-inline-text">
          {invoiceType && invoiceType.display} {type && type.display} {checkNumber ? `(#${checkNumber})` : ''}
        </span>
        <p className="modal-message">Please confirm you are charging {formatDollar(price)} to {carrier}?</p>
      </ModalContent>
    );
  }
}

export default withRouter(connect()(ConfirmInvoiceTotal));

import { mergeStateAndEntityResult } from '../services/app/normalizr';
import { ReportRecipientActions } from '../constants/actions';
import _ from 'lodash';
import { ReportRecipient } from '../types/ReportRecipient';

export interface ReportRecipientsState {
  data: { [id: string]: ReportRecipient };
  order: string[];
  ui: { loading?: boolean };
}

// eslint-disable-next-line max-lines-per-function
export function reportRecipients(
  state: ReportRecipientsState = { data: {}, ui: {}, order: [] },
  action: Record<string, any> = {}
): ReportRecipientsState {
  const nextData = mergeStateAndEntityResult(state, action.entities, 'reportRecipients');

  switch (action.type) {
    case ReportRecipientActions.REQUEST_REPORT_RECIPIENT:
      return _.set({ ...state }, `data.${action.result}`, { loading: true });
    case ReportRecipientActions.REQUEST_REPORT_RECIPIENT_LIST:
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: true,
        },
      };
    case ReportRecipientActions.RECEIVE_REPORT_RECIPIENT_LIST:
      return {
        ...state,
        data: nextData,
        order: action.order,
        ui: {
          ...state.ui,
          loading: false,
        },
      };
    case ReportRecipientActions.DELETED_REPORT_RECIPIENT:
      return {
        ...state,
        data: _.omit({ ...state.data }, action.reportRecipientId),
        order: _.pull(state.order, action.reportRecipientId),
      };
    default:
      return {
        ...state,
        data: nextData,
      };
  }
}

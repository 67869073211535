import { WorkOrderActions } from '../constants/actions';
import * as WorkOrderService from '../services/api/workOrder';
import * as ImageService from '../services/api/image';
import { normalizeResult } from '../services/app/normalizr';
import { WorkOrder } from '../types/WorkOrder';
import { workOrdersSchema, invoiceSchema } from '../types/schemas';
import { App } from '../constants/app';
import { showSuccessAlert } from './alerts';
import { WorkOrderStatus } from '../constants/workOrder';
import { dataURLtoFile, openFile } from '../services/api/utils';
import { InvoiceInput, Invoice } from '../types/Invoice';
import { isCompanyAdmin, isEmployee, isRSEmployee, isSuperAdmin } from '../services/app/auth';
import _ from 'lodash';
import { createdInvoice } from './invoices';
import { Dispatch } from 'react';

const normalizeInvoiceResult = normalizeResult(invoiceSchema);
const CreatedWorkOrderCustomFields = (companyId: string) => ({ type: WorkOrderActions.CREATED_WORK_ORDER_CUSTOM_FIELDS, companyId });
const requestWorkOrderList = (showLoader) => ({ type: WorkOrderActions.REQUEST_WORK_ORDER_LIST, showLoader });
const receiveWorkOrderList = (entities, order) => ({ type: WorkOrderActions.RECEIVE_WORK_ORDER_LIST, entities, order, });
const creatingWorkOrder = () => ({ type: WorkOrderActions.CREATING_WORK_ORDER });
const createdWorkOrder = (entities, workOrderId: string) => ({ type: WorkOrderActions.CREATED_WORK_ORDER, entities, workOrderId });
const requestWorkOrder = (workOrderId: string) => ({ type: WorkOrderActions.REQUEST_WORK_ORDER, result: workOrderId });
const receiveWorkOrder = (entities) => ({ type: WorkOrderActions.RECEIVE_WORK_ORDER, entities });
const creatingWorkOrderInvoice = () => ({ type: WorkOrderActions.CREATING_WORK_ORDER_INVOICE });
const updatingWorkOrder = () => ({ type: WorkOrderActions.UPDATING_WORK_ORDER });
const updatedWorkOrder = (entities) => ({ type: WorkOrderActions.UPDATED_WORK_ORDER, entities });
const sendingWorkOrder = () => ({ type: WorkOrderActions.SENDING_WORK_ORDER_RECEIPT });
const sentWorkOrder = (workOrderId, workOrderStatus) => ({ type: WorkOrderActions.SENT_WORK_ORDER_RECEIPT, workOrderId, workOrderStatus });
const SendingWorkOrderApprove = (workorderId: string) => ({ type: WorkOrderActions.SENDING_WORK_ORDER_APPROVE, workorderId });
const SentWorkOrderApprove = (workorderId: string) => ({ type: WorkOrderActions.SENT_WORK_ORDER_APPROVE, workorderId });
const normalizeWorkOrderResult = normalizeResult(workOrdersSchema);
const PublicSentWorkOrderApprove = (token: string) => ({ type: WorkOrderActions.PUBLIC_SENT_WORK_ORDER_APPROVE, token });

export const invoicePreparationStarted = () => ({ type: WorkOrderActions.INVOICE_PREPARATION_STARTED });
export const deletingWorkOrder = (workOrderId: string) => ({ type: WorkOrderActions.DELETING_WORK_ORDER, workOrderId });
export const deletedWorkOrder = (workOrderId) => ({ type: WorkOrderActions.DELETED_WORK_ORDER, workOrderId });
export const PublicSendingWorkOrderApprove = (token: string) => ({ type: WorkOrderActions.PUBLIC_SENDING_WORK_ORDER_APPROVE, token });
export const CreatingWorkOrderCustomFields = (companyId: string) => ({ type: WorkOrderActions.CREATING_WORK_ORDER_CUSTOM_FIELDS, companyId });

// eslint-disable-next-line max-params
export function getWorkOrders(offset = 0, limit = App.PAGE_SIZE, filter?: string | null, showLoader = true) {
  return (dispatch: Function) => {
    dispatch(requestWorkOrderList(showLoader));
    return WorkOrderService.getWorkOrders(offset, limit, filter)
      .then(normalizeWorkOrderResult)
      .then((data: any) => {
        dispatch(receiveWorkOrderList(data.entities, data.result));
      });
  };
}

// eslint-disable-next-line max-params
export function getWorkOrderListByCompany(
  companyId: string, offset = 0, limit = App.PAGE_SIZE, filter?: string | null, showLoader = true, locationId?: string | null) {
  return (dispatch: Function) => {
    dispatch(requestWorkOrderList(showLoader));
    return WorkOrderService.getWorkOrderListByCompany(companyId, offset, limit, filter, locationId)
      .then(normalizeWorkOrderResult)
      .then((data: any) => {
        dispatch(receiveWorkOrderList(data.entities, data.result));
      });
  };
}

export function createWorkOrder(workOrder: WorkOrder, companyId: string) {
  return (dispatch: Function) => {
    dispatch(creatingWorkOrder());
    return WorkOrderService.createWorkOrder(workOrder, companyId)
      .then(normalizeWorkOrderResult)
      .then((data: any) => {
        dispatch(createdWorkOrder(data.entities, data.result));
        return data.result;
      });
  };
}

export function getWorkOrder(workOrderId: string) {
  return (dispatch: Function) => {
    dispatch(requestWorkOrder(workOrderId));
    return WorkOrderService.getWorkOrderById(workOrderId)
      .then(normalizeWorkOrderResult)
      .then((data: any) => dispatch(receiveWorkOrder(data.entities)));
  };
}

export function updateWorkOrder(workOrder: Partial<WorkOrder>) {
  return (dispatch: Function) => {
    dispatch(updatingWorkOrder());
    return WorkOrderService.updateWorkOrder(workOrder)
      .then(normalizeWorkOrderResult)
      .then((data: any) => {
        dispatch(updatedWorkOrder(data.entities));
        return data.result;
      });
  };
}



export function deleteWorkOrder(workOrderId: string) {
  return (dispatch: Function) => {
    dispatch(deletingWorkOrder(workOrderId));
    return WorkOrderService.deleteWorkOrder(workOrderId)
      .then((data: any) => dispatch(deletedWorkOrder(workOrderId)));
  };
}

export function sendWorkOrder(workOrderId: string, email?: string, phoneNumber?: string) {
  return (dispatch: Function) => {
    dispatch(sendingWorkOrder());
    return WorkOrderService.sendWorkOrder(workOrderId, email, phoneNumber)
      .then(() => dispatch(sentWorkOrder(workOrderId, WorkOrderStatus.SENT.key)))
      .then(() => dispatch(showSuccessAlert(`Work Order Authorization Sent to ${email || phoneNumber}`)));
  };
}

// eslint-disable-next-line max-lines-per-function
export function getWorkOrderPdf(workOrderId: string): (d: Dispatch<any>) => void {
  return openFile(WorkOrderService.getWorkOrderPdf(workOrderId));
}

// eslint-disable-next-line max-lines-per-function
export function getWorkOrderPdfPublic(token: string): (d: Dispatch<any>) => void {
  return openFile(WorkOrderService.getWorkOrderPdfPublic(token));
}

export function approveWorkOrder(workorderId: string, workorderData: WorkOrderService.WorkOrderData) {
  return (dispatch: Function) => {
    dispatch(SendingWorkOrderApprove(workorderId));
    return WorkOrderService.approveWorkOrder(workorderId, workorderData)
      .then(() => dispatch(SentWorkOrderApprove(workorderId)));
  };
}

export function approveWorkOrderPublic(token: string, workorderData) {
  return (dispatch: Function) => {
    dispatch(PublicSendingWorkOrderApprove(token));
    return WorkOrderService.approveWorkOrderPublic(token, workorderData)
      .then(settings => dispatch(PublicSentWorkOrderApprove(token)));
  };
}

export function uploadWorkOrderSignature(workOrderId: string, image: any) {
  return (dispatch: Function) => {
    return ImageService.uploadFile(dataURLtoFile(image, `signature-${workOrderId}.png`))
      .then(response => ({ fileId: response.id }));
  };
}

export function uploadWorkOrderSignaturePublic(workOrderId: string, image: any, token: string) {
  return (dispatch: Function) => {
    return WorkOrderService.uploadFilePublic(dataURLtoFile(image, `signature-${workOrderId}.png`), token)
      .then(response => ({ fileId: response.id }));
  };
}

export function createWorkOrderInvoice(invoice: InvoiceInput | Invoice, workOrder: WorkOrder, cb?: Function) {
  return (dispatch: Function) => {
    dispatch(creatingWorkOrderInvoice());
    return WorkOrderService.createWorkOrderInvoice(workOrder.id, invoice)
      .then(result => {
        if (cb) {
          cb(result);
        }
        return normalizeInvoiceResult(result);
      })
      .then((data: any) => dispatch(createdInvoice(data.entities)));
  };
}

// eslint-disable-next-line max-lines-per-function
export function receiveWorkOrderUpdatePush(workOrder: WorkOrder) {
  // eslint-disable-next-line max-lines-per-function
  return (dispatch: Function, getState: Function) => {
    return normalizeWorkOrderResult(workOrder)
      // eslint-disable-next-line max-lines-per-function
      .then((data: any) => {
        const { auth: { me }, workOrders } = getState();

        const notifyWorkOrderStatusUpdate = () => {
          const oldWorkOrder = _.get(workOrders.data, workOrder.id) as WorkOrder;
          const workOrderInfoText = `Work Order # ${workOrder.publicId}`;
          if (!oldWorkOrder || oldWorkOrder.status !== workOrder.status) {
            if (workOrder.status === WorkOrderStatus.COMPLETED.key) {
              dispatch(showSuccessAlert(`${workOrderInfoText} Was Successfully Completed`));
            }
          }
        };

        const updateWorkOrderStatusInStore = () => {
          const oldWorkOrder = _.get(workOrders.data, workOrder.id) as WorkOrder;
          if (oldWorkOrder) {
            dispatch(receiveWorkOrder(data.entities));
          }
        };

        if (isSuperAdmin(me) || isRSEmployee(me)) {
          updateWorkOrderStatusInStore();
        } else if ((isCompanyAdmin(me) || isEmployee(me)) && workOrder.companyId === me.companyId) {
          notifyWorkOrderStatusUpdate();
          updateWorkOrderStatusInStore();
        }
      });
  };
}

export function setWorkorderCustomFields(companyId: string, customFieldsData) {
  return (dispatch: Function) => {
    dispatch(CreatingWorkOrderCustomFields(companyId));
    return WorkOrderService.setWorkorderCustomFields(companyId, customFieldsData)
      .then(settings => dispatch(CreatedWorkOrderCustomFields(companyId)));
  };
}

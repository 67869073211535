import React from 'react';

const NoLocationsScreen: React.FC = () => (
  <div className="newpos-wrap-small">
    <div className="newpos-title error">
      There are no locations associated with your company. Please contact support.
    </div>
  </div>
);

export default NoLocationsScreen;

import React from 'react';
import { WrappedFieldProps } from 'redux-form';

interface Props extends WrappedFieldProps {
  options: Array<any>;
  fieldKey: any;
  disabled?: boolean;
  fieldDisplay: string;
  label?: string;
  required?: boolean;
}

class RadioButtonGroup extends React.Component<Props, {}> {

  constructor(props: Props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const { input } = this.props;
    if (!input) {
      return;
    }
    const value = isNaN(event.target.value) ? event.target.value : Number(event.target.value);
    input.onChange(value);
  }

  // eslint-disable-next-line
  render() {
    const { label, input, options, fieldKey, fieldDisplay, disabled, meta: { error }, required } = this.props;
    if (!input) {
      return null;
    }

    const content = options.map(item => {
      const key = `radio-${input.name}-${item[fieldKey]}`;

      return (
        <div className="nr-btn" key={key}>
          <input
            id={key}
            value={item[fieldKey]}
            disabled={disabled}
            type="radio"
            name={`radioGroup-${input.name}`}
            checked={String(input.value) === String(item[fieldKey])}
            onChange={this.handleChange}
          />
          <label htmlFor={key}>
            {item[fieldDisplay]}
          </label>
        </div>
      );
    });

    return (
      <React.Fragment>
        <div className={`notifications-row${required ? ' input-required' : ''}`}>
          {label && <span>{label}</span>}
          <div className="nr-btns">
            {content}
          </div>
          <div className="">{error}</div>
        </div>
      </React.Fragment>
    );
  }
}

export default RadioButtonGroup;

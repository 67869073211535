import { WorkOrdersEndpoints } from './endpoints';
import { WorkOrder } from '../../types/WorkOrder';
import { PublicWorkOrderResponse } from '../../types/WorkOrderPublic';
import { Invoice, InvoiceInput } from '../../types/Invoice';
import { CustomField } from '../../types/CustomField';
import { WorkOrderTemplate } from '../../types/WorkOrderTemplate';
import * as http from './http';
import _ from 'lodash';
import { FileUrl } from '../../types/FileUrl';

export function getWorkOrders(offset: number, limit: number, filter?: string | null): Promise<{}> {
  return http.get(WorkOrdersEndpoints.getWorkOrderList(offset, limit, filter));
}

// eslint-disable-next-line max-params
export function getWorkOrderListByCompany(
  companyId: string, offset: number, limit: number, filter?: string | null, locationId?: string | null
): Promise<{}> {
  return http.get(WorkOrdersEndpoints.getWorkOrderListByCompany(companyId, offset, limit, filter, locationId));
}

export function getWorkOrderById(workOrderId: string): Promise<WorkOrder> {
  return http.get(WorkOrdersEndpoints.getWorkOrderById(workOrderId));
}

export function createWorkOrderInvoice(workOrderId: string, invoice: InvoiceInput | Invoice): Promise<Invoice> {
  return http.postJSON(
    WorkOrdersEndpoints.createWorkOrderInvoice(workOrderId),
    _.pick(invoice, [
      'id', 'description', 'payerName', 'payerPhone', 'payerEmail', 'locationId', 'customFields',
      'departmentId', 'shiftId', 'lineItems', 'workflowStatus', 'comments', 'convFeeDisable'
    ])
  );
}

export function getWorkOrderByToken(workOrderToken: string): Promise<PublicWorkOrderResponse> {
  return http.get(WorkOrdersEndpoints.getWorkOrderByToken(workOrderToken));
}

export function createWorkOrder(workOrder: WorkOrder, companyId: string): Promise<{}> {
  return http.postJSON(WorkOrdersEndpoints.createWorkOrder(companyId), prepareWorkOrderInput(workOrder));
}

export function updateWorkOrder(workOrder: Partial<WorkOrder>): Promise<{}> {
  return http.putJSON(
    WorkOrdersEndpoints.updateWorkOrder(workOrder.id as string),
    prepareWorkOrderInput(workOrder as WorkOrder)
  );
}

export function deleteWorkOrder(workOrderId: string): Promise<any> {
  return http.del(WorkOrdersEndpoints.deleteWorkOrder(workOrderId));
}

export function sendWorkOrder(workOrderId: string, email?: string, phone?: string): Promise<any> {
  return http.postJSON(WorkOrdersEndpoints.sendWorkOrder(workOrderId), { email, phone });
}

export function getWorkOrderPdf(workOrderId: string): Promise<any> {
  return http.get(WorkOrdersEndpoints.getWorkOrderPdf(workOrderId));
}

export function getWorkOrderPdfPublic(token: string): Promise<FileUrl> {
  return http.get(WorkOrdersEndpoints.getWorkOrderPdfPublic(token));
}

export async function getWorkOrderTemplates(locationId: string): Promise<WorkOrderTemplate[]> {
  try {
    return await http.get(WorkOrdersEndpoints.templates(locationId));
  } catch (e) {
    return [];
  }
}

export async function createWorkOrderTemplate(template: WorkOrderTemplate): Promise<WorkOrderTemplate> {
  return http.postJSON(WorkOrdersEndpoints.createTemplate(template.locationId), template);
}

export async function getWorkOrderTemplate(templateId: string): Promise<WorkOrderTemplate> {
  return http.get(WorkOrdersEndpoints.getTemplate(templateId));
}

export async function getWorkOrderTemplateByLocationId(locationId: string): Promise<WorkOrderTemplate | undefined> {
  const templates = await getWorkOrderTemplates(locationId);
  return templates[0];
}

export async function updateWorkOrderTemplate(template: WorkOrderTemplate): Promise<WorkOrderTemplate> {
  return http.putJSON(WorkOrdersEndpoints.updateTemplate(template.id), template);
}

export async function deleteWorkOrderTemplate(template: WorkOrderTemplate): Promise<void> {
  return http.del(WorkOrdersEndpoints.deleteTemplate(template.id));
}

export function approveWorkOrder(workOrderId: string, workOrderData): Promise<any> {
  return http.postJSON(WorkOrdersEndpoints.approve(workOrderId), workOrderData);
}

export type WorkOrderData = Pick<WorkOrder, "signedCarrierName" | "signedContactName" | "signedContactEmail" |
  "signedDriverName" | "signedDriverEmail" | "signedDriverPhone" | "signedCustomFields"> & {
    signedContactPhoneNumber?: string | null;
    signedFileId?: string | null;
  }

export function approveWorkOrderPublic(token: string, workorderData: WorkOrderData): Promise<any> {
  return http.postJSON(WorkOrdersEndpoints.publicApprove(token), workorderData);
}

export function uploadFilePublic(file, token): Promise<any> {
  return http.post(WorkOrdersEndpoints.uploadFilePublic(token), file);
}

export function getWorkOrderLocationPdfPreview(locationId: string, description: string): Promise<any> {
  return http.postJSON(WorkOrdersEndpoints.getWorkOrderLocationPdfPreview(locationId), { description });
}

function prepareWorkOrderInput(workOrder: WorkOrder): Partial<WorkOrder> {
  return _.pick(workOrder, [
    'companyId', 'userId', 'reference', 'signedCarrierName', 'signedDriverName', 'signedDriverEmail',
    'signedDriverPhone', 'locationId', 'signedCustomFields', 'workOrderSettingsId',
  ]);
}

export function setWorkorderCustomFields(locationId: string, workOrderCustomFields: CustomField[]): Promise<{}> {
  return http.postJSON(
    WorkOrdersEndpoints.createWorkOrderLocationCustomFields(locationId),
    workOrderCustomFields
  );
}

export async function getWorkOrderCustomFields(locationId: string): Promise<CustomField[]> {
  const template = await getWorkOrderTemplateByLocationId(locationId);
  return template?.customFields || [];
}

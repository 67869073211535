import { fetchWithHeaders, parseResponse, normalFetch, createURL } from "./utils";

export enum Method {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

// eslint-disable-next-line
export async function request<T>(
    method: Method,
    url: string,
    body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null,
    withAuthToken = true,
    headers?: Headers,
    credentials?: RequestCredentials,
): Promise<T> {
    const _credentials = credentials ?? 'include';
    return parseResponse(await (withAuthToken ? fetchWithHeaders : normalFetch)(
        createURL(url),
        { method, credentials: _credentials, body },
        headers,
        _credentials,
    ));
}

export async function get<T>(url: string, withAuthToken = true): Promise<T> {
    return request(Method.GET, url, undefined, withAuthToken);
}

// eslint-disable-next-line max-params
export async function post<T>(
    url: string,
    body?: string | Blob | ArrayBufferView | ArrayBuffer | FormData | URLSearchParams | ReadableStream<Uint8Array> | null,
    withAuthToken = true,
    headers?: Headers,
): Promise<T> {
    return request(Method.POST, url, body, withAuthToken, headers);
}

// eslint-disable-next-line max-params
export async function postJSON<T>(url: string, body?: {}, withAuthToken = true, headers?: Headers): Promise<T> {
    return post(url, body ? JSON.stringify(body) : undefined, withAuthToken, headers);
}

// eslint-disable-next-line max-params
export async function putJSON<T>(url: string, body?: {}, withAuthToken = true, headers?: Headers): Promise<T> {
    return request(Method.PUT, url, body ? JSON.stringify(body) : undefined, withAuthToken, headers);
}

// eslint-disable-next-line max-params
export async function del<T>(url: string, withAuthToken = true, headers?: Headers): Promise<T> {
    return request(Method.DELETE, url, undefined, withAuthToken, headers);
}

// eslint-disable-next-line max-params
export async function delJSON<T>(url: string, body: {}, withAuthToken = true, headers?: Headers): Promise<T> {
    return request(Method.DELETE, url, JSON.stringify(body), withAuthToken, headers);
}

import { AppSettingsActions, AuthActions } from '../constants/actions';
import { AppSettings } from '../types/AppSettings';
import { PreSelectedLocationValue } from '../services/app/location';

export interface AppSettingsState {
  settings?: AppSettings;
  loading?: boolean;
  defaults?: PreSelectedLocationValue;
}

interface AppSettingsAction {
  type: string;
  defaults?: PreSelectedLocationValue;
  result?: AppSettings;
}

export function appSettings(
  state: AppSettingsState = { loading: true, defaults: undefined },
  action: AppSettingsAction = { type: "uknown" }
): AppSettingsState {
  switch (action.type) {
    case AuthActions.REQUEST_LOGOUT:
      return { loading: false };
    case AppSettingsActions.REQUEST_APP_SETTINGS:
      return { ...state, loading: true };
    case AppSettingsActions.RECEIVE_APP_SETTINGS:
      return {
        ...state,
        settings: action.result,
        loading: false,
        defaults: action.defaults,
      };
    case AppSettingsActions.SET_APP_DEFAULTS: {
      return { ...state, defaults: action.defaults };
    }
    default:
      return state;
  }
}

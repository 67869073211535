import { ProductActions } from '../constants/actions';
import * as ProductService from '../services/api/products';
import { normalizeResult } from '../services/app/normalizr';
import { Product } from '../types/Product';
import { productSchema } from '../types/schemas';
import { App } from '../constants/app';
import { showSuccessAlert, showErrorAlert } from './alerts';
import { ErrorCodes } from '../constants/errorCodes';

function requestProductList() { // simple action creator
  return {
    type: ProductActions.REQUEST_PRODUCT_LIST,
  };
}

function receiveProductList(entities, order) {
  return {
    type: ProductActions.RECEIVE_PRODUCT_LIST,
    entities,
    order,
  };
}

function creatingProduct() {
  return {
    type: ProductActions.CREATING_PRODUCT,
  };
}

function requestProduct(productId: string) {
  return {
    type: ProductActions.REQUEST_PRODUCT,
    result: productId,
  };
}

function receiveProduct(entities) {
  return {
    type: ProductActions.RECEIVE_PRODUCT,
    entities,
  };
}

function updatingProduct() {
  return {
    type: ProductActions.UPDATING_PRODUCT,
  };
}

function updatedProduct(entities) {
  return {
    type: ProductActions.UPDATED_PRODUCT,
    entities,
  };
}

function updateProductWeight(order) {
  return {
    type: ProductActions.UPDATE_PRODUCT_WEIGHT,
    order,
  };
}

export function destroyProductList() {
  return {
    type: ProductActions.DESTROY_LIST,
  };
}

const normalizeProductResult = normalizeResult(productSchema);

// eslint-disable-next-line max-params
export function getProducts(locationId: string, offset = 0, limit = App.PAGE_SIZE, type?: string) {
  return (dispatch: Function) => {
    dispatch(requestProductList());
    return ProductService.getProducts(locationId, offset, limit, type)
      .then(normalizeProductResult)
      .then((data: any) => {
        dispatch(receiveProductList(data.entities, data.result));
      });
  };
}

export function getProductWithoutTaxes(locationId: string, offset = 0, limit = App.PAGE_SIZE) {
  return (dispatch: Function) => {
    dispatch(requestProductList());
    return ProductService.getProductWithoutTaxes(locationId, offset, limit)
      .then(normalizeProductResult)
      .then((data: any) => {
        dispatch(receiveProductList(data.entities, data.result));
      });
  };
}

export function createProduct(locationId: string, product: Partial<Product>) {
  return (dispatch: Function) => {
    dispatch(creatingProduct());
    return ProductService.createProduct(locationId, product)
      .then(normalizeProductResult)
      .then((data: any) => {
        dispatch(receiveProduct(data.entities));
      });
  };
}

export function getProduct(productId: string) {
  return (dispatch: Function) => {
    dispatch(requestProduct(productId));
    return ProductService.getProductById(productId)
      .then(normalizeProductResult)
      .then((data: any) => dispatch(receiveProduct(data.entities)));
  };
}

export function updateProduct(product: Product) {
  return (dispatch: Function) => {
    dispatch(updatingProduct());
    return ProductService.updateProduct(product)
      .then(normalizeProductResult)
      .then((data: any) => {
        dispatch(updatedProduct(data.entities));
        dispatch(showSuccessAlert('Product information updated successfully!'));
      });
  };
}

export function deletingProduct(productId: string) {
  return {
    type: ProductActions.DELETING_PRODUCT,
    productId,
  };
}

export function deletedProduct(productId) {
  return {
    type: ProductActions.DELETED_PRODUCT,
    productId,
  };
}

export function deleteProduct(productId: string) {
  return (dispatch: Function) => {
    dispatch(deletingProduct(productId));
    return ProductService.deleteProduct(productId)
      .then((data: any) => dispatch(deletedProduct(productId)))
      .catch((error: any) => {
        if (String(error.status) === ErrorCodes.UNAUTHORIZED.key) {
          dispatch(showErrorAlert('You are not authorized to delete products'));
        }
      });
  };
}

export function deleteTax(productId: string) {
  return (dispatch: Function) => {
    dispatch(deletingProduct(productId));
    return ProductService.deleteProduct(productId)
      .then((data: any) => dispatch(deletedProduct(productId)))
      .catch((error: any) => {
        if (String(error.status) === ErrorCodes.UNAUTHORIZED.key) {
          dispatch(showErrorAlert('You are not authorized to delete taxes'));
        }
      });
  };
}

export function reorderProducts(locationId: string, fromIndex: number, toIndex: number) {
  return (dispatch: Function, getState: Function) => {
    const order = [...getState().products.order];

    const newOrder = [...order];
    newOrder.splice(toIndex, 0, newOrder.splice(fromIndex, 1)[0]);

    dispatch(updateProductWeight(newOrder));

    return ProductService.updateProductWeight(locationId, newOrder)
      .catch((e) => dispatch(updateProductWeight(order)));
  };
}


import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { submit } from 'redux-form';
import { ModalsConstants } from '../../constants/modals';
import ModalContent from '../../components/modals/ModalContent';
import SendReceiptForm, { SendReceiptFormData } from '../../components/invoice/SendReceiptForm';
import { sendWorkOrder } from '../../actions/workOrders';
import { closeModal } from '../../actions/modals';
import { handleReduxFormError } from '../../services/app/forms';
import { WorkOrderPaths } from '../../services/app/paths';
import { normalizePhoneOrEmail } from "../../services/app/normalizers";
import { GlobalState } from '../../types/GlobalState';
import { RouteComponentProps } from 'react-router-dom';

interface PropsFromState {
  submitting?: boolean;
}

interface OwnProps {
  workOrderId: string;
  initialValues?: Partial<SendReceiptFormData>;
}

interface Props extends OwnProps, PropsFromState, DispatchProp, RouteComponentProps { }

class WorkOrderSendModal extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.handleModalSubmit = this.handleModalSubmit.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleModalSubmit(): void {
    const { dispatch } = this.props;
    dispatch(submit('sendReceipt'));
  }

  handleModalClose(): void {
    const { dispatch } = this.props;
    dispatch(closeModal(ModalsConstants.SEND_WORK_ORDER));
  }

  handleFormSubmit(values) {
    const { dispatch, history, workOrderId } = this.props;
    values.phoneOrEmail = normalizePhoneOrEmail(values.phoneOrEmail);
    const action = values.phoneOrEmail && String(values.phoneOrEmail).indexOf('@') >= 0
      ? sendWorkOrder(workOrderId, values.phoneOrEmail)
      : sendWorkOrder(workOrderId, undefined, values.phoneOrEmail);
    return dispatch<any>(action)
      .then(() => {
        if (history) {
          history.push(WorkOrderPaths.workOrdersUrl());
        }
        dispatch(closeModal(ModalsConstants.SEND_WORK_ORDER));
      })
      .catch(handleReduxFormError);
  }

  render(): React.ReactElement {
    const { submitting, initialValues } = this.props;
    return (
      <ModalContent
        className="invoice-decline-reason-modal"
        primaryActionText={submitting ? 'Sending...' : 'Send Authorization'}
        primaryActionClick={this.handleModalSubmit}
        primaryActionDisabled={submitting}
        secondaryActionText="Close"
        secondaryActionClick={this.handleModalClose}
      >
        <span>Send Work Order Authorization</span>
        <SendReceiptForm
          onSubmit={this.handleFormSubmit}
          initialValues={initialValues}
        />
      </ModalContent>
    );
  }
}

const mapStateToProps = ({ form }: GlobalState): PropsFromState => ({ submitting: form?.sendReceipt?.submitting });
export default connect(mapStateToProps)(WorkOrderSendModal);

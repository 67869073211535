import { CompanySettingsActions } from '../constants/actions';
import { CompanySettings } from '../types/CompanySettings';

import _ from 'lodash';
import { mergeStateAndEntityResult } from '../services/app/normalizr';

export interface CompanySettingsState {
  data: {
    [id: string]: CompanySettings & { loading?: boolean };
  };
  ui: {
    loading?: boolean;
    error?: string;
  };
}

// eslint-disable-next-line max-lines-per-function
export function companySettings(state: CompanySettingsState = { data: {}, ui: {} }, action: Record<string, any> = {}): CompanySettingsState {
  const nextData = mergeStateAndEntityResult(state, action.entities, 'companySettings');
  switch (action.type) {
    case CompanySettingsActions.REQUEST_COMPANY_SETTINGS:
      return _.set({ ...state }, `data.${action.companyId}`, { loading: true });
    case CompanySettingsActions.RECEIVE_COMPANY_SETTINGS:
    case CompanySettingsActions.SAVED_COMPANY_SETTINGS:
      return {
        ...state,
        data: nextData,
        ui: {
          ...state.ui,
          loading: false,
          error: undefined,
        },
      };
    case CompanySettingsActions.SAVING_COMPANY_SETTINGS_ERROR:
      return {
        ...state,
        data: nextData,
        ui: {
          ...state.ui,
          loading: false,
          error: action.error.message,
        },
      };
    default:
      return {
        ...state,
        data: nextData,
      };
  }
}

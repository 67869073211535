import React from 'react';

const NotAllowedToEdit: React.FC = (): React.ReactElement => (
  <div className="newpos-wrap-small">
    <div className="newpos-title error">
      You can't Edit Invoice in this status.
    </div>
  </div>
);

export default NotAllowedToEdit;

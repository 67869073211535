export class UserEndpoints {
  static getUser = (userId: string): string => `/api/v1/users/${userId}`;
  static createUser = (companyId: string): string => `/api/v1/companies/${companyId}/users`;
  static updateUser = (userId: string): string => `/api/v1/users/${userId}`;
  static deleteUser = (userId: string): string => `/api/v1/users/${userId}`;
  // eslint-disable-next-line max-params
  static getUsers = (companyId: string, offset: number, limit: number, quickfilter = ''): string => (
    `/api/v1/companies/${companyId}/users?offset=${offset}&limit=${limit}&search=${quickfilter}`
  )
  // eslint-disable-next-line max-params
  static getAdminUsers = (offset: number, limit: number, quickfilter: string, companytype: string, role: string): string => (
    `/api/v1/users?offset=${offset}&limit=${limit}&q=${quickfilter}&companytype=${companytype}&role=${role}`
  )
  static generateTwoFactorToken = (userId: string): string => `/api/v1/users/${userId}/otp/new`;
  static getTwoFactorQRCode = (userId: string): string => `/api/v1/users/${userId}/otp/qr`;
  static confirmTwoFactorToken = (userId: string): string => `/api/v1/users/${userId}/otp/confirm`;
  static resetTwoFactorToken = (userId: string): string => `/api/v1/users/${userId}/otp/reset`;
  static verify = (userId: string): string => `/api/v1/users/${userId}/verify`;
  static regenerateVerificationCode = (userId: string): string =>
    `/api/v1/users/${userId}/regenerate-verification-code`;
  static lockOutLocationUsers = (locationId: string): string => `/api/v1/locations/${locationId}/users/lock`;
  static lockOutCompanyUsers = (companyId: string): string => `/api/v1/companies/${companyId}/users/lock`;
}

import React from 'react';
import _ from 'lodash';
import StaticDropDownList from './List/DropDownList';
import { WrappedFieldMetaProps } from 'redux-form';

interface Props {
  id?: string;
  label?: string;
  dataProvider: any[];
  itemRenderer: Function;
  disabled?: boolean;
  value: any;
  onSelection: Function;
  meta: Partial<WrappedFieldMetaProps>;
  required?: boolean;
  errorMessage?: string;
  input?: any;
}

interface State {
  displayList?: boolean;
  focusIndex: number;
  selectedIndex: number;
  filterString: string;
  timeout?: NodeJS.Timeout;
}

const keyMap = {
  9: 'handleTabKey',
  13: 'handleEnterKey',
  27: 'handleEscKey',
  32: 'handleSpaceKey',
  38: 'handleUpKey',
  40: 'handleDownKey',
};

export default class StaticDropDown extends React.Component<Props, State> {

  dropdown: StaticDropDownList | null = null;
  autocorrect: HTMLInputElement | null = null;
  state = {
    displayList: false,
    focusIndex: -1,
    selectedIndex: -1,
    filterString: '',
    timeout: undefined,
  };

  // eslint-disable-next-line max-statements
  constructor(props: Props) {
    super(props);

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.handleUpKey = this.handleUpKey.bind(this);
    this.handleDownKey = this.handleDownKey.bind(this);
    this.handleEnterKey = this.handleEnterKey.bind(this);
    this.handleTabKey = this.handleEnterKey.bind(this);
    this.handleEscKey = this.handleEscKey.bind(this);
    this.handleSpaceKey = this.handleSpaceKey.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  componentWillUnmount(): void {
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }
  }

  onFocus(): void {
    this.setState({
      displayList: true,
    });
  }

  onBlur(): void {
    this.setState({
      displayList: false,
      focusIndex: this.state.selectedIndex,
      filterString: '',
    });
  }

  onSelect(item): void {
    const index = this.props.dataProvider.indexOf(item);
    this.props.onSelection(item);
    this.setState({
      selectedIndex: index,
      focusIndex: index,
      filterString: '',
    });
  }

  // eslint-disable-next-line max-lines-per-function
  onKeyDown(event): void {
    if (!this.state.displayList) {
      this.moveTo(this.state.selectedIndex);
      this.onFocus();
      return;
    }

    if (keyMap[event.keyCode]) {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
      this[keyMap[event.keyCode]]();
    } else {
      this.handleCharKey(event);
    }
  }

  // eslint-disable-next-line max-lines-per-function,max-statements
  handleCharKey(event): void {
    const { timeout: currentTimeout, filterString } = this.state;
    if (currentTimeout) {
      clearTimeout(currentTimeout);
    }

    const timeout = setTimeout(() => { this.setState({ filterString: '' }); }, 3000);
    const needle = filterString + event.key;
    const found = _.find(this.props.dataProvider, (item) => {
      let rtn = _.startsWith(item.value, _.toUpper(needle));
      if (!rtn) {
        rtn = _.startsWith(item.name, _.startCase(needle));
      }
      return rtn;
    });
    if (found) {
      const index = this.props.dataProvider.indexOf(found);
      this.setState({
        filterString: needle,
        timeout,
      });
      this.moveTo(index);
    } else {
      this.setState({
        timeout,
      });
    }
  }

  handleUpKey(): void {
    this.move(-1);
  }

  handleDownKey(): void {
    this.move(1);
  }

  handleTabKey(): void {
    // treat as a select
  }

  handleEnterKey(): void {
    const item = this.props.dataProvider[this.state.focusIndex];
    this.props.onSelection(item);
    this.setState({
      selectedIndex: this.state.focusIndex,
      filterString: '',
    });
    this.onBlur();
  }

  handleEscKey(): void {
    // treat as a non-select
    this.onBlur();
  }

  handleSpaceKey(): void {
    this.handleEnterKey();
  }

  move(direction: number): void {
    let index = this.state.focusIndex;
    const len = this.props.dataProvider.length;

    index += direction;
    if (index >= len) {
      index = 0;
    } else if (index < 0) {
      index = len - 1;
    }
    this.setState({
      filterString: '',
    });
    this.moveTo(index);
  }

  moveTo(index: number): void {
    if (this.dropdown && this.dropdown.ul.childNodes[index]) {
      this.dropdown.ul.childNodes[index].scrollIntoView();
    }

    this.setState({
      focusIndex: index,
    });
  }

  // eslint-disable-next-line max-lines-per-function
  render(): React.ReactElement {
    const { id, meta, required, errorMessage, input } = this.props;
    const styles = {
      maxHeight: '300px',
      display: this.state.displayList ? 'block' : 'none',
      overflow: 'auto',
      width: '100%',
      top: '0px',
    };
    const isVisible = this.state.displayList ? 'visible' : '';
    const isOpened = this.state.displayList ? 'opened' : '';

    const className = (this.props.disabled && this.props.disabled === true) ? 'disabled' : '';
    const hasError = (meta.touched && meta.error) || (errorMessage && errorMessage.includes(input.name));
    return (
      <div className={`inp-row ${(hasError ? ' field-validation-error' : '')} ${required ? 'input-required' : ''}`}>
        {this.props.label && <label htmlFor={id} className="active">{this.props.label}</label>}
        <div className={`custom-select ${isOpened}`}>
          <span className="current-value">
            <input
              id={id}
              ref={(ref): void => { this.autocorrect = ref; }}
              type="text"
              className={`select-dropdown${className} ${(hasError) ? 'is-error' : ''}`}
              readOnly={true}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onKeyDown={this.onKeyDown}
              // eslint-disable-next-line no-mixed-operators
              value={this.props.value && this.props.value.name || 'Please Select...'}
              disabled={this.props.disabled}
            />
          </span>
          <StaticDropDownList
            ref={(list): void => { this.dropdown = list; }}
            styles={styles}
            isVisible={isVisible}
            className={`select-dropdown${className}`}
            dataProvider={this.props.dataProvider}
            itemRenderer={this.props.itemRenderer}
            focusIndex={this.state.focusIndex}
            selectedIndex={this.state.selectedIndex}
            selectHandler={this.onSelect}
          />
        </div>
        {hasError && <div className="field-error-message">{meta.error}</div>}
      </div>
    );
  }
}

export class InvoicePaths {
  static listUrl = (): string => '/invoices';
  static createUrl = (): string => '/invoices/new';
  static expressPaymentUrl = (): string => '/invoices/express-payment';
  static editUrl = (invoiceId?: string): string => invoiceId ? `/invoices/${invoiceId}/edit` : `/invoices/new`;
  static editTowbookUrl = (invoiceId?: string): string => invoiceId ? `/invoices/towbook/${invoiceId}/edit` : `/invoices/new`;
  static receiptUrl = (invoiceId: string): string => `/invoices/${invoiceId}/receipt`;
  static approveInvoiceUrl = (invoiceId: string): string => `/invoices/${invoiceId}/approve`;
  static declineInvoiceUrl = (invoiceId: string): string => `/invoices/${invoiceId}/decline`;
  static showInvoiceAttachments = (invoiceId: string): string => `/invoices/${invoiceId}/attachments`;
  static editApprovedInvoice = (invoiceId: string): string => `/invoices/${invoiceId}/approved/edit`;
  static generateV1InvoiceDetailsUrl = (): string => `/invoices/v1`;
  static publicInvoiceUrl = (invoiceId: string): string => `/p/i/${invoiceId}`;
  static billingInvoiceDetailsUrl = (invoiceId: string): string => `/invoices/${invoiceId}/invoice-details`;
  static importFromTowbookUrl = (): string => `/invoices/import-from-towbook`;
}

import * as InvoiceService from '../services/api/invoices';
import { AttachedFileActions } from '../constants/actions';
import { normalizeResult } from '../services/app/normalizr';
import { fileSchema } from '../types/schemas';
import { showErrorAlert } from './alerts';
import * as FileService from '../services/api/image';
import { openFile } from '../services/api/utils';
import { Dispatch } from 'react';
import { File } from '../types/File';

const normalizeFileResult = normalizeResult(fileSchema);

export function requestFilesForInvoice(invoiceId: string): { type: string; invoiceId: string } {
  return {
    type: AttachedFileActions.REQUEST_PDF_INVOICE,
    invoiceId,
  };
}

export function receiveFilesForInvoice(invoiceId: string, entities, order): { type: string; invoiceId: string; entities: any; order: [] } {
  return {
    type: AttachedFileActions.RECEIVE_PDF_INVOICE,
    invoiceId,
    entities,
    order,
  };
}

export function getInvoiceFiles(invoiceId: string): Function {
  return (dispatch: Function) => {
    dispatch(requestFilesForInvoice(invoiceId));
    return InvoiceService.getFilesForInvoice(invoiceId)
      .then(normalizeFileResult)
      .then((data: any) => dispatch(receiveFilesForInvoice(invoiceId, data.entities, data.result)));
  };
}

export function attachingPdfToInvoice(): { type: string } {
  return {
    type: AttachedFileActions.ATTACHING_PDF_INVOICE,
  };
}

export function attachedPdfToInvoice(invoiceId: string, entities, order): { type: string; invoiceId: string; entities: any; order: [] } {
  return {
    type: AttachedFileActions.ATTACHED_PDF_INVOICE,
    invoiceId,
    entities,
    order,
  };
}

export function attachPdfToInvoice(invoiceId: string, file: any, fileName = ''): Function {
  return (dispatch: Function) => {
    dispatch(attachingPdfToInvoice());
    return InvoiceService.attachPdfToInvoice(invoiceId, file, fileName)
      .then(normalizeFileResult)
      .then((data: any) => dispatch(attachedPdfToInvoice(invoiceId, data.entities, data.result)))
      .catch((e) => {
        dispatch(showErrorAlert(e.message));
      });
  };
}

export function removingPdfFromInvoice(invoiceId: string): { type: string; invoiceId: string } {
  return {
    type: AttachedFileActions.REMOVING_PDF_INVOICE,
    invoiceId,
  };
}

export function removedPdfFromInvoice(invoiceId: string, invoiceFileId: string): { type: string; invoiceId: string; invoiceFileId: string } {
  return {
    type: AttachedFileActions.REMOVED_PDF_INVOICE,
    invoiceId,
    invoiceFileId,
  };
}

export function removePdfFromInvoice(invoiceId: string, invoiceFileId: string): Function {
  return (dispatch: Function) => {
    dispatch(removingPdfFromInvoice(invoiceId));
    return InvoiceService.removePdfFromInvoice(invoiceId, invoiceFileId)
      .then(() => dispatch(removedPdfFromInvoice(invoiceId, invoiceFileId)));
  };
}

export const openFileUrl = (fileId: string): (d: Dispatch<any>) => void =>
  openFile(FileService.getFile(fileId));

export const openFileUrlByInvoiceToken = (token: string, fileId: string): (d: Dispatch<any>) => void =>
  openFile(InvoiceService.getInvoiceAttachmentByToken(token, fileId));

export const getFileUrl = (fileId: string): Promise<File> => {
  return FileService.getFile(fileId);
}

import React from 'react';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { usPhoneNumber, required } from '../../services/app/validators';
import { Error } from '../ui/Error';
import { RenderTextField } from '../ui/Form';
import { Grid } from '@roadsync/roadsync-ui';
import { InputAdornment, StyledComponentProps, withStyles } from '@material-ui/core';
import styles from './DirectPaymentCheckoutForm.css';
import PhoneIcon from '@material-ui/icons/Phone';
import { displayFormattedUsPhoneNumber } from '../../services/app/formats';

export interface DirectPaymentCheckoutFormData {
  payerPhone: string;
}

type Props = InjectedFormProps<DirectPaymentCheckoutFormData> & StyledComponentProps;

const DirectPaymentCheckoutForm: React.FC<Props> = (props: Props) => {
  const { error, handleSubmit, classes } = props;
  return (
    <form onSubmit={handleSubmit} className={classes?.directPaymentForm} >
      <Grid container direction='column' wrap='nowrap' spacing={2}>
        {error && <Error error={error} />}
        <Grid item>
          <Field
            id='payerPhone'
            className={classes?.directPaymentNonEditableField}
            disabled
            name='payerPhone'
            label="Driver's Phone Number"
            type='text'
            component={RenderTextField}
            validate={[required, usPhoneNumber]}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <PhoneIcon />
                </InputAdornment>
              ),
            }}
            format={displayFormattedUsPhoneNumber}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default reduxForm<DirectPaymentCheckoutFormData>({ form: 'directPaymentCheckout' })(withStyles(styles)(DirectPaymentCheckoutForm));

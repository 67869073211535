import React from 'react';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { emailOrPhoneNumber, required } from '../../services/app/validators';
import { Error } from '../ui/Error';
import { RenderTextField } from '../ui/Form';
import { Grid } from '@roadsync/roadsync-ui';
import { LargeSecondaryButton } from '../ui/Buttons';

export interface SendInvoiceDetailsToPayerFormData {
  phoneOrEmail: string;
}

type Props = InjectedFormProps<SendInvoiceDetailsToPayerFormData>;

const SendInvoiceDetailsToPayerForm: React.FC<Props> = (props: Props) => {
  const { error, handleSubmit, anyTouched, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      {anyTouched && error && <Error error={error} />}
      <Grid container direction="column" wrap="nowrap" spacing={2}>
        <Grid item>
          <Field
            name="phoneOrEmail"
            label="Payer Email or Phone Number"
            type="text"
            component={RenderTextField}
            validate={[required, emailOrPhoneNumber]}
          />
        </Grid>
        <Grid item>
          <LargeSecondaryButton disabled={submitting} type="submit">
            {submitting ? 'Sending...' : 'Send'}
          </LargeSecondaryButton>
        </Grid>
      </Grid>
    </form>
  );
};

export default reduxForm<SendInvoiceDetailsToPayerFormData>({
  form: 'sendInvoiceDetailsToPayer'
})(SendInvoiceDetailsToPayerForm);

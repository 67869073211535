import React from 'react';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import useStyles from './SuccessOutlined.css';

interface Props {
  id?: string;
}

const SuccessOutlined: React.FC<Props> = React.memo((props: Props) => {
  const { id } = props;
  const classes = useStyles();

  return <CheckCircleOutlineRoundedIcon
    id={id ?? 'success-icon'}
    data-testid={id ?? 'success-icon'}
    className={classes.successIcon}
  />;
});

export default SuccessOutlined;
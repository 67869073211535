import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  errorIcon: {
    fontSize: 180,
    color: theme.palette.error.main
  },
}));

export default useStyles;

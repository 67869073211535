import React from 'react';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { required } from '../../services/app/validators';
import SelectStyled from '../controls/SelectStyled';
import FileUploader from '../controls/FileUpload';
import { Error } from '../ui/Error';
import { Location } from '../../types/Location';

export interface CopyLocationOrCreateFormData {
  locationId?: string;
  file?: any;
}

interface OwnProps {
  locations: Location[];
}

type Props = InjectedFormProps<CopyLocationOrCreateFormData, OwnProps> & OwnProps;

const LocationsSelect = (locations: Location[], props: any): React.ReactElement => {
  return (
    <SelectStyled allowNullValue={true} {...props}>
      {[<option key="all" value="">New location</option>,
      ...locations.map(item => <option key={item.id} value={item.id}>{item.name}</option>)]}
    </SelectStyled>
  );
};

const CopyLocationOrCreateForm: React.FC<Props> = (props: Props) => {
  const { error, handleSubmit, locations } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Error error={error} />
      <Field name="locationId" label="Existing Location" component={LocationsSelect.bind(null, locations)} />
      <Field
        name="file"
        validate={required}
        label="File upload"
        component={FileUploader}
      />
    </form>
  );
};

export default reduxForm<CopyLocationOrCreateFormData, OwnProps>({ form: 'copyLocationForm' })(CopyLocationOrCreateForm);

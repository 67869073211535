import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { PaymentMethods, PreparationSteps } from '../../../../constants/invoice';
import LocationCustomFieldsForm from '../../../../components/invoice/LocationCustomFieldsForm';
import { InvoicePaths } from '../../../../services/app/paths';
import { getPreparedInvoice } from '../../../../services/app/invoice';
import { Company } from '../../../../types/Company';
import { isAnyPaymentMethodExceptCheckEnabled } from '../../../../services/app/company';
import { handleReduxFormError } from '../../../../services/app/forms';
import * as Query from 'query-string';
import { Invoice } from '../../../../types/Invoice';
import { Location } from '../../../../types/Location';
import InvoicePreparationStepContainer from './InvoicePreparationStepContainer';
import { CustomField } from '../../../../types/CustomField';
import { GlobalState } from '../../../../types/GlobalState';
import { getInvoice } from '../../../../actions/invoices'
import { OnCompletedStepProps } from '../../../../types/OnCompletedStepProps';
import { createInvoice, updateInvoice } from '../../../../services/api/invoices';
import { createWorkOrderInvoice } from '../../../../services/api/workOrder';
import { FullScreenLoader } from '@roadsync/roadsync-ui';
import { getAttributes, isTreatmentOn } from '../../../../services/app/split';
import { FeatureFlag } from '../../../../components/featureFlag/FeatureFlag';

interface State {
    submitting: boolean;
    isSplitAdvanceToCheckoutDirectPaymentsEnabled?: boolean;
}

interface RouteParams {
    invoiceId?: string;
}

type PropsFromState = Pick<GlobalState, "auth" | "invoices" | "locations" | "deposits" | "companies" | "labels">;

interface OwnProps extends OnCompletedStepProps {
    onPrevStep: () => void;
}

interface Props extends OwnProps, RouteComponentProps<RouteParams>, DispatchProp, PropsFromState { }

class LocationCustomFields extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = { submitting: false };
    }

    getInvoiceLocationId(): string | undefined {
        const invoice = this.getInvoice();
        return invoice.locationId
            ? invoice.locationId
            : "string" === typeof invoice.location
                ? invoice.location
                : invoice.location?.id
    }

    getLocation(): Location | undefined {
        const { locations } = this.props;
        const locationId = this.getInvoiceLocationId();
        return locationId ? locations.data?.[locationId] : undefined;
    }

    async componentDidMount(): Promise<void> {
        const { onPrevStep, auth } = this.props;
        const company = this.getCompany();
        const attributes = getAttributes(auth?.me, company);
        const isSplitAdvanceToCheckoutDirectPaymentsEnabled = await isTreatmentOn(FeatureFlag.AdvanceToCheckoutDirectPayments, attributes);

        this.setState({ isSplitAdvanceToCheckoutDirectPaymentsEnabled });

        if (!this.getLocation()?.customFields?.length) {
            onPrevStep();
        }
    }

    getInvoice(): Partial<Invoice> {
        const { invoices, match: { params: { invoiceId } } } = this.props;
        const invoice: Partial<Invoice> = invoiceId ? invoices?.data?.[invoiceId] || {} : {};
        const progress = invoices?.ui?.progress;
        if (progress) {
            Object.keys(progress).forEach(k => invoice[k] = progress[k]);
        }
        return invoice;
    }

    getCompany(): Company | undefined {
        const { companies, auth: { me } } = this.props;
        return companies.data?.[me.companyId];
    }

    getNextStep(createdInvoice?: Partial<Invoice>): PreparationSteps {
        const { isSplitAdvanceToCheckoutDirectPaymentsEnabled } = this.state;
        var invoice = this.getInvoice();
        const company = this.getCompany();

        if (createdInvoice) {
            invoice = createdInvoice;
        }

        // 1. before creation we do not know the invoice type upfront - so we need to rely on split flag only -> redirect to LineItems
        // 2. on invoice edit if the invoice.type = 'directpayment' -> redirect to LineItems
        // 3. on invoice edit even if split flag is off, there will be a message to change the payment method
        if (isSplitAdvanceToCheckoutDirectPaymentsEnabled && invoice?.type === PaymentMethods.DIRECT_PAYMENT.key) {
            return PreparationSteps.LINE_ITEMS;
        }

        if (invoice.id && invoice.type) {
            return PreparationSteps.PAYMENT_METHOD_EXISTS;
        }
        if (!isAnyPaymentMethodExceptCheckEnabled(company)) {
            return PreparationSteps.SELECT_FLEET_CARD_TYPE;
        }

        return PreparationSteps.PAYMENT_METHOD;
    }

    getQueryParameters(): Query.ParsedQuery<string> {
        return Query.parse(this.props?.location?.search || "");
    }

    getWorkOrderIdQueryParam(): string | null | undefined {
        const workOrderId = this.getQueryParameters().workOrderId;
        return Array.isArray(workOrderId) ? workOrderId.shift() : workOrderId;
    }

    onFormError(err: any): void {
        handleReduxFormError(err);
    }

    async updateInvoice(invoice: Invoice): Promise<Invoice> {
        return updateInvoice(invoice);
    }

    async createInvoiceFromWorkOrder(workOrderId: string, invoice: Invoice): Promise<Invoice> {
        return createWorkOrderInvoice(workOrderId, invoice);
    }

    async createInvoice(invoice: Invoice): Promise<Invoice> {
        const { auth: { me } } = this.props;
        return createInvoice(me.companyId, invoice);
    }

    async save(customFields: { [k: string]: string | undefined }): Promise<Invoice> {
        const workOrderId = this.getWorkOrderIdQueryParam();
        const invoice = getPreparedInvoice({ ...this.getInvoice() as Invoice, customFields });
        if (invoice.id) {
            return this.updateInvoice(invoice);
        }
        if (workOrderId) {
            return this.createInvoiceFromWorkOrder(workOrderId, invoice);
        }
        return this.createInvoice(invoice);
    }

    async handleSubmit(customFields: { [k: string]: string | undefined }): Promise<void> {
        const { history, dispatch } = this.props;
        this.setState({ submitting: true });
        try {
            const invoice = await this.save(customFields);
            await dispatch<any>(getInvoice(invoice.id));
            history.replace(InvoicePaths.editUrl(invoice.id));

            const { onCompletedStep } = this.props;
            onCompletedStep(this.getNextStep(invoice));
        } catch (e) {
            this.onFormError(e);
        }
        this.setState({ submitting: false });
    }

    getLocationCustomFields(): CustomField[] | undefined {
        const { locations } = this.props;
        const locationId = this.getInvoiceLocationId();
        return locationId ? locations.data?.[locationId]?.customFields : undefined;
    }

    getInvoiceCustomFields(): { [k: string]: string | undefined } | undefined {
        const invoice = this.getInvoice();
        return invoice?.customFields;
    }

    render() {
        const { onPrevStep } = this.props;
        const { submitting } = this.state;

        return (
            <InvoicePreparationStepContainer title="Enter Additional Information">
                <FullScreenLoader show={submitting} />
                <LocationCustomFieldsForm
                    customFields={this.getLocationCustomFields()}
                    initialValues={this.getInvoiceCustomFields()}
                    onPrevStep={onPrevStep}
                    onSubmit={this.handleSubmit}
                />
            </InvoicePreparationStepContainer>
        );
    }
}

// istanbul ignore next
const mapStateToProps = ({ auth, invoices, locations, deposits, companies, labels }): PropsFromState =>
    ({ auth, invoices, locations, deposits, companies, labels })

export default withRouter(connect(mapStateToProps)(LocationCustomFields));

import React from "react";
import { Box } from "@material-ui/core";
import { connect, DispatchProp } from "react-redux";
import { openModal } from "../../actions/modals";
import { ModalsConstants } from "../../constants/modals";
import { Error } from "../ui/Error";
import SignatureBox from "../ui/SignatureBox";

interface Props extends OwnProps, DispatchProp { }

interface OwnProps {
  uploadSignature: (base64Img: string) => void;
  onSignatureUploaded: (signatureUrl: string) => void;
  signatureUrl?: string;
  invoiceId?: string;
  token?: string;
}

interface State {
  error?: string;
}

class InvoiceSignatureBox extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.handleSignatureCapture = this.handleSignatureCapture.bind(this);
    this.openAddSignatureModal = this.openAddSignatureModal.bind(this);
    this.state = {};
  }

  handleSignatureCapture(obj): void {
    const { uploadSignature } = this.props;
    if (obj.errorMsg) {
      return this.showError(obj.errorMsg);
    }
    uploadSignature(`data:image/png;base64,${obj.imageData}`)
  }

  showError(error = 'Something went wrong. Please try again later'): void {
    this.setState({ error }, () => setTimeout(this.clearError, 5000));
  }

  clearError(): void {
    this.setState({ error: undefined });
  }

  openAddSignatureModal(): void {
    const { dispatch, invoiceId, token, onSignatureUploaded } = this.props;
    dispatch(openModal(ModalsConstants.SIGNATURE, {
      invoiceId, token, signatureUploaded: onSignatureUploaded
    }));
  }

  render(): React.ReactElement {
    const { error } = this.state;
    const { signatureUrl } = this.props;
    return (
      <Box>
        <Error error={error} mt={2} mb={2} />
        <SignatureBox onClick={this.openAddSignatureModal} signatureUrl={signatureUrl} />
      </Box>
    );
  }
}


export default connect()(InvoiceSignatureBox);

import { CombinedState, Store } from 'redux';
import { FeatureFlag } from '../../components/ui/Visibility';

let store: Store<CombinedState<{ [x: string]: any }>, never> | undefined = undefined;

export function init(s: Store<CombinedState<{ [x: string]: any }>, never>): void {
  store = s;
}

export function isFeatureFlagEnabled(flag: FeatureFlag): boolean {
  return !!store?.getState()?.split?.features?.[flag];
}

import _ from 'lodash';

export interface InvoiceStepCategory {
  key: string;
  display: string;
}

export class InvoiceStepCategories {
  static readonly INFO: InvoiceStepCategory = {
    key: 'info',
    display: 'Info',
  };

  static readonly ATTACH: InvoiceStepCategory = {
    key: 'attach',
    display: 'Attach',
  };

  static readonly PAYMENT: InvoiceStepCategory = {
    key: 'payment',
    display: 'Pay',
  };

  static readonly INVOICE: InvoiceStepCategory = {
    key: 'invoice',
    display: 'Invoice',
  };

  static readonly CHECKOUT: InvoiceStepCategory = {
    key: 'checkout',
    display: 'Checkout',
  };

  private static readonly LOOKUP = {
    'info': InvoiceStepCategories.INFO,
    'attach': InvoiceStepCategories.ATTACH,
    'pay': InvoiceStepCategories.PAYMENT,
    'invoice': InvoiceStepCategories.INVOICE,
    'checkout': InvoiceStepCategories.CHECKOUT,
  };

  static getTypes(): InvoiceStepCategory[] {
    return _.values(InvoiceStepCategories.LOOKUP);
  }

  static getByKey(key: string): InvoiceStepCategory {
    return InvoiceStepCategories.LOOKUP[key];
  }

}

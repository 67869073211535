import React from 'react';
import { Button } from './Button';
import { ButtonProps } from './ButtonProps';
import { ButtonConfig } from './ButtonConfig';

export const SecondaryButton: React.FC<ButtonProps> = (props: ButtonProps): React.ReactElement => {
  const { children, ref, ...rest } = props;
  return (
    <Button variant={ButtonConfig.Variant} color="secondary" {...rest}>
      {children}
    </Button>
  );
}

import _ from 'lodash';

export interface FeatureCategoriesType {
  key: string;
  display: string;
}

export class FeatureCategories {
  static readonly PAYMENT_METHOD: FeatureCategoriesType = {
    display: 'Payment Method',
    key: 'payment_method',
  };

  static readonly FEE: FeatureCategoriesType = {
    display: 'Fee',
    key: 'fee',
  };

  static readonly PAYOUT: FeatureCategoriesType = {
    display: 'Payout',
    key: 'payout',
  };

  static readonly INVOICE: FeatureCategoriesType = {
    display: 'Invoice',
    key: 'invoice',
  };

  static readonly RECEIPT: FeatureCategoriesType = {
    display: 'Receipt',
    key: 'receipt',
  };

  static readonly SIGNATURE: FeatureCategoriesType = {
    display: 'Signature',
    key: 'signature',
  };

  static readonly WORK_ORDERS: FeatureCategoriesType = {
    display: 'Work Orders',
    key: 'work_orders',
  };

  static readonly PRIVACY: FeatureCategoriesType = {
    display: 'Privacy',
    key: 'privacy',
  };

  static readonly EMAILS: FeatureCategoriesType = {
    display: 'Emails',
    key: 'emails',
  };

  static readonly SMS: FeatureCategoriesType = {
    display: 'SMS',
    key: 'sms',
  };

  static readonly CARDS: FeatureCategoriesType = {
    display: 'Cards',
    key: 'cards',
  };

  static readonly REPORTS: FeatureCategoriesType = {
    display: 'Reports',
    key: 'reports',
  };

  static readonly SURVEY: FeatureCategoriesType = {
    display: 'Survey',
    key: 'survey',
  };

  static readonly PRODUCTS: FeatureCategoriesType = {
    display: 'Products',
    key: 'products',
  };

  static readonly ACCOUNTING: FeatureCategoriesType = {
    display: 'Accounting',
    key: 'accounting',
  };

  private static readonly LOOKUP = {
    'payment_method': FeatureCategories.PAYMENT_METHOD,
    'fee': FeatureCategories.FEE,
    'payout': FeatureCategories.PAYOUT,
    'invoice': FeatureCategories.INVOICE,
    'receipt': FeatureCategories.RECEIPT,
    'signature': FeatureCategories.SIGNATURE,
    'work_orders': FeatureCategories.WORK_ORDERS,
    'privacy': FeatureCategories.PRIVACY,
    'emails': FeatureCategories.EMAILS,
    'sms': FeatureCategories.SMS,
    'cards': FeatureCategories.CARDS,
    'reports': FeatureCategories.REPORTS,
    'survey': FeatureCategories.SURVEY,
    'products': FeatureCategories.PRODUCTS,
    'accounting': FeatureCategories.ACCOUNTING,
  };

  static getTypes(): FeatureCategoriesType[] {
    return _.values(FeatureCategories.LOOKUP);
  }
}

/* eslint-disable max-lines */
export class AuthActions {
  static readonly REQUEST_AUTH_CHECK = 'auth/REQUEST_AUTH_CHECK';
  static readonly RECEIVE_AUTH_CHECK = 'auth/RECEIVE_AUTH_CHECK';
  static readonly RECEIVE_LOGIN = 'auth/RECEIVE_LOGIN';
  static readonly REQUEST_LOGOUT = 'auth/REQUEST_LOGOUT';
  static readonly CHANGED_PASSWORD = 'auth/CHANGED_PASSWORD';
  static readonly REQUEST_SIGNUP = 'auth/REQUEST_SIGNUP';
  static readonly RECEIVE_SIGNUP = 'auth/RECEIVE_SIGNUP';
}

export class UserActions {
  static readonly REQUEST_USER = 'user/REQUEST_USER';
  static readonly RECEIVE_USER = 'user/RECEIVE_USER';
  static readonly CREATING_USER = 'user/CREATING_USER';
  static readonly CREATED_USER = 'user/CREATED_USER';
  static readonly UPDATING_USER = 'user/UPDATING_USER';
  static readonly UPDATED_USER = 'user/UPDATED_USER';
  static readonly DELETING_USER = 'user/DELETING_USER';
  static readonly DELETED_USER = 'user/DELETED_USER';
  static readonly REQUEST_USER_LIST = 'user/REQUEST_USER_LIST';
  static readonly RECEIVE_USER_LIST = 'user/RECEIVE_USER_LIST';
  static readonly VERIFYING_USER = 'user/VERIFYING_USER';
  static readonly VERIFIED_USER = 'user/VERIFIED_USER';
  static readonly REGENERATING_VERIFICATION_CODE = 'user/REGENERATING_VERIFICATION_CODE';
  static readonly REGENERATED_VERIFICATION_CODE = 'user/REGENERATED_VERIFICATION_CODE';
  static readonly REQUEST_ADMIN_USER_LIST = 'user/REQUEST_ADMIN_USER_LIST';
  static readonly RECEIVE_ADMIN_USER_LIST = 'user/RECEIVE_ADMIN_USER_LIST';
  static readonly REQUEST_LOCK_OUT_LOCATION_USERS = 'user/REQUEST_LOCK_OUT_LOCATION_USERS';
  static readonly RECEIVE_LOCK_OUT_LOCATION_USERS = 'user/RECEIVE_LOCK_OUT_LOCATION_USERS';
  static readonly REQUEST_LOCK_OUT_COMPANY_USERS = 'user/REQUEST_LOCK_OUT_COMPANY_USERS';
  static readonly RECEIVE_LOCK_OUT_COMPANY_USERS = 'user/RECEIVE_LOCK_OUT_COMPANY_USERS';
}

export class TwoFactorActions {
  static readonly REQUEST_TWO_FACTOR_AUTH_NEW = 'user/REQUEST_TWO_FACTOR_AUTH_NEW';
  static readonly RECEIVE_TWO_FACTOR_AUTH_NEW = 'user/RECEIVE_TWO_FACTOR_AUTH_NEW';
  static readonly REQUEST_TWO_FACTOR_AUTH_QR = 'user/REQUEST_TWO_FACTOR_AUTH_QR';
  static readonly RECEIVE_TWO_FACTOR_AUTH_QR = 'user/RECEIVE_TWO_FACTOR_AUTH_QR';
  static readonly REQUEST_TWO_FACTOR_AUTH_CONFIRMATION = 'user/REQUEST_TWO_FACTOR_AUTH_CONFIRMATION';
  static readonly RECEIVE_TWO_FACTOR_AUTH_CONFIRMATION = 'user/RECEIVE_TWO_FACTOR_AUTH_CONFIRMATION';
  static readonly REQUEST_TWO_FACTOR_AUTH_RESET = 'user/REQUEST_TWO_FACTOR_AUTH_RESET';
  static readonly RECEIVE_TWO_FACTOR_AUTH_RESET = 'user/RECEIVE_TWO_FACTOR_AUTH_RESET';
}

export class CompanyActions {
  static readonly REQUEST_COMPANY = 'company/REQUEST_COMPANY';
  static readonly RECEIVE_COMPANY = 'company/RECEIVE_COMPANY';
  static readonly CREATING_COMPANY = 'company/CREATING_COMPANY';
  static readonly CREATED_COMPANY = 'company/CREATED_COMPANY';
  static readonly REQUEST_COMPANY_LIST = 'company/REQUEST_COMPANY_LIST';
  static readonly RECEIVE_COMPANY_LIST = 'company/RECEIVE_COMPANY_LIST';
  static readonly UPDATING_COMPANY = 'company/UPDATING_COMPANY';
  static readonly UPDATED_COMPANY = 'company/UPDATED_COMPANY';
}

export class LocationActions {
  static readonly REQUEST_LOCATION = 'location/REQUEST_LOCATION';
  static readonly RECEIVE_LOCATION = 'location/RECEIVE_LOCATION';
  static readonly REQUEST_LOCATION_LIST = 'location/REQUEST_LOCATION_LIST';
  static readonly RECEIVE_LOCATION_LIST = 'location/RECEIVE_LOCATION_LIST';
  static readonly CREATING_LOCATION = 'location/CREATING_LOCATION';
  static readonly CREATED_LOCATION = 'location/CREATED_LOCATION';
  static readonly UPDATING_LOCATION = 'location/UPDATING_LOCATION';
  static readonly UPDATED_LOCATION = 'location/UPDATED_LOCATION';
  static readonly UPDATING_CUSTOM_FIELDS = 'location/UPDATING_CUSTOM_FIELDS';
  static readonly UPDATED_CUSTOM_FIELDS = 'location/UPDATED_CUSTOM_FIELDS';
}

export class ProductActions {
  static readonly REQUEST_PRODUCT_LIST = 'product/REQUEST_PRODUCT_LIST';
  static readonly RECEIVE_PRODUCT_LIST = 'product/RECEIVE_PRODUCT_LIST';
  static readonly CREATING_PRODUCT = 'product/CREATING_PRODUCT';
  static readonly CREATED_PRODUCT = 'product/CREATED_PRODUCT';
  static readonly REQUEST_PRODUCT = 'product/REQUEST_PRODUCT';
  static readonly RECEIVE_PRODUCT = 'product/RECEIVE_PRODUCT';
  static readonly UPDATING_PRODUCT = 'product/UPDATING_PRODUCT';
  static readonly UPDATED_PRODUCT = 'product/UPDATED_PRODUCT';
  static readonly DELETING_PRODUCT = 'product/DELETING_PRODUCT';
  static readonly DELETED_PRODUCT = 'product/DELETED_PRODUCT';
  static readonly UPDATE_PRODUCT_WEIGHT = 'product/UPDATE_PRODUCT_WEIGHT';
  static readonly DESTROY_LIST = 'product/DESTROY_LIST';
}

export class InvoiceActions {
  static readonly REQUEST_INVOICE_LIST = 'invoice/REQUEST_INVOICE_LIST';
  static readonly RECEIVE_INVOICE_LIST = 'invoice/RECEIVE_INVOICE_LIST';
  static readonly CREATING_INVOICE = 'invoice/CREATING_INVOICE';
  static readonly DUPLICATING_INVOICE = 'invoice/DUPLICATING_INVOICE';
  static readonly CREATED_INVOICE = 'invoice/CREATED_INVOICE';
  static readonly REQUEST_INVOICE = 'invoice/REQUEST_INVOICE';
  static readonly RECEIVE_INVOICE = 'invoice/RECEIVE_INVOICE';
  static readonly REQUEST_VOIDED_INVOICE = 'invoice/REQUEST_VOIDED_INVOICE';
  static readonly RECEIVE_VOIDED_INVOICE = 'invoice/RECEIVE_VOIDED_INVOICE';
  static readonly RECEIVE_INVOICE_PUSH = 'invoice/RECEIVE_INVOICE_PUSH';
  static readonly UPDATING_INVOICE = 'invoice/UPDATING_INVOICE';
  static readonly UPDATED_INVOICE = 'invoice/UPDATED_INVOICE';
  static readonly PREPARATION_STARTED = 'invoice/PREPARATION_STARTED';
  static readonly UNSAVED_PROGRESS = 'invoice/UNSAVED_PROGRESS';
  static readonly CLEAN_PROGRESS = 'invoice/CLEAN_PROGRESS';
  static readonly DELETING_INVOICE = 'invoice/DELETING_INVOICE';
  static readonly DELETED_INVOICE = 'invoice/DELETED_INVOICE';
  static readonly GENERATING_INVOICE_RECEIPT = 'invoice/GENERATING_INVOICE_RECEIPT';
  static readonly GENERATED_INVOICE_RECEIPT = 'invoice/GENERATED_INVOICE_RECEIPT';
  static readonly SENDING_INVOICE_RECEIPT = 'invoice/SENDING_INVOICE_RECEIPT';
  static readonly SENT_INVOICE_RECEIPT = 'invoice/SENT_INVOICE_RECEIPT';
  static readonly SENDING_NUMBER_INFO_TO_DRIVER = 'invoice/SENDING_NUMBER_INFO_TO_DRIVER';
  static readonly SENT_NUMBER_INFO_TO_DRIVER = 'invoice/SENT_NUMBER_INFO_TO_DRIVER';
  static readonly SENDING_NUMBER_NOTIFICATION_TO_PAYER = 'invoice/SENDING_NUMBER_NOTIFICATION_TO_PAYER';
  static readonly SENT_NUMBER_NOTIFICATION_TO_PAYER = 'invoice/SENT_NUMBER_NOTIFICATION_TO_PAYER';
  static readonly ADDING_SIGNATURE = 'invoice/ADDING_SIGNATURE';
  static readonly ADDED_SIGNATURE = 'invoice/ADDED_SIGNATURE';
  static readonly SENDING_INVOICE_FOR_COMPLETION = 'invoice/SENDING_INVOICE_FOR_COMPLETION';
  static readonly SENT_INVOICE_FOR_COMPLETION = 'invoice/SENT_INVOICE_FOR_COMPLETION';
  static readonly UPDATING_APPROVED_INVOICE = 'invoice/UPDATING_APPROVED_INVOICE';
  static readonly UPDATED_APPROVED_INVOICE = 'invoice/UPDATED_APPROVED_INVOICE';
  static readonly REQUEST_V1_INVOICE = 'invoice/REQUEST_V1_INVOICE';
  static readonly DELETING_V1_INVOICE = 'invoice/DELETING_V1_INVOICE';
  static readonly REQUEST_PAID_INVOICE_LIST = 'invoice/REQUEST_PAID_INVOICE_LIST';
  static readonly RECEIVE_PAID_INVOICE_LIST = 'invoice/RECEIVE_PAID_INVOICE_LIST';
  static readonly REQUEST_UNPAID_INVOICE_LIST = 'invoice/REQUEST_UNPAID_INVOICE_LIST';
  static readonly RECEIVE_UNPAID_INVOICE_LIST = 'invoice/RECEIVE_UNPAID_INVOICE_LIST';
  static readonly REQUEST_REFUNDED_INVOICE_LIST = 'invoice/REQUEST_REFUNDED_INVOICE_LIST';
  static readonly RECEIVE_REFUNDED_INVOICE_LIST = 'invoice/RECEIVE_REFUNDED_INVOICE_LIST';
  // maybe separated class needed
  static readonly REQUEST_ALL_OUTSTANDING_INVOICE_LIST = 'invoice/REQUEST_ALL_OUTSTANDING_INVOICE_LIST';
  static readonly RECEIVE_ALL_OUTSTANDING_INVOICE_LIST = 'invoice/RECEIVE_ALL_OUTSTANDING_INVOICE_LIST';

  static readonly REQUEST_UNSENT_OUTSTANDING_INVOICE_LIST = 'invoice/REQUEST_UNSENT_OUTSTANDING_INVOICE_LIST';
  static readonly RECEIVE_UNSENT_OUTSTANDING_INVOICE_LIST = 'invoice/RECEIVE_UNSENT_OUTSTANDING_INVOICE_LIST';

  static readonly REQUEST_SENT_OUTSTANDING_INVOICE_LIST = 'invoice/REQUEST_SENT_OUTSTANDING_INVOICE_LIST';
  static readonly RECEIVE_SENT_OUTSTANDING_INVOICE_LIST = 'invoice/RECEIVE_SENT_OUTSTANDING_INVOICE_LIST';

  static readonly REQUEST_BILLING_PAID_INVOICE_LIST = 'invoice/REQUEST_BILLING_PAID_INVOICE_LIST';
  static readonly RECEIVE_BILLING_PAID_INVOICE_LIST = 'invoice/RECEIVE_BILLING_PAID_INVOICE_LIST';

  static readonly UPDATE_INVOICES_BATCH = 'invoice/LAST_INVOICES_BATCH';

  static readonly SENDING_BATCH_INVOICE_RECEIPT = 'invoice/SENDING_BATCH_INVOICE_RECEIPT';
  static readonly SENT_BATCH_INVOICE_RECEIPT = 'invoice/SENT_BATCH_INVOICE_RECEIPT';
  static readonly DESTROY_LIST = 'invoice/DESTROY_LIST';
  static readonly MARKING_INVOICE_PROCESSING = 'invoice/MARKING_INVOICE_PROCESSING';
  static readonly MARKED_INVOICE_PROCESSING = 'invoice/MARKED_INVOICE_PROCESSING';
}

export class PublicActions {
  static readonly REQUEST_APP_SETTINGS = 'public/REQUEST_APP_SETTINGS';
  static readonly RECEIVE_APP_SETTINGS = 'public/RECEIVE_APP_SETTINGS';
  static readonly REQUEST_COMPANY_FEES = 'public/REQUEST_COMPANY_FEES';
  static readonly RECEIVE_COMPANY_FEES = 'public/RECEIVE_COMPANY_FEES';
  static readonly REQUEST_INVOICE = 'public/REQUEST_INVOICE';
  static readonly RECEIVE_INVOICE = 'public/RECEIVE_INVOICE';
  static readonly SEND_CHECK = 'public/SEND_CHECK';
  static readonly REQUEST_DEPOSIT = 'public/REQUEST_DEPOSIT';
  static readonly RECEIVE_DEPOSIT = 'public/RECEIVE_DEPOSIT';
  static readonly REUSE_CHECK_NUMBER = 'public/REUSE_CHECK_NUMBER';
  static readonly REQUEST_PUBLIC_FEATURE_LIST = 'features/REQUEST_PUBLIC_FEATURE_LIST';
  static readonly RECEIVE_PUBLIC_FEATURE_LIST = 'features/RECEIVE_PUBLIC_FEATURE_LIST';
}


export class DepositActions {
  static readonly UNSAVED_PROGRESS = 'deposit/UNSAVED_PROGRESS';
  static readonly REQUEST_DEPOSIT = 'deposit/REQUEST_DEPOSIT';
  static readonly RECEIVE_DEPOSIT = 'deposit/RECEIVE_DEPOSIT';
  static readonly REQUEST_DEPOSIT_VOIDED = 'deposit/REQUEST_DEPOSIT_VOIDED';
  static readonly RECEIVE_DEPOSIT_VOIDED = 'deposit/RECEIVE_DEPOSIT_VOIDED';
  static readonly RECEIVE_BATCH_DEPOSIT = 'deposit/RECEIVE_BATCH_DEPOSIT';
}

export class SocketActions {
  static readonly CONNECTION_OPENED = 'socket/CONNECTION_OPENED';
  static readonly CONNECTION_CLOSED = 'socket/CONNECTION_CLOSED';
  static readonly CONNECTION_FAILED = 'socket/CONNECTION_FAILED';
}

export class AlertActions {
  static readonly ALERT_SHOW = 'alert/SHOW';
  static readonly ALERT_DISMISS = 'alert/DISMISS';
}

export class BreadcrumbsActions {
  static readonly INITIALIZE = 'breadcrumbs/INITIALIZE';
  static readonly DESTROY = 'breadcrumbs/DESTROY';
}

export class ActivityLogs {
  static readonly REQUEST_ACTIVITY_LOGS = 'logs/REQUEST_ACTIVITY_LOGS';
  static readonly RECEIVE_ACTIVITY_LOGS = 'logs/RECEIVE_ACTIVITY_LOGS';
}

export class BankActions {
  static readonly REQUEST_STATUS = 'bank/REQUEST_STATUS';
  static readonly RECEIVE_STATUS = 'bank/RECEIVE_STATUS';

  static readonly CREATING_CUSTOMER = 'bank/CREATING_CUSTOMER';
  static readonly CREATED_CUSTOMER = 'bank/CREATED_CUSTOMER';

  static readonly ATTACHING_BANK_ACCOUNT = 'bank/ATTACHING_BANK_ACCOUNT';
  static readonly ATTACHED_BANK_ACCOUNT = 'bank/ATTACHED_BANK_ACCOUNT';

  static readonly CONFIRM_MICRO_DEPOSITS = 'bank/CONFIRM_MICRO_DEPOSITS';
  static readonly CONFIRMED_MICRO_DEPOSITS = 'bank/CONFIRMED_MICRO_DEPOSITS';

  static readonly BANKING_COLLECTING = 'bank/BANKING_COLLECTING';
  static readonly BANKING_COLLECTED = 'bank/BANKING_COLLECTED';

  static readonly REQUEST_BANK_ACCOUNT = 'bank/REQUEST_BANK_ACCOUNT';
  static readonly RECEIVE_BANK_ACCOUNT = 'bank/RECEIVE_BANK_ACCOUNT';

  static readonly REQUEST_BANK_BALANCE = 'bank/REQUEST_BANK_BALANCE';
  static readonly RECEIVE_BANK_BALANCE = 'bank/RECEIVE_BANK_BALANCE';

  static readonly REQUEST_BENEFICIAL_OWNERS = 'bank/REQUEST_BENEFICIAL_OWNERS';
  static readonly RECEIVE_BENEFICIAL_OWNERS = 'bank/RECEIVE_BENEFICIAL_OWNERS';

  static readonly UPDATING_BENEFICIAL_OWNERS = 'bank/UPDATING_BENEFICIAL_OWNERS';
  static readonly UPDATED_BENEFICIAL_OWNERS = 'bank/UPDATED_BENEFICIAL_OWNERS';

  static readonly DELETING_BENEFICIAL_OWNERS = 'bank/DELETING_BENEFICIAL_OWNERS';
  static readonly DELETED_BENEFICIAL_OWNERS = 'bank/DELETED_BENEFICIAL_OWNERS';

  static readonly RESETTING_BANK_ACCOUNT = 'bank/RESETTING_BANK_ACCOUNT';
  static readonly RESET_BANK_ACCOUNT = 'bank/RESET_BANK_ACCOUNT';
}

export class CardActions {
  static readonly REQUEST_CARD_ACCOUNT_STATUS = 'card/REQUEST_CARD_ACCOUNT_STATUS';
  static readonly RECEIVE_CARD_ACCOUNT_STATUS = 'card/RECEIVE_CARD_ACCOUNT_STATUS';

  static readonly ACCEPTING_CARD_TERMS_OF_SERVICE = 'card/ACCEPTING_CARD_TERMS_OF_SERVICE';
  static readonly ACCEPTED_CARD_TERMS_OF_SERVICE = 'card/ACCEPTED_CARD_TERMS_OF_SERVICE';

  static readonly ATTACHING_CARD_BANK_ACCOUNT = 'card/ATTACHING_CARD_BANK_ACCOUNT';
  static readonly ATTACHED_CARD_BANK_ACCOUNT = 'card/ATTACHED_CARD_BANK_ACCOUNT';

  static readonly CREATING_CARD_MERCHANT = 'card/CREATING_CARD_MERCHANT';
  static readonly CREATED_CARD_MERCHANT = 'card/CREATED_CARD_MERCHANT';

  static readonly REQUEST_TERMS_OF_SERVICE = 'card/REQUEST_TERMS_OF_SERVICE';
  static readonly RECEIVE_TERMS_OF_SERVICE = 'card/RECEIVE_TERMS_OF_SERVICE';

  static readonly REQUEST_RECOVERY_QUESTIONS = 'card/REQUEST_RECOVERY_QUESTIONS';
  static readonly RECEIVE_RECOVERY_QUESTIONS = 'card/RECEIVE_RECOVERY_QUESTIONS';

  static readonly CREATING_CARD_COMPANY_ACCOUNT = 'card/CREATING_CARD_COMPANY_ACCOUNT';
  static readonly CREATED_CARD_COMPANY_ACCOUNT = 'card/CREATED_CARD_COMPANY_ACCOUNT';

  static readonly CONFIRMING_MICRODEPOSIT = 'card/CONFIRMING_MICRODEPOSIT';
  static readonly CONFIRMED_MICRODEPOSIT = 'card/CONFIRMED_MICRODEPOSIT';

  static readonly CONFIRMING_BENEFICIAL_OWNERS = 'card/CONFIRMING_BENEFICIAL_OWNERS';
  static readonly CONFIRMED_BENEFICIAL_OWNERS = 'card/CONFIRMED_BENEFICIAL_OWNERS';

  static readonly RESETTING_BANK_ACCOUNT = 'card/RESETTING_BANK_ACCOUNT';
  static readonly RESET_BANK_ACCOUNT = 'card/RESET_BANK_ACCOUNT';
}

export class CheckStatusesActions {
  static readonly REQUEST_CHECK_LIST = 'check/REQUEST_CHECK_LIST';
  static readonly RECEIVE_CHECK_LIST = 'check/RECEIVE_CHECK_LIST';
  static readonly REQUEST_CHECK_DEPOSITS_LIST = 'check/REQUEST_CHECK_DEPOSITS_LIST';
  static readonly RECEIVE_CHECK_DEPOSITS_LIST = 'check/RECEIVE_CHECK_DEPOSITS_LIST';
  static readonly REQUEST_CHECK_INFO = 'check/REQUEST_CHECK_INFO';
  static readonly RECEIVE_CHECK_INFO = 'check/RECEIVE_CHECK_INFO';
  static readonly INSERTING_CHECK_NUMBERS = 'check/INSERTING_CHECK_NUMBERS';
  static readonly INSERTED_CHECK_NUMBERS = 'check/INSERTED_CHECK_NUMBERS';
  static readonly REQUEST_AVAILABLE_CHECKS = 'check/REQUEST_AVAILABLE_CHECKS';
  static readonly RECEIVE_AVAILABLE_CHECKS = 'check/RECEIVE_AVAILABLE_CHECKS';
  static readonly DESTROY_LIST = 'check/DESTROY_LIST';
}

export class AppSettingsActions {
  static readonly REQUEST_APP_SETTINGS = 'appsettings/REQUEST_APP_SETTINGS';
  static readonly RECEIVE_APP_SETTINGS = 'appsettings/RECEIVE_APP_SETTINGS';
  static readonly SET_APP_DEFAULTS = 'appsettings/SET_APP_DEFAULTS';
}

export class MobileMenuActions {
  static readonly TOGGLE_MOBILE_MENU = 'mobilemenu/TOGGLE_MOBILE_MENU';
  static readonly CLOSE_MOBILE_MENU = 'mobilemenu/CLOSE_MOBILE_MENU';
}

export class SelectedMenuItem {
  static readonly UPDATE_SELECTED_MENU_ITEM = 'selectedMenuItem/UPDATE_SELECTED_MENU_ITEM';
}

export class FileUploadActions {
  static readonly UPLOADING_FILE = 'fileupload/UPLOADING_FILE';
  static readonly UPLOADED_FILE = 'fileupload/UPLOADED_FILE';
  static readonly GET_IMAGE_BY_FILEID = 'fileupload/GET_IMAGE_BY_FILEID';
  static readonly GOT_IMAGE_BY_FILEID = 'fileupload/GOT_IMAGE_BY_FILEID';
}

export class FeeActions {
  static readonly REQUEST_FEE_LIST = 'fees/REQUEST_FEE_LIST';
  static readonly RECEIVE_FEE_LIST = 'fees/RECEIVE_FEE_LIST';
  static readonly REQUEST_FEE = 'fees/REQUEST_FEE';
  static readonly RECEIVE_FEE = 'fees/RECEIVE_FEE';
  static readonly CREATING_FEE = 'fees/CREATING_FEE';
  static readonly CREATED_FEE = 'fees/CREATED_FEE';
  static readonly UPDATING_FEE = 'fees/UPDATING_FEE';
  static readonly UPDATED_FEE = 'fees/UPDATED_FEE';
  static readonly DELETING_FEE = 'fees/DELETING_FEE';
  static readonly DELETED_FEE = 'fees/DELETED_FEE';
}

export class FeatureActions {
  static readonly REQUEST_FEATURE_LIST = 'features/REQUEST_FEATURE_LIST';
  static readonly RECEIVE_FEATURE_LIST = 'features/RECEIVE_FEATURE_LIST';
  static readonly REQUEST_FEATURE = 'features/REQUEST_FEATURE';
  static readonly RECEIVE_FEATURE = 'features/RECEIVE_FEATURE';
  static readonly CREATING_FEATURE = 'features/CREATING_FEATURE';
  static readonly CREATED_FEATURE = 'features/CREATED_FEATURE';
  static readonly UPDATING_FEATURE = 'features/UPDATING_FEATURE';
  static readonly UPDATED_FEATURE = 'features/UPDATED_FEATURE';
  static readonly DELETING_FEATURE = 'features/DELETING_FEATURE';
  static readonly DELETED_FEATURE = 'features/DELETED_FEATURE';
  static readonly DESTROY_LIST = 'features/DESTROY_LIST';
}

export class DepartmentActions {
  static readonly REQUEST_DEPARTMENT_LIST = 'department/REQUEST_DEPARTMENT_LIST';
  static readonly RECEIVE_DEPARTMENT_LIST = 'department/RECEIVE_DEPARTMENT_LIST';
  static readonly CREATING_DEPARTMENT = 'department/CREATING_DEPARTMENT';
  static readonly CREATED_DEPARTMENT = 'department/CREATED_DEPARTMENT';
  static readonly REQUEST_DEPARTMENT = 'department/REQUEST_DEPARTMENT';
  static readonly RECEIVE_DEPARTMENT = 'department/RECEIVE_DEPARTMENT';
  static readonly UPDATING_DEPARTMENT = 'department/UPDATING_DEPARTMENT';
  static readonly UPDATED_DEPARTMENT = 'department/UPDATED_DEPARTMENT';
  static readonly DELETING_DEPARTMENT = 'department/DELETING_DEPARTMENT';
  static readonly DELETED_DEPARTMENT = 'department/DELETED_DEPARTMENT';
}

export class ShiftActions {
  static readonly REQUEST_SHIFT_LIST = 'shift/REQUEST_SHIFT_LIST';
  static readonly RECEIVE_SHIFT_LIST = 'shift/RECEIVE_SHIFT_LIST';
  static readonly CREATING_SHIFT = 'shift/CREATING_SHIFT';
  static readonly CREATED_SHIFT = 'shift/CREATED_SHIFT';
  static readonly REQUEST_SHIFT = 'shift/REQUEST_SHIFT';
  static readonly RECEIVE_SHIFT = 'shift/RECEIVE_SHIFT';
  static readonly UPDATING_SHIFT = 'shift/UPDATING_SHIFT';
  static readonly UPDATED_SHIFT = 'shift/UPDATED_SHIFT';
  static readonly DELETING_SHIFT = 'product/DELETING_SHIFT';
  static readonly DELETED_SHIFT = 'product/DELETED_SHIFT';
}

export class ModalActions {
  static readonly OPEN_MODAL = 'modal/OPEN_MODAL';
  static readonly CLOSE_MODAL = 'modal/CLOSE_MODAL';
}

export class ReportActions {
  static readonly CLEAN_REPORT_SCREEN = 'report/CLEAN_REPORT_SCREEN';
  static readonly GETTING_STATS_BY_COMPANY = 'report/GETTING_STATS_BY_COMPANY';
  static readonly GOT_STATS_BY_COMPANY = 'report/GOT_STATS_BY_COMPANY';
  static readonly REQUEST_INVOICES_BY_FILTER = 'report/REQUEST_INVOICES_BY_FILTER';
  static readonly RECEIVE_INVOICES_BY_FILTER = 'report/RECEIVE_INVOICES_BY_FILTER';
  static readonly REQUEST_VOIDED_INVOICES_REPORT = 'report/REQUEST_VOIDED_INVOICES_REPORT';
  static readonly RECEIVE_VOIDED_INVOICES_REPORT = 'report/RECEIVE_VOIDED_INVOICES_REPORT';
  static readonly REQUEST_REPORT_SETTINGS = 'report/REQUEST_REPORT_SETTINGS';
  static readonly RECEIVE_REPORT_SETTINGS = 'report/RECEIVE_REPORT_SETTINGS';
  static readonly SAVING_REPORT_SETTINGS = 'report/SAVING_REPORT_SETTINGS';
  static readonly REQUEST_REFUNDED_INVOICES_REPORT = 'report/REQUEST_REFUNDED_INVOICES_REPORT';
  static readonly RECEIVE_REFUNDED_INVOICES_REPORT = 'report/RECEIVE_REFUNDED_INVOICES_REPORT';
}

export class ReportRecipientActions {
  static readonly REQUEST_REPORT_RECIPIENT_LIST = 'reportrecipient/REQUEST_REPORT_RECIPIENT_LIST';
  static readonly RECEIVE_REPORT_RECIPIENT_LIST = 'reportrecipient/RECEIVE_REPORT_RECIPIENT_LIST';
  static readonly CREATING_REPORT_RECIPIENT = 'reportrecipient/CREATING_REPORT_RECIPIENT';
  static readonly CREATED_REPORT_RECIPIENT = 'reportrecipient/CREATED_REPORT_RECIPIENT';
  static readonly REQUEST_REPORT_RECIPIENT = 'reportrecipient/REQUEST_REPORT_RECIPIENT';
  static readonly RECEIVE_REPORT_RECIPIENT = 'reportrecipient/RECEIVE_REPORT_RECIPIENT';
  static readonly UPDATING_REPORT_RECIPIENT = 'reportrecipient/UPDATING_REPORT_RECIPIENT';
  static readonly UPDATED_REPORT_RECIPIENT = 'reportrecipient/UPDATED_REPORT_RECIPIENT';
  static readonly DELETING_REPORT_RECIPIENT = 'reportrecipient/DELETING_REPORT_RECIPIENT';
  static readonly DELETED_REPORT_RECIPIENT = 'reportrecipient/DELETED_REPORT_RECIPIENT';
}

export class CopyLocationActions {
  static readonly EXPORTING = 'copy_location/EXPORTING';
  static readonly EXPORTED = 'copy_location/EXPORTED';
  static readonly IMPORTING = 'copy_location/IMPORTING';
  static readonly IMPORTED = 'copy_location/IMPORTED';
  static readonly IMPORTING_NEW = 'copy_location/IMPORTING_NEW';
  static readonly IMPORTED_NEW = 'copy_location/IMPORTED_NEW';
}

export class EmailsLogsActions {
  static readonly REQUEST_EMAILS_LOGS_LIST = 'email_logs/REQUEST_EMAILS_LOGS_LIST';
  static readonly RECEIVE_EMAILS_LOGS_LIST = 'email_logs/RECEIVE_EMAILS_LOGS_LIST';
}

export class WorkOrderActions {
  static readonly REQUEST_WORK_ORDER_LIST = 'workorder/REQUEST_WORK_ORDER_LIST';
  static readonly RECEIVE_WORK_ORDER_LIST = 'workorder/RECEIVE_WORK_ORDER_LIST';
  static readonly CREATING_WORK_ORDER = 'workorder/CREATING_WORK_ORDER';
  static readonly CREATED_WORK_ORDER = 'workorder/CREATED_WORK_ORDER';
  static readonly REQUEST_WORK_ORDER = 'workorder/REQUEST_WORK_ORDER';
  static readonly RECEIVE_WORK_ORDER = 'workorder/RECEIVE_WORK_ORDER';
  static readonly UPDATING_WORK_ORDER = 'workorder/UPDATING_WORK_ORDER';
  static readonly UPDATED_WORK_ORDER = 'workorder/UPDATED_WORK_ORDER';
  static readonly DELETING_WORK_ORDER = 'workorder/DELETING_WORK_ORDER';
  static readonly DELETED_WORK_ORDER = 'workorder/DELETED_WORK_ORDER';
  static readonly SENDING_WORK_ORDER_RECEIPT = 'workorder/SENDING_WORK_ORDER_RECEIPT';
  static readonly SENT_WORK_ORDER_RECEIPT = 'workorder/SENT_WORK_ORDER_RECEIPT';

  static readonly SENDING_WORK_ORDER_APPROVE = 'workorder/SENDING_WORK_ORDER_APPROVE';
  static readonly SENT_WORK_ORDER_APPROVE = 'workorder/SENT_WORK_ORDER_APPROVE';

  static readonly PUBLIC_SENDING_WORK_ORDER_APPROVE = 'workorder/PUBLIC_SENDING_WORK_ORDER_APPROVE';
  static readonly PUBLIC_SENT_WORK_ORDER_APPROVE = 'workorder/PUBLIC_SENT_WORK_ORDER_APPROVE';

  static readonly CREATING_WORK_ORDER_INVOICE = 'workorder/CREATING_WORK_ORDER_INVOICE';
  static readonly CREATED_WORK_ORDER_INVOICE = 'workorder/CREATED_WORK_ORDER_INVOICE';
  static readonly INVOICE_PREPARATION_STARTED = 'workorder/INVOICE_PREPARATION_STARTED';

  static readonly CREATING_WORK_ORDER_CUSTOM_FIELDS = 'workorder/CREATING_WORK_ORDER_CUSTOM_FIELDS';
  static readonly CREATED_WORK_ORDER_CUSTOM_FIELDS = 'workorder/CREATED_WORK_ORDER_CUSTOM_FIELDS';
}

export class WorkOrderTemplatesActions {
  static readonly REQUEST_WORK_ORDER_TEMPLATES = 'workorder/REQUEST_WORK_ORDER_TEMPLATES';
  static readonly RECEIVE_WORK_ORDER_TEMPLATES = 'workorder/RECEIVE_WORK_ORDER_TEMPLATES';
  static readonly REQUEST_WORK_ORDER_TEMPLATE = 'workorder/REQUEST_WORK_ORDER_TEMPLATE';
  static readonly RECEIVE_WORK_ORDER_TEMPLATE = 'workorder/RECEIVE_WORK_ORDER_TEMPLATE';
  static readonly SAVING_WORK_ORDER_TEMPLATE = 'workorder/SAVING_WORK_ORDER_SETTINGS';
  static readonly SAVED_WORK_ORDER_TEMPLATE = 'workorder/SAVED_WORK_ORDER_TEMPLATE';
  static readonly REQUEST_WORK_ORDER_PDF_PREVIEW = 'workorder/REQUEST_WORK_ORDER_PDF_PREVIEW';
  static readonly RECEIVE_WORK_ORDER_PDF_PREVIEW = 'workorder/RECEIVE_WORK_ORDER_PDF_PREVIEW';
}

export class CompanyLabels {
  static readonly REQUEST_COMPANY_LABELS = 'company_labels/REQUEST_COMPANY_LABELS';
  static readonly RECEIVE_COMPANY_LABELS = 'company_labels/RECEIVE_COMPANY_LABELS';
  static readonly SENDING_COMPANY_LABELS = 'company_labels/SENDING_COMPANY_LABELS';
  static readonly SENT_COMPANY_LABELS = 'company_labels/SENT_COMPANY_LABELS';
}

export class CheckAuthorizerActions {
  static readonly REQUEST_PROCESSING_CHECKS = 'check_authorizer/REQUEST_PROCESSING_CHECKS';
  static readonly RECEIVE_PROCESSING_CHECKS = 'check_authorizer/RECEIVE_PROCESSING_CHECKS';

  static readonly REQUEST_CLAIM_DEPOSIT = 'check_authorizer/REQUEST_CLAIM_DEPOSIT';
  static readonly RECEIVE_CLAIM_DEPOSIT = 'check_authorizer/RECEIVE_CLAIM_DEPOSIT';

  static readonly REQUEST_APPROVE_DEPOSIT = 'check_authorizer/REQUEST_APPROVE_DEPOSIT';
  static readonly RECEIVE_APPROVE_DEPOSIT = 'check_authorizer/RECEIVE_APPROVE_DEPOSIT';

  static readonly REQUEST_DECLINE_DEPOSIT = 'check_authorizer/REQUEST_DECLINE_DEPOSIT';
  static readonly RECEIVE_DECLINE_DEPOSIT = 'check_authorizer/RECEIVE_DECLINE_DEPOSIT';

  static readonly RECEIVE_DEPOSIT = 'check_authorizer/RECEIVE_DEPOSIT';

  static readonly SET_BEEPED = 'check_authorizer/SET_BEEPED';
}

export class AttachedFileActions {
  static readonly REQUEST_PDF_INVOICE = 'files/REQUEST_PDF_INVOICE';
  static readonly RECEIVE_PDF_INVOICE = 'files/RECEIVE_PDF_INVOICE';
  static readonly ATTACHING_PDF_INVOICE = 'files/ATTACHING_PDF_INVOICE';
  static readonly ATTACHED_PDF_INVOICE = 'files/ATTACHED_PDF_INVOICE';
  static readonly REMOVING_PDF_INVOICE = 'files/REMOVING_PDF_INVOICE';
  static readonly REMOVED_PDF_INVOICE = 'files/REMOVED_PDF_INVOICE';
}

export class CompanySettingsActions {
  static readonly REQUEST_COMPANY_SETTINGS = 'companysettings/REQUEST_COMPANY_SETTINGS';
  static readonly RECEIVE_COMPANY_SETTINGS = 'companysettings/RECEIVE_COMPANY_SETTINGS';
  static readonly SAVING_COMPANY_SETTINGS = 'companysettings/SAVING_COMPANY_SETTINGS';
  static readonly SAVED_COMPANY_SETTINGS = 'companysettings/SAVED_COMPANY_SETTINGS';
  static readonly SAVING_COMPANY_SETTINGS_ERROR = 'companysettings/SAVING_COMPANY_SETTINGS_ERROR';
}

export class CarrierActions {
  static readonly REQUEST_CARRIER_LIST = 'carriers/REQUEST_CARRIER_LIST';
  static readonly RECEIVE_CARRIER_LIST = 'carriers/RECEIVE_CARRIER_LIST';
  static readonly REQUEST_CARRIER = 'carriers/REQUEST_CARRIER';
  static readonly RECEIVE_CARRIER = 'carriers/RECEIVE_CARRIER';
  static readonly CREATING_CARRIER = 'carriers/CREATING_CARRIER';
  static readonly CREATED_CARRIER = 'carriers/CREATED_CARRIER';
  static readonly UPDATING_CARRIER = 'carriers/UPDATING_CARRIER';
  static readonly UPDATED_CARRIER = 'carriers/UPDATED_CARRIER';
  static readonly DELETING_CARRIER = 'carriers/DELETING_CARRIER';
  static readonly DELETED_CARRIER = 'carriers/DELETED_CARRIER';
  static readonly IMPORTING_LIST = 'carriers/IMPORTING_LIST';
  static readonly IMPORTED_LIST = 'carriers/IMPORTED_LIST';
  static readonly REQUEST_UNIVERSAL_CARRIER_LIST = 'carriers/REQUEST_UNIVERSAL_CARRIER_LIST';
  static readonly RECEIVE_UNIVERSAL_CARRIER_LIST = 'carriers/RECEIVE_UNIVERSAL_CARRIER_LIST';
  static readonly CLEAN_UNIVERSAL_CARRIER_LIST = 'carriers/CLEAN_UNIVERSAL_CARRIER_LIST';
}

export class DashboardActions {
  static readonly RECEIVE_DASHBOARD_WEEKLY_TOTAL = 'dashboard/RECEIVE_DASHBOARD_WEEKLY_TOTAL';
  static readonly REQUEST_DEPOSIT_WEEKLY_TOTAL = 'dashboard/REQUEST_DEPOSIT_WEEKLY_TOTAL';
  static readonly RECEIVE_DASHBOARD_MONTHLY_TOTAL = 'dashboard/RECEIVE_DASHBOARD_MONTHLY_TOTAL';
  static readonly REQUEST_DEPOSIT_MONTHLY_TOTAL = 'dashboard/REQUEST_DEPOSIT_MONTHLY_TOTAL';
  static readonly RECEIVE_DASHBOARD_TRANSACTIONS_RANGE_TOTAL = 'dashboard/RECEIVE_DASHBOARD_TRANSACTIONS_RANGE_TOTAL';
  static readonly REQUEST_DEPOSIT_TRANSACTIONS_RANGE_TOTAL = 'dashboard/REQUEST_DEPOSIT_TRANSACTIONS_RANGE_TOTAL';
  static readonly REQUEST_ALL_PAYOUT_ACTIVITY = 'dashboard/REQUEST_ALL_PAYOUT_ACTIVITY';
}

export class AccountingActions {
  static readonly REQUEST_ACCOUNTING_SETTINGS = 'accounting/REQUEST_ACCOUNTING_SETTINGS';
  static readonly RECEIVE_ACCOUNTING_SETTINGS = 'accounting/RECEIVE_ACCOUNTING_SETTINGS';
  static readonly UPDATING_ACCOUNTING_SETTINGS = 'accounting/UPDATING_ACCOUNTING_SETTINGS';
  static readonly UPDATED_ACCOUNTING_SETTINGS = 'accounting/UPDATED_ACCOUNTING_SETTINGS';
  static readonly RECEIVE_ACCOUNTING_SETTINGS_ERROR = 'accounting/RECEIVE_ACCOUNTING_SETTINGS_ERROR';
  static readonly REQUEST_ACCOUNTING_PRODUCTS_LIST = 'accounting/REQUEST_ACCOUNTING_PRODUCTS_LIST';
  static readonly RECEIVE_ACCOUNTING_PRODUCTS_LIST = 'accounting/RECIEVE_ACCOUNTING_PRODUCTS_LIST';
  static readonly RECEIVE_ACCOUNTING_PRODUCTS_LIST_ERROR = 'accounting/RECEIVE_ACCOUNTING_PRODUCTS_LIST_ERROR';

}

export class DebitCardActions {
  static readonly REQUEST_DEBIT_CARDS = 'debitcard/REQUEST_DEBIT_CARDS';
  static readonly RECEIVE_DEBIT_CARDS = 'debitcard/RECEIVE_DEBIT_CARDS';
  static readonly CREATING_DEBIT_CARD = 'debitcard/CREATING_DEBIT_CARD';
  static readonly CREATED_DEBIT_CARD = 'debitcard/CREATED_DEBIT_CARD';
  static readonly DELETING_DEBIT_CARD = 'debitcard/DELETING_DEBIT_CARD';
  static readonly DELETED_DEBIT_CARD = 'debitcard/DELETED_DEBIT_CARD';
  static readonly UPDATING_DEBIT_CARD = 'debitcard/UPDATING_DEBIT_CARD';
  static readonly UPDATED_DEBIT_CARD = 'debitcard/UPDATED_DEBIT_CARD';

}

export class TransferActions {
  static readonly REQUEST_TRANSFER_DETAIL_LIST = 'transfer/REQUEST_TRANSFER_DETAIL_LIST';
  static readonly RECEIVE_TRANSFER_DETAIL_LIST = 'transfer/RECEIVE_TRANSFER_DETAIL_LIST';
  static readonly RECEIVE_TRANSFER_DETAIL_LIST_ERROR = 'transfer/RECEIVE_TRANSFER_DETAIL_LIST_ERROR';
}

export class CustomerActions {
  static readonly REQUEST_CUSTOMER = 'customer/REQUEST_CUSTOMER';
  static readonly RECEIVE_CUSTOMER = 'customer/RECEIVE_CUSTOMER';
  static readonly CREATING_CUSTOMER = 'customer/CREATING_CUSTOMER';
  static readonly CREATED_CUSTOMER = 'customer/CREATED_CUSTOMER';
  static readonly UPDATING_CUSTOMER = 'customer/UPDATING_CUSTOMER';
  static readonly UPDATED_CUSTOMER = 'customer/UPDATED_CUSTOMER';
  static readonly DELETING_CUSTOMER = 'customer/DELETING_CUSTOMER';
  static readonly DELETED_CUSTOMER = 'customer/DELETED_CUSTOMER';
  static readonly REQUEST_CUSTOMER_LIST = 'customer/REQUEST_CUSTOMER_LIST';
  static readonly RECEIVE_CUSTOMER_LIST = 'customer/RECEIVE_CUSTOMER_LIST';
  static readonly REQUEST_PUBLIC_CUSTOMER = 'customer/REQUEST_PUBLIC_CUSTOMER';
  static readonly RECEIVE_PUBLIC_CUSTOMER = 'customer/RECEIVE_PUBLIC_CUSTOMER';
  static readonly RECEIVE_PUBLIC_CUSTOMER_ERROR = 'customer/RECEIVE_PUBLIC_CUSTOMER_ERROR ';
  static readonly ASSIGN_INVOICE_TO_CUSTOMER = 'customer/ASSIGN_INVOICE_TO_CUSTOMER ';
  static readonly REQUEST_COMPANY_CUSTOMER = 'customer/REQUEST_COMPANY_CUSTOMER';
  static readonly RECEIVE_COMPANY_CUSTOMER = 'customer/RECEIVE_COMPANY_CUSTOMER';
  static readonly CLEAR_SEARCH_RESULTS = 'customer/CLEAR_SEARCH_RESULTS';
}

export class SplitActions {
  static readonly SET_SPLIT = 'split/SET_SPLIT';
}

export class PwaPreparationActionType {
  static readonly SAVE_EVENT_OBJECT = 'pwaPreparation/SAVE_EVENT_OBJECT'
}

export enum AdActions {
  OPEN_AD = 'ad/OPEN_AD',
  CLOSE_AD = 'ad/CLOSE_AD',
}

export enum InvoicePriceUpdatedModalActions {
  OPEN = 'invoicePriceUpdatedModal/OPEN',
  CLOSE = 'invoicePriceUpdatedModal/CLOSE',
  UNKNOWN = 'invoicePriceUpdatedModal/UNKNOWN'
}

import React from 'react';
import { reduxForm, Field, InjectedFormProps } from 'redux-form';
import { required } from '../../services/app/validators';
import SelectStyled from '../controls/SelectStyled';
import { InvoiceDeclineReasons } from '../../constants/invoiceDeclineReasons';
import { Deposit } from "../../types/Deposit";
import { Error } from '../ui/Error';

const TypeSelect = (props) => {
  const checkType = props.deposit.checkType;
  return (
    <SelectStyled {...props}>
      {InvoiceDeclineReasons.getReasonsByCheckType(checkType).map((option) =>
        <option key={option.key} value={option.key}>{option.display}</option>)}
    </SelectStyled>
  );
};

export interface RejectCheckFormData {
  reason: string;
}

type OwnProps = {
  deposit: Deposit;
}

type Props = InjectedFormProps<RejectCheckFormData, OwnProps> & OwnProps;

const RejectCheckForm: React.FC<Props> = (props: Props) => {
  const { error, handleSubmit, submitting, deposit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="dialer-reject-check-form">
        <Error error={error} />
        <button className="btn-white submit-btn" disabled={submitting} type="submit">
          {submitting ? 'Rejecting...' : 'Reject'}
        </button>
        <Field name="reason" component={TypeSelect} validate={required} deposit={deposit} />
      </div>
    </form>
  );
};

export default reduxForm<RejectCheckFormData, OwnProps>({ form: 'declineCheckForm' })(RejectCheckForm);

import * as DepositService from '../services/api/deposits';
import { getInvoice } from './invoices';
import { DepositActions } from '../constants/actions';
import { Deposit } from '../types/Deposit';
import { showSuccessAlert } from './alerts';
import { Dispatch } from "react";

export function receiveDeposit(invoiceId, deposit) {
    return {
        type: DepositActions.RECEIVE_DEPOSIT,
        invoiceId,
        deposit,
    };
}

function requestDeposit(invoiceId) {
    return {
        type: DepositActions.REQUEST_DEPOSIT,
        invoiceId,
    };
}

function receiveDepositVoided(invoiceId, deposit) {
    return {
        type: DepositActions.RECEIVE_DEPOSIT_VOIDED,
        invoiceId,
        deposit,
    };
}

function requestDepositVoided(invoiceId) {
    return {
        type: DepositActions.REQUEST_DEPOSIT_VOIDED,
        invoiceId,
    };
}

export function unsavedProgress(selectedType: string) {
    return {
        type: DepositActions.UNSAVED_PROGRESS,
        selectedType,
    };
}

export function depositCash(invoiceId: string, subType: string) {
    return (dispatch: Function) => {
        return DepositService.depositCash(invoiceId, subType)
            .then(() => dispatch(receiveDeposit(invoiceId, {})))
            .then(() => dispatch(getInvoice(invoiceId)));
    };
}

export function depositCheckCreateOrReuse(invoiceId: string, checkType: string, payerState, payerIdentifier, depositData?: Partial<Deposit>) {
    return async (dispatch: Function) => {
        return DepositService.depositCheckCreateOrReuse(invoiceId, checkType, payerState, payerIdentifier, depositData)
            .then(result => dispatch(receiveDeposit(invoiceId, result)));
    };
}

export function depositVGSCard(
    invoiceId: string,
    invoiceToken: string,
    license: string,
    billingFirstName: string,
    billingLastName: string,
    streetAddress: string,
    city: string,
    state: string,
    zipCode: string,
    paymentToken: string,
    cvv: string,
    ccexp: string
) {
    return async (dispatch: Function) => {
        const result = await DepositService.depositVGSCard(
            invoiceToken,
            license,
            billingFirstName,
            billingLastName,
            streetAddress,
            city,
            state,
            zipCode,
            paymentToken,
            cvv,
            ccexp
        );
        const deposit = await dispatch(receiveDeposit(invoiceId, result));
        await dispatch(getInvoice(invoiceId));
        return deposit;
    };
}

export function depositClonedCard(invoiceId: string): (dispatch: Dispatch<any>) => Promise<void> {
    return async (dispatch: Function) => {
        const result = await DepositService.depositClonedCard(invoiceId);
        const deposit = await dispatch(receiveDeposit(invoiceId, result));
        await dispatch(getInvoice(invoiceId));
        return deposit;
    };
}

export function validateCard(
    invoiceId: string, companyId: string, token: string, streetAddress?: string, cardState?: string, zipCode?: string,
    billingFirstName?: string, billingLastName?: string, city?: string, cvv?: string
) {
    return () => {
        return DepositService.validateCard(invoiceId, companyId, token, streetAddress, cardState, zipCode, billingFirstName, billingLastName, city, cvv)
    };
}

export function getDepositForInvoice(invoiceId: string) {
    return (dispatch: Function) => {
        dispatch(requestDeposit(invoiceId));
        return DepositService.getDepositForInvoice(invoiceId)
            .then(result => dispatch(receiveDeposit(invoiceId, result)));
    };
}

export function getDepositForVoidedInvoice(invoiceId: string) {
    return (dispatch: Function) => {
        dispatch(requestDepositVoided(invoiceId));
        return DepositService.getDepositForVoidedInvoice(invoiceId)
            .then(result => dispatch(receiveDepositVoided(invoiceId, result)));
    };
}

export function processCheck(deposit: Deposit) {
    return (dispatch: Function) => {
        return DepositService.processCheck(deposit.id, deposit)
            .then(result => dispatch(receiveDeposit(deposit.invoiceId, result)));
    };
}

export function processCheckComdata(deposit: Deposit) {
    return async (dispatch: Function) => {
        const result = await DepositService.processCheck(deposit.id, deposit);
        const newDeposit = await dispatch(receiveDeposit(deposit.invoiceId, result));
        await dispatch(getInvoice(deposit.invoiceId));
        return newDeposit;
    };
}

export function approveCheck(depositId: string, invoiceId: string, authNumber: string) {
    return (dispatch: Function) => {
        return DepositService.approveCheck(depositId, authNumber)
            .then(result => {
                dispatch(receiveDeposit(invoiceId, result));
                dispatch(showSuccessAlert('Check authorized successfully!'));
            });
    };
}

export function declineCheck(depositId: string, invoiceId: string, error: string) {
    return (dispatch: Function) => {
        return DepositService.declineCheck(depositId, error)
            .then(result => dispatch(receiveDeposit(invoiceId, result)));
    };
}

export function setCashPaymentMethod(invoiceId: string, subType: string) {
    return (dispatch: Function) => {
        return DepositService.setCashPaymentMethod(invoiceId, subType)
            .then(result => dispatch(receiveDeposit(invoiceId, result)));
    };
}

export function setCardPaymentMethod(invoiceId: string) {
    return (dispatch: Function) => {
        return DepositService.setCardPaymentMethod(invoiceId)
            .then(result => dispatch(receiveDeposit(invoiceId, result)));
    };
}

export function setFuelCardPaymentMethod(invoiceId: string, cardPaymentProcessor?: string) {
    return (dispatch: Function) => {
        return DepositService.setFuelCardPaymentMethod(invoiceId, cardPaymentProcessor)
            .then(result => dispatch(receiveDeposit(invoiceId, result)));
    };
}

export function setRemoteCheckoutMethod(invoiceId: string) {
    return (dispatch: Function) => {
        return DepositService.setRemoteCheckoutPaymentMethod(invoiceId)
            .then(result => dispatch(receiveDeposit(invoiceId, result)));
    };
}

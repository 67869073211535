
import React from 'react';
import { Grid, StyledComponentProps, withStyles } from '@material-ui/core';
import { Alert as MUIAlert } from '@material-ui/lab';
import { LightingIcon } from '../app/LightingIcon';
import styles from "./CustomStyledAlert.css";

type Props = StyledComponentProps & { className?: string, icon?: React.ReactNode, color?: 'success' | 'info' | 'warning' | 'error', children?: React.ReactNode };

const CustomStyledAlert: React.FC<Props> = (props: Props) => {
  const { classes, className, icon, color, children } = props;
    return <Grid item className={`${classes?.customStyledAlertWrapper} ${className}`}>
      <MUIAlert
        aria-atomic="true"
        color={color ? color : 'warning'}
        icon={icon ? icon : <LightingIcon />}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs>{children}</Grid>
        </Grid>
      </MUIAlert>
    </Grid>;
};

export default withStyles(styles)(CustomStyledAlert);

import React from "react";
import NotFound from "./notFound/NotFound";
import { Log } from "../services/LoggerService";

interface Props {
  children: React.ReactElement;
}

interface State {
  hasError?: boolean;
  errorId?: string;
}

class ErrorBoundary extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, errorId: undefined };
  }

  static getDerivedStateFromError(e: Error): State {
    return { hasError: true, errorId: Log.captureException(e) };
  }

  componentDidCatch(e: Error, errorInfo: Record<string, any>): void {
    const { errorId } = this.state;
    console.error(`Exception ${errorId}`, e, errorInfo);
  }

  render(): React.ReactElement {
    const { children } = this.props;
    const { hasError, errorId } = this.state;
    return hasError
      ? (
        <NotFound title="Sorry!" description="We've run into an unexpected technical issue.">
          <>To get help, email support@roadsync.com with the error code below.<br /><br />Error Code: {errorId}</>
        </NotFound>
      )
      : children;
  }
}

export default ErrorBoundary;

import { mergeStateAndEntityResult } from '../services/app/normalizr';
import { ShiftActions } from '../constants/actions';
import _ from 'lodash';
import { Shift } from '../types/Shift';

export interface ShiftsState {
  data?: { [id: string]: Shift };
  ui?: { loading?: boolean };
  order?: string[];
}

// eslint-disable-next-line max-lines-per-function
export function shifts(state = { data: {}, ui: {}, order: [] }, action: Record<string, any> = {}): ShiftsState {
  const nextData = mergeStateAndEntityResult(state, action.entities, 'shifts');

  switch (action.type) {
    case ShiftActions.REQUEST_SHIFT:
      return _.set({ ...state }, `data.${action.result}`, { loading: true });
    case ShiftActions.REQUEST_SHIFT_LIST:
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: true,
        },
      };
    case ShiftActions.RECEIVE_SHIFT_LIST:
      return {
        ...state,
        data: nextData,
        order: action.order,
        ui: {
          ...state.ui,
          loading: false,
        },
      };
    case ShiftActions.DELETED_SHIFT:
      return {
        ...state,
        data: _.omit({ ...state.data }, action.shiftId),
        order: _.pull(state.order, action.shiftId),
      };
    default:
      return {
        ...state,
        data: nextData,
      };
  }
}

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import useStyles from './LoaderIndicator.css';

interface Props {
  id?: string;
}

const LoaderIndicator: React.FC<Props> = React.memo((props: Props) => {
  const { id } = props;
  const classes = useStyles();

  return <CircularProgress
    id={id ?? 'loader-icon'}
    data-testid={id ?? 'loader-icon'}
    className={classes.loader}
  />;
});

export default LoaderIndicator;
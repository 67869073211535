import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  productInputLabel: {
    position: 'absolute',
    bottom: '55px',
    left: '0',
    right: '0',
    color: '#fff'
  },
}));

export default useStyles;


import { SplitFactory } from "@splitsoftware/splitio";
import SplitIO from "@splitsoftware/splitio/types/splitio";
import { User } from "../../types/User";
import { Company } from "../../types/Company";
import { CompaniesState } from "../../reducers/companies";
import { PublicDataState } from "../../reducers/public";
import { getAppSettingsPublic } from "../api/appSettings";
import { authCheck } from "../api/auth";

const isJestRunning = process.env.JEST_WORKER_ID !== undefined;

const getFactory: () => Promise<SplitIO.ISDK> = async (): Promise<SplitIO.ISDK> => {
  let user: User | undefined = undefined;
  const settings = await getAppSettingsPublic();
  try {
    user = await authCheck();
  } catch (e) {
    // Not logged in, do nothing.
  }
  const sdk = SplitFactory({
    core: {
      authorizationKey: settings.splitAuthorizationKey,
      key: user?.email || 'anonymous',
    }
  });
  return Promise.resolve(sdk);
}

export const manager: Promise<SplitIO.IManager> = new Promise(async (resolve) => {
  // During unit tests, you should be mocking all the actual Split look up functions, like `isTreatmentOn`,
  // `getTreatment`, and so forth. We don't want to reach out to the actual Split API.
  if (isJestRunning) {
    return;
  }
  const factory = await getFactory();
  const manager = factory.manager();
  manager.once(manager.Event.SDK_READY, () => resolve(manager));
});

export const client: Promise<SplitIO.IClient> = new Promise(async (resolve) => {
  // During unit tests, you should be mocking all the actual Split look up functions, like `isTreatmentOn`,
  // `getTreatment`, and so forth. We don't want to reach out to the actual Split API.
  if (isJestRunning) {
    return;
  }
  const factory = await getFactory();
  const client = factory.client();
  client.once(client.Event.SDK_READY, () => resolve(client));
});

export const getTreatmentWithConfig = async (splitName: string, attributes?: SplitIO.Attributes): Promise<SplitIO.TreatmentWithConfig> =>
  (await client).getTreatmentWithConfig(splitName, attributes);

export const getTreatment = async (splitName: string, attributes?: SplitIO.Attributes): Promise<SplitIO.Treatment> =>
  (await client).getTreatment(splitName, attributes);

export const isTreatmentOn = async (splitName: string, attributes?: SplitIO.Attributes): Promise<boolean> =>
  (await getTreatment(splitName, attributes)) === "on";

export const isTreatmentOff = async (splitName: string, attributes?: SplitIO.Attributes): Promise<boolean> =>
  !(await isTreatmentOff(splitName, attributes));

export const getAttributes = (user?: User, company?: Company): Record<string, string | number | boolean | Array<string | number>> => ({
  companyName: company?.name || "",
  companyId: company?.publicId || "",
  companyType: company?.type || "",
  companyTimezone: company?.timezone || "",
  companyState: company?.state || "",
  companyEnabledFeatures: company?.features?.filter(f => f.isEnabled).map(f => f.featureType) || [],
  companyDisabledFeatures: company?.features?.filter(f => !f.isEnabled).map(f => f.featureType) || [],
  email: user?.email || "",
  roles: user?.roles || [],
  username: user?.username || "",
});

export const getCompanyFromState = (
  companies: CompaniesState,
  publicData: PublicDataState,
  user?: User,
): Company | undefined => {
  return user?.id
    // If user is authenticated, prefer the user's company
    ? companies?.data?.[user?.companyId]
    // If the user is not authenticated, use the company object of the current invoice.
    // This would only be applicable when a guest is on a remote checkout page.
    : "string" === typeof publicData?.data?.invoice?.company
      ? undefined
      : publicData?.data?.invoice?.company;
}

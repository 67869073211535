import { PublicActions } from '../constants/actions';
import { Invoice } from '../types/Invoice';
import { Deposit } from '../types/Deposit';
import { AppConfig } from '../types/AppConfig';
import { Fee } from '../types/fee';
import { Feature } from '../types/Feature';

export interface PublicDataState {
  ui?: { loading?: boolean };
  deposit: Deposit;
  data: {
    invoice?: Invoice;
  };
  features: Feature[];
  fees: Fee[];
  settings: AppConfig;
}

interface PublicDataAction {
  type: string;
  loading?: boolean | null;
  entities: Invoice | Deposit | AppConfig | Feature[] | Fee[];
}

// eslint-disable-next-line max-lines-per-function
export function publicData(
  state: PublicDataState = { ui: {}, data: {}, deposit: {}, settings: {}, fees: {}, features: [] } as any,
  action: PublicDataAction = { type: "unknown", entities: [] }
): PublicDataState {
  switch (action.type) {
    case PublicActions.REQUEST_INVOICE:
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: action.loading == null ? true : action.loading,
        },
      };
    case PublicActions.RECEIVE_INVOICE:
      return {
        ...state,
        data: {
          invoice: action.entities as Invoice,
        },
        ui: {
          ...state.ui,
          loading: false,
        },
      };
    case PublicActions.REQUEST_DEPOSIT:
      return {
        ...state,
        ui: {
          ...state.ui,
        },
      };
    case PublicActions.RECEIVE_DEPOSIT:
      return {
        ...state,
        deposit: action.entities as Deposit,
        ui: {
          ...state.ui,
        },
      };
    case PublicActions.REQUEST_APP_SETTINGS:
      return {
        ...state,
        ui: {
          ...state.ui,
          loading: true
        },
      };
    case PublicActions.RECEIVE_APP_SETTINGS:
      return {
        ...state,
        settings: action.entities as AppConfig,
      };
    case PublicActions.RECEIVE_COMPANY_FEES:
      return {
        ...state,
        fees: action.entities as Fee[],
      };
    case PublicActions.RECEIVE_PUBLIC_FEATURE_LIST:
      return {
        ...state,
        features: action.entities as Feature[],
      };
    default:
      return {
        ...state,
      };
  }
}

import React from 'react'
import { Grid } from '@material-ui/core';
import MainWrapper from '../../../app/MainWrapper';

interface Props {
  children: React.ReactNode;
}

export const BreadcrumbsContainer: React.FC<Props> = (props: Props): React.ReactElement => (
  <Grid item>
    <MainWrapper>{props.children}</MainWrapper>
  </Grid>
);

import { round } from "./invoice";

export const incrementDecimalValueByOne = (value: number) => {
  const roundedTwoPointsDecimal = round(Number(value), 2);
  const calculatedNextValueTimes100 = (round(roundedTwoPointsDecimal * 100, 2) + 100);
  const decimalNextValue = calculatedNextValueTimes100 / 100;
  return decimalNextValue;
};

export const decrementDecimalValueByOne = (value: number) => {
  const roundedTwoPointsDecimal = round(Number(value), 2);
  const calculatedNextValueTimes100 = (round(roundedTwoPointsDecimal * 100, 2) - 100);
  const decimalNextValue = calculatedNextValueTimes100 / 100;
  return decimalNextValue;
};

import React from 'react';
import { LargePrimaryButton } from '../ui/buttons/LargePrimaryButton';
import styles from './HiddenSubmitButton.css';

interface Props {
  text?: string;
  disabled?: boolean;
  id?: string;
}

const HiddenSubmitButton: React.FC<Props> = (props: Props): React.ReactElement => {
  const { text, id, disabled } = props;
  const classes = styles();
  return (
    <LargePrimaryButton
      disabled={disabled}
      id={id}
      className={classes?.hiddenBtn}
      type="submit"
    >
      {text ? text : 'Submit'}
    </LargePrimaryButton>);
};

export default HiddenSubmitButton;

import { getCurrentVersion } from "./api/utils";
import { ConsoleLogger } from "./logger/ConsoleLogger";
import { DatadogLogger } from "./logger/DatadogLogger";
import { LoggerInterface } from "./logger/LoggerInterface";
import { LogLevel } from "./logger/LogLevel";
import { MultiLogger } from "./logger/MultiLogger";

export const environment: () => string = () => process.env.NODE_ENV;

export const version: () => string = () => getCurrentVersion();

export let Level: LogLevel = process.env.NODE_ENV !== "production"
  ? LogLevel.Debug
  : LogLevel.Warn;

export let Log: LoggerInterface = process.env.NODE_ENV !== "production"
  ? new ConsoleLogger()
  : new MultiLogger(new DatadogLogger());

export const use = (logger: LoggerInterface): void => {
  Log = logger;
}

export const useLevel = (level: LogLevel): void => {
  Level = level;
}

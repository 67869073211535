import { PreEditResponse, PreEditResponseParsed } from "../../../types";

function replaceComdataCharsWithRegexRules(char: string): string {
  const numeric = '#';
  const alpha = '@';
  const alphanumeric = '*';

  char = char.replace(numeric, '[0-9]');
  char = char.replace(alpha, '[a-zA-Z]');
  char = char.replace(alphanumeric, '[0-9a-zA-Z]');
  return char;
}

function validationRuleBasedOnFormat(format: string): string {
  let validationRule = '';
  const formatArray = format.split('');
  const converted = formatArray.map(item => replaceComdataCharsWithRegexRules(item));
  converted.map(item => validationRule += item);
  return validationRule;
}

export function comdataPreEditParser(data: PreEditResponse): PreEditResponseParsed {
  for (const [key] of Object.entries(data)) {
    if (key.endsWith('Format', key.length) && requiredByIndicatorValue(data[key.substring(0, key.length - 6) + 'Indicator'])) {
      data[key.substring(0, key.length - 6) + 'Validation'] = validationRuleBasedOnFormat(data[key]);
    }

    // driverLicenseStateFormat only, no personal indicator. Use driverLicenseNumberIndicator as they always come together
    if (requiredByIndicatorValue(data['driverLicenseNumberIndicator']) && key === 'driverLicenseStateFormat' && key.length > 0) {
      data['driverLicenseStateValidation'] = validationRuleBasedOnFormat(data[key]);
    }
  }
  return data;
}

export const requiredByIndicatorValue = (rbiValue?: string): boolean =>
  rbiValue === 'C'
  || rbiValue === 'E'
  || rbiValue === 'V'
  || rbiValue === 'R';
  
import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  loader: {
    height: '150px!important',
    width: '150px!important',
  },
}));

export default useStyles;

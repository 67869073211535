export class CardEndpoints {
  static getCardAccountStatus = (companyId: string): string => `/api/v1/company/${companyId}/card/status`;
  static acceptTermsOfService = (companyId: string): string => `/api/v1/company/${companyId}/card/accept`;
  static createBankAccount = (companyId: string): string => `/api/v1/company/${companyId}/card/bank`;
  static createCompany = (companyId: string): string => `/api/v1/company/${companyId}/card/account`;
  static createMerchant = (companyId: string): string => `/api/v1/company/${companyId}/card/merchant`;
  static validateMicrodeposit = (companyId: string): string => `/api/v1/company/${companyId}/card/microdeposit`;
  static getTerms = (companyId: string): string => `/api/v1/company/${companyId}/card/terms`;
  static getRecoveryQuestions = (companyId: string): string => `/api/v1/company/${companyId}/card/recovery`;
  static confirmBeneficialOwners = (companyId: string): string => `/api/v1/company/${companyId}/card/merchant/confirm`;
  static resetBankAccount = (companyId: string): string => `/api/v1/company/${companyId}/card/account/reset`;
}

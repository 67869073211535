import { ActivityLogs } from '../constants/actions';


export interface LogsState {
  ui: { loading?: boolean };
  data: any;
  order?: string[];
}

export function logs(state: LogsState = { ui: {}, data: {} }, action: Record<string, any> = {}): LogsState {
  switch (action.type) {
    case ActivityLogs.REQUEST_ACTIVITY_LOGS:
      return { ...state, ui: { loading: true } };
    case ActivityLogs.RECEIVE_ACTIVITY_LOGS:
      return { data: action.entities.logs, order: action.order, ui: { loading: false } };
    default:
      return state;
  }
}

import { CompanyActions } from '../constants/actions';
import { mergeStateAndEntityResult } from '../services/app/normalizr';
import { Company } from '../types/Company';

export interface CompaniesState {
  data?: { [id: string]: Company };
  order?: string[];
}

// eslint-disable-next-line max-lines-per-function
export function companies(state: CompaniesState = { data: {} }, action: Record<string, any> = {}): CompaniesState {
  const nextData = mergeStateAndEntityResult(state, action.entities, 'companies');
  switch (action.type) {
    case CompanyActions.REQUEST_COMPANY:
    case CompanyActions.REQUEST_COMPANY_LIST:
      // Deprecated, do nothing
      return state;
    case CompanyActions.RECEIVE_COMPANY_LIST:
      return { ...state, data: nextData, order: action.order };
    case CompanyActions.RECEIVE_COMPANY:
    default:
      return { ...state, data: nextData };
  }
}

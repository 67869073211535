export class BankEndpoints {
  static getBankStatus = (companyId: string): string => `/api/v1/companies/${companyId}/bank/status`;
  static createPersonalCustomer = (companyId: string): string => `/api/v1/companies/${companyId}/bank/customer`;
  static createBusinessCustomer = (companyId: string): string => `/api/v1/companies/${companyId}/bank/customer/business`;
  static attachBankAccount = (companyId: string): string => `/api/v1/companies/${companyId}/bank/source`;
  static confirmMicroDeposits = (companyId: string): string => `/api/v1/companies/${companyId}/bank/microdeposits`;
  static bankingCollect = (companyId: string): string => `/api/v1/companies/${companyId}/bank/collect`;
  static bankingPayout = (companyId: string): string => `/api/v1/companies/${companyId}/bank/payout`;
  static getBankAccount = (companyId: string): string => `/api/v1/companies/${companyId}/bank`;
  static getCompanyBeneficialOwners = (companyId: string): string => `/api/v1/companies/${companyId}/bank/ownership/list`;
  static updateBeneficialOwnerById = (ownerId: string): string => `/api/v1/bank/ownership/${ownerId}`;
  static deleteBeneficialOwnerById = (ownerId: string): string => `/api/v1/bank/ownership/${ownerId}`;
  static attachBankAccountWithPlaid = (companyId: string): string => `/api/v1/companies/${companyId}/bank/source/plaid`;
  static resetBankAccount = (companyId: string): string => `/api/v1/companies/${companyId}/bank/account/reset`;
  static getIsTodayABankHoliday = (): string => `/api/v1/bank/holiday/today`;
}

import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey';

const styles = makeStyles((theme: Theme) => createStyles({
  row: {
    background: theme.palette.common.white,
    boxShadow: theme.shadows[2],
  },
  container: {
    justifyContent: 'flex-end',
    [theme.breakpoints.up(650)]: {
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
    },
  },
  actionBtn: {
    background: grey[200],
  },
  saveGrid: {
    [theme.breakpoints.up(450)]: {
      flexBasis: '50%',
    },
    [theme.breakpoints.up(500)]: {
      flexBasis: 'auto',
    }
  },
  hidden: {
    display: 'none',
  },
  addGrid: {
    [theme.breakpoints.up(450)]: {
      flexBasis: '50%',
    },
    [theme.breakpoints.up(500)]: {
      flexBasis: 'auto',
    }
  },
  saveBtn: {
    fontSize: '0.875rem',
    padding: '10px 24px 9px',
    width: '100%',
  },
  addBtn: {
    width: '100%',
  }
}));

export default styles;

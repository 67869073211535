import { AppSettingsActions } from '../constants/actions';
import * as appSettingsService from '../services/api/appSettings';
import { appSettingsSchema } from '../types/schemas';
import { normalizeResult } from '../services/app/normalizr';
import { isEmployee } from '../services/app/auth';
import _ from 'lodash';

function requestAppSettings() {
  return {
    type: AppSettingsActions.REQUEST_APP_SETTINGS,
  };
}

function receiveAppSettings(entities, result, defaults) {
  return {
    type: AppSettingsActions.RECEIVE_APP_SETTINGS,
    entities,
    result,
    defaults,
  };
}

const normalizeAppSettingsResult = normalizeResult(appSettingsSchema);
const parseAppSettings = (appSettings) => ({
  ...appSettings,
  labels: appSettings.labels
    ? Object.keys(appSettings.labels).map(id => ({ id, data: appSettings.labels[id] }))
    : []
});

export function getAppSettings() {
  return (dispatch: Function, getState: Function) => {
    dispatch(requestAppSettings());

    return appSettingsService.getAppSettings()
      .then(parseAppSettings)
      .then((appSettings: any) => {
        const storeDefaults = getState().appSettings?.defaults;
        const newDefaults = getAppDefaults(storeDefaults, appSettings);
        return normalizeAppSettingsResult(appSettings)
          .then((data: any) => {
            return dispatch(receiveAppSettings(data.entities, data.result, newDefaults));
          });
      });
  };
}

// eslint-disable-next-line max-lines-per-function,max-statements
function getAppDefaults(storeDefaults, { user, accessibleLocations }) {
  if (isEmployee(user) && accessibleLocations) {
    if (storeDefaults) {
      // check whether redux store defaults are compatible with received app settings
      const location = accessibleLocations.find(x => x.id === storeDefaults.locationId);

      if (location) {
        const departments = location.departments || [];
        const shifts = location.shifts || [];

        // check whether stored department id is compatible with received departments
        const isDepartmentCompatible =
          (!storeDefaults.departmentId && departments.length === 0) ||
          (storeDefaults.departmentId && departments.some(x => x.id === storeDefaults.departmentId));

        // check whether stored shift id is compatible with received shifts
        const isShiftCompatible =
          (!storeDefaults.shiftId && shifts.length === 0) ||
          (storeDefaults.shiftId && shifts.some(x => x.id === storeDefaults.shiftId));

        if (isDepartmentCompatible && isShiftCompatible) {
          return storeDefaults;
        }
      }
    }

    if (accessibleLocations.length === 1) {
      const location = accessibleLocations[0];
      const departments = location.departments || [];
      const shifts = location.shifts || [];

      if (departments.length > 1 || shifts.length > 1) {
        return null;
      }

      return {
        locationId: location.id,
        departmentId: _.head(departments.map(department => department.id)),
        shiftId: _.head(shifts.map(shift => shift.id)),
      };
    }
  }

  return null;
}

export function setAppDefaults(locationId, departmentId, shiftId) {
  return {
    type: AppSettingsActions.SET_APP_DEFAULTS,
    defaults: {
      locationId,
      departmentId,
      shiftId,
    },
  };
}
